import React from 'react'
import { Link, NavLink as RRNavLink, } from 'react-router-dom'
import { FormattedDate, FormattedTime, } from 'react-intl'

import {
  Card, CardHeader, CardBody, CardFooter,
  FormGroup, Input,
  Spinner, NavLink
} from 'reactstrap'

import PhoneInput from 'react-phone-number-input'

import { AppContext } from 'contexts/AppContext'
import ProtectedComponent, { useHasRights } from 'components/ProtectedComponent'
import FilterSelect from 'components/FilterSelect'
import SlotSelector from 'components/SlotSelector'
import CustomSelect from 'components/CustomSelect'
import { T, TBit, ErrAlert, TButton, TNavLink, TLabel, TCustomInput, TAlert, TDatePicker } from 'components/TComponents'

import './DeskDetails.scss'

const Tel = chunk => <a href={`tel:${chunk}`}>{chunk}</a>

const businessReducer = (state, action) => {
  switch (action.type) {
  case 'success': return {
    loading: false,
    data: action.payload,
    error: undefined
  }
  case 'error': return {
    loading: false,
    data: undefined,
    error: action.payload
  }
  default: return state
  }
}

const businessInitialState = {
  loading: true,
  error: undefined,
  data: undefined,
}

const DeskDetails = ({ match, location, history }) => {
  const { api, constants } = React.useContext(AppContext)

  const hasDeskStatus = useHasRights('desk_status')
  const hasDeskEdit = useHasRights('desk_edit')

  // TODO: useReducer for ticket editing removing state weird thing
  const [ticket, setTicket] = React.useState()
  const [state, setState] = React.useState('load')
  const [loadError, setLoadError] = React.useState()
  const [creating, setCreating] = React.useState()
  const [createError, setCreateError] = React.useState()
  const [ticketEdited, setTicketEdited] = React.useState()
  const [ticketStatusId, setTicketStatusId] = React.useState(-1)
  const [reasonCanceled, setReasonCanceled] = React.useState()
  const [commentsCanceled, setCommentsCanceled] = React.useState()
  // TODO: check if edit or create
  const [canEditStatus, setCanEditStatus] = React.useState(false)
  const [statusDisable, setStatusDisable] = React.useState(false)
  const [statusUpdateError, setStatusUpdateError] = React.useState()
  const [canEditTags, setCanEditTags] = React.useState(false)
  const [tagsDisable, setTagsDisable] = React.useState(false)
  const [tagsUpdateError, setTagsUpdateError] = React.useState()

  const [businessState, businessDispatch] = React.useReducer(businessReducer, businessInitialState)

  const [updating, setUpdating] = React.useState(false)
  const [updateError, setUpdateError] = React.useState()

  const [comment, setComment] = React.useState('')
  const [commentStatus, setCommentStatus] = React.useState({ type: 'pristine' })
  const [canSaveEdit, setCanSaveEdit] = React.useState(false)

  const [ticketHistory, setTicketHistory] = React.useState()

  const [deskTags, setDeskTags] = React.useState()
  const [deskTagsSelected, setDeskTagsSelected] = React.useState([])
  const [deskTagLoading, setDeskTagLoading] = React.useState(true)
  const [deskTagLoadError, setDeskTagLoadError] = React.useState()

  const [duplicateTicketId, setDuplicateTicketId] = React.useState()

  React.useEffect(() => {
    const { params: { id: ticketId, }, } = match

    const urlParams = new URLSearchParams(location.search)
    urlParams.get('ticketId') && setDuplicateTicketId(urlParams.get('ticketId'))

    if (ticketId) {
      setCanEditStatus(hasDeskStatus)
      setCanEditTags(hasDeskEdit)

      api.get('/desk/details', undefined, { ticketId })
        .then(response => {
          setTicket({
            ...response,
            dateAppointment: response.dateAppointment ? new Date(response.dateAppointment) : undefined,
            dateStartLocation: response.dateStartLocation ? new Date(response.dateStartLocation) : undefined,
          })
          setTicketStatusId(response.ticketStatusId)
          setState('viewing')
          setDeskTagsSelected(response.desktags && response.desktags.length > 0 && response.desktags.map(t => { return { deskTagId: t.deskTagId, tagIdentifier: t.tagIdentifier } }))
          getTicketHistory(ticketId)
        })
        .catch(response => {
          setLoadError(response)
          setState('error')
        })
        .then(() => setState('viewing'))
    } else {
      setTicketEdited({
        businessId: '',
        dateAppointment: null,
        equipmentIdentifier: '',
        defectParts: 0,
        defectOther: '',
        availabilityIssue: 0,
        dateStartLocation: null,
        traveledKm: 0,
        issueDescription: '',
        lastName: '',
        firstName: '',
        email: '',
        phoneNumber: '',
        address: '',
        addressComplement: '',
        postalCode: '',
        city: '',
        ...((location.state && location.state.ticket) || {}),
      })
      setState('creating')
    }

  }, [api, hasDeskStatus, hasDeskEdit, location, match, getTicketHistory])

  React.useEffect(() => {
    api.get('/desk/business', undefined, { ipp: -1 })
      .then(response => businessDispatch({
        type: 'success', payload: response.map(business => ({
          value: business.businessId,
          label: business.businessName,
        }))
      }))
      .catch(payload => businessDispatch({ type: 'error', payload }))

    api.get('/desktags/list')
      .then(response => {
        setDeskTags(response)
      })
      .catch(response => {
        setDeskTagLoadError(response)
      })
      .then(() => setDeskTagLoading(false))
  }, [api])

  React.useEffect(() => {
    if (ticket) {
      if (ticket.ticketStatusId !== ticketStatusId) {
        setStatusDisable(false)
      } else {
        setStatusDisable(true)
      }
    }
    if (ticketStatusId != 30) {
      setReasonCanceled()
      setCommentsCanceled()
    }
  }, [ticket, ticketStatusId, constants])

  React.useEffect(() => {
    if (ticket) {
      if (JSON.stringify(ticket.desktags && ticket.desktags.length > 0 && ticket.desktags.map(d => d.deskTagId).sort()) != JSON.stringify(deskTagsSelected && deskTagsSelected.length > 0 && deskTagsSelected.map(d => d.deskTagId).sort() || null)) {
        setTagsDisable(false)
      } else {
        setTagsDisable(true)
      }
    }
  }, [ticket, deskTagsSelected])

  React.useEffect(() => {
    setStatusDisable(false)
  }, [commentsCanceled, reasonCanceled])

  React.useEffect(() => {
    if (canSaveEdit && editState) {
      saveEditAction()
    }
  }, [canSaveEdit, editState, saveEditAction])

  React.useEffect(() => {
    if (duplicateTicketId) {
      setCanEditStatus(hasDeskStatus)
      setCanEditTags(hasDeskEdit)

      api.get('/desk/details', undefined, { ticketId: duplicateTicketId })
        .then(response => {
          setTicketEdited({
            ...response,
            ticketStatusValue: 'new',
            dateCreated: null,
            dateUpdated: null,
            dateAppointment: response.dateAppointment ? new Date(response.dateAppointment) : undefined,
            dateStartLocation: response.dateStartLocation ? new Date(response.dateStartLocation) : undefined,
          })
          // setTicketStatusId(response.ticketStatusId)
          setState('creating')
          setDeskTagsSelected(response.desktags && response.desktags.length > 0 && response.desktags.map(t => { return { value: t.deskTagId, label: t.tagIdentifier } }))
        })
        .catch(response => {
          setLoadError(response)
          setState('error')
        })
        .then(() => setState('creating'))
    }
  }, [duplicateTicketId, api, hasDeskStatus, hasDeskEdit])

  const getTicketHistory = React.useCallback((ticketId) => {
    api.get('/desk/history', undefined, { ticketId })
      .then(response => {
        setTicketHistory(response)
      })
      .catch(error => {
        setLoadError(error)
        setState('error')
      })
  }, [api])

  const parseTicketHistory = (th, i) => {
    const sharedTicketHistory = th => {
      return (
        <React.Fragment key={`${th.user}-${i}`}>
          <FormattedDate value={new Date(th.dateCreated)} />{" "}<FormattedTime value={new Date(th.dateCreated)} />
          <>{" - "}</>
          <>{th.user === 'Système' ? <T id="systeme" /> : th.user}</>
          <>{" - "}  </>
        </React.Fragment>
      )
    }

    switch (th.action) {
    case 'comment': case 'comment_velodesk': return (
      <p key={`${th.user}-${i}`}>
        {sharedTicketHistory(th)}
        <T id={th.value ? th.action : `${th.action}.noValue`} />
        <span className="mt-1 font-italic" style={{ color: '#0080cc' }}>{th.value}</span>
      </p>
    )
    case 'status': return (
      <p key={`${th.user}-${i}`}>
        {sharedTicketHistory(th)}
        <T id={th.action} />{" "}
        <span className="mt-1 font-italic">
          <T id={`status.${th.value}`} style={{ color: '#dd9c02' }} />
          {th.value2 && <>{' '}<T id='status.commentsHistoryCanceled' /><span style={{ color: '#0080cc' }}>{th.value2}</span></>}
        </span>
      </p>
    )
    case 'intervention': return (
      <p key={`${th.user}-${i}`}>
        {sharedTicketHistory(th)}
        <T id={th.action} style={{ color: '#3e884f' }} />{' '}
        <a style={{ color: '#3e884f', textDecoration: 'underline' }} className="font-weight-bold" href={`/interventions/${th.value}`}>{th.value}</a>
      </p>
    )
    case 'swap': return (
      <p key={`${th.user}-${i}`}>
        {sharedTicketHistory(th)}
        <T id={th.action} style={{ color: '#3e884f' }} />{' '}
        <a style={{ color: '#3e884f', textDecoration: 'underline' }} className="font-weight-bold" href={`/swaps/${th.value}`}>{th.value}</a>
      </p>
    )
    case 'duplicate_itv': return (
      <p key={`${th.user}-${i}`}>
        {th.value2 ? (
          <>
            {sharedTicketHistory(th)}
            <a style={{ color: '#3e884f', textDecoration: 'underline' }} className="font-weight-bold" href={`/interventions/${th.value}`}>{th.value}</a>
            {' '}<T id={`${th.action}`} style={{ color: '#3e884f' }} />{' '}
            <a style={{ color: '#3e884f', textDecoration: 'underline' }} className="font-weight-bold" href={`/interventions/${th.value}`}>{th.value2}</a>
          </>
        ) : (
          <>
            {sharedTicketHistory(th)}
            <T id={`${th.action}.old`} style={{ color: '#3e884f' }} />{' '}
            <a style={{ color: '#3e884f', textDecoration: 'underline' }} className="font-weight-bold" href={`/interventions/${th.value}`}>{th.value}</a>
          </>
        )}
      </p>
    )
    case 'transformItvSwap': return (
      <p key={`${th.user}-${i}`}>
        {sharedTicketHistory(th)}
        <T id={`${th.action}.itv`} style={{ color: '#3e884f' }} />{' '}
        <a style={{ color: '#3e884f', textDecoration: 'underline' }} className="font-weight-bold" href={`/interventions/${th.value2}`}>{th.value2}</a>
        <T id={`${th.action}.swap`} style={{ color: '#3e884f' }} />{' '}
        <a style={{ color: '#3e884f', textDecoration: 'underline' }} className="font-weight-bold" href={`/swaps/${th.value}`}>{th.value}</a>
      </p>
    )
    case 'transformSwapItv': return (
      <p key={`${th.user}-${i}`}>
        {sharedTicketHistory(th)}
        <T id={`${th.action}.swap`} style={{ color: '#3e884f' }} />{' '}
        <a style={{ color: '#3e884f', textDecoration: 'underline' }} className="font-weight-bold" href={`/swaps/${th.value2}`}>{th.value2}</a>
        <T id={`${th.action}.itv`} style={{ color: '#3e884f' }} />{' '}
        <a style={{ color: '#3e884f', textDecoration: 'underline' }} className="font-weight-bold" href={`/interventions/${th.value}`}>{th.value}</a>
      </p>
    )
    case 'duplicate': return (
      <p key={`${th.user}-${i}`}>
        {sharedTicketHistory(th)}
        <T id={`${th.action}.ticket`} style={{ color: '#3e884f' }} />{' '}
        <a style={{ color: '#3e884f', textDecoration: 'underline' }} className="font-weight-bold" href={`/desk/${th.value}`}>{th.value}</a>
      </p>
    )
    default: return (
      <p key={`${th.user}-${i}`}>
        {sharedTicketHistory(th)}
        <T id={th.action} />
      </p>
    )
    }
  }

  const edit = React.useCallback(() => {
    setTicketEdited({ ...ticket })
    setState('editing')
  }, [ticket])

  const saveEdit = React.useCallback(() => {
    if (ticketEdited && ticket && ticket.ticketStatusId !== ticketEdited.ticketStatusId) {
      updateStatus()
    }
    if (comment) {
      saveComment()
    }
    if ((ticketEdited && ticket && ticket.ticketStatusId === ticketEdited.ticketStatusId) && !comment) {
      setCanSaveEdit(true)
    }
  }, [ticketEdited, ticket, comment, saveComment, updateStatus])

  const saveEditAction = React.useCallback(() => {
    setUpdateError()
    setUpdating(true)
    const body = {
      ...ticketEdited,
      desktags: ticketEdited.desktags ? ticketEdited.desktags.map(t => t.deskTagId ? t.deskTagId : t) : undefined,
    }
    api.post('/desk/details', { body: JSON.stringify(body) })
      .then(response => {
        setTicket({
          ...response,
          dateAppointment: response.dateAppointment ? new Date(response.dateAppointment) : undefined,
          dateStartLocation: response.dateStartLocation ? new Date(response.dateStartLocation) : undefined,
        })
        setTicketStatusId(response.ticketStatusId)
        setState('viewing')
        getTicketHistory(response.ticketId)
      })
      .catch(error => setUpdateError(error))
      .then(() => {
        setCanSaveEdit(false)
        //setTagsDisable(true)
        setUpdating(false)
      })
  }, [api, getTicketHistory, ticketEdited])

  const cancelEdit = React.useCallback(() => {
    setTicketEdited()
    setState('viewing')
  }, [])

  const create = React.useCallback(() => {
    setCreateError()
    setCreating(true)
    delete ticketEdited.ticketId
    api.post('/desk/details', {
      body: JSON.stringify({
        ...ticketEdited,
        ticketTypeId: ticketEdited.ticketTypeId || 0,
        criticityId: ticketEdited.criticityId || 0,
        ticketQualifId: ticketEdited.ticketQualifId || 0,
        ...(duplicateTicketId && { duplicateTicketId })

      })
    })
      .then(response => history.push({
        pathname: `/desk/${response.ticketId}`,
        state: location.state
      }))
      .catch(error => {
        setCreateError(error)
        setCreating(false)
      })
  }, [api, history, location.state, ticketEdited, duplicateTicketId])

  const duplicate = React.useCallback(() => {
    history.push(`/desk/new?ticketId=${ticket.ticketId}`)
  }, [history, ticket])

  const handleTicketChange = React.useCallback(({ target: { name, value, }, }) => {
    setTicketEdited({
      ...ticketEdited,
      [name]: value
    })
  }, [ticketEdited])

  const handleTicketBitChange = React.useCallback(({ target: { value, name }, }) => {
    handleTicketChange({ target: { name, value: ticketEdited[name] ^ value } })
  }, [handleTicketChange, ticketEdited])

  const handleCommentChange = (e) => {
    setCommentStatus({ type: 'pristine' })
    setComment(e.currentTarget.value)
  }

  const handleDeskTagChange = React.useCallback(e => {
    setTicketEdited({
      ...ticketEdited,
      desktags: e === null ? [] : e.map(t => { return t.value })
    })
    setTagsDisable(false)
    setDeskTagsSelected(e === null ? [] : e.map(t => { return { deskTagId: t.value, tagIdentifier: t.label, colorHex: t.data } }))
  }, [ticketEdited])

  const saveComment = React.useCallback(() => {
    setUpdateError()
    setUpdating(true)
    api.post('/desk/comments', { body: JSON.stringify({ ticketId: ticket.ticketId, comments: comment }) })
      .then(response => {
        if (response.return === 'ok') {
          setComment('')
          setCommentStatus({ type: 'success' })
          getTicketHistory(ticket.ticketId)
          if (editState) {
            setCanSaveEdit(true)
          }
        } else {
          setCommentStatus({ type: 'error', payload: { response } })
          if (editState) { setUpdateError(response) }
        }
      })
      .catch(error => {
        setCommentStatus({ type: 'error', payload: { error } })
        if (editState) { setUpdateError(error) }
      })
      .then(() => setUpdating(false))
  }, [api, comment, ticket, getTicketHistory, editState])

  const updateStatus = React.useCallback(() => {
    setStatusDisable(true)
    setStatusUpdateError()
    setUpdateError()
    setUpdating(true)
    api.post('/desk/status', {
      body: JSON.stringify({ ticketId: ticket.ticketId, ticketStatusId, reasonCanceled, commentsCanceled })
    })
      .then(response => {
        setTicket({
          ...response,
          dateAppointment: response.dateAppointment ? new Date(response.dateAppointment) : undefined,
          dateStartLocation: response.dateStartLocation ? new Date(response.dateStartLocation) : undefined,
        })
        setTicketStatusId(response.ticketStatusId)
        getTicketHistory(ticket.ticketId)
        if (editState) {
          setCanSaveEdit(true)
        }
      })
      .catch(err => {
        if (editState) {
          setUpdateError(err)
        } else {
          setStatusUpdateError(err)
        }
      })
      .then(() => setUpdating(false))
  }, [api, ticket, ticketStatusId, getTicketHistory, reasonCanceled, commentsCanceled, editState])

  const updateTags = React.useCallback(() => {
    setTagsDisable(true)
    setTagsUpdateError()
    setUpdateError()
    setUpdating(true)
    api.post('/desk/desktag', {
      body: JSON.stringify({ ticketId: ticket.ticketId, desktags: deskTagsSelected && deskTagsSelected.length > 0 ? deskTagsSelected.map(d => d.deskTagId) : [] })
    })
      .then(response => {
        setTicket(response)
        if (editState) {
          setCanSaveEdit(true)
        }
      })
      .catch(err => {
        if (editState) {
          setUpdateError(err)
        } else {
          setTagsUpdateError(err)
        }
      })
      .then(() => setUpdating(false))
  }, [api, ticket, editState, deskTagsSelected])

  const EquipmentLink = React.useMemo(() =>
    ticket
      ? (
        ticket.equipmentId ?
          <NavLink
            tag={Link}
            to={{
              pathname: `/equipments/${ticket.equipmentId}/identity`
            }}
            className="d-inline p-0">
            {ticket.equipmentIdentifier}
          </NavLink>
          : (
            <NavLink
              tag={Link}
              to={{
                pathname: '/equipments',
                state: { filter: ticket.equipmentIdentifier, businessId: ticket.businessId || '' }
              }}
              className="d-inline p-0">
              {ticket.equipmentIdentifier}
            </NavLink>
          )
      )
      : ''
  , [ticket])

  const editState = React.useMemo(() => state === 'editing' || state === 'creating', [state])

  if (loadError) {
    return <>
      <TNavLink id="returnToList" tag={Link}
        to={{
          pathname: '/desk',
          search: location.state ? location.state.desk : undefined
        }} />
      <ErrAlert error={loadError} />
    </>
  }

  if (['error', 'load'].includes(state)) {
    return <>
      <TNavLink id="returnToList" tag={Link}
        to={{
          pathname: '/desk',
          search: location.state ? location.state.desk : undefined
        }} />
      <Spinner className="d-flex mx-auto my-5" color="primary" />
    </>
  }

  return (
    <>
      <TNavLink id="returnToList" tag={Link}
        to={{
          pathname: '/desk',
          search: location.state ? location.state.desk : undefined
        }} />
      <Card className="mb-2">
        <CardHeader>
          {state === 'creating'
            ? <T id="title" className="h3" />
            : <T id="title"
              values={{
                ticketId: ticket.ticketId,
                equipmentIdentifier: EquipmentLink,
                categoryName: ticket.categoryName,
                familyName: ticket.familyName
              }}
              className="h3" />
          }
        </CardHeader>
        <CardBody>
          {state !== 'creating' &&
            <div className="mb-3">
              {!editState &&
                <>
                  <FormGroup tag="fieldset">
                    <TLabel id="ticketStatusValue" for="ticketStatusId" />
                    <CustomSelect
                      inputId="ticketStatusId"
                      name="ticketStatusId"
                      options={ticket.ticketStatusTo}
                      onChange={e => setTicketStatusId(e ? e.ticketStatusId : '')}
                      placeholder={<T id={`ticketStatus.${ticket.ticketStatusValue}`} raw />}
                      value={ticket.ticketStatusTo.filter(t => t.ticketStatusId === ticketStatusId)}
                      getOptionLabel={option => <T id={`ticketStatus.${option.ticketStatusValue}`} raw />}
                      getOptionValue={option => option.ticketStatusId}
                      isDisabled={!canEditStatus || creating || updating} />
                    {canEditStatus && (ticketStatusId != 30 || (ticketStatusId == 30 && ticket.ticketStatusId == ticketStatusId)) &&
                      <TButton className="mt-3 py-2"
                        id="ticketStatusUpdate"
                        color="primary"
                        onClick={() => updateStatus()}
                        disabled={statusDisable} />
                    }
                  </FormGroup>
                  {canEditStatus && ticketStatusId == 30 && ticketStatusId !== ticket.ticketStatusId && (
                    <>
                      <FormGroup className="mt-3 mb-3" tag="fieldset">
                        <TLabel id="reasonCanceled" for="reasonCanceled" className="mb-1" />
                        <CustomSelect
                          inputId="reasonCanceled"
                          name="reasonCanceled"
                          options={constants.ticketReasonCanceled}
                          onChange={e => setReasonCanceled(e ? e.value : '')}
                          isClearable
                          placeholder={<T id={`ticketReasonCanceled.placeholder`} raw />}
                          value={constants.ticketReasonCanceled.filter(t => t.value === reasonCanceled)}
                          getOptionLabel={option => <T id={`ticketReasonCanceled.${option.key}`} raw />}
                          isDisabled={!canEditStatus || creating || updating} />
                      </FormGroup>
                      <FormGroup className="mb-3" tag="fieldset">
                        <TLabel id="commentsCanceled" className="mb-1" />
                        <Input type="textarea"
                          disabled={!canEditStatus || creating || updating}
                          rows="8"
                          name="commentsCanceled"
                          value={commentsCanceled}
                          onChange={e => setCommentsCanceled(e.target.value)} />
                        {canEditStatus && <TButton className="mt-3 py-2"
                          id="ticketStatusUpdate"
                          color="primary"
                          onClick={() => updateStatus()}
                          disabled={statusDisable} />}
                      </FormGroup>
                    </>
                  )}
                </>
              }
              {editState &&
                <>
                  <FormGroup className="mt-3 mb-3" tag="fieldset">
                    <TLabel id="ticketStatusValue" for="ticketStatusId" />
                    <CustomSelect
                      inputId="ticketStatusId"
                      name="ticketStatusId"
                      options={ticket.ticketStatusTo}
                      onChange={e => {
                        handleTicketChange({ target: { name: 'ticketStatusId', value: e && e.ticketStatusId } })
                        setTicketStatusId(e ? e.ticketStatusId : '')
                      }}
                      placeholder={<T id={`ticketStatus.${ticket.ticketStatusValue}`} raw />}
                      value={ticket.ticketStatusTo.filter(t => t.ticketStatusId === ticketStatusId)}
                      getOptionLabel={option => <T id={`ticketStatus.${option.ticketStatusValue}`} raw />}
                      getOptionValue={option => option.ticketStatusId}
                      isDisabled={creating || updating} />
                  </FormGroup>
                  {ticketStatusId == 30 && ticketStatusId !== ticket.ticketStatusId && (
                    <>
                      <FormGroup className="mt-3 mb-3" tag="fieldset">
                        <TLabel id="reasonCanceled" for="reasonCanceled" />
                        <CustomSelect
                          inputId="reasonCanceled"
                          name="reasonCanceled"
                          options={constants.ticketReasonCanceled}
                          onChange={e => setReasonCanceled(e ? e.value : '')}
                          isClearable
                          placeholder={<T id={`ticketReasonCanceled.placeholder`} raw />}
                          value={constants.ticketReasonCanceled.filter(t => t.value === reasonCanceled)}
                          getOptionLabel={option => <T id={`ticketReasonCanceled.${option.key}`} raw />}
                          isDisabled={creating || updating} />
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <TLabel id="commentsCanceled" className="mb-1" />
                        <Input type="textarea"
                          disabled={creating || updating}
                          rows="8"
                          name="commentsCanceled"
                          value={commentsCanceled}
                          onChange={e => setCommentsCanceled(e.target.value)} />
                      </FormGroup>
                    </>
                  )}
                </>
              }
              {statusUpdateError && !editState && <ErrAlert error={statusUpdateError} className="mt-3 mb-0" />}
            </div>
          }

          {!deskTagLoading && !deskTagLoadError && (
            <FormGroup className="mb-2" style={{ marginBottom: 8 }}>
              <TLabel for="tag" id="tag" />
              <div className="d-flex">
                <div style={{ flex: 1 }}>
                  <CustomSelect
                    inputId="tag"
                    name="tag"
                    isMulti
                    isSearchable
                    style={{ flex: 1 }}
                    options={deskTags && deskTags.result.map((r) => ({
                      label: r.tagIdentifier,
                      value: r.deskTagId,
                      data: r.colorHex
                    }))}
                    onChange={handleDeskTagChange}
                    isDisabled={(!editState && !canEditTags) || creating || updating}
                    placeholder={<T id="deskTagsPlaceholder" />}
                    value={deskTagsSelected && deskTagsSelected.length > 0 && deskTags && deskTags.result.map((r) => ({ label: r.tagIdentifier, value: r.deskTagId, data: r.colorHex })).filter(t => deskTagsSelected.find(it => it.deskTagId === t.value))}
                    formatOptionLabel={option => option.data
                      ? <div className="d-flex flex-row align-items-center"><div style={{ backgroundColor: option.data }} className="mr-2 color-tag" /><>{option.label}</></div>
                      : <div className="d-flex flex-row align-items-center"><div style={{ backgroundColor: '#fff' }} className="mr-2 color-tag" /><>{option.label}</></div>}
                  />
                </div>
              </div>
              {(canEditTags && !editState) && (ticketStatusId != 30 || (ticketStatusId == 30 && ticket.ticketStatusId == ticketStatusId)) && (
                <TButton className="mt-3 py-2"
                  id="ticketStatusUpdate"
                  color="primary"
                  onClick={() => updateTags()}
                  disabled={tagsDisable} />
              )}
              {tagsUpdateError && !editState && <ErrAlert error={tagsUpdateError} className="mt-3 mb-0" />}
            </FormGroup>
          )}
          <FormGroup className="mb-2">
            <TLabel id="businessId.label"
              for="businessId"
              className="mb-1" />
            {businessState.error && <ErrAlert error={businessState.error} />}
            {!businessState.error && businessState.loading && <Spinner color="primary" className="d-block" />}
            {!businessState.error && !businessState.loading && state === 'creating'
              && <FilterSelect name="businessId"
                value={businessState.data.find(business => business.value == ticketEdited.businessId)}
                options={businessState.data}
                isDisabled={creating}
                onChange={value => handleTicketChange({ target: { name: 'businessId', value } })} />}
            {!businessState.error && !businessState.loading && state !== 'creating'
              && businessState.data.find(business => business.value == ticket.businessId).label}
          </FormGroup>

          <FormGroup className="mb-2">
            <TLabel id="ticketQualifId"
              for="ticketQualifId"
              className="mb-1" />

            {editState
              ? <div>
                {constants.ticketQualif.map(qualif => <TCustomInput key={qualif.key}
                  id={`ticketQualifId-${qualif.key}`}
                  className="mb-2"
                  type="radio"
                  name={`ticketQualifId`}
                  label={`ticketQualification.${qualif.key}`}
                  raw
                  value={qualif.value}
                  checked={qualif.value === ticketEdited.ticketQualifId}
                  onChange={() => handleTicketChange({ target: { name: 'ticketQualifId', value: qualif.value } })}
                  inline />)}
              </div>
              : <T raw id={`ticketQualification.${constants.ticketQualif.find(c => c.value === ticket.ticketQualifId).key}`} />}
          </FormGroup>

          <FormGroup className="mb-2">
            <TLabel id="ticketTypeId"
              for="ticketTypeId"
              className="mb-1" />
            {editState
              ? <div>
                {constants.ticketType.map(ticketType => <TCustomInput key={ticketType.key}
                  id={`ticketTypeId-${ticketType.key}`}
                  className="mb-2"
                  type="radio"
                  name={`ticketTypeId`}
                  label={`ticketType.${ticketType.key}`}
                  raw
                  value={ticketType.value}
                  checked={ticketType.value === ticketEdited.ticketTypeId}
                  disabled={creating || updating}
                  onChange={() => handleTicketChange({ target: { name: 'ticketTypeId', value: ticketType.value } })}
                  inline />)}
              </div>
              : <T raw id={`ticketType.${constants.ticketType.find(c => c.value === ticket.ticketTypeId).key}`} />}
          </FormGroup>

          <FormGroup className="mb-2">
            <TLabel id="criticityId"
              for="criticityId"
              className="mb-1" />
            {editState
              ? <div>
                {constants.ticketCriticity.map(criticity => <TCustomInput key={criticity.key}
                  id={`criticityId-${criticity.key}`}
                  className="mb-2"
                  type="radio"
                  name={`criticityId`}
                  label={`ticketCriticity.${criticity.key}`}
                  raw
                  value={criticity.value}
                  checked={criticity.value === ticketEdited.criticityId}
                  disabled={creating || updating}
                  onChange={() => handleTicketChange({ target: { name: 'criticityId', value: criticity.value } })}
                  inline />)}
              </div>
              : <T raw id={`ticketCriticity.${constants.ticketCriticity.find(c => c.value === ticket.criticityId).key}`} />}
          </FormGroup>

          <FormGroup className="mb-2" tag="fieldset">
            <TLabel id="claimNumber"
              for="claimNumber"
              className="mb-1" />
            {editState
              ? <Input id="claimNumber"
                className="mb-2"
                type="text"
                name="claimNumber"
                value={ticketEdited.claimNumber || ''}
                disabled={creating || updating}
                onChange={handleTicketChange} />
              : ticket.claimNumber
                ? <span>{ticket.claimNumber}</span>
                : <T id="noClaimNumber" />
            }
          </FormGroup>

          <FormGroup className="mb-2 d-flex flex-column align-items-start">
            <TLabel id="dateAppointment" className="mb-1" />
            {!editState && (ticket.dateAppointment
              ? <><FormattedDate value={new Date(ticket.dateAppointment)} />{' '}<FormattedTime value={new Date(ticket.dateAppointment)} /></>
              : <T id="noDateAppointment" />)}
            {editState && <TDatePicker
              selected={ticketEdited.dateAppointment}
              onChange={e => handleTicketChange({ target: { name: 'dateAppointment', value: e }, })}
              disabled={creating || updating}
              showTimeSelect
              dateFormat="eeee dd/MM/yyyy HH:mm"
              customInput={<SlotSelector />}
              placeholderText={<T id="noDate" />}
            />}
          </FormGroup>

          <FormGroup className="mb-2">
            <TLabel id="equipmentIdentifier" for="equipmentIdentifier"
              className="mb-1" />
            {editState
              ? <Input id="equipmentIdentifier"
                className="mb-2"
                type="text"
                name="equipmentIdentifier"
                value={ticketEdited.equipmentIdentifier}
                disabled={creating || updating}
                onChange={handleTicketChange} />
              : (
                ticket.equipmentId ?
                  <NavLink
                    tag={Link}
                    to={{
                      pathname: `/equipments/${ticket.equipmentId}/identity`
                    }}
                    className="d-inline p-0">
                    {ticket.equipmentIdentifier}
                  </NavLink>
                  : (
                    <NavLink
                      tag={Link}
                      to={{
                        pathname: '/equipments',
                        state: { filter: ticket.equipmentIdentifier, businessId: ticket.businessId || '' }
                      }}
                      className="d-inline p-0">
                      {ticket.equipmentIdentifier}
                    </NavLink>
                  )
              )}
          </FormGroup>

          {editState
            ? <FormGroup className="mb-2">
              <TLabel id="defectParts" for="defectParts" className="mb-1" />
              <div>
                {constants.ticketDefectParts.map(part => <TCustomInput key={part.key}
                  id={`defectParts-${part.key}`}
                  className="mb-2"
                  type="checkbox"
                  name={`defectParts`}
                  label={`defectParts.${part.key}`}
                  raw
                  value={part.bit}
                  checked={Boolean(ticketEdited.defectParts & part.bit)}
                  disabled={creating || updating}
                  onChange={handleTicketBitChange}
                  inline />)}
              </div>
            </FormGroup>
            : <FormGroup className="mb-2">
              <TLabel id="defectParts" for="defectParts" className="mb-1" />
              {ticket.defectParts ? ticket.defectPartsValue : <T id="noDefectParts" />}
            </FormGroup>}

          <FormGroup className="mb-2">
            <TLabel id="defectOther" className="mb-1" />
            {!editState && (ticket.defectOther ? ticket.defectOther : <T id="noDefectOther" />)}
            {editState && <Input id="defectOther"
              className="mb-2"
              type="text"
              name="defectOther"
              value={ticketEdited.defectOther || ''}
              disabled={creating || updating}
              onChange={handleTicketChange} />}
          </FormGroup>


          {editState
            ? <FormGroup className="mb-2">
              <TLabel id="availabilityIssue" for="availabilityIssue" className="mb-1" />
              <div>
                {constants.ticketAvailabilityIssue.map(availabilityIssue => <TCustomInput key={availabilityIssue.key}
                  id={`availabilityIssue-${availabilityIssue.key}`}
                  className="mb-2"
                  type="checkbox"
                  name={`availabilityIssue`}
                  label={`availabilityIssue.${availabilityIssue.key}`}
                  raw
                  value={availabilityIssue.bit}
                  checked={Boolean(ticketEdited.availabilityIssue & availabilityIssue.bit)}
                  disabled={creating || updating}
                  onChange={handleTicketBitChange}
                  inline />)}
              </div>
            </FormGroup>
            : <FormGroup className="mb-2">
              <TLabel id="availabilityIssue" for="availabilityIssue" className="mb-1" />
              {ticket.availabilityIssue
                ? <TBit bitmask={ticket.availabilityIssue} descriptor={constants.ticketAvailabilityIssue} prefix="availabilityIssue." raw />
                : <T id="noAvailabilityIssue" />}
            </FormGroup>}

          <FormGroup className="mb-2 d-flex flex-column align-items-start">
            <TLabel id="dateStartLocation" className="mb-1" />
            {!editState && (ticket.dateStartLocation
              ? <FormattedDate value={new Date(ticket.dateStartLocation)} />
              : <T id="noDateLocation" />)}
            {editState && <TDatePicker
              selected={ticketEdited.dateStartLocation}
              onChange={e => handleTicketChange({ target: { name: 'dateStartLocation', value: e }, })}
              disabled={creating || updating}
              dateFormat="eeee dd/MM/yyyy"
              customInput={<SlotSelector />}
              placeholderText={<T id="noDate" />}
            />}
          </FormGroup>

          <FormGroup className="mb-2">
            <TLabel id="traveledKm" className="mb-1" />
            {!editState && (ticket.traveledKm ? ticket.traveledKm : <T id="noTraveledKm" />)}
            {editState && <Input id="traveledKm"
              className="mb-2"
              type="number"
              name="traveledKm"
              value={ticketEdited.traveledKm || ''}
              disabled={creating || updating}
              onChange={handleTicketChange} />}
          </FormGroup>

          <FormGroup className="mb-3">
            <TLabel id="issueDescription" className="mb-1" />
            <Input type="textarea"
              disabled={!editState || creating || updating}
              rows="8"
              name="issueDescription"
              value={(editState ? ticketEdited.issueDescription : ticket.issueDescription) || ''}
              onChange={handleTicketChange} />
          </FormGroup>

          {!editState && <FormGroup className="mb-2">
            <TLabel id="clientInfo" className="mb-1" />
            <T id="clientInfoValue"
              values={{ ...ticket, tel: Tel }} />
          </FormGroup>}
          {editState && <div className="d-flex mb-2">
            <FormGroup tag="fieldset" className="flex-grow-1 mb-0">
              <TLabel id="firstName" for="firstName" className="mb-1" />
              <Input id="firstName"
                className="w-100"
                type="text"
                name="firstName"
                value={editState ? ticketEdited.firstName : ticket.firstName}
                disabled={creating || updating}
                onChange={handleTicketChange} />
            </FormGroup>
            <FormGroup tag="fieldset" className="ml-3 flex-grow-1 mb-0">
              <TLabel id="lastName" for="lastName" className="mb-1" />
              <Input id="lastName"
                className="w-100"
                type="text"
                name="lastName"
                value={editState ? ticketEdited.lastName : ticket.lastName}
                disabled={creating || updating}
                onChange={handleTicketChange} />
            </FormGroup>
            <FormGroup tag="fieldset" className="ml-3 flex-grow-1 mb-0">
              <TLabel id="email" for="email" className="mb-1" />
              <Input id="email"
                className="w-100"
                type="text"
                name="email"
                value={ticketEdited.email}
                disabled={creating || updating}
                onChange={handleTicketChange} />
            </FormGroup>
            <FormGroup tag="fieldset" className="ml-3 flex-grow-1 mb-0">
              <TLabel id="phoneNumber" for="phoneNumber" className="mb-1" />
              {/* TODO: For all PhoneNumber : migrate lib to v3 */}
              <Input id="phoneNumber"
                tag={PhoneInput}
                defaultCountry="FR"
                country="FR"
                className="w-100 d-flex"
                type="text"
                name="address"
                value={ticketEdited.phoneNumber}
                disabled={creating || updating}
                onChange={value => handleTicketChange({ target: { value, name: 'phoneNumber' } })} />
            </FormGroup>
          </div>}

          {!editState && <FormGroup className="mb-2">
            <TLabel id="address" className="mb-1" />
            <T id="addressValue" values={ticket} />
          </FormGroup>}
          {editState && <div className="d-flex mb-2">
            <FormGroup tag="fieldset" className="flex-grow-1 mb-0">
              <TLabel id="address" for="address" className="mb-1" />
              <Input id="address"
                className="w-100"
                type="text"
                name="address"
                value={ticketEdited.address}
                disabled={creating || updating}
                onChange={handleTicketChange} />
            </FormGroup>
            <FormGroup tag="fieldset" className="ml-3 flex-grow-1 mb-0">
              <TLabel id="postalCode" for="postalCode" className="mb-1" />
              <Input id="postalCode"
                className="w-100"
                type="text"
                name="postalCode"
                value={ticketEdited.postalCode}
                disabled={creating || updating}
                onChange={handleTicketChange} />
            </FormGroup>
            <FormGroup tag="fieldset" className="ml-3 flex-grow-1 mb-0">
              <TLabel id="city" for="city" className="mb-1" />
              <Input id="city"
                className="w-100"
                type="text"
                name="city"
                value={ticketEdited.city}
                disabled={creating || updating}
                onChange={handleTicketChange} />
            </FormGroup>
          </div>}

          <FormGroup className="mb-2">
            <TLabel id="addressComplement" className="mb-1" />
            {!editState && ticket.addressComplement}
            {editState && <Input id="addressComplement"
              className="mb-2"
              type="text"
              name="addressComplement"
              value={ticketEdited.addressComplement}
              disabled={creating || updating}
              onChange={handleTicketChange} />}
          </FormGroup>

          {state !== 'creating' && <FormGroup className="mb-2">
            <TLabel id="dateCreated" className="mb-1" />
            <FormattedDate value={new Date(ticket.dateCreated)} />{' '}<FormattedTime value={new Date(ticket.dateCreated)} />
          </FormGroup>}

          {state !== 'creating' && <FormGroup className="mb-2">
            <TLabel id="dateUpdated" className="mb-1" />
            {ticket.dateUpdated
              ? <><FormattedDate value={new Date(ticket.dateUpdated)} />{' '}<FormattedTime value={new Date(ticket.dateUpdated)} /></>
              : <T id="noDateUpdated" />}
          </FormGroup>}

          {state !== 'creating' && <FormGroup className="mb-0">
            <TLabel id="subscriptionId" className="mb-0" />
            {ticket.subscriptionId ? ticket.subscriptionId : <T id="noSubscriptionId" />}
          </FormGroup>}

        </CardBody>

        {canEditStatus && state !== 'creating' && (
          <>
            <ProtectedComponent rights={['desk_edit']}>
              <CardBody className="Section">
                <FormGroup className="mb-2">
                  <TLabel id="comment" className="mb-4" />
                  <Input type="text"
                    rows="8"
                    name="comment"
                    value={comment}
                    onChange={(e) => { handleCommentChange(e) }} />
                </FormGroup>
                {!editState && <TButton
                  id="saveComment"
                  disabled={comment.length == 0}
                  onClick={saveComment} />}
                {!editState && commentStatus.type == 'error' && <ErrAlert className="mb-0 mt-4" error={commentStatus.payload} />}
                {!editState && commentStatus.type == 'success' && <TAlert className="mb-0 mt-4" id="commentSuccess" color="success" />}
              </CardBody>
            </ProtectedComponent>
          </>
        )}

        {!creating && ticketHistory && (
          <CardBody className="Section">
            <TLabel id="history" className="mb-4" />
            {ticketHistory && ticketHistory.map(th => parseTicketHistory(th))}
          </CardBody>
        )}

        {createError && <ErrAlert className="mb-0 mt-4" error={createError} />}
        {updateError && <ErrAlert className="mb-0 mt-4" error={updateError} />}

        <CardFooter className="d-flex">
          {state !== 'creating' && (
            <>
              <TButton tag={RRNavLink}
                className="mr-4"
                id="newItv"
                to={{
                  pathname: "/interventions/new",
                  search: `?${new URLSearchParams({ ticketId: ticket.ticketId })}`
                }} />
              <TButton tag={RRNavLink}
                id="newSwap"
                to={{
                  pathname: "/swaps/new",
                  search: `?${new URLSearchParams({ ticketId: ticket.ticketId })}`
                }} />
            </>
          )}

          <div className="ml-auto">
            <ProtectedComponent rights={['desk_edit']}>
              {ticket && ticket.ticketStatusValue === 'done' && <TButton id="duplicate"
                onClick={duplicate}
                disabled={creating || updating}
                className="ml-2" />}
              {state === 'editing' && <TButton id="cancelEdit"
                onClick={cancelEdit}
                disabled={creating || updating}
                className="ml-2" />}
              {state === 'editing' && <TButton id="saveEdit"
                onClick={saveEdit}
                disabled={creating || updating}
                className="ml-2" />}
              {state === 'viewing' && <TButton id="edit"
                onClick={edit}
                disabled={creating || updating}
                className="ml-2" />}
              {state === 'creating' && <TButton id="create"
                onClick={create}
                disabled={creating || updating}
                className="ml-2" />}
            </ProtectedComponent>
          </div>
        </CardFooter>
      </Card >
    </>
  )
}

export default DeskDetails
