import React from 'react'
import { useParams, useLocation, useRouteMatch, Link, Redirect, Switch, NavLink as RRNavLink } from 'react-router-dom'
import { FormattedDate, FormattedTime } from 'react-intl'
import QRCode from 'qrcode.react'

import {
  Alert, Card, CardHeader, CardBody, CardFooter,
  Nav, NavItem,
  TabContent, TabPane, Button,
  Form, FormGroup, Label, Input, CustomInput,
  Table, ListGroup, ListGroupItem,
  Spinner,
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import { useFormatMessage } from 'hooks/intl.hooks'

import CustomSelect from 'components/CustomSelect'
import ProtectedComponent, { useHasRights }  from 'components/ProtectedComponent'
import SlotSelector from 'components/SlotSelector'
import Pagination from 'components/Pagination'
import TH from 'components/TH'
import { T, TLabel, TButton, ErrAlert, Toption, TAlert, TNavLink, TDatePicker } from 'components/TComponents'
import Route from 'components/Route'

import EquipmentLogRoute from './EquipmentLogRoute'

const EquipmentEdit = () => {
  const { api } = React.useContext(AppContext)
  const location = useLocation()
  const routeParams = useParams()
  const routeMatch = useRouteMatch()

  const [settings, setSettings] = React.useState()
  const [settingsLoading, setSettingsLoading] = React.useState(true)
  const [settingsError, setSettingsError] = React.useState()

  React.useEffect(() => {
    api.get('/equipments/settings', undefined, { equipmentId: routeParams.id })
      .then(response => setSettings(response))
      .catch(response => setSettingsError(response))
      .then(() => setSettingsLoading(false))
  }, [api, routeParams.id])

  const tabs = React.useMemo(() => {
    if (settings) {
      return Object.entries(settings.tabs).filter(([, active]) => active).map(([tabName]) => tabName)
    }
  }, [settings])

  if (settingsError) {
    return (
      <>
        <TNavLink id="returnToList" tag={Link} to={{
          pathname: "/equipments",
          state: location.state ? location.state.equipmentsState : undefined
        }} />
        <ErrAlert error={settingsError} className="mt-4 mb-0" />
      </>
    )
  }

  if (settingsLoading) {
    return (
      <>
        <TNavLink id="returnToList" tag={Link} to={{
          pathname: "/equipments",
          state: location.state ? location.state.equipmentsState : undefined
        }} />
        <Spinner color="primary" className="d-flex mx-auto mt-4" />
      </>
    )
  }

  return (
    <>
      <TNavLink id="returnToList" tag={Link} to={{
        pathname: "/equipments",
        state: location.state ? location.state.equipmentsState : undefined
      }} />
      <Nav tabs className="mb-3">
        <div className="navitem-container">
          {tabs.map(tabName => (
            <ProtectedComponent key={tabName}>
              <NavItem>
                <TNavLink
                  id={`menu.${tabName}`}
                  tag={RRNavLink}
                  activeClassName="active"
                  to={{
                    pathname: `/equipments/${routeParams.id}/${tabName}`,
                    state: location.state
                  }} />
              </NavItem>
            </ProtectedComponent>
          ))}
        </div>
      </Nav>

      <TabContent>
        <Switch>
          <Redirect exact from={`${routeMatch.path}/`} to={{ pathname: `${routeMatch.path}/${tabs[0]}`, state: location.state }} />
          {settings.tabs.identity && <TabPane tag={Route} exact path={`${routeMatch.path}/identity`} render={() => <Identity settings={settings.identity} />} />}
          {settings.tabs.sensors && <TabPane tag={Route} exact path={`${routeMatch.path}/sensors`} render={() => <Sensors settings={settings.sensors} />} />}
          {settings.tabs.interventions && <TabPane tag={Route} exact path={`${routeMatch.path}/interventions`} render={() => <Interventions settings={settings.interventions} />} />}
          {settings.tabs.swaps && <TabPane tag={Route} exact path={`${routeMatch.path}/swaps`} render={() => <Swaps settings={settings.swaps} />} />}
          {settings.tabs.crc && <TabPane tag={Route} exact path={`${routeMatch.path}/crc`} render={() => <CRC settings={settings.crc} />} />}
          {settings.tabs.status && <TabPane tag={Route} exact path={`${routeMatch.path}/status`} render={() => <Status settings={settings.status} />} />}
          {settings.tabs.logs && <TabPane tag={Route} path={`${routeMatch.path}/logs`} render={() => <EquipmentLogRoute settings={settings.status} id={routeParams.id} />} />}
          <Redirect to={`/error/`} />
        </Switch>
      </TabContent>
    </>
  )
}

const Identity = ({ settings }) => {
  const { api } = React.useContext(AppContext)
  const routeParams = useParams()
  const formatMessage = useFormatMessage()

  const [identity, setIdentity] = React.useState()
  const [identityLoading, setIdentityLoading] = React.useState(true)
  const [identityError, setIdentityError] = React.useState()
  const [availableSwaps, setAvailableSwaps] = React.useState([])
  const [availableSwapsLoading, setAvailableSwapsLoading] = React.useState(true)
  const [availableSwapsError, setAvailableSwapsError] = React.useState()
  const [tags, setTags] = React.useState()
  const [tagsSelected, setTagsSelected] = React.useState([])
  const [tagLoading, setTagLoading] = React.useState(true)
  const [tagLoadError, setTagLoadError] = React.useState()
  const [updatingTag, setUpdatingTag] = React.useState()
  const [updateTagError, setUpdateTagError] = React.useState()
  const [addresses, setAddresses] = React.useState()
  const [addressesLoading, setAddressesLoading] = React.useState(false)
  const [addressesLoadError, setAddressesLoadError] = React.useState()
  const [addressSelected, setAddressSelected] = React.useState()
  const [updatingAddress, setUpdatingAddress] = React.useState()
  const [updateAddressError, setUpdateAddressError] = React.useState()
  const [matrix, setMatrix] = React.useState()
  const [onEditMatrix, setOnEditMatrix] = React.useState(false)
  const [matrixBase, setMatrixBase] = React.useState()
  const [matrixLoading, setMatrixLoading] = React.useState(true)
  const [matrixLoadError, setMatrixLoadError] = React.useState()
  const [matrixEditing, setMatrixEditing] = React.useState(false)
  const [matrixEditError, setMatrixEditError] = React.useState()
  const [statusTo, setStatusTo] = React.useState('')
  const [updatingStatus, setUpdatingStatus] = React.useState()
  const [updateStatusError, setUpdateStatusError] = React.useState()
  const [businessList, setBusinessList] = React.useState([])
  const [businessSelected, setBusinessSelected] = React.useState()
  const [businessLoadingError, setBusinessLoadingError] = React.useState()
  const [businessLoading, setBusinessLoading] = React.useState(true)
  const [updatingBusiness, setUpdatingBusiness] = React.useState()
  const [updateBusinessError, setUpdateBusinessError] = React.useState()
  const [lastDateDone, setLastDateDone] = React.useState()
  const [lastKm, setLastKm] = React.useState()
  const [swapLink, setSwapLink] = React.useState()
  const [itvLink, setItvLink] = React.useState()

  // TODO: Spe Fluow
  const [equipmentIdTo, setEquipmentIdTo] = React.useState('')
  const [updatingSubscription, setUpdatingSubscription] = React.useState(false)
  const [updateSubscriptionError, setUpdateSubscriptionError] = React.useState()

  const hasLocationChangeRight = useHasRights('equipments-change_locate_business')

  React.useEffect(() => {
    api.get('/equipments/identityv2', undefined, { equipmentId: routeParams.id })
      //equipements menu déroulant
      .then(response => {
        setIdentity(response)
        setTagsSelected(response.tags.map(t => { return { tagId: t.tagId, tagIdentifier: t.tagIdentifier } }))
        setStatusTo(response.childStatusId ? `${response.statusId}.${response.childStatusId}` : `${response.statusId}`)
      })
      .catch(response => setIdentityError(response))
      .then(() => setIdentityLoading(false))

    api.get('/equipments/availableswaps', undefined, { equipmentId: routeParams.id })
      //equipements menu déroulant
      .then(response => {
        setAvailableSwaps(response)
      })
      .catch(response => setAvailableSwapsError(response))
      .then(() => setAvailableSwapsLoading(false))

    api.get('/equipments/matrixinfo', undefined, { equipmentId: routeParams.id })
      .then(response => {
        setMatrix(response)
        setMatrixBase(response)
      })
      .catch(response => setMatrixLoadError(response))
      .then(() => setMatrixLoading(false))

    api.get('/tags/list')
      .then(response => setTags(response))
      .catch(response => setTagLoadError(response))
      .then(() => setTagLoading(false))

    api.get('/business/list', undefined, { ipp: -1 })
      .then(response => setBusinessList(response.result))
      .catch(error => setBusinessLoadingError(error))
      .then(() => setBusinessLoading(false))
  }, [api, routeParams])

  React.useEffect(() => {
    if (businessSelected || (identity && identity.businessId)) {
      api.get('/business/addresseslist', undefined, { businessId: businessSelected || identity.businessId })
        .then(response => setAddresses(response))
        .catch(error => setAddressesLoadError(error))
        .then(() => setAddressesLoading(false))
    }
  }, [api, businessSelected, identity])

  React.useEffect(() => {
    if (identity) {
      setLastKm(((identity.swapLastDateDone > identity.itvLastDateDone) || !identity.itvLastDateDone) ? identity.swapLastKm : identity.itvLastKm)
      setLastDateDone(((identity.swapLastDateDone > identity.itvLastDateDone) || !identity.itvLastDateDone) ? identity.swapLastDateDone : identity.itvLastDateDone)
      setSwapLink((identity.swapLastDateDone > identity.itvLastDateDone) || (identity.swapLastDateDone && !identity.itvLastDateDone) ? identity.swapLastId : undefined)
      setItvLink((identity.itvLastDateDone > identity.swapLastDateDone) || (identity.itvLastDateDone && !identity.swapLastDateDone) ? identity.itvLastId : undefined)
    }
  }, [identity])

  const handleSubscriptionChange = React.useCallback(({ target: { value } }) => {
    setEquipmentIdTo(value)
    setUpdateSubscriptionError()
  }, [])

  const handleStatusChange = React.useCallback(({ target: { value } }) => {
    setStatusTo(value)
    setUpdateStatusError()
  }, [])

  const changeBusiness = React.useCallback(() => {
    api.post('/equipments/moveBusiness', { body: JSON.stringify({ equipmentId: parseInt(routeParams.id), businessId: businessSelected }) })
      .then(response => {
        setUpdatingBusiness(false)
        setIdentity(response)
        setUpdateBusinessError()
      })
      .catch(error => setUpdateBusinessError(error))
  }, [api, routeParams.id, businessSelected])

  const changeAddress = React.useCallback(() => {
    api.post('/equipments/moveAddress', { body: JSON.stringify({ equipmentId: parseInt(routeParams.id), businessId: businessSelected || identity.businessId, addressId: addressSelected }) })
      .then(response => {
        setUpdatingAddress(false)
        setIdentity(response)
        setUpdateAddressError()
      })
      .catch(error => setUpdateAddressError(error))
  }, [api, routeParams.id, businessSelected, addressSelected, identity])

  const handleBusinessChange = React.useCallback(e => {
    setBusinessSelected(e ? e.businessId : '')
  }, [])

  const handleAddressChange = React.useCallback(e => {
    setAddressSelected(e ? e.addressId : '')
  }, [])

  const handleTagChange = React.useCallback(e => {
    setTagsSelected(e === null ? [] : e.map(t => { return { tagId: t.value, tagIdentifier: t.label, colorHex: t.data } }))
  }, [])

  const changeTag = React.useCallback(() => {
    api.post('/equipments/tag', { body: JSON.stringify({ equipmentId: routeParams.id, tagId: tagsSelected.map(t => t.tagId) }) })
      .catch(error => setUpdateTagError(error))
      .then(response => {
        setUpdatingTag(false)
        setIdentity(response)
        setTagsSelected(response.tags.map(t => { return { tagId: t.tagId, tagIdentifier: t.tagIdentifier } }))
      })
  }, [api, routeParams.id, tagsSelected])

  const changeStatus = React.useCallback(() => {
    setUpdateStatusError()
    setUpdatingStatus(true)
    const status = statusTo.split('.')
    api.post('/equipments/status', { body: JSON.stringify({ equipmentId: routeParams.id, statusTo: status[0], childStatusTo: status[1] }) })
      .then(response => setIdentity({ ...identity, ...response }))
      .catch(error => setUpdateStatusError(error))
      .then(() => setUpdatingStatus(false))
  }, [api, identity, routeParams, statusTo])

  const changeSubscription = React.useCallback(() => {
    const { id: equipmentIdFrom, } = routeParams

    setUpdateSubscriptionError()
    setUpdatingSubscription(true)
    api.post('/equipments/subscription', { body: JSON.stringify({ equipmentIdTo, equipmentIdFrom }) })
      .then(response => {
        setIdentity(i => ({ ...i, ...response }))
        setEquipmentIdTo('')
      })
      .catch(response => setUpdateSubscriptionError(response))
      .then(() => setUpdatingSubscription(false))
  }, [api, equipmentIdTo, routeParams])

  const changeSubscriptionOutEquipment = React.useCallback(() => {
    const { id: equipmentIdFrom, } = routeParams

    setUpdateSubscriptionError()
    setUpdatingSubscription(true)
    api.post('/equipments/subscriptionOutEquipment', { body: JSON.stringify({ equipmentIdTo, equipmentIdFrom }) })
      .then(response => {
        setIdentity(i => ({ ...i, ...response }))
        setEquipmentIdTo('')
      })
      .catch(response => setUpdateSubscriptionError(response))
      .then(() => setUpdatingSubscription(false))
  }, [api, equipmentIdTo, routeParams])

  const onEdit = React.useCallback(() => {
    setOnEditMatrix(true)
    setMatrix(matrixBase)
  }, [matrixBase])

  const onMatrixSave = React.useCallback(() => {
    setMatrixEditError()

    const data = {
      equipmentId: routeParams.id,
      matrixId: matrix.matrixId,
      matrixInfos: matrix.matrixInfos
        .filter(matrixInfo => matrixInfo.editable)
        .map(matrixInfo => ({
          name: matrixInfo.name,
          value: matrixInfo.value,
          equipmentPartKm: matrixInfo.hasEquipmentPartKm ? Number(matrixInfo.equipmentPartKm) : undefined,
          datePart: matrixInfo.hasEquipmentPartKm ? matrixInfo.datePart : undefined
        })),
    }

    setMatrixEditing(true)
    return api.post('/equipments/matrixinfo', { body: JSON.stringify(data) })
      .then(response => {
        setMatrix(response)
        setMatrixBase(response)
        setOnEditMatrix(false)
      })
      .catch(error => setMatrixEditError(error))
      .then(() => setMatrixEditing(false))
  }, [api, routeParams, matrix])

  const handleMatrixInfoChange = React.useCallback(({ target: { name, value, }, }, attr = 'value') => {
    setMatrixEditError()
    setMatrix(matrix => ({
      ...matrix,
      matrixInfos: matrix.matrixInfos.map(inf => inf.name === name ? ({
        ...inf,
        [attr]: value
      }) : inf)
    }))
  }, [])

  const cancelEditMatrix = React.useCallback(() => {
    setOnEditMatrix(false)
    setMatrix(matrixBase)
  }, [matrixBase])

  const handleMatrixInfoDateChange = React.useCallback((date, name, attr) => {
    const MM = date.getMonth() + 1
    const dd = date.getDate()
    handleMatrixInfoChange({ target: { name, value: `${date.getFullYear()}-${MM < 10 ? '0' : ''}${MM}-${dd < 10 ? '0' : ''}${dd}` } }, attr)
  }, [handleMatrixInfoChange])

  const renderMatrixInfo = React.useCallback((matrixInfo) => {
    const { name } = matrixInfo

    if (!onEditMatrix) {
      return (
        <div key={name} className="mb-2">
          <T className="font-weight-bold" id={name} />
          {matrixInfo.type === 'text' && <span>{matrixInfo.value}</span>}
          {matrixInfo.type === 'date' && (
            matrixInfo.value ? <FormattedDate value={new Date(matrixInfo.value)} /> : <T id="date.NA" />
          )}

          {matrixInfo.hasEquipmentPartKm &&
            <div>
              <T id="equipmentPart" values={{
                datePart: matrixInfo.datePart ? new Date(matrixInfo.datePart) : undefined,
                equipmentPartKm: matrixInfo.equipmentPartKm || undefined,
              }} />
              {matrixInfo.interventionId &&
                <>
                  <T id="equipmentPart.interventionId" />
                  <Link to={`/interventions/${matrixInfo.interventionId}`}>#{matrixInfo.interventionId}</Link>
                </>
              }
            </div>}
        </div>
      )
    }

    return <div key={name} style={{ marginBottom: 13 }}>
      <TLabel id={name} />
      <Form onSubmit={e => e.preventDefault()} inline>
        <ListGroup className="w-100">
          <ListGroupItem>
            <FormGroup className="d-flex">
              <TLabel id="value" className="mr-2" />
              {matrixInfo.type === 'text' && <Input value={matrixInfo.value}
                name={name}
                disabled={matrixEditing || !matrixInfo.editable}
                onChange={handleMatrixInfoChange} />}
              {matrixInfo.type === 'date' && <TDatePicker
                disabled={matrixEditing || !matrixInfo.editable}
                customInput={<SlotSelector className="py-1" />}
                selected={new Date(matrixInfo.value)}
                onChange={date => handleMatrixInfoDateChange(date, name)}
                // TODO: all DatePicker dateFormat shoudl be in translation. Maybe event a custom DatPicker component for that
                dateFormat="eeee dd/MM/yyyy"
              />}
            </FormGroup>
          </ListGroupItem>

          {matrixInfo.editable && matrixInfo.hasEquipmentPartKm && <>
            <ListGroupItem>
              <FormGroup className="d-flex">
                <TLabel id="datePart.label" className="mr-2" />
                <TDatePicker
                  disabled={matrixEditing}
                  customInput={<SlotSelector className="py-1" />}
                  selected={matrixInfo.datePart ? new Date(matrixInfo.datePart) : undefined}
                  placeholderText={formatMessage({ id: 'datePart.placeholder' })}
                  onChange={date => handleMatrixInfoDateChange(date, name, 'datePart')}
                  // TODO: all DatePicker dateFormat shoudl be in translation. Maybe event a custom DatPicker component for that
                  dateFormat="eeee dd/MM/yyyy"
                />
              </FormGroup>
            </ListGroupItem>
            <ListGroupItem>
              <FormGroup className="d-flex">
                <TLabel id="equipmentPartKm.label" className="mr-2" />
                <Input value={matrixInfo.equipmentPartKm}
                  name={name}
                  disabled={matrixEditing}
                  onChange={e => handleMatrixInfoChange(e, 'equipmentPartKm')} />
              </FormGroup>
            </ListGroupItem>
          </>}
        </ListGroup>
      </Form>
    </div>
  }, [formatMessage, handleMatrixInfoChange, handleMatrixInfoDateChange, matrixEditing, onEditMatrix])

  if (identityError) {
    return <ErrAlert className="mt-4 mb-0" error={identityError} />
  }

  if (identityLoading) {
    return <Spinner className="d-flex mx-auto mt-4" color="primary" />
  }

  return (
    <>
      <Card style={{ marginBottom: 12 }}>
        <CardHeader><T id="velocareInfos" /></CardHeader>
        {identityError && <CardBody><ErrAlert className="mb-0" error={identityError} /></CardBody>}
        {identityLoading && <Spinner className="d-flex ml-auto mr-auto mt-4 mb-4" color="primary" />}
        {!identityLoading && !identityError && <CardBody>
          <div>
            {identity.checklistAlert && identity.checklistAlert.length > 0 && (
              <>
                {identity.checklistAlert.map(alert => (
                  <>
                    {alert.statusValue === "watch" && (
                      <Alert color="warning">
                        <T id="alertEquipment" />
                        <span className="d-block" key={`${alert.alertInterventionId}${alert.checkLabel}${alert.comments}`}>
                          <><T id="alertInterventionId" />
                            <Link to={`/interventions/${alert.alertInterventionId}`}>
                              {` #${alert.alertInterventionId} `}
                            </Link></>
                          {' '}
                          {alert.userQualityFirstname ? (
                            <T id="alertUserQuality"
                              values={{
                                value: <>{alert.userQualityFirstname}{' '}{alert.userQualityLastname}</>
                              }} />
                          ) : (
                            <T id="alertUser"
                              values={{
                                value: <>{alert.userDoneFirstname}{' '}{alert.userDoneLastname}</>
                              }} />
                          )}
                          <>- {alert.checkLabel}</>
                          <>{' '}<T id="alertCommentsWatch" />{alert.comments}</>
                        </span>
                      </Alert>
                    )}
                    {alert.statusValue === "nok" && (
                      <Alert color="danger">
                        <T id="alertEquipment" />
                        <span className="d-block" key={`${alert.alertInterventionId}${alert.checkLabel}${alert.comments}`}>
                          <><T id="alertInterventionId" />
                            <Link to={`/interventions/${alert.alertInterventionId}`}>
                              {` #${alert.alertInterventionId} `}
                            </Link></>
                          {' '}
                          {alert.userQualityFirstname ? (
                            <T id="alertUserQuality"
                              values={{
                                value: <>{alert.userQualityFirstname}{' '}{alert.userQualityLastname}</>
                              }} />
                          ) : (
                            <T id="alertUser"
                              values={{
                                value: <>{alert.userDoneFirstname}{' '}{alert.userDoneLastname}</>
                              }} />
                          )}
                          <>- {alert.checkLabel}</>
                          <>{' '}<T id="alertCommentsNok" />{alert.comments}</>
                        </span>
                      </Alert>
                    )}
                  </>
                ))}
              </>
            )}
          </div>
          <div className="d-flex">
            <div>
              <div style={{ marginBottom: 4 }}>
                <TLabel id="equipmentIdentifier" />
                <span>{identity.equipmentIdentifier}</span>
              </div>
              <div style={{ marginBottom: 4 }}>
                <TLabel id="categoryName" />
                <span>{identity.categoryName}</span>
              </div>
              <div style={{ marginBottom: 4 }}>
                <TLabel id="familyName" />
                <span>{identity.familyName}</span>
              </div>
              <div style={{ marginBottom: 4 }}>
                <TLabel id="businessName" />
                <span>{identity.businessName}</span>
              </div>
            </div>
            <div className="ml-5 d-flex flex-column">
              <QRCode value={identity.equipmentIdentifier} size="96" />
              <span>{identity.equipmentIdentifier}</span>
            </div>
          </div>
          <ProtectedComponent rights={['equipments-change_business']}>
            {businessLoading && <Spinner className="d-flex ml-auto mr-auto mt-4 mb-4" color="primary" />}
            {!businessLoading && !businessLoadingError && (
              <FormGroup tag="fieldset" style={{ marginBottom: 8 }}>
                <TLabel for="changeBusiness" id="changeBusiness" />
                <div className="d-flex">
                  <div style={{ flex: 1 }}>
                    <CustomSelect
                      inputId="changeBusiness"
                      name="changeBusiness"
                      options={businessList}
                      onChange={handleBusinessChange}
                      placeholder={<T id="changeBusinessPlaceholder" />}
                      isClearable
                      value={businessList && businessList.length > 0 && businessList.filter(b => b.businessId === businessSelected) || identity.business}
                      getOptionLabel={option => option.businessName}
                      getOptionValue={option => option.businessId} />
                  </div>
                  <TButton className="ml-2 py-2"
                    id="changeBusinessButton"
                    onClick={() => changeBusiness()}
                    disabled={!businessSelected || businessSelected === identity.businessId || updatingBusiness} />
                </div>
              </FormGroup>
            )}
            {updateBusinessError && <ErrAlert className="mt-2 mb-2" error={`businessChange.${updateBusinessError.code}`} />}
          </ProtectedComponent>
          {addressesLoadError && <CardBody><ErrAlert className="mb-0" error={addressesLoadError} /></CardBody>}
          {addressesLoading && <Spinner className="d-flex ml-auto mr-auto mt-4 mb-4" color="primary" />}
          {!addressesLoading && !addressesLoading && identity && identity.businessId && (
            <FormGroup tag="fieldset" style={{ marginBottom: 8 }}>
              <TLabel for="changeAddress" id="changeAddress" />
              <div className="d-flex">
                <div style={{ flex: 1 }}>
                  <CustomSelect
                    inputId="changeAddress"
                    name="changeAddress"
                    options={addresses}
                    onChange={handleAddressChange}
                    placeholder={<T id="changeAddressesPlaceholder" />}
                    isClearable
                    isDisabled={!hasLocationChangeRight}
                    value={addresses && addresses.length > 0 && (addressSelected ? addresses.filter(a => a.addressId === addressSelected) : addresses.filter(a => a.addressId === identity.addressId))}
                    getOptionLabel={option => option.addressLabel}
                    getOptionValue={option => option.addressId} />
                </div>
                <ProtectedComponent rights={['equipments-change_locate_business']}>
                  <TButton className="ml-2 py-2"
                    id="changeAddressButton"
                    onClick={() => changeAddress()}
                    disabled={!addressSelected || addressSelected === identity.addressId || updatingAddress} />
                </ProtectedComponent>
              </div>
            </FormGroup>
          )}
          {updateAddressError && <ErrAlert className="mt-2 mb-2" error={`addressChange.${updateAddressError.code}`} />}
          <ProtectedComponent rights={['equipments-edit_tag']}>
            {tagLoadError && <CardBody><ErrAlert className="mb-0" error={tagLoadError} /></CardBody>}
            {tagLoading && <Spinner className="d-flex ml-auto mr-auto mt-4 mb-4" color="primary" />}
            {!tagLoading && !tagLoadError &&
              <FormGroup tag="fieldset" style={{ marginBottom: 8 }}>
                <TLabel for="tag" id="tag" />
                <div className="d-flex">
                  <div style={{ flex: 1 }}>
                    <CustomSelect
                      inputId="tag"
                      name="tag"
                      isMulti
                      isSearchable
                      style={{ flex: 1 }}
                      options={tags && tags.result.map((r) => ({
                        label: r.tagIdentifier,
                        value: r.tagId,
                        data: r.colorHex
                      }))}
                      onChange={handleTagChange}
                      placeholder={<T id="equipmentTagsPlacholder" />}
                      value={tagsSelected && tagsSelected.length > 0 && tags && tags.result.map((r) => ({ label: r.tagIdentifier, value: r.tagId, data: r.colorHex })).filter(t => tagsSelected.find(it => it.tagId === t.value))}
                      formatOptionLabel={option => option.data
                        ? <div className="d-flex flex-row align-items-center"><div style={{ backgroundColor: option.data }} className="mr-2 color-tag" /><>{option.label}</></div>
                        : <div className="d-flex flex-row align-items-center"><div style={{ backgroundColor: '#fff' }} className="mr-2 color-tag" /><>{option.label}</></div>}
                    />
                  </div>
                  <TButton className="ml-2 py-2"
                    id="changeTag"
                    onClick={() => changeTag()}
                    disabled={updatingTag || (tagsSelected && tagsSelected.length !== 0 && JSON.stringify(identity.tags.sort((a, b) => a.tagId - b.tagId)) === JSON.stringify(tagsSelected.sort((a, b) => a.tagId - b.tagId)))} />
                </div>
              </FormGroup>
            }
            {updateTagError && <ErrAlert className="mt-2 mb-0" error={updateTagError} />}
          </ProtectedComponent>
          <FormGroup tag="fieldset" style={{ marginBottom: 8 }}>
            <Label style={{ marginBottom: 4 }} for="statusValue"><T id="statusValue" /></Label>
            <div className="d-flex align-items-center">
              <CustomInput id="statusValue"
                className="pt-2"
                type="select"
                name="statusTo"
                value={statusTo}
                onChange={handleStatusChange}
                disabled={updatingStatus}>
                <Toption value={''} disabled={true} id="statusPlaceholder" />
                {identity.childStatusId
                  ? (
                    <Toption id={{
                      id: 'isCurrentStatus',
                      values: { status: formatMessage({ id: `equipmentStatus.${identity.statusValue}.${identity.childStatusValue}`, raw: true }) }
                    }}
                    value={`${identity.statusId}.${identity.childStatusId}`} />
                  ) : (
                    <Toption id={{
                      id: 'isCurrentStatus',
                      values: { status: formatMessage({ id: `equipmentStatus.${identity.statusValue}`, raw: true }) }
                    }}
                    value={`${identity.statusId}`} />
                  )}
                {identity.availableStatus && identity.availableStatus.map(status =>
                  status.children && status.children.length > 0
                    ? (
                      <optgroup key={status.statusId} label={formatMessage({ id: `equipmentStatus.${status.statusValue}`, raw: true })}>
                        {status.children.map(child => (
                          <Toption key={`${status.statusValue}.${child.childStatusValue}`}
                            raw id={`equipmentStatus.${status.statusValue}.${child.childStatusValue}`}
                            value={`${status.statusId}.${child.childStatusId}`} />
                        ))}
                      </optgroup>
                    ) : (
                      <Toption key={status.statusId}
                        raw id={`equipmentStatus.${status.statusValue}`}
                        value={`${status.statusId}`} />
                    )
                )}
              </CustomInput>
              <Button className="ml-2 py-2"
                onClick={() => changeStatus()}
                disabled={updatingStatus || String(identity.statusId) === String(statusTo)}>
                <T id="applyStatus" />
              </Button>
            </div>
            {updateStatusError && <ErrAlert className="mt-2 mb-0" error={updateStatusError} />}
          </FormGroup>

          <div style={{ marginBottom: 4 }}>
            <TLabel id="hasLivemodule" />
            {identity.hasLivemodule
              ? <T id="hasLivemoduleYes" />
              : <T id="hasLivemoduleNo" />}
          </div>

          <div style={{ marginBottom: 4 }}>
            <TLabel id="dateCreated" />
            {identity.dateCreated ? (
              <FormattedDate value={new Date(identity.dateCreated)} />
            ) : (
              <T id="dateCreated.NA" />
            )}
          </div>
          <div style={{ marginBottom: 4 }}>
            <TLabel id="dateUpdated" />
            {identity.dateUpdated
              ? <FormattedDate value={new Date(identity.dateUpdated)} />
              : <T id="noLastDateUpdate" />}
          </div>
          <div style={{ marginBottom: 4 }}>
            <TLabel id="km" />
            {lastKm
              ? <>
                {lastKm}
                {!!swapLink && (
                  <>
                    <T id="linkToSwap" />
                    <Link to={`/swaps/${swapLink}`}>#{swapLink}</Link>
                  </>
                )}
                {!!itvLink && (
                  <>
                    <T id="linkToItv" />
                    <Link to={`/interventions/${itvLink}`}>#{itvLink}</Link>
                  </>
                )}
                <T id="lastDateDone" />
                {lastDateDone && <FormattedDate value={new Date(lastDateDone)} />}
              </>
              : <T id="noLastKm" />
            }
          </div>
        </CardBody>}
      </Card>

      {settings.hasSubscription && !identityLoading && !identityError && <Card style={{ marginBottom: 12 }}>
        <CardHeader><T id="equipmentSubscription" /></CardHeader>
        <CardBody>
          <div style={{ marginBottom: 4 }}>
            <TLabel id="subscriptionId" />
            <span>{identity.subscriptionId}</span>
          </div>
          <ProtectedComponent rights={['equipments_swap']}>
            <FormGroup tag="fieldset" style={{ marginBottom: 8 }}>
              <Label style={{ marginBottom: 4 }} for="changeEquipment"><T id="changeEquipment" /></Label>
              <div className="d-flex align-items-center">
                <div style={{ flex: 1 }}>
                  <CustomSelect
                    style={{ flex: 1 }}
                    inputId="changeEquipment"
                    name="changeEquipment"
                    options={availableSwaps}
                    onChange={e => handleSubscriptionChange({ target: { value: e && e.equipmentId } })}
                    placeholder={<T id="equipmentIdentifierPlaceholder" />}
                    value={availableSwaps.filter(f => f.equipmentId === equipmentIdTo)}
                    isDisabled={updatingSubscription}
                    getOptionLabel={option => option.equipmentIdentifier}
                    getOptionValue={option => option.equipmentId} />
                </div>
                <Button className="ml-2 py-2"
                  onClick={() => identity.statusId === 14 ? changeSubscriptionOutEquipment() : changeSubscription()}
                  disabled={updatingSubscription || !equipmentIdTo}>
                  <T id="applyStatus" />
                </Button>
              </div>
              {updateSubscriptionError && (
                <>
                  {updateSubscriptionError.code === 'wrongEquipmentStatus' ? (
                    <ErrAlert error={`wrongUpdateStatusSubscription`} className="mt-2 mb-0" />
                  ) : (
                    <ErrAlert error={updateSubscriptionError} className="mt-2 mb-0" />
                  )}
                </>
              )}

            </FormGroup>
          </ProtectedComponent>
        </CardBody>
      </Card>}

      <Card style={{ marginBottom: 12 }}>
        <CardHeader><T id="equipmentInfos" /></CardHeader>
        {matrixLoadError && <CardBody><ErrAlert className="mb-0" error={matrixLoadError} /></CardBody>}
        {matrixLoading && <Spinner className="d-flex ml-auto mr-auto mt-4 mb-4" color="primary" />}
        {!matrixLoading && !matrixLoadError && <CardBody>
          {matrix.matrixInfos
            .sort((a, b) => a.order - b.order)
            .map(renderMatrixInfo)}
          {matrixEditError && <ErrAlert className="mt-2 mb-0" error={matrixEditError} />}
        </CardBody>}
        {!matrixLoadError && <CardFooter className="d-flex">
          {onEditMatrix && <TButton id="cancel" disabled={matrixLoading || matrixEditing} onClick={cancelEditMatrix} color="danger" />}
          {!onEditMatrix && <TButton id="edit" disabled={matrixLoading || matrixEditing} onClick={onEdit} className="ml-auto" />}
          {onEditMatrix && <TButton id="save" disabled={matrixLoading || matrixEditing} onClick={onMatrixSave} className="ml-auto" color="primary" spin={matrixEditing} />}
        </CardFooter>}
      </Card>
    </>
  )
}

const Sensors = () => {
  const { api, constants } = React.useContext(AppContext)
  const routeParams = useParams()

  const [data, setData] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [loadError, setLoadError] = React.useState()
  const [acting, setActing] = React.useState('')
  const [actSuccess, setActSuccess] = React.useState()
  const [actError, setActError] = React.useState()

  React.useEffect(() => {
    api.get('/equipments/sensors', undefined, { equipmentId: routeParams.id })
      .then(response => setData(response))
      .catch(response => setLoadError(response))
      .then(() => setLoading(false))
  }, [api, routeParams])

  const equipmentStatus = React.useMemo(() =>
    constants.equipmentStatus.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {})
  , [constants.equipmentStatus])

  const hubActions = React.useCallback(action => {
    setActError()
    setActing(action)
    setActSuccess(false)
    Promise.all([
      api.post('/fluow/hubActions', { body: JSON.stringify({ equipmentId: routeParams.id, action }) }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(() => setActSuccess(action))
      .catch(response => setActError(response))
      .then(() => setActing(''))
  }, [api, routeParams])

  // eslint-disable-next-line react/display-name
  const HubAction = React.useMemo(() => ({ name, ...props }) =>
    <TButton
      id={`hubActions.${name}`}
      onClick={() => hubActions(name)}
      disabled={acting.length > 0}
      spin={acting === name}
      className="m-2"
      {...props} />
  , [acting, hubActions])

  if (loadError) {
    return <ErrAlert className="mt-4 mb-0" error={loadError} />
  }

  if (loading) {
    return <Spinner className="d-flex mx-auto mt-4" color="primary" />
  }

  return (
    <>
      <Card>
        <CardHeader><T id="hubActions.header" /></CardHeader>
        <CardBody className="p-3">
          {actError && <ErrAlert error={actError} />}
          <HubAction name="activateModeAlive" />
          <HubAction name="deactivateModeAlive" />
          <HubAction name="activateModeStorage" />
          <HubAction name="runAutotest" />
          <HubAction name="modifyEbikeUsageStateMaintenance" disabled={equipmentStatus.maintenance !== data.statusId} />
          <HubAction name="runGGmao" />
          {actSuccess && <TAlert id={`hubActions.${actSuccess}.success`} color="primary" className="mb-0 mt-4" toggle={() => setActSuccess()} />}
        </CardBody>
      </Card>
      <Table responsive striped hover className="mb-3">
        <thead>
          <tr>
            <TH colName="name" className={['border-top-0']} />
            <TH colName="value" className={['border-top-0']} />
            <TH colName="dateUpdated" className={['border-top-0']} />
          </tr>
        </thead>
        <tbody>
          {data.sensors.map(sensor =>
            <tr key={sensor.name}>
              <td><T id={sensor.name} /></td>
              <td>{sensor.value}</td>
              <td>{sensor.dateUpdated
                ? <><FormattedDate value={new Date(sensor.dateUpdated)} />{' '}<FormattedTime value={new Date(sensor.dateUpdated)} /></>
                : <T id="noDatePlanned" />}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  )
}

const Interventions = () => {
  const { api } = React.useContext(AppContext)
  const routeParams = useParams()

  const [interventions, setInterventions] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [loadError, setLoadError] = React.useState()

  const difficultyClass = React.useCallback((value) => {
    switch (value) {
    case 'easy':
      return 'bg-success'
    case 'medium':
      return 'bg-warning'
    case 'hard':
      return 'bg-danger'
    }
  }, [])

  React.useEffect(() => {
    api.get('/equipments/interventions', undefined, { equipmentId: routeParams.id })
      .then(response => setInterventions(response))
      .catch(response => setLoadError(response))
      .then(() => setLoading(false))
  }, [api, routeParams])

  const getInterventions = React.useCallback(params => {
    return api.get('/equipments/interventions', undefined, {
      equipmentId: routeParams.id,
      p: interventions ? interventions.currentPage : 1,
      'order[column]': interventions ? interventions.order.column : undefined,
      'order[dir]': interventions ? interventions.order.dir : undefined,
      ...params,
    })
      .then(response => setInterventions(response))
      .catch(response => setLoadError(response))
      .then(() => setLoading(false))
  }, [api, interventions, routeParams])

  const order = React.useCallback((e, column) => {
    e.preventDefault()

    getInterventions({
      'order[column]': column,
      'order[dir]': interventions.order.column === column && interventions.order.dir === `asc` ? `desc` : `asc`,
    })
  }, [getInterventions, interventions])

  const handlePageClick = React.useCallback(p => getInterventions({ p }), [getInterventions])

  if (loadError) {
    return <ErrAlert className="mt-4 mb-0" error={loadError} />
  }

  if (loading) {
    return <Spinner className="d-flex mx-auto mt-4" color="primary" />
  }

  return (
    <Table responsive striped hover className="mb-3 mt-2">
      <thead>
        <tr><TH colSpan="100" className={['border-top-0']}
          colName="count"
          colValues={{ total: interventions.total }} />
        </tr>
      </thead>
      <thead>
        <tr>
          <TH colName="interventionId" />
          <TH colName="difficultyValue" />
          <TH colName="userPlanned" />
          <TH colName="address" />
          <TH colName="businessName" />
          <TH colName="datePlanned" order={interventions.order} sort={order} />
          <TH colName="duration" />
          <TH colName="statusValue" />
          <TH />
        </tr>
      </thead>
      <tbody>
        {interventions.result.map(intervention =>
          <tr key={intervention.interventionId}>
            <td>{intervention.interventionId}</td>
            <td className={difficultyClass(intervention.difficultyValue)}></td>
            <td>{intervention.userPlanned
              ? intervention.userPlanned
              : <T id="noUserPlanned" />}
            </td>
            <td>{intervention.address}</td>
            <td>{intervention.businessName}</td>
            <td>{intervention.datePlanned
              ? <><FormattedDate value={new Date(intervention.datePlanned)} />{' '}<FormattedTime value={new Date(intervention.datePlanned)} /></>
              : <T id="noDatePlanned" />}
            </td>
            <td>{intervention.duration}</td>
            <td><T raw id={`interventionStatus.${intervention.statusValue}`} /></td>
            <td><TButton id="view" className="ml-auto"
              tag={RRNavLink}
              to={`/interventions/${intervention.interventionId}`} />
            </td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr><td colSpan="100">
          <Pagination totalPage={interventions.totalPage}
            currentPage={interventions.currentPage}
            onPageClick={page => handlePageClick(page)} /></td></tr>
      </tfoot>
    </Table>
  )
}

const Swaps = () => {
  const { api } = React.useContext(AppContext)
  const routeParams = useParams()

  const [swaps, setSwaps] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [loadError, setLoadError] = React.useState()

  React.useEffect(() => {
    api.get('/equipments/swaps', undefined, { equipmentId: routeParams.id })
      .then(response => setSwaps(response))
      .catch(response => setLoadError(response))
      .then(() => setLoading(false))
  }, [api, routeParams])

  const getSwaps = React.useCallback(params => {
    return api.get('/equipments/swaps', undefined, {
      equipmentId: routeParams.id,
      p: swaps ? swaps.currentPage : 1,
      'order[column]': swaps ? swaps.order.column : undefined,
      'order[dir]': swaps ? swaps.order.dir : undefined,
      ...params,
    })
      .then(response => setSwaps(response))
      .catch(response => setLoadError(response))
      .then(() => setLoading(false))
  }, [api, swaps, routeParams])

  const order = React.useCallback((e, column) => {
    e.preventDefault()

    getSwaps({
      'order[column]': column,
      'order[dir]': swaps.order.column === column && swaps.order.dir === `asc` ? `desc` : `asc`,
    })
  }, [getSwaps, swaps])

  const handlePageClick = React.useCallback(p => getSwaps({ p }), [getSwaps])

  if (loadError) {
    return <ErrAlert className="mt-4 mb-0" error={loadError} />
  }

  if (loading) {
    return <Spinner className="d-flex mx-auto mt-4" color="primary" />
  }

  return (
    <Table responsive striped hover className="mb-3 mt-2">
      <thead>
        <tr><TH colSpan="100" className={['border-top-0']}
          colName="count"
          colValues={{ total: swaps.total }} />
        </tr>
      </thead>
      <thead>
        <tr>
          <TH colName="swapId" />
          <TH colName="userPlanned" />
          <TH colName="address" />
          <TH colName="businessName" />
          <TH colName="datePlanned" order={swaps.order} sort={order} />
          <TH colName="statusValue" />
          <TH />
        </tr>
      </thead>
      <tbody>
        {swaps.result.map(swap =>
          <tr key={swap.swapId}>
            <td>{swap.swapId}</td>
            <td>{swap.userPlanned
              ? swap.userPlanned
              : <T id="noUserPlanned" />}
            </td>
            <td>{swap.address}</td>
            <td>{swap.businessName}</td>
            <td>{swap.datePlanned
              ? <><FormattedDate value={new Date(swap.datePlanned)} />{' '}<FormattedTime value={new Date(swap.datePlanned)} /></>
              : <T id="noDatePlanned" />}
            </td>
            <td><T raw id={`swapStatus.${swap.statusValue}`} /></td>
            <td><TButton id="view" className="ml-auto"
              tag={RRNavLink}
              to={`/swaps/${swap.swapId}`} />
            </td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr><td colSpan="100">
          <Pagination totalPage={swaps.totalPage}
            currentPage={swaps.currentPage}
            onPageClick={page => handlePageClick(page)} /></td></tr>
      </tfoot>
    </Table>
  )
}

const CRC = () => {
  const { api } = React.useContext(AppContext)
  const routeParams = useParams()

  const [crcs, setCrcs] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [loadError, setLoadError] = React.useState()

  React.useEffect(() => {
    api.get('/equipments/crcinfos', undefined, { equipmentId: routeParams.id })
      .then(response => setCrcs(response))
      .catch(response => setLoadError(response))
      .then(() => setLoading(false))
  }, [api, routeParams])

  const getCrcs = React.useCallback(params => {
    return api.get('/equipments/crcinfos', undefined, {
      equipmentId: routeParams.id,
      p: crcs ? crcs.currentPage : 1,
      ...params,
    })
      .then(response => setCrcs(response))
      .catch(response => setLoadError(response))
      .then(() => setLoading(false))
  }, [api, crcs, routeParams])

  const handlePageClick = React.useCallback(p => getCrcs({ p }), [getCrcs])

  if (loadError) {
    return <ErrAlert className="mt-4 mb-0" error={loadError} />
  }

  if (loading || !crcs.result) {
    return <Spinner className="d-flex mx-auto mt-4" color="primary" />
  }

  return (
    <Table responsive striped hover className="mb-3 mt-2">
      <thead>
        <tr><TH colSpan="100" className={['border-top-0']}
          colName="count"
          colValues={{ total: crcs.total }} />
        </tr>
      </thead>
      <thead>
        <tr>
          <TH colName="crcInfoId" />
          <TH colName="equipmentInfos" />
          <TH colName="dateEvent" />
        </tr>
      </thead>
      <tbody>
        {crcs.result.map(crc =>
          <tr key={crc.crcInfoId}>
            <td>{crc.crcInfoId}</td>
            <td>{crc.equipmentInfos}</td>
            <td><FormattedDate value={new Date(crc.dateEvent)} />{' '}<FormattedTime value={new Date(crc.dateEvent)} /></td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr><td colSpan="100">
          <Pagination totalPage={crcs.totalPage}
            currentPage={crcs.currentPage}
            onPageClick={page => handlePageClick(page)} /></td></tr>
      </tfoot>
    </Table>
  )
}

const Status = () => {
  const { api } = React.useContext(AppContext)
  const routeParams = useParams()

  const [statuss, setStatuss] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [loadError, setLoadError] = React.useState()

  React.useEffect(() => {
    api.get('/equipments/statushistory', undefined, { equipmentId: routeParams.id })
      .then(response => setStatuss(response))
      .catch(response => setLoadError(response))
      .then(() => setLoading(false))
  }, [api, routeParams])

  const getStatus = React.useCallback(param => {
    return api.get('/equipments/statushistory', undefined, {
      equipmentId: routeParams.id,
      p: statuss ? statuss.currentPage : 1,
      ...param,
    })
      .then(response => setStatuss(response))
      .catch(response => setLoadError(response))
      .then(() => setLoading(false))
  }, [api, routeParams, statuss])

  const handlePageClick = React.useCallback(p => getStatus({ p }), [getStatus])

  if (loadError) {
    return <ErrAlert className="mt-4 mb-0" error={loadError} />
  }

  if (loading || !statuss.result) {
    // TODO
    return <Spinner className="d-flex mx-auto mt-4" color="primary" />
  }

  return (
    <Table responsive striped hover className="mb-3 mt-2">
      <thead>
        <tr><TH colSpan="100" className={['border-top-0']}
          colName="count"
          colValues={{ total: statuss.total }} />
        </tr>
      </thead>
      <thead>
        <tr>
          <TH colName="historyId" />
          <TH colName="historySrc" />
          <TH colName="userName" />
          <TH colName="statusBefore" />
          <TH colName="statusAfter" />
          <TH colName="swapInfo" />
          <TH colName="dateCreated" />
        </tr>
      </thead>
      <tbody>
        {statuss.result.map(status =>
          <tr key={status.historyId}>
            <td>{status.historyId}</td>
            <td>{status.historySrc}</td>
            <td>{status.userName ? status.userName : <T id="noUserPlanned" />}</td>
            <td><T raw id={`equipmentStatus.${status.statusBefore}`} /></td>
            <td><T raw id={`equipmentStatus.${status.statusAfter}`} /></td>
            <td>{status.swapInfo}</td>
            <td><FormattedDate value={new Date(status.dateCreated)} />{' '}<FormattedTime value={new Date(status.dateCreated)} /></td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr><td colSpan="100">
          <Pagination totalPage={statuss.totalPage}
            currentPage={statuss.currentPage}
            onPageClick={page => handlePageClick(page)} /></td></tr>
      </tfoot>
    </Table>
  )
}

export default EquipmentEdit
