import React from 'react'
import { Link, NavLink as RRNavLink, } from 'react-router-dom'
import { FormattedDate, FormattedTime } from 'react-intl'

import {
  Alert, Button,
  Card, CardHeader, CardBody,
  Modal, ModalBody, ModalFooter,
  Label, Input,
  Dropdown, DropdownToggle, DropdownItem, DropdownMenu,
  ListGroup, ListGroupItem,
  Spinner
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import { T, TNavLink, TInput, Toption, ErrAlert } from 'components/TComponents'

const SelectTechcenterPopup = ({ isOpen, onSubmit, onDismiss, buttonSubmitDisabled }) => {
  const { api } = React.useContext(AppContext)

  const [techcenters, setTechcenters] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()

  const [techcenterId, setTechcenterId] = React.useState('')

  React.useEffect(() => {
    api.get('/fluow/assignrequtechcenters')
      .then(response => setTechcenters(response))
      .catch(response => setError(response))
      .then(() => setLoading(false))
  }, [api])

  // TODO: handle error

  return (
    <Modal isOpen={isOpen} fade={true} toggle={onDismiss}>
      <ModalBody>
        <Label className="mb-0 mr-3" for="techcenterpopup.techcenterId"><T id="techcenterpopup.techcenterIdLabel" /></Label>
        <Input type="select"
          id="techcenterpopup.techcenterId"
          name="baseId"
          disabled={loading}
          value={techcenterId}
          onChange={e => setTechcenterId(e.target.value)}>
          <Toption id="techcenterpopup.techcenterIdPlaceholder" disabled={true} value={''} />
          {techcenters && techcenters.map(techcenter =>
            <option key={techcenter.techcenterId} value={techcenter.techcenterId}>{techcenter.techcenterName} - {techcenter.address}</option>)}
        </Input>
        {error && <Alert className="mb-0 mt-2" color="danger"><T raw id={`error.${error}`} /></Alert>}
      </ModalBody>
      <ModalFooter>
        <Button disabled={loading} onClick={onDismiss}>
          <T id="techcenterpopup.cancel" />
        </Button>
        <Button disabled={loading || !techcenterId || buttonSubmitDisabled} className="ml-2" color="primary" onClick={() => onSubmit(Number(techcenterId))}>
          {loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
          <T id="techcenterpopup.valid" />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const AssignmentRequest = ({ match, location }) => {
  const { api, constants } = React.useContext(AppContext)

  const [assignment, setAssignment] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()
  const [assignmentEquipments, setAssignmentEquipments] = React.useState([])
  const [assignmentEquipmentsLoading, setAssignmentEquipmentsLoading] = React.useState(true)
  const [assignmentEquipmentsError, setAssignmentEquipmentsError] = React.useState()

  const [askingTechcenter, setAskingTechcenter] = React.useState(false)
  const [buttonSubmitDisabled, setButtonSubmitDisabled] = React.useState(false)
  const [assigning, setAssigning] = React.useState(false)
  const [assignError, setAssignError] = React.useState()

  // TODO: from old states
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const [selectedEquipment, setSelectedEquipment] = React.useState()
  const [searchEquipment, setSearchEquipment] = React.useState('')
  const [filteredEquipments, setFilteredEquipments] = React.useState()
  const [editEquipment, setEditEquipment] = React.useState()

  React.useEffect(() => {
    api.get('/fluow/assignRequDetails', undefined, { assignRequId: match.params.id })
      .then(response => {
        setAssignment(response)
        setLoading(false)
        return api.get('/fluow/assignRequEquipments', undefined, {
          categoryId: response.categoryId,
          familyId: response.familyId,
          statusId: constants.equipmentStatus.find(s => s.key === 'available').value
        })
          .then(equipments => setAssignmentEquipments(equipments))
          .catch(response => setAssignmentEquipmentsError(response))
          .then(() => setAssignmentEquipmentsLoading(false))
      })
      .catch(response => {
        setError(response)
        setLoading(false)
      })
  }, [api, constants.equipmentStatus, match.params.id])

  const assign = React.useCallback((equipment, techcenterId) => {
    setAssigning(true)
    setAssignError()
    return api.post('/fluow/assignrequ', {
      body: JSON.stringify({
        assignRequId: assignment.assignRequId,
        equipmentStatusId: assignment.equipmentStatusId,
        equipmentId: equipment.equipmentId,
        techcenterId
      })
    })
      .then(() => {
        return api.get('/fluow/assignRequDetails', undefined, { assignRequId: match.params.id })
          .then(response => {
            setAssignment(response)
            return api.get('/fluow/assignRequEquipments', undefined, {
              categoryId: response.categoryId,
              familyId: response.familyId,
              statusId: constants.equipmentStatus.find(s => s.key === 'available').value
            })
              .then(response => { setAssignmentEquipments(response) })
          })
          .catch(response => setError(response))
          .then(() => {
            setSelectedEquipment()
            setEditEquipment(false)
          })
      })
      .catch(setAssignError)
      .then(() => setAssigning(false))
  }, [api, assignment, constants.equipmentStatus, match.params.id])

  const handleSelectTechcenterSubmit = React.useCallback(techcenterId => {
    setButtonSubmitDisabled(true)
    assign(assignment, techcenterId)
      .then(() => setAskingTechcenter(false))
  }, [assign, assignment])

  const handleSelectTechcenterDismiss = React.useCallback(() => {
    setButtonSubmitDisabled(false)
    setAskingTechcenter(false)
  }, [])

  React.useEffect(() => {
    setFilteredEquipments((searchEquipment
      ? assignmentEquipments.filter(eq => eq.equipmentIdentifier.includes(searchEquipment))
      : assignmentEquipments)
      .sort(({ equipmentIdentifier: a }, { equipmentIdentifier: b }) => a > b ? -1 : a < b ? 1 : 0))
  }, [assignmentEquipments, searchEquipment])

  React.useEffect(() => {
    setAssignError()
  }, [selectedEquipment, editEquipment])

  if (loading) {
    return <>
      <TNavLink id="returnToList" tag={Link}
        to={{
          pathname: '/fluow/assignments',
          state: location.state ? location.state.assignmentsState : undefined
        }} />
      <Spinner className="d-flex ml-auto mr-auto" color="primary" />
    </>
  }

  if (error) {
    return <>
      <TNavLink id="returnToList" tag={Link}
        to={{
          pathname: '/fluow/assignments',
          state: location.state ? location.state.assignmentsState : undefined
        }} />
      <ErrAlert error={error} />
    </>
  }

  const toggleEditEquipment = assignment && (!assignment.equipmentIdentifier || editEquipment)

  const canEditEquipment = ['preparation', 'maintenance'].includes(assignment.equipmentStatus) && ['preparation', '', undefined, null].includes(assignment.equipmentStatusBeforeMaintenance)
  const canSubmitIssue = !toggleEditEquipment && ['preparation', 'ready', 'deliver'].includes(assignment.equipmentStatus)
  const canDefineAsReady = !toggleEditEquipment && ['preparation'].includes(assignment.equipmentStatus)

  return (
    <>
      <TNavLink id="returnToList" tag={Link}
        to={{
          pathname: '/fluow/assignments',
          state: location.state ? location.state.assignmentsState : undefined
        }} />
      <Card className="mb-5">
        <CardHeader><span className="h5"><T id="title" /></span></CardHeader>
        <CardBody className="pb-0">
          <T id="dateRead" />
          {assignment.dateRead
            ? <><FormattedDate value={new Date(assignment.dateRead)} />{' '}<FormattedTime value={new Date(assignment.dateRead)} /></>
            : <T id="noDateRead" />}
        </CardBody>
        <CardBody className="border-bottom border-dark">
          <p className="h5"><T id="informations" /></p>
          <p className="mb-1"><T id="categoryName" />{assignment.categoryName}</p>
          <p className="mb-1"><T id="familyName" />{assignment.familyName}</p>
          <p className="mb-1"><T id="dateReceived" />
            {assignment.dateReceived
              ? <><FormattedDate value={new Date(assignment.dateReceived)} />{' '}<FormattedTime value={new Date(assignment.dateReceived)} /></>
              : <T id="noDateReceived" />}
          </p>
          <p className="mb-1"><T id="dateAssigned" />
            {assignment.dateAssigned
              ? <><FormattedDate value={new Date(assignment.dateAssigned)} />{' '}<FormattedTime value={new Date(assignment.dateAssigned)} /></>
              : <T id="noDateAssigned" />}
          </p>

          <p className="mb-1">
            <T id="products" />
            {assignment.products.length === 0 && <T id="noProduct" />}
          </p>
          {assignment.products.length > 0 && <ListGroup className="mb-3">
            {assignment.products.map((product, index) => <ListGroupItem key={index}>{product.codeLabel}</ListGroupItem>)}
          </ListGroup>}

          <div className="d-flex align-items-center mb-2">
            {!toggleEditEquipment && <div className="d-flex align-items-center"><p className="mb-0">
              <T id="assignedEquipment" />{assignment.equipmentIdentifier}
            </p>
            {canEditEquipment && <Button className="py-2 ml-2"
              onClick={() => setEditEquipment(true)}>
              <T id="editEquipment" />
            </Button>}
            </div>}
            {toggleEditEquipment && <div className="d-flex">
              <Dropdown isOpen={dropdownOpen && !assignmentEquipmentsLoading} toggle={() => setDropdownOpen(s => !s)}>
                <DropdownToggle caret
                  disabled={assigning || assignmentEquipmentsLoading}
                  className="text-primary rounded-0 bg-transparent p-2 d-flex align-items-center">
                  {assignmentEquipmentsLoading && <Spinner size="sm" className="mr-2" color="primary" />}
                  <span className="mr-1">{selectedEquipment ? selectedEquipment.equipmentIdentifier + (selectedEquipment.hasLiveModule ? ` - LVM (${selectedEquipment.marque_livemodule})` : '') : <T id="selectEquipment" />}</span>
                </DropdownToggle>
                <DropdownMenu className="pt-0 pb-0 overflow-auto vlcr-dropdown">
                  <TInput className="border-left-0 border-right-0 border-top-0 pt-2 pb-2"
                    type="search"
                    name="searchEquipment"
                    value={searchEquipment}
                    onChange={({ target: { value } }) => setSearchEquipment(value)}
                    placeholder="searchEquipment"
                    disabled={assignmentEquipmentsLoading}
                    autoFocus="autofocus"
                    autoComplete="off" />
                  {filteredEquipments && filteredEquipments.map((assignRequEquipment, index) => <DropdownItem key={index}
                    onClick={() => setSelectedEquipment(assignRequEquipment)}>
                    {assignRequEquipment.equipmentIdentifier}{assignRequEquipment.hasLivemodule && ` - LVM (${assignRequEquipment.marque_livemodule})`}
                  </DropdownItem>)}
                  {filteredEquipments && filteredEquipments.length === 0 && <DropdownItem><T id="noResult" /></DropdownItem>}
                </DropdownMenu>
              </Dropdown>
              <Button className="ml-2 py-2"
                onClick={() => assign(selectedEquipment)}
                disabled={!selectedEquipment || assigning}>
                <T id="assign" />
              </Button>
              {canEditEquipment && <Button className="ml-2 py-2" color="danger"
                onClick={() => setEditEquipment(false)}>
                <T id="cancelReassign" />
              </Button>}
            </div>}

            <div className="d-flex ml-auto">
              {canDefineAsReady && <Button className="ml-2 py-2"
                type="button"
                color="primary"
                disabled={askingTechcenter}
                onClick={() => {
                  setAskingTechcenter(true)
                  setButtonSubmitDisabled(false)
                }}>
                <T id="ready" />
              </Button>}
              {canSubmitIssue && <Button className="ml-2 py-2"
                tag={RRNavLink}
                color="danger"
                to={{
                  pathname: "/interventions/new",
                  search: `?${new URLSearchParams({ equipmentId: assignment.equipmentId, equipmentIdentifier: assignment.equipmentIdentifier })}`
                }}>
                <T id="submitIssue" />
              </Button>}
            </div>
          </div>

          {assignError && <ErrAlert error={assignError} />}
          {assignmentEquipmentsError && <ErrAlert error={assignmentEquipmentsError} />}

          <p className="mb-0">
            <T id="status" />
            <T raw id={`equipmentStatus.${assignment.equipmentStatus || 'unknown'}`} />
          </p>
          <p className="mb-0"><T id="subscriptionId" />{assignment.subscriptionId}</p>
        </CardBody>
        <CardBody className="border-bottom border-dark">
          <p className="h5"><T id="subscriberInfo" /></p>
          <p className="mb-1">{`${assignment.subscriberInfo.civility} ${assignment.subscriberInfo.firstName} ${assignment.subscriberInfo.lastName}`}</p>
          <p className="mb-1"><T id="email" />{assignment.subscriberInfo.email}</p>
          <p className="mb-1"><T id="phoneNumber" />{assignment.subscriberInfo.phoneNumber}</p>
          <p className="mb-0"><T id="subscriberId" />{assignment.subscriberInfo.subscriberId}</p>
        </CardBody>
        <CardBody className="d-flex align-items-start">
          <div className="flex-grow-1">
            <p className="h5"><T id="deliveryInfo" /></p>
            <p className="mb-1"><T id="PDLMembership" />{assignment.deliveryInfo.pdlmembership}</p>
            <p className="mb-1"><T id="PDLName" />{assignment.deliveryInfo.pdlname}</p>
            <p className="mb-1"><T id="address" />{`${assignment.deliveryInfo.address} ${assignment.deliveryInfo.postalCode} ${assignment.deliveryInfo.city}`}</p>
            <p className="mb-1"><T id="addressComplement" />{`${assignment.deliveryInfo.addressComplement}`}</p>
            <p className="mb-1"><T id="type" />{assignment.deliveryInfo.type}</p>
            <p className="mb-1"><T id="cabSend" />{assignment.deliveryInfo.cabSend}</p>
            <p className="mb-1"><T id="cabReturn" />{assignment.deliveryInfo.cabReturn}</p>
            <p className="mb-0"><T id="techcenterName" />{assignment.deliveryInfo.techcenterName}</p>
            <p className="mb-0"><T id="deliveryDate" />{assignment.deliveryInfo.date ? new Date(assignment.deliveryInfo.date).toLocaleDateString('fr-FR') : null}</p>
          </div>
          {assignment.deliveryInfo.bothLabelUrl && <div className="d-flex">
            <Button className="ml-2 d-flex align-items-center" tag="a" target="_blank" href={assignment.deliveryInfo.bothLabelUrl}>
              <i className="mr-2 iconsmind-Tag-3" />
              <T id="bothLabelUrl" />
            </Button>
          </div>}
        </CardBody>
      </Card>

      <SelectTechcenterPopup
        isOpen={askingTechcenter}
        onDismiss={handleSelectTechcenterDismiss}
        onSubmit={handleSelectTechcenterSubmit}
        buttonSubmitDisabled={buttonSubmitDisabled} />
    </>
  )
}

export default AssignmentRequest
