import React from 'react'

import { AppContext } from 'contexts/AppContext'

const reducer = (state, action) => {
  switch (action.type) {
  case 'startRefresh': return state.loading ? state : { ...state, refreshing: true, error: undefined }
  case 'setData': return { ...state, refreshing: false, loading: false, data: action.payload }
  case 'setError': return { ...state, refreshing: false, loading: false, error: action.payload }
  default: return state
  }
}

export const useGet = (path, config, query, options = {}) => {
  const { api } = React.useContext(AppContext)
  const [state, dispatch] = React.useReducer(reducer, {
    loading: !options.pass,
    refreshing: !options.pass,
    error: undefined,
    data: undefined
  })

  React.useEffect(() => {
    let ignore = options.pass

    if (!ignore) {
      dispatch({ type: 'startRefresh' })
      Promise.all([
        api.get(path, config, query),
        new Promise(resolve => setTimeout(resolve, options.timeout || 0))
      ])
        .then(([payload]) => { if (!ignore) { dispatch({ type: 'setData', payload }) } })
        .catch(payload => { if (!ignore) { dispatch({ type: 'setError', payload }) } })
    }

    return () => { ignore = true }
  }, [api, path, config, query, options.pass, options.timeout, options.dependencies])

  const refresh = React.useCallback(() => {
    dispatch({ type: 'startRefresh' })
    Promise.all([
      api.get(path, config, query),
      new Promise(resolve => setTimeout(resolve, options.timeout || 0))
    ])
      .then(([payload]) => dispatch({ type: 'setData', payload }))
      .catch(payload => dispatch({ type: 'setError', payload }))
  }, [api, config, options.timeout, path, query])

  return [state.data, state.refreshing, state.error, state.loading, refresh, payload => dispatch({ type: 'setData', payload })]
}
