import React from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'
import {
  Alert, Badge,
  Card, CardHeader, CardBody, CardFooter,
  FormGroup, Input, Label,
  ListGroup, ListGroupItem,
  Modal, ModalBody, ModalFooter,
  Spinner,
  Table, Form, Row, Col
} from 'reactstrap'
import { Link, NavLink } from 'react-router-dom'
import PhoneInput from 'react-phone-number-input'
import CustomSelect from '../../components/CustomSelect'
import { useHasRights } from 'components/ProtectedComponent'
import moment from 'moment-timezone'

import './InterventionEdit.scss'

import { AppContext } from 'contexts/AppContext'

import { useFormatMessage } from 'hooks/intl.hooks'

import { T, ErrAlert, TAlert, TButton, TLabel, TInput, TCustomInput, TAutocomplete } from 'components/TComponents'
import TH from 'components/TH'
import ProtectedComponent from 'components/ProtectedComponent'
import Button from 'components/Button'
import Duration from 'components/Duration'
import Pagination from 'components/Pagination'

import { debounce } from 'util/Utils'

import ItvcodeSelector from 'components/ItvcodeSelector'

import VeloptimPlanModal from 'components/VeloptimPlanModal'

const InterventionEdit = ({ match, history, location }) => {
  const { api, constants, CHECKLIST_STATUS, user, subsidiary, timezone } = React.useContext(AppContext)
  const formatMessage = useFormatMessage()
  const canEditRight = useHasRights('interventions_new')
  const canCloseRight = useHasRights('interventions_close')
  const canReadQualityControlRight = useHasRights('controlquality_view')

  const [autocomplete, setAutocomplete] = React.useState()

  const [intervention, setIntervention] = React.useState()
  const [interventionLoading, setInterventionLoading] = React.useState(true)
  const [interventionError, setInterventionError] = React.useState()
  const [interventionUpdateLoading, setInterventionUpdateLoading] = React.useState(false)
  const [interventionUpdateError, setInterventionUpdateError] = React.useState()
  const [interventionUpdateDurationError, setInterventionUpdateDurationError] = React.useState()
  const [interventionDeleteLoading, setInterventionDeleteLoading] = React.useState(false)
  const [interventionDeleteError, setInterventionDeleteError] = React.useState(false)
  const [baseIntervention, setBaseIntervention] = React.useState()

  const [addresses, setAddresses] = React.useState()
  const [addressesLoading, setAddressesLoading] = React.useState(true)
  const [addressesError, setAddressesError] = React.useState()

  const [equipments, setEquipments] = React.useState()
  const [equipmentsLoading, setEquipmentsLoading] = React.useState(true)
  const [equipmentsError, setEquipmentsError] = React.useState()
  const [equipmentAdded, setEquipmentAdded] = React.useState()
  const [equipmentsSearchInput, setEquipmentsSearchInput] = React.useState('')
  const [equipmentAlert, setEquipmentAlert] = React.useState()

  const [itvcodesBase, setItvcodesBase] = React.useState()
  const [itvcodesLoading, setItvcodesLoading] = React.useState(true)
  const [itvcodesError, setItvcodesError] = React.useState()
  const [closeLoading, setCloseLoading] = React.useState(false)
  const [closeError, setCloseError] = React.useState()
  const [closeErrorVeloparts, setCloseErrorVeloparts] = React.useState()

  const [transformLoading, setTransformLoading] = React.useState(false)
  const [transformDurationLoading, setTransformDurationLoading] = React.useState(false)

  const [confirmClose, setConfirmClose] = React.useState(false)
  const [confirmCancel, setConfirmCancel] = React.useState(false)
  const [cancelationReasonTemp, setCancelationReasonTemp] = React.useState('')

  const [preventUpdate, setPreventUpdate] = React.useState()

  const [changeDuration, setChangeDuration] = React.useState(false)
  const [currentDuration, setCurrentDuration] = React.useState()
  const [newDuration, setNewDuration] = React.useState()

  const [businessQualif, setBusinessQualif] = React.useState([])
  const [businessQualifError, setBusinessQualifError] = React.useState('')
  const [businessQualifLoading, setBusinessQualifLoading] = React.useState(true)

  const [modalDuplicate, setModalDuplicate] = React.useState(false)
  const [duplicateInterventionNoQuality, setDuplicateInterventionNoQuality] = React.useState()
  const [pictureOpen, setPictureOpen] = React.useState(undefined)

  const [errorBC, setErrorBc] = React.useState()

  const [modifyDone, setModifyDone] = React.useState(false)

  const [matrix, setMatrix] = React.useState([])
  const [matrixFieldsFiltered, setMatrixFieldsFiltered] = React.useState([])
  const [matrixFields, setMatrixFields] = React.useState()
  const [loadingMatrix, setLoadingMatrix] = React.useState()
  const [loadingCodes, setLoadingCodes] = React.useState()
  const [matrixError, setMatrixError] = React.useState()

  const [showHistory, setShowHistory] = React.useState(false)
  const [itvHistory, setItvHistory] = React.useState()
  const [itvHistoryError, setItvHistoryError] = React.useState()

  const [checklistVisible, setChecklistVisible] = React.useState(false)

  const [qualityModal, setQualityModal] = React.useState(false)
  const [qualityControl, setQualityControl] = React.useState()
  const [qualityControlError, setQualityControlError] = React.useState()
  const [qualityControlSuccess, setQualityControlSuccess] = React.useState()
  const [qualityControlDetails, setQualityControlDetails] = React.useState()
  const [qualityControlVisible, setQualityControlVisible] = React.useState()

  const [durationEstimated, setDurationEstimated] = React.useState(intervention && intervention.durationEstimated)

  const [itvHourSelected, setItvHourSelected] = React.useState(0)
  const [itvMinuteSelected, setItvMinuteSelected] = React.useState(0)

  const itvHours = Array(9).fill().map((_, i) => i)
  const itvMinutes = Array(12).fill().map((_, i) => (i * 5))

  const [veloptimModal, setVeloptimModal] = React.useState(location && location.veloptimModal)
  const [addressChange, setAddressChange] = React.useState(false)
  const [planSuccess, setPlanSuccess] = React.useState()
  const [itvcodesForPlan, setItvcodesForPlan] = React.useState()

  const [confirmPlanModal, setConfirmPlanModal] = React.useState(false)
  const [confirmPlan, setConfirmPlan] = React.useState(false)

  const [veloptimCancel, setVeloptimCancel] = React.useState(false)

  const {
    interventionId,
    deskTicketId,
    noQuality,
    equipment,
    claimNumber,
    duplicateInterventionId,
    duplicateInterventionIds,
    swapId,

    statusValue,
    userCanceled, cancelationReason, dateCanceled,
    userAnomaly, anomalyReasonValue, dateAnomaly, anomalyInformation,
    businessId, itvcodes,

    dateDone,
    datePlanned,
    durationPlanned,
    durationChrono,
    durationDone,
    timeRangeStart,
    timeRangeEnd,
    difficultyId,
    information,

    startSlot,
    endSlot,
    reorderTour,

    userAssignFirstname, userAssignLastname,

    checkoutComments,
    checkoutSigned,
    reportLink,

    qualifId,
    selectedAddress,
    addressLabel,
    addressComplement, addressFull,
    locationId,

    equipmentKm,
    techComments,
    anomalyComments,
    checklist,

    firstName, lastName,
    email, phoneNumber,
    subscriptionId,

    postComments, srcFrom
  } = intervention || {}

  React.useEffect(() => {
    getDetails()
  }, [getDetails])

  React.useEffect(() => {
    if (location && location.state && location.state.adressChange) {
      setAddressChange(true)
    }
    if (location && location.state && location.state.success) {
      setPlanSuccess(true)
    }
  }, [location])

  React.useEffect(() => {
    if (addressChange) {
      getDetails()
      setPlanSuccess(true)
      setAddressChange(false)
    }
  }, [addressChange, getDetails])

  React.useEffect(() => {
    // Get Equipment Alerts
    if (equipment && equipment.equipmentId) {
      api.get('/equipments/alerts', undefined, {
        equipmentId: equipment.equipmentId,
      })
        .then(response => setEquipmentAlert(response))
    }
  }, [equipment, api])

  React.useEffect(() => {
    if (baseIntervention && hasStatus('done') && canReadQualityControlRight) {
      getQualityControl()
    }
  }, [baseIntervention, hasStatus, getQualityControl, canReadQualityControlRight])

  React.useEffect(() => {
    if (duplicateInterventionId) {
      api.get('/interventions/details', undefined, { interventionId: duplicateInterventionId })
        .then(response => {
          setDuplicateInterventionNoQuality(response.noQuality)
        })
    }

  }, [api, duplicateInterventionId])

  React.useEffect(() => {
    if (businessId) {
      api.get('/interventions/addresses', undefined, { businessId })
        .then(addresses => setAddresses(addresses))
        .catch(error => setAddressesError(error))
        .then(() => setAddressesLoading(false))

      api.get('/interventions/qualif', undefined, { businessId })
        .then(qualifs => setBusinessQualif(qualifs))
        .catch(response => setBusinessQualifError(response))
        .then(() => setBusinessQualifLoading(false))
    }
  }, [api, businessId])

  React.useEffect(() => {
    if (businessId && equipment) {
      api.get('/matrices/list', undefined, {
        businessId,
        familyId: equipment.familyId,
        categoryId: equipment.categoryId
      })
        .then(data => {
          setMatrixError()
          if (data && data.result && data.result.length > 0) {
            const matrixData = data.result[0]
            setLoadingCodes(true)
            setLoadingMatrix(true)
            api.get('/matrices/details', undefined, {
              businessId,
              matrixId: matrixData && matrixData.matrixId
            })
              .then(data => {
                setLoadingMatrix(false)
                setLoadingCodes(false)
                setMatrixError()
                setMatrixFields(data.matrixFields)
              })
              .catch(error => {
                setLoadingMatrix(false)
                setLoadingCodes(false)
                setMatrixError(error)
              })
          }
        })
        .catch(error => setMatrixError(error))
    }
  }, [api, businessId, equipment])

  React.useEffect(() => {
    if (itvcodes && matrixFields) {
      const codesWithMatrix = matrixFields.filter(field => field.itvcodeId !== null)
      const codesToLink = []
      itvcodes.map(code => {
        codesWithMatrix.map(m => {
          if (code.itvcodeId === m.itvcodeId) {
            codesToLink.push({
              itvcodeId: code.itvcodeId,
              itvcodeIdentifier: code.itvcodeIdentifier,
              itvcodeLabel: code.itvcodeLabel,
              matrixColumnName: m.matrixColumnName,
            })
          }
        })
      })
      setMatrixFieldsFiltered(codesToLink)
    }
  }, [matrixFields, itvcodes])

  React.useEffect(() => {
    if (itvcodes && itvcodes.length > 0) {
      const body = {
        itvcodes: itvcodes.map(itvcode => ({
          itvcodeId: itvcode.itvcodeId,
          quantity: itvcode.quantity,
        }))
      }
      api.post('/interventions/timeItvcodes', { body: JSON.stringify(body) })
        .then(response => setDurationEstimated(response.durationEstimated))
    } else {
      setDurationEstimated(0)
    }
  }, [api, itvcodes])

  React.useEffect(() => {
    if (baseIntervention && baseIntervention.statusValue === 'anomaly') {
      api.get('/equipments/list', undefined, { businessId: baseIntervention && baseIntervention.businessId ? baseIntervention.businessId : undefined, ipp: 3 })
        .then(equipments => setEquipments(equipments))
        .catch(error => setEquipmentsError(error))
        .then(() => setEquipmentsLoading(false))
    }
  }, [api, location.search, baseIntervention])

  React.useEffect(() => {
    setItvHourSelected(Math.trunc((durationPlanned || durationEstimated) / 60))
    setItvMinuteSelected((durationPlanned || durationEstimated) % 60)
  }, [durationPlanned, durationEstimated])

  React.useEffect(() => {
    setIntervention(intervention => ({
      ...intervention,
      durationPlanned: itvMinuteSelected + itvHourSelected * 60
    }))
  }, [itvMinuteSelected, itvHourSelected])

  React.useEffect(() => {
    if (planSuccess) {
      getDetails()
    }
  }, [planSuccess, getDetails])

  const timeFromUTC = React.useCallback(initialDate => {
    const dateArray = initialDate.split(' ')
    const dateInJs = `${dateArray[0]}T${dateArray[1]}Z`
    return moment(dateInJs).tz(timezone).format('HH:mm')
  }, [timezone])

  // EQUIPMENTS MANAGMENT
  const getEquipments = React.useCallback(params => {
    return api.get('/equipments/list', undefined, {
      filter: equipments ? equipments.filter : '',
      businessId: equipments && equipments.filters.businessId.selected
        ? equipments.filters.businessId.selected.value : undefined,
      categoryId: equipments && equipments.filters.categoryId.selected
        ? equipments.filters.categoryId.selected.value : undefined,
      familyId: equipments && equipments.filters.familyId.selected
        ? equipments.filters.familyId.selected.value : undefined,
      statusId: equipments && equipments.filters.statusId.selected
        ? equipments.filters.statusId.selected[0].value : undefined,
      'order[column]': equipments ? equipments.order.column : undefined,
      'order[dir]': equipments ? equipments.order.dir : undefined,
      p: equipments ? equipments.currentPage : 1,
      ipp: 3,
      ...params
    })
      .then(equipments => setEquipments(equipments))
      .catch(error => setEquipmentsError(error))
      .then(() => setEquipmentsLoading(false))
  }, [api, equipments])

  const getDetails = React.useCallback(() => {
    api.get('/interventions/details', undefined, { interventionId: match.params.id })
      .then(response => {
        setIntervention(response)
        setBaseIntervention({ ...response })
        if (response.durationDone) {
          setCurrentDuration(response.durationDone)
          setNewDuration(response.durationDone)
        }
        if (response.error_bc) {
          setErrorBc(response.error_bc.message)
        } else {
          setErrorBc()
        }

        getItvHistory(match.params.id)

        api.get('/itvcodes/list', undefined, {
          ipp: -1,
          businessId: response.businessId,
          familyId: response.equipment.familyId,
          categoryId: response.equipment.categoryId
        })
          .then(response => {
            setItvcodesBase(response)
          })
          .catch(response => {
            setItvcodesError(response)
          })
          .then(() => {
            setItvcodesLoading(false)
          })
      })
      .catch((error) => setInterventionError(error.code))
      .then(() => setInterventionLoading(false))
  }, [api, match, getItvHistory])

  const getItvHistory = React.useCallback(interventionId => {
    api.get('/interventions/history', undefined, { interventionId })
      .then(response => {
        setItvHistory(response)
        setItvHistoryError()
      })
      .catch(error => {
        setItvHistoryError(error)
      })
  }, [api])

  const parseItvHistory = (th, i) => {
    const sharedItvHistory = th => {
      return (
        <React.Fragment key={`${th.user}-${i}`}>
          <FormattedDate value={new Date(th.dateCreated)} />{" "}<FormattedTime value={new Date(th.dateCreated)} />
          <>{" - "}</>
          {th.action !== "new_api" && th.action !== "schedule_api" && th.action !== "api_cancel" && (
            <>
              {th.user === 'Système' || th.user === '' || th.user === null ? <T id="history.systeme" /> : th.user}
              {" - "}  
            </>
          )}
        </React.Fragment>
      )
    }

    switch (th.action) {
    case 'new': return (
      <p key={`${th.user}-${i}`}>
        {sharedItvHistory(th)}
        <T id={`history.${th.action}`} />
      </p>
    )
    case 'schedule': case 'reschedule': case 'schedule_velopt_tech': case 'schedule_api': case 'api_cancel': return (
      <p key={`${th.user}-${i}`}>
        {sharedItvHistory(th)}
        <T id={`history.${th.action}`} />{" "}
        <span className="mt-1 font-italic">
          {th.value && <span style={{ color: '#dd9c02' }}>{`${th.value} `}</span>}
          {th.value2 && <>{' '}<T id='history.dateSchedule' />{' '}<FormattedDate style={{ color: '#0080cc' }} value={th.value2} />{' '}<T id="history.dateScheduleAt" />{' '}<FormattedTime style={{ color: '#0080cc' }} value={th.value2} /></>}
        </span>
      </p>
    )
    case 'schedule_veloptim': return (
      <p key={`${th.user}-${i}`}>
        {sharedItvHistory(th)}
        <T id={`history.${th.action}`} />{" "}
        <span className="mt-1 font-italic">
          {th.value && <span style={{ color: '#dd9c02' }}>{`${th.value} `}</span>}
          {th.value2 && <>{' '}<T id='history.dateSchedule' />{' '}<FormattedDate style={{ color: '#0080cc' }} value={th.value2.day} /></>}
          {th.value2 && <>{' '}<T id='history.slotSchedule' />{' '}<span>{timeFromUTC(th.value2.startSlot)} - {timeFromUTC(th.value2.endSlot)}</span></>}
        </span>
      </p>
    )
    case 'auto_assign': return (
      <p key={`${th.user}-${i}`}>
        <React.Fragment key={`${th.user}-${i}`}>
          <FormattedDate value={new Date(th.dateCreated)} />{" "}<FormattedTime value={new Date(th.dateCreated)} />
          <>{" - "}</>
          <>{th.user === 'Système' || th.user === '' || th.user === null ? <>{`${<T id="history.systeme" />} `}</> : <>{`${th.user} `}</>}</>
        </React.Fragment>
        <T id={`history.${th.action}`} />
      </p>
    )
    case 'start': case 'end': case 'anomaly_end': return (
      <p key={`${th.user}-${i}`}>
        {sharedItvHistory(th)}
        <span style={{ color: '#3e884f' }}><T id={`history.${th.action}`} /></span>
      </p>
    )
    case 'anomaly': return (
      <p key={`${th.user}-${i}`}>
        {sharedItvHistory(th)}
        <span style={{ color: '#cc0101' }}><T id={`history.${th.action}`} /></span>
      </p>
    )
    case 'close': case 'cancel': return (
      <p key={`${th.user}-${i}`}>
        {sharedItvHistory(th)}
        <span style={{ color: '#dd9c02' }}><T id={`history.${th.action}`} /></span>
      </p>
    )
    case 'system_close': return (
      <p key={`${th.user}-${i}`}>
        {sharedItvHistory(th)}
        <span style={{ color: '#dd9c02' }}><T id={`history.${th.action}`} /></span>
      </p>
    )
    case 'anomaly_cancel': return (
      <p key={`${th.user}-${i}`}>
        {sharedItvHistory(th)}
        <span style={{ color: '#dd9c02' }}><T id={`history.${th.action}`} values={{ value: th.value }} /></span>
      </p>
    )
    case 'fr_maintain_pl': return (
      <p key={`${th.user}-${i}`}>
        {sharedItvHistory(th)}
        <span style={{ color: '#dd9c02' }}><T id={`history.${th.action}`} /><Link to={`../maintenance/${th.value}`}>{` #${th.value} `}</Link></span>
      </p>
    )
    default: return (
      <p key={`${th.user}-${i}`}>
        {sharedItvHistory(th)}
        <T id={`history.${th.action}`} />
      </p>
    )
    }
  }

  const getQualityControl = React.useCallback(() => {
    if (intervention && intervention.equipment && intervention.equipment.equipmentId) {
      api.get('/qualitycontrols/checklist', undefined, {
        equipmentId: intervention.equipment.equipmentId
      })
        .then(response => {
          const res = JSON.parse(JSON.stringify(response))
          res.checklist = response.checklist.map(c => {
            c.status = 1
            return c
          })
          setQualityControl(res)
          api.get('/qualitycontrols/details', undefined, {
            interventionId: intervention.interventionId,
          })
            .then(response => setQualityControlDetails(response))
            .catch(error => setInterventionError(error))
        })
        .catch(error => setInterventionError(error))
    }
  }, [api, intervention])

  const handleCancelModifyDone = React.useCallback(() => {
    getDetails()
    setModifyDone(false)
  }, [getDetails])

  const handleAllOk = React.useCallback(() => {
    setQualityControl({
      checklist: qualityControl.checklist.map(checklistItem => ({
        ...checklistItem,
        status: 3
      }))
    })
  }, [setQualityControl, qualityControl])

  const handleCheckChange = React.useCallback(({ item, status, comments }) => {
    if (status) {
      setQualityControl({
        checklist: qualityControl.checklist.map(checklistItem => ({
          ...checklistItem,
          status: item.priority === checklistItem.priority ? status : (checklistItem.status ? checklistItem.status : 1)
        }))
      })
    }
    if (comments || comments === "") {
      setQualityControl({
        checklist: qualityControl.checklist.map(checklistItem => ({
          ...checklistItem,
          comments: item.priority === checklistItem.priority ? comments : (checklistItem.comments ? checklistItem.comments : '')
        }))
      })
    }
  }, [setQualityControl, qualityControl])

  const checkIfChecklistOk = React.useCallback(() => {
    if (qualityControl.checklist) {
      const check = qualityControl.checklist.map(item => {
        if ((!item.status || item.status === 1) && (!item.comments || item.comments.length === 0)) {
          return 'ko'
        }
        if (item.status === 2 && (!item.comments || item.comments.length === 0)) {
          return 'comments'
        }
        return true
      })
      return check
    }
    return []
  }, [qualityControl])

  const handleSaveQualityControl = React.useCallback(() => {
    const check = checkIfChecklistOk()
    if (check.includes('ko')) {
      setQualityControlError('checklistCommentKo')
    } else if (check.includes('comments')) {
      setQualityControlError('checklistComment')
    } else {
      api.post('/qualitycontrols/control', {
        _stringifyBody: true,
        body: {
          interventionId: intervention.interventionId,
          checklist: qualityControl.checklist
        }
      })
        .then(() => {
          setQualityModal(false)
          setQualityControlSuccess(true)
          getQualityControl()
          getDetails()
        })
        .catch(error => setQualityControlError(error.code))
    }
  }, [api, checkIfChecklistOk, getQualityControl, intervention, qualityControl, getDetails])

  const handleSaveModifyDone = React.useCallback(() => {
    api.post('/interventions/updateDone', {
      _stringifyBody: true,
      body: {
        interventionId: intervention.interventionId,
        mapId: canEdit ? intervention.mapId ? intervention.mapId : "" : undefined,
        itvcodes: intervention.itvcodes.map(({ itvcodeId, quantity, wear, reasonId }) => ({
          itvcodeId,
          quantity,
          wear,
          ...(reasonId && { reasonId })
        })),
        difficultyId: canEdit ? intervention.difficultyId ? intervention.difficultyId : 1 : undefined,
        qualifId: canEdit ? intervention.qualifId ? intervention.qualifId : 0 : undefined,
        locationId: canEdit ? intervention.locationId : undefined,
        postComments: intervention.postComments,
        addressLabel: canEdit ? intervention.addressLabel ? intervention.addressLabel : "" : undefined,
        techComments: intervention.techComments || '',
        durationDone: newDuration || intervention.durationDone || 0,
        addressComplement: canEdit ? intervention.addressComplement ? intervention.addressComplement : "" : undefined,
        information: canEdit ? intervention.information : undefined,
        firstName: canEdit ? intervention.firstName : undefined,
        lastName: canEdit ? intervention.lastName : undefined,
        email: canEdit ? intervention.email : undefined,
        phoneNumber: canEdit ? intervention.phoneNumber : undefined,
        claimNumber: intervention.claimNumber,
        ...(matrix && { matrix }),
      }
    })
      .then(response => {
        setIntervention(response)
        setModifyDone(false)
        getDetails()
      })
      .catch(error => {
        setInterventionUpdateError(error)
      })
  }, [api, intervention, matrix, newDuration, getDetails, canEdit])

  const onItvcodesChange = React.useCallback((itvcodes) => {
    setVeloptimCancel(true)
    setIntervention(intervention => ({ ...intervention, itvcodes: itvcodes }))
  }, [])

  const cancel = React.useCallback(() => {
    if (interventionDeleteLoading) { return }

    setInterventionDeleteLoading(true)

    api.del('/interventions/details', undefined, { interventionId: baseIntervention.interventionId, cancelationReason: cancelationReasonTemp })
      .then(response => {
        setIntervention(response)
        setConfirmCancel(false)
        getDetails()
      })
      .catch(setInterventionDeleteError)
      .then(() => setInterventionDeleteLoading(false))
  }, [api, baseIntervention, cancelationReasonTemp, interventionDeleteLoading, getDetails])

  const addressHandleChange = React.useCallback(e => {
    setVeloptimCancel(true)
    const addressId = e ? e.addressId : undefined

    const addressSelected = addressId
      ? addresses.find(address => String(address.addressId) === String(addressId))
      : undefined

    setIntervention(intervention => ({
      ...intervention,
      selectedAddress: addressId,
      addressLabel: addressSelected ? addressSelected.addressLabel : '',
      addressComplement: addressSelected ? addressSelected.addressComplement : '',
      addressFull: addressSelected ? addressSelected.addressFull : '',
      mapId: addressSelected ? addressSelected.mapId : ''
    }))
  }, [addresses])

  const handleMatrixChange = React.useCallback((sn, matrixColumnName) => {
    const matrixItem = {
      matrixColumnName: matrixColumnName,
      SN: sn
    }
    const matrixToSend = JSON.parse(JSON.stringify(matrix))
    if (matrixToSend.length > 0) {
      const columnId = matrixToSend.findIndex(m => m.matrixColumnName === matrixItem.matrixColumnName)
      if (columnId !== -1) {
        matrixToSend[columnId].SN = sn
        matrixToSend[columnId].km = baseIntervention.equipment.settings.trackKm ? intervention.equipmentKm || 0 : 0
      } else {
        matrixToSend.push({
          matrixColumnName: matrixItem.matrixColumnName,
          SN: sn,
          km: baseIntervention.equipment.settings.trackKm ? intervention.equipmentKm || 0 : 0
        })
      }
    } else {
      matrixToSend.push({
        matrixColumnName: matrixItem.matrixColumnName,
        SN: sn,
        km: baseIntervention.equipment.settings.trackKm ? intervention.equipmentKm || 0 : 0
      })
    }
    // Test with deep object copy to prevent infintie loop on saveIntervention
    if (JSON.stringify(matrixToSend) !== JSON.stringify(matrix)) {
      setMatrix(matrixToSend)
      setIntervention(intervention => ({
        ...intervention,
        ...{ matrix: matrixToSend },
      }))
    }

  }, [baseIntervention, matrix, intervention])

  const handleChangeAddressFull = React.useCallback(e => {
    setVeloptimCancel(true)
    e.persist()
    setIntervention(intervention => ({
      ...intervention,
      addressFull: e.target.value,
      mapId: undefined
    }))
    api.get('/maps/autocomplete', undefined, {
      input: e.target.value,
      ...(autocomplete && autocomplete.session_token && { session_token: autocomplete.session_token })
    })
      .then(response => {
        setAutocomplete(response)
      })
      .catch(error => console.log(error))
  }, [api, autocomplete])

  const handleAutocomplete = React.useCallback(e => {
    setIntervention(intervention => ({
      ...intervention,
      addressFull: e,
    }))
    api.get('/maps/placedetails', undefined, {
      place_id: autocomplete.results.find(r => r.addressFull === e).place_id,
      session_token: autocomplete.session_token,
      addressFull: e
    })
      .then(response => {
        setIntervention(intervention => ({
          ...intervention,
          mapId: response.mapId,
        }))
      })
      .catch(error => console.log(error))
  }, [api, autocomplete])

  const handleItvHoursChange = React.useCallback(({ target: { value } }) => {
    setVeloptimCancel(true)
    setIntervention(intervention => ({
      ...intervention,
      durationPlanned: itvMinuteSelected + Number(value) * 60
    }))
  }, [itvMinuteSelected])

  const handleItvMinutesChange = React.useCallback(({ target: { value } }) => {
    setVeloptimCancel(true)
    setIntervention(intervention => ({
      ...intervention,
      durationPlanned: Number(value) + itvHourSelected * 60
    }))
  }, [itvHourSelected])

  const handleChange = React.useCallback((e) => {
    const { target: { name, value } } = e
    const newState = { [name]: value }

    setIntervention(intervention => ({
      ...intervention,
      ...newState,
    }))
  }, [])

  const handleTrack = React.useCallback(() => {
    api.post('/interventions/tracking', {
      _stringifyBody: true,
      body: {
        interventionId: intervention.interventionId,
        tracking: 1
      }
    })
  }, [intervention, api])

  const hasCodeReason = React.useCallback(itvcode => {
    let hasReason = false
    if (intervention) {
      const qualifKey = constants.interventionQualif.find(qualif => qualif.value == intervention.qualifId) && constants.interventionQualif.find(qualif => qualif.value == intervention.qualifId).key
      if (itvcode.reasonListItvDisplay === 1 || (itvcode.reasonListItvDisplay === -1 && itvcode.enabledQualifReasonPart && itvcode.enabledQualifReasonPart.includes(qualifKey))) {
        hasReason = true
      } else {
        const code = itvcode.reason && itvcode.reason.length > 0 && itvcode.reason.find(r => r.businessId === intervention.businessId)
        if (code && (code.reasonListItvDisplay === 1 || (code.reasonListItvDisplay === -1 && code.enabledQualifReasonPart && code.enabledQualifReasonPart.includes(qualifKey)))) {
          hasReason = true
        }
      }
    }
    return hasReason
  }, [constants, intervention])

  const saveWait = React.useCallback(override => {
    return api.post('/interventions/updateWait', {
      _stringifyBody: true,
      body: {
        interventionId,
        mapId: canEdit ? intervention.mapId ? intervention.mapId : "" : undefined,
        itvcodes: canEdit ? intervention.itvcodes.map(itvcode => ({
          itvcodeId: itvcode.itvcodeId,
          quantity: itvcode.quantity,
          wear: Boolean(itvcode.wear), // Should send a Boolean to avoid not sending the property if false
          ...(itvcode.reasonId && hasCodeReason(itvcode) && { reasonId: itvcode.reasonId })
        })) : undefined,
        difficultyId: canEdit ? intervention.difficultyId : undefined,
        qualifId: canEdit ? intervention.qualifId : undefined,
        locationId: canEdit ? intervention.locationId : undefined,
        addressLabel: canEdit ? intervention.addressLabel : undefined,
        claimNumber: canEdit ? intervention.claimNumber : undefined,
        information: canEditInformation ? intervention.information : undefined,
        addressComplement: canEdit ? intervention.addressComplement : undefined,
        durationPlanned: intervention.durationPlanned,
        firstName: canEdit ? intervention.firstName : undefined,
        lastName: canEdit ? intervention.lastName : undefined,
        email: canEdit ? intervention.email : undefined,
        phoneNumber: canEdit ? intervention.phoneNumber : undefined,
        ...override
      }
    })
  }, [api, canEdit, canEditInformation, hasCodeReason, intervention, interventionId])

  const saveSchedule = React.useCallback(override => {
    return api.post('/interventions/updateSchedule', {
      _stringifyBody: true,
      body: {
        interventionId,
        mapId: canEdit ? intervention.mapId ? intervention.mapId : "" : undefined,
        itvcodes: canEdit ? intervention.itvcodes.map(itvcode => ({
          itvcodeId: itvcode.itvcodeId,
          quantity: itvcode.quantity,
          wear: Boolean(itvcode.wear),
          ...(itvcode.reasonId && hasCodeReason(itvcode) && { reasonId: itvcode.reasonId })
        })) : undefined,
        difficultyId: canEdit ? intervention.difficultyId : undefined,
        qualifId: canEdit ? intervention.qualifId : undefined,
        locationId: canEdit ? intervention.locationId : undefined,
        addressLabel: canEdit ? intervention.addressLabel : undefined,
        claimNumber: canEdit ? intervention.claimNumber : undefined,
        information: canEditInformation ? intervention.information : undefined,
        addressComplement: canEdit ? intervention.addressComplement : undefined,
        durationPlanned: intervention.durationPlanned,
        firstName: canEdit ? intervention.firstName : undefined,
        lastName: canEdit ? intervention.lastName : undefined,
        email: canEdit ? intervention.email : undefined,
        phoneNumber: canEdit ? intervention.phoneNumber : undefined,
        veloptimCancel: veloptimCancel,
        ...override
      }
    })
  }, [api, canEdit, canEditInformation, hasCodeReason, intervention, interventionId, veloptimCancel])

  const saveEnd = React.useCallback(override => {
    return api.post('/interventions/updateEnd', {
      _stringifyBody: true,
      body: {
        interventionId,
        itvcodes: canEdit ? intervention.itvcodes.map(itvcode => ({
          itvcodeId: itvcode.itvcodeId,
          quantity: itvcode.quantity,
          wear: Boolean(itvcode.wear), // Should send a Boolean to avoid not sending the property if false
          ...(itvcode.reasonId && hasCodeReason(itvcode) && { reasonId: itvcode.reasonId })
        })) : [],
        difficultyId: canEdit ? intervention.difficultyId ? intervention.difficultyId : 1 : undefined,
        information: canEditInformation ? intervention.information : undefined,
        qualifId: canEdit ? intervention.qualifId ? intervention.qualifId : 0 : undefined,
        locationId: canEdit ? intervention.locationId : undefined,
        addressLabel: canEdit ? intervention.addressLabel ? intervention.addressLabel : "" : undefined,
        addressComplement: canEdit ? intervention.addressComplement ? intervention.addressComplement : "" : undefined,
        firstName: canEdit ? intervention.firstName : undefined,
        lastName: canEdit ? intervention.lastName : undefined,
        email: canEdit ? intervention.email : undefined,
        phoneNumber: canEdit ? intervention.phoneNumber : undefined,
        postComments: intervention.postComments,
        mapId: canEdit ? intervention.mapId ? intervention.mapId : "" : undefined,
        durationPlanned: intervention.durationPlanned,
        ...(intervention.anomalyComments && { anomalyComments: canEdit ? intervention.anomalyComments : undefined }),
        ...override
      }
    })
  }, [api, canEdit, canEditInformation, hasCodeReason, intervention, interventionId])

  const saveAnomaly = React.useCallback(override => {
    return api.post('/interventions/updateAnomaly', {
      _stringifyBody: true,
      body: {
        interventionId,
        itvcodes: canEdit ? intervention.itvcodes.map(itvcode => ({
          itvcodeId: itvcode.itvcodeId,
          quantity: itvcode.quantity,
          wear: Boolean(itvcode.wear), // Should send a Boolean to avoid not sending the property if false
          ...(itvcode.reasonId && hasCodeReason(itvcode) && { reasonId: itvcode.reasonId })
        })) : [],
        difficultyId: canEdit ? intervention.difficultyId ? intervention.difficultyId : 1 : undefined,
        information: canEditInformation ? intervention.information : undefined,
        qualifId: canEdit ? intervention.qualifId ? intervention.qualifId : 0 : undefined,
        locationId: canEdit ? intervention.locationId : undefined,
        addressLabel: canEdit ? intervention.addressLabel ? intervention.addressLabel : "" : undefined,
        addressComplement: canEdit ? intervention.addressComplement ? intervention.addressComplement : "" : undefined,
        firstName: canEdit ? intervention.firstName : undefined,
        lastName: canEdit ? intervention.lastName : undefined,
        email: canEdit ? intervention.email : undefined,
        phoneNumber: canEdit ? intervention.phoneNumber : undefined,
        mapId: canEdit ? intervention.mapId ? intervention.mapId : "" : undefined,
        claimNumber: canEdit ? intervention.claimNumber : undefined,
        anomalyComments : canEdit 
          ? intervention.anomalyComments ? intervention.anomalyComments : "" 
          : "" ,
        ...(isInterventionAnomalyIncomplete && { equipmentId: equipmentAdded ? equipmentAdded.equipmentId : undefined }),
        ...override
      }
    })
  }, [api, canEdit, canEditInformation, equipmentAdded, hasCodeReason, intervention, interventionId, isInterventionAnomalyIncomplete])

  const save = React.useCallback((override) => {
    if (hasStatus('waitPec')) {
      return saveWait(override)
    } else if (hasStatus('scheduled')){
      return saveSchedule(override)      
    } else if (hasStatus('done')){
      return saveEnd(override)
    } else if (hasStatus('anomaly')){
      return saveAnomaly(override)
    }     
  }, [hasStatus, saveWait, saveSchedule, saveEnd, saveAnomaly])

  const toggleDuration = () => setChangeDuration(!changeDuration)

  const handleChangeDuration = e => setNewDuration(e.target.value);

  const saveDuration = () => {
    if (newDuration !== currentDuration) {
      setTransformDurationLoading(true)
      setInterventionUpdateDurationError()
      Promise.all([
        api.post('/interventions/updatedurationdone', { _stringifyBody: true, body: { interventionId, durationDone: newDuration } }),
        new Promise(resolve => setTimeout(resolve, 250))
      ])
        .then(() => {
          setChangeDuration(!changeDuration)
          setTransformDurationLoading(false)
          setCurrentDuration(newDuration);
        })
        .catch(response => {
          setTransformDurationLoading(false)
          setInterventionUpdateDurationError(response)
          setNewDuration(currentDuration)
        })
    }
  }

  const cancelDuration = () => {
    setNewDuration(currentDuration)
    setChangeDuration(!changeDuration)
    setInterventionUpdateDurationError()
  }

  const confirmDuration = () => {
    setChangeDuration(!changeDuration)
    setInterventionUpdateDurationError()
  }

  const handleSave = React.useCallback(override => {
    setPreventUpdate()
    setInterventionUpdateLoading(true)
    save(override)
      .then(response => {
        setIntervention(response)
        setBaseIntervention({ ...response })
        setInterventionUpdateError()
        getDetails()
      })
      .catch(response => {
        response && response.code === 'preventUpdate'
          ? setPreventUpdate({ ...response.params, callback: handleSave })
          : setInterventionUpdateError(response)
      })
      .then(() => setInterventionUpdateLoading(false))
  }, [save, getDetails])

  const handleSaveAndPlanVeloptim = React.useCallback(override => {
    if (hasStatus('scheduled') && !confirmPlan && intervention.dateVeloptimScheduled) {
      setConfirmPlanModal(true)
    } else {
      setConfirmPlanModal(false)
      setInterventionUpdateError()
      setPreventUpdate()
      setInterventionUpdateLoading(true)
      save(override)
        .then(() => setVeloptimModal(true))
        .catch(response => {
          if (response && response.code === 'preventUpdate') {
            setPreventUpdate({ ...response.params, callback: handleSaveAndPlanVeloptim })
          } else {
            setInterventionUpdateError(response)
          }
        })
        .then(() => setInterventionUpdateLoading(false))
    }
  }, [hasStatus, confirmPlan, intervention, save])

  const handleSaveAndPlan = React.useCallback(override => {
    setPreventUpdate()
    setInterventionUpdateLoading(true)
    save(override)
      .then(() => {
        history.push(`/interventions/plan/${intervention.interventionId}`)
      })
      .catch(response => {
        response && response.code === 'preventUpdate'
          ? setPreventUpdate({ ...response.params, callback: handleSaveAndPlan })
          : setInterventionUpdateError(response)
      })
      .then(() => setInterventionUpdateLoading(false))
  }, [history, intervention, save])

  const handleReschedule = React.useCallback((interventionId) => {
    setPreventUpdate()
    setInterventionUpdateLoading(true)
    save()
      .then(
        Promise.all([
          api.post('/interventions/reschedule', { _stringifyBody: true, body: { interventionId } }),
          new Promise(resolve => setTimeout(resolve, 250))
        ])
          .then(() => history.push(`/interventions/plan/${interventionId}`))
          .catch(response => {
            setInterventionUpdateLoading(false)
            response && response.code === 'preventUpdate'
              ? setPreventUpdate({ ...response.params, callback: handleReschedule })
              : setInterventionUpdateError(response)
          })
      ).catch(response =>
        response && response.code === 'preventUpdate'
          ? setPreventUpdate({ ...response.params, callback: handleReschedule })
          : setInterventionUpdateError(response)
      )
  }, [api, history, save])

  const handleTransform = React.useCallback((interventionId) => {
    setTransformLoading(true)
    setInterventionUpdateError()
    save()
      .then(
        Promise.all([
          api.post('/interventions/transform', { _stringifyBody: true, body: { interventionId } }),
          new Promise(resolve => setTimeout(resolve, 250))
        ])
          .then(([response]) => history.push(`/swaps/${response.swapId}`))
          .catch(response => {
            setTransformLoading(false)
            setInterventionUpdateError(response)
          })
      ).catch(response =>
        response && response.code === 'preventUpdate'
          ? setPreventUpdate({ ...response.params, callback: handleTransform })
          : setInterventionUpdateError(response)
      )
  }, [api, history, save])

  const handleClose = React.useCallback(override => {
    setPreventUpdate()
    setInterventionUpdateLoading(true)
    setCloseLoading(true)
    save(override)
      .then(() => {
        api.post('/interventions/close', { _stringifyBody: true, body: { interventionId: intervention.interventionId }, })
          .then(response => {
            if (response.error_bc) {
              if (response.error_bc.includes('warehouseRefNotFound')) {
                setCloseError('warehouseRefNotFound')
              } else if (response.error_dynamic_clearance) {
                setCloseErrorVeloparts(response.error_dynamic_clearance)
              } else {
                setCloseError(response.error_bc.message)
                setConfirmClose(false)
              }
            } else {
              setConfirmClose(false)
              setIntervention(response)
              setBaseIntervention({ ...response })
              setCloseError()
              setInterventionUpdateError()
              setErrorBc()
              getDetails()
            }
          })
          .catch(error => setCloseError(error))
          .then(() => {
            setCloseLoading(false)
            setInterventionUpdateLoading(false)
          })
      })
      .catch(response => {
        response && response.code === 'preventUpdate'
          ? setPreventUpdate({ ...response.params, callback: handleClose })
          : setInterventionUpdateError(response)
        setCloseLoading(false)
        setInterventionUpdateLoading(false)
        setCloseLoading(false)
      })
  }, [api, intervention, save, getDetails])

  const handleEndAnomaly = React.useCallback(interventionId => {
    setPreventUpdate()
    setInterventionUpdateLoading(true)
    save()
      .then(() =>
        Promise.all([
          api.post('/interventions/endanomaly', { _stringifyBody: true, body: { interventionId: interventionId } }),
          new Promise(resolve => setTimeout(resolve, 250))
        ])
          .then(() => history.push(`/interventions`))
          .catch(response =>
            response && response.code === 'preventUpdate'
              ? setPreventUpdate({ ...response.params, callback: handleEndAnomaly })
              : setInterventionUpdateError(response)
          )
      ).catch(response =>
        response && response.code === 'preventUpdate'
          ? setPreventUpdate({ ...response.params, callback: handleEndAnomaly })
          : setInterventionUpdateError(response)
      )
      .then(() => setInterventionUpdateLoading(false))
  }, [api, history, save])

  const handleDuplicate = () => {
    setModalDuplicate(true)
  }

  const duplicate = quality => {
    history.push(`/interventions/new?interventionId=${intervention.interventionId}&noQuality=${quality}`)
  }

  const getEquipmentsDebounced = React.useMemo(() =>
    debounce(params => getEquipments(params), 250)
  , [getEquipments])

  const equipmentsHandleSearchInput = React.useCallback(e => {
    setEquipmentsSearchInput(e.target.value)
    getEquipmentsDebounced({ filter: e.target.value, p: 1 })
  }, [getEquipmentsDebounced])

  const equipmentsOrder = React.useCallback((e, column) => {
    e.preventDefault()

    getEquipments({
      'order[column]': column,
      'order[dir]': equipments.order.column === column && equipments.order.dir === 'asc' ? 'desc' : 'asc',
    })
  }, [getEquipments, equipments])

  const addEquipment = React.useCallback(equipment => {
    api.get('/itvcodes/list', undefined, {
      ipp: -1,
      businessId: equipment.businessId,
      familyId: equipment.familyId,
      categoryId: equipment.categoryId
    })
      .then(response => setItvcodesBase(response))
      .then(() => setEquipmentAdded(equipment))
      .catch(error => setItvcodesError(error))
      .then(() => setItvcodesLoading(false))
  }, [api])

  const removeEquipment = React.useCallback(() => {
    setEquipmentAdded()
  }, [])

  React.useEffect(() => {
    if (itvcodes && itvcodes.length > 0) {
      setItvcodesForPlan(itvcodes.map(i => i.itvcodeId))
    }
  }, [itvcodes])

  React.useEffect(() => {
    if (confirmPlan) {
      handleSaveAndPlanVeloptim()
      setConfirmPlan(false)
    }
  }, [confirmPlan, handleSaveAndPlanVeloptim])

  const _renderEquipmentSelector = React.useCallback(() => (
    <ProtectedComponent rights={['interventions_new']}>
      <Card className="mb-3">
        <CardHeader>
          <Form onSubmit={e => e.preventDefault()}>
            <Row form>
              <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel for="equipmentsSearchInput" id="equipmentsSearchInputLabel" className="" />
                  <TInput
                    name="equipmentsSearchInput"
                    type="text"
                    placeholder="equipmentsSearchInputPlaceholder"
                    value={equipmentsSearchInput}
                    disabled={!canEdit}
                    onChange={e => equipmentsHandleSearchInput(e)} />
                </FormGroup>
              </Col>
              {equipments.filters && equipments.filters.businessId && equipments.filters.businessId.values && <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel for="businessId" id="businessLabel" className="" />
                  <CustomSelect
                    inputId="businessId"
                    name="businessId"
                    onChange={e => getEquipments({ businessId: e.value })}
                    isDisabled={true}
                    options={equipments.filters.businessId.values}
                    defaultValue={equipments.filters.businessId.values.filter(e => e.value === intervention.businessId)} />
                </FormGroup>
              </Col>}
              {equipments.filters && equipments.filters.categoryId && equipments.filters.categoryId.values && <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel for="categoryId" id="categoryLabel" className="" />
                  <CustomSelect
                    inputId="categoryId"
                    name="categoryId"
                    isClearable
                    options={equipments.filters.categoryId.values}
                    onChange={e => getEquipments({ categoryId: e && e.value })}
                    placeholder={<T id="categoryPlaceholder" />}
                    isDisabled={equipmentsLoading}
                    value={equipments.filters.categoryId.selected || ''} />
                </FormGroup>
              </Col>}
              {equipments.filters && equipments.filters.familyId && equipments.filters.familyId.values && <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel for="familyId" id="familyLabel" className="" />
                  <CustomSelect
                    inputId="familyId"
                    name="familyId"
                    isClearable
                    options={equipments.filters.familyId.values}
                    onChange={e => getEquipments({ familyId: e && e.value })}
                    placeholder={<T id="familyPlaceholder" />}
                    isDisabled={equipmentsLoading}
                    value={equipments.filters.familyId.selected || ''} />
                </FormGroup>
              </Col>}
              {equipments.filters && equipments.filters.statusId && equipments.filters.statusId.values && <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel for="statusId" id="statusLabel" className="" />
                  <CustomSelect
                    inputId="statusId"
                    name="statusId"
                    isClearable
                    options={equipments.filters.statusId.values}
                    onChange={e => getEquipments({ statusId: e && e.value })}
                    placeholder={<T id="statusPlaceholder" />}
                    isDisabled={equipmentsLoading}
                    value={equipments.filters.statusId.selected || ''}
                    getOptionLabel={option => <T id={`equipmentStatus.${option.label}`} raw />} />
                </FormGroup>
              </Col>}
            </Row>
          </Form>
        </CardHeader>
        <Table responsive striped hover className="mb-0 mt-2">
          <thead>
            <tr><TH colSpan="7" className={['border-top-0']}
              colName="count"
              colValues={{ total: equipments.total }} />
            </tr>
          </thead>
          <thead>
            <tr>
              <TH colName="equipmentIdentifier" order={equipments.order} sort={equipmentsOrder} />
              <TH colName="equipmentBusiness" order={equipments.order} sort={equipmentsOrder} />
              <TH colName="categoryName" order={equipments.order} sort={equipmentsOrder} />
              <TH colName="familyName" order={equipments.order} sort={equipmentsOrder} />
              <TH colName="dateCreated" order={equipments.order} sort={equipmentsOrder} />
              <TH colName="equipmentStatus" />
              <TH />
            </tr>
          </thead>
          <tbody style={{ transform: `rotate(0)` }}>
            {equipmentsLoading && <tr><td><div className="floating-spinner">
              <style dangerouslySetInnerHTML={{
                __html: `
                .floating-spinner {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                .floating-spinner:before {
                  content: '';
                  top: 0;
                  left: 0;
                  position: absolute;
                  background: black;
                  width: 100%;
                  height: 100%;
                  opacity: .1;
                }` }}>
              </style>
              <Spinner className="" color="primary" />
            </div></td></tr>}
            {equipments.result.length > 0 && equipments.result.map(equipment => {
              return <tr key={equipment.equipmentId}>
                <td>{equipment.equipmentIdentifier}</td>
                <td>{equipment.businessName}</td>
                <td>{equipment.categoryName}</td>
                <td>{equipment.familyName}</td>
                <td><FormattedDate value={new Date(equipment.dateCreated)} /></td>
                <td><T id={`equipmentStatus.${equipment.statusValue}`} raw /></td>
                <td>
                  <Button className="ml-auto"
                    color={'secondary'}
                    disabled={false}
                    onClick={() => addEquipment(equipment)}>
                    <T id="addEquipment" />
                  </Button>
                </td>
              </tr>
            })}
          </tbody>
          <tfoot>
            <tr><td colSpan="100" className="pb-0">
              <Pagination
                totalPage={equipments.totalPage}
                currentPage={equipments.currentPage}
                onPageClick={p => getEquipments({ p })} /></td></tr>
          </tfoot>
        </Table>
      </Card>
    </ProtectedComponent>
  ), [addEquipment, equipments, intervention, equipmentsHandleSearchInput, equipmentsLoading, equipmentsOrder, equipmentsSearchInput, getEquipments, canEdit])

  const hasStatus = React.useCallback((...status) => status.includes(statusValue), [statusValue])

  const isInterventionAnomalyIncomplete = hasStatus('anomaly') && !(intervention &&
    intervention.equipment && intervention.equipment.equipmentIdentifier &&
    intervention.itvcodes && intervention.itvcodes.length > 0 &&
    intervention.qualifId &&
    intervention.difficultyId &&
    intervention.addressLabel
  )
  const hasCheckout = hasStatus('done', 'closed') && (
    checkoutComments ||
    checkoutSigned === 0 ||
    (equipment && equipment.pics && equipment.pics.pic1Url.length > 0) ||
    (equipment && equipment.pics && equipment.pics.pic2Url.length > 0) ||
    (equipment && equipment.pics && equipment.pics.pic3Url.length > 0) ||
    (equipment && equipment.pics && equipment.pics.signUrl.length > 0) ||
    reportLink)
  const canEdit = hasStatus('waitPec', 'scheduled', 'done', 'anomaly') && !loading && !!canEditRight
  const canEditInformation = hasStatus('waitPec', 'scheduled', 'canceled') && !!canEditRight
  const isInterventionEnded = hasStatus('done', 'canceled', 'closed')
  const loading = interventionLoading || interventionUpdateLoading || transformLoading || interventionDeleteLoading
  const codesAndMatrixLoading = itvcodesLoading || loadingMatrix || loadingCodes
  
  if (interventionError) {
    return <>
      <NavLink tag={Link} to={{
        pathname: '/interventions',
        search: location.state ? location.state.interventions : undefined
      }}>
        <T id="returnToList" />
      </NavLink>
      <TAlert id="itvNotFound" className="mb-0" color="danger" />
    </>
  }

  if (!interventionLoading && !equipment) {
    return <>
      <NavLink tag={Link} to={{
        pathname: '/interventions',
        search: location.state ? location.state.interventions : undefined
      }}>
        <T id="returnToList" />
      </NavLink>
      <TAlert id="invalidItv" className="mb-0" color="danger" />
    </>
  }

  if (interventionLoading) {
    return <>
      <NavLink tag={Link} to={{
        pathname: '/interventions',
        search: location.state ? location.state.interventions : undefined
      }}>
        <T id="returnToList" />
      </NavLink>
      <CardHeader className="d-flex align-items-center">
        <T id="loading" />
        <Spinner className="ml-2" color="primary" size="sm" />
      </CardHeader>
    </>
  }

  if (interventionError) {
    return <>
      <NavLink tag={Link} to={{
        pathname: '/interventions',
        search: location.state ? location.state.interventions : undefined
      }}>
        <T id="returnToList" />
      </NavLink>
      <TAlert id="itvNotFound" className="mb-0" color="danger" />
    </>
  }

  if (!interventionLoading && !equipment) {
    return <>
      <NavLink tag={Link} to={{
        pathname: '/interventions',
        search: location.state ? location.state.interventions : undefined
      }}>
        <T id="returnToList" />
      </NavLink>
      <TAlert id="invalidItv" className="mb-0" color="danger" />
    </>
  }

  if (interventionLoading) {
    return <>
      <NavLink tag={Link} to={{
        pathname: '/interventions',
        search: location.state ? location.state.interventions : undefined
      }}>
        <T id="returnToList" />
      </NavLink>
      <CardHeader className="d-flex align-items-center">
        <T id="loading" />
        <Spinner className="ml-2" color="primary" size="sm" />
      </CardHeader>
    </>
  }

  return (
    <div className="container-fluid InterventionEdit">
      <NavLink tag={Link} to={{
        pathname: '/interventions',
        search: location.state ? location.state.interventions : undefined
      }}>
        <T id="returnToList" />
      </NavLink>
      <Card>
        {!interventionLoading && <CardHeader className={`difficulty ${baseIntervention.difficultyValue}`}>
          <div className="ml-2">
            {equipment && <div className="h4">
              <T id="equipmentId" />
              {equipment.equipmentId ? (
                <>
                  <NavLink
                    tag={Link}
                    to={`/equipments/${equipment.equipmentId}`}
                    className="p-0 d-inline">
                    {equipment.equipmentIdentifier}
                  </NavLink>
                  <small className="ml-2">{equipment.categoryName}</small>
                  <small className="ml-2">{equipment.familyName}</small>
                </>
              ) : (
                <T id="noEquipment" />
              )}
            </div>}
            <div className="h6 mb-0">
              <span><T id="interventionId" />{interventionId}</span>
              <small className="ml-2">
                {deskTicketId ?
                  <Link to={`/desk/${deskTicketId}`}><T id="deskTicketId" />{deskTicketId}</Link> :
                  <><T id="deskTicketId" /><T id="noTicketId" /></>}
              </small>
            </div>
            {noQuality === 1 &&
              <div className="mb-0 mt-2">
                <T id="noQuality" />
                {duplicateInterventionIds && duplicateInterventionIds.length > 0 && <T id="noQuality.origin" />}
                {duplicateInterventionIds && duplicateInterventionIds.length > 0 && duplicateInterventionIds.map(itv =>
                  <Link key={itv} to={`../interventions/${itv}`}>
                    {` #${itv} `}
                  </Link>
                )}
              </div>
            }
            {duplicateInterventionId && (
              <div className="mb-0 mt-2">
                <T id="duplicateOrigin" />
                <Link to={`../interventions/${duplicateInterventionId}`}>
                  {` #${duplicateInterventionId} `}
                </Link>
                {!!duplicateInterventionNoQuality && <T id="duplicateOriginNoQuality" />}
              </div>
            )}
            {swapId && (
              <div className="mb-0 mt-2">
                <T id="swapOrigin" />
                <Link to={`../swaps/${swapId}`}>
                  {` #${swapId} `}
                </Link>
              </div>
            )}
          </div>
        </CardHeader>}

        <CardBody>
          {equipmentAlert && (
            <div className="mb-3 pt-2">
              {!!equipmentAlert.nbInfo && <TAlert color="info" id="nbInfo" values={{ value: equipmentAlert.nbInfo }} />}
              {!!equipmentAlert.nbMinor && <TAlert color="warning" id="nbMinor" values={{ value: equipmentAlert.nbMinor }} />}
              {!!equipmentAlert.nbMajor && <TAlert color="danger" id="nbMajor" values={{ value: equipmentAlert.nbMajor }} />}
              {(!!equipmentAlert.nbInfo || !!equipmentAlert.nbMinor || !!equipmentAlert.nbMajor) && equipment && (
                <TButton id="goToEquipmentLog"
                  tag={Link}
                  to={{
                    pathname: `/equipments/${equipment.equipmentId}/logs`,
                    state: location.state
                  }}
                  color="primary" />
              )}
            </div>
          )}

          {constants.equipmentStatus && equipment && equipment.statusId && (
            <>
              {!!constants.equipmentStatus.find(s => s.value === equipment.statusId).alerting && (
                <Alert className="mb-2" color="warning">
                  <T id="equipmentStatusAlert" />
                  {!equipment.childStatusId ? (
                    <T raw id={`equipmentStatus.${constants.equipmentStatus.find(s => s.value === equipment.statusId).key}`} />
                  ) : (
                    <T raw id={`equipmentStatus.${constants.equipmentStatus.find(s => s.value === equipment.statusId).key}.${constants.equipmentStatus.find(s => s.value === equipment.statusId).children.find(c => c.value === equipment.childStatusId).key}`} />
                  )}
                </Alert>
              )}
            </>
          )}
          {isInterventionAnomalyIncomplete && intervention && (
            !intervention.equipment || (intervention.equipment && !intervention.equipment.equipmentIdentifier)
          ) &&
            <>
              {!equipmentAdded
                ? <><TLabel id="equipmentAdd" />
                  {!equipmentsError && equipments && _renderEquipmentSelector()}
                  {equipmentsError && <ErrAlert error={equipmentsError} />}
                </>
                : <><TLabel id="equipmentList" />
                  <ListGroup className="mb-3">
                    <ListGroupItem className="d-flex align-items-center">
                      {equipmentAdded.equipmentIdentifier}
                      <TButton className="ml-auto pt-2 pb-2 mt-0"
                        onClick={() => removeEquipment()}
                        id="removeEquipment" />
                    </ListGroupItem>
                  </ListGroup>
                </>
              }
              {!equipmentAdded && <TAlert color="warning" id="noEquipmentAdded" />}
            </>
          }

          <FormGroup tag="fieldset">
            <TLabel className="mb-0" id="statusValue" />
            {interventionLoading
              ? <Spinner className="ml-2" color="primary" size="sm" />
              :
              <>
                <T raw id={`interventionStatus.${statusValue}`} className="pl-1" />
                {hasStatus('scheduled') && intervention.dateVeloptimScheduled && ' 🤖'}
              </>
            }
          </FormGroup>

          {userAssignFirstname && userAssignLastname && (
            <FormGroup tag="fieldset">
              <TLabel className="mb-0" id="userAssign" />
              {interventionLoading
                ? <Spinner className="ml-2" color="primary" size="sm" />
                : <span>{`${userAssignFirstname} ${userAssignLastname}`}</span>}
            </FormGroup>
          )}

          <FormGroup tag="fieldset">
            <>
              <TLabel className="mb-0" id="datePlanned.label" />
              {interventionLoading
                ? <Spinner className="ml-2" color="primary" size="sm" />
                : <>
                  <T id="datePlanned.value"
                    values={{
                      datePlanned: datePlanned ? new Date(datePlanned) : null,
                      timeStart: timeRangeStart && !startSlot ? new Date().setHours(0, Math.min(timeRangeStart), 0, 0) : undefined,
                      timeEnd: timeRangeStart && !startSlot ? new Date().setHours(0, Math.max(timeRangeEnd), 0, 0) : undefined
                    }}
                    className="pl-1" />
                  {datePlanned && <TButton id="toPlanning" color="primary"
                    tag={NavLink}
                    to={`/planning?i=${intervention.interventionId}`}
                    className="mr-auto ml-2" />}
                </>
              }
            </>
          </FormGroup >

          <FormGroup tag="fieldset">
            {!!reorderTour && !!startSlot && <TAlert id="reorderTour" className="mt-2" color="warning" />}
            {!!startSlot && !!endSlot && (
              <>
                <TLabel id="slot" />
                {timeFromUTC(startSlot)} - {timeFromUTC(endSlot)}
              </>
            )}
          </FormGroup>

          {
            dateDone && <FormGroup tag="fieldset">
              <TLabel className="mb-0" id="dateDone.label" />
              {interventionLoading
                ? <Spinner className="ml-2" color="primary" size="sm" />
                : <T id="dateDone.value"
                  values={{
                    dateDone: dateDone ? new Date(dateDone) : null,
                    timeStart: timeRangeStart ? new Date().setHours(0, Math.min(timeRangeStart), 0, 0) : undefined,
                    timeEnd: timeRangeStart ? new Date().setHours(0, Math.max(timeRangeEnd), 0, 0) : undefined
                  }}
                  className="pl-1" />}
            </FormGroup>
          }

          {
            userCanceled && <FormGroup tag="fieldset">
              <div><T id="userCanceled" values={{ user: userCanceled }} /></div>
              <Alert className="mb-0" color="danger">{
                cancelationReason ? cancelationReason : <T id="noCancelReason" />}</Alert>
            </FormGroup>
          }

          {dateCanceled && !userCanceled && <FormGroup tag="fieldset">
            <div><T id="userCanceledApi" /></div>
            <Alert className="mb-0" color="danger">{
              cancelationReason ? cancelationReason : <T id="noCancelReason" />}</Alert>
          </FormGroup>}

          {
            dateAnomaly && <FormGroup tag="fieldset">
              <T id="userAnomaly" tagName="p" className="mb-1" values={{ user: userAnomaly, date: new Date(dateAnomaly), reason: anomalyReasonValue }} />
              <Alert color="danger">{anomalyInformation || <T id="noAnomalyReason" />}</Alert>
            </FormGroup>
          }

          <FormGroup tag="fieldset">
            <TLabel id="qualifId" for="qualifId" />
            <div>
              {businessQualifLoading && <Spinner className="" color="primary" />}
              {!businessQualifLoading && businessQualifError && <ErrAlert error={businessQualifError} />}
              {!businessQualifLoading && !businessQualifError && (
                <>
                  {constants.interventionQualif.map(qualif =>
                    <React.Fragment key={qualif.key}>
                      {(qualif.value === qualifId || businessQualif.find(bq => bq.key === qualif.key).enabled) && (
                        <FormGroup key={qualif.key} check inline>
                          <TCustomInput
                            id={`qualifId-${qualif.key}`}
                            type="radio"
                            name="qualifId"
                            label={`qualif.${qualif.key}`}
                            disabled={!canEdit}
                            checked={qualifId == qualif.value}
                            value={qualif.value}
                            onChange={() => handleChange({ target: { name: 'qualifId', value: qualif.value } })} />
                        </FormGroup>
                      )}
                    </React.Fragment>
                  )}
                </>
              )}
            </div>
          </FormGroup>

          <TLabel id="itvcodeList" />

          {hasStatus('anomaly') && intervention && !intervention.equipment && !equipmentAdded ? (
            <TAlert color="warning" id="missingEquipmentForItvcode" />
          ) : (
            itvcodesError ? <ErrAlert error={itvcodesError} /> : itvcodesBase && itvcodes && !codesAndMatrixLoading && 
              <ItvcodeSelector
                hasBusiness={Boolean(businessId)}
                loading={interventionLoading || itvcodesLoading}
                editable={!!(canEdit || modifyDone || canCloseRight) && !hasStatus('canceled', 'closed')}
                itvcodesAdded={itvcodes}
                itvcodes={itvcodesBase.result}
                reason={!isInterventionEnded}
                finishedReason={isInterventionEnded}
                isIntervention
                qualif={constants.interventionQualif.find(qualif => qualif.value == qualifId) && constants.interventionQualif.find(qualif => qualif.value == qualifId).key}
                onChange={itvcodes => onItvcodesChange(itvcodes)} />
          )}
          {intervention && intervention.equipment && intervention.equipment.parts && intervention.equipment.parts.length > 0 &&
            <>
              <TLabel id="pieces" />
              <Card className="mb-4">
                <CardBody className="pb-3">
                  {intervention.equipment.parts.map(piece =>
                    <div className="mb-4" key={piece.partName}>
                      <T id={piece.partName} className="d-block" raw />
                      <span className="d-block"><T id="piece.equipmentPartKm" />{piece.equipmentPartKm}</span>
                      <span className="d-block"><T id="piece.partSN" />{piece.partSN}</span>
                    </div>
                  )}
                </CardBody>
              </Card>
            </>}

          {!isInterventionAnomalyIncomplete &&
            <>
              <TLabel id="durations" />
              <Card className="mb-4">
                <CardBody className="pb-3">
                  <div className={`${!hasStatus('waitPec', 'scheduled') && 'row'} mb-2`}>
                    {/* Display /60 hour */}
                    <TLabel
                      id="durationEstimated"
                      values={{
                        hours: Math.floor(durationEstimated / 60),
                        minutes: durationEstimated % 60,
                      }}
                      className="col mb-2 pl-0" />

                    {!hasStatus('waitPec', 'scheduled') ? (
                      <>
                        {durationPlanned != null
                          ? <TLabel
                            id="durationPlanned"
                            values={{
                              hours: Math.floor(durationPlanned / 60),
                              minutes: durationPlanned % 60,
                            }}
                            className="col mb-0" />
                          : <TLabel
                            id="durationPlannedNull"
                            className="col mb-0" />}
                      </>
                    ) : (
                      <>
                        <TLabel id="durationPlannedToChange" className="col mb-1 pl-0" />
                        <Row>
                          <Col>
                            <FormGroup tag="fieldset">
                              <TLabel for="selectHour" id="selectHour" />
                              <CustomSelect
                                inputId="selectHour"
                                name="selectHour"
                                options={itvHours}
                                placeholder={"0"}
                                onChange={e => handleItvHoursChange({ target: { value: e } })}
                                value={itvHours.filter(h => h == itvHourSelected)}
                                getOptionValue={option => option}
                                getOptionLabel={option => option}
                                isDisabled={!canEdit} />
                            </FormGroup>
                          </Col>

                          <Col>
                            <FormGroup tag="fieldset">
                              <TLabel for="selectMinute" id="selectMinute" />
                              <CustomSelect
                                inputId="selectMinute"
                                name="selectMinute"
                                options={itvMinutes}
                                onChange={e => handleItvMinutesChange({ target: { value: e } })}
                                placeholder={"0"}
                                value={itvMinutes.filter(h => h == itvMinuteSelected)}
                                getOptionValue={option => option}
                                getOptionLabel={option => option}
                                isDisabled={!canEdit} />
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                  {(durationChrono != null || durationDone != null) && <div className="row mb-2">
                    {durationChrono != null && <TLabel
                      id="durationChrono"
                      values={{
                        hours: Math.floor(durationChrono / 60),
                        minutes: durationChrono % 60,
                      }}
                      className="col mb-0" />}
                    {currentDuration != null &&
                      <div className="col mb-0">
                        {newDuration != null && newDuration !== currentDuration ? (
                          <TLabel
                            id="durationDone"
                            values={{
                              hours: Math.floor(newDuration / 60),
                              minutes: newDuration % 60,
                            }}
                            className="" />
                        ) : (
                          <TLabel
                            id="durationDone"
                            values={{
                              hours: Math.floor(currentDuration / 60),
                              minutes: currentDuration % 60,
                            }}
                            className="" />
                        )}
                        {hasStatus('done') && !modifyDone && <ProtectedComponent rights={['interventions_durationDoneEdit']}>
                          <>
                            {!changeDuration ? (
                              <i className="simple-icon-pencil ml-2 clickIcon" onClick={toggleDuration} />
                            ) : (
                              <>
                                <div className="d-flex align-items-center h5">
                                  <Duration tagName={Badge} className="mr-2 mb-0" duration={newDuration} />
                                  <TCustomInput type="range" min="1" max="480" step="1"
                                    id="duration"
                                    name="duration"
                                    value={newDuration}
                                    onChange={e => { handleChangeDuration(e) }}
                                    disabled={false} />
                                </div>
                                <TButton spin={transformDurationLoading} id='save' onClick={() => saveDuration()} />
                                <TButton id='cancel' className="mx-1 ml-2" color="danger" onClick={() => cancelDuration()} />
                              </>
                            )}
                            {interventionUpdateDurationError && <ErrAlert className="mt-2" error={interventionUpdateDurationError} />}
                          </>
                        </ProtectedComponent>}
                        {modifyDone && <ProtectedComponent rights={['intervention-update_done']}>
                          <>
                            {!changeDuration ? (
                              <i className="simple-icon-pencil ml-2 clickIcon" onClick={toggleDuration} />
                            ) : (
                              <>
                                <div className="d-flex align-items-center h5">
                                  <Duration tagName={Badge} className="mr-2 mb-0" duration={newDuration} />
                                  <TCustomInput type="range" min="1" max="480" step="1"
                                    id="duration"
                                    name="duration"
                                    value={newDuration}
                                    onChange={e => { handleChangeDuration(e) }}
                                    disabled={false} />
                                </div>
                                <TButton spin={transformDurationLoading} id='save' onClick={() => confirmDuration()} />
                                <TButton id='cancel' className="mx-1 ml-2" color="danger" onClick={() => cancelDuration()} />
                              </>
                            )}
                            {interventionUpdateDurationError && <ErrAlert className="mt-2" error={interventionUpdateDurationError} />}
                          </>
                        </ProtectedComponent>}
                      </div>
                    }
                  </div>}
                </CardBody>
              </Card>
            </>}

          {<FormGroup tag="fieldset" className="mt-3">
            <TLabel id="difficultyId" for="difficultyId" />
            <div className={`difficulty ${!loading && !isInterventionAnomalyIncomplete && difficultyId && constants.difficultyLevels.find(d => Number(d.value) === Number(difficultyId)).key}`}>
              <CustomSelect
                inputId="difficultyId"
                name="difficultyId"
                options={constants.difficultyLevels}
                onChange={e => { handleChange({ target: { name: 'difficultyId', value: e.value } }) }}
                value={constants.difficultyLevels.filter(d => d.value === difficultyId)}
                isDisabled={!canEdit}
                getOptionLabel={option => <T id={`difficulty.${option.key}`} raw />}
                getOptionValue={option => option.value} />
            </div>
          </FormGroup>
          }

          <FormGroup tag="fieldset">
            <TLabel id="claimNumber" />
            <Input type="text" name="claimNumber" id="claimNumber"
              disabled={loading || (!canEdit && !modifyDone) || (isInterventionEnded && !modifyDone)}
              value={claimNumber || ''}
              onChange={handleChange} />
          </FormGroup>

          <FormGroup tag="fieldset">
            <TLabel id="information" />
            <Input type="textarea" name="information" id="information"
              disabled={loading || !canEditInformation}
              value={information || ''}
              onChange={handleChange} />
          </FormGroup>

          <TLabel id="addressTitle" />
          <Card className="mb-4">
            <CardBody>
              {addressesError ? <ErrAlert error={addressesError} /> : canEdit && <FormGroup tag="fieldset">
                <TLabel className="mt-0" id="addresses.label" for="addresses" />
                <CustomSelect
                  inputId="addresses"
                  name="addresses"
                  options={addresses}
                  onChange={addressHandleChange}
                  placeholder={<T id="addresses.manual" />}
                  value={addresses && addresses.filter(a => a.addressId === selectedAddress) || ''}
                  isClearable
                  isDisabled={addressesLoading || loading}
                  getOptionLabel={option => `${option.addressLabel} – ${option.addressFull} ${option.addressComplement && '- ' + option.addressComplement}`}
                  getOptionValue={option => option.addressId} />
              </FormGroup>}

              <FormGroup tag="fieldset" className="flex-grow-1">
                <TLabel for="addressLabel" className="justify-content-start mb-2" id="addressLabel" />
                <Input id="addressLabel"
                  className="w-100"
                  type="text"
                  name="addressLabel"
                  disabled={!canEdit || selectedAddress}
                  value={addressLabel || ''}
                  onChange={e => handleChange(e)} />
              </FormGroup>

              <FormGroup tag="fieldset" className="w-100">
                <TLabel for="addressFull" className="mb-2" id="addressFull" />
                <TAutocomplete id="addressFull"
                  className="w-100"
                  type="text"
                  name="addressFull"
                  disabled={!canEdit || selectedAddress}
                  value={addressFull || ''}
                  placeholder="addressFullPlaceholder"
                  searchOnFocus={e => handleChangeAddressFull(e)}
                  onChange={e => handleChangeAddressFull(e)}
                  onAutocomplete={e => handleAutocomplete(e)}
                  options={
                    autocomplete && autocomplete.results.length > 0 && autocomplete.results.map(r => {
                      return (r.addressFull)
                    })} />
              </FormGroup>

              <FormGroup tag="fieldset" className="w-100 mb-0">
                <TLabel for="addressComplement" className="mb-2" id="addressComplement" />
                <TInput id="addressComplement"
                  className="w-100"
                  type="text"
                  name="addressComplement"
                  disabled={!canEdit || selectedAddress}
                  value={addressComplement || ''}
                  placeholder="addressComplementPlaceholder"
                  onChange={e => handleChange(e)} />
              </FormGroup>
            </CardBody>
          </Card>

          <FormGroup tag="fieldset">
            <TLabel id="locationId" for="locationId" />
            <div>
              {constants.interventionLocation.map(type => <FormGroup key={type.key}
                check inline>
                <TCustomInput
                  id={`locationId-${type.key}`}
                  type="radio"
                  name="locationId"
                  label={`locationId.${type.key}`}
                  disabled={!canEdit}
                  checked={Number(locationId) === type.value}
                  value={type.value}
                  onChange={() => handleChange({ target: { name: 'locationId', value: type.value } })} />
              </FormGroup>)}
            </div>
          </FormGroup>

          <TLabel id="contactTitle" />
          <Card>
            <CardBody>
              <div className="d-flex">
                <FormGroup tag="fieldset" className="w-100 flex-grow-1">
                  <TLabel for="firstName" className="my-0" id="firstName" />
                  <Input name="firstName"
                    type="text"
                    value={firstName || ''}
                    disabled={!canEdit}
                    onChange={e => handleChange(e)} />
                </FormGroup>
                <FormGroup tag="fieldset" className="ml-3 w-100 flex-grow-1">
                  <TLabel for="lastName" className="my-0" id="lastName" />
                  <Input name="lastName"
                    type="text"
                    value={lastName || ''}
                    disabled={!canEdit}
                    onChange={e => handleChange(e)} />
                </FormGroup>
              </div>
              <div className="d-flex">
                <FormGroup tag="fieldset" className="w-100 flex-grow-1">
                  <TLabel for="email" className="my-0" id="email" />
                  <Input name="email"
                    type="text"
                    value={email || ''}
                    disabled={!canEdit}
                    onChange={e => handleChange(e)} />
                </FormGroup>
                <FormGroup tag="fieldset" className="ml-3 mb-0 w-100 flex-grow-1">
                  <TLabel id="phoneNumber" for="phoneNumber" className="my-0" />
                  <div className="d-flex align-items-center">
                    <Input name="phoneNumber"
                      id="phoneNumber"
                      tag={PhoneInput}
                      defaultCountry="FR"
                      country="FR"
                      className={`w-100 d-flex${!canEdit ? ' disabled' : ''}`}
                      type="text"
                      value={phoneNumber || ''}
                      disabled={!canEdit}
                      onChange={value => handleChange({ target: { value, name: 'phoneNumber' } })} />
                    {/* TODO: feature not requested yet */}
                    {false && <NavLink className="simple-icon-phone h4 py-0 px-2 mb-0 text-success" disabled={!phoneNumber} href={`tel:${phoneNumber}`} />}
                  </div>
                </FormGroup>
              </div>
              {subscriptionId && (
                <div>
                  <TLabel id="subscriptionId" />
                  <p className="mb-1">{subscriptionId}</p>
                </div>
              )}
            </CardBody>
          </Card>

          {
            hasStatus('anomaly') &&
            <FormGroup className="my-4">
              <TLabel id="techComments" />
              <Input type="textarea" name="techComments" id="techComments"
                value={techComments || ''}
                readOnly={true}
                onChange={e => handleChange(e)} />
            </FormGroup>}

          {
            hasStatus('anomaly') &&
            <FormGroup className="my-4">
              <TLabel id="anomalyComments" />
              <Input type="textarea" name="anomalyComments" id="anomalyComments"
                value={anomalyComments || ''}
                readOnly={!canEdit || !hasStatus('anomaly')}
                onChange={e => handleChange(e)} />
            </FormGroup>
          }

          {
            hasStatus('anomaly', 'canceled', 'closed', 'done') && equipment && equipment.pics && equipment.pics.anlyUrl &&
            <FormGroup>
              <TLabel id="pictures.equipment.anlyUrl" className="d-block" />
              <input type="image"
                src={equipment.pics.anlyUrl}
                style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }}
                onClick={() => setPictureOpen({ picUrl: equipment.pics.anlyUrl })} />
            </FormGroup>
          }

          {
            isInterventionEnded && <>
              {hasStatus('canceled', 'closed', 'done') && equipmentKm !== null && <FormGroup className="mt-4">
                <TLabel className="mb-0" id="equipmentKm.label" />
                {interventionLoading
                  ? <Spinner className="ml-2" color="primary" size="sm" />
                  : <T id="equipmentKm.value" values={{ equipmentKm }} className="pl-1" />}
              </FormGroup>}

              <FormGroup className="my-4">
                <TLabel id="techComments" />
                <Input type="textarea" name="techComments" id="techComments"
                  value={techComments || ''}
                  readOnly={modifyDone ? false : true}
                  onChange={e => handleChange(e)} />
              </FormGroup>

              <FormGroup className="my-4">
                <TLabel id="anomalyComments" />
                <Input type="textarea" name="anomalyComments" id="anomalyComments"
                  value={anomalyComments || ''}
                  readOnly={true}
                  onChange={e => handleChange(e)} />
              </FormGroup>

              {modifyDone && (
                <>
                  {matrixFieldsFiltered && matrixFieldsFiltered.length > 0 &&
                    <Card>
                      <CardBody>
                        <TLabel className="mb-4" id="codesToLink.description" />
                        {matrixFieldsFiltered.map(code =>
                          <>
                            <FormGroup key={code.itvcodeId} tag="fieldset">
                              <div className="mb-2">
                                <TLabel id="itvcodeLabel" />
                                <span>{code.itvcodeIdentifier} - {code.itvcodeLabel}</span>
                              </div>
                              <div className="mb-2">
                                <TLabel id="pieces" />
                                <T id={`matrix.${code.matrixColumnName}`} />
                              </div>
                              <div className="mb-2">
                                <TLabel id="partSN" />
                                <Input
                                  id="partSN"
                                  name="partSN"
                                  type="text"
                                  onChange={e => { handleMatrixChange(e.target.value, code.matrixColumnName) }}
                                />
                              </div>
                            </FormGroup>
                          </>
                        )}
                      </CardBody>
                    </Card>
                  }
                </>
              )}
              {checklist && checklist.length > 0 && (
                <>
                  {srcFrom !== 'instant' && <TLabel className="mb-2" id="checklist" />}
                  {srcFrom !== 'instant' && <Card className="mb-4">
                    <CardBody className="p-0">
                      <ListGroup>{checklist.map(category => <React.Fragment key={category.checklistCategory}>
                        <ListGroupItem className="border-bottom-0">
                          <T id="checkCategory"
                            values={{
                              category: formatMessage({ id: `check.${category.checklistCategory}`, raw: true })
                            }} />
                        </ListGroupItem>
                        <ListGroupItem className="p-0 border-top-0">
                          <ListGroup>{category.checklistElements.map(element =>
                            <ListGroupItem key={element.key}
                              className="border-left-0 border-right-0 border-bottom-0 pl-4 rounded-0">
                              <T id="checkItem"
                                values={{
                                  item: formatMessage({ raw: true, id: `check.${element.key}` }),
                                  value: formatMessage({
                                    raw: true, id: `check.${element.values.find(val => val.value === element.value)
                                      ? element.values.find(val => val.value === element.value).key
                                      : 'na'}`
                                  })
                                }} />
                            </ListGroupItem>
                          )}
                          </ListGroup>
                        </ListGroupItem>
                      </React.Fragment>)}
                      </ListGroup>
                    </CardBody>
                  </Card>}
                </>
              )}
              <FormGroup className="mb-0">
                <TLabel id="postComments" />
                <Input type="textarea" name="postComments" id="postComments"
                  disabled={loading || !canEdit || !isInterventionEnded}
                  value={postComments || ''}
                  onChange={handleChange} />
              </FormGroup>
            </>
          }
        </CardBody >

        {intervention && qualityControlDetails && qualityControlDetails.userIdQualityControl && qualityControlDetails.checklist && qualityControlDetails.checklist.length > 0 ? (
          <>
            <CardBody className="Section">
              <Button className="mb-2" onClick={() => { setQualityControlVisible(!qualityControlVisible) }}>
                <T id="qualityControl.title" />{' - '}
                <T id={qualityControlVisible ? 'qualityControl.seeLess' : 'qualityControl.seeMore'} />
              </Button>
              {qualityControlDetails.checklist_nok && (
                <TAlert id="checklistNok" color="warning" />
              )}
              {qualityControlDetails.checklist_watch && (
                <TAlert id="checklistWatch" color="warning" />
              )}
              {qualityControlVisible && qualityControlDetails.checklist && qualityControlDetails.checklist.length > 0 && qualityControlDetails.checklist.map(item =>
                <Card key={item.interventionChecklistId}>
                  <CardBody className='pb-3 pt-3'>
                    <Label className="mb-2">{item.checkLabel}</Label>
                    <div>
                      <TLabel id="checklistStatus" />{' '}
                      {item.status === 1 && <T id='check.ko' raw />}
                      {item.status === 2 && <T id='check.watch' raw />}
                      {item.status === 3 && <T id='check.ok' raw />}
                    </div>
                    {item.comments && (
                      <div>
                        <TLabel id="comments" />{' '}
                        <span key={`comments-${item.priority}`}>{item.comments}</span>
                      </div>
                    )}
                  </CardBody>
                </Card>
              )}
            </CardBody>
          </>
        ) : (
          <>
            {intervention && intervention.checklistitems && intervention.checklistitems.items && intervention.checklistitems.items.length > 0 && intervention.srcFrom !== 'instant' && (
              <CardBody className="Section">
                <Button className="mb-2" onClick={() => { setChecklistVisible(!checklistVisible) }}>
                  <T id="checklist.title" />{' - '}
                  <span>{intervention.checklistitems.ok}/{intervention.checklistitems.ok + intervention.checklistitems.watch + intervention.checklistitems.nok}{' '}<T id="checklist.ok" />{' - '}</span>
                  {!!intervention.checklistitems.watch && <span>{intervention.checklistitems.watch}{' '}<T id="checklist.watch" />{' - '}</span>}
                  {!!intervention.checklistitems.nok && <span>{intervention.checklistitems.nok}{' '}<T id="checklist.nok" />{' - '}</span>}
                  <T id={checklistVisible ? 'checklist.seeLess' : 'checklist.seeMore'} />
                </Button>
                {checklistVisible && intervention.checklistitems.items.map(item =>
                  <Card key={item.interventionChecklistId}>
                    <CardBody className='pb-3 pt-3'>
                      <Label className="mb-2">{item.checkLabel}</Label>
                      <div>
                        <TLabel id="checklistStatus" />{' '}
                        <T id={`check.${item.statusValue}`} raw />
                      </div>
                      {item.comments && (
                        <div>
                          <TLabel id="comments" />{' '}
                          <span key={`comments-${item.priority}`}>{item.comments}</span>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                )}
              </CardBody>
            )}
          </>
        )}

        {equipment && equipment.pics && (equipment.pics.fot1Url || equipment.pics.fot2Url || equipment.pics.fot3Url) &&
          <CardBody style={{ borderTop: '1px solid rgba(0,0,0,.125)' }}>
            <TLabel className="mb-2" id="fot" />
            <Card>
              {equipment.pics.fot1Url &&
                <>
                  <CardHeader><T id="pictures.equipment.fot1" /></CardHeader>
                  <CardBody className="d-flex align-items-start">
                    <input type="image"
                      src={equipment.pics.fot1Url}
                      style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }}
                      onClick={() => setPictureOpen({ picUrl: equipment.pics.fot1Url })} />
                  </CardBody>
                </>
              }
              {equipment.pics.fot2Url &&
                <>
                  <CardHeader><T id="pictures.equipment.fot2" /></CardHeader>
                  <CardBody className="d-flex align-items-start">
                    <input type="image"
                      src={equipment.pics.fot2Url}
                      style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }}
                      onClick={() => setPictureOpen({ picUrl: equipment.pics.fot2Url })} />
                  </CardBody>
                </>
              }
              {equipment.pics.fot3Url &&
                <>
                  <CardHeader><T id="pictures.equipment.fot3" /></CardHeader>
                  <CardBody className="d-flex align-items-start">
                    <input type="image"
                      src={equipment.pics.fot3Url}
                      style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }}
                      onClick={() => setPictureOpen({ picUrl: equipment.pics.fot3Url })} />
                  </CardBody>
                </>
              }
            </Card>
          </CardBody>
        }

        {
          hasCheckout &&
          <CardBody style={{ borderTop: '1px solid rgba(0,0,0,.125)' }}>
            <TLabel className="mb-2" id="checkout" />
            {!!reportLink && (
              <div>
                <Button
                  className="mb-2"
                  // style={{ alignItems: 'center', justifyContent: 'center' }}
                  tag="a"
                  target="_blank"
                  href={reportLink}>
                  <i style={{ fontSize: 16 }} className="simple-icon-docs mb-0 mr-2" />
                  <T id="reportButton" />
                </Button>
              </div>
            )}
            {checkoutSigned === 0 || !checkoutSigned && (
              <div className="mb-4">
                <T id="noSignature" />
              </div>
            )}
            <>
              {checkoutComments && (
                <div className="mb-4">
                  <TLabel className="mt-4" id="checkoutComments" />
                  <p className="mb-1" style={{ whiteSpace: 'pre-line' }}>{checkoutComments}</p>
                </div>
              )}
              {equipment && equipment.pics &&
                  <Card>
                    {equipment.pics.pic1Url &&
                      <>
                        <CardHeader><T id="pictures.equipment.pic1" /></CardHeader>
                        <CardBody className="d-flex align-items-start">
                          <input type="image"
                            src={equipment.pics.pic1Url}
                            style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }}
                            onClick={() => setPictureOpen({ picUrl: equipment.pics.pic1Url })} />
                        </CardBody>
                      </>
                    }
                    {equipment.pics.pic2Url &&
                      <>
                        <CardHeader><T id="pictures.equipment.pic2" /></CardHeader>
                        <CardBody className="d-flex align-items-start">
                          <input type="image"
                            src={equipment.pics.pic2Url}
                            style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }}
                            onClick={() => setPictureOpen({ picUrl: equipment.pics.pic2Url })} />
                        </CardBody>
                      </>
                    }
                    {equipment.pics.pic3Url &&
                      <>
                        <CardHeader><T id="pictures.equipment.pic3" /></CardHeader>
                        <CardBody className="d-flex align-items-start">
                          <input type="image"
                            src={equipment.pics.pic3Url}
                            style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }}
                            onClick={() => setPictureOpen({ picUrl: equipment.pics.pic3Url })} />
                        </CardBody>
                      </>
                    }
                    {checkoutSigned && equipment.pics.signUrl &&
                      <>
                        <CardHeader><T id="pictures.equipment.signUrl" /></CardHeader>
                        <CardBody className="d-flex align-items-start">
                          <input type="image"
                            src={equipment.pics.signUrl}
                            style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }}
                            onClick={() => setPictureOpen({ picUrl: equipment.pics.signUrl })} />
                        </CardBody>
                      </>
                    }
                  </Card>
              }
            </>
          </CardBody>
        }

        <CardBody className="Section">
          <TButton className="mb-2" id={showHistory ? "hideHistory" : "showHistory"} onClick={() => setShowHistory(!showHistory)} />
          {showHistory && (
            <div>
              {itvHistory && itvHistory.map(th => parseItvHistory(th))}
              {itvHistoryError && <ErrAlert className="mb-0 mt-4" error={itvHistoryError.code} />}
            </div>
          )}
        </CardBody>

        {
          pictureOpen && <Modal isOpen={true} fade={true} toggle={() => setPictureOpen(undefined)} size="lg">
            <ModalBody>
              <div className="position-relative">
                <i
                  onClick={() => setPictureOpen(undefined)}
                  className="iconsmind-Close-Window p-2 h3"
                  style={{ position: 'absolute', top: 0, right: 0 }} />
                <img src={pictureOpen.picUrl} style={{ maxWidth: '100%' }} />
              </div>

              <a href={pictureOpen.picUrl} target="_blank" rel="noreferrer" className="mt-2 d-block"><T id="pictures.seefull" /></a>
            </ModalBody>
          </Modal>
        }

        {
          <Modal isOpen={qualityModal && qualityControl && qualityControl.checklist && qualityControl.checklist.length > 0} fade={true} toggle={() => { setQualityModal(false) }} size="lg">
            <ModalBody>
              <TLabel className="mb-3 d-block" id="qualityControl.title" />
              <Button
                className="mb-3"
                onClick={handleAllOk} >
                <T id="allOk" />
              </Button>
              {qualityControl && qualityControl.checklist && qualityControl.checklist.length > 0 && qualityControl.checklist.map(item => (
                <Card key={item.priority}>
                  <CardBody className='pb-3 pt-3'>
                    <FormGroup tag="fieldset" className="pb-0">
                      <span className="d-block" style={{ fontWeight: 'bold', marginBottom: 8 }}>{item.checkLabel}</span>
                      <div className='d-flex flex-rox mb-2'>
                        {Object.entries(CHECKLIST_STATUS).map(statusItem =>
                          <TCustomInput
                            id={`${item.checkLabel}.${statusItem[1]}`}
                            raw
                            key={`${item.checkLabel}.${statusItem[1]}`}
                            type="radio"
                            name={`${item.checkLabel}`}
                            label={`check.${statusItem[0]}`}
                            disabled={loading}
                            className="mr-4"
                            onChange={(e) => handleCheckChange({ item, status: parseInt(e.target.value) })}
                            checked={parseInt(item.status) === statusItem[1]}
                            value={parseInt(statusItem[1])} />
                        )}
                      </div>
                      <div>
                        <TLabel id="comments" />
                        <Input
                          id={`${item.checkLabel}.comments`}
                          name="comments"
                          type="text"
                          value={item.comments || ""}
                          onChange={e => { handleCheckChange({ item, comments: e.target.value }) }}
                        />
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              ))}
              <ModalFooter>
                <div className="w-100">
                  {qualityControlError && <TAlert id={qualityControlError} color="danger" />}
                </div>
                <TButton className="mr-2" id="cancel" onClick={() => { setQualityModal(false) }} />
                <TButton id="save" onClick={handleSaveQualityControl} />
              </ModalFooter>
            </ModalBody>
          </Modal>
        }

        <CardFooter>
          {interventionUpdateError && <ErrAlert error={interventionUpdateError} values={{ value: interventionUpdateError.itvcodes && interventionUpdateError.itvcodes.map((code, i) => i === 0 ? code : `, ${code}`) }} />}
          {matrixError && <ErrAlert error={matrixError} color="danger" />}
          {planSuccess && <TAlert id="planSuccess" color="success" />}
          {errorBC && <TAlert id="errorBC" values={{ value: errorBC }} color="danger" />}
          {qualityControlSuccess && <TAlert id="qualityControlSuccess" color="success" />}
          <div className="d-flex">
            {!hasStatus('canceled', 'closed') && (
              <>
                <ProtectedComponent rights={['interventions_cancel']}>
                  <TButton disabled={!canEdit}
                    id="cancel"
                    className="mx-1"
                    onClick={() => setConfirmCancel(true)} color="danger" />
                </ProtectedComponent>
                <ProtectedComponent rights={['interventions_transform-swaps']}>
                  {hasStatus('anomaly', 'scheduled') && <TButton disabled={loading || isInterventionAnomalyIncomplete}
                    spin={transformLoading && !interventionUpdateError}
                    className="mx-1"
                    onClick={() => handleTransform(interventionId)}
                    id="transform" />}
                </ProtectedComponent>
                <div className="ml-auto">
                  {!hasStatus('done') && (
                    <ProtectedComponent rights={['interventions_new']}>
                      <TButton className="mx-1" disabled={loading}
                        id="save"
                        spin={interventionUpdateLoading}
                        onClick={() => handleSave()} />
                    </ProtectedComponent>
                  )}
                  {hasStatus('anomaly') &&
                    <ProtectedComponent rights={['interventions_schedule']}>
                      <TButton disabled={loading || isInterventionAnomalyIncomplete}
                        spin={interventionUpdateLoading}
                        className="mx-1 my-1"
                        onClick={() => handleReschedule(interventionId)}
                        id="reprog" />
                    </ProtectedComponent>
                  }
                  {!hasStatus('anomaly') && !hasStatus('done') &&
                    <ProtectedComponent rights={['interventions_schedule', 'interventions_new']}>
                      <>
                        {((user.enableVeloptim && subsidiary.enableVeloptim) || user.email == 'email-admin@test.tld') ? (
                          <>
                            <TButton className="mx-1 my-1" disabled={loading || !hasStatus('waitPec', 'scheduled') || isInterventionAnomalyIncomplete}
                              id="saveAndPlanVeloptim"
                              spin={interventionUpdateLoading}
                              onClick={() => handleSaveAndPlanVeloptim()} />
                            <TButton className="mx-1 my-1" disabled={loading || !hasStatus('waitPec', 'scheduled') || isInterventionAnomalyIncomplete}
                              id="saveAndPlanWithoutVeloptim"
                              spin={interventionUpdateLoading}
                              onClick={() => handleSaveAndPlan()} />
                          </>
                        ) : (
                          <TButton className="mx-1 my-1" disabled={loading || !hasStatus('waitPec', 'scheduled') || isInterventionAnomalyIncomplete}
                            id="saveAndPlan"
                            spin={interventionUpdateLoading}
                            onClick={() => handleSaveAndPlan()} />
                        )}
                      </>
                    </ProtectedComponent >}
                  {hasStatus('done') && <ProtectedComponent rights={['interventions_close']}>
                    <TButton className="mx-1 my-1" disabled={loading}
                      id="close"
                      onClick={() => setConfirmClose(true)} color="primary" />
                    <TButton type="button" className="mx-1 my-1" color="primary" onClick={e => handleDuplicate(e)} id="duplicate" />
                  </ProtectedComponent >}
                  {hasStatus('done') && !checkoutSigned && <ProtectedComponent rights={['intervention-update_done']}>
                    {modifyDone ? (
                      <>
                        <TButton className="mx-1 my-1" disabled={loading}
                          id="cancel"
                          onClick={() => handleCancelModifyDone()} color="warning" />
                        <TButton className="mx-1 my-1" disabled={loading}
                          id="saveModfication"
                          onClick={() => handleSaveModifyDone()} color="primary" />
                      </>
                    ) : (
                      <>
                        {intervention.equipment && intervention.equipment.equipmentId && (
                          <TButton className="mx-1 my-1" disabled={loading}
                            id="modify"
                            onClick={() => setModifyDone(true)} color="primary" />
                        )}
                      </>
                    )}
                  </ProtectedComponent>}
                  {hasStatus('done') && qualityControlDetails && qualityControlDetails.checklist && qualityControlDetails.checklist.length > 0 && (
                    <ProtectedComponent rights={['controlquality_edit']}>
                      <TButton className="mx-1 my-1" disabled={loading}
                        id={`${qualityControlDetails && qualityControlDetails.userIdQualityControl && qualityControlDetails.checklist && qualityControlDetails.checklist.length > 0 ? 'requality' : 'quality'}`}
                        onClick={() => { setQualityModal(true) }} color="primary" />
                    </ProtectedComponent >
                  )}
                  {hasStatus('anomaly') && <TButton disabled={loading || isInterventionAnomalyIncomplete}
                    spin={interventionUpdateLoading}
                    className="mx-1 my-1"
                    onClick={() => handleEndAnomaly(interventionId)}
                    id="endAnomaly" />}
                  {(hasStatus('scheduled') || hasStatus('waitPec')) &&
                    <ProtectedComponent rights={['interventions-end_web']}>
                      <TButton
                        disabled={loading}
                        className="mx-1 my-1"
                        id="start"
                        tag={NavLink}
                        to={`./start/${intervention.interventionId}`}
                        onClick={handleTrack}
                      />
                    </ProtectedComponent>}
                </div >
              </>
            )}
            {
              (hasStatus('closed') || hasStatus('canceled')) &&
              <div className="ml-auto">
                <TButton type="button" className="mx-1 my-1" color="primary" onClick={e => handleDuplicate(e)} id="duplicate" />
              </div >}
          </div >
        </CardFooter >
      </Card >

      <Modal isOpen={confirmClose} fade={true} toggle={() => setConfirmClose(false)}>
        <ModalBody>
          <div className="mb-1"><T id="modal.closeContent" /></div>
          {closeError && <ErrAlert style={{ height: 'auto' }} className="mb-0 mt-2" error={closeError} />}
          {closeErrorVeloparts && closeErrorVeloparts.length >= 0 &&
            <Alert className="mb-0" color="danger" >
              <T id='closeErrorVeloparts' />
              {closeErrorVeloparts.map((e, i) => (
                <>{i === 0 ? e : `,${e}`}</>
              ))}
            </Alert>}
        </ModalBody>
        <ModalFooter>
          <Button disabled={closeLoading}
            onClick={() => setConfirmClose(false)}>
            <T id="modal.closeCancel" />
          </Button>
          <Button disabled={closeLoading} spin={closeLoading}
            className="ml-2" color="success" onClick={() => handleClose()}>
            <T id="modal.closeConfirm" />
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalDuplicate} fade={true} toggle={() => setModalDuplicate(!modalDuplicate)}>
        <ModalBody>
          <div className="mb-1"><T id="modal.duplicateQuality" /></div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => duplicate(1)}>
            <T id="modal.duplicateQuality.yes" />
          </Button>
          <Button
            className="ml-2" onClick={() => duplicate(0)}>
            <T id="modal.duplicateQuality.no" />
          </Button>
        </ModalFooter>
      </Modal>

      {
        preventUpdate && <Modal isOpen={true} fade={true} toggle={() => setPreventUpdate()}>
          <ModalBody>
            <T id="modal.preventUpdate.content" values={preventUpdate} />

            <ListGroup>
              {preventUpdate.reasons && preventUpdate.reasons.map(reason => (
                <ListGroupItem key={reason}><T id={`modal.preventUpdate.reason.${reason}`} values={preventUpdate} /></ListGroupItem>
              ))}
            </ListGroup>
          </ModalBody>
          <ModalFooter>
            <Button disabled={interventionDeleteLoading} spin={interventionDeleteLoading}
              onClick={() => setPreventUpdate()}>
              <T id="modal.preventUpdate.cancel" />
            </Button>
            <Button disabled={interventionDeleteLoading} spin={interventionDeleteLoading}
              className="ml-2" color="danger" onClick={() => preventUpdate.callback({
                force: preventUpdate.reasons
              })}>
              <T id="modal.preventUpdate.confirm" />
            </Button>
          </ModalFooter>
        </Modal>
      }

      <Modal isOpen={confirmCancel} fade={true} toggle={() => setConfirmCancel(false)}>
        <ModalBody>
          <div className="mb-1"><T id="modal.cancelContent" /></div>
          <TLabel for="cancelationReason" id="modal.cancelationReason" />
          <TInput id="cancelationReason"
            type="textarea" name="cancelationReasonTemp"
            value={cancelationReasonTemp}
            onChange={e => setCancelationReasonTemp(e.target.value)}
            placeholder="modal.cancelationReasonPlaceholder" />
          {interventionDeleteError && <ErrAlert className="mb-0 mt-2" error={interventionDeleteError} />}
        </ModalBody>
        <ModalFooter>
          <Button disabled={interventionDeleteLoading} spin={interventionDeleteLoading}
            onClick={() => setConfirmCancel(false)}>
            <T id="modal.cancelCancel" />
          </Button>
          <Button disabled={interventionDeleteLoading} spin={interventionDeleteLoading}
            className="ml-2" color="danger" onClick={() => cancel()}>
            <T id="modal.cancelConfirm" />
          </Button>
        </ModalFooter>
      </Modal>


      <Modal isOpen={confirmPlanModal} fade={true} toggle={() => setConfirmPlanModal(false)}>
        <ModalBody>
          <div className="mb-1"><T id="modal.confirmPlanVeloptim" /></div>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={loading || !hasStatus('waitPec', 'scheduled') || isInterventionAnomalyIncomplete}
            spin={interventionUpdateLoading}
            onClick={() => setConfirmPlan(true)}>
            <T id="modal.confirm" />
          </Button>
          <Button
            spin={interventionUpdateLoading}
            disabled={loading || !hasStatus('waitPec', 'scheduled') || isInterventionAnomalyIncomplete}
            className="ml-2" color="danger" onClick={() => setConfirmPlanModal(false)}>
            <T id="modal.cancel" />
          </Button>
        </ModalFooter>
      </Modal>



      {
        ((user.enableVeloptim && subsidiary.enableVeloptim) || user.email == 'email-admin@test.tld') && intervention && veloptimModal && (
          <VeloptimPlanModal
            location={location}
            typeIntervention="intervention"
            id={intervention.interventionId}
            itvcodes={itvcodesForPlan}
            familyId={equipment && equipment.familyId}
            mapId={intervention.mapId}
            durationPlanned={durationPlanned || durationEstimated}
            onDismiss={() => { setVeloptimModal(false) }}
            isOpen={veloptimModal} />
        )
      }
    </div >
  )
}

export default InterventionEdit
