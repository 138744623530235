import React from 'react'
import { FormattedNumber } from 'react-intl'
import {
  NavLink,
  Col, Alert, Label, Input, CustomInput,
} from 'reactstrap'
import DatePicker from 'react-datepicker'
import { registerLocale } from 'react-datepicker'
import * as fnsLocales from 'date-fns/esm/locale'
import NumericInput from 'react-numeric-input'

import Button from './Button'
import { AppContext } from 'contexts/AppContext'

import { useFormatMessage } from 'hooks/intl.hooks'

export const T = ({ id, values, raw, tagName: TagName = 'span', ...props }) => {
  const formatMessage = useFormatMessage()

  return (
    <TagName {...props}>
      {formatMessage({ id, raw }, values)}
    </TagName>
  )
}

export const TBit = ({ bitmask, descriptor, join = ', ', prefix = '', raw = false }) => {
  const formatMessage = useFormatMessage()

  const message = React.useMemo(() => descriptor.filter(d => d.bit & bitmask).map(d => formatMessage({ id: `${prefix}${d.key}`, raw })).join(join), [bitmask, descriptor, formatMessage, join, prefix, raw])

  return message
}

export const TProps = ({ translateProps, tagName: TagName, ...props }) => {
  const formatMessage = useFormatMessage()

  return <TagName
    {...Object.entries(translateProps).reduce((acc, [entryKey, entryValue]) => {
      acc[entryKey] = formatMessage({ id: entryValue })
      return acc
    }, {})}
    {...props}
  />
}

const handleTranslation = (message, raw = false) => typeof message === 'object'
  ? [{ id: message.id, raw }, message.values]
  : message ? [{ id: message, raw }] : undefined

export const Colxx = (props) => <Col {...props} widths={['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']} />

export const TAlert = (props) => <T tagName={Alert} {...props} />
export const ErrAlert = ({ error, ...props }) => <TAlert raw
  id={`error.${(typeof error === 'object' ? error.code : error) || 'technical'}`}
  values={error && error.params}
  color="danger"
  {...props} />

export const TNavLink = (props) => <T tagName={NavLink} {...props} />
export const TLabel = ({ className, ...props }) => <T tagName={Label} className={className === undefined ? '' : className} {...props} />

export const TButton = ({ id, values, raw, loading, ...props }) => <Button {...props}>
  {loading && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
  <T raw={raw} id={id} values={values} />
</Button>

export function TInput({ placeholder, raw, ...props }) {
  const formatMessage = useFormatMessage()

  return <Input placeholder={formatMessage(...handleTranslation(placeholder, raw))} {...props} />
}

export function TAutocomplete({ placeholder, raw, options, onAutocomplete, searchOnFocus, ...props }) {
  const formatMessage = useFormatMessage()
  const [focus, setFocus] = React.useState(false)
  const [activeOption, setActiveOption] = React.useState(0)

  const handleFocus = React.useCallback(e => {
    e.target && e.target.value && searchOnFocus(e)
    setFocus(true)
  }, [searchOnFocus])

  const handleBlur = React.useCallback(e => {
    e.relatedTarget && e.relatedTarget.className && e.relatedTarget.className === 'option-active' && e.relatedTarget.innerText && onAutocomplete(e.relatedTarget.innerText)
    setFocus(false)
  }, [onAutocomplete])

  const onKeyDown = React.useCallback(e => {
    if (e.keyCode === 40) {
      if (activeOption - 1 === options.length) {
        setFocus(true)
      }
      if (activeOption === options.length - 1) {
        setActiveOption(0)
        return
      }
      setActiveOption(activeOption + 1)
    }
    if (e.keyCode === 38) {
      if (activeOption === 0) {
        setActiveOption(options.length - 1)
        return
      }
      setActiveOption(activeOption - 1)
    }
    if (e.keyCode === 13 && options[activeOption]) {
      onAutocomplete(options[activeOption])
      setFocus(false)
    }
  }, [activeOption, options, onAutocomplete])

  const onMouseOver = React.useCallback(e => {
    setActiveOption(e)
  }, [])

  return (
    <>
      <Input placeholder={formatMessage(...handleTranslation(placeholder, raw))} onFocus={handleFocus} onBlur={handleBlur} onKeyDown={onKeyDown} {...props} />
      {options && options.length > 0 && focus && (
        <ul className="options autocomplete-input">
          {options.map((opt, i) => {
            let className
            if (i === activeOption) {
              className = 'option-active';
            }
            return (
              <li tabIndex={i} onMouseOver={() => { onMouseOver(i) }} className={className} key={opt} >
                {opt}
              </li>
            );
          })}
        </ul>
      )}
    </>
  )
}

export function TCustomInput({ label, raw, ...props }) {
  const formatMessage = useFormatMessage()

  return <CustomInput label={label && formatMessage(...handleTranslation(label, raw))} {...props} />
}

export const Toption = ({ id, raw, ...props }) => {
  const formatMessage = useFormatMessage()

  return <option {...props}>{formatMessage(...handleTranslation(id, raw))}</option>
}

export const Toptgroup = ({ id, raw, children, ...props }) => {
  const formatMessage = useFormatMessage()

  return <optgroup {...props} label={formatMessage(...handleTranslation(id, raw))}>{children}</optgroup>
}

export const TDatePicker = ({ selected, ...props }) => {
  const { language } = React.useContext(AppContext)
  let locale = 'fr-FR'
  switch (language.langIdentifier) {
  case 'fr-FR':
    locale = 'fr'
    break
  case 'en-GB':
    locale = 'enGB'
    break
  default:
    locale = 'fr'
  }
  const date = selected ? new Date(selected) : selected
  registerLocale(locale, fnsLocales[locale])
  return <DatePicker locale={locale} {...props} selected={date} />
}

export const TCurrency = ({ ...props }) => {
  const { subsidiary } = React.useContext(AppContext)
  return <FormattedNumber style="currency" currency={getCurrencyNameUtility(subsidiary)} {...props} />
}

export const TCustomNumericInput = ({ onChange, canEdit, ...props }) => {
  const { subsidiary } = React.useContext(AppContext)
  let format = val => val
  if (props.type && props.type === 'currency') {
    if (subsidiary.currencyIdentifier === '€') {
      format = canEdit ? undefined : val => val + ' €'
    }
    if (subsidiary.currencyIdentifier === '£') {
      format = canEdit ? undefined : val => '£' + val
    }
  }
  return (
    <Input
      tag={NumericInput}
      format={format}
      {...props}
      // See NumericInput doc for more information https://github.com/vlad-ignatov/react-numeric-input
      onChange={(valueAsNumber/*, valueAsString, input*/) =>
        onChange({ target: { name: props.name, value: valueAsNumber } })} />
  )
}

const getCurrencyNameUtility = subsidiary => {
  switch (subsidiary.currencyIdentifier) {
  case '€':
    return 'EUR'
  case '£':
    return 'GBP'
  default:
    return 'EUR'
  }
}
