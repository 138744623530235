import React from 'react'
import { useHistory, Link } from 'react-router-dom'

import {
  Card, CardBody, CardFooter,
  Form, FormGroup,
  Input,
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import { ErrAlert, TLabel, TButton, TNavLink } from 'components/TComponents'

const NewBase = () => {
  const { api } = React.useContext(AppContext)
  const history = useHistory()

  const [baseName, setBaseName] = React.useState('')
  const [baseIdentifier, setBaseIdentifier] = React.useState('')

  const [creating, setCreating] = React.useState(false)
  const [createError, setCreateError] = React.useState()

  const create = (e) => {
    e.preventDefault()

    setCreating(true)
    setCreateError()
    api.post('/bases/details', { body: JSON.stringify({ baseName, baseIdentifier, }) })
      .then(() => history.push('/admin/bases'))
      .catch(error => {
        setCreateError(error)
        setCreating(false)
      })
  }

  return (
    <>
      <TNavLink tag={Link} to="/admin/bases" id="returnToList" />
      <Card className="mb-2" tag={Form}
        onSubmit={e => create(e)}>
        <CardBody>
          <FormGroup tag="fieldset">
            <TLabel id="baseIdentifier" for="baseIdentifier" />
            <Input id="baseIdentifier"
              type="text"
              name="baseIdentifier"
              disabled={creating}
              value={baseIdentifier}
              onChange={e => setBaseIdentifier(e.target.value)} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel id="baseName" for="baseName" />
            <Input id="baseName"
              type="text"
              name="baseName"
              disabled={creating}
              value={baseName}
              onChange={e => setBaseName(e.target.value)} />
          </FormGroup>
          {createError && <ErrAlert className="mb-0" error={createError} />}
        </CardBody>
        <CardFooter className="d-flex justify-content-end">
          <TButton id="create" disabled={creating} type="submit" className="ml-2" color="primary" />
          <TButton id="cancel" disabled={creating} type="cancel" tag={Link} className="ml-2" to="/admin/bases" />
        </CardFooter>
      </Card>
    </>
  )
}

export default NewBase
