import React from 'react'

import {
  Button as RSButton, Spinner,
} from 'reactstrap'

const Button = ({ type, spin, children, ...props }, ref) => (
  <RSButton type={type || 'button'} {...props} ref={ref}>
    {spin && <Spinner size="sm" className="mr-2" />}
    {children}
  </RSButton>
)

const ForwardedButton = React.forwardRef(Button)

export default ForwardedButton
