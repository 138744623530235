import React from 'react'
import {
  Card, CardTitle,
  Form, Input,
  Button, FormGroup
} from 'reactstrap'
import { useLocation, Link } from 'react-router-dom'

import { AppContext } from 'contexts/AppContext'

import { T, TButton, TLabel, TNavLink, ErrAlert } from 'components/TComponents'

const Login = () => {
  const location = useLocation()
  const { loading, api, login, changePassword } = React.useContext(AppContext)

  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')

  const [urlError, setUrlError] = React.useState()

  const [loginLoading, setLoginLoading] = React.useState(false)
  const [loginError, setLoginError] = React.useState()

  const [ssoUrlLoading, setSsoUrlLoading] = React.useState(false)
  const [ssoUrlError, setSsoUrlError] = React.useState()

  React.useEffect(() => {

    const params = new URLSearchParams(location.search)
    const error = params.get('error')
    const tokenUrl = params.get('token')

    if (error) {
      setUrlError({ code: error })
    } else if (tokenUrl) {
      setLoginLoading(true)
      login(tokenUrl)
        .catch(response => setLoginError(response))
        .then(() => setLoginLoading(false))
    }
  }, [location.search, login])

  const onUserLogin = React.useCallback((e) => {
    e.preventDefault()
    if (email !== '' && password !== '') {
      setLoginLoading(true)
      api.post('/auth/signin', { body: JSON.stringify({ email, password, }) })
        .then(response => {
          setLoginLoading(false)
          login(response.accessToken)
        })
        .catch(response => {
          setLoginLoading(false)
          if (response.code === 'passwordExpired') {
            changePassword(response.accessToken)
          } else {
            setLoginError(response)
          }
        })
    }
  }, [api, login, email, password, changePassword])

  const getSsoUrl = React.useCallback(() => {
    setSsoUrlLoading(true)
    return api.get('/auth/azuread')
      .then(response => { window.location.href = response.redir_to })
      .catch(response => {
        setSsoUrlLoading(false)
        setSsoUrlError(response)
      })
  }, [api])

  const disabled = loading || loginLoading || ssoUrlLoading

  return (
    <Card className="auth-card">
      <div className="position-relative image-side d-flex flex-column">
        <img src="assets/img/velogik-digital-fg.svg" alt="logo velocare" className="ml-auto mr-auto mb-3" />
        <T tagName="p" id="disclaimer.content" className="white ml-auto mr-auto" />
        <T tagName="p" id="version" values={{ VERSION }} className="white" />
      </div>
      <div className="form-side">
        <span className="logo-single mb-4" />

        <T tagName={CardTitle} id="form.title" className="mb-4" />

        <Form onSubmit={event => onUserLogin(event)}
          className="pb-4"
          style={{
            borderBottomWidth: 1,
            borderBottomColor: '#d7d7d7',
            borderBottomStyle: 'solid'
          }}>
          <FormGroup className="form-group has-float-label mb-4">
            <Input id="email"
              type="email"
              name="email"
              placeholder="Email"
              disabled={disabled}
              onChange={e => setEmail(e.target.value)} />
            <TLabel id="form.email" for="email" className="" />
          </FormGroup>
          <FormGroup className="form-group has-float-label mb-4">
            <Input id="password"
              type="password"
              name="password"
              disabled={disabled}
              onChange={e => setPassword(e.target.value)} />
            <TLabel id="form.password" for="password" className="" />
          </FormGroup>
          <ErrAlert error={loginError} isOpen={Boolean(loginError)} toggle={() => setLoginError()} />
          <div className="d-flex align-items-center">
            <TNavLink id="forgotPassword" className="text-primary pl-0" tag={Link} to={{
              pathname: "/forgot-password",
              state: { email }
            }} />
            <TButton
              id="form.submit"
              type="submit"
              color="primary"
              className="ml-auto btn-shadow"
              size="lg"
              disabled={disabled} />
          </div>
        </Form>

        <div className="mt-4">
          <T id="signin" />
          <Button className="d-flex align-items-center justify-content-center mt-4 py-2"
            color="primary"
            size="lg"
            block
            onClick={() => getSsoUrl()}
            disabled={disabled}>
            <img src="assets/img/ms.svg" alt="logo microsoft" className="mr-2" height="24" />
            <T id="sso" />
          </Button>
        </div>

        {(ssoUrlError || urlError) && <ErrAlert error={ssoUrlError || urlError} />}
      </div>
    </Card>
  )
}

export default Login
