import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedDate, FormattedTime } from 'react-intl'

import {
  Card, CardBody, CardHeader, CardFooter,
  Form, FormGroup, Input,
  Modal, ModalBody, ModalFooter,
  Label,
  Spinner
} from 'reactstrap'
import CustomSelect from 'components/CustomSelect'

import { AppContext, } from 'contexts/AppContext'

import ProtectedComponent from 'components/ProtectedComponent'
import { T, ErrAlert, TNavLink, TLabel, TButton, } from 'components/TComponents'

import UserCalendar from '../../UserCalendar'

const userReducer = (state, action) => {
  switch (action.type) {
  case 'init': return {
    user: action.payload,
    baseUser: action.payload
  }
  case 'update': return {
    ...state,
    user: {
      ...state.user,
      ...action.payload
    }
  }
  case 'reset': return {
    ...state,
    user: state.baseUser
  }
  default:
    return state
  }
}

const UserEdit = ({ match }) => {
  const { api, constants } = React.useContext(AppContext)

  const [baseProfiles, setBaseProfiles] = React.useState()
  const [companies, setCompanies] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [loadError, setLoadError] = React.useState()

  const [userState, userDispatch] = React.useReducer(userReducer, undefined)

  const [updating, setUpdating] = React.useState(false)
  const [updateError, setUpdateError] = React.useState()

  const [locking, setLocking] = React.useState(false)
  const [lockError, setLockError] = React.useState()

  const [modalOpen, setModalOpen] = React.useState(false)
  const [onEdit, setOnEdit] = React.useState(false)

  const sortProfile = (a, b) => {
    if (a.baseId < b.baseId) {
      return -1;
    }
    if (a.baseId > b.baseId) {
      return 1;
    }
    return 0;
  }

  React.useEffect(() => {
    Promise.all([
      api.get('/users/details', undefined, { userId: match.params.id }),
      api.get('/rights/profiles', undefined, { ipp: -1 }),
      api.get('/companies/list', undefined, { ipp: -1 })
    ])
      .then(([user, profiles, companies]) => {
        setBaseProfiles(profiles.sort(sortProfile))
        setCompanies(companies)
        userDispatch({ type: 'init', payload: user })
      })
      .catch(response => setLoadError(response))
      .then(() => setLoading(false))
  }, [api, match])

  React.useEffect(() => { setUpdateError() }, [userState])

  const handleCheckProfile = React.useCallback((e, profileId) => {
    if (!onEdit) {
      return
    }

    userDispatch({
      type: 'update', payload: {
        profiles: e.target.checked
          ? userState.user.profiles.concat(baseProfiles.find(pr => pr.profileId === profileId))
          : userState.user.profiles.filter(pr => pr.profileId !== profileId)
      }
    })
  }, [baseProfiles, onEdit, userState])

  const lockUser = React.useCallback(() => {
    setLocking(true)
    api.post('/users/details', {
      body: JSON.stringify({
        userId: userState.baseUser.userId,
        locked: !userState.baseUser.locked
      })
    }).then(response => {
      userDispatch({ type: 'init', payload: response })
      setModalOpen(false)
    })
      .catch(response => setLockError(response))
      .then(() => setLocking(false))
  }, [api, userState])

  const save = React.useCallback(() => {
    setUpdating(true)
    api.post('/users/details', {
      body: JSON.stringify({
        ...userState.user,
        userId: userState.baseUser.userId,
        profiles: Object.values(userState.user.profiles)
          .map(profile => profile.profileId)
      })
    })
      .then(response => {
        userDispatch({ type: 'init', payload: response })
        setOnEdit(false)
      })
      .catch(response => setUpdateError(response))
      .then(() => setUpdating(false))
  }, [api, userState])

  const cancel = React.useCallback(() => {
    userDispatch({ type: 'reset' })
    setOnEdit(false)
  }, [])

  if (loadError) {
    return <>
      <TNavLink id="returnToList" tag={Link} to="/admin/users" />
      <ErrAlert error={loadError} className="mt-2" />
    </>
  }

  if (loading) {
    return <>
      <TNavLink id="returnToList" tag={Link} to="/admin/users" />
      <Spinner className="d-flex ml-auto mr-auto" color="primary" />
    </>
  }

  return <>
    <TNavLink id="returnToList" tag={Link} to="/admin/users" />
    <Card className="mb-2">
      <CardHeader tag="h2">
        {userState.baseUser.locked && <i className="mr-1 iconsmind-Lock" />}
        {userState.baseUser.firstName}&nbsp;{userState.baseUser.lastName}
      </CardHeader>
      <CardBody tag={Form}>
        <FormGroup tag="fieldset">
          <TLabel id="firstName" for="firstName" />
          <Input id="firstName"
            type="text"
            name="firstName"
            disabled={!onEdit}
            value={userState.user.firstName}
            onChange={e => userDispatch({ type: 'update', payload: { firstName: e.target.value } })} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel id="lastName" for="lastName" />
          <Input id="lastName"
            type="text"
            name="lastName"
            disabled={!onEdit}
            value={userState.user.lastName}
            onChange={e => userDispatch({ type: 'update', payload: { lastName: e.target.value } })} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel id="email" for="email" />
          <Input id="email"
            type="text"
            name="email"
            disabled={true}
            value={userState.baseUser.email} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel id="userTypeId" for="userTypeId" />
          <CustomSelect
            id="userTypeId"
            name="userTypeId"
            isDisabled={!onEdit}
            value={constants.userTypes.find(_userType => _userType.value === userState.user.userTypeId)}
            onChange={e => userDispatch({ type: 'update', payload: { userTypeId: e.value } })}
            isSearchable={false}
            placeholder={'...'}
            options={constants.userTypes}
            getOptionLabel={option => <T raw id={`userType.${option.key}`} />}
            getOptionValue={option => option.value} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel id="companyId.label" for="companyId" />
          <CustomSelect
            id="companyId"
            name="companyId"
            isDisabled={!onEdit}
            value={companies.result.find(company => company.companyId === userState.user.companyId) || ''}
            onChange={e => userDispatch({ type: 'update', payload: { companyId: e.companyId } })}
            isSearchable={false}
            placeholder={'...'}
            options={companies.result}
            getOptionLabel={option => option.companyName}
            getOptionValue={option => option.companyId} />
        </FormGroup>
        {onEdit && <FormGroup tag="fieldset" className="mb-2" check>
          <Input id="aplz"
            type="checkbox"
            name="aplz"
            disabled={!onEdit}
            value={userState.user.aplz}
            onChange={e => userDispatch({ type: 'update', payload: { aplz: e.target.checked } })} />
          <TLabel id="aplz" for="aplz" check className="" />
        </FormGroup>}
        <TLabel id="profilesList" />
        {baseProfiles.map(profile => <FormGroup key={profile.profileId} tag="fieldset" check>
          <Input id={`profile.${profile.profileId}`}
            type="checkbox"
            name={profile.nameProfile}
            checked={userState.user.profiles.find(_profile => _profile.profileId === profile.profileId) || false}
            disabled={!onEdit}
            onChange={e => handleCheckProfile(e, profile.profileId)} />
          <Label for={`profile.${profile.profileId}`}>{`${profile.baseName} - ${profile.nameProfile}`}</Label>
        </FormGroup>)}
      </CardBody>
      <ProtectedComponent rights={['admin-users_date_action_connexion']}>
        <CardBody>
          <FormGroup tag="fieldset" className="mb-2">
            <TLabel id="dateConnexion" className="d-block" />
            {userState.baseUser.dateConnexion ? (
              <>
                <FormattedDate value={new Date(userState.baseUser.dateConnexion)} />{" "}<FormattedTime value={new Date(userState.baseUser.dateConnexion)} />
              </>
            ) : (
              <T id="noDateConnexion" />
            )}
          </FormGroup>
          <FormGroup tag="fieldset" className="mb-2">
            <TLabel id="dateAction" className="d-block" />
            {userState.baseUser.dateAction ? (
              <>
                <FormattedDate value={new Date(userState.baseUser.dateAction)} />{" "}<FormattedTime value={new Date(userState.baseUser.dateAction)} />
              </>
            ) : (
              <T id="noDateAction" />

            )}
          </FormGroup>
        </CardBody>
      </ProtectedComponent>
      <UserCalendar userId={userState.baseUser.userId} userType={"admin"} />
      <ProtectedComponent rights={['admin-users_edit']}>
        <CardFooter>
          <div>
            {updateError && <ErrAlert error={updateError} className="mt-2" />}
          </div>
          <div className="d-flex">
            <TButton id={`${userState.baseUser.locked ? 'unlock' : 'lock'}`} disabled={updating} className="ml-2"
              onClick={() => setModalOpen(true)} color="primary" />
            <div className="ml-auto pl-2 d-flex">
              {!onEdit && <TButton id="edit" onClick={() => setOnEdit(true)} />}
              {onEdit && <TButton id="save" loading={updating} disabled={updating} className="ml-2" onClick={() => save()} color="primary" />}
              {onEdit && <TButton id="cancel" disabled={updating} className="ml-2" onClick={() => cancel()} />}
            </div>
          </div>
        </CardFooter>
      </ProtectedComponent>
    </Card>

    <Modal isOpen={modalOpen} fade={false} toggle={() => setModalOpen(false)}>
      <ModalBody>
        <T id={`${userState.baseUser.locked ? 'unlock' : 'lock'}Content`} />
        {lockError && <ErrAlert error={lockError} className="mb-0 mt-2" />}
      </ModalBody>
      <ModalFooter>
        <TButton id="lockCancel"
          disabled={locking}
          onClick={() => setModalOpen(false)} />
        <TButton id={`${userState.baseUser.locked ? 'unlock' : 'lock'}Confirm`}
          loading={locking} className="ml-2" color="danger" onClick={() => lockUser()} />
      </ModalFooter>
    </Modal>
  </>
}

export default UserEdit
