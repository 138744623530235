import React from 'react'
import { Switch } from 'react-router-dom'

import Route from 'components/Route'

import List from './CompanyList'
import Details from './CompanyDetails'

export default function CompaniesRoute({ match }) {
  return <Switch>
    <Route exact path={`${match.path}`} component={List} rights={['admin-companies_edit', 'admin-companies_view',]} />
    <Route path={`${match.path}/new`} component={Details} rights={['admin-companies_edit',]} />
    <Route path={`${match.path}/:id`} component={Details} rights={['admin-companies_edit', 'admin-companies_view',]} />
  </Switch>
}
