import React from 'react'

export const Item = ({
  item, dimensions, itemRenderer,
  ...props
}) => {
  const [grabbing, setGrabbing] = React.useState(false)
  const [isHover, setIsHover] = React.useState(false)

  const itemStyle = React.useMemo(() => ({
    position: 'absolute',
    boxSizing: 'border-box',
    left: `${dimensions.left}`,
    top: `${dimensions.top}`,
    width: `calc(${dimensions.width} - 1px)`,
    height: `${dimensions.height}`,
    zIndex: 80,
    cursor: props.canMove ? grabbing ? 'grabbing' : 'grab' : 'pointer',
    backgroundColor: props.canMove ? 'rgba(0, 125, 250, .4)' : 'rgba(0, 0, 0, .4)',
    ...item.style,
  }), [dimensions.height, dimensions.left, dimensions.top, dimensions.width, grabbing, item.style, props.canMove])

  const handleDragStart = React.useCallback(e => {
    if (props.canMove) {
      setIsHover(false)
      const { dataTransfer, } = e
      setGrabbing(true)

      // Pass item through context or setState passed parent function instead
      dataTransfer.setData('item', JSON.stringify(item))

      props.onItemDragStart && props.onItemDragStart(e, item)
    }
  }, [item, props])

  const handleDragEnd = React.useCallback(e => {
    setGrabbing(false)
    props.onItemDragEnd && props.onItemDragEnd(e, item)
  }, [item, props])


  const handleDrop = React.useCallback(e => {
    props.onItemDrop && props.onItemDrop(e, item.group)
  }, [item, props])

  const handleClick = React.useCallback(e => {
    item.onItemClick && item.onItemClick(item, e)
  }, [item])

  return (
    <div
      id={item.id}
      title={item.title}
      className={`rct-item ${item.className || ''}`}
      style={itemStyle}
      draggable={props.canMove}
      onDragOver={e => e.preventDefault()}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDrop={handleDrop}
      onClick={handleClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}>
      {itemRenderer
        ? itemRenderer({ ...item, isHover })
        : <div className="rct-item-content" style={{ maxHeight: `${dimensions.height}` }}>{item.title}</div>}
    </div>
  )
}

export default Item