import React from 'react'
import CustomSelect from 'components/CustomSelect'
import {
  Card, CardHeader, CardFooter, Col, Row, CardBody, FormGroup, Table
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'
import TH from 'components/TH'

import { T, TAlert, TLabel, TButton, TCustomInput, TInput } from 'components/TComponents'
import { debounce } from 'util/Utils'

const ItvcodereasonList = () => {

  const { api } = React.useContext(AppContext)

  const [filtersDone, setFiltersDone] = React.useState(false)

  const [itvcodes, setItvcodes] = React.useState([])
  const [itvcodesInitial, setItvcodesInitial] = React.useState([])

  const [loadingCodes, setLoadingCodes] = React.useState()
  const [updating, setUpdating] = React.useState()
  const [updateError, setUpdateError] = React.useState()
  const [updateSuccess, setUpdateSuccess] = React.useState(false)

  const [itvcodesError, setItvcodesError] = React.useState()

  const [editState, setEditState] = React.useState(false)
  const [searchInput, setSearchInput] = React.useState('')

  React.useEffect(() => {
    api.get('/reasonparts/list')
      .then(data => {
        setItvcodes(data)
        setItvcodesError()
      })
      .catch(error => setItvcodesError(error))
  }, [api, setItvcodes])

  React.useEffect(() => {
    if (itvcodes && itvcodes.filters && itvcodes.filters.businessId.selected && itvcodes.filters.categoryId.selected && itvcodes.filters.familyId.selected) {
      setFiltersDone(true)
    } else {
      setFiltersDone(false)
    }
  }, [itvcodes])

  const refresh = React.useCallback((params) => {
    if (itvcodes) {
      setUpdateError()
      setLoadingCodes(true)
      Promise.all([
        api.get('/reasonparts/list', undefined, {
          businessId: itvcodes.filters.businessId.selected && itvcodes.filters.businessId.selected.value,
          categoryId: itvcodes.filters.categoryId.selected && itvcodes.filters.categoryId.selected.value,
          familyId: itvcodes.filters.familyId.selected && itvcodes.filters.familyId.selected.value,
          ...params
        }),
        new Promise(resolve => setTimeout(resolve, 250))
      ])
        .then(([data]) => {
          setItvcodesError()
          if (params.familyId && !params.filter) {
            setItvcodesInitial(data)
          }
          setItvcodes({
            ...data,
            result: data.result.length === 0 ? [] : data.result.map(code => {
              const newItvcode = code
              newItvcode.reasons = code.reasons.map(r => {
                r.invoice = r.invoice ? true : false
                r.disabled = false
                // Always billed and Never billed case
                if ((r.reasonId === 7 || r.reasonId == 8) && r.invoice) {
                  code.reasons.map(r2 => {
                    if (r2.reasonId !== r.reasonId && r2.reasonId !== 7 && r2.reasonId !== 8) {
                      r2.disabled = r.invoice ? true : false
                    }
                    return r2
                  })
                }
                return r
              })
              return newItvcode
            })
          })
          setLoadingCodes(false)
        })
        .catch(error => {
          setItvcodesError(error)
          setLoadingCodes(false)
        })
    }
  }, [api, itvcodes])

  const refreshDebounced = React.useMemo(() => debounce((params) => refresh(params), 250), [refresh])

  const handleSearchInput = React.useCallback(({ target: { value } }) => {
    setSearchInput(value)
    refreshDebounced({ filter: value, p: 1 })
  }, [refreshDebounced])

  const handleSave = React.useCallback(() => {
    setUpdating(true)
    const itvcodesToPrepare = itvcodesInitial.result.map(code =>
      itvcodes.result && itvcodes.result.map(c => {
        if (c.itvcodeId === code.itvcodeId) {
          return c
        } else {
          return code
        }
      })[0] || code
    )
    const itvcodesToSend = itvcodesToPrepare.map(code => {
      const newCode = {}
      newCode.itvcodeId = code.itvcodeId
      newCode.reasons = code.reasons.map(r => {
        const newReason = {}
        newReason.reasonId = r.reasonId
        newReason.invoice = r.invoice
        return newReason
      })
      return newCode
    })
    api.post('/reasonparts/details', {
      body: JSON.stringify({
        businessId: itvcodes.filters.businessId.selected && itvcodes.filters.businessId.selected.value,
        categoryId: itvcodes.filters.categoryId.selected && itvcodes.filters.categoryId.selected.value,
        familyId: itvcodes.filters.familyId.selected && itvcodes.filters.familyId.selected.value,
        itvcodes: itvcodesToSend
      })
    })
      .then(response => {
        setUpdating(false)
        setUpdateSuccess(true)
        setUpdateError()
        setEditState(false)
        setItvcodesInitial({
          ...itvcodes,
          result: response
        })
      })
      .catch(response => {
        setEditState(false)
        setUpdateSuccess(true)
        setUpdateError(response.code)
        setUpdating(false)
      })
  }, [api, itvcodes, itvcodesInitial])

  const handleReason = React.useCallback((checked, itvcode, reasonId) => {
    setItvcodes({
      ...itvcodes,
      result: itvcodes.result.map(code => {
        if (code.itvcodeId === itvcode.itvcodeId) {
          const newItvcode = code
          newItvcode.reasons = code.reasons.map(r => {
            r.invoice = r.invoice ? true : false
            if (code.itvcodeId === itvcode.itvcodeId && r.reasonId === reasonId) {
              // Always billed and Never billed case
              if (reasonId === 7 || reasonId == 8) {
                code.reasons.map(r2 => {
                  if (code.itvcodeId === itvcode.itvcodeId && r2.reasonId !== reasonId) {
                    r2.invoice = false
                    if (r2.reasonId !== 7 && r2.reasonId !== 8) {
                      r2.disabled = checked ? true : false
                    }
                  }
                  return r2
                })
              }
              r.invoice = checked
            }
            return r
          })
          return newItvcode
        }
        return code
      })
    })
  }, [itvcodes])

  return (
    <div className="container-fluid">
      <Card className="mb-2">
        <CardHeader>
          <Row form>
            {itvcodes && itvcodes.filters && itvcodes.filters.businessId && itvcodes.filters.businessId.values &&
              <Col sm="6" md="3" >
                <FormGroup tag="fieldset">
                  <TLabel id="businessId.label" for="businessId" />
                  <CustomSelect
                    id="businessId"
                    options={itvcodes.filters.businessId.values}
                    onChange={e => refresh({
                      businessId: e && e.value,
                      categoryId: undefined,
                      familyId: undefined
                    })}
                    value={itvcodes.filters.businessId.selected && itvcodes.filters.businessId.values.filter(b => b.value === itvcodes.filters.businessId.selected.value)}
                    isClearable
                    placeholder={<T id="businessId.placeholder" />}
                    noOptionsMessage={() => <T id="businessId.noResult" />} />
                </FormGroup>
              </Col>}
            {itvcodes && itvcodes.filters && itvcodes.filters.categoryId && itvcodes.filters.categoryId.values &&
              <Col sm="6" md="3" >
                <FormGroup tag="fieldset">
                  <TLabel id="categoryId.label" for="categoryId" />
                  <CustomSelect
                    id="categoryId"
                    isClearable
                    options={itvcodes.filters.categoryId.values}
                    onChange={e => refresh({
                      categoryId: e && e.value,
                      familyId: undefined
                    })}
                    value={itvcodes.filters.categoryId.selected && itvcodes.filters.categoryId.values.filter(b => b.value === itvcodes.filters.categoryId.selected.value)}
                    placeholder={<T id="categoryId.placeholder" />}
                    noOptionsMessage={() => <T id="noResult" raw />} />
                </FormGroup>
              </Col>}
            {itvcodes && itvcodes.filters && itvcodes.filters.familyId && itvcodes.filters.familyId.values &&
              <Col sm="6" md="3" >
                <FormGroup tag="fieldset">
                  <TLabel id="familyId.label" for="familyId" />
                  <CustomSelect
                    id="familyId"
                    isClearable
                    options={itvcodes.filters.familyId.values}
                    onChange={e => {
                      e && e.value ? setFiltersDone(true) : setFiltersDone(false)
                      refresh({ familyId: e && e.value })
                    }}
                    value={itvcodes.filters.familyId.selected && itvcodes.filters.familyId.values.filter(b => b.value === itvcodes.filters.familyId.selected.value)}
                    placeholder={<T id="familyId.placeholder" />}
                    noOptionsMessage={() => <T id="noResult" raw />} />
                </FormGroup>
              </Col>}
          </Row>
        </CardHeader>
        <CardBody>
          {itvcodesError && <TAlert id="notMatrix" className="mb-0" color="danger" />}
          {!filtersDone && <T id="choseFiltersDescription" />}
          {filtersDone && (
            <>
              <TAlert color="warning" id="explication" />
              <FormGroup tag="fieldset">
                <TLabel for="searchInput" id="searchInputLabel" />
                <TInput
                  name="searchInput"
                  type="text"
                  placeholder="searchInputPlaceholder"
                  value={searchInput}
                  onChange={handleSearchInput} />
              </FormGroup>
            </>
          )}
          {loadingCodes && filtersDone && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
          {!itvcodesError && !updateError && !loadingCodes && (
            <>
              {filtersDone && (
                <>
                  {!loadingCodes && itvcodes && itvcodes.result.length > 0 ? (
                    <>
                      <FormGroup tag="fieldset">
                        <Table responsive striped hover className="mb-3 mt-2">
                          <thead>
                            <tr>
                              <TH colName="itvcodeIdentifier" />
                              <TH />
                              <TH />
                              <TH />
                              <TH />
                              <TH />
                              <TH />
                              <TH />
                              <TH />
                            </tr>
                          </thead>
                          <tbody>
                            {itvcodes.result.map(itvcode =>
                              <tr key={itvcode.itvcodeId} style={{
                                transform: `rotate(0)`
                              }}>
                                <td>{`${itvcode.itvcodeIdentifier} - ${itvcode.itvcodeLabel}`}</td>
                                {itvcode.reasons.map(reason =>
                                  <td key={reason.reasonId}>
                                    <TCustomInput
                                      key={`${itvcode.itvcodeIdentifier} - ${reason.reasonId}`}
                                      id={`${itvcode.itvcodeIdentifier} - ${reason.reasonValue}`}
                                      type="checkbox"
                                      name={`${itvcode.itvcodeIdentifier} - ${reason.reasonValue}`}
                                      label={`reasonParts.${reason.reasonValue}`}
                                      raw
                                      disabled={reason.disabled || !editState}
                                      checked={reason.invoice}
                                      onChange={e => { handleReason(e.target.checked, itvcode, reason.reasonId) }} />
                                  </td>
                                )}
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </FormGroup>
                    </>
                  ) : (
                    <T id="noData" />
                  )}
                </>
              )}
            </>
          )}
        </CardBody>
        <CardFooter>
          <div className="mb-2">
            {updateError && <TAlert id={updateError} className="mb-0" color="danger" />}
            {updateSuccess && <TAlert id="updateSuccess" className="mb-0" color="success" />}
          </div>
          <div className="d-flex justify-content-end">
            {editState ? (
              <>
                <TButton
                  disabled={loadingCodes || updating || !filtersDone}
                  className="ml-2"
                  spin={updating}
                  onClick={() => handleSave()}
                  id="save" />
                <TButton
                  disabled={loadingCodes || updating || !filtersDone}
                  className="ml-2"
                  spin={updating}
                  color="warning"
                  onClick={() => setEditState(!editState)}
                  id="cancel" />
              </>
            ) : (
              <TButton
                disabled={loadingCodes || updating || !filtersDone}
                className="ml-2"
                spin={updating}
                onClick={() => setEditState(!editState)}
                id="edit" />
            )}
          </div>
        </CardFooter>
      </Card>
    </div>
  )
}

export default ItvcodereasonList