import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import Route from 'components/Route'

import MaintenanceList from './MaintenanceList'
import MaintenanceNew from './MaintenanceNew'
import MaintenancePlan from './MaintenancePlan'
import MaintenanceEdit from './MaintenanceEdit'
import MaintenanceStart from './MaintenanceStart'

const MaintenanceRoute = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/new`} component={MaintenanceNew} rights={['maintenanceplan-edit']} />
    <Route exact path={`${match.path}`} component={MaintenanceList} rights={['maintenanceplan-view']} />
    <Route exact path={`${match.path}/plan/:id`} component={MaintenancePlan} rights={['maintenanceplan-edit']} />
    <Route exact path={`${match.path}/:id`} component={MaintenanceEdit} rights={['maintenanceplan-view']} />
    <Route exact path={`${match.path}/start/:id`} component={MaintenanceStart} rights={['maintenanceplan-end_web']} />
    <Redirect to={`/`} />
  </Switch>
)

export default MaintenanceRoute
