import React from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'

import {
  Card, CardBody, CardHeader, CardFooter,
  Modal, ModalBody, ModalFooter,
  FormGroup,
  Spinner,
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import CustomSelect from 'components/CustomSelect'
import ProtectedComponent from 'components/ProtectedComponent'
import { T, TInput, ErrAlert, TButton, TNavLink, TLabel, } from 'components/TComponents'

const CompanyDetails = () => {
  const { api } = React.useContext(AppContext)
  const routeParams = useParams()
  const history = useHistory()

  const [company, setCompany] = React.useState()
  const [companyBase, setCompanyBase] = React.useState({
    companyName: ''
  })
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()
  const [updateLoading, setUpdateLoading] = React.useState(false)
  const [updateError, setUpdateError] = React.useState()
  const [archiveLoading, setArchiveLoading] = React.useState(false)
  const [archiveError, setArchiveError] = React.useState()
  const [techcenterBase, setTechcenterBase] = React.useState()
  const [techcenter, setTechcenter] = React.useState()

  const [onEdit, setOnEdit] = React.useState(false)
  const [archiveOpen, setArchiveOpen] = React.useState(false)

  React.useEffect(() => {
    if (routeParams.id) {
      api.get('/companies/details', undefined, { companyId: routeParams.id })
        .then(response => setCompanyBase(response))
        .catch(error => setError(error))
        .then(() => setLoading(false))
    } else {
      setCompanyBase({
        companyName: ''
      })
      setLoading(false)
    }
  }, [api, routeParams])

  React.useEffect(() => {
    if (company && company.companyId) {
      api.get('/techcenters/list', undefined, { companyId: company.companyId, ipp: -1 })
        .then(response => setTechcenterBase(response))
        .catch(error => setError(error))
        .then(() => setLoading(false))
    }
  }, [api, company])

  const handleChange = React.useCallback(({ target: { name, value } }) => setCompany(company => ({ ...company, [name]: value })), [])

  const save = React.useCallback(() => {
    setUpdateLoading(true)
    setUpdateError(undefined)
    api.post('/companies/details', {
      body: JSON.stringify({
        ...company,
        ...(techcenter && { techcenterId: techcenter }),
      })
    })
      .then(response => {
        setCompanyBase(response)

        if (!routeParams.id) {
          history.push(`/admin/companies/${response.companyId}`)
        }
      })
      .catch(error => setUpdateError(error))
      .then(() => setUpdateLoading(false))
  }, [api, routeParams, history, company, techcenter])

  const archive = React.useCallback(() => {
    setArchiveLoading(true)
    setArchiveError(undefined)
    api.del('/companies/details', undefined, { companyId: routeParams.id })
      .then(response => {
        setCompanyBase(response)
        setArchiveOpen(false)
      })
      .catch(error => setArchiveError(error))
      .then(() => setArchiveLoading(false))
  }, [api, routeParams.id])

  const cancel = React.useCallback(() => {
    setCompany(companyBase)
    setOnEdit(false)
  }, [companyBase])

  React.useEffect(() => cancel(), [cancel])

  if (error) {
    return <>
      <TNavLink tag={Link} to="/admin/companies" id="returnToList" />
      <ErrAlert error={error} className="mt-2" />
    </>
  }

  if (!company || loading) {
    return <>
      <TNavLink tag={Link} to="/admin/companies" id="returnToList" />
      <Spinner className="d-flex ml-auto mr-auto" color="primary" />
    </>
  }

  return (
    <>
      <TNavLink tag={Link} to="/admin/companies" id="returnToList" />
      <Card className="mb-2">
        <CardHeader tag="h2" className="d-flex align-items-center">
          {/* TODO: HEY ! On fait ca partout ? */}
          {companyBase.companyId && companyBase.archived && <i className="mr-1 mb-0 h5 iconsmind-Box-Close text-primary" />}
          {companyBase.companyId && companyBase.companyName}
          {!companyBase.companyId && <T id="title" />}
        </CardHeader>
        <CardBody>
          <FormGroup tag="fieldset">
            <TLabel id="companyName.label" />
            <TInput id="companyName"
              type="text"
              name="companyName"
              placeholder="companyName.placeholder"
              disabled={!onEdit && companyBase.companyId}
              value={company.companyName}
              onChange={handleChange} />
          </FormGroup>
          {techcenterBase && techcenterBase.result && techcenterBase.result.length > 0 && (
            <FormGroup tag="fieldset">
              <TLabel id="techcenterName.label" />
              <CustomSelect
                inputId="techcenters"
                name="techcenters"
                options={techcenterBase.result}
                onChange={e => setTechcenter(e && e.techcenterId)}
                isClearable
                placeholder={<T id="techcenterPlaceholder" />}
                defaultValue={techcenterBase.result.find(t => (t.techcenterId === company.techcenterId))}
                isDisabled={!onEdit && companyBase.companyId}
                getOptionLabel={option => option.techcenterName}
                getOptionValue={option => option.techcenterId} />
            </FormGroup>
          )}
          {updateError && <ErrAlert error={updateError} />}
        </CardBody>
        <ProtectedComponent rights={['admin-bases_edit']}>
          <CardFooter className="d-flex">
            {companyBase.companyId && onEdit && <ProtectedComponent rights={['admin_archive']}>
              <TButton disabled={updateLoading} color="danger"
                onClick={() => setArchiveOpen(true)}
                id={company.archived ? 'restore' : 'archive'} />
            </ProtectedComponent>}
            <div className="ml-auto pl-2">
              {companyBase.companyId && !onEdit && <TButton onClick={() => setOnEdit(true)} id="edit" />}
              {companyBase.companyId && onEdit && <TButton disabled={updateLoading} spin={updateLoading}
                onClick={save} color="primary" className="ml-2"
                id="save" />}
              {companyBase.companyId && onEdit && <TButton disabled={updateLoading}
                onClick={cancel} className="ml-2"
                id="cancel" />}
              {!companyBase.companyId && <TButton disabled={updateLoading} spin={updateLoading}
                onClick={save} className="ml-2" color="primary"
                id="create" />}
            </div>
          </CardFooter>
        </ProtectedComponent>

        <Modal isOpen={archiveOpen} fade={false} toggle={() => setArchiveOpen(false)}>
          <ModalBody>
            <T id={`${company.archived ? 'restore' : 'archive'}.content`} />
            {archiveError && <ErrAlert error={archiveError} className="mb-0 mt-2" />}
          </ModalBody>
          <ModalFooter className="py-3">
            <TButton disabled={archiveLoading}
              onClick={() => setArchiveOpen(false)}
              id={`${company.archived ? 'restore' : 'archive'}.cancel`} />
            <TButton disabled={archiveLoading} loading={archiveLoading}
              className="ml-2" color="danger"
              onClick={archive}
              id={`${company.archived ? 'restore' : 'archive'}.confirm`} />
          </ModalFooter>
        </Modal>
      </Card>
    </>
  )
}

export default CompanyDetails