import React from 'react'

import { T, } from './TComponents'
import CustomSelect from '../components/CustomSelect'

const FilterSelect = ({ name, options, placeholder, onChange, translate, ...props }) => {
  const getTranslation = option => (
    translate && translate.length > 0 && translate.find(t => t === option.value) !== undefined ? <T raw id={`${name}.${option.value}`} /> : <>{option.label}</>
  )
  return (
    <CustomSelect id={name}
      name={name}
      isSearchable={false}
      options={options}
      menuShouldScrollIntoView={false}
      placeholder={placeholder || <T id={`${name}.placeholder`} />}
      getOptionLabel={option => getTranslation(option)}
      onChange={
        value => onChange(props.isMulti
          ? (value ? value.map(val => val.value) : [])
          : (value ? value.value : undefined))
      }
      {...props} />

  )
}


export default FilterSelect
