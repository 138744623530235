import React from 'react'
import { useHistory } from 'react-router-dom'

import { Card, CardBody, Spinner, } from 'reactstrap'

import { AppContext, } from 'contexts/AppContext'
import { T, TAlert } from 'components/TComponents'

const LanguageSelector = () => {
  const history = useHistory()

  const { subsidiary, setLanguage } = React.useContext(AppContext)
  const { api } = React.useContext(AppContext)

  const [loading, setLoading] = React.useState()
  const [languages, setLanguages] = React.useState([])
  const [languagesError, setLanguagesError] = React.useState()

  React.useEffect(() => {
    Promise.all([
      api.get('/languages/list'),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([response]) => setLanguages(response))
      .catch(response => setLanguagesError(response))
      .then(() => setLoading(false))
  }, [api])

  const handleLanguageClick = React.useCallback(language => {
    setLanguage(language, subsidiary),
    history.goBack()
  }, [setLanguage, subsidiary, history])

  return (
    <div className="mt-4 d-flex flex-wrap justify-content-center">
      {loading ? (
        <Spinner />
      ) : (
        <>
          {languagesError && <TAlert color="danger" id={languagesError.code} />}
          {languages && languages.map(lang =>
            <Card key={lang.langId} className="m-2 selector-card">
              <T
                tagName={CardBody}
                id={lang.langIdentifier}
                className="text-center d-flex justify-content-center align-items-center"
                onClick={() => handleLanguageClick(lang)} />
            </Card>
          )}
        </>
      )}
    </div>
  )
}

export default LanguageSelector
