import React from 'react'
import { FormattedDate } from 'react-intl'
import { useLocation, Link, useHistory } from 'react-router-dom'
import { objectToQuery, queryToObject } from 'react-rest-api'

import {
  Card, CardBody, CardHeader,
  Table, Spinner
} from 'reactstrap'

import { useGet } from 'hooks/useApi.hooks'

import TH from 'components/TH'
import { T, TAlert, ErrAlert, TNavLink } from 'components/TComponents'
import { Filters } from 'components/Filters'

import { useFormatMessage } from 'hooks/intl.hooks'

import '../Table.scss'

const rowReducer = (state, action) => {
  switch (action.type) {
  case 'setData': return { ...state }
  case 'load': return { loading: true, error: undefined }
  case 'loaded': return { loading: false, error: undefined }
  case 'error': return { loading: false, error: action.payload }
  default: return state
  }
}

const rowInitialState = {
  loading: false,
  error: undefined
}

const Row = ({ day, i }) => {
  const [rowState, rowDispatch] = React.useReducer(rowReducer, rowInitialState)

  React.useEffect(() => {
    rowDispatch({ type: 'setData' })
  }, [day])

  return (
    <>
      <tr className="line">
        <td className={'date border-0 py-1 sticky color-white ' + (i % 2 === 0 && 'even')}><FormattedDate value={new Date(day.date)} /></td>
        {day.counts.map((count, i) =>
          <React.Fragment key={i}>
            <td className={'count align-middle border-top-0 border-right py-1 ' + (i === 0 && 'border-left')}>{count.interventions}</td>
            <td className={'count align-middle border-top-0 border-right py-1'}>{count.swaps}</td>
            <td className={'count align-middle border-top-0 border-right py-1'}>{count.tickets}</td>
          </React.Fragment>
        )}
      </tr>
      {rowState.error && (
        <tr>
          <td className="p-0 border-top-0" colSpan={1 + day.counts.length + 4}><ErrAlert error={rowState.error} className="mb-0" /></td>
        </tr>
      )}
    </>
  )
}

const DeskTable = () => {
  const location = useLocation()
  const history = useHistory()

  const formatMesage = useFormatMessage()

  const filters = React.useMemo(() => ({
    p: {
      type: 'number'
    },
    businessId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false,
        getOptionValue: option => option.label,
        isClearable: true,
        isSearchable: true,
      },
    },
    companyId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false,
        translate: [0]
      },
    },
    categoryId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false,
        isClearable: true,
      },
    },
    familyId: {
      array: false,
      componentType: 'select',
      componentOptions: {
        isMulti: false,
        isClearable: true,
      },
    },
    itvcodeId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false,
        isClearable: true,
      },
    },
    userTypeId: {
      array: false,
      componentType: 'select',
      componentOptions: {
        isMulti: false,
        isClearable: true,
        getOptionLabel: option => formatMesage({ id: `userType.${option.label}`, raw: true })
      },
    }
  }), [formatMesage])

  // DATA
  const params = React.useMemo(() => ({ ...location.state, ...queryToObject(location.search, filters) }), [location, filters])

  const [data, refreshing, error, loading] = useGet(`/desk/board`, undefined, params)

  const refresh = React.useCallback((_params = {}) => history.push({ search: objectToQuery({ ...params, ..._params }) }), [history, params])

  return (
    <div className="container-fluid Table">
      <TNavLink id="returnToList" tag={Link}
        to={{
          pathname: '/desk',
          state: location.state ? location.state.desk : undefined
        }} />
      <Card className="mb-2">
        <CardHeader>
          <Filters
            loading={loading}
            disabled={refreshing}
            data={data}
            refresh={refresh}
            filters={filters}
          />
        </CardHeader>
        {error && <ErrAlert error={error} />}
        {!error && loading && <Spinner color="primary" className="d-flex my-4 mx-auto" />}
        {!error && !loading && (!data || data.users.length === 0) && (
          <TAlert id="noResults" color="warning" />
        )}
        {!error && !loading && data && data.users.length > 0 && (
          <CardBody className="py-3">
            <Table responsive className="mb-3 w-auto table-striped separate">
              <thead>
                <tr>
                  <TH colName="date" className={[`border-top-0 border-bottom text-center sticky color-white`]} />
                  {data.users.map((user, i) =>
                    <TH colSpan="3" key={`user-${i}`} className={['border-top-0 border-bottom text-center']}>{user}</TH>)}
                  <TH className={['border-0']} />
                </tr>
                <tr>
                  <td className="color-white sticky border-0" />
                  {data.users.map((user, i) =>
                    <React.Fragment key={i}>
                      <td className={'border-top-0 ' + (i === 0 && 'border-left')}><T id="interventions" /></td>
                      <td className="border-0"><T id="swaps" /></td>
                      <td className="border-top-0 border-right"><T id="tickets" /></td>
                    </React.Fragment>
                  )}
                </tr>
              </thead>
              <tbody>
                {data.days.map((day, i) => <Row day={day} key={day.date} i={i} />)}
              </tbody>
            </Table>
          </CardBody>
        )}
      </Card>
    </div>
  )
}

export default DeskTable
