import React from 'react'

import Items from './items/Items'
import Sidebar from './layout/Sidebar'
import Columns from './columns/Columns'
import Rows from './rows/Rows'

import {
  calculateTimeForPosition,
  stackTimelineItems,
} from './utility/calendar'
import TimelineHeaders from './headers/TimelineHeaders'
import GroupHeader from './headers/GroupHeader'
import DateHeader from './headers/DateHeader'

export const ReactCalendarTimeline = ({
  sidebarWidth = 150,
  dragSnap = 1000 * 60 * 5, // 5min
  lineHeight = 30,
  itemHeightRatio = 0.65,

  direction = 'horizontal',

  canMove = true,

  itemRenderer, groupRenderer,
  onSidebarGroupClick,

  defaultTimeStart, defaultTimeEnd,
  duration,
  locale,

  items, groups,

  onCanvasDoubleClick, onCanvasDrop, onDragStart, onDragEnd,
  customHeight
}) => {
  const containerRef = React.useRef()
  const scrollElementRef = React.useRef()

  const [grabbing, setGrabbing] = React.useState(false)

  const getTimeFromRowClickEvent = React.useCallback(e => {
    const { offsetX } = e.nativeEvent

    const { width } = scrollElementRef.current.getBoundingClientRect()

    let time = calculateTimeForPosition(
      defaultTimeStart,
      defaultTimeEnd,
      width,
      offsetX
    )
    time = Math.floor(time / dragSnap) * dragSnap

    return time
  }, [defaultTimeEnd, defaultTimeStart, dragSnap])

  const handleGroupDoubleClick = React.useCallback((e, groupId) => {
    if (onCanvasDoubleClick) {
      const time = getTimeFromRowClickEvent(e)
      onCanvasDoubleClick(e, groupId, time)
    }
  }, [getTimeFromRowClickEvent, onCanvasDoubleClick])

  const handleDragStart = React.useCallback(e => {
    setGrabbing(true)
    return onDragStart && onDragStart(e)
  }, [onDragStart])

  const handleDragEnd = React.useCallback(e => {
    setGrabbing(false)
    return onDragEnd && onDragEnd(e)
  }, [onDragEnd])

  const handleDrop = React.useCallback((e, groupId) => {
    const types = e.dataTransfer.types

    if (!types.includes('item')) {
      return
    }

    const item = JSON.parse(e.dataTransfer.getData('item'))

    const { clientX, pageY } = e.nativeEvent

    const { width, height, } = scrollElementRef.current.getBoundingClientRect()

    const time = Math.floor(calculateTimeForPosition(
      defaultTimeStart,
      defaultTimeEnd,
      direction === 'horizontal' ? width : height,
      direction === 'horizontal'
        ? clientX - scrollElementRef.current.offsetLeft
        : pageY - scrollElementRef.current.offsetTop
    ) / dragSnap) * dragSnap

    return onCanvasDrop && onCanvasDrop(groupId, time, item)
  }, [defaultTimeEnd, defaultTimeStart, direction, dragSnap, onCanvasDrop])

  // TODO: useMemo
  let { dimensionItems, groupHeights, } = stackTimelineItems(
    items,
    groups,
    defaultTimeStart,
    defaultTimeEnd,
    direction,
    lineHeight,
    itemHeightRatio,
    customHeight)

  // TODO: Revert Times and Groups if vertical / horizontal
  return (
    <div
      ref={containerRef}
      className="react-calendar-timeline">
      {direction === 'horizontal'
        ? <TimelineHeaders sidebarWidth={sidebarWidth}>
          <DateHeader unit="day"
            canvasTimeStart={defaultTimeStart}
            duration={duration}
            locale={locale} />
          <DateHeader unit="hour"
            duration={duration}
            canvasTimeStart={defaultTimeStart} />
        </TimelineHeaders>
        : <TimelineHeaders sidebarWidth={sidebarWidth}>
          <GroupHeader elements={groups} />
        </TimelineHeaders>}
      <div className="rct-outer">
        {/* TODO: handle direction by Sidebar directly and stop diff Sidebar and DateHeader like Groups do */}
        {direction === 'horizontal'
          ? <Sidebar
            elements={groups}
            elementRenderer={groupRenderer}
            handleElementClick={onSidebarGroupClick}
            width={sidebarWidth}
            groupHeights={groupHeights}
          />
          : <DateHeader
            style={{
              height: undefined,
              minWidth: `${sidebarWidth}px`,
              position: 'static',
            }}
            itemStyle={{
              position: 'static',
              height: 30,
              justifyContent: 'flex-end',
              padding: 4,
            }}
            canvasTimeStart={defaultTimeStart}
            duration={duration}
            unit="hour" />}
        <div
          ref={scrollElementRef}
          data-testid="scroll-element"
          className="rct-scroll"
          style={{
            position: 'relative',
            cursor: grabbing ? 'grabbing' : undefined,
          }}>
          <Items
            dimensionItems={dimensionItems}
            items={items}
            dragSnap={dragSnap}
            canMove={canMove}
            onItemDragStart={handleDragStart}
            onItemDragEnd={handleDragEnd}
            onItemDrop={handleDrop}
            itemRenderer={itemRenderer} />
          {/* TODO: calc lineCount */}
          <Columns
            direction={direction}
            duration={duration} />
          <Rows
            direction={direction}
            groups={groups}
            groupHeights={groupHeights}
            onGroupDoubleClick={handleGroupDoubleClick}
            onDrop={handleDrop} />
        </div>
      </div>
    </div>
  )
}

export default ReactCalendarTimeline
