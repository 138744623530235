import React from 'react'
import {
  Nav,
  NavItem,
  TabContent,
  NavLink,
  TabPane
} from 'reactstrap'
import { Redirect, Switch, NavLink as RRNavLink } from 'react-router-dom'

import Route from 'components/Route'
import { T } from 'components/TComponents'

import LogList from './LogList'
import PrivateLogList from './PrivateLogList'

const Account = ({ match }) => {
  return (
    <div className="container-fluid">
      <Nav tabs className="mb-3">
        <div className="navitem-container">
          <NavItem>
            <NavLink
              tag={RRNavLink}
              activeClassName="active"
              to="/log/global">
              <T id="globallog" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={RRNavLink}
              activeClassName="active"
              to="/log/private?onlyPrivate=1">
              <T id="privatelog" />
            </NavLink>
          </NavItem>
        </div>
      </Nav>

      <TabContent>
        <Switch>
          <TabPane tag={Redirect} exact from={`${match.path}/`} to={`log/global`} />
          <TabPane tag={Route} path={`${match.path}/global`} component={LogList} />
          <TabPane tag={Route} path={`${match.path}/private`} component={PrivateLogList} />
          <TabPane tag={Redirect} to={`/error/`} />
        </Switch>
      </TabContent>
    </div>
  )
}

export default Account
