import React from 'react'

import VeloptimPlanComponent from '../../components/VeloptimPlanComponent'

import { NavLink, Link } from 'react-router-dom'

import { T, TLabel } from 'components/TComponents'

const SwapPlanVeloptim = ({ location }) => {
  const { id } = location.state.props

  return (
    <div className="container-fluid InterventionEdit">
      <div className="mb-3">
        <NavLink tag={Link} to={{
          pathname: `/swaps/${id}`,
          state: { swaps: location.state ? location.state.swaps : undefined }
        }}>
          <T id="returnToSwap" />
        </NavLink>
      </div>
      <TLabel id="VeloptimPlanModal.saveAndPlan" raw className="mb-3 title" />
      <VeloptimPlanComponent location={location} />
    </div>
  )
}

export default SwapPlanVeloptim