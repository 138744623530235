import React from 'react'
import { useRouteMatch, Route, Redirect, } from 'react-router-dom'
import {
  Card, CardHeader, CardBody
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import { NotificationManager } from 'components/ReactNotifications/index'
import { hasRights } from 'components/ProtectedComponent'
import { T } from 'components/TComponents'

class ErrorBoundary extends React.Component {
  state = {
    error: undefined
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  renderError (error) {
    const user = this.props && this.props.user ? this.props.user.userId : undefined
    const subsidiary = this.props && this.props.subsidiary ? this.props.subsidiary.subsidiaryName : undefined

    fetch(`${process.env.API_ENDPOINT}/velocare/alert`, {
      method: 'POST',
      headers: {
        'X-Velocare-Apikey': process.env.API_KEY,
      },
      body: JSON.stringify({ err: `
Href: ${window.location.href}
User: ${user ? window.location.origin + '/admin/users/' + user : 'None'}
Subsidiary: *${subsidiary || 'None'}*
${error.stack}`})
    })

    return (
      <Card>
        <CardHeader><T raw id="error.title" /></CardHeader>
        <CardBody>
          <p className="mb-0" style={{ whiteSpace: 'pre-wrap' }}>{error.toString()}</p>
        </CardBody>
      </Card>
    )
  }

  render () {
    return this.state.error ? this.renderError(this.state.error) : this.props.children
  }
}


const _Route = ({ rights, render, component: Component, ...props }) => {
  const { user, subsidiary, profiles } = React.useContext(AppContext)
  const routeMatch = useRouteMatch()

  if (!profiles && routeMatch.path !== '/') {
    return <Redirect to="/" />
  }

  if (rights && !hasRights(Array.isArray(rights) ? rights : [rights], profiles)) {
    NotificationManager.error('forbiddenAccess')
    return <Redirect to="/" />
  }

  return <Route {...props} render={rest => (
    <ErrorBoundary user={user} subsidiary={subsidiary}>
      {Component && <Component {...rest} />}
      {!Component && render && render(rest)}
    </ErrorBoundary>
  )} />
}

export default _Route
