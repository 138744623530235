import React from 'react'

import {
  Pagination as RSPAgination, PaginationItem, PaginationLink
} from 'reactstrap'

const MAX_PAGE_GAP = 2

const Pagination = ({ totalPage, currentPage, onPageClick }) => {
  const pages = [currentPage]

  let leftToFill = MAX_PAGE_GAP * 2
  let lastMaxPage = totalPage
  if (currentPage < totalPage) {
    for (let i = currentPage + 1; i <= currentPage + MAX_PAGE_GAP && i <= totalPage; ++i) {
      --leftToFill
      lastMaxPage = i
      pages.push(i)
    }
  }

  if (currentPage > 1) {
    for (let i = currentPage - 1; i >= 1 && leftToFill > 0; --i) {
      pages.push(i)
      --leftToFill
    }
  }

  for (; leftToFill > 0 && lastMaxPage < totalPage; --leftToFill) {
    pages.push(++lastMaxPage)
  }

  pages.sort((base, comp) => Number(base) - Number(comp))

  return (
    <RSPAgination className="justify-content-center nav" aria-label="Page navigation example">
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink className="first" onClick={() => onPageClick(1)}>
          <i className="simple-icon-control-start" />
        </PaginationLink>
      </PaginationItem>
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink className="prev" onClick={() => onPageClick(currentPage - 1)}>
          <i className="simple-icon-arrow-left" />
        </PaginationLink>
      </PaginationItem>
      {pages.map((page, index) => <PaginationItem key={index} active={page === currentPage} disabled={page === currentPage}>
        <PaginationLink onClick={() => onPageClick(page)}>
          {page}
        </PaginationLink>
      </PaginationItem>)}
      <PaginationItem disabled={currentPage === totalPage}>
        <PaginationLink className="next" onClick={() => onPageClick(currentPage + 1)}>
          <i className="simple-icon-arrow-right" />
        </PaginationLink>
      </PaginationItem>
      <PaginationItem disabled={currentPage === totalPage}>
        <PaginationLink className="last" onClick={() => onPageClick(totalPage)}>
          <i className="simple-icon-control-end" />
        </PaginationLink>
      </PaginationItem>
    </RSPAgination>
  )
}

export default Pagination

