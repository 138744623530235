import React from 'react'
import { useHistory, Link } from 'react-router-dom'

import {
  Card, CardBody, CardFooter,
  Form, FormGroup,
  Input
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import CustomSelect from 'components/CustomSelect'
import { T, TLabel, TButton, ErrAlert, TNavLink } from 'components/TComponents'

const FamilyNew = () => {
  const { api } = React.useContext(AppContext)
  const history = useHistory()

  const [familyName, setFamilyName] = React.useState('')
  const [categoryId, setCategoryId] = React.useState('')

  const [categories, setCategories] = React.useState()
  const [categoriesLoading, setCategoriesLoading] = React.useState(true)
  const [categoriesError, setCategoriesError] = React.useState()

  const [creating, setCreating] = React.useState(false)
  const [createError, setCreateError] = React.useState()

  React.useEffect(() => {
    api.get('/categories/list', undefined, { ipp: -1 })
      .then(bases => setCategories(bases))
      .catch(error => setCategoriesError(error))
      .then(() => setCategoriesLoading(false))
  }, [api])

  const create = React.useCallback((e) => {
    e.preventDefault()

    setCreating(true)
    api.post('/families/details', {
      body: JSON.stringify({
        familyName, categoryId
      })
    })
      .then(() => {
        history.push('/admin/categories?type=families')
      })
      .catch(error => {
        setCreateError(error)
        setCreating(false)
      })
  }, [api, categoryId, familyName, history])

  return (
    <>
      <TNavLink tag={Link} to="/admin/categories?type=families" id="returnToList" />
      <Card className="mb-2" tag={Form}
        onSubmit={create}>
        <CardBody>
          <FormGroup tag="fieldset">
            <TLabel for="categoryId" id="categoryId" />
            {categories && (
              <CustomSelect
                inputId="categoryId"
                name="categoryId"
                options={categories.result}
                onChange={e => setCategoryId(e.categoryId)}
                value={categories.result.filter(c => c.categoryId === categoryId)}
                isDisabled={creating || categoriesLoading}
                placeHolder={<T id="selectCategory" />}
                getOptionLabel={option => option.categoryName}
                getOptionValue={option => option.categoryId} />
            )}
            {categoriesError && <ErrAlert color="danger" id={`error.${categoriesError}`} />}
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="familyName" id="familyName" />
            <Input id="familyName"
              type="text"
              name="familyName"
              disabled={creating}
              value={familyName}
              onChange={e => setFamilyName(e.target.value)} />
          </FormGroup>
          {createError && <ErrAlert color="danger" error={createError} className="mb-0" />}
        </CardBody>
        <CardFooter className="d-flex justify-content-end">
          <TButton disabled={creating} loading={creating} type="submit" className="ml-2" color="primary" id="create" />
          <TButton disabled={creating} type="cancel" tag={Link} className="ml-2" to="/admin/categories?type=families" id="cancel" />
        </CardFooter>
      </Card>
    </>
  )
}

export default FamilyNew
