import React from 'react'
import { FormattedDate, FormattedTime, } from 'react-intl'
import { useLocation, useHistory, NavLink } from 'react-router-dom'
import { objectToQuery, queryToObject } from 'react-rest-api'

import {  Card, CardHeader } from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import { Table } from 'components/Table'
import { Filters } from 'components/Filters'
import ProtectedComponent from 'components/ProtectedComponent'
import { T, TBit, ErrAlert, TButton } from 'components/TComponents'

import { useGet } from 'hooks/useApi.hooks'
import { useFormatMessage } from 'hooks/intl.hooks'


const DeskList = () => {
  const { constants } = React.useContext(AppContext)
  const location = useLocation()
  const history = useHistory()
  const formatMessage = useFormatMessage()

  const filters = React.useMemo(() => ({
    p: {
      type: 'number'
    },
    ipp: {
      type: 'number'
    },
    'order[column]': {},
    'order[dir]': {},
    filter: {
      type: 'string',
      componentType: 'textDebounce'
    },
    businessId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isSearchable: true,
        getOptionValue: option => option.label,
        isMulti: false
      },
    },
    categoryId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false
      },
    },
    familyId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false
      },
    },
    ticketType: {
      array: true,
      componentType: 'select',
      componentOptions: {
        getOptionLabel: option => formatMessage({ id: `ticketType.${option.label}`, raw: true })
      }
    },
    criticity: {
      array: true,
      componentType: 'select',
      componentOptions: {
        getOptionLabel: option => formatMessage({ id: `ticketCriticity.${option.label}`, raw: true })
      }
    },
    ticketStatusId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: true,
        getOptionLabel: option => formatMessage({ id: `ticketStatus.${option.label}`, raw: true })
      },
    },
    availabilityIssue: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: true,
        getOptionLabel: option => formatMessage({ id: `availabilityIssue.${option.label}`, raw: true })
      },
    },
    deskTagId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: true
      },
    }
  }), [formatMessage])

  const params = React.useMemo(() => queryToObject(location.search, filters), [location.search, filters])

  const [data, refreshing, error, loading] = useGet(`/desk/list`, undefined, params)

  const refresh = React.useCallback((_params = {}) => history.push({ search: objectToQuery({ ...params, ..._params }) }), [history, params])

  const renderId = React.useCallback(item => {
    const color = ((value) => {
      switch (value) {
      case 'normal': return 'success'
      case 'urgent': return 'warning'
      case 'critical': return 'danger'
      default: return ''
      }
    })(item.criticityValue)

    return <h6 className="mb-0"><span className={`badge badge-${color}`}>{item.ticketId}</span></h6>
  }, [])

  const DetailsButton = React.useCallback(item => (
    <TButton className="ml-auto stretched-link" outline
      id="view"
      tag={NavLink}
      to={{
        pathname: `./desk/${item.ticketId}`,
        state: { desk: location.search }
      }} />
  ), [location])

  const renderTags = React.useCallback(item => (
    item.desktags && item.desktags.length > 0 && item.desktags.map(t => {
      return (<div key={t.desktagId} className="mb-1 text-center color-tag-rounded" style={{ backgroundColor: t.colorHex ? t.colorHex : '#fff', color: t.colorHex ? '#fff' : '#000' }}>
        {t.tagIdentifier}
      </div>)
    })
  ), [])

  if (error) {
    return <ErrAlert error={error} />
  }

  return (
    <div className="container-fluid">
      <Card className="mb-2">
        <CardHeader>
          <Filters
            loading={loading}
            disabled={refreshing}
            data={data}
            refresh={refresh}
            filters={filters}
          />
        </CardHeader>
        <ProtectedComponent rights={['desk_edit']}>
          <CardHeader className="d-flex">
            <TButton tag={NavLink}
              id="createTicket"
              to={{
                pathname: './desk/new',
                state: {
                  desk: location.state,
                  ticket: {
                    businessId: data && data.filters.businessId.selected ? data.filters.businessId.selected.value : ''
                  }
                }
              }} />
            <ProtectedComponent rights={['desk_board']}>
              <div className="ml-auto pl-2">
                <TButton tag={NavLink}
                  id="table"
                  to={{
                    pathname: './desk/table',
                    state: { desk: location.state }
                  }} />
              </div>
            </ProtectedComponent>
          </CardHeader>
        </ProtectedComponent>
      </Card>
      <Table
        isLoading={loading}
        disabled={refreshing}
        data={data}
        refresh={refresh}
        columns={[
          {
            title: 'ticketId',
            render: renderId
          },
          {
            title: 'equipmentIdentifier',
            render: item => item.equipmentIdentifier
          },
          {
            title: 'categoryId',
            render: item => item.categoryId
          },
          {
            title: 'familyId',
            render: item => item.familyId
          },
          {
            title: 'subscriptionId',
            render: item => item.subscriptionId
          },
          {
            title: 'dateCreated',
            hasOrder: true,
            render: item => item.dateCreated
              ? <><FormattedDate value={new Date(item.dateCreated)} />{' '}<FormattedTime value={new Date(item.dateCreated)} /></>
              : <T id="noDateCreated" />
          },
          {
            title: 'dateUpdated',
            hasOrder: true,
            render: item => item.dateUpdated
              ? <><FormattedDate value={new Date(item.dateUpdated)} />{' '}<FormattedTime value={new Date(item.dateUpdated)} /></>
              : <T id="noDateUpdated" />
          },
          {
            title: 'postalCode',
            hasOrder: true,
            render: item => item.postalCode
          },
          {
            title: 'city',
            hasOrder: true,
            render: item => item.city
          },
          {
            title: 'availabilityIssue',
            render: item => item.availabilityIssue
              ? <TBit bitmask={item.availabilityIssue} descriptor={constants.ticketAvailabilityIssue} prefix="availabilityIssue." raw />
              : <T id="noAvailabilityIssue" />
          },
          {
            title: 'ticketTypeId',
            render: item => formatMessage({ id: `ticketType.${item.ticketTypeValue}`, raw: true })
          },
          {
            title: 'ticketStatusId',
            hasOrder: true,
            render: item => formatMessage({ id: `ticketStatus.${item.ticketStatusValue}`, raw: true })
          },
          {
            title: 'deskTagId',
            render: renderTags
          },
          {
            className: () => 'align-middle',
            render: DetailsButton
          },
        ]}
      />
    </div>
  )
}

export default DeskList
