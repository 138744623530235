import React from 'react'

const VehicleIcon = ({ type }) => {
  switch (type) {
  case 1: return (
    <img src="/assets/img/cargo.png" alt="cargo" className="mr-2 ml-2" height="24" style={{ padding: 2 }} />
  )
  case 2: return (
    <img src="/assets/img/cargo-trailer.png" alt="cargo" className="mr-2 ml-2" height="24" style={{ padding: 2 }} />
  )
  case 3: return (
    <img src="/assets/img/vehicle-light.png" alt="cargo" className="mr-2 ml-2" height="24" style={{ padding: 2 }} />
  )
  case 4: return (
    <img src="/assets/img/vehicle-commercial.svg" alt="cargo" className="mr-2 ml-2" height="24" style={{ padding: 2 }} />
  )
  case 5: return (
    <img src="/assets/img/vehicle-other.png" alt="cargo" className="mr-2 ml-2" height="24" style={{ padding: 2 }} />
  )
  default: return <></>
  }
}

export default VehicleIcon