import React from 'react'
import { Switch } from 'react-router-dom'

import Route from 'components/Route'

import List from 'routes/Admin/Itvcodegroups/ItvcodegroupsList'
import Detail from 'routes/Admin/Itvcodegroups/ItvcodegroupsDetail'

export default function Users({ match }) {
  return <Switch>
    <Route exact path={`${match.path}`} component={List} rights={['admin-itvcodegroups_edit', 'admin-itvcodegroups_view',]} />
    <Route path={`${match.path}/new`} component={Detail} rights={['admin-itvcodegroups_edit',]} />
    <Route path={`${match.path}/:id`} component={Detail} rights={['admin-itvcodegroups_edit', 'admin-itvcodegroups_view',]} />
  </Switch>
}
