import React from 'react'
import classNames from 'classnames'

const TimelineHeaders = props => {
  return (
    <div
      data-testid="headerRootDiv"
      style={{
        display: 'flex',
      }}
      className={classNames('rct-header-root', props.className)}>
      <div className="rct-sidebar-header" style={{ minWidth: props.sidebarWidth }} />
      <div
        style={{ overflow: 'hidden' }}
        className={'rct-calendar-header'}
        data-testid="headerContainer">
        {props.children}
      </div>
    </div>
  )
}

export default TimelineHeaders
