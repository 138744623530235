import React from 'react'

const Duration = ({ duration, tagName: TagName = React.Fragment, ...props }) => {
  const durationString = React.useMemo(() => {
    const hours = Math.floor(duration / 60)
    const minutes = duration % 60

    const str = []
    if (hours > 0) {
      str.push(`${hours}h`)
    }

    if (minutes > 0) {
      if (minutes < 10) {
        str.push('0')
      }
      str.push(minutes)

      if (hours === 0) {
        str.push('m')
      }
    }

    if (str.length === 0) {
      str.push('0m')
    }
    return str.join('')
  }, [duration])

  return (
    <TagName {...props}>{durationString}</TagName>
  )
}

export default Duration