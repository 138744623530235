import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import Route from 'components/Route'

import List from './DeskList'
import Details from './DeskDetails'
import Table from './DeskTable'

class Admin extends React.Component {
  render() {
    const { match } = this.props
    return <Switch>
      <Route exact path={`${match.path}`} component={List} />
      <Route exact path={`${match.path}/new`} component={Details} key="create" />
      <Route exact path={`${match.path}/table`} component={Table} />
      <Route exact path={`${match.path}/:id`} component={Details} key="detail" />
      <Redirect to={`/`} />
    </Switch>
  }
}

export default Admin
