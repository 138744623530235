import React from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'

import {
  Card, CardBody, CardHeader, CardFooter,
  Form, FormGroup, Input,
  Modal, ModalBody, ModalFooter,
  Spinner
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import CustomSelect from 'components/CustomSelect'
import ProtectedComponent from 'components/ProtectedComponent'
import { T, TLabel, TButton, TNavLink, ErrAlert } from 'components/TComponents'

const Techcenter = () => {
  const { api } = React.useContext(AppContext)
  const routeParams = useParams()
  const history = useHistory()

  const [techcenter, setTechcenter] = React.useState({
    techcenterIdentifier: '',
    techcenterName: '',
    address: '',
    addressComplement: '',
    postalCode: '',
    city: '',
    stockIdentifier: '',
    baseId: '',
    clearanceId: ''
  })
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()
  const [techcenterBase, setTechcenterBase] = React.useState()

  const [bases, setBases] = React.useState()
  const [basesLoading, setBasesLoading] = React.useState(true)
  const [basesError, setBasesError] = React.useState()

  const [clearances, setClearances] = React.useState()
  const [clearancesLoading, setClearancesLoading] = React.useState(true)
  const [clearancesError, setClearancesError] = React.useState()

  const [archiveOpen, setArchiveOpen] = React.useState(false)
  const [archiveLoading, setArchiveLoading] = React.useState(false)
  const [archiveError, setArchiveError] = React.useState()

  const [updateLoading, setUpdateLoading] = React.useState(false)
  const [updateError, setUpdateError] = React.useState()

  const [edit, setEdit] = React.useState(!routeParams.id)

  React.useEffect(() => {
    if (routeParams.id) {
      api.get('/techcenters/details', undefined, { techcenterId: routeParams.id })
        .then(response => setTechcenterBase(response))
        .catch(response => setError(response))
        .then(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [api, routeParams])

  React.useEffect(() => {
    api.get('/bases/list', undefined, { ipp: -1 })
      .then(response => setBases(response))
      .catch(response => setBasesError(response))
      .then(() => setBasesLoading(false))

    api.get('/clearances/list', undefined, { ipp: -1 })
      .then(response => setClearances(response))
      .catch(response => setClearancesError(response))
      .then(() => setClearancesLoading(false))
  }, [api])

  React.useEffect(() => {
    if (techcenterBase) {
      setTechcenter(techcenterBase)
    }
  }, [techcenterBase])

  const handleChange = React.useCallback(({ target: { name, value } }) => {
    setTechcenter(techcenter => ({
      ...techcenter,
      [name]: value
    }))
  }, [])

  const save = React.useCallback(() => {
    setUpdateLoading(true)
    setUpdateError()
    Promise.all([
      api.post('/techcenters/details', { body: JSON.stringify(techcenter) }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([response]) => {
        if (routeParams.id) {
          setTechcenterBase(response)
          setEdit(false)
        } else {
          history.replace(`/admin/techcenters/${response.techcenterId}`)
        }
      })
      .catch(response => setUpdateError(response))
      .then(() => setUpdateLoading(false))
  }, [api, setUpdateLoading, techcenter, routeParams, history])

  const cancel = React.useCallback(() => {
    setTechcenter(techcenterBase)
    setEdit(false)
  }, [techcenterBase])

  const archive = React.useCallback(() => {
    setArchiveError()
    setArchiveLoading(true)
    Promise.all([
      api.del('/techcenters/details', undefined, { techcenterId: routeParams.id }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([response]) => {
        setTechcenterBase(response)
        setEdit(false)
        setArchiveOpen(false)
      })
      .catch(response => setArchiveError(response))
      .then(() => setArchiveLoading(false))
  }, [api, routeParams])

  const clearanceName = React.useMemo(() => {
    if (clearances) {
      const clearance = clearances.result.find(clearance => String(clearance.clearanceId) === String(techcenter.clearanceId))
      return clearance ? clearance.clearanceName : undefined
    }
  }, [clearances, techcenter.clearanceId])

  if (loading) {
    return <>
      <TNavLink tag={Link} to="/admin/techcenters" id="returnToList" />
      <Spinner className="d-flex ml-auto mr-auto" color="primary" />
    </>
  }

  if (error) {
    return <>
      <TNavLink tag={Link} to="/admin/techcenters" id="returnToList" />
      <ErrAlert error={error} />
    </>
  }

  return (
    <>
      <TNavLink tag={Link} to="/admin/techcenters" id="returnToList" />
      <Card className="mb-2" tag={Form}>
        {routeParams.id && <CardHeader tag="h2">
          {/* TODO: use useFormatMessage for title */}
          {/* {techcenter.archived && <i title={formatMessage({ id: 'asdasd' })} className="mr-1 mb-0 h5 iconsmind-Box-Close text-primary" />} */}
          {techcenter.archived && <i className="mr-1 mb-0 h5 iconsmind-Box-Close text-primary" />}
          {techcenter.techcenterIdentifier} - {techcenter.techcenterName}
        </CardHeader>}
        <CardBody>
          <FormGroup tag="fieldset">
            <TLabel for="techcenterIdentifier" id="techcenterIdentifier" />
            <Input id="techcenterIdentifier"
              type="text"
              name="techcenterIdentifier"
              disabled={!edit || updateLoading}
              value={techcenter.techcenterIdentifier}
              onChange={handleChange} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="techcenterName" id="techcenterName" />
            <Input id="techcenterName"
              type="text"
              name="techcenterName"
              disabled={!edit || updateLoading}
              value={techcenter.techcenterName}
              onChange={handleChange} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="address" id="address" />
            <Input id="address"
              type="text"
              name="address"
              disabled={!edit || updateLoading}
              value={techcenter.address}
              onChange={handleChange} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="addressComplement" id="addressComplement" />
            <Input id="addressComplement"
              type="text"
              name="addressComplement"
              disabled={!edit || updateLoading}
              value={techcenter.addressComplement}
              onChange={handleChange} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="postalCode" id="postalCode" />
            <Input id="postalCode"
              type="text"
              name="postalCode"
              disabled={!edit || updateLoading}
              value={techcenter.postalCode}
              onChange={handleChange} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="city" id="city" />
            <Input id="city"
              type="text"
              name="city"
              disabled={!edit || updateLoading}
              value={techcenter.city}
              onChange={handleChange} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="clearanceId" id="clearanceLabel" />
            {clearancesError && <ErrAlert error={clearancesError} className="mb-0" />}
            {clearancesLoading && <Spinner className="d-flex" />}
            {!clearancesLoading && !clearancesError &&
              <CustomSelect
                inputId="clearanceId"
                name="clearanceId"
                options={clearances.result}
                onChange={e => handleChange({ target: { name: 'clearanceId', value: e && e.clearanceId } })}
                isClearable
                placeholder={<T id="clearanceSelect" />}
                value={clearances.result.filter(c => c.clearanceId === techcenter.clearanceId)}
                getOptionLabel={option => <T raw id={`clearances.${option.clearanceName}`} />}
                getOptionValue={option => option.clearanceId}
                isDisabled={!edit || updateLoading} />
            }
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="stockIdentifier" id="stockIdentifier" values={{ clearanceName }} />
            <Input id="stockIdentifier"
              type="text"
              name="stockIdentifier"
              disabled={!edit || updateLoading || !techcenter.clearanceId}
              value={techcenter.stockIdentifier}
              onChange={handleChange} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="baseId" id="baseLabel" />
            {basesError && <ErrAlert error={basesError} className="mb-0" />}
            {basesLoading && <Spinner className="d-flex" />}
            {!basesLoading && !basesError &&
              <CustomSelect
                inputId="baseId"
                name="baseId"
                options={bases.result}
                onChange={e => handleChange({ target: { name: 'baseId', value: e && e.baseId } })}
                isClearable
                placeholder={<T id="baseSelect" />}
                value={bases.result.filter(b => b.baseId === techcenter.baseId)}
                getOptionLabel={option => option.baseName}
                getOptionValue={option => option.baseId}
                isDisabled={!edit || updateLoading} />
            }
          </FormGroup>
          {updateError && <ErrAlert error={updateError} />}
        </CardBody>
        <ProtectedComponent rights={['admin-techcenters_edit']}>
          <CardFooter className="d-flex">
            {routeParams.id && edit && <ProtectedComponent rights={['admin_archive']}>
              <TButton disabled={updateLoading} color="danger"
                onClick={() => setArchiveOpen(true)}
                id={techcenter.archived ? 'restore' : 'archive'} />
            </ProtectedComponent>}
            <div className="ml-auto pl-2">
              {routeParams.id && !edit && <TButton onClick={() => setEdit(true)} id="edit" />}
              {routeParams.id && edit && <TButton disabled={updateLoading} spin={updateLoading}
                onClick={save} color="primary" className="ml-2"
                id="save" />}
              {routeParams.id && edit && <TButton disabled={updateLoading}
                onClick={cancel} className="ml-2"
                id="cancel" />}
              {!routeParams.id && <TButton disabled={updateLoading} spin={updateLoading}
                onClick={save} className="ml-2" color="primary"
                id="create" />}
            </div>
          </CardFooter>
        </ProtectedComponent>

        <Modal isOpen={archiveOpen} fade={false} toggle={() => setArchiveOpen(false)}>
          <ModalBody>
            <T id={`${techcenter.archived ? 'restore' : 'archive'}.content`} />
            {archiveError && <ErrAlert className="mb-0 mt-2" error={archiveError} />}
          </ModalBody>
          <ModalFooter>
            <TButton disabled={archiveLoading}
              onClick={() => setArchiveOpen(false)}
              id={`${techcenter.archived ? 'restore' : 'archive'}.cancel`} />
            <TButton disabled={archiveLoading} spin={archiveLoading}
              className="ml-2" color="danger"
              onClick={archive}
              id={`${techcenter.archived ? 'restore' : 'archive'}.confirm`} />
          </ModalFooter>
        </Modal>
      </Card>
    </>
  )
}

export default Techcenter
