import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import Route from 'components/Route'

import InterventionNew from './InterventionNew'
import InterventionEdit from './InterventionEdit'
import InterventionPlan from './InterventionPlan'
import InterventionPlanVeloptim from './InterventionPlanVeloptim'
import InterventionList from './InterventionList'
import InterventionTable from './InterventionTable'
import InterventionStart from './InterventionStart'

const InterventionRoute = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/new`} component={InterventionNew} rights={['interventions_new']} />
    <Route exact path={`${match.path}/table`} component={InterventionTable} rights={['interventions_view']} />
    <Route exact path={`${match.path}/plan/:id`} component={InterventionPlan} rights={['interventions_view']} />
    <Route exact path={`${match.path}/veloptim/:id`} component={InterventionPlanVeloptim} rights={['interventions_view']} />
    <Route exact path={`${match.path}/:id`} component={InterventionEdit} rights={['interventions_view']} />
    <Route exact path={`${match.path}`} component={InterventionList} rights={['interventions_view']} />
    <Route exact path={`${match.path}/start/:id`} component={InterventionStart} rights={['interventions-end_web']} />
    {/* TODO: Bad redirect ??? */}
    <Redirect to={`/`} />
  </Switch>
)

export default InterventionRoute
