import React from 'react'
import {
  Card, CardBody, CardFooter,
  FormGroup, Input
} from 'reactstrap'
import { useHistory, Link } from 'react-router-dom'

import { AppContext } from 'contexts/AppContext'

import { T, TLabel, TButton, ErrAlert, TNavLink, TCustomInput } from 'components/TComponents'
import ItvcodeSelector from 'components/ItvcodeSelector'
import CustomSelect from 'components/CustomSelect'

const TriggersNew = () => {

  const { api, TRIGGERS_CRITICALITY } = React.useContext(AppContext)
  const history = useHistory()

  const [createError, setCreateError] = React.useState(false)
  const [triggersAvailable, setTriggersAvailable] = React.useState()
  const [triggersAvailableError, setTriggersAvailableError] = React.useState()
  const [triggersAvailableLoading, setTriggersAvailableLoading] = React.useState()

  const [triggerIdentifier, setTriggerIdentifier] = React.useState()

  const [triggerToPost, setTriggerToPost] = React.useState({ 'enabled': true, params: {} })

  const postTrigger = React.useCallback(() => {
    setCreateError(false)
    const trigger = triggerToPost.params.itvcodeId ? {
      ...triggerToPost,
      params: {
        ...triggerToPost.params,
        'itvcodeId': triggerToPost.params.itvcodeId[0].itvcodeId
      }
    } : triggerToPost
    api.post('/triggers/privateDetails', {
      body: JSON.stringify(trigger)
    })
      .then(response => {
        if (response.result) {
          history.push('/account/triggers')
        }
      })
      .catch(error => setCreateError(error))
  }, [api, triggerToPost, history])

  React.useEffect(() => {
    setTriggersAvailableLoading(true)
    api.get('/triggers/availablelist', undefined, { onlyPrivate : 1 })
      .then(response => setTriggersAvailable(response))
      .catch(error => setTriggersAvailableError(error))
      .then(() => setTriggersAvailableLoading(false))
  }, [api])

  const getFormattedItvcodesOptions = data => {
    return (data.map(d => (
      d.families.map(f => ({
        label: `${d.categoryName} - ${f.familyName}`,
        options: f.items.map(i => (
          {
            label: `${i.itvcodeIdentifier}  - ${i.itvcodeLabel} - (${d.categoryName}-${f.familyName})`,
            value: Object.assign({}, i, { categoryName: d.categoryName }, { familyName: f.familyName })
          })
        )
      }))
    ))[0])
  }

  const handleChange = React.useCallback(({ target: { name, value, type, checked }, isParam }) => {
    if (isParam) {
      setTriggerToPost({
        ...triggerToPost,
        params: {
          ...triggerToPost.params,
          [name]: type === 'checkbox'
            ? checked
            : type === 'number' ? parseInt(value) : value
        }
      })
    } else {
      setTriggerToPost({
        ...triggerToPost,
        [name]: type === 'checkbox'
          ? checked
          : type === 'number' ? parseInt(value) : value
      })
    }
  }, [triggerToPost])

  const renderField = React.useCallback((param) => {
    switch (param.type) {
    case 'integer':
      return (
        <FormGroup tag="fieldset">
          <TLabel for={param.name} id={`Triggers.${param.name}`} raw />
          <Input
            id={param.name}
            type="number"
            name={param.name}
            value={triggerToPost.params[param.name]}
            onChange={e => handleChange({ target: { name: e.target.name, value: e.target.value }, isParam: true })} />
        </FormGroup>
      )
    case 'identifier':
      return (
        <FormGroup tag="fieldset">
          <TLabel for={param.name} id={`Triggers.${param.name}`} raw />
          <ItvcodeSelector
            hasBusiness
            itvcodesAdded={triggerToPost.params[param.name] || []}
            itvcodes={getFormattedItvcodesOptions(param.values.itvcodes)}
            noQuantity
            noWear
            withHeaders
            withFamilies
            onlyOne
            onChange={codes => handleChange({ target: { name: param.name, value: codes }, isParam: true })} />
        </FormGroup>
      )
    default: return <></>
    }
  }, [handleChange, triggerToPost])

  return (
    <>
      <TNavLink id="returnToList" tag={Link} to="/account/triggers" />
      <Card>
        <CardBody>
          {createError && <ErrAlert error={createError} />}
          {triggersAvailableError && <ErrAlert error={triggersAvailableError} />}
          <FormGroup tag="fieldset">
            <TLabel for="triggerIdentifier" id="triggerIdentifier" />
            <CustomSelect
              id="triggerIdentifier"
              options={triggersAvailable}
              enabled={!triggersAvailableLoading}
              onChange={value => {
                setTriggerIdentifier(value)
                handleChange({ target: { name: "triggerIdentifier", value: value.name } })
              }}
              value={triggersAvailable && triggerIdentifier && triggersAvailable.find(t => t.name === triggerIdentifier.name)}
              placeholder={<T id="triggerIdentifier.placeholder" />}
              getOptionValue={option => option.name}
              getOptionLabel={option => <T id={`Triggers.${option.name}`} raw />} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="description" id="description" />
            <Input
              id="description"
              type="text"
              name="description"
              value={triggerToPost.description}
              onChange={handleChange} />
          </FormGroup>
          {triggerIdentifier && (
            <>
              {triggerIdentifier.params.map(param => (
                <>
                  {renderField(param)}
                </>
              ))}
            </>
          )}
          <FormGroup tag="fieldset">
            <TLabel for="criticality" id="criticality" />
            <CustomSelect
              id="criticality"
              options={TRIGGERS_CRITICALITY}
              onChange={value => handleChange({ target: { name: "criticality", value: value.value } })}
              placeholder={< T id="criticality.placeholder" />}
              getOtionValue={option => option.value}
              getOptionLabel={option => <T id={`Triggers.criticality.${option.key}`} raw />} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TCustomInput type="switch"
              id="enabled"
              name="enabled"
              label="enabled"
              className="w-100 bg-transparent border-0"
              checked={triggerToPost.enabled}
              onChange={handleChange} />
          </FormGroup>
        </CardBody>
        <CardFooter className="d-flex justify-content-end">
          <TButton
            className="ml-2"
            onClick={postTrigger}
            id="add" />
        </CardFooter>
      </Card>
    </>
  )
}

export default TriggersNew
