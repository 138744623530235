import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  Card, CardBody, CardHeader,
  FormGroup
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import ProtectedComponent from 'components/ProtectedComponent'
import { TCustomInput, TAlert, TLabel, TInput, TButton } from 'components/TComponents'
import { Table } from 'components/Table'

import { debounce } from 'util/Utils'

const PartList = () => {
  const { api } = React.useContext(AppContext)

  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()

  const [filter, setFilter] = React.useState('')

  React.useEffect(() => {
    setLoading(true)
    api.get('/parts/list', undefined)
      .then(response => setData(response))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api])

  const refresh = React.useCallback(params => {
    setLoading(true)
    setError()
    Promise.all([
      api.get('/parts/list', undefined, {
        ...data.filters,
        p: data.currentPage,
        ...params
      }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => setData(data))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api, data])

  const refreshDebounced = React.useMemo(() => debounce(params => refresh(params), 250), [refresh])

  const handleSearchInput = React.useCallback(({ target: { value } }) => {
    setFilter(value)
    refreshDebounced({ filter: value, p: 1 })
  }, [refreshDebounced])

  const RefAndArchived = React.useCallback(part => (
    <>
      {part.dateArchived && <i className="mr-1 iconsmind-Lock text-danger" />}
      {part.partRef}
    </>
  ), [])

  const DetailsButton = React.useCallback(part => (
    <TButton id="viewPart" className="ml-auto stretched-link" outline
      tag={NavLink}
      to={`/veloparts/parts/${part.partId}`} />
  ), [])

  return (
    <>
      <Card className="mb-2">
        <CardHeader>
          <FormGroup className="flex-grow-1 pl-0">
            <TLabel id="partsFilterTitle" className="sr-only" for="partFilter" />
            <TInput
              name="partFilter"
              type="text"
              placeholder="partFilterPlaceholder"
              value={filter}
              onChange={handleSearchInput} />
          </FormGroup>
          <FormGroup className="flex-grow-1 pl-0 mb-0">
            <TCustomInput type="switch"
              id="archived"
              name="archived"
              label="archived"
              className="w-100 bg-transparent border-0"
              disabled={loading}
              checked={data && data.filters ? data.filters.archived : false}
              onChange={e => refresh({ archived: e.target.checked, p: 1 })} />
          </FormGroup>
        </CardHeader>
        <ProtectedComponent rights={['veloparts-parts_edit']}>
          <CardHeader>
            <TButton id="createPart"
              tag={NavLink}
              to="/veloparts/parts/new" />
          </CardHeader>
        </ProtectedComponent>
        <CardBody>
          {error && <TAlert color="danger" id={error.code} />}
          <Table
            isLoading={loading}
            disabled={loading}
            data={data}
            refresh={refresh}
            columns={[
              {
                title: 'partRef',
                render: RefAndArchived
              },
              {
                title: 'partLabel',
                hasOrder: true,
                render: item => item.partLabel
              },
              {
                title: 'externalIdentifier',
                hasOrder: true,
                render: item => item.externalIdentifier
              },
              {
                title: 'barcode',
                hasOrder: true,
                render: item => item.barcode
              },
              {
                className: () => 'align-middle text-right',
                render: DetailsButton
              }
            ]}
          />
        </CardBody>
      </Card>
    </>

  )
}

export default PartList
