import React from 'react'
import { useHistory, Link } from 'react-router-dom'

import {
  Label,
  Card, CardBody, CardFooter,
  Form, FormGroup,
  Input,
  NavLink,
  Spinner
} from 'reactstrap'
import CustomSelect from 'components/CustomSelect'

import { AppContext } from 'contexts/AppContext'
import { T, ErrAlert, TLabel, TButton, TNavLink, } from 'components/TComponents'

const UserNew = () => {
  const { api, constants } = React.useContext(AppContext)
  const history = useHistory()

  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [userTypeId, setUserTypeId] = React.useState(0)
  const [company, setCompany] = React.useState('')
  const [aplz, setAplz] = React.useState(false)

  const [loadError, setLoadError] = React.useState()
  const [companies, setCompanies] = React.useState([])
  const [companiesLoading, setCompaniesLoading] = React.useState(true)
  const [profiles, setProfiles] = React.useState([])
  const [profilesLoading, setProfilesLoading] = React.useState(true)
  const [baseProfiles, setBaseProfiles] = React.useState()

  const [creating, setCreating] = React.useState(false)
  const [createError, setCreateError] = React.useState()

  const sortProfile = (a, b) => {
    if (a.baseId < b.baseId) {
      return -1;
    }
    if (a.baseId > b.baseId) {
      return 1;
    }
    return 0;
  }

  React.useEffect(() => {
    api.get('/companies/list', undefined, { ipp: -1 })
      .then(response => setCompanies(response))
      .catch(response => setLoadError(response))
      .then(() => setCompaniesLoading(false))
  }, [api])

  React.useEffect(() => {
    api.get('/rights/profiles', undefined, { ipp: -1 })
      .then(profiles => setBaseProfiles(profiles.sort(sortProfile)))
      .catch(response => setLoadError(response))
      .then(() => setProfilesLoading(false))
  }, [api])

  const handleCheckProfile = React.useCallback((e, profileId) => {
    setProfiles(e.target.checked
      ? profiles.concat(baseProfiles.find(pr => pr.profileId === profileId))
      : profiles.filter(pr => pr.profileId !== profileId))
  }, [baseProfiles, profiles])

  const create = React.useCallback(() => {
    setCreating(true)
    setCreateError()
    api.post('/users/details', {
      body: JSON.stringify({
        firstName, lastName, email, userTypeId,
        companyId: company ? company.companyId : undefined,
        aplz,
        profiles: Object.values(profiles).map(profile => profile.profileId)
      })
    })
      .then(() => history.push('/admin/users'))
      .catch(response => {
        setCreating(false)
        setCreateError(response)
      })
  }, [api, firstName, lastName, email, userTypeId, company, aplz, profiles, history])

  if (loadError) {
    return <>
      <TNavLink id="returnToList" tag={Link} to="/admin/users" />
      <ErrAlert error={loadError} className="mt-2" />
    </>
  }

  if (profilesLoading || companiesLoading) {
    return <>
      <NavLink id="returnToList" tag={Link} to="/admin/users" />
      <Spinner className="d-flex ml-auto mr-auto" color="primary" />
    </>
  }

  return (
    <>
      <TNavLink id="returnToList" tag={Link} to="/admin/users" />
      <Card className="mb-2">
        <CardBody tag={Form}>
          <FormGroup tag="fieldset">
            <TLabel id="firstName" for="firstName" />
            <Input id="firstName"
              type="text"
              name="firstName"
              value={firstName}
              disabled={creating}
              onChange={e => setFirstName(e.target.value)} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel id="lastName" for="lastName" />
            <Input id="lastName"
              type="text"
              name="lastName"
              value={lastName}
              disabled={creating}
              onChange={e => setLastName(e.target.value)} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel id="email" for="email" />
            <Input id="email"
              type="text"
              name="email"
              value={email}
              disabled={creating}
              onChange={e => setEmail(e.target.value)} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel id="userTypeId" for="userTypeId" />
            <CustomSelect
              id="userTypeId"
              onChange={e => setUserTypeId(e.value)}
              isSearchable={false}
              name="userTypeId"
              options={constants.userTypes}
              value={constants.userTypes.find(_userType => _userType.value === userTypeId)}
              placeholder={'...'}
              isDisabled={creating}
              getOptionLabel={option => <T raw id={`userType.${option.key}`} />} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel id="companyId.label" for="userTypeId" />
            <CustomSelect
              id="companyId"
              onChange={(e, f) => { console.log(e, f); setCompany(e) }}
              isSearchable={false}
              name="companyId"
              options={companies.result}
              value={company}
              placeholder={'...'}
              isDisabled={creating}
              getOptionLabel={option => option.companyName}
              getOptionValue={option => option.companyId} />
          </FormGroup>

          <FormGroup tag="fieldset" className="mb-3" check>
            <Input id="aplz"
              type="checkbox"
              name="aplz"
              checked={aplz}
              disabled={creating}
              onChange={e => setAplz(e.target.checked)} />
            <TLabel id="aplz" for="aplz" check className="" />
          </FormGroup>

          <TLabel id="profilesList" />
          {baseProfiles.map(profile => <FormGroup key={profile.profileId} tag="fieldset" check>
            <Input id={`profile.${profile.profileId}`}
              type="checkbox"
              name={profile.nameProfile}
              checked={profiles.find(_profile => _profile.profileId === profile.profileId) || false}
              onChange={e => handleCheckProfile(e, profile.profileId)} />
            <Label for={`profile.${profile.profileId}`}>{`${profile.baseName} - ${profile.nameProfile}`}</Label>
          </FormGroup>)}
          {createError && <ErrAlert error={createError} className="mb-0 mt-2" />}
        </CardBody>
        <CardFooter className="d-flex justify-content-end">
          <TButton id="create" disabled={creating} className="ml-2" onClick={() => create()} color="primary" />
          <TButton id="cancel" disabled={creating} tag={Link} className="ml-2" to="/admin/users" />
        </CardFooter>
      </Card>
    </>
  )
}

export default UserNew
