/* eslint-disable react/display-name */
import React from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'
import { useParams, useLocation, useRouteMatch, Redirect, Switch, NavLink as RRNavLink } from 'react-router-dom'

import { Card, CardHeader, Nav, NavItem, TabContent, TabPane } from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import { useFormatMessage } from 'hooks/intl.hooks'

import { ErrAlert, TButton, T, TCustomInput, TNavLink } from 'components/TComponents'
import { Table } from 'components/Table'
import { Filters } from 'components/Filters'
import Route from 'components/Route'

const EquipmentLogRoute = () => {

  const location = useLocation()
  const routeParams = useParams()
  const routeMatch = useRouteMatch()

  return (
    <>
      <Nav tabs className="mb-3">
        <div className="navitem-container">
          <NavItem>
            <TNavLink
              id={`global`}
              tag={RRNavLink}
              activeClassName="active"
              to={{
                pathname: `/equipments/${routeParams.id}/logs/global`,
                state: location.state
              }} />
          </NavItem>
          <NavItem>
            <TNavLink
              id={`private`}
              tag={RRNavLink}
              activeClassName="active"
              to={{
                pathname: `/equipments/${routeParams.id}/logs/private`,
                state: location.state
              }} />
          </NavItem>
        </div>
      </Nav>

      <TabContent>
        <Switch>
          <TabPane tag={Route} exact path={`${routeMatch.path}/global`} render={() => <EquipmentGlobalLog id={routeParams.id} />} />
          <TabPane tag={Route} exact path={`${routeMatch.path}/private`} render={() => <EquipmentPrivateLog id={routeParams.id} />} />
          <Redirect to={`${routeMatch.path}/global`} />
        </Switch>
      </TabContent>
    </>
  )
}

const EquipementLogList = ({ id, isPrivate }) => {
  const formatMesage = useFormatMessage()
  const { api } = React.useContext(AppContext)

  const [data, setData] = React.useState([])
  const [error, setError] = React.useState()
  const [loading, setLoading] = React.useState()

  const [refreshedData, setRefreshedData] = React.useState()

  const filters = React.useMemo(() => ({
    p: {
      type: 'number'
    },
    ipp: {
      type: 'number'
    },
    'order[column]': {},
    'order[dir]': {},
    filter: {
      type: 'string',
      componentType: 'textDebounce'
    },
    criticality: {
      componentType: 'select',
      componentOptions: {
        getOptionLabel: option => formatMesage({ id: `Triggers.criticality.${option.label}`, raw: true })
      }
    },
    archived: {
      type: 'boolean',
      componentType: "switch",
      noLabel: true
    },
  }), [formatMesage])

  React.useEffect(() => {
    Promise.all([
      api.get(isPrivate ? '/alerttriggers/listPrivate' : '/alerttriggers/list', undefined, { equipmentId: id, p: 1 }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => setData(data))
  }, [api, id, isPrivate])

  const refresh = React.useCallback((params) => {
    setLoading(true)
    setError()
    Promise.all([
      api.get(isPrivate ? '/alerttriggers/listPrivate' : '/alerttriggers/list', undefined, {
        p: 1,
        filter: data.filters.filter,
        equipmentId: id,
        criticality: data.filters.criticality.selected && data.filters.criticality.selected.value,
        archived: data.filters.archived,
        ...params
      }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => setData(data))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api, data, id, isPrivate])

  React.useEffect(() => {
    if (data) {
      setRefreshedData(data)
    }
  }, [data])

  // INNER COMPONENTS
  const RenderId = React.useCallback(item => {
    const color = ((value) => {
      switch (value) {
      case 1: return 'success'
      case 2: return 'warning'
      case 3: return 'danger'
      default: return ''
      }
    })(item.criticality)
    return <h6 className="mb-0"><span className={`badge badge-${color}`}>{item.alerttriggerId}</span></h6>
  }, [])

  const DateCreated = React.useCallback(item => (
    <span><FormattedDate value={new Date(item.dateCreated)} />{' '}<FormattedTime value={new Date(item.dateCreated)} /></span>
  ), [])

  const RenderMessage = React.useCallback(item => {
    switch (item.triggerIdentifier) {
    case 'frequency_intervention_equipment':
      return (
        <T id="Triggers.message.frequency_intervention_equipment" raw values={{ equipmentId: item.equipmentIdentifier, nbItv: item.params.nb_itv, frequency: item.params.frequency }} />
      );
    case 'frequency_intervention_equipment_one_itvcode':
      return (
        <T id="Triggers.message.frequency_intervention_equipment_one_itvcode" raw values={{ equipmentId: item.equipmentIdentifier, nbItv: item.params.nb_itv, frequency: item.params.frequency, itvcodeIdentifier: item.params.itvcodeIdentifier, itvcodeLabel: item.params.itvcodeLabel }} />
      );
    case 'frequency_none_intervention_equipment_one_itvcode':
      return (
        <T id="Triggers.message.frequency_none_intervention_equipment_one_itvcode" raw values={{ equipmentId: item.equipmentIdentifier, frequency: item.params.frequency, itvcodeIdentifier: item.params.itvcodeIdentifier, itvcodeLabel: item.params.itvcodeLabel }} />
      );
    case 'frequency_none_intervention_equipment':
      return (
        <T id="Triggers.message.frequency_none_intervention_equipment" raw values={{ equipmentId: item.equipmentIdentifier, frequency: item.params.frequency }} />
      );
    case 'checklist_nok':
      return (
        <T id="Triggers.message.checklist_nok" raw values={{ interventionId: item.params.interventionId }} />
      );
    case 'checklist_watch':
      return (
        <T id="Triggers.message.checklist_watch" raw values={{ interventionId: item.params.interventionId }} />
      );
    case 'qualitycontrol_nok':
      return (
        <T id="Triggers.message.qualitycontrol_nok" raw values={{ interventionId: item.params.interventionId }} />
      );
    case 'qualitycontrol_watch':
      return (
        <T id="Triggers.message.qualitycontrol_watch" raw values={{ interventionId: item.params.interventionId }} />
      );
    case 'frequency_intervention_equipment_one_itvcode_subscription':
      return (
        <T id="Triggers.message.frequency_intervention_equipment_one_itvcode_subscription" raw values={{ equipmentId: item.equipmentIdentifier, nbItv: item.params.nb_itv, frequency: item.params.frequency, itvcodeIdentifier: item.params.itvcodeIdentifier, itvcodeLabel: item.params.itvcodeLabel, subscriptionId: item.subscriptionId }} />
      );
    default:
      return ''
    }
  }, [])

  const DetailsButton = React.useCallback(item => {
    switch (item.triggerIdentifier) {
    case 'frequency_intervention_equipment': case 'frequency_intervention_equipment_one_itvcode': case 'frequency_none_intervention_equipment_one_itvcode': case 'frequency_none_intervention_equipment': case 'frequency_intervention_equipment_one_itvcode_subscription':
      return (
        <TButton className="ml-auto" outline
          id="details"
          tag={RRNavLink}
          to={`/equipments/${item.equipmentId}`} />
      );
    case 'checklist_nok': case 'checklist_watch': case 'qualitycontrol_nok': case 'qualitycontrol_watch':
      return (
        <TButton className="ml-auto" outline
          id="details"
          tag={RRNavLink}
          to={`/interventions/${item.params.interventionId}`} />
      );
    default:
      return ''
    }
  }, [])

  const ArchiveButton = React.useCallback(item => {
    return (
      <TCustomInput type="switch"
        id="archivedButton"
        name="archivedButton"
        className="w-100 bg-transparent border-0"
        onChange={() => { archive(item) }}
        checked={item.archived} />
    )
  }, [archive])

  const archive = React.useCallback(item => {
    if (!item.archived) {
      api.del('/alerttriggers/details', undefined, { alerttriggersId: item.alerttriggerId })
        .then(() => {
          setLoading(true)
          setError()
          Promise.all([
            api.get('/alerttriggers/list', undefined, {
              p: 1,
              filter: data && data.filters && data.filters.filter,
              equipmentId: id,
              criticality: data && data.filters && data.filters.criticality.selected && data.filters.criticality.selected.value,
              archived: data && data.filters && data.filters.archived
            }),
            new Promise(resolve => setTimeout(resolve, 250))
          ])
            .then(([data]) => setData(data))
            .catch(error => setError(error))
            .then(() => setLoading(false))
        })
    }
  }, [api, data, id])

  // RENDER
  if (error) {
    return <ErrAlert error={error} />
  }

  return (
    <div className="container-fluid">
      <Card className="mb-2">
        <CardHeader>
          <Filters
            loading={loading}
            disabled={loading}
            data={refreshedData || data}
            refresh={refresh}
            filters={filters}
          />
        </CardHeader>
      </Card>
      <Table
        isLoading={loading}
        disabled={loading}
        data={refreshedData || data}
        refresh={refresh}
        columns={[
          {
            title: 'alerttriggerId',
            render: RenderId
          },
          {
            title: 'dateCreated',
            hasOrder: true,
            render: DateCreated
          },
          {
            title: 'type',
            render: item => <T id={`Triggers.${item.triggerIdentifier}`} raw />
          },
          {
            title: 'description',
            render: item => item.description
          },
          {
            title: 'message',
            render: RenderMessage
          },
          {
            title: 'archived',
            render: ArchiveButton
          },
          {
            className: () => 'align-middle text-right',
            render: DetailsButton
          },
        ]}
      />
    </div>
  )
}

const EquipmentGlobalLog = ({ id }) => {
  return <EquipementLogList id={id} isPrivate={false} />
}

const EquipmentPrivateLog = ({ id }) => {
  return <EquipementLogList id={id} isPrivate={true} />
}

export default EquipmentLogRoute
