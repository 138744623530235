import React from 'react'
import {
  ListGroup, ListGroupItem,
  Card, CardBody, CardHeader, CardFooter,
  Form, FormGroup, Input, Label,
  Modal, ModalBody, ModalFooter,
  Spinner
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import CustomSelect from 'components/CustomSelect'
import ProtectedComponent from 'components/ProtectedComponent'
import { T, TButton, TCustomInput, TLabel, ErrAlert } from 'components/TComponents'

const Subsidiary = () => {
  const { api, setSubsidiary, subsidiary: { subsidiaryId }, constants } = React.useContext(AppContext)
  const fileInput = React.useRef()

  const [subsidiaryState, setSubsidiaryState] = React.useState()
  const [subsidiaryBase, setSubsidiaryBase] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()
  const [updating, setUpdating] = React.useState(false)
  const [updateError, setUpdateError] = React.useState()
  const [archiving, setArchiving] = React.useState(false)
  const [archiveError, setArchiveError] = React.useState()
  const [languages, setLanguages] = React.useState([])
  const [timezones, setTimezones] = React.useState([])
  const [currencies, setCurrencies] = React.useState([])

  const [onEdit, setOnEdit] = React.useState(false)
  const [archiveOpen, setArchiveOpen] = React.useState(false)
  const [uploadError, setUploadError] = React.useState()
  const [uploadLoading, setUploadLoading] = React.useState()

  React.useEffect(() => {
    Promise.all([
      api.get('/languages/list'),
      api.get('/timezones/list'),
      api.get('/currencies/list'),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([languages, timezones, currencies]) => {
        setLanguages(languages)
        setTimezones(timezones)
        setCurrencies(currencies)
      })
      .catch(error => setError(error))
  }, [api])

  React.useEffect(() => {
    setLoading(true)
    api.get('/subsidiaries/details', undefined, { subsidiaryId })
      .then(response => {
        setSubsidiaryState(response)
        setSubsidiaryBase(response)
      })
      .then(() => getLogo())
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api, subsidiaryId, getLogo])

  React.useEffect(() => {
    if (subsidiaryState && subsidiaryState.invoiceSystemId && !subsidiaryState.invoiceSystemId.key) {
      setSubsidiaryState(subsidiary => ({
        ...subsidiary,
        invoiceSystemId: {
          value: subsidiaryState.invoiceSystemId,
          key: ([{ value: 0, key: 'invoice.noSystem' }].concat(constants.invoiceSystem)).filter(i => i.value === subsidiaryState.invoiceSystemId)[0].key
        }
      }
      ))
    }
    if (subsidiaryState && !subsidiaryState.invoiceSystemId) {
      setSubsidiaryState(subsidiary => ({
        ...subsidiary,
        invoiceSystemId: { value: 0, key: 'invoice.noSystem' }
      }))
    }
  }, [subsidiaryState, constants])

  const getLogo = React.useCallback(() => {
    api.get('/subsidiaries/logo', undefined, { subsidiaryId })
      .then(response => {
        if (response && response.path) {
          setSubsidiaryState(subsidiary => ({
            ...subsidiary,
            logo: response.path
          }))
        }
      })
      .catch(error => setError(error))
  }, [api, subsidiaryId])

  const handleLogo = React.useCallback(e => {
    const file = e.target.files[0]
    setUploadLoading(true)
    api.put('/subsidiaries/logo', { body: file }, {
      subsidiaryId: subsidiaryState.subsidiaryId
    })
      .then(response => {
        if (response && response.path) {
          setSubsidiaryState(subsidiary => ({
            ...subsidiary,
            logo: response.path
          }))
        }
      })
      .catch(error => setUploadError(error))
      .then(setUploadLoading(false))
  }, [api, subsidiaryState])

  const deleteLogo = React.useCallback(() => {
    api.del('/subsidiaries/logo', undefined, { subsidiaryId })
      .then(() => {
        setSubsidiaryState(subsidiary => ({
          ...subsidiary,
          logo: undefined
        }))
      })
      .catch(error => setError(error))
  }, [api, subsidiaryId])

  const handleChange = React.useCallback(({ target: { name, value } }) => {
    setSubsidiaryState(subsidiary => ({ ...subsidiary, [name]: value }))
  }, [])

  const handleChangeLanguage = React.useCallback(value => {
    setSubsidiaryState(subsidiary => ({
      ...subsidiary,
      langId: value.langId,
      langIdentifier: value.langIdentifier,
      langName: value.langName
    }))
  }, [])

  const handleChangeTimezone = React.useCallback(value => {
    setSubsidiaryState(subsidiary => ({
      ...subsidiary,
      timezoneId: value.timezoneId,
      timezoneIdentifier: value.timezoneIdentifier,
    }))
  }, [])

  const handleChangeCurrency = React.useCallback(value => {
    setSubsidiaryState(subsidiary => ({
      ...subsidiary,
      currencyId: value.currencyId,
      currencyIdentifier: value.currencyIdentifier,
    }))
  }, [])

  const handleChangeVeloptim = React.useCallback(value => {
    setSubsidiaryState(subsidiary => ({
      ...subsidiary,
      enableVeloptim: value ? 1 : 0,
    }))
  }, [])

  const handleChangeReasonParts = React.useCallback(value => {
    setSubsidiaryState(subsidiary => ({
      ...subsidiary,
      enableReasonParts: value ? 1 : 0,
    }))
  }, [])

  const handleChangeSameEquipment = React.useCallback(value => {
    setSubsidiaryState(subsidiary => ({
      ...subsidiary,
      enableSameEquipmentIdentifier: value ? 1 : 0,
    }))
  }, [])

  const handleChangeVelocenter = React.useCallback(value => {
    setSubsidiaryState(subsidiary => ({
      ...subsidiary,
      enableVelocenter: value ? 1 : 0,
    }))
  }, [])

  const handleChangeInvoiceSystem = React.useCallback(value => {
    setSubsidiaryState(subsidiary => ({
      ...subsidiary,
      invoiceSystemId: { value: value.value, key: value.key }
    }))
  }, [])

  const save = React.useCallback(() => {
    setUpdating(true)
    setUpdateError(undefined)
    const body = {
      ...subsidiaryState,
      invoiceSystemId: subsidiaryState.invoiceSystemId.value
    }
    api.post('/subsidiaries/details', { body: JSON.stringify(body) })
      .then(response => {
        setSubsidiaryState(response)
        setSubsidiaryBase(response)
        setSubsidiary(response)
        setOnEdit(false)
      })
      .catch(error => setUpdateError(error))
      .then(() => setUpdating(false))
  }, [api, subsidiaryState, setSubsidiary])

  const archive = React.useCallback(() => {
    setArchiving(true)
    setArchiveError(undefined)
    api.del('/subsidiaries/details', undefined, { subsidiaryId: subsidiaryState.subsidiaryId })
      .then(response => {
        setSubsidiaryState(response)
        setSubsidiaryBase(response)
        setOnEdit(false)
        setArchiveOpen(false)
      })
      .catch(error => setArchiveError(error))
      .then(() => setArchiving(false))
  }, [api, subsidiaryState])

  const cancel = React.useCallback(() => {
    setSubsidiaryState(subsidiaryBase)
    setOnEdit(false)
  }, [subsidiaryBase])

  if (!subsidiaryState || loading) {
    return <Spinner className="d-flex ml-auto mr-auto" color="primary" />
  }

  if (error) {
    return <ErrAlert error={error} />
  }

  return (
    <Card className="mb-2">
      <CardHeader tag="h2">{subsidiaryBase.subsidiaryIdentifier} - {subsidiaryBase.subsidiaryName}</CardHeader>
      <CardBody tag={Form}>
        <FormGroup tag="fieldset">
          <TLabel for="subsidiaryIdentifier" id="subsidiaryIdentifier" />
          <Input id="subsidiaryIdentifier"
            type="text"
            name="subsidiaryIdentifier"
            disabled={!onEdit}
            value={subsidiaryState.subsidiaryIdentifier}
            onChange={handleChange} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel for="subsidiaryName" id="subsidiaryName" />
          <Input id="subsidiaryName"
            type="text"
            name="subsidiaryName"
            disabled={!onEdit}
            value={subsidiaryState.subsidiaryName}
            onChange={handleChange} />
        </FormGroup>
        {subsidiaryState.invoiceSystemId && subsidiaryState.invoiceSystemId.key && (
          <>
            <FormGroup tag="fieldset">
              <TLabel for="invoiceSystem" id="invoiceSystem" />
              <CustomSelect
                inputId="invoiceSystem"
                name="invoiceSystem"
                options={[{ value: 0, key: 'invoice.noSystem' }].concat(constants.invoiceSystem)}
                onChange={handleChangeInvoiceSystem}
                value={[{ value: 0, key: 'invoice.noSystem' }].concat(constants.invoiceSystem).filter(i => i.value === subsidiaryState.invoiceSystemId.value)}
                isDisabled={!onEdit}
                placeholder={<T id="invoiceSystem.placeholder" />}
                getOptionLabel={option => <T id={option.key} raw />}
                getOptionValue={option => option.value} />
            </FormGroup>
            {subsidiaryState.invoiceSystemId && subsidiaryState.invoiceSystemId.key && subsidiaryState.invoiceSystemId.value !== 0 && <FormGroup tag="fieldset">
              <Label for="profile.invoiceSystemIdentifier">
                <T id={`invoiceSystemIdentifier`} />{` ${constants.invoiceSystem.filter(i => i.value === subsidiaryState.invoiceSystemId.value).length > 0 && constants.invoiceSystem.filter(i => i.value === subsidiaryState.invoiceSystemId.value)[0].key}`}
              </Label>
              <Input id="profile.invoiceSystemIdentifier"
                type="text"
                name="invoiceSystemIdentifier"
                value={subsidiaryState.invoiceSystemIdentifier}
                disabled={!onEdit}
                onChange={handleChange} />
            </FormGroup>}
          </>
        )}
        <FormGroup tag="fieldset">
          <TLabel for="invoiceSystem" id="invoiceSystem" />
          <CustomSelect
            inputId="invoiceSystem"
            name="invoiceSystem"
            options={[{ value: 0, key: 'invoice.noSystem' }].concat(constants.invoiceSystem)}
            onChange={handleChangeInvoiceSystem}
            value={constants.invoiceSystem.filter(i => i.value === subsidiaryState.invoiceSystemId)}
            isDisabled={!onEdit}
            placeholder={<T id="invoiceSystem.placeholder" />}
            getOptionLabel={option => <T id={option.key} raw />}
            getOptionValue={option => option.value} />
        </FormGroup>
        {subsidiaryState.invoiceSystemId && subsidiaryState.invoiceSystemId !== 0 && <FormGroup tag="fieldset">
          <Label for="profile.invoiceSystemIdentifier">
            <T id={`invoiceSystemIdentifier`} />{constants.invoiceSystem.filter(i => i.value === subsidiaryState.invoiceSystemId) && constants.invoiceSystem.filter(i => i.value === subsidiaryState.invoiceSystemId)[0] && constants.invoiceSystem.filter(i => i.value === subsidiaryState.invoiceSystemId)[0].key}
          </Label>
          <Input id="profile.invoiceSystemIdentifier"
            type="text"
            name="invoiceSystemIdentifier"
            value={subsidiaryState.invoiceSystemIdentifier}
            disabled={!onEdit}
            onChange={handleChange} />
        </FormGroup>}
        <FormGroup tag="fieldset">
          <TLabel for="bcCode" id="bcCode" />
          <Input id="bcCode"
            type="text"
            name="bcCode"
            disabled={!onEdit}
            value={subsidiaryState.bcCode}
            onChange={handleChange} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel for="language" id="language" />
          <CustomSelect
            inputId="language"
            name="language"
            options={languages}
            onChange={handleChangeLanguage}
            value={languages.filter(lang => lang.langId === subsidiaryState.langId)}
            isDisabled={!onEdit}
            placeholder={<T id="language.placeholder" />}
            noOptionsMessage={() => <T id="language.noResult" />}
            getOptionLabel={option => <T id={option.langIdentifier} />}
            getOptionValue={option => option.langId} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel for="timezone" id="timezone" />
          <CustomSelect
            id="timezone"
            name="timezone"
            options={timezones}
            onChange={handleChangeTimezone}
            value={timezones.filter(timezone => timezone.timezoneId === subsidiaryState.timezoneId)}
            isDisabled={!onEdit}
            placeholder={<T id="timezone.placeholder" />}
            noOptionsMessage={() => <T id="timezone.noResult" />}
            getOptionLabel={option => option.timezoneIdentifier}
            getOptionValue={option => option.timezoneId} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel for="currency" id="currency" />
          <CustomSelect
            id="currency"
            name="currency"
            options={currencies}
            onChange={handleChangeCurrency}
            value={currencies.filter(currency => currency.currencyId === subsidiaryState.currencyId)}
            isDisabled={!onEdit}
            placeholder={<T id="currency.placeholder" />}
            noOptionsMessage={() => <T id="currency.noResult" />}
            getOptionLabel={option => option.currencyIdentifier}
            getOptionValue={option => option.currencyId} />
        </FormGroup>
        {subsidiaryState.bases && subsidiaryState.bases.length > 0 && <FormGroup tag="fieldset">
          <TLabel for="baseName" id="basesName" />
          <ListGroup>
            {subsidiaryState.bases.map(base =>
              <ListGroupItem key={base.baseId}>{base.baseName}</ListGroupItem>)}
          </ListGroup>
        </FormGroup>}
        <FormGroup tag="fieldset">
          <TLabel for="enableVeloptim" id="enableVeloptimLabel" />
          <TCustomInput
            id="enableVeloptim"
            type="checkbox"
            name="enableVeloptim"
            label="enableVeloptim"
            checked={subsidiaryState.enableVeloptim}
            disabled={updating || !onEdit}
            onChange={e => { handleChangeVeloptim(e.target.checked) }} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel for="enableVelocenter" id="enableVelocenterLabel" />
          <TCustomInput
            id="enableVelocenter"
            type="checkbox"
            name="enableVelocenter"
            label="enableVelocenter"
            checked={subsidiaryState.enableVelocenter}
            disabled={updating || !onEdit}
            onChange={e => { handleChangeVelocenter(e.target.checked) }} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel for="enableReasonParts" id="enableReasonPartsLabel" />
          <TCustomInput
            id="enableReasonParts"
            type="checkbox"
            name="enableReasonParts"
            label="enableReasonParts"
            checked={subsidiaryState.enableReasonParts}
            disabled={updating || !onEdit}
            onChange={e => { handleChangeReasonParts(e.target.checked) }} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel id="planningTime" />
          <div className="d-flex">
            <div>
              <TLabel for="timeStartWork" id="timeStartWork" />
              <Input id="timeStartWork"
                type="number"
                name="timeStartWork"
                disabled={!onEdit}
                value={subsidiaryState.timeStartWork}
                onChange={handleChange} />
            </div>
            <div className="ml-2">
              <TLabel for="timeEndWork" id="timeEndWork" />
              <Input id="timeEndWork"
                type="number"
                name="timeEndWork"
                disabled={!onEdit}
                value={subsidiaryState.timeEndWork}
                onChange={handleChange} />
            </div>
          </div>
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel for="maxDuration" id="maxDuration" />
          <Input id="maxDuration"
            type="number"
            name="maxDuration"
            disabled={!onEdit}
            value={subsidiaryState.maxDuration}
            onChange={handleChange} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel for="enableSameEquipmentIdentifier" id="enableSameEquipmentIdentifierLabel" />
          <TCustomInput
            id="enableSameEquipmentIdentifier"
            type="checkbox"
            name="enableSameEquipmentIdentifier"
            label="enableSameEquipmentIdentifier"
            checked={subsidiaryState.enableSameEquipmentIdentifier}
            disabled={updating || !onEdit}
            onChange={e => { handleChangeSameEquipment(e.target.checked) }} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel for="logo" id="logo" />
          <input ref={fileInput} type="file" name="logo" accept="application/png, application/jpeg" onChange={e => { handleLogo(e) }} hidden />
          <div className="d-flex flex-column align-items-start">
            {uploadLoading && <Spinner className="d-flex ml-auto mr-auto" color="primary" />}
            {subsidiaryState.logo && (
              <img className="mb-2" src={subsidiaryState.logo} style={{ maxWidth: 600, maxHeight: 80, objectFit: 'contain' }} />
            )}
            {subsidiaryState.logo ? (
              <div className='d-flex flex-row'>
                <TButton className="mr-auto" id="upload" onClick={() => fileInput.current.click()} disabled={uploadLoading} />
                <TButton className="mr-auto ml-2" id="delete" color="warning" onClick={() => deleteLogo()} disabled={uploadLoading} />
              </div>
            ) : (
              <TButton className="mr-auto" id="upload" onClick={() => fileInput.current.click()} disabled={uploadLoading} />
            )}
          </div>
        </FormGroup>
        {updateError && <ErrAlert error={updateError} />}
        {uploadError && <ErrAlert error={uploadError} />}
      </CardBody>
      {/* TODO: :( */}
      {/* <ProtectedComponent rights={['admin-subsidiaries_edit']}> */}
      <ProtectedComponent>
        <CardFooter className="d-flex justify-content-end">
          {!onEdit && <TButton onClick={() => setOnEdit(true)} id="edit" />}
          {onEdit && <ProtectedComponent rights={['admin_archive']}>
            <TButton disabled={updating} onClick={() => setArchiveOpen(true)} color="danger" className="mr-auto" id={subsidiaryState.archived ? 'restore' : 'archive'} />
          </ProtectedComponent>}
          {onEdit && <TButton disabled={updating || archiving} className="ml-2" onClick={save} color="primary" id="save" />}
          {onEdit && <TButton disabled={updating || archiving} loading={updating} className="ml-2" onClick={cancel} id="cancel" />}
        </CardFooter>
      </ProtectedComponent>

      <Modal isOpen={archiveOpen} fade={false} toggle={() => setArchiveOpen(false)}>
        <ModalBody>
          <T id={`${subsidiaryState.archived ? 'restore' : 'archive'}.content`} />
          {archiveError && <ErrAlert error={archiveError} className="mb-0 mt-2" />}
        </ModalBody>
        <ModalFooter className="py-3">
          <TButton disabled={archiving}
            onClick={() => setArchiveOpen(false)}
            id={`${subsidiaryState.archived ? 'restore' : 'archive'}.cancel`} />
          <TButton disabled={archiving} loading={archiving}
            className="ml-2" color="danger"
            onClick={archive}
            id={`${subsidiaryState.archived ? 'restore' : 'archive'}.confirm`} />
        </ModalFooter>
      </Modal>
    </Card>
  )
}

export default Subsidiary
