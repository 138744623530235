import React from 'react'

import {
  Card, CardBody, Spinner, FormGroup, Input, Alert
} from 'reactstrap'

import { AppContext, } from 'contexts/AppContext'

import { ErrAlert } from 'components/TComponents'

import { TButton, TLabel, T } from 'components/TComponents'

const userReducer = (state, action) => {
  switch (action.type) {
  case 'init': return {
    user: action.payload,
    baseUser: action.payload
  }
  case 'update': return {
    ...state,
    user: {
      ...state.user,
      ...action.payload
    }
  }
  case 'reset': return {
    ...state,
    user: state.baseUser
  }
  default:
    return state
  }
}

const Profile = ({ match }) => {
  const { api, user, updateUser } = React.useContext(AppContext)

  const [loading, setLoading] = React.useState(true)
  const [loadError, setLoadError] = React.useState()
  const [timeStartWork, setTimeStartWork] = React.useState()
  const [timeEndWork, setTimeEndWork] = React.useState()

  const [saveSuccess, setSaveSuccess] = React.useState()
  const [saveError, setSaveError] = React.useState()

  const [userState, userDispatch] = React.useReducer(userReducer, undefined)

  React.useEffect(() => {
    Promise.all([
      api.get('/users/details', undefined, { userId: user.userId }),
    ])
      .then(([user]) => {
        userDispatch({ type: 'init', payload: user })
        setTimeEndWork(user.timeEndWork)
        setTimeStartWork(user.timeStartWork)
      })
      .catch(response => setLoadError(response))
      .then(() => setLoading(false))
  }, [api, match, user])

  const save = React.useCallback(() => {
    api.post('/users/details', {
      body: JSON.stringify({
        ...user,
        timeStartWork,
        timeEndWork
      })
    })
      .then(response => {
        updateUser(response)
        setSaveSuccess(true)
        setSaveError()
      })
      .catch(error => {
        setSaveError(error)
        setSaveSuccess()
      })
  }, [api, timeEndWork, timeStartWork, user, updateUser])

  return (
    <>
      {loadError && <ErrAlert error={loadError} className="mt-2" />}
      {loading && <Spinner className="d-flex ml-auto mr-auto" color="primary" />}
      {userState && (
        <Card>
          <CardBody>
            <TLabel id="planningTime" />
            <FormGroup tag="fieldset" className="d-flex">
              <div>
                <TLabel for="timeStartWork" id="timeStartWork" />
                <Input id="timeStartWork"
                  type="number"
                  name="timeStartWork"
                  value={timeStartWork}
                  onChange={e => { setTimeStartWork(parseInt(e.target.value)) }} />
              </div>
              <div className="ml-2">
                <TLabel for="timeEndWork" id="timeEndWork" />
                <Input id="timeEndWork"
                  type="number"
                  name="timeEndWork"
                  value={timeEndWork}
                  onChange={e => { setTimeEndWork(parseInt(e.target.value)) }} />
              </div>
              <TButton onClick={save} id="save" className="ml-2" style={{ marginTop: 'auto' }} />
            </FormGroup>
            {saveSuccess && <T tagName={Alert} id="saveSuccess" color="success" />}
            {saveError && <T tagName={Alert} id="saveError" color="danger" />}
          </CardBody>
        </Card>
      )}
    </>
  )
}

export default Profile
