import React from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'

import {
  Alert,
  Card, CardHeader, CardBody, CardFooter,
  FormGroup, Input,
  ListGroup, ListGroupItem,
  Modal, ModalBody, ModalFooter,
  Spinner, NavLink,
  Row, Col
} from 'reactstrap'
import { Link, NavLink as RouterNavLink } from 'react-router-dom'
import moment from 'moment-timezone'

import PhoneInput from 'react-phone-number-input'
import ProtectedComponent from 'components/ProtectedComponent'
import { AppContext, } from 'contexts/AppContext'

import CustomSelect from '../../components/CustomSelect'
import { T, TAutocomplete, ErrAlert, TAlert, TNavLink, TLabel, TInput, TButton, TCustomInput, } from 'components/TComponents'

import ItvcodeSelector from 'components/ItvcodeSelector'
import VeloptimPlanModal from 'components/VeloptimPlanModal'

const swapReducer = (state, action) => {
  switch (action.type) {
  case 'update':
  case 'cancel':
  case 'reprog':
  case 'transform':
  case 'resume':
  case 'close':
    return {
      ...state,
      loading: true,
      status: action.type,
      error: undefined,
      prevent: action.clearPrevent ? undefined : state.prevent
    }
  case 'ready': return {
    status: action.type,
    loading: false,
    baseData: action.payload,
    currentData: { ...action.payload },
    error: undefined,
    prevent: undefined
  }
  case 'prevent': return {
    ...state,
    loading: false,
    status: 'ready',
    prevent: action.payload
  }
  case 'edit': return {
    ...state,
    loading: false,
    currentData: {
      ...state.currentData,
      ...action.payload
    }
  }
  case 'reset': return {
    status: 'ready',
    loading: false,
    baseData: state.baseData,
    currentData: { ...state.baseData },
    error: undefined,
    prevent: state.prevent
  }
  case 'error':
    return {
      ...state,
      loading: false,
      status: action.type,
      error: action.payload
    }
  default: return state
  }
}

const swapInitialState = {
  status: 'fetch',
  loading: true,
  currentData: undefined,
  baseData: undefined,
  error: undefined,
  prevent: undefined
}

const SwapEdit = ({ match, history, location }) => {
  const { api, constants, user, subsidiary, timezone } = React.useContext(AppContext)

  const [autocomplete, setAutocomplete] = React.useState()

  const [swapState, swapDispatch] = React.useReducer(swapReducer, swapInitialState)

  const [addresses, setAddresses] = React.useState()
  const [addressesLoading, setAddressesLoading] = React.useState(true)
  const [addressesError, setAddressesError] = React.useState()

  const [itvcodesBase, setItvcodesBase] = React.useState()
  const [itvcodesLoading, setItvcodesLoading] = React.useState(true)
  const [itvcodesError, setItvcodesError] = React.useState()

  const [confirmCancel, setConfirmCancel] = React.useState(false)
  const [cancelationReasonTemp, setCancelationReasonTemp] = React.useState('')
  const [pictureOpen, setPictureOpen] = React.useState(undefined)

  const [showHistory, setShowHistory] = React.useState(false)
  const [swapHistory, setSwapHistory] = React.useState()
  const [swapHistoryError, setSwapHistoryError] = React.useState()

  const [equipmentAlert, setEquipmentAlert] = React.useState()

  const [itvHourSelected, setSwapHourSelected] = React.useState(0)
  const [swapMinutesSelected, setSwapMinutesSelected] = React.useState(0)

  const swapHours = Array(9).fill().map((_, i) => i)
  const swapMinutes = Array(12).fill().map((_, i) => (i * 5))

  const [veloptimModal, setVeloptimModal] = React.useState(location && location.veloptimModal)
  const [addressChange, setAddressChange] = React.useState(false)
  const [planSuccess, setPlanSuccess] = React.useState()

  const [confirmPlanModal, setConfirmPlanModal] = React.useState(false)
  const [confirmPlan, setConfirmPlan] = React.useState(false)

  const [veloptimCancel, setVeloptimCancel] = React.useState(false)

  const {
    swapId,
    deskTicketId,
    equipmentBefore,
    equipmentAfter,
    claimNumber,

    statusValue,
    userCanceled, cancelationReason,
    userAnomaly, anomalyReasonValue, dateAnomaly, anomalyInformation,
    businessId, itvcodes,
    userAssignFirstname, userAssignLastname,

    datePlanned,
    durationEstimated,
    durationPlanned,
    durationChrono,
    durationDone,
    timeRangeStart,
    timeRangeEnd,
    information,

    startSlot,
    endSlot,
    reorderTour,

    selectedAddress,
    addressLabel,
    addressComplement,
    addressFull,
    locationId,

    anomalyComments,
    equipmentKm,
    techComments,

    firstName, lastName,
    email, phoneNumber,
    techcenterInfo,
    checkoutComments,
    checkoutSigned,
    subscriptionId,

    postComments,
    dateVeloptimScheduled
  } = swapState.currentData || {}

  const hasStatus = React.useCallback((...status) => status.includes(statusValue), [statusValue])

  const canEdit = hasStatus('waitPec', 'scheduled') && !loading
  const canEditAnomaly = hasStatus('anomaly') && !loading
  const canEditInformation = hasStatus('waitPec', 'scheduled')
  const isSwapEnded = hasStatus('done', 'canceled')

  React.useEffect(() => {
    getDetails()
  }, [getDetails])

  React.useEffect(() => {
    if (location && location.state && location.state.props && location.state.props.adressChange) {
      setAddressChange(true)
    }
    if (location && location.state && location.state.props && location.state.props.success) {
      setPlanSuccess(true)
    }
  }, [location])

  React.useEffect(() => {
    if (addressChange) {
      getDetails()
      setPlanSuccess(true)
      setAddressChange(false)
    }
  }, [addressChange, getDetails])

  React.useEffect(() => {
    // Get Equipment Alerts
    if (equipmentBefore) {
      api.get('/equipments/alerts', undefined, {
        equipmentId: equipmentBefore.equipmentId,
      })
        .then(response => setEquipmentAlert(response))
    }
  }, [equipmentBefore, api])

  React.useEffect(() => {
    if (businessId) {
      api.get('/swaps/addresses', undefined, { businessId })
        .then(addresses => setAddresses(addresses))
        .catch(error => setAddressesError(error))
        .then(() => setAddressesLoading(false))
    }
  }, [api, businessId])

  React.useEffect(() => {
    setSwapHourSelected(Math.trunc((durationPlanned || durationEstimated) / 60))
    setSwapMinutesSelected((durationPlanned || durationEstimated) % 60)
  }, [durationPlanned, durationEstimated])

  React.useEffect(() => {
    if (planSuccess) {
      getDetails()
    }
  }, [planSuccess, getDetails])

  React.useEffect(() => {
    if (confirmPlan) {
      handleSaveAndPlanVeloptim()
      setConfirmPlan(false)
    }
  }, [confirmPlan, handleSaveAndPlanVeloptim])

  const timeFromUTC = React.useCallback(initialDate => {
    const dateArray = initialDate.split(' ')
    const dateInJs = `${dateArray[0]}T${dateArray[1]}Z`
    return moment(dateInJs).tz(timezone).format('HH:mm')
  }, [timezone])

  const getDetails = React.useCallback(() => {
    api.get('/swaps/details', undefined, { swapId: match.params.id })
      .then(response => {
        swapDispatch({ type: 'ready', payload: response })
        getSwapHistory(match.params.id)
        return api.get('/itvcodes/list', undefined, {
          ipp: -1,
          businessId: response.businessId,
          familyId: response.equipmentBefore.familyId,
          categoryId: response.equipmentBefore.categoryId
        })
          .then(response => {
            setItvcodesBase(response)
          })
          .catch(response => setItvcodesError(response))
          .then(() => setItvcodesLoading(false))
      })
      .catch(response => swapDispatch({ type: 'error', payload: response }))
  }, [api, getSwapHistory, match.params.id])

  const getSwapHistory = React.useCallback(swapId => {
    api.get('/swaps/history', undefined, { swapId })
      .then(response => {
        setSwapHistory(response)
        setSwapHistoryError()
      })
      .catch(error => {
        setSwapHistoryError(error)
      })
  }, [api])


  const parseSwapHistory = (th, i) => {
    const sharedSwapHistory = th => {
      return (
        <React.Fragment key={`${th.user}-${i}`}>
          <FormattedDate value={new Date(th.dateCreated)} />{" "}<FormattedTime value={new Date(th.dateCreated)} />
          <>{" - "}</>
          <>{th.user === 'Système' || th.user === '' || th.user === null ? <T id="history.systeme" /> : th.user}</>
          <>{" - "}  </>
        </React.Fragment>
      )
    }

    switch (th.action) {
    case 'new': return (
      <p key={`${th.user}-${i}`}>
        {sharedSwapHistory(th)}
        <T id={`history.${th.action}`} />
      </p>
    )
    case 'schedule': case 'reschedule': case 'schedule_velopt_tech': return (
      <p key={`${th.user}-${i}`}>
        {sharedSwapHistory(th)}
        <T id={`history.${th.action}`} />{" "}
        <span className="mt-1 font-italic">
          {th.value && <span style={{ color: '#dd9c02' }}>{`${th.value} `}</span>}
          {th.value2 && <>{' '}<T id='history.dateSchedule' />{' '}<FormattedDate style={{ color: '#0080cc' }} value={th.value2} />{' '}<T id="history.dateScheduleAt" />{' '}<FormattedTime style={{ color: '#0080cc' }} value={th.value2} /></>}
        </span>
      </p>
    )
    case 'schedule_veloptim': return (
      <p key={`${th.user}-${i}`}>
        {sharedSwapHistory(th)}
        <T id={`history.${th.action}`} />{" "}
        <span className="mt-1 font-italic">
          {th.value && <span style={{ color: '#dd9c02' }}>{`${th.value} `}</span>}
          {th.value2 && <>{' '}<T id='history.dateSchedule' />{' '}<FormattedDate style={{ color: '#0080cc' }} value={th.value2.day} /></>}
          {th.value2 && <>{' '}<T id='history.slotSchedule' />{' '}<span>{timeFromUTC(th.value2.startSlot)} - {timeFromUTC(th.value2.endSlot)}</span></>}
        </span>
      </p>
    )
    case 'auto_assign': return (
      <p key={`${th.user}-${i}`}>
        <React.Fragment key={`${th.user}-${i}`}>
          <FormattedDate value={new Date(th.dateCreated)} />{" "}<FormattedTime value={new Date(th.dateCreated)} />
          <>{" - "}</>
          <>{th.user === 'Système' || th.user === '' || th.user === null ? <>{`${<T id="history.systeme" />} `}</> : <>{`${th.user} `}</>}</>
        </React.Fragment>
        <T id={`history.${th.action}`} />
      </p>
    )
    case 'start': case 'end': return (
      <p key={`${th.user}-${i}`}>
        {sharedSwapHistory(th)}
        <span style={{ color: '#3e884f' }}><T id={`history.${th.action}`} /></span>
      </p>
    )
    case 'anomaly': return (
      <p key={`${th.user}-${i}`}>
        {sharedSwapHistory(th)}
        <span style={{ color: '#cc0101' }}><T id={`history.${th.action}`} /></span>
      </p>
    )
    case 'close': case 'cancel': return (
      <p key={`${th.user}-${i}`}>
        {sharedSwapHistory(th)}
        <span style={{ color: '#dd9c02' }}><T id={`history.${th.action}`} /></span>
      </p>
    )
    case 'system_close': return (
      <p key={`${th.user}-${i}`}>
        {sharedSwapHistory(th)}
        <span style={{ color: '#dd9c02' }}><T id={`history.${th.action}`} /></span>
      </p>
    )
    default: return (
      <p key={`${th.user}-${i}`}>
        {sharedSwapHistory(th)}
        <T id={`history.${th.action}`} />
      </p>
    )
    }
  }

  const onItvcodesChange = React.useCallback((itvcodes) => {
    setVeloptimCancel(true)
    swapDispatch({ type: 'edit', payload: { itvcodes } })
  }, [])

  const addressHandleChange = React.useCallback((e) => {
    setVeloptimCancel(true)
    const addressId = e ? e.addressId : undefined

    const addressSelected = addressId
      ? addresses.find(address => String(address.addressId) === String(addressId))
      : undefined

    swapDispatch({
      type: 'edit', payload: {
        selectedAddress: addressId,
        addressLabel: addressSelected ? addressSelected.addressLabel : '',
        addressComplement: addressSelected ? addressSelected.addressComplement : '',
        addressFull: addressSelected ? addressSelected.addressFull : '',
        mapId: addressSelected ? addressSelected.mapId : ''
      }
    })
  }, [addresses])

  const handleChangeAddressFull = React.useCallback(e => {
    e.persist()
    swapDispatch({
      type: 'edit', payload: {
        addressFull: e.target.value,
        mapId: undefined
      }
    })
    api.get('/maps/autocomplete', undefined, {
      input: e.target.value,
      ...(autocomplete && autocomplete.session_token && { session_token: autocomplete.session_token })
    })
      .then(response => {
        setAutocomplete(response)
      })
      .catch(error => console.log(error))
  }, [api, autocomplete])

  const handleAutocomplete = React.useCallback(e => {
    swapDispatch({
      type: 'edit', payload: {
        addressFull: e,
      }
    })
    api.get('/maps/placedetails', undefined, {
      place_id: autocomplete.results.find(r => r.addressFull === e).place_id,
      session_token: autocomplete.session_token,
      addressFull: e
    })
      .then(response => {
        swapDispatch({
          type: 'edit', payload: {
            mapId: response.mapId,
          }
        })
      })
      .catch(error => console.log(error))
  }, [api, autocomplete])

  const handleSwapHoursChange = React.useCallback(({ target: { value } }) => {
    setVeloptimCancel(true)
    swapDispatch({
      type: 'edit', payload: {
        durationPlanned: swapMinutesSelected + Number(value) * 60
      }
    })
  }, [swapMinutesSelected])

  const handleSwapMinutesChange = React.useCallback(({ target: { value } }) => {
    setVeloptimCancel(true)
    swapDispatch({
      type: 'edit', payload: {
        durationPlanned: Number(value) + itvHourSelected * 60
      }
    })
  }, [itvHourSelected])

  const handleChange = React.useCallback(({ target: { name, value } }) => {
    swapDispatch({ type: 'edit', payload: { [name]: value } })
  }, [])

  const save = React.useCallback((override) => {
    if (hasStatus('waitPec')) {
      return saveWait(override, swapState)
    } else if (hasStatus('scheduled')){
      return saveSchedule(override, swapState)      
    } else if (hasStatus('done')){
      return saveEnd(override, swapState)
    } else if (hasStatus('anomaly')){
      return saveAnomaly(override, swapState)
    }     
  }, [hasStatus, saveWait, saveSchedule, saveEnd, saveAnomaly, swapState])

  const saveWait = React.useCallback((override, state) => {
    return api.post('/swaps/updateWait', {
      _stringifyBody: true,
      body: {
        swapId,
        itvcodes: state.currentData.itvcodes.map(itvcode => ({
          itvcodeId: itvcode.itvcodeId,
          quantity: itvcode.quantity,
          wear: Boolean(itvcode.wear), // Should send a Boolean to avoid not sending the property if false
          ...(itvcode.reasonId && itvcode.reasonListSwapDisplay && { reasonId: itvcode.reasonId })
        })),
        claimNumber: state.currentData.claimNumber,
        information: state.currentData.information,
        locationId: state.currentData.locationId,
        addressLabel:state.currentData.addressLabel,
        addressComplement:state.currentData.addressComplement,
        mapId:state.currentData.mapId,
        firstName:state.currentData.firstName,
        lastName:state.currentData.lastName,
        email:state.currentData.email,
        durationPlanned:state.currentData.durationPlanned,
        phoneNumber:state.currentData.phoneNumber,
        ...override
      }
    })
  }, [api, swapId])

  const saveSchedule = React.useCallback((override, state) => {
    return api.post('/swaps/updateSchedule', {
      _stringifyBody: true,
      body: {
        swapId,
        itvcodes: state.currentData.itvcodes.map(itvcode => ({
          itvcodeId: itvcode.itvcodeId,
          quantity: itvcode.quantity,
          wear: Boolean(itvcode.wear), // Should send a Boolean to avoid not sending the property if false
          ...(itvcode.reasonId && itvcode.reasonListSwapDisplay && { reasonId: itvcode.reasonId })
        })),
        claimNumber: state.currentData.claimNumber,
        information: state.currentData.information,
        locationId: state.currentData.locationId,
        addressLabel: state.currentData.addressLabel,
        addressComplement: state.currentData.addressComplement,
        mapId: state.currentData.mapId,
        firstName: state.currentData.firstName,
        lastName: state.currentData.lastName,
        email: state.currentData.email,
        durationPlanned: state.currentData.durationPlanned,
        veloptimCancel,
        phoneNumber: state.currentData.phoneNumber,
        ...override
      }
    })
  }, [api, swapId, veloptimCancel])

  const saveEnd = React.useCallback((override, state) => {
    return api.post('/swaps/updateEnd', {
      _stringifyBody: true,
      body: {
        swapId,
        postComments: state.currentData.postComments,
        ...override
      }
    })
  }, [api, swapId])

  const saveAnomaly = React.useCallback((override, state) => {
    return api.post('/swaps/updateAnomaly', {
      _stringifyBody: true,
      body: {
        swapId,
        itvcodes: state.currentData.itvcodes.map(itvcode => ({
          itvcodeId: itvcode.itvcodeId,
          quantity: itvcode.quantity,
          wear: Boolean(itvcode.wear), // Should send a Boolean to avoid not sending the property if false
          ...(itvcode.reasonId && itvcode.reasonListSwapDisplay && { reasonId: itvcode.reasonId })
        })),
        locationId: state.currentData.locationId,
        addressLabel: state.currentData.addressLabel,
        addressComplement: state.currentData.addressComplement,
        mapId: state.currentData.mapId,
        firstName: state.currentData.firstName,
        lastName: state.currentData.lastName,
        email: state.currentData.email,
        phoneNumber: state.currentData.phoneNumber,
        anomalyComments: state.currentData.anomalyComments,
        claimNumber: state.currentData.claimNumber,
        ...override
      }
    })
  }, [api, swapId])

  const handleSave = React.useCallback(override => {
    swapDispatch({ type: 'update' })
    Promise.all([
      save(override),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([response]) => {
        swapDispatch({ type: 'ready', payload: response })
        setVeloptimCancel(false)
      })
      .catch(response => response && response.code === 'preventUpdate'
        ? swapDispatch({ type: 'prevent', payload: { ...response.params, callback: handleSave } })
        : swapDispatch({ type: 'error', payload: response })
      )
  }, [save])

  const handleSaveAndPlan = React.useCallback(override => {
    swapDispatch({ type: 'update' })
    save(override)
      .then(() => history.push(`/swaps/plan/${match.params.id}`))
      .catch(response => {
        response && response.code === 'preventUpdate'
          ? swapDispatch({ type: 'prevent', payload: { ...response.params, callback: handleSaveAndPlan } })
          : swapDispatch({ type: 'error', payload: response })
      })
  }, [history, match, save])

  const handleSaveAndPlanVeloptim = React.useCallback(override => {
    if (hasStatus('scheduled') && !confirmPlan && dateVeloptimScheduled) {
      setConfirmPlanModal(true)
    } else {
      setConfirmPlanModal(false)
      swapDispatch({ type: 'update' })
      save(override)
        .then((response) => swapDispatch({ type: 'ready', payload: response }))
        .then(() => setVeloptimModal(true)).catch(response => {
          if (response && response.code === 'preventUpdate') {
            swapDispatch({ type: 'prevent', payload: { ...response.params, callback: handleSaveAndPlanVeloptim } })
          } else {
            swapDispatch({ type: 'error', payload: response })
          }
        })
    }
  }, [confirmPlan, dateVeloptimScheduled, hasStatus, save])

  const handleStart = React.useCallback(() => {
    history.push(`/swaps/start/${match.params.id}`)
  }, [history, match])

  const handleCancel = React.useCallback(() => {
    swapDispatch({ type: 'cancel' })

    api.del('/swaps/details', undefined, { swapId: match.params.id, cancelationReason: cancelationReasonTemp })
      .then(response => {
        swapDispatch({ type: 'ready', payload: response })
        setConfirmCancel(false)
      })
      .catch(response => swapDispatch({ type: 'error', payload: response }))
  }, [api, match, cancelationReasonTemp])

  const handleReschedule = React.useCallback((swapId) => {
    save()
      .then(() =>
        swapDispatch({ type: 'reschedule' }),
      Promise.all([
        api.post('/swaps/reschedule', { _stringifyBody: true, body: { swapId } }),
        new Promise(resolve => setTimeout(resolve, 250))
      ])
        .then(() => history.push(`/swaps/plan/${swapId}`))
        .catch(response => swapDispatch({ type: 'error', payload: response }))
      )
      .catch(response =>
        response && response.code === 'preventUpdate'
          ? swapDispatch({ type: 'prevent', payload: { ...response.params, callback: handleReschedule } })
          : swapDispatch({ type: 'error', payload: response })
      )
  }, [api, history, save])

  const handleTransform = React.useCallback((swapId) => {
    save()
      .then(() =>
        swapDispatch({ type: 'transform' }),
      Promise.all([
        api.post('/swaps/transform', { _stringifyBody: true, body: { swapId } }),
        new Promise(resolve => setTimeout(resolve, 250))
      ])
        .then(([response]) => history.push(`/interventions/${response.interventionId}`))
        .catch(response => swapDispatch({ type: 'error', payload: response }))
      ).catch(response =>
        response && response.code === 'preventUpdate'
          ? swapDispatch({ type: 'prevent', payload: { ...response.params, callback: handleTransform } })
          : swapDispatch({ type: 'error', payload: response })
      )
  }, [api, history, save])

  const handleTerminate = React.useCallback((swapId) => {
    save()
      .then(() =>
        swapDispatch({ type: 'close' }),
      Promise.all([
        api.post('/swaps/close', { _stringifyBody: true, body: { swapId } }),
        new Promise(resolve => setTimeout(resolve, 250))
      ])
        .then(([response]) => swapDispatch({ type: 'ready', payload: response }))
        .catch(response => swapDispatch({ type: 'error', payload: response }))
      )
      .catch(response =>
        response && response.code === 'preventUpdate'
          ? swapDispatch({ type: 'prevent', payload: { ...response.params, callback: handleTerminate } })
          : swapDispatch({ type: 'error', payload: response })
      )
  }, [api, save])

  const handleResume = React.useCallback((swapId) => {
    save()
      .then(() => {
        swapDispatch({ type: 'resume' }),
        Promise.all([
          api.post('/swaps/resume', { _stringifyBody: true, body: { swapId } }),
          new Promise(resolve => setTimeout(resolve, 250))
        ])
          .then(([response]) => {
            swapDispatch({ type: 'ready', payload: response })
          })
          .catch(response => swapDispatch({ type: 'error', payload: response }))
      })
      .catch(response =>
        response && response.code === 'preventUpdate'
          ? swapDispatch({ type: 'prevent', payload: { ...response.params, callback: handleResume } })
          : swapDispatch({ type: 'error', payload: response })
      )
  }, [api, save])

  const loading = swapState.status !== 'ready' || itvcodesLoading

  if (swapState.status === 'fetch' && swapState.error) {
    return <>
      <NavLink tag={Link} to={{
        pathname: '/swaps',
        search: location.state ? location.state.swaps : undefined
      }}>
        <T id="returnToList" />
      </NavLink>
      <TAlert id="itvNotFound" className="mb-0" color="danger" />
    </>
  }

  if (swapState.status !== 'fetch' && (!equipmentBefore)) {
    return <>
      <TNavLink tag={Link} to={{
        pathname: '/swaps',
        search: location.state ? location.state.swaps : undefined
      }} id="returnToList" />

      {swapState.error && <ErrAlert error={swapState.error} values={{ value: swapState.error.itvcodes && swapState.error.itvcodes.map((code, i) => i === 0 ? code : `, ${code}`) }} />}
      <TAlert id="invalidSwap" className="mb-0" color="danger" />
    </>
  }

  return (
    <div className="container-fluid InterventionEdit">
      <NavLink tag={Link} to={{
        pathname: '/swaps',
        search: location.state ? location.state.swaps : undefined
      }}>
        <T id="returnToList" />
      </NavLink>
      <Card>
        {/* TODO: loading */}
        {swapState.status !== 'fetch' && <CardHeader>
          <div className="ml-2">
            <div className="h4">
              <T id="equipmentBeforeId" />
              <NavLink
                tag={Link}
                to={`/equipments/${equipmentBefore.equipmentId}`}
                className="p-0 d-inline">
                {equipmentBefore.equipmentIdentifier}
              </NavLink>
              <small className="ml-2">{equipmentBefore.categoryName}</small>
              <small className="ml-2">{equipmentBefore.familyName}</small>
            </div>
            {equipmentAfter && <div className="h4">
              <T id="equipmentAfterId" />
              <NavLink
                tag={Link}
                to={`/equipments/${equipmentAfter.equipmentId}`}
                className="p-0 d-inline">
                {equipmentAfter.equipmentIdentifier}
              </NavLink>
            </div>}
            <div className="h6 mb-0">
              <span><T id="swapId" />{swapId}</span>
              <small className="ml-2">
                {deskTicketId ?
                  <Link to={`/desk/${deskTicketId}`}><T id="deskTicketId" />{deskTicketId}</Link> :
                  <><T id="deskTicketId" /><T id="noTicketId" /></>}
              </small>
            </div>
          </div>
        </CardHeader>}
        {swapState.status === 'fetch' && <CardHeader className="d-flex align-items-center">
          <T id="loading" />
          <Spinner className="ml-2" color="primary" size="sm" />
        </CardHeader>}

        <CardBody>
          {equipmentAlert && (
            <div className="mb-3 pt-2">
              {!!equipmentAlert.nbInfo && <TAlert color="info" id="nbInfo" values={{ value: equipmentAlert.nbInfo }} />}
              {!!equipmentAlert.nbMinor && <TAlert color="warning" id="nbMinor" values={{ value: equipmentAlert.nbMinor }} />}
              {!!equipmentAlert.nbMajor && <TAlert color="danger" id="nbMajor" values={{ value: equipmentAlert.nbMajor }} />}
              {(!!equipmentAlert.nbInfo || !!equipmentAlert.nbMinor || !!equipmentAlert.nbMajor) && equipmentBefore && (
                <TButton id="goToEquipmentLog"
                  tag={Link}
                  to={{
                    pathname: `/equipments/${equipmentBefore.equipmentId}/logs`,
                    state: location.state
                  }}
                  color="primary" />
              )}
            </div>
          )}
          <FormGroup tag="fieldset">
            <TLabel className="mb-0" id="statusValue" />
            {swapState.status === 'fetch'
              ? <Spinner className="ml-2" color="primary" size="sm" />
              : 
              <>
                <T raw id={`swapStatus.${statusValue}`} className="pl-1" />
                {hasStatus('scheduled') && dateVeloptimScheduled && ' 🤖'}
              </>
            }
          </FormGroup>

          {userAssignFirstname && userAssignLastname && (
            <FormGroup tag="fieldset">
              <TLabel className="mb-0" id="userAssign" />
              {swapState.status === 'fetch'
                ? <Spinner className="ml-2" color="primary" size="sm" />
                : <span>{`${userAssignFirstname} ${userAssignLastname}`}</span>}
            </FormGroup>
          )}

          <FormGroup tag="fieldset">
            <>
              <TLabel className="mb-0" id="datePlanned.label" />
              {swapState.status === 'fetch'
                ? <Spinner className="ml-2" color="primary" size="sm" />
                : <>
                  <T id="datePlanned.value"
                    values={{
                      datePlanned: datePlanned ? new Date(datePlanned) : null,
                      timeStart: timeRangeStart && !startSlot ? new Date().setHours(0, Math.min(timeRangeStart), 0, 0) : undefined,
                      timeEnd: timeRangeStart && !startSlot ? new Date().setHours(0, Math.max(timeRangeEnd), 0, 0) : undefined
                    }}
                    className="pl-1" />
                  {datePlanned && !hasStatus('canceled') && <TButton id="toPlanning" color="primary"
                    tag={RouterNavLink}
                    to={`/planning?t=0&c=0&d=${datePlanned.split('T')[0]}`}
                    className="mr-auto ml-2" />}
                </>
              }
            </>
          </FormGroup>

          <FormGroup className='fieldset'>
            {!!reorderTour && startSlot && <TAlert id="reorderTour" className="mt-2" color="warning" />}
            {!!startSlot && !!endSlot && (
              <>
                <TLabel id="slot" />
                {timeFromUTC(startSlot)} - {timeFromUTC(endSlot)}
              </>
            )}
          </FormGroup>

          {userCanceled && <FormGroup tag="fieldset">
            <T id="userCanceled" tagName="p" className="mb-1" values={{ user: userCanceled }} />
            <Alert color="danger">{cancelationReason || <T id="noCancelReason" />}</Alert>
          </FormGroup>}

          {dateAnomaly && <FormGroup tag="fieldset">
            <T id="userAnomaly" tagName="p" className="mb-1" values={{ user: userAnomaly, date: new Date(dateAnomaly), reason: anomalyReasonValue }} />
            <Alert color="danger">{anomalyInformation || <T id="noAnomalyReason" />}</Alert>
          </FormGroup>}

          <TLabel id="itvcodeList" />

          {itvcodesError ? <ErrAlert error={itvcodesError} /> : itvcodesBase && itvcodes && <ItvcodeSelector
            hasBusiness={Boolean(businessId)}
            loading={swapState.status === 'fetch' || itvcodesLoading}
            editable={canEdit || canEditAnomaly}
            itvcodesAdded={itvcodes}
            itvcodes={itvcodesBase.result}
            isSwap
            reason
            onChange={itvcodes => onItvcodesChange(itvcodes)} />}

          <TLabel id="durations" />
          <Card className="mb-4">
            <CardBody className="pb-3">
              <div className={`${!hasStatus('waitPec', 'scheduled') && 'row'} mb-2`}>
                {/* Display /60 hour */}
                <TLabel
                  id="durationEstimated"
                  values={{
                    hours: Math.floor(durationEstimated / 60),
                    minutes: durationEstimated % 60,
                  }}
                  className="col mb-2 pl-0" />
                {!hasStatus('waitPec', 'scheduled') ? (
                  <>
                    {durationPlanned != null
                      ? <TLabel
                        id="durationPlanned"
                        values={{
                          hours: Math.floor(durationPlanned / 60),
                          minutes: durationPlanned % 60,
                        }}
                        className="col mb-0" />
                      : <TLabel
                        id="durationPlannedNull"
                        className="col mb-0" />}
                  </>
                ) : (
                  <>
                    <TLabel id="durationPlannedToChange" className="col mb-1 pl-0" />
                    <Row>
                      <Col>
                        <FormGroup tag="fieldset">
                          <TLabel for="selectHour" id="selectHour" />
                          <CustomSelect
                            inputId="selectHour"
                            name="selectHour"
                            options={swapHours}
                            placeholder={"0"}
                            onChange={e => handleSwapHoursChange({ target: { value: e } })}
                            value={swapHours.filter(h => h == itvHourSelected)}
                            getOptionValue={option => option}
                            getOptionLabel={option => option}
                            isDisabled={swapState.loading || !(canEdit || canEditAnomaly)} />
                        </FormGroup>
                      </Col>

                      <Col>
                        <FormGroup tag="fieldset">
                          <TLabel for="selectMinute" id="selectMinute" />
                          <CustomSelect
                            inputId="selectMinute"
                            name="selectMinute"
                            options={swapMinutes}
                            onChange={e => handleSwapMinutesChange({ target: { value: e } })}
                            placeholder={"0"}
                            value={swapMinutes.filter(h => h == swapMinutesSelected)}
                            getOptionValue={option => option}
                            getOptionLabel={option => option}
                            isDisabled={swapState.loading || !(canEdit || canEditAnomaly)} />
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
              {(durationChrono != null || durationDone != null) && <div className="row mb-2">
                {durationChrono != null && <TLabel
                  id="durationChrono"
                  values={{
                    hours: Math.floor(durationChrono / 60),
                    minutes: durationChrono % 60,
                  }}
                  className="col mb-0" />}
                {durationDone != null && <TLabel
                  id="durationDone"
                  values={{
                    hours: Math.floor(durationDone / 60),
                    minutes: durationDone % 60,
                  }}
                  className="col mb-0" />}
              </div>}
            </CardBody>
          </Card>

          <FormGroup tag="fieldset">
            <TLabel id="claimNumber" />
            <Input type="text" name="claimNumber" id="claimNumber"
              disabled={loading || !canEdit || isSwapEnded}
              value={claimNumber || ''}
              onChange={handleChange} />
          </FormGroup>

          <FormGroup tag="fieldset">
            <TLabel id="information" />
            <Input type="textarea" name="information" id="information"
              disabled={swapState.loading || loading || !canEditInformation}
              value={information || ''}
              onChange={handleChange} />
          </FormGroup>

          <TLabel className="mb-2" id="addressTitle" />
          <Card className="mb-4">
            <CardBody>
              {addressesError ? <ErrAlert error={addressesError} /> : (canEdit || canEditAnomaly) &&
                <FormGroup tag="fieldset">
                  <TLabel className="mt-0" id="addresses.label" for="addresses" />
                  <CustomSelect
                    inputId="addresses"
                    name="addresses"
                    options={addresses}
                    onChange={addressHandleChange}
                    placeholder={<T id="addresses.manual" />}
                    value={addresses && addresses.filter(a => a.addressId === selectedAddress) || ''}
                    isClearable
                    isDisabled={swapState.loading || addressesLoading || loading}
                    getOptionLabel={option => `${option.addressLabel} – ${option.addressFull} ${option.addressComplement && '- ' + option.addressComplement}`}
                    getOptionValue={option => option.addressId} />
                </FormGroup>}
              <FormGroup tag="fieldset" className="flex-grow-1">
                <TLabel for="addressLabel" className="justify-content-start mb-2" id="addressLabel" />
                <Input id="addressLabel"
                  className="w-100"
                  type="text"
                  name="addressLabel"
                  disabled={swapState.loading || !(canEdit || canEditAnomaly) || selectedAddress}
                  value={addressLabel || ''}
                  onChange={e => handleChange(e)} />
              </FormGroup>
              <FormGroup tag="fieldset" className="w-100">
                <TLabel for="addressFull" className="mb-2" id="addressFull" />
                <TAutocomplete id="addressFull"
                  className="w-100"
                  type="text"
                  name="addressFull"
                  disabled={!canEdit || selectedAddress}
                  value={addressFull || ''}
                  placeholder="addressFullPlaceholder"
                  searchOnFocus={e => handleChangeAddressFull(e)}
                  onChange={e => handleChangeAddressFull(e)}
                  onAutocomplete={e => handleAutocomplete(e)}
                  options={
                    autocomplete && autocomplete.results.length > 0 && autocomplete.results.map(r => {
                      return (r.addressFull)
                    })} />
              </FormGroup>
              <FormGroup tag="fieldset" className="w-100 mb-0">
                <TLabel for="addressComplement" className="mb-2" id="addressComplement" />
                <TInput id="addressComplement"
                  className="w-100"
                  type="text"
                  name="addressComplement"
                  disabled={swapState.loading || !(canEdit || canEditAnomaly) || selectedAddress}
                  value={addressComplement || ''}
                  placeholder="addressComplementPlaceholder"
                  onChange={e => handleChange(e)} />
              </FormGroup>
            </CardBody>
          </Card>

          <FormGroup tag="fieldset">
            <TLabel id="locationId" for="locationId" />
            <div>
              {constants.swapLocation.map(type => <FormGroup key={type.key}
                check inline>
                <TCustomInput
                  id={`locationId-${type.key}`}
                  type="radio"
                  name="locationId"
                  label={`locationId.${type.key}`}
                  disabled={swapState.loading || !(canEdit || canEditAnomaly)}
                  checked={Number(locationId) === type.value}
                  value={type.value}
                  onChange={() => handleChange({ target: { name: 'locationId', value: type.value } })} />
              </FormGroup>)}
            </div>
          </FormGroup>

          <TLabel className="mb-1" id="contactTitle" />
          <Card>
            <CardBody>
              <div className="d-flex">
                <FormGroup tag="fieldset" className="w-100 flex-grow-1">
                  <TLabel for="firstName" className="my-0" id="firstName" />
                  <Input name="firstName"
                    type="text"
                    value={firstName || ''}
                    disabled={swapState.loading || !(canEdit || canEditAnomaly)}
                    onChange={e => handleChange(e)} />
                </FormGroup>
                <FormGroup tag="fieldset" className="ml-3 w-100 flex-grow-1">
                  <TLabel for="lastName" className="my-0" id="lastName" />
                  <Input name="lastName"
                    type="text"
                    value={lastName || ''}
                    disabled={swapState.loading || !(canEdit || canEditAnomaly)}
                    onChange={e => handleChange(e)} />
                </FormGroup>
              </div>
              <div className="d-flex">
                <FormGroup tag="fieldset" className="w-100 flex-grow-1">
                  <TLabel for="email" className="my-0" id="email" />
                  <Input name="email"
                    type="text"
                    value={email || ''}
                    disabled={swapState.loading || !(canEdit || canEditAnomaly)}
                    onChange={e => handleChange(e)} />
                </FormGroup>
                <FormGroup tag="fieldset" className="ml-3 mb-0 w-100 flex-grow-1">
                  <TLabel id="phoneNumber" for="phoneNumber" className="my-0" />
                  <div className="d-flex align-items-center">
                    <Input name="phoneNumber"
                      id="phoneNumber"
                      tag={PhoneInput}
                      defaultCountry="FR"
                      country="FR"
                      className={`w-100 d-flex${!(canEdit || canEditAnomaly) ? ' disabled' : ''}`}
                      type="text"
                      value={phoneNumber || ''}
                      disabled={swapState.loading || !(canEdit || canEditAnomaly)}
                      onChange={value => handleChange({ target: { value, name: 'phoneNumber' } })} />
                    {/* TODO: feature not requested yet */}
                    {false && <NavLink className="simple-icon-phone h4 py-0 px-2 mb-0 text-success" disabled={!phoneNumber} href={`tel:${phoneNumber}`} />}
                  </div>
                </FormGroup>
              </div>
              {subscriptionId && (
                <div>
                  <TLabel id="subscriptionId" />
                  <p className="mb-1">{subscriptionId}</p>
                </div>
              )}
            </CardBody>
          </Card>

          {(hasStatus('anomaly') || isSwapEnded) && (
            <>
              <FormGroup className="my-4">
                <TLabel id="techComments" />
                <Input type="textarea" name="techComments" id="techComments"
                  value={techComments || ''}
                  readOnly={true}
                  onChange={e => handleChange(e)} />
              </FormGroup>
              <FormGroup className="my-4">
                <TLabel id="anomalyComments" />
                <Input type="textarea" name="anomalyComments" id="anomalyComments"
                  value={anomalyComments || ''}
                  readOnly={!canEdit && !hasStatus('anomaly')}
                  onChange={e => handleChange(e)} />
              </FormGroup>
            </>
          )}

          {hasStatus('anomaly', 'canceled', 'closed', 'done') && equipmentBefore && equipmentBefore.pics && equipmentBefore.pics.anlyUrl &&
            <FormGroup>
              <TLabel id="pictures.equipmentBefore.anlyUrl" className="d-block" />
              <input type="image"
                src={equipmentBefore.pics.anlyUrl}
                style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }}
                onClick={() => setPictureOpen({ picUrl: equipmentBefore.pics.anlyUrl })} />
            </FormGroup>}

          {isSwapEnded && <>
            {hasStatus('canceled', 'done') && equipmentKm !== null && <FormGroup className="mt-4">
              <TLabel className="mb-0" id="equipmentKm.label" />
              {swapState.status === 'fetch'
                ? <Spinner className="ml-2" color="primary" size="sm" />
                : <T id="equipmentKm.value" values={{ equipmentKm }} className="pl-1" />}
            </FormGroup>}

            <FormGroup className="mb-0">
              <TLabel id="postComments" />
              <Input type="textarea" name="postComments" id="postComments"
                disabled={swapState.loading || loading || ['canceled', 'anomaly'].includes(statusValue)}
                value={postComments || ''}
                onChange={handleChange} />
            </FormGroup>
          </>}

          {((equipmentAfter && equipmentAfter.picUrl) || (equipmentBefore && equipmentBefore.picUrl)) && <>
            <TLabel className="mb-1 mt-4" id="pictures.title" />
            <Card>
              {equipmentBefore && equipmentBefore.picUrl && <>
                <CardHeader><T id="pictures.equipmentBefore.title" values={equipmentBefore} /></CardHeader>
                <CardBody className="d-flex align-items-start">
                  <input type="image"
                    src={equipmentBefore.picUrl}
                    style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }}
                    onClick={() => setPictureOpen(equipmentBefore)} />
                </CardBody>
              </>}

              {equipmentAfter && equipmentAfter.picUrl && <>
                <CardHeader><T id="pictures.equipmentAfter.title" values={equipmentAfter} /></CardHeader>
                <CardBody className="d-flex align-items-start">
                  <input type="image"
                    src={equipmentAfter.picUrl}
                    style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }}
                    onClick={() => setPictureOpen(equipmentAfter)} />
                </CardBody>
              </>}
            </Card>
          </>}
          {techcenterInfo && (
            <div>
              <TLabel className="mt-4" id="techcenter" />
              <p className="mb-1">{techcenterInfo}</p>
            </div>
          )}
        </CardBody>
        {(hasStatus('done') && checkoutComments || (equipmentBefore && equipmentBefore.pics && equipmentBefore.pics.pic1Url) || checkoutSigned === 0) &&
          <CardBody style={{ borderTop: '1px solid rgba(0,0,0,.125)' }}>
            <TLabel className="mt-4" id="checkout" />
            {(checkoutSigned === 0 || !checkoutSigned) && (
              <div className="mb-4">
                <T id="noSignature" />
              </div>
            )}
            <>
              {checkoutComments && (
                <div className="mb-4">
                  <TLabel className="mt-4" id="checkoutComments" />
                  <p className="mb-1" style={{ whiteSpace: 'pre-line' }}>{checkoutComments}</p>
                </div>
              )}
              {equipmentBefore && equipmentBefore.pics &&
                <Card>
                  {equipmentBefore.pics.pic1Url &&
                    <>
                      <CardHeader><T id="pictures.equipmentBefore.pic1" /></CardHeader>
                      <CardBody className="d-flex align-items-start">
                        <input type="image"
                          src={equipmentBefore.pics.pic1Url}
                          style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }}
                          onClick={() => setPictureOpen({ picUrl: equipmentBefore.pics.pic1Url })} />
                      </CardBody>
                    </>
                  }
                  {equipmentBefore.pics.pic2Url &&
                    <>
                      <CardHeader><T id="pictures.equipmentBefore.pic2" /></CardHeader>
                      <CardBody className="d-flex align-items-start">
                        <input type="image"
                          src={equipmentBefore.pics.pic2Url}
                          style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }}
                          onClick={() => setPictureOpen({ picUrl: equipmentBefore.pics.pic2Url })} />
                      </CardBody>
                    </>
                  }
                  {equipmentBefore.pics.pic3Url &&
                    <>
                      <CardHeader><T id="pictures.equipmentBefore.pic3" /></CardHeader>
                      <CardBody className="d-flex align-items-start">
                        <input type="image"
                          src={equipmentBefore.pics.pic3Url}
                          style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }}
                          onClick={() => setPictureOpen({ picUrl: equipmentBefore.pics.pic3Url })} />
                      </CardBody>
                    </>
                  }
                  {!!(equipmentBefore.pics.signUrl && checkoutSigned) &&
                    <>
                      <CardHeader><T id="pictures.equipmentBefore.signUrl" /></CardHeader>
                      <CardBody className="d-flex align-items-start">
                        <input type="image"
                          src={equipmentBefore.pics.signUrl}
                          style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }}
                          onClick={() => setPictureOpen({ picUrl: equipmentBefore.pics.signUrl })} />
                      </CardBody>
                    </>
                  }
                </Card>
              }
            </>
          </CardBody>
        }

        <CardBody className="Section">
          <TButton className="mb-2" id={showHistory ? "hideHistory" : "showHistory"} onClick={() => setShowHistory(!showHistory)} />
          {showHistory && (
            <div>
              {swapHistory && swapHistory.map(th => parseSwapHistory(th))}
              {swapHistoryError && <ErrAlert className="mb-0 mt-4" error={swapHistoryError.code} />}
            </div>
          )}
        </CardBody>

        {((equipmentAfter && equipmentAfter.picUrl) || (equipmentBefore && equipmentBefore.picUrl) || (equipmentBefore && equipmentBefore.pics)) && <>
          {pictureOpen && <Modal isOpen={true} fade={true} toggle={() => setPictureOpen(undefined)} size="lg">
            <ModalBody>
              <div className="position-relative">
                <i
                  onClick={() => setPictureOpen(undefined)}
                  className="iconsmind-Close-Window p-2 h3"
                  style={{ position: 'absolute', top: 0, right: 0 }} />
                <img src={pictureOpen.picUrl} style={{ maxWidth: '100%' }} />
              </div>

              <a href={pictureOpen.picUrl} target="_blank" rel="noreferrer" className="mt-2 d-block"><T id="pictures.seefull" /></a>
            </ModalBody>
          </Modal>}
        </>
        }
        <CardFooter>
          {swapState.status !== 'cancel' && swapState.error && <ErrAlert error={swapState.error} values={{ value: swapState.error.itvcodes && swapState.error.itvcodes.map((code, i) => i === 0 ? code : `, ${code}`) }} />}
          {planSuccess && <TAlert id="planSuccess" color="success" />}
          <div className="d-flex">
            <div>
              {!hasStatus('canceled', 'anomaly', 'done') && <TButton disabled={swapState.loading}
                onClick={() => setConfirmCancel(true)} color="danger"
                className="mx-1"
                id="cancel" />}
              <ProtectedComponent rights={['swaps_transform-interventions']}>
                {hasStatus('anomaly', 'scheduled') && <TButton disabled={swapState.loading}
                  spin={(swapState.loading || swapState.status === 'transform')}
                  className="mx-1"
                  onClick={() => handleTransform(swapId)}
                  id="transform" />}
              </ProtectedComponent>
            </div>
            <div className="ml-auto">
              {!hasStatus('canceled', 'done') && <TButton disabled={swapState.loading}
                spin={(swapState.loading || swapState.status === 'update')}
                className="mx-1 my-1"
                onClick={() => handleSave()}
                id="save" />}
              {hasStatus('anomaly') && <TButton disabled={swapState.loading}
                spin={(swapState.loading || swapState.status === 'reschedule')}
                className="mx-1 my-1"
                onClick={() => handleReschedule(swapId)}
                id="reprog" />}
              {hasStatus('anomaly') && <TButton disabled={swapState.loading}
                spin={(swapState.loading || swapState.status === 'close')}
                className="mx-1 my-1"
                onClick={() => handleTerminate(swapId)}
                id="terminate" />}
              <ProtectedComponent rights={['swaps-resume']}>
                {hasStatus('anomaly') && <TButton disabled={swapState.loading}
                  spin={(swapState.loading || swapState.status === 'close')}
                  className="mx-1 my-1"
                  onClick={() => handleResume(swapId)}
                  id="resume" />}
              </ProtectedComponent>
              {!hasStatus('canceled', 'done', 'anomaly') && (
                <>
                  {((user.enableVeloptim && subsidiary.enableVeloptim) || user.email == 'email-admin@test.tld') ? (
                    <>
                      <TButton disabled={swapState.loading || !hasStatus('waitPec', 'scheduled')}
                        id="saveAndPlanVeloptim"
                        className="mx-1"
                        spin={(swapState.loading || swapState.status === 'update')}
                        onClick={() => handleSaveAndPlanVeloptim()} />
                      <TButton disabled={swapState.loading || !hasStatus('waitPec', 'scheduled')}
                        id="saveAndPlanWithoutVeloptim"
                        className="mx-1 my-1"
                        spin={(swapState.loading || swapState.status === 'update')}
                        onClick={() => handleSaveAndPlan()} />
                    </>
                  ) : (
                    <TButton disabled={swapState.loading || !hasStatus('waitPec', 'scheduled')}
                      spin={(swapState.loading || swapState.status === 'update')}
                      className="mx-1 my-1"
                      onClick={() => handleSaveAndPlan()}
                      id="saveAndPlan" />
                  )}
                </>
              )}
              {hasStatus('done') && <TButton disabled={swapState.loading}
                spin={(swapState.loading || swapState.status === 'update')}
                className="mx-1 my-1"
                onClick={() => handleSave()}
                id="save" />}
              {(hasStatus('scheduled', 'waitPec')) &&
                <ProtectedComponent rights={['swaps-end_web']}>
                  <TButton
                    disabled={swapState.loading}
                    className="mx-1"
                    onClick={handleStart}
                    id="start" />
                </ProtectedComponent>
              }
            </div>
          </div>
        </CardFooter>
      </Card>

      {swapState.prevent && <Modal isOpen={true} fade={true} toggle={() => swapDispatch({ type: 'prevent', payload: undefined })}>
        <ModalBody>
          <T id="modal.preventUpdate.content" values={swapState.prevent} />

          <ListGroup>
            {swapState.prevent.reasons && swapState.prevent.reasons.map(reason => (
              <ListGroupItem key={reason}><T id={`modal.preventUpdate.reason.${reason}`} values={swapState.prevent} /></ListGroupItem>
            ))}
          </ListGroup>
        </ModalBody>
        <ModalFooter>
          <TButton disabled={swapState.loading || swapState.status === 'cancel'}
            onClick={() => swapDispatch({ type: 'prevent', })}
            id="modal.preventUpdate.cancel" />
          <TButton disabled={swapState.loading || swapState.status === 'cancel'} spin={swapState.loading || swapState.status === 'cancel'}
            className="ml-2" color="danger" onClick={() => swapState.prevent.callback({
              force: swapState.prevent.reasons
            })}
            id="modal.preventUpdate.confirm" />
        </ModalFooter>
      </Modal>
      }

      <Modal isOpen={confirmCancel} fade={true} toggle={() => setConfirmCancel(false)}>
        <ModalBody>
          <div className="mb-1"><T id="modal.cancelContent" /></div>
          <TLabel for="cancelationReason" id="modal.cancelationReason" />
          <TInput id="cancelationReason"
            type="textarea" name="cancelationReasonTemp"
            value={cancelationReasonTemp}
            onChange={e => setCancelationReasonTemp(e.target.value)}
            placeholder="modal.cancelationReasonPlaceholder" />
          {swapState.status === 'cancel' && swapState.error && <ErrAlert className="mb-0 mt-2" error={swapState.error} values={{ value: swapState.error.itvcodes && swapState.error.itvcodes.map((code, i) => i === 0 ? code : `, ${code}`) }} />}
        </ModalBody>
        <ModalFooter>
          <TButton disabled={swapState.loading && swapState.status === 'cancel'}
            onClick={() => setConfirmCancel(false)}
            id="modal.cancelCancel" />
          <TButton disabled={swapState.loading && swapState.status === 'cancel'} spin={swapState.loading && swapState.status === 'cancel'}
            className="ml-2" color="danger" onClick={handleCancel}
            id="modal.cancelConfirm" />
        </ModalFooter>
      </Modal>

      <Modal isOpen={confirmPlanModal} fade={true} toggle={() => setConfirmPlanModal(false)}>
        <ModalBody>
          <div className="mb-1"><T id="modal.confirmPlanVeloptim" /></div>
        </ModalBody>
        <ModalFooter>
          <TButton
            disabled={swapState.loading || !hasStatus('waitPec', 'scheduled')}
            spin={swapState.loading || swapState.status === 'update'}
            onClick={() => setConfirmPlan(true)}
            id="modal.confirm" />
          <TButton
            spin={swapState.loading || swapState.status === 'update'}
            disabled={swapState.loading || !hasStatus('waitPec', 'scheduled')}
            className="ml-2" color="danger" onClick={() => setConfirmPlanModal(false)}
            id="modal.cancel" />
        </ModalFooter>
      </Modal>


      {((user.enableVeloptim && subsidiary.enableVeloptim) || user.email == 'email-admin@test.tld') && <VeloptimPlanModal
        typeIntervention="swap"
        id={match.params.id}
        itvcodes={itvcodes && itvcodes.map(i => i.itvcodeId) || []}
        familyId={equipmentBefore && equipmentBefore.familyId}
        mapId={swapState && swapState.currentData && swapState.currentData.mapId}
        durationPlanned={durationPlanned || durationEstimated}
        onDismiss={() => { setVeloptimModal(false) }}
        onSuccess={() => {
          setVeloptimModal(false)
          setPlanSuccess(true)
        }}
        onAdressChange={() => { setAddressChange(true) }}
        isOpen={veloptimModal} />}
    </div >
  )
}

export default SwapEdit
