import React from 'react'
import { useLocation, useHistory, NavLink } from 'react-router-dom'
import CustomSelect from 'components/CustomSelect'
import { objectToQuery } from 'react-rest-api'
import { useHasRights } from 'components/ProtectedComponent'
import moment from 'moment-timezone'

import 'react-datepicker/dist/react-datepicker.css'

import {
  Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter,
  InputGroup, InputGroupAddon, FormGroup,
  ListGroup, ListGroupItem
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'
import { useFormatMessage } from 'hooks/intl.hooks'

import { NotificationManager } from 'components/ReactNotifications'
import SlotSelector from 'components/SlotSelector'
import PlanningItem from 'components/PlanningItem'
import PlanningItemModal from 'components/PlanningItemModal'
import Timeline from 'components/react-calendar-timeline'
import VehicleIcon from '../components/VehicleIcon'
import 'components/react-calendar-timeline/lib/Timeline.scss'

import { T, TProps, TButton, TCustomInput, TInput, ErrAlert, TDatePicker } from 'components/TComponents'

import './Planning.scss'

const SidebarItem = ({ group }) => {
  const location = useLocation()

  return (
    <NavLink className="stretched-link"
      to={{
        pathname: `/planning/${group.id}`,
        search: objectToQuery({ d: location.state ? location.state.d : undefined }),
        state: {
          d: location.state ? location.state.d : undefined,
          planning: location.state
        }
      }}>
      <div className="h-100 d-flex flex-row align-items-center">
        {group.title}
        {group.vehicle && group.vehicleIdentifier && (
          <>{' - '}<VehicleIcon type={group.vehicle} /> {' - '} {group.vehicleIdentifier}</>
        )}
      </div >
    </NavLink>
  )
}

const Planning = () => {
  const history = useHistory()
  const location = useLocation()
  const { api, constants, translations, timeStartWork, timeEndWork } = React.useContext(AppContext)
  const hasInterventionsRemind = useHasRights('interventions_remind')
  const formatMessage = useFormatMessage()

  const [planningsUsers, setPlanningsUsers] = React.useState()
  const [planningsUsersLoad, setPlanningsUsersLoad] = React.useState(true)
  const [planningsUsersError, setPlanningsUsersError] = React.useState()
  const [plannings, setPlannings] = React.useState([])
  const [planningUserToAdd, setPlanningUserToAdd] = React.useState([])

  const [searchUser, setSearchUser] = React.useState((location.state && location.state.s) || '')
  const [interventionInput, setInterventionInput] = React.useState((location.state && location.state.i) || '')
  const [dateSelected, setDateSelected] = React.useState(location.state && location.state.d ? new Date(location.state.d) : new Date())
  const [disableFilters, setDisableFilters] = React.useState(!!(location.state && location.state.i))

  const [dateStart, setDateStart] = React.useState()
  const [dateEnd, setDateEnd] = React.useState()
  const [planningGroups, setPlanningGroups] = React.useState([])
  const [planningItems, setPlanningItems] = React.useState([])

  const [loadPlanningEnable, setLoadPlanningEnable] = React.useState(true)

  const [scheduleError, setScheduleError] = React.useState()

  const [isHelpOpen, setIsHelpOpen] = React.useState(false)

  const [itemSelected, setItemSelected] = React.useState()

  const [params, setParams] = React.useState({})

  const planningDuration = (location.state && location.state.p) || ''

  const getPlanningsUsers = React.useCallback(() => {
    const _params = {
      ...params,
      ...(params.dayStart && { dayStart: params.dayStart.split('T')[0] }),
    }
    return api.get('/plannings/view', undefined, { ..._params })
      .then(response => {
        setPlannings([])
        setPlanningsUsers(response)
      })
  }, [api, params])

  React.useEffect(() => {
    if (Object.keys(params).length !== 0) {
      setPlanningsUsersError()
      setPlanningsUsersLoad(true)
      getPlanningsUsers()
        .catch(response => setPlanningsUsersError(response))
        .then(() => setPlanningsUsersLoad(false))
    }
  }, [getPlanningsUsers, params])

  const fetchPlanningUser = React.useCallback(i => {
    if (i < planningsUsers.users.length) {
      const _params = {
        ...params,
        dayStart: planningsUsers.filters.dayStart,
        userId: planningsUsers.users[i].userId
      }
      api.get('/plannings/userPlanning', undefined, { ..._params })
        .then(response => {
          setPlannings(oldPlanning => [...oldPlanning.filter((pUser => pUser.userId !== planningsUsers.users[i].userId)), {
            userId: planningsUsers.users[i].userId,
            userName: planningsUsers.users[i].firstName + ' ' + planningsUsers.users[i].lastName,
            distance: planningsUsers.users[i].distance || null,
            interventions: response.interventions,
            appointments: response.appointments,
            swaps: response.swaps,
            unavailabilities: response.unavailabilities,
            maintenanceplans: response.maintenanceplans,
          }])
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, planningsUsers])

  const fetchPlanningUserArray = React.useCallback(userIndexes => {
    setPlanningUserToAdd([])
    userIndexes.map(i => {
      const _params = {
        ...params,
        dayStart: planningsUsers.filters.dayStart,
        userId: planningsUsers.users[i].userId
      }
      api.get('/plannings/userPlanning', undefined, { ..._params })
        .then(response => {
          const item = {
            userId: planningsUsers.users[i].userId,
            userName: planningsUsers.users[i].firstName + planningsUsers.users[i].lastName,
            distance: planningsUsers.users[i].distance || null,
            interventions: response.interventions,
            appointments: response.appointments,
            maintenanceplans: response.maintenanceplans,
            swaps: response.swaps,
            unavailabilities: response.unavailabilities
          }
          setPlanningUserToAdd(oldPlanningUserToAdd => [...oldPlanningUserToAdd, item])
        })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, planningsUsers])

  React.useEffect(() => {
    if (planningUserToAdd.length === 2) {
      setPlannings(oldPlanning => [...oldPlanning.filter((pUser => !planningUserToAdd.map(pU => pU.userId).includes(pUser.userId))), ...planningUserToAdd])
    }
  }, [planningUserToAdd])

  React.useEffect(() => {
    if (planningsUsers && planningsUsers.users && planningsUsers.users.length > 0) {
      for (let i = 0; i < planningsUsers.users.length; i++) {
        fetchPlanningUser(i)
      }
    }
  }, [planningsUsers, fetchPlanningUser])



  React.useEffect(() => {
    if (location.state) {
      history.replace({ search: objectToQuery({ ...location.state }), state: location.state })
    }
  }, [history, location.state])

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (loadPlanningEnable) {
      setParams(params => ({
        ...params,
        companyId: searchParams.get('c') || params.companyId,
        dayStart: searchParams.get('d') ? searchParams.get('d') : params.dayStart,
        userTypeId: searchParams.get('t') || params.userTypeId,
        planningDuration: searchParams.get('p') || params.planningDuration,
        interventionId: searchParams.get('i'),
      }))
    }
    if (searchParams.get('i')) {
      setInterventionInput(searchParams.get('i'))
      setDisableFilters(searchParams.get('i') !== '')
    }
    setSearchUser(searchParams.get('user'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  React.useEffect(() => {
    if (planningsUsers) {
      setDateStart(new Date(planningsUsers.filters.dayStart).setHours(timeStartWork, 0))
      setDateEnd(new Date(planningsUsers.filters.dayStart).setHours(timeEndWork, 0))
    }
  }, [planningsUsers, timeStartWork, timeEndWork])


  const itvStatus = React.useMemo(() => constants.interventionStatus.reduce((acc, status) => ({ ...acc, [status.key]: status.value }), {}), [constants])
  const swapStatus = React.useMemo(() => constants.swapStatus.reduce((acc, status) => ({ ...acc, [status.key]: status.value }), {}), [constants])
  const appointmentStatus = React.useMemo(() => constants.appointmentStatus.reduce((acc, status) => ({ ...acc, [status.key]: status.value }), {}), [constants])
  const maintenanceStatus = React.useMemo(() => constants.maintenanceplansStatus.reduce((acc, status) => ({ ...acc, [status.key]: status.value }), {}), [constants])

  React.useEffect(() => {
    if (planningsUsers) {
      const users = !searchUser || searchUser.trim().length === 0 ? planningsUsers.users
        : planningsUsers.users.filter(user => (user.firstName + ' ' + user.lastName).toLowerCase().indexOf(searchUser.toLowerCase()) !== -1)

      setPlanningGroups(users.map(user => ({ id: user.userId, title: user.firstName + ' ' + user.lastName, vehicle: user.vehicleType, vehicleIdentifier: user.vehicleIdentifier })))
    }
  }, [planningsUsers, searchUser, itvStatus, swapStatus, appointmentStatus, planningDuration])

  const getTimeStart = React.useCallback((item, planningDuration) => {
    switch (planningDuration) {
    // durationDone
    case '4': return (
      new Date(item.dateDone).getTime() - item.durationDone * 1000 * 60
    )
      // durationEstimated
    case '1': return (
      new Date(item.datePlanned).getTime()
    )
      // durationChrono
    case '3': return (
      new Date(item.dateDone).getTime() - item.durationChrono * 1000 * 60
    )
      // durationPlanned
    case '2': return (
      item.datePlanned ? new Date(item.datePlanned).getTime() : new Date(item.dateDone).getTime() - item.durationPlanned * 1000 * 60
    )
    }
  }, [])

  const getTimeEnd = React.useCallback((item, planningDuration) => {
    switch (planningDuration) {
    // durationDone
    case '4': return (
      new Date(item.dateDone).getTime()
    )
      // durationEstimated
    case '1': return (
      new Date(item.datePlanned).getTime() + item.durationEstimated * 1000 * 60
    )
      // durationChrono
    case '3': return (
      new Date(item.dateDone).getTime()
    )
      // durationPlanned
    case '2': return (
      item.datePlanned ? new Date(item.datePlanned).getTime() + item.durationPlanned * 1000 * 60 : new Date(item.dateDone).getTime()
    )
    }
  }, [])

  React.useEffect(() => {
    if (plannings && plannings.length > 0) {
      const getInterventionStyle = intervention => {
        const style = {}
        if ([itvStatus.done, itvStatus.canceled, itvStatus.closed].includes(intervention.statusId)) {
          style['backgroundColor'] = 'rgba(0, 0, 0, .4)'
        } else if (intervention.statusId === itvStatus.scheduled && (new Date(intervention.datePlanned).getTime() + intervention.durationPlanned * 1000 * 60) < Date.now()) {
          style['backgroundColor'] = 'rgba(255, 0, 0, .4)'
        }
        return style
      }

      const getSwapStyle = swap => {
        const style = {}

        if ([swapStatus.done, swapStatus.canceled].includes(swap.statusId)) {
          style.background = 'repeating-linear-gradient(125deg, rgba(246, 186, 82, .5), rgba(246, 186, 82, .5) 50%, rgba(0, 0, 0, .4) 50%, rgba(0, 0, 0, .4) 100%)'
        } else if (swap.statusId === swapStatus.scheduled && (new Date(swap.datePlanned).getTime() + swap.durationPlanned * 1000 * 60) < Date.now()) {
          style.background = 'repeating-linear-gradient(125deg, rgba(255, 0, 0, .5), rgba(255, 0, 0, .5) 50%, rgba(255, 209, 128, .4) 50%, rgba(255, 209, 128, .4) 100%)'
        } else {
          style.background = 'repeating-linear-gradient(125deg, rgba(246, 186, 82, 1), rgba(246, 186, 82, 1) 50%, rgba(255, 209, 128, 1) 50%, rgba(255, 209, 128, 1) 100%)'
        }
        return style
      }

      const users = !searchUser || searchUser.trim().length === 0 ? plannings
        : plannings.filter(user => user.userName.toLowerCase().indexOf(searchUser.toLowerCase()) !== -1)

      const isSameDate = (startDate, endDate) => {
        const startMoment = moment(startDate).clone().startOf('day')
        const endMoment = moment(endDate).clone().startOf('day')
        return startMoment.diff(endMoment, 'days') == 0;
      }

      const filteredStartedItvs = items => {
        return items
          .filter(intervention => planningDuration !== '3' || (planningDuration === '3' && getTimeEnd(intervention, planningDuration) !== 0))
          .filter(intervention => planningDuration !== '4' || (planningDuration === '4' && getTimeEnd(intervention, planningDuration) !== 0))
          .filter(intervention => planningDuration !== '3' || (planningDuration === '3' && isSameDate(new Date(intervention.dateDone), new Date(planningsUsers.filters.dayStart))))
          .filter(intervention => planningDuration !== '4' || (planningDuration === '4' && isSameDate(new Date(intervention.dateDone), new Date(planningsUsers.filters.dayStart))))
      }

      const planningDuration = planningsUsers && planningsUsers.filters.planningDuration.selected.value.toString()

      setPlanningItems(users.reduce((acc, user) =>
        acc
          .concat(filteredStartedItvs(user.interventions).map(intervention => ({
            id: `timelineItem-itv-${intervention.interventionId}`,
            type: 'intervention',
            group: user.userId,
            canMove: new Date(intervention.datePlanned) > new Date().setHours(0, 0, 0, 0) && ![itvStatus.closed, itvStatus.done, itvStatus.canceled].includes(intervention.statusId),
            className: intervention.difficultyValue,
            timeStart: getTimeStart(intervention, planningDuration),
            timeEnd: getTimeEnd(intervention, planningDuration),
            style: getInterventionStyle(intervention),
            onItemClick: itvStatus.canceled == intervention.statusId ? undefined : intervention.isReadable ? setItemSelected : undefined,
            intervention
          })))
          .concat(filteredStartedItvs(user.swaps).map(swap => ({
            id: `timelineItem-swap-${swap.swapId}`,
            type: 'swap',
            group: user.userId,
            canMove: new Date(swap.datePlanned) > new Date().setHours(0, 0, 0, 0) && ![swapStatus.done, swapStatus.canceled].includes(swap.statusId),
            timeStart: getTimeStart(swap, planningDuration),
            timeEnd: getTimeEnd(swap, planningDuration),
            style: getSwapStyle(swap),
            onItemClick: swapStatus.canceled == swap.statusId ? undefined : swap.isReadable ? setItemSelected : undefined,
            swap
          })))
          .concat(user.appointments.map(appointment => ({
            id: `timelineItem-app-${appointment.appointmentId}`,
            type: 'appointment',
            group: user.userId,
            canMove: new Date(appointment.dateAppointment) > new Date().setHours(0, 0, 0, 0) && appointment.statusId !== appointmentStatus.canceled,
            timeStart: new Date(appointment.dateAppointment).getTime(),
            timeEnd: new Date(appointment.dateAppointment).getTime() + appointment.durationPlanned * 1000 * 60,
            style: { 'backgroundColor': 'rgba(125, 0, 125, .4)' },
            onItemClick: appointment.isReadable ? setItemSelected : undefined,
            appointment
          })))
          .concat(user.unavailabilities.map(unavailability => ({
            id: `timelineItem-unv-${unavailability.unavailabilityId}`,
            type: 'unavailability',
            group: user.userId,
            canMove: false,
            stackable: false,
            timeStart: new Date(unavailability.dateStart).getTime(),
            timeEnd: new Date(unavailability.dateEnd).getTime(),
            style: {
              background: 'repeating-linear-gradient(135deg,#eeeeee,#eeeeee 10px,#cacaca 10px,#cacaca 20px)',
              zIndex: 0
            },
            unavailability
          })))
          .concat(user.maintenanceplans.map(maintenance => ({
            id: `timelineItem-mai-${maintenance.maintenanceplanId}`,
            type: 'maintenance',
            group: user.userId,
            canMove: new Date(maintenance.datePlanned) > new Date().setHours(0, 0, 0, 0) && ![maintenanceStatus.closed, maintenanceStatus.done, maintenanceStatus.canceled].includes(maintenance.statusId),
            title: '',
            className: maintenance.difficultyValue,
            timeStart: getTimeStart(maintenance, planningDuration),
            timeEnd: getTimeEnd(maintenance, planningDuration),
            style: { 'backgroundColor': 'rgba(226, 134, 59, .4)' },
            onItemClick: maintenanceStatus.canceled == maintenance.statusId ? undefined : maintenance.isReadable ? setItemSelected : undefined,
            maintenance
          })))
      , []))
    }
  }, [plannings, searchUser, itvStatus, swapStatus, appointmentStatus, maintenanceStatus, getTimeEnd, getTimeStart, planningsUsers])


  const scheduleIntervention = React.useCallback((itemState, originUser) => {
    if (!itemState) {
      NotificationManager.error('unknowtechnical')
      return Promise.reject()
    }

    setScheduleError()
    return api.post('/interventions/schedule', {
      body: JSON.stringify({
        interventionId: itemState.id,
        datePlanned: itemState.date,
        userId: itemState.userId,
        duration: itemState.duration,
        force: itemState.force
      })
    })
      .then(_intervention => {
        if (originUser) {
          if (_intervention.userIdAssignedTo === originUser) {
            fetchPlanningUser(planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === originUser)))
          } else {
            fetchPlanningUserArray([
              planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === originUser)),
              planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === _intervention.userIdAssignedTo))
            ])
          }
        } else {
          fetchPlanningUser(planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === _intervention.userIdAssignedTo)))
        }
      })
      .catch(error => setScheduleError({ ...error, item: { ...itemState }, originUser }))
  }, [api, fetchPlanningUserArray, fetchPlanningUser, planningsUsers])

  const scheduleSwap = React.useCallback((itemState, originUser) => {
    if (!itemState) {
      NotificationManager.error('unknowtechnical')
      return Promise.reject()
    }

    setScheduleError()
    return api.post('/swaps/schedule', {
      body: JSON.stringify({
        swapId: itemState.id,
        datePlanned: itemState.date,
        userId: itemState.userId,
        duration: itemState.duration,
        force: itemState.force
      })
    })
      .then(_swap => {
        if (originUser) {
          if (_swap.userIdAssignedTo === originUser) {
            fetchPlanningUser(planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === originUser)))
          } else {
            fetchPlanningUserArray([
              planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === originUser)),
              planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === _swap.userIdAssignedTo))
            ])
          }
        } else {
          fetchPlanningUser(planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === _swap.userIdAssignedTo)))
        }
      })
      .catch(error => setScheduleError({ ...error, item: { ...itemState }, originUser }))
  }, [api, fetchPlanningUserArray, fetchPlanningUser, planningsUsers])

  const scheduleAppointment = React.useCallback((itemState, originUser) => {
    if (!itemState) {
      NotificationManager.error('unknowtechnical')
      return Promise.reject()
    }

    setScheduleError()
    return api.post('/appointments/schedule', {
      body: JSON.stringify({
        appointmentId: itemState.id,
        dateAppointment: itemState.date,
        userId: itemState.userId,
        duration: itemState.duration
      })
    })
      .then(_appointment => {
        if (originUser) {
          if (_appointment.userIdAssignedTo === originUser) {
            fetchPlanningUser(planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === originUser)))
          } else {
            fetchPlanningUserArray([
              planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === originUser)),
              planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === _appointment.userIdAssignedTo))
            ])
          }
        } else {
          fetchPlanningUser(planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === _appointment.userIdAssignedTo)))
        }
      })
      .catch(error => setScheduleError({ ...error, item: itemState, originUser }))
  }, [api, fetchPlanningUserArray, fetchPlanningUser, planningsUsers])

  const scheduleMaintenance = React.useCallback((itemState, originUser) => {
    if (!itemState) {
      NotificationManager.error('unknowtechnical')
      return Promise.reject()
    }

    setScheduleError()
    return api.post('/maintenanceplans/schedule', {
      body: JSON.stringify({
        maintenanceplanId: itemState.id,
        datePlanned: itemState.date,
        userId: itemState.userId,
        duration: itemState.duration,
        force: itemState.force
      })
    })
      .then(_maintenance => {
        if (originUser) {
          if (_maintenance.userIdAssignedTo === originUser) {
            fetchPlanningUser(planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === originUser)))
          } else {
            fetchPlanningUserArray([
              planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === originUser)),
              planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === _maintenance.userIdAssignedTo))
            ])
          }
        } else {
          fetchPlanningUser(planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === _maintenance.userIdAssignedTo)))
        }
      })
      .catch(error => setScheduleError({ ...error, item: itemState , originUser }))
  }, [api, fetchPlanningUserArray, fetchPlanningUser, planningsUsers])

  const forceSchedule = React.useCallback(() => {
    if (scheduleError.item.type === 'intervention') {
      scheduleIntervention({
        ...scheduleError.item,
        force: scheduleError.params.reasons
      }, scheduleError.originUser)
    }
    if (scheduleError.item.type === 'swap') {
      scheduleSwap({
        ...scheduleError.item,
        force: scheduleError.params.reasons
      }, scheduleError.originUser)
    }
    if (scheduleError.item.type === 'maintenance') {
      scheduleMaintenance({
        ...scheduleError.item,
        force: scheduleError.params.reasons
      }, scheduleError.originUser)
    }
  }, [scheduleError, scheduleIntervention, scheduleMaintenance, scheduleSwap])

  const handleHelpClick = React.useCallback(() => setIsHelpOpen(true), [])

  const handleHelpToggle = React.useCallback(() => setIsHelpOpen(h => !h), [])

  const handleItemSelectedSave = React.useCallback(itemState => {
    const newDate = new Date(itemState.date)
    const newDuration = String(itemState.duration)
    switch (itemSelected.type) {
    case 'intervention': {
      const oldDate = new Date(itemSelected.intervention.datePlanned)
      const oldDuration = String(itemSelected.intervention.durationPlanned)
      return scheduleIntervention(itemState, parseInt(itemSelected.intervention.userId))
        .then(() => {
          if (hasInterventionsRemind && (oldDate.getTime() !== newDate.getTime() || oldDuration !== newDuration)) {
            setItemSelected({
              type: 'intervention',
              intervention: {
                interventionId: itemState.id,
                userId: itemState.userId,
                datePlanned: itemState.date,
                durationPlanned: itemState.duration
              },
              remind: true
            })
          } else {
            setItemSelected()
          }
        })
    }
    case 'swap': {
      const oldDate = new Date(itemSelected.swap.datePlanned)
      const oldDuration = String(itemSelected.swap.durationPlanned)
      return scheduleSwap(itemState, parseInt(itemSelected.swap.userId))
        .then(() => {
          if (hasInterventionsRemind && (oldDate.getTime() !== newDate.getTime() || oldDuration !== newDuration)) {
            setItemSelected({
              type: 'swap',
              swap: {
                swapId: itemState.id,
                userId: itemState.userId,
                datePlanned: itemState.date,
                durationPlanned: itemState.duration
              },
              remind: true
            })
          } else {
            setItemSelected()
          }
        })
    }
    case 'appointment': return scheduleAppointment(itemState, parseInt(itemSelected.appointment.userId)).then(() => setItemSelected())
    case 'maintenance': return scheduleMaintenance(itemState, parseInt(itemSelected.maintenance.userId)).then(() => setItemSelected())
    default: return Promise.resolve().then(() => setItemSelected())
    }
  }, [itemSelected, scheduleIntervention, scheduleSwap, scheduleAppointment, scheduleMaintenance, hasInterventionsRemind])

  const handleItemRemind = React.useCallback(() => {
    switch (itemSelected.type) {
    case 'intervention': {
      return Promise.all([
        api.post('/interventions/reminder', { body: JSON.stringify({ interventionId: itemSelected.intervention.interventionId }) }),
        new Promise(resolve => setTimeout(resolve, 250))
      ])
        .then(() => {
          setItemSelected()
          NotificationManager.success(formatMessage({ id: 'remind.success' }))
        })
    }
    case 'swap': {
      return Promise.all([
        api.post('/swaps/reminder', { body: JSON.stringify({ swapId: itemSelected.swap.swapId }) }),
        new Promise(resolve => setTimeout(resolve, 250))
      ])
        .then(() => {
          setItemSelected()
          NotificationManager.success(formatMessage({ id: 'remind.success' }))
        })
    }
    default: return Promise.resolve()
    }
  }, [api, formatMessage, itemSelected])

  const handleDateChange = React.useCallback(d => {
    setDateSelected(d)
    history.push({
      search: location.search,
      state: { ...location.state, d }
    })
    setLoadPlanningEnable(true)
  }, [history, location.search, location.state])

  const handlePlanningItemDrop = React.useCallback((group, time, item) => {
    switch (item.type) {
    case 'intervention': {
      const constructItem = {
        id: item.intervention.interventionId,
        date: new Date(time),
        userId: group,
        duration: item.intervention.durationPlanned,
        type: item.type
      }
      // TODO: canMove false behaviour should be implemented in scheduleMethod (for appointments to)
      // setPlanningItems(p => p.map(_item => _item.id === item.id ? { ..._item, canMove: false } : _item))
      scheduleIntervention(constructItem, parseInt(item.intervention.userId))
      break;
    }
    case 'swap': {
      const constructItem = {
        id: item.swap.swapId,
        date: new Date(time),
        userId: group,
        duration: item.swap.durationPlanned,
        type: item.type
      }
      scheduleSwap(constructItem, parseInt(item.swap.userId))
      break;
    }
    case 'appointment': {
      const constructItem = {
        id: item.appointment.appointmentId,
        date: new Date(time),
        userId: group,
        duration: item.appointment.durationPlanned
      }
      scheduleAppointment(constructItem, parseInt(item.appointment.userId))
      break;
    }
    case 'maintenance': {
      const constructItem = {
        id: item.maintenance.maintenanceplanId,
        date: new Date(time),
        userId: group,
        duration: item.maintenance.durationPlanned,
        type: item.type
      }
      scheduleMaintenance(constructItem, parseInt(item.maintenance.userId))
      break;
    }
    }
  }, [scheduleAppointment, scheduleSwap, scheduleIntervention, scheduleMaintenance])

  const handleSearchUser = React.useCallback(value => {
    setLoadPlanningEnable(false)
    setSearchUser(value)
    const searchParams = new URLSearchParams(location.search)
    const locationState = {
      c: searchParams.get('c'),
      d: searchParams.get('d'),
      t: searchParams.get('t'),
      user: searchParams.get('user')
    }
    history.replace({
      search: location.search,
      state: { ...locationState, user: value }
    })
  }, [history, location.search])

  const handleUserTypeId = React.useCallback(value => {
    const searchParams = new URLSearchParams(location.search)
    const locationState = {
      c: searchParams.get('c'),
      d: searchParams.get('d'),
      t: searchParams.get('t'),
      user: searchParams.get('user')
    }
    history.replace({
      search: location.search,
      state: { ...locationState, t: value }
    })
    setLoadPlanningEnable(true)
  }, [history, location.search])

  const handleCompanyId = React.useCallback(value => {
    const searchParams = new URLSearchParams(location.search)
    const locationState = {
      c: searchParams.get('c'),
      d: searchParams.get('d'),
      t: searchParams.get('t'),
      user: searchParams.get('user')
    }
    history.replace({
      search: location.search,
      state: { ...locationState, c: value }
    })
    setLoadPlanningEnable(true)
  }, [history, location.search])

  const handlePlanningDuration = React.useCallback(e => {
    const searchParams = new URLSearchParams(location.search)
    const locationState = {
      c: searchParams.get('c'),
      d: searchParams.get('d'),
      t: searchParams.get('t'),
      p: searchParams.get('p'),
      user: searchParams.get('user')
    }
    history.replace({
      search: location.search,
      state: { ...locationState, p: e.target.value }
    })
    setLoadPlanningEnable(true)
  }, [history, location.search])

  const handleInterventionSearch = React.useCallback(e => {
    e.preventDefault()
    setDisableFilters(interventionInput !== '')
    const searchParams = new URLSearchParams(location.search)
    const locationState = {
      c: searchParams.get('c'),
      d: searchParams.get('d'),
      t: searchParams.get('t'),
      user: searchParams.get('user')
    }
    history.replace({
      search: location.search,
      state: { ...locationState, i: interventionInput }
    })
    setLoadPlanningEnable(true)
  }, [history, interventionInput, location.search])

  const resetInterventionSearch = React.useCallback(e => {
    e.preventDefault()
    setInterventionInput('')
    setDisableFilters(false)
    const searchParams = new URLSearchParams(location.search)
    const locationState = {
      c: searchParams.get('c'),
      d: searchParams.get('d'),
      t: searchParams.get('t'),
      user: searchParams.get('user')
    }
    history.replace({
      search: location.search,
      state: { ...locationState }
    })
    setLoadPlanningEnable(true)
  }, [history, location])

  const handlePlanningsErrorClick = React.useCallback(() => {
    if (planningsUsersError.code === 'unscheduledIntervention') {
      history.push(`interventions/plan/${planningsUsersError.params.interventionId}`)
    }
  }, [history, planningsUsersError])

  return (
    <div className="container-fluid Planning">
      <div className="d-flex align-items-center mb-3">
        <div className="position-relative">
          <TDatePicker
            disabled={planningsUsersLoad || disableFilters}
            customInput={<SlotSelector />}
            selected={disableFilters ? dateStart || dateSelected : params.dayStart ? params.dayStart : dateSelected}
            onChange={e => handleDateChange(e)}
            dayHeaderFormat="wide"
            dateFormat="eeee dd/MM/yyyy"
          />
        </div>

        {planningsUsers &&
          <InputGroup className="flex-nowrap w-auto ml-3">
            <TInput type="text"
              id="searchUser"
              name="searchUser"
              style={{ minWidth: 150 }}
              disabled={planningsUsersLoad || disableFilters}
              value={searchUser}
              autoComplete="off"
              onChange={e => handleSearchUser(e.target.value)}
              placeholder="searchUser" />
            <CustomSelect
              id="userTypeId"
              onChange={e => handleUserTypeId(e.value)}
              isSearchable={false}
              name="userTypeId"
              options={[{ value: 0, key: 'all' }].concat(constants.userTypes)}
              value={constants.userTypes.find(_userType => planningsUsers && _userType.value === Number(planningsUsers.filters.userTypeId.selected.value)) || { value: 0, key: 'all' }}
              isDisabled={planningsUsersLoad || disableFilters}
              getOptionLabel={option => <T raw id={`userType.${option.key}`} />} />
            <CustomSelect
              id="selectCompany"
              onChange={e => handleCompanyId(e.value)}
              isSearchable={false}
              name="selectCompany"
              options={planningsUsers.filters.companyId.values}
              value={planningsUsers.filters.companyId.values.find(_companyId => _companyId.value === planningsUsers.filters.companyId.selected.value)}
              isDisabled={planningsUsersLoad || disableFilters}
              getOptionLabel={option => option.value == 0 ? <T raw id={`companyId.${option.value}`} /> : <>{option.label}</>} />
          </InputGroup>
        }

        <form className="d-flex align-items-center ml-3" onSubmit={handleInterventionSearch} onReset={resetInterventionSearch}>
          <InputGroup>
            <TInput type="text"
              id="interventionInput"
              name="interventionInput"
              disabled={planningsUsersLoad}
              value={interventionInput}
              autoComplete="off"
              onChange={e => setInterventionInput(e.target.value)}
              placeholder="interventionSearch.placeholder" />

            <InputGroupAddon addonType="append">
              <Button
                className="py-0 px-3 d-flex align-items-center"
                type="reset"
                disabled={interventionInput === ''}>
                <i className="simple-icon-close font-weight-bold" />
              </Button>
              <Button
                className="py-0 px-3 d-flex align-items-center"
                type="submit"
                disabled={interventionInput === (location.state && location.state.i)}>
                <i className="simple-icon-magnifier font-weight-bold" />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </form>

        {planningsUsers && <InputGroup className="w-auto ml-3">
          {planningsUsers.filters.planningDuration.values.map(_planningDuration => (
            <FormGroup tag="fieldset" className="px-1" key={_planningDuration.value} check>
              <TCustomInput type="radio"
                id={`planningDuration.${_planningDuration.value}`}
                name={'planningDuration'}
                value={_planningDuration.value}
                checked={planningsUsers.filters.planningDuration.selected.value === _planningDuration.value}
                onChange={e => handlePlanningDuration(e)}
                label={`planningDuration.${_planningDuration.label}`} />
            </FormGroup>
          ))}
        </InputGroup>}

        <TProps className="ml-auto pl-2"
          tagName="div"
          translateProps={{ title: 'helpModal.title' }}
          onClick={handleHelpClick}>
          <i className="simple-icon-question h4 mb-0 text-muted" />
        </TProps>
        <Modal isOpen={isHelpOpen} toggle={handleHelpToggle} size="lg">
          <T tagName={ModalHeader} id="helpModal.header" />
          <T tagName={ModalBody} style={{ whiteSpace: 'pre-wrap' }} id="helpModal.content" />
          <ModalFooter className="py-3">
            <TButton id="helpModal.ok" color="primary" onClick={handleHelpToggle} />
          </ModalFooter>
        </Modal>
      </div>

      <ErrAlert error={planningsUsersError} isOpen={Boolean(planningsUsersError)} fade={false} onClick={handlePlanningsErrorClick} />

      {planningsUsersLoad && <Spinner className="d-flex ml-auto mr-auto" color="primary" />}

      {planningsUsers && !planningsUsersLoad && (
        <Timeline
          groups={planningGroups}
          items={planningItems}
          direction="horizontal"
          itemHeightRatio={1}
          defaultTimeStart={dateStart}
          defaultTimeEnd={dateEnd}
          sidebarWidth={300}
          duration={timeEndWork - timeStartWork}
          onCanvasDrop={handlePlanningItemDrop}
          itemRenderer={PlanningItem}
          groupRenderer={SidebarItem}
          locale={translations.locale}
        />
      )}

      {scheduleError && (
        <Modal isOpen={Boolean(scheduleError)} toggle={() => setScheduleError()}>
          <ModalBody>
            {scheduleError.code === 'preventUpdate'
              ? <>
                <T id={`error.${scheduleError.code}`} values={scheduleError.params} />
                <ListGroup>
                  {scheduleError.params.reasons.map(reason => (
                    <ListGroupItem key={reason}><T id={`error.preventUpdate.${reason}`} /></ListGroupItem>
                  ))}
                </ListGroup>
              </>
              : <ErrAlert error={scheduleError} className="mb-0" />
            }
          </ModalBody>
          <ModalFooter className="py-2">
            <TButton id="scheduleError.dismiss" onClick={() => setScheduleError()} />
            {scheduleError.code === 'preventUpdate' &&
              <TButton id="scheduleError.force" color="danger" onClick={forceSchedule} />}
          </ModalFooter>
        </Modal>
      )}

      {
        plannings && !planningsUsersLoad && (
          <PlanningItemModal
            item={itemSelected}
            users={planningsUsers.users}
            onSubmit={handleItemSelectedSave}
            onDismiss={() => setItemSelected()}
            onRemind={handleItemRemind}
          />
        )
      }
    </div >
  )
}

export default Planning
