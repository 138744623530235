import React from 'react'
import { Switch } from 'react-router-dom'

import Route from 'components/Route'

import List from 'routes/Admin/Itvcodelists/ItvcodelistList'
import New from 'routes/Admin/Itvcodelists/ItvcodelistNew'
import Edit from 'routes/Admin/Itvcodelists/ItvcodelistEdit'

export default function Users({ match }) {
  return <Switch>
    <Route exact path={`${match.path}`} component={List} rights={['admin-itvcodelists_edit', 'admin-itvcodelists_view',]} />
    <Route path={`${match.path}/new`} component={New} rights={['admin-itvcodelists_edit',]} />
    <Route path={`${match.path}/:id`} component={Edit} rights={['admin-itvcodelists_edit', 'admin-itvcodelists_view',]} />
  </Switch>
}
