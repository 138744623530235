import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Nav, NavItem as RSNavItem } from 'reactstrap'
import { useLocation, Link, Route } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { AppContext } from 'contexts/AppContext'

import ProtectedComponent from 'components/ProtectedComponent'

const NavItem = ({ to, args, className, children, ...props }) => (
  <Route
    path={to}
    // eslint-disable-next-line react/no-children-prop
    children={({ match }) => (
      <RSNavItem active={Boolean(match)}
        className={className}>
        <Link to={args ? `${to}${args}` : to} {...props}>{children}</Link>
      </RSNavItem>
    )} />
)

const Sidebar = () => {
  const { menuOpen, setMenuOpen } = React.useContext(AppContext)
  const [isMobile, setIsMobile] = React.useState(false)

  const menuRef = React.useRef()
  const location = useLocation()

  React.useEffect(() => {
    document.addEventListener('click', handleDocumentClick, true)
    document.addEventListener('touchstart', handleDocumentClick, true)
    window.addEventListener('resize', handleWindowResize)
    handleWindowResize()

    return () => {
      document.removeEventListener('click', handleDocumentClick, true)
      document.removeEventListener('touchstart', handleDocumentClick, true)
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [handleDocumentClick, handleWindowResize])

  React.useEffect(() => {
    setMenuOpen(false)
    window.scrollTo(0, 0)
  }, [location.pathname, setMenuOpen])

  const handleWindowResize = React.useCallback((event) => {
    if (event && !event.isTrusted) {
      return
    }

    setIsMobile(window.innerWidth < 768)
    setMenuOpen(false)
  }, [setMenuOpen])

  const handleDocumentClick = React.useCallback((e) => {
    // TODO: check states instead of classes
    const isTargetParentMenuButton = e.target.parentElement &&
      e.target.parentElement.classList &&
      (e.target.parentElement.classList.contains('menu-button') ||
        e.target.parentElement.classList.contains('menu-button-mobile'))

    const isMenuClick = isTargetParentMenuButton

    if (isMenuClick || (menuRef.current.contains(e.target) && menuRef.current !== e.target)) {
      return
    }

    setMenuOpen(false)
  }, [setMenuOpen])

  const mainMenuClasses = React.useMemo(() => ['main-menu']
    .concat(menuOpen ? 'open' : undefined).join(' ').concat(isMobile ? 'mobile' : undefined)
  , [menuOpen, isMobile])

  return (
    <div
      ref={menuRef}
      className="sidebar">
      <div className={mainMenuClasses}>
        <div className="scroll">
          <PerfectScrollbar options={{ suppressScrollX: true, wheelPropagation: false }}>
            <Nav
              vertical
              className="list-unstyled">
              <NavItem to="/dashboard">
                <i className="iconsmind-Dashboard" />
                <FormattedMessage id="menu.dashboard" />
              </NavItem>

              <ProtectedComponent rights={['interventions_view',]}>
                <NavItem to="/interventions" args="?p=1&statusId=1&statusId=2&statusId=6&statusId=3">
                  <i className="iconsmind-Bicycle" />
                  <FormattedMessage id="menu.interventions" />
                </NavItem>
              </ProtectedComponent>

              <ProtectedComponent rights={['masterplan-view',]}>
                <NavItem to="/maintenanceplan">
                  <i className="iconsmind-Box-Full" />
                  <FormattedMessage id="menu.maintenanceplan" />
                </NavItem>
              </ProtectedComponent>

              <ProtectedComponent rights={['maintenanceplan-view',]}>
                <NavItem to="/maintenance">
                  <i className="iconsmind-Repair" />
                  <FormattedMessage id="menu.maintenance" />
                </NavItem>
              </ProtectedComponent>

              <ProtectedComponent rights={['swaps_view',]}>
                <NavItem to="/swaps" args="?p=1&statusId=1&statusId=2&statusId=5">
                  <i className="iconsmind-Repeat-3" />
                  <FormattedMessage id="menu.swaps" />
                </NavItem>
              </ProtectedComponent>

              <ProtectedComponent rights={['appointments_view',]}>
                <NavItem to="/appointments" args="?p=1&statusId=1&statusId=2">
                  <i className="iconsmind-Address-Book2" />
                  <FormattedMessage id="menu.appointments" />
                </NavItem>
              </ProtectedComponent>

              <ProtectedComponent rights={['equipments_view',]}>
                <NavItem to="/equipments">
                  <i className="iconsmind-Map2" />
                  <FormattedMessage id="menu.equipments" />
                </NavItem>
              </ProtectedComponent>

              <ProtectedComponent rights={['planning_view',]}>
                <NavItem to="/planning">
                  <i className="iconsmind-Calendar-4" />
                  <FormattedMessage id="menu.planning" />
                </NavItem>
              </ProtectedComponent>

              <ProtectedComponent rights={['veloptim-dashboard_view']}>
                <NavItem to="/veloptim">
                  <i className="iconsmind-Arrow-Squiggly" />
                  <FormattedMessage id="menu.veloptim" />
                </NavItem>
              </ProtectedComponent>

              <ProtectedComponent rights={['veloparts_view']}>
                <NavItem to="/veloparts">
                  <i className="iconsmind-Puzzle" />
                  <FormattedMessage id="menu.veloparts" />
                </NavItem>
              </ProtectedComponent>

              <ProtectedComponent rights={['desk_view',]}>
                <NavItem to="/desk" args="?p=1&ticketStatusId=10&ticketStatusId=15">
                  <i className="iconsmind-Support" />
                  <FormattedMessage id="menu.desk" />
                </NavItem>
              </ProtectedComponent>

              <NavItem to="/log">
                <i className="iconsmind-Newspaper" />
                <FormattedMessage id="menu.log" />
              </NavItem>

              <ProtectedComponent rights={[
                'fluow-assignment',
                'fluow-pairing'
              ]}>
                <NavItem to="/fluow">
                  <i className="iconsmind-Furl" />{' '}
                  <FormattedMessage id="menu.fluow" />
                </NavItem>
              </ProtectedComponent>

              <ProtectedComponent rights={[
                'stats_view',
              ]}>
                <NavItem to="/statistics">
                  <i className="iconsmind-Bar-Chart2" />
                  <FormattedMessage id="menu.stats" />
                </NavItem>
              </ProtectedComponent>

              <ProtectedComponent rights={[
                'admin-subsidiaries_view',
                'admin-bases_view',
                'admin-business_view',
                'admin-techcenters_view',
                'admin-users_view',
                'admin-profiles_view',
                'admin-unavailabilities_view',
                'admin-categories_view'
              ]}>
                <NavItem to="/admin">
                  <i className="simple-icon-settings" />{' '}
                  <FormattedMessage id="menu.admin" />
                </NavItem>
              </ProtectedComponent>
            </Nav>
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  )
}


export default Sidebar
