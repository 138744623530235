import React from 'react'
import {
  format,
} from 'date-fns'
import moment from 'moment-timezone'
import * as fnsLocales from 'date-fns/esm/locale'

import { AppContext, } from 'contexts/AppContext'

const defaultHeaderFormats = {
  year: {
    long: 'yyyy',
    mediumLong: 'yyyy',
    medium: 'yyyy',
    short: 'YY'
  },
  month: {
    long: 'MMMM yyyy',
    mediumLong: 'MMMM',
    medium: 'MMMM',
    short: 'MM/YY'
  },
  week: {
    long: 'w',
    mediumLong: 'w',
    medium: 'w',
    short: 'w'
  },
  day: {
    long: 'eeee dd/MM/yyyy',
    mediumLong: 'EEEEEE, MMMM d, yyyy',
    medium: 'EEEEEE d',
    short: 'd'
  },
  hour: {
    long: 'EEEEEE, MMMM d, yyyy, HH:00',
    mediumLong: `HH'h'`,
    medium: 'HH',
    short: 'HH'
  },
  minute: {
    long: 'HH:mm',
    mediumLong: 'HH:mm',
    medium: 'HH:mm',
    short: 'mm',
  },
  second: {
    long: 'ss',
    mediumLong: 'ss',
    medium: 'ss',
    short: 'ss',
  }
}

const DateHeader = (props) => {
  const { timezone, language } = React.useContext(AppContext)
  let fnsLocale = 'fr'
  if (language.langIdentifier === 'en-GB') {
    fnsLocale = 'enGB'
  }
  const intervals = React.useMemo(() => {
    // TODO: rework intervals calcs
    const intervals = []

    if (!props.canvasTimeStart) {
      return []
    }

    if (props.unit === 'day') {
      intervals.push(new Date(props.canvasTimeStart))
    } else if (props.unit === 'hour') {
      const oneHour = 1000 * 60 * 60
      const times = props.duration
      for (let i = 0; i < times; i++) {
        const startTime = props.canvasTimeStart + oneHour * i
        intervals.push(new Date(moment
          .tz(startTime, timezone)
          .format("YYYY-MM-DDTHH:mm:ss.SSS")))
      }
    }
    return intervals
  }, [props, timezone])

  const style = React.useMemo(() => ({
    height: props.height || 30,
    position: 'relative',
    ...props.style
  }), [props.height, props.style])

  return (
    <div
      data-testid={`dateHeader`}
      className={`rct-dateHeader-wrapper ${props.className || ''}`}
      style={style}>
      {intervals.map(interval => (
        <div
          key={`label-${interval.valueOf()}`}
          data-testid="dateHeaderInterval"
          style={props.itemStyle}
          className={`rct-dateHeader`}>
          <span>{formatLabel(interval, props.unit, fnsLocales[fnsLocale])}</span>
        </div>
      ))}
    </div>
  )
}

function formatLabel(
  time,
  unit,
  locale,
  formatOptions = defaultHeaderFormats,
) {
  switch (unit) {
  case 'day': return format(time, formatOptions[unit]['long'], { locale: locale })
  case 'hour': return format(time, formatOptions[unit]['mediumLong'])
  default: return format(time, formatOptions[unit]['short'])
  }
}

export default DateHeader
