import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  Card, CardBody, CardHeader,
  FormGroup, Spinner
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import ProtectedComponent from 'components/ProtectedComponent'
import { TCustomInput, TAlert, TButton } from 'components/TComponents'
import Pagination from 'components/Pagination'

import VehicleIcon from '../../../components/VehicleIcon'

const VehiclesList = () => {
  const { api } = React.useContext(AppContext)

  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()

  // const [filter, setFilter] = React.useState('')

  React.useEffect(() => {
    setLoading(true)
    api.get('/vehicles/list', undefined)
      .then(response => setData(response))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api])

  const refresh = React.useCallback(params => {
    setLoading(true)
    setError()
    Promise.all([
      api.get('/vehicles/list', undefined, {
        // ...data.filters,
        p: data.currentPage,
        ...params
      }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => setData(data))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api, data])

  // const refreshDebounced = React.useMemo(() => debounce(params => refresh(params), 250), [refresh])

  // const handleSearchInput = React.useCallback(({ target: { value } }) => {
  //     setFilter(value)
  //     refreshDebounced({ filter: value, p: 1 })
  // }, [refreshDebounced])

  return (
    <>
      <Card className="mb-2">
        <CardHeader>
          {/* <FormGroup className="flex-grow-1 pl-0">
                        <TLabel id="vehiclesFilterTitle" className="sr-only" for="vehiclesFilter" />
                        <TInput
                            name="vehiclesFilter"
                            type="text"
                            placeholder="vehiclesFilterPlaceholder"
                            value={filter}
                            onChange={handleSearchInput} />
                    </FormGroup> */}
          <FormGroup className="flex-grow-1 pl-0 mb-0">
            <TCustomInput type="switch"
              id="archived"
              name="archived"
              label="archived"
              className="w-100 bg-transparent border-0"
              disabled={loading}
              checked={data && data.filters ? data.filters.archived : false}
              onChange={e => refresh({ archived: e.target.checked, p: 1 })} />
          </FormGroup>
        </CardHeader>
        <ProtectedComponent rights={['admin-vehicle_edit']}>
          <CardHeader>
            <TButton id="createVehicle"
              tag={NavLink}
              to="./vehicles/new" />
          </CardHeader>
        </ProtectedComponent>
        <CardBody>
          {error && <TAlert color="danger" id={error.code} />}
          {loading && <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />}
          {!loading && !error && data.result.map(vehicle => (
            <Card key={vehicle.vehicleId} className="mb-2">
              <CardHeader className="d-flex align-items-center">
                <VehicleIcon type={vehicle.typeId} />
                <span className="h6 mb-0">
                  {vehicle.archived && <i className="mr-1 iconsmind-Lock text-danger" />}
                  {vehicle.vehicleIdentifier}&nbsp;{vehicle.vehicleLabel}
                </span>
                <TButton id="viewVehicle" className="ml-auto stretched-link" outline
                  tag={NavLink}
                  to={`./vehicles/${vehicle.vehicleId}`} />
              </CardHeader>
            </Card>

          ))}
        </CardBody>
      </Card>
      {data && <Pagination totalPage={data.totalPage} currentPage={data.currentPage} onPageClick={p => refresh({ p })} />}
    </>

  )
}

export default VehiclesList
