import React from 'react'
import {
  Card, CardBody, CardFooter,
  FormGroup, Input
} from 'reactstrap'
import { useHistory, Link } from 'react-router-dom'

import { AppContext } from 'contexts/AppContext'

import { TLabel, TButton, ErrAlert, TNavLink } from 'components/TComponents'

const PartNew = () => {

  const { api } = React.useContext(AppContext)
  const history = useHistory()
  const [partRef, setPartRef] = React.useState('')
  const [partLabel, setPartLabel] = React.useState('')
  const [externalIdentifier, setExternalIdentifier] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [barcode, setBarcode] = React.useState('')
  const [supplier, setSupplier] = React.useState('')
  const [unit, setUnit] = React.useState('')
  const [purchasePrice, setPurchasePrice] = React.useState('')

  const [createError, setCreateError] = React.useState()
  const [createLoading, setCreateLoading] = React.useState()

  const postPart = React.useCallback(() => {
    setCreateError(false)
    setCreateLoading(true)
    api.post('/parts/details', {
      body: JSON.stringify({
        partRef,
        partLabel,
        externalIdentifier,
        description,
        barcode,
        supplier,
        unit,
        purchasePrice
      })
    })
      .then(response => {
        if (response.partId) {
          history.push('/veloparts/parts')
        }
      })
      .catch(error => setCreateError(error))
      .then(() => setCreateLoading(false))
  }, [api, partRef, partLabel, description, externalIdentifier, barcode, supplier, unit, purchasePrice, history])

  return (
    <>
      <TNavLink id="returnToList" tag={Link} to="/veloparts/parts" />
      <Card>
        <CardBody>
          <FormGroup tag="fieldset">
            <TLabel for="partRef" id="partRef" />
            <Input id="partRef"
              type="text"
              name="partRef"
              value={partRef}
              onChange={e => { setPartRef(e.target.value) }}
            />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="partLabel" id="partLabel" />
            <Input id="partLabel"
              type="text"
              name="partLabel"
              value={partLabel}
              onChange={e => { setPartLabel(e.target.value) }}
            />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="externalIdentifier" id="externalIdentifier" />
            <Input id="externalIdentifier"
              type="text"
              name="externalIdentifier"
              value={externalIdentifier}
              onChange={e => { setExternalIdentifier(e.target.value) }}
            />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="unit" id="unit" />
            <Input id="unit"
              type="text"
              name="unit"
              value={unit}
              onChange={e => { setUnit(e.target.value) }}
            />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="barcode" id="barcode" />
            <Input id="barcode"
              type="text"
              name="barcode"
              value={barcode}
              onChange={e => { setBarcode(e.target.value) }}
            />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="description" id="description" />
            <Input id="description"
              type="text"
              name="description"
              value={description}
              onChange={e => { setDescription(e.target.value) }}
            />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="supplier" id="supplier" />
            <Input id="supplier"
              type="text"
              name="supplier"
              value={supplier}
              onChange={e => { setSupplier(e.target.value) }}
            />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="purchasePrice" id="purchasePrice" />
            <Input id="purchasePrice"
              type="number"
              name="purchasePrice"
              value={purchasePrice}
              onChange={e => { setPurchasePrice(e.target.value) }}
            />
          </FormGroup>
          {createError && <ErrAlert error={createError} />}
        </CardBody>
        <CardFooter className="d-flex justify-content-end">
          <TButton
            disabled={createLoading}
            spin={createLoading}
            className="ml-2"
            onClick={postPart}
            id="add" />
        </CardFooter>
      </Card>
    </>
  )
}

export default PartNew
