import React from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'

import {
  Button,
  Card, CardHeader, CardBody, CardFooter,
  FormGroup,
  Row, Col,
  ListGroup, ListGroupItem,
  NavLink,
} from 'reactstrap'

import 'react-phone-number-input/style.css'
import { AppContext } from 'contexts/AppContext'

import CustomSelect from '../../components/CustomSelect'
import { T, TLabel, TAlert, ErrAlert } from 'components/TComponents'

import ItvcodeSelector from 'components/ItvcodeSelector'

const MaintenancePlanNew = () => {
  const { api, constants } = React.useContext(AppContext)
  const location = useLocation()
  const history = useHistory()

  const [maintenance, setMaintenance] = React.useState()
  const [maintenanceError, setMaintenanceError] = React.useState()
  const [maintenanceLoading, setMaintenanceLoading] = React.useState(false)
  const [businessId, setBusinessId] = React.useState()
  const [categoryId, setCategoryId] = React.useState()
  const [familyId, setFamilyId] = React.useState()
  const [addressId, setAddressId] = React.useState()

  const [itvcodesBase, setItvcodesBase] = React.useState([])
  const [itvcodesLoading, setItvcodesLoading] = React.useState(false)
  const [itvcodesError, setItvcodesError] = React.useState()
  const [itvcodes, setItvcodes] = React.useState({})
  const [durationEstimated, setDurationEstimated] = React.useState({})

  const [difficultyId, setDifficultyId] = React.useState(1)

  const [maintenanceCreating, setMaintenanceCreating] = React.useState(false)
  const [maintenanceCreateError, setMaintenanceCreateError] = React.useState()


  React.useEffect(() => {
    setMaintenanceLoading(true)
    api.get('/maintenanceplans/prepare', undefined, {
      businessId,
      categoryId,
      familyId,
      addressId
    })
      .then(response => {
        setMaintenance(response)
        if (response.result && response.result && response.result.length > 0) {
          response.result.map((r, i) => {
            setItvcodes(codes => ({
              ...codes,
              [i]: []
            }))
          })
        }
      })
      .catch(error => setMaintenanceError(error))
      .then(() => setMaintenanceLoading(false))
  }, [api, businessId, categoryId, familyId, addressId])

  React.useEffect(() => {
    if (maintenance && maintenance.result && maintenance.result.length > 0) {
      maintenance.result.forEach((r, i) => {
        setItvcodesLoading(true)
        api.get('/itvcodes/list', undefined, {
          ipp: -1,
          businessId: r.businessId,
          familyId: r.familyId,
          categoryId: r.categoryId
        })
          .then(response => {
            setItvcodesBase(itvcodesBase => ({
              ...itvcodesBase,
              [i]: response
            }))
          })
          .catch(error => setItvcodesError(error))
          .then(() => setItvcodesLoading(false))
      })
    }
  }, [api, maintenance])

  React.useEffect(() => {
    if (Object.values(itvcodes).length > 0) {
      {Object.values(itvcodes).map((codes, i) => {
        const body = {
          itvcodes: codes.map(itvcode => ({
            itvcodeId: itvcode.itvcodeId,
            quantity: itvcode.quantity,
          }))
        }
        api.post('/interventions/timeItvcodes', { body: JSON.stringify(body) })
          .then(response => {
            setDurationEstimated(duration => ({
              ...duration,
              [i]: response.durationEstimated
            }))
          })
      })}
    } else {
      setDurationEstimated({})
    }
  }, [api, itvcodes])

  const create = React.useCallback(e => {
    e.preventDefault()

    setMaintenanceCreating(true)
    setMaintenanceCreateError()
    return api.post('/masterplans/details', {
      body: JSON.stringify({
        businessId,
        addressId: addressId || null,
        itvcodes: Object.values(itvcodes).map((codes, i) => ({
          itvcodes: codes.map(itvcode => ({
            itvcodeId: itvcode.itvcodeId,
            quantity: itvcode.quantity,
            wear: itvcode.wear
          })),
          categoryId:maintenance.result[i].categoryId ,
          familyId: maintenance.result[i].familyId
        })),
        difficultyId
      })
    })
      .then(response => {
        setMaintenanceCreating(false)
        return response
      })
  }, [api, businessId, difficultyId, itvcodes, addressId, maintenance])

  const createAndReturn = React.useCallback(e => {
    setMaintenanceCreateError()
    create(e)
      .then(() => history.push({
        pathname: '/maintenanceplan',
        search: location.state ? location.state.maintenance : undefined
      }))
      .catch(error => setMaintenanceCreateError(error))
      .then(() => setMaintenanceCreating(false))
  }, [create, history, location])

  const createAndGoToMaintenance = React.useCallback(e => {
    setMaintenanceCreateError()
    create(e)
      .then(masterplan => {
        api.post('/masterplans/maintenance', {
          _stringifyBody: true,
          body: {
            masterplanId: masterplan.masterplanId,
            itvcodes: masterplan.itvcodes,
            difficultyId: masterplan.difficultyId
          }
        })
          .then(maintenance => history.push(`/maintenance/${maintenance.maintenanceplanId}`))
          .catch(error => setMaintenanceCreateError(error))
      })
      .catch(error => {
        setMaintenanceCreateError(error)
        setMaintenanceCreating(false)
      })
  }, [create, history, api])

  const _renderFilters = React.useCallback(() => (
    <>
      {maintenance && maintenance.filters && (
        <>
          <TLabel id="parc" />
          <Card className="mb-3">
            <CardHeader>
              {maintenance.result && maintenance.result.nbEquipment ? (
                <>
                  <TLabel id="parcSelected" />
                  <ListGroup className="mb-3">
                    <ListGroupItem className="d-flex align-items-center">
                      <T id="nbEquipments"
                        values={{
                          businessName: maintenance.result.businessName,
                          categoryName: maintenance.result.categoryName,
                          familyName: maintenance.result.familyName,
                          nbEquipment: maintenance.result.nbEquipment
                        }} />
                    </ListGroupItem>
                  </ListGroup>
                </>
              ) : (
                <>
                  <Row form>
                    {maintenance.filters.businessId && maintenance.filters.businessId.values && (
                      <Col sm="6" md="3">
                        <FormGroup tag="fieldset">
                          <TLabel for="businessId" id="businessLabel" className="" />
                          <CustomSelect
                            inputId="businessId"
                            name="businessId"
                            isClearable
                            options={maintenance.filters.businessId.values}
                            onChange={e => setBusinessId(e && e.value)}
                            placeholder={<T id="businessPlaceholder" />}
                            isDisabled={maintenanceLoading}
                            value={maintenance.filters.businessId.selected || ''} />
                        </FormGroup>
                      </Col>
                    )}
                    {maintenance.filters.categoryId && maintenance.filters.categoryId.values && (
                      <Col sm="6" md="3">
                        <FormGroup tag="fieldset">
                          <TLabel for="categoryId" id="categoryLabel" className="" />
                          <CustomSelect
                            inputId="categoryId"
                            name="categoryId"
                            isClearable
                            options={maintenance.filters.categoryId.values}
                            onChange={e => setCategoryId(e && e.value)}
                            placeholder={<T id="categoryPlaceholder" />}
                            isDisabled={maintenanceLoading}
                            value={maintenance.filters.categoryId.selected || ''} />
                        </FormGroup>
                      </Col>
                    )}
                    {maintenance.filters.familyId && maintenance.filters.familyId.values && (
                      <Col sm="6" md="3">
                        <FormGroup tag="fieldset">
                          <TLabel for="familyId" id="familyLabel" className="" />
                          <CustomSelect
                            inputId="familyId"
                            name="familyId"
                            isClearable
                            options={maintenance.filters.familyId.values}
                            onChange={e => setFamilyId(e && e.value)}
                            placeholder={<T id="familyPlaceholder" />}
                            isDisabled={maintenanceLoading}
                            value={maintenance.filters.familyId.selected || ''} />
                        </FormGroup>
                      </Col>
                    )}
                    {maintenance.filters.addressId && maintenance.filters.addressId.values && (
                      <Col sm="6" md="3">
                        <FormGroup tag="fieldset">
                          <TLabel for="addressId" id="locationLabel" className="" />
                          <CustomSelect
                            inputId="addressId"
                            name="addressId"
                            isClearable
                            options={maintenance.filters.addressId.values}
                            onChange={e => setAddressId(e && e.value)}
                            placeholder={<T id="locationPlaceholder" />}
                            isDisabled={maintenanceLoading}
                            value={maintenance.filters.addressId.selected || ''} />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                </>
              )}
              {!(businessId && categoryId && familyId) && !(businessId && addressId) ? (
                <TAlert color="warning" id="alertNoFilters" />
              ) : (
                <>
                  {maintenance.results && maintenance.results.length === 0 && (
                    <TAlert color="warning" id="noEquipments" />
                  )}
                </>
              )}
            </CardHeader>
          </Card >
        </>
      )}
    </>
  ), [maintenance, maintenanceLoading, businessId, categoryId, familyId, addressId])

  const _renderItvCodes = React.useCallback((res, i) => (
    <>
      {res && res.nbEquipment ? (
        <>
          <TLabel id="parcSelected" />
          <ListGroup className="mb-3">
            <ListGroupItem className="d-flex align-items-center">
              <T id="nbEquipments"
                values={{
                  businessName: res.businessName,
                  categoryName: res.categoryName,
                  familyName: res.familyName,
                  nbEquipment: res.nbEquipment
                }} />
            </ListGroupItem>
          </ListGroup>
        </>
      ) : (
        <TAlert color="warning" id="noEquipments" />
      )}
      <TLabel id="itvcodeList" />
      <>
        {itvcodesError ? <ErrAlert error={itvcodesError} /> : itvcodesBase && itvcodesBase[i] && (
          <ItvcodeSelector
            hasBusiness={businessId}
            loading={maintenanceLoading || itvcodesLoading}
            itvcodesAdded={itvcodes[i] || []}
            itvcodes={itvcodesBase[i].result}
            reason={false}
            noWear
            onChange={itvcodes => {
              setItvcodes(codes => ({
                ...codes,
                [i]: itvcodes
              }))
            }} />
        )}
        <>
          <TLabel id="durations" />
          <Card className="mb-4">
            <CardBody className="pb-3">
              <div className="row mb-2">
                {/* Display /60 hour */}
                <TLabel
                  id="durationEstimated"
                  values={{
                    hours: isNaN(durationEstimated[i]) ? 0 : Math.floor(durationEstimated[i] / 60),
                    minutes: isNaN(durationEstimated[i]) ? 0 : durationEstimated[i] % 60,
                  }}
                  className="col mb-0" />
              </div>
            </CardBody>
          </Card>
        </>
      </>
    </>
  ), [businessId, itvcodesBase, itvcodesLoading, maintenanceLoading, itvcodes, durationEstimated, itvcodesError])

  const _renderInfos = React.useCallback(() => (
    <>
      <FormGroup tag="fieldset">
        <TLabel id="difficultyId" for="difficultyId" />
        <div className={`difficultyInput ${constants.difficultyLevels.find(diff => String(diff.value) === String(difficultyId)).key}`}>
          <CustomSelect
            inputId="difficultyId"
            name="difficultyId"
            options={constants.difficultyLevels}
            onChange={e => setDifficultyId(e && e.value)}
            value={constants.difficultyLevels.filter(d => d.value === difficultyId)}
            isDisabled={maintenanceCreating}
            getOptionLabel={option => <T id={`difficulty.${option.key}`} raw />}
            getOptionValue={option => option.value} />
        </div>
      </FormGroup>
    </>
  ), [constants, difficultyId, maintenanceCreating])

  const _renderErrors = React.useCallback(() => (
    <>
      {maintenanceCreateError && <ErrAlert error={maintenanceCreateError} />}
      {maintenanceError && <ErrAlert error={maintenanceError} />}
    </>
  ), [maintenanceCreateError, maintenanceError])

  const _renderFooter = React.useCallback(() => (
    <CardFooter className="d-flex justify-content-end">
      <Button disabled={maintenanceCreating} type="button" className="ml-2" color="primary" onClick={e => createAndReturn(e)}><T id="createAndReturn" /></Button>
      <Button disabled={maintenanceCreating} type="button" className="ml-2" color="primary" onClick={e => createAndGoToMaintenance(e)}><T id="createAndGoToMaintenance" /></Button>
      <Button disabled={maintenanceCreating} type="cancel" tag={Link}
        className="ml-2"
        to={{
          pathname: '/interventions',
          search: location.state ? location.state.interventions : undefined
        }}>
        <T id="cancel" />
      </Button>
    </CardFooter>
  ), [maintenanceCreating, location.state, createAndGoToMaintenance, createAndReturn])

  return (
    <div className="container-fluid MaintenanceNew">
      <NavLink tag={Link} to={{
        pathname: '/maintenanceplan',
        search: location.state ? location.state.interventions : undefined
      }}>
        <T id="returnToList" />
      </NavLink>
      <Card className="mb-3">
        <CardBody>
          
          {_renderFilters()}
          {maintenance && maintenance.result && maintenance.result.length > 0 && maintenance.result.map((res, i) => (
            <>
              {_renderItvCodes(res, i)}
            </>
          ))}
          {_renderInfos()}
        </CardBody>
        {_renderErrors()}
        {_renderFooter()}
      </Card>
    </div>
  )
}

export default MaintenancePlanNew