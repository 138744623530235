import React from 'react'
import { useHistory, Link } from 'react-router-dom'

import {
  Card, CardBody, CardFooter,
  Form, FormGroup,
  Input,
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import { TLabel, TButton, ErrAlert, TNavLink } from 'components/TComponents'

const NewCategory = () => {
  const { api } = React.useContext(AppContext)
  const history = useHistory()

  const [categoryName, setCategoryName] = React.useState('')

  const [creating, setCreating] = React.useState(false)
  const [createError, setCreateError] = React.useState()

  const create = React.useCallback(e => {
    e.preventDefault()

    setCreating(true)
    api.post('/categories/details', {
      body: JSON.stringify({
        categoryName
      })
    })
      .then(() => {
        history.push('/admin/categories?type=categories')
      })
      .catch(error => {
        setCreateError(error)
        setCreating(false)
      })
  }, [api, categoryName, history])

  return (
    <>
      <TNavLink tag={Link} to="/admin/categories" id="returnToList" />
      <Card className="mb-2" tag={Form}
        onSubmit={create}>
        <CardBody>
          <FormGroup tag="fieldset">
            <TLabel for="categoryName" id="categoryName" />
            <Input id="categoryName"
              type="text"
              name="categoryName"
              disabled={creating}
              value={categoryName}
              onChange={e => setCategoryName(e.target.value)} />
          </FormGroup>
          {createError && <ErrAlert color="danger" error={createError} className="mb-0" />}
        </CardBody>
        <CardFooter className="d-flex justify-content-end">
          <TButton disabled={creating} loading={creating} type="submit" className="ml-2" color="primary" id="create" />
          <TButton disabled={creating} type="cancel" tag={Link} className="ml-2" to="/admin/categories" id="cancel" />
        </CardFooter>
      </Card>
    </>
  )
}

export default NewCategory
