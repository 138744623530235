import React from 'react'
import { useParams, useLocation, useHistory, Link } from 'react-router-dom'
import CustomSelect from 'components/CustomSelect'
import { useIntl } from 'react-intl'
import { objectToQuery } from 'react-rest-api'

import 'react-datepicker/dist/react-datepicker.css'

import Timeline from 'components/react-calendar-timeline'
import 'components/react-calendar-timeline/lib/Timeline.scss'

import {
  Spinner,
  Button,
  Col, Row,
  FormGroup, FormText, Label,
  NavLink,
  ListGroup, ListGroupItem, InputGroup,
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import Slider from 'components/Slider'
import { NotificationManager } from 'components/ReactNotifications'
import SlotSelector from 'components/SlotSelector'
import PlanningItem from 'components/PlanningItem'
import VehicleIcon from 'components/VehicleIcon'
import { T, TButton, TProps, ErrAlert, TLabel, TInput, TCustomInput, TDatePicker } from 'components/TComponents'

import { debounce } from 'util/Utils'

import '../Planning.scss'

const itvHours = Array(9).fill().map((_, i) => i)
const itvMinutes = Array(12).fill().map((_, i) => (i * 5))

const planningDurations = ['durationPlanned', 'durationDone', 'durationChrono', 'durationEstimated']

const MaintenancePlan = () => {
  const routeParams = useParams()
  const history = useHistory()
  const location = useLocation()

  const { api, constants, subsidiary, timezone, timeStartWork, timeEndWork } = React.useContext(AppContext)
  const intl = useIntl()

  const preferredTimeRangeOptions = React.useMemo(() => ({
    min: 6 * 60,
    max: 20 * 60,
    step: 15
  }), [])

  const [planningsUsers, setPlanningsUsers] = React.useState()
  const [planningsUsersLoad, setPlanningsUsersLoad] = React.useState(true)
  const [planningsUsersError, setPlanningsErrorLoad] = React.useState()
  const [plannings, setPlannings] = React.useState([])
  const [planningUserToAdd, setPlanningUserToAdd] = React.useState([])

  const [maintenance, setMaintenance] = React.useState()
  const [maintenanceLoad, setMaintenanceLoad] = React.useState(false)
  const [maintenanceError, setMaintenanceError] = React.useState()
  const [baseMaintenance, setBaseMaintenance] = React.useState()

  const [searchUser, setSearchUser] = React.useState(location.state ? location.state.s : '')
  const [userTypeId, setUserTypeId] = React.useState(location.state && location.state.t)
  const [companyId, setCompanyId] = React.useState(location.state ? location.state.c : subsidiary.companyId)

  const [dateSelected, setDateSelected] = React.useState(location.state && location.state.d ? new Date(location.state.d) : new Date())
  const [itvHourSelected, setItvHourSelected] = React.useState(0)
  const [itvMinuteSelected, setItvMinuteSelected] = React.useState(0)
  const [planningDuration/*, setPlanningDuration*/] = React.useState(planningDurations[0])

  const [disableTimeRange, setDisableTimeRange] = React.useState(true)
  const [preferredTimeRange, setPreferredTimeRange] = React.useState([])

  const [dateStart, setDateStart] = React.useState(new Date().setHours(timeStartWork, 0))
  const [dateEnd, setDateEnd] = React.useState(new Date().setHours(timeEndWork))
  const [planningGroups, setPlanningGroups] = React.useState([])
  const [planningItems, setPlanningItems] = React.useState([])

  const [isHelpOpen, setIsHelpOpen] = React.useState(false)

  const [scheduleLoad, setScheduleLoad] = React.useState(false)
  const [scheduleError, setScheduleError] = React.useState()
  const [durationError, setDurationError] = React.useState()
  const [unScheduleLoad, setUnScheduleLoad] = React.useState(false)
  const [unScheduleError, setUnScheduleError] = React.useState()

  const [userTechcenter, setUserTechcenter] = React.useState([])
  const [techenterScheduleModal, setTechenterScheduleModal] = React.useState(false)
  const [techenterScheduleData, setTechenterScheduleData] = React.useState()

  React.useEffect(() => {
    api.get('/maintenanceplans/details', undefined, { maintenanceplanId: routeParams.id })
      .then(response => {
        setBaseMaintenance(response)
        setMaintenance(response)
      })
      .catch(response => setMaintenanceError(response))
      .then(() => setMaintenanceLoad(false))
  }, [api, routeParams.id])

  React.useEffect(() => {
    if (maintenance) {
      setItvHourSelected(Math.trunc((maintenance.durationPlanned || maintenance.durationEstimated) / 60))
      setItvMinuteSelected((maintenance.durationPlanned || maintenance.durationEstimated) % 60)
      setDisableTimeRange(!(maintenance.timeRangeStart && maintenance.timeRangeEnd))
      setPreferredTimeRange([maintenance.timeRangeStart || preferredTimeRangeOptions.min, maintenance.timeRangeEnd || preferredTimeRangeOptions.max])
      // Blur on select component to hack react-select bug on change
      const inputHour = document.getElementById('selectHour')
      const inputMinute = document.getElementById('selectMinute')
      if (Math.trunc(maintenance.durationPlanned  / 60) !== Math.trunc(baseMaintenance.durationPlanned / 60)) {
        inputHour.focus()
      } else if (maintenance.durationPlanned % 60 !== baseMaintenance.durationPlanned % 60) {
        inputMinute.focus()
      }
    }
  }, [baseMaintenance, maintenance, preferredTimeRangeOptions.max, preferredTimeRangeOptions.min])

  React.useEffect(() => {
    if (baseMaintenance) {
      let ignore = false
      setPlanningsErrorLoad()
      setPlanningsUsersLoad(true)
      api.get('/plannings/view', undefined, {
        dayStart: `${dateSelected.getFullYear()}-${dateSelected.getMonth() + 1}-${dateSelected.getDate()}`,
        userTypeId,
        companyId
      })
        .then(response => {
          if (!ignore) {
            setPlannings([])
            setPlanningsUsers(response)
            const userTechcenterTemp = []
            if (response && response.users && response.users.length > 0) {
              response.users.map(user => {
                if (typeof user.distance !== 'undefined') {
                  userTechcenterTemp.push(user.userId)
                }
              })
            }
            setUserTechcenter(userTechcenterTemp)
          }
        })
        .catch(response => { if (!ignore) { setPlanningsErrorLoad(response) } })
        .then(() => { if (!ignore) { setPlanningsUsersLoad(false) } })

      return () => { ignore = true }
    }
  }, [api, dateSelected, userTypeId, companyId, baseMaintenance])

  const fetchPlanningUser = React.useCallback(i => {
    if (i < planningsUsers.users.length) {
      const _params = {
        dayStart: `${dateSelected.getFullYear()}-${dateSelected.getMonth() + 1}-${dateSelected.getDate()}`,
        userId: planningsUsers.users[i].userId
      }
      api.get('/plannings/userPlanning', undefined, { ..._params })
        .then(response => {
          setPlannings(oldPlanning => [...oldPlanning.filter((pUser => pUser.userId !== planningsUsers.users[i].userId)), {
            userId: planningsUsers.users[i].userId,
            userName: planningsUsers.users[i].firstName + ' ' + planningsUsers.users[i].lastName,
            distance: (planningsUsers.users[i].distance || planningsUsers.users[i].distance === 0) ? planningsUsers.users[i].distance : null,
            interventions: response.interventions,
            appointments: response.appointments,
            swaps: response.swaps,
            unavailabilities: response.unavailabilities,
            maintenanceplans: response.maintenanceplans
          }])
        })
    }
  }, [api, planningsUsers, dateSelected])

  const fetchPlanningUserArray = React.useCallback(userIndexes => {
    setPlanningUserToAdd([])
    userIndexes.map(i => {
      const _params = {
        dayStart: `${dateSelected.getFullYear()}-${dateSelected.getMonth() + 1}-${dateSelected.getDate()}`,
        userId: planningsUsers.users[i].userId
      }
      api.get('/plannings/userPlanning', undefined, { ..._params })
        .then(response => {
          const item = {
            userId: planningsUsers.users[i].userId,
            userName: planningsUsers.users[i].firstName + planningsUsers.users[i].lastName,
            distance: planningsUsers.users[i].distance || null,
            interventions: response.interventions,
            appointments: response.appointments,
            swaps: response.swaps,
            unavailabilities: response.unavailabilities,
            maintenanceplans: response.maintenanceplans
          }
          setPlanningUserToAdd(oldPlanningUserToAdd => [...oldPlanningUserToAdd, item])
        })
    })
  }, [api, planningsUsers, dateSelected])

  React.useEffect(() => {
    if (planningUserToAdd.length === 2) {
      setPlannings(oldPlanning => [...oldPlanning.filter((pUser => !planningUserToAdd.map(pU => pU.userId).includes(pUser.userId))), ...planningUserToAdd])
    }
  }, [planningUserToAdd])

  React.useEffect(() => {
    const dateToCompare = `${dateSelected.getFullYear()}-${("0" + (dateSelected.getMonth() + 1)).toString().padStart(2, '0')}-${dateSelected.getDate().toString().padStart(2, '0')}`
    if (planningsUsers && planningsUsers.users && planningsUsers.users.length > 0 && planningsUsers.filters && planningsUsers.filters.dayStart === dateToCompare) {
      for (let i = 0; i < planningsUsers.users.length; i++) {
        fetchPlanningUser(i)
      }
    }
  }, [planningsUsers, fetchPlanningUser, dateSelected])

  React.useEffect(() => {
    history.replace({ search: objectToQuery({ ...location.state }), state: location.state })
  }, [history, location.state])

  React.useEffect(() => {
    if (planningsUsers) {
      setDateStart(new Date(planningsUsers.filters.dayStart).setHours(timeStartWork, 0))
      setDateEnd(new Date(planningsUsers.filters.dayStart).setHours(timeEndWork))
    }
  }, [planningsUsers, timezone, timeStartWork, timeEndWork])

  const itvStatus = React.useMemo(() => constants.interventionStatus.reduce((acc, status) => ({ ...acc, [status.key]: status.value }), {}), [constants])
  const swapStatus = React.useMemo(() => constants.swapStatus.reduce((acc, status) => ({ ...acc, [status.key]: status.value }), {}), [constants])

  React.useEffect(() => {
    if (planningsUsers) {
      const users = !searchUser || searchUser.trim().length === 0 ? planningsUsers.users
        : planningsUsers.users.filter(user => (user.firstName + ' ' + user.lastName).toLowerCase().indexOf(searchUser.toLowerCase()) !== -1)
      setPlanningGroups(users.map(user => ({ id: user.userId, title: user.firstName + ' ' + user.lastName, distance: user.distance, customHeight: (user.distance || user.distance === 0) ? true : false || null, vehicle: user.vehicleType, vehicleIdentifier: user.vehicleIdentifier })))
    }
  }, [maintenance, planningsUsers, searchUser, itvStatus, swapStatus])

  React.useEffect(() => {
    if (plannings && plannings.length > 0 && maintenance) {

      const getInterventionStyle = maintenance => {
        const style = {}
        if ([itvStatus.done, itvStatus.canceled, itvStatus.closed].includes(maintenance.statusId)) {
          style['backgroundColor'] = 'rgba(0, 0, 0, .4)'
        } else if (maintenance.statusId === itvStatus.scheduled && (new Date(maintenance.datePlanned).getTime() + maintenance.durationPlanned * 1000 * 60) < Date.now()) {
          style['backgroundColor'] = 'rgba(255, 0, 0, .4)'
        } else {
          style['backgroundColor'] = 'rgba(0, 125, 250, .4)'
        }
        return style
      }

      const getSwapStyle = swap => {
        const style = {}
        if ([swapStatus.done, swapStatus.canceled].includes(swap.statusId)) {
          style.background = 'repeating-linear-gradient(125deg, rgba(246, 186, 82, .5), rgba(246, 186, 82, .5) 50%, rgba(0, 0, 0, .4) 50%, rgba(0, 0, 0, .4) 100%)'
        } else if (swap.statusId === swapStatus.scheduled && (new Date(swap.datePlanned).getTime() + swap.durationPlanned * 1000 * 60) < Date.now()) {
          style.background = 'repeating-linear-gradient(125deg, rgba(255, 0, 0, .5), rgba(255, 0, 0, .5) 50%, rgba(255, 209, 128, .4) 50%, rgba(255, 209, 128, .4) 100%)'
        } else {
          style.background = 'repeating-linear-gradient(125deg, rgba(246, 186, 82, 1), rgba(246, 186, 82, 1) 50%, rgba(255, 209, 128, 1) 50%, rgba(255, 209, 128, 1) 100%)'
        }
        return style
      }

      const users = !searchUser || searchUser.trim().length === 0 ? plannings
        : plannings.filter(user => user.userName.toLowerCase().indexOf(searchUser.toLowerCase()) !== -1)

      setPlanningItems(users.reduce((acc, user) =>
        acc
          .concat(user.interventions.map(_intervention => ({
            id: `timelineItem-itv-${_intervention.interventionId}`,
            type: 'intervention',
            group: user.userId,
            canMove: false,
            title: '',
            className: _intervention.difficultyValue,
            timeStart: new Date(_intervention.datePlanned).getTime(),
            timeEnd: new Date(_intervention.datePlanned).getTime() + _intervention.durationPlanned * 1000 * 60,
            style: getInterventionStyle(_intervention),
            intervention: _intervention
          })))
          .concat(user.swaps.map(swap => ({
            id: `timelineItem-swap-${swap.swapId}`,
            type: 'swap',
            group: user.userId,
            canMove: false,
            timeStart: new Date(swap.datePlanned).getTime(),
            timeEnd: new Date(swap.datePlanned).getTime() + swap[planningDuration] * 1000 * 60,
            style: getSwapStyle(swap),
            swap
          })))
          .concat(user.appointments.map(appointment => ({
            id: `timelineItem-app-${appointment.appointmentId}`,
            type: 'appointment',
            group: user.userId,
            canMove: false,
            timeStart: new Date(appointment.dateAppointment).getTime(),
            timeEnd: new Date(appointment.dateAppointment).getTime() + appointment.durationPlanned * 1000 * 60,
            style: { 'backgroundColor': 'rgba(125, 0, 125, .4)' },
            appointment
          })))
          .concat(user.unavailabilities.map(unavailability => ({
            id: `timelineItem-unv-${unavailability.unavailabilityId}`,
            type: 'unavailability',
            group: user.userId,
            canMove: false,
            stackable: false,
            timeStart: new Date(unavailability.dateStart).getTime(),
            timeEnd: new Date(unavailability.dateEnd).getTime(),
            style: {
              'background': 'repeating-linear-gradient(135deg,#eeeeee,#eeeeee 10px,#cacaca 10px,#cacaca 20px)',
              zIndex: 0
            },
            unavailability
          })))
          .concat(user.maintenanceplans.map(_maintenance => ({
            id: `timelineItem-mai-${_maintenance.maintenanceplanId}`,
            type: 'maintenance',
            group: user.userId,
            canMove: maintenance.maintenanceplanId === _maintenance.maintenanceplanId,
            canResize: maintenance.maintenanceplanId === _maintenance.maintenanceplanId,
            title: '',
            className: _maintenance.difficultyValue + (maintenance.maintenanceplanId === _maintenance.maintenanceplanId ? ' active' : ''),
            timeStart: new Date(_maintenance.datePlanned).getTime(),
            timeEnd: new Date(_maintenance.datePlanned).getTime() + _maintenance[planningDuration] * 1000 * 60,
            style: { 'backgroundColor': 'rgba(226, 134, 59, .4)' },
            maintenance: _maintenance
          })))
      , [])
      )
    }
  }, [maintenance, plannings, searchUser, planningDuration, itvStatus, swapStatus])

  const schedule = React.useCallback(({ userId, force, time, minutes, hours, timeRangeStart, timeRangeEnd, forceAddressTechcenter }) => {
    const { id: maintenanceplanId } = routeParams
    const duration = ((hours ?? itvHourSelected) * 60) + (minutes ?? itvMinuteSelected)

    const datePlanned = time ? new Date(time) : undefined

    setBaseMaintenance(maintenance)

    setScheduleError()
    return api.post('/maintenanceplans/schedule', {
      body: JSON.stringify({
        maintenanceplanId,
        datePlanned,
        userId,
        timeRangeStart,
        timeRangeEnd,
        duration: maintenance.durationPlanned === duration ? undefined : duration,
        force,
        forceAddressTechcenter
      })
    })
      .then(_maintenance => {
        if (maintenance.userIdAssignedTo) {
          if (_maintenance.userIdAssignedTo === maintenance.userIdAssignedTo) {
            fetchPlanningUser(planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === maintenance.userIdAssignedTo)))
          } else {
            fetchPlanningUserArray([
              planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === maintenance.userIdAssignedTo)),
              planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === _maintenance.userIdAssignedTo))
            ])
          }
        } else {
          if (_maintenance.userIdAssignedTo) {
            fetchPlanningUser(planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === _maintenance.userIdAssignedTo)))
          }
        }
        setMaintenance(_maintenance)
      })
  }, [api, maintenance, itvHourSelected, itvMinuteSelected, routeParams, fetchPlanningUserArray, fetchPlanningUser, planningsUsers])

  const unschedule = React.useCallback(() => {
    const { id: maintenanceplanId } = routeParams
    setUnScheduleLoad(true)
    return api.del('/maintenanceplans/schedule', undefined, { maintenanceplanId })
      .then(_maintenance => setMaintenance(_maintenance))
      .then(() => fetchPlanningUser(planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === maintenance.userIdAssignedTo))))
      .catch(response => setUnScheduleError(response))
      .then(() => setUnScheduleLoad(false))
  }, [api, routeParams, fetchPlanningUser, maintenance, planningsUsers])

  const handleTimelineDoubleClick = React.useCallback((e, userId, time) => {
    const data = { userId, time }
    if (userTechcenter.includes(userId)) {
      setTechenterScheduleModal(true)
      setTechenterScheduleData(data)
    } else {
      setScheduleLoad(true)
      schedule(data)
        .catch(error => setScheduleError({ ...error, data }))
        .then(() => setScheduleLoad(false))
    }
  }, [schedule, userTechcenter])

  const handleMaintenanceDrop = React.useCallback((userId, time, item) => {
    setPlanningItems(p => p.map(_item => _item.id === item.id ? { ..._item, canMove: false } : _item))
    const data = { userId, time }
    setScheduleLoad(true)
    schedule(data)
      .catch(error => setScheduleError({ ...error, data }))
      .catch(response => NotificationManager.error(response))
      .then(() => setScheduleLoad(false))
  }, [schedule])

  const handleHelpClick = React.useCallback(() => setIsHelpOpen(true), [])

  const handleHelpToggle = React.useCallback(() => setIsHelpOpen(h => !h), [])

  const handleItvHoursChange = React.useCallback(({ target: { value } }) => {
    setDurationError()
    setScheduleLoad(true)
    const data = { hours: Number(value) }
    schedule(data)
      .catch(error => {
        if (error.code === 'preventUpdate') {
          setScheduleError({ ...error, data })
        } else {
          setDurationError(error)
        }
      })
      .then(() => setScheduleLoad(false))
  }, [schedule])

  const handleItvMinutesChange = React.useCallback(({ target: { value } }) => {
    setDurationError()
    setScheduleLoad(true)
    const data = { minutes: Number(value) }
    schedule(data)
      .catch(error => setScheduleError(error.code === 'preventUpdate' ? { ...error, data } : error))
      .then(() => setScheduleLoad(false))
  }, [schedule])

  const scheduleDebounce = React.useMemo(() => debounce(schedule, 1200), [schedule])

  const handleTimeRange = React.useCallback(({ target: { value } }) => {
    setPreferredTimeRange(value)
    setScheduleLoad(true)
    scheduleDebounce({
      timeRangeStart: Math.min(...value),
      timeRangeEnd: Math.max(...value),
    })
      .catch(error => setScheduleError(error))
      .then(() => setScheduleLoad(false))
  }, [scheduleDebounce])

  const handleTimeRangeDisable = React.useCallback(({ target: { checked } }) => {
    setDisableTimeRange(!disableTimeRange)
    setScheduleLoad(true)
    schedule({
      timeRangeStart: checked ? null : preferredTimeRange[0],
      timeRangeEnd: checked ? null : preferredTimeRange[1],
    })
      .catch(error => setScheduleError(error))
      .then(() => setScheduleLoad(false))
  }, [disableTimeRange, preferredTimeRange, schedule])

  const handleItvDate = React.useCallback(e => {
    e.preventDefault()
    handleDateChange(new Date(maintenance.datePlanned))
  }, [handleDateChange, maintenance])

  const handleDateChange = React.useCallback((date) => {
    if (date.getTime() < new Date().setHours(0, 0, 0, 0)) {
      return NotificationManager.error({ code: 'cannotGoPast' })
    }
    setDateSelected(date)
    history.replace({
      search: location.search,
      state: { ...location.state, d: date }
    })
  }, [history, location.search, location.state])

  const filterUsers = React.useCallback(value => {
    setSearchUser(value)
    history.replace({
      search: location.search,
      state: { ...location.state, s: value }
    })
  }, [history, location.search, location.state])

  const handleUserTypeId = React.useCallback(value => {
    setUserTypeId(value)
    history.replace({
      search: location.search,
      state: { ...location.state, t: value }
    })
  }, [history, location.search, location.state])

  const handleCompanyId = React.useCallback(value => {
    setCompanyId(value)
    history.replace({
      search: location.search,
      state: { ...location.state, c: value }
    })
  }, [history, location.search, location.state])

  const SidebarItem = ({ group }) => {
    return (
      <div className="h-100 d-flex flex-row align-items-center">
        <div className="h-100 d-flex justify-content-center flex-column">
          <p className="mb-0">{group.title}</p>
          {(group.distance || group.distance === 0) && <p className="mb-0 font-weight-light font-italic">
            <T id="techcenterDistance" values={{ value: (Math.round(group.distance) / 1000).toFixed(3) }} /></p>}
        </div >
        {group.vehicle && group.vehicleIdentifier && (
          <>{' - '}<VehicleIcon type={group.vehicle} /> {' - '} {group.vehicleIdentifier}</>
        )}
      </div >
    )
  }

  const error = maintenanceError || planningsUsersError
  if (error) {
    return <>
      <NavLink tag={Link} to={`/maintenance/${routeParams.id}`}><T id="returnToDetails" /></NavLink>
      <ErrAlert error={error} />
    </>
  }

  return (
    <div className="container-fluid InterventionPlan">
      <NavLink tag={Link} to={`/maintenance/${routeParams.id}`}><T id="returnToDetails" /></NavLink>

      <span className="mb-2 d-block">
        <span className="h5 text-muted"><T id="title" /></span>
        <span className="h4">{` ${routeParams.id}`}</span>
        {maintenanceLoad && <Spinner className="h6 ml-2" animation="border" role="status" size="sm"></Spinner>}
      </span>

      <Row>
        <Col>
          <FormGroup tag="fieldset">
            <Label for="selectHour"><T id="selectHour" /></Label>
            <CustomSelect
              inputId="selectHour"
              name="selectHour"
              options={itvHours}
              placeholder={"0"}
              onChange={e => handleItvHoursChange({ target: { value: e } })}
              value={itvHours.filter(h => h == itvHourSelected)}
              getOptionValue={option => option}
              getOptionLabel={option => option}
              isDisabled={maintenanceLoad} />
          </FormGroup>
        </Col>

        <Col>
          <FormGroup tag="fieldset">
            <Label for="selectMinute"><T id="selectMinute" /></Label>
            <CustomSelect
              inputId="selectMinute"
              name="selectMinute"
              options={itvMinutes}
              onChange={e => handleItvMinutesChange({ target: { value: e } })}
              placeholder={"0"}
              value={itvMinutes.filter(h => h == itvMinuteSelected)}
              getOptionValue={option => option}
              getOptionLabel={option => option}
              isDisabled={maintenanceLoad} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <ErrAlert error={durationError} isOpen={Boolean(durationError)} toggle={() => setDurationError()} />
        </Col>
      </Row>
      <Row>
        <Col>
          <fieldset className="mb-4">
            <TLabel id="preferredTimeRange.label" className="" tagName="div" />
            <TCustomInput
              id="preferredTimeRange"
              className="mb-2"
              type="checkbox"
              name="disable"
              label="preferredTimeRange.disable"
              checked={disableTimeRange}
              disabled={maintenanceLoad}
              onChange={handleTimeRangeDisable}
              inline />
            {!disableTimeRange && preferredTimeRange.length > 0 && <T id="preferredTimeRange.value" tagName="div" values={{
              timeStart: new Date().setHours(0, Math.min(...preferredTimeRange), 0, 0),
              timeEnd: new Date().setHours(0, Math.max(...preferredTimeRange), 0, 0)
            }} />}
            {disableTimeRange && <T id="preferredTimeRange.noValue" tagName="div" />}
            <Slider
              name="preferredTimeRange"
              values={preferredTimeRange}
              onChange={handleTimeRange}
              disabled={maintenanceLoad || disableTimeRange}
              {...preferredTimeRangeOptions} />
            <FormText><T id="preferredTimeRange.disclaimer" /></FormText>
          </fieldset>
        </Col>
      </Row>

      {scheduleError && (
        <Modal isOpen={Boolean(scheduleError)} toggle={() => setScheduleError()}>
          <ModalBody>
            {scheduleError.code === 'preventUpdate'
              ? <T id={`error.${scheduleError.code}`} values={scheduleError.params} />
              : <ErrAlert error={scheduleError} className="mb-0" />}
            {scheduleError.params && scheduleError.params.reasons && (
              <ListGroup>
                {scheduleError.params.reasons.map(reason => (
                  <ListGroupItem key={reason}><T id={`error.preventUpdate.${reason}`} /></ListGroupItem>
                ))}
              </ListGroup>
            )}
          </ModalBody>
          <ModalFooter className="py-2">
            <TButton id="scheduleError.dismiss" onClick={() => setScheduleError()} />
            {scheduleError.code === 'preventUpdate' && <TButton id="scheduleError.force" color="danger" onClick={() => schedule({
              ...scheduleError.data,
              force: scheduleError.params.reasons,
              forceAddressTechcenter: scheduleError.forceAddressTechcenter
            })} />}
          </ModalFooter>
        </Modal>
      )}

      <Modal isOpen={Boolean(techenterScheduleModal)} toggle={() => setScheduleError()}>
        <ModalBody>
          <T id="forceTechcenterSchedule" />
        </ModalBody>
        <ModalFooter className="py-2">
          <TButton id="forceTechcenterSchedule.0" color="success" onClick={() => schedule({
            ...techenterScheduleData,
            forceAddressTechcenter: 0
          }).catch(error => setScheduleError({ ...error, data: techenterScheduleData, forceAddressTechcenter: 0 }))
            .then(() => setScheduleLoad(false))
            .then(() => setTechenterScheduleModal(false))} />
          <TButton id="forceTechcenterSchedule.1" color="warning" onClick={() => schedule({
            ...techenterScheduleData,
            forceAddressTechcenter: 1
          }).catch(error => {
            setScheduleError({ ...error, data: techenterScheduleData, forceAddressTechcenter: 1 })
          })
            .then(() => setScheduleLoad(false))
            .then(() => setTechenterScheduleModal(false))} />
        </ModalFooter>
      </Modal>

      <div className="d-flex align-items-center mb-3">
        <div className="position-relative d-flex">
          <i className="btn btn-secondary simple-icon-arrow-left px-2 mr-1"
            onClick={() => { handleDateChange(new Date(dateSelected.getTime() - (3600 * 1000 * 24))) }}
          />
          <TDatePicker
            disabled={scheduleLoad || unScheduleLoad || maintenanceLoad}
            customInput={<SlotSelector className="date-fixed" />}
            selected={dateSelected}
            onChange={handleDateChange}
            // TODO: all DatePicker dateFormat shoudl be in translation. Maybe event a custom DatPicker component for that
            dateFormat="eeee dd/MM/yyyy"
            minDate={new Date()}
          />
          <i className="btn btn-secondary simple-icon-arrow-right px-2 ml-1"
            onClick={() => { handleDateChange(new Date(dateSelected.getTime() + (3600 * 1000 * 24))) }}
          />
        </div>

        {planningsUsers &&
          <InputGroup className="mx-3 flex-nowrap w-auto">
            <TInput type="text"
              id="searchUser"
              name="searchUser"
              style={{ minWidth: 150 }}
              value={searchUser}
              autoComplete="off"
              onChange={e => filterUsers(e.target.value)}
              placeholder="searchUser" />
            <CustomSelect
              id="userTypeId"
              onChange={e => handleUserTypeId(e.value)}
              isSearchable={false}
              name="userTypeId"
              options={[{ value: 0, key: 'all' }].concat(constants.userTypes)}
              value={constants.userTypes.find(_userType => _userType.value === Number(planningsUsers.filters.userTypeId.selected.value)) || { value: 0, key: 'all' }}
              isDisabled={scheduleLoad || unScheduleLoad || maintenanceLoad}
              getOptionLabel={option => <T raw id={`userType.${option.key}`} />} />
            <CustomSelect
              id="selectCompany"
              onChange={e => handleCompanyId(e.value)}
              isSearchable={false}
              name="selectCompany"
              options={planningsUsers.filters.companyId.values}
              value={planningsUsers.filters.companyId.selected}
              isDisabled={scheduleLoad || unScheduleLoad || maintenanceLoad}
              getOptionLabel={option => option.value == 0 ? <T raw id={`companyId.${option.value}`} /> : <>{option.label}</>} />
          </InputGroup>
        }

        {maintenance && maintenance.datePlanned && <div className="mr-3">
          <T id="datePlanned"
            className="d-inline-block py-2 mr-2"
            values={{
              date: intl.formatDate(maintenance.datePlanned),
              time: intl.formatTime(maintenance.datePlanned)
            }} />
          <T tagName={Button} onClick={handleItvDate} id="returnToPlanDate" />
        </div>}
        {maintenance && !maintenance.datePlanned && <T className="mr-3" id="noDatePlanned" />}

        <TProps className="ml-auto"
          tagName="div"
          translateProps={{ title: 'helpTitle' }}
          onClick={handleHelpClick}>
          <i className="simple-icon-question h4 mb-0 text-muted" />
        </TProps>
        <Modal isOpen={isHelpOpen} toggle={handleHelpToggle}>
          <T tagName={ModalHeader} id="helpHeader" />
          <T tagName={ModalBody} style={{ whiteSpace: 'pre-wrap' }} id="helpContent" />
          <ModalFooter className="py-3">
            <Button color="primary" onClick={handleHelpToggle}><T id="helpOk" /></Button>
          </ModalFooter>
        </Modal>
      </div>

      {!planningsUsersLoad && <Timeline
        groups={planningGroups}
        groupRenderer={SidebarItem}
        items={planningItems}
        direction="horizontal"
        itemHeightRatio={1}
        customHeight={45}
        sidebarWidth={300}
        duration={timeEndWork - timeStartWork}
        defaultTimeStart={dateStart}
        defaultTimeEnd={dateEnd}
        onCanvasDoubleClick={handleTimelineDoubleClick}
        onCanvasDrop={handleMaintenanceDrop}
        itemRenderer={PlanningItem} />}

      <ErrAlert error={unScheduleError} isOpen={Boolean(unScheduleError)} toggle={() => setUnScheduleError()} className="mt-3 mb-0" />

      <div className="d-flex mt-3 align-items-start">
        <div className="ml-auto pl-2">
          <TButton
            id="unschedule"
            disabled={scheduleLoad || unScheduleLoad || maintenanceLoad || !maintenance || !maintenance.datePlanned}
            color="danger"
            onClick={() => unschedule()} />
        </div>
      </div>
    </div >
  )
}

export default MaintenancePlan
