import React from 'react'
import { useHistory, Link } from 'react-router-dom'

import {
  Card, CardBody, CardFooter,
  Form, FormGroup, Input,
  Spinner
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import CustomSelect from 'components/CustomSelect'
import ProtectedComponent from 'components/ProtectedComponent'
import { T, TButton, TNavLink, TLabel, ErrAlert, TCustomNumericInput } from 'components/TComponents'

const ItvcodeNew = () => {
  const { api } = React.useContext(AppContext)
  const history = useHistory()

  const [itvcode, setItvcode] = React.useState({})

  const [createLoading, setCreateLoading] = React.useState(false)
  const [createError, setCreateError] = React.useState()
  const [categories, setCategories] = React.useState([])
  const [categoryId, setCategoryId] = React.useState()
  const [families, setFamilies] = React.useState([])
  const [familyId, setFamilyId] = React.useState()
  const [loading, setLoading] = React.useState()
  const [loadError, setLoadError] = React.useState()

  React.useEffect(() => {
    Promise.all([
      api.get('/categories/list', undefined, { ipp: -1 })
    ])
      .then(([categories]) => {
        setCategories(categories.result.map(category => ({
          label: category.categoryName,
          value: category.categoryId,
        })))
      })
      .catch(response => setLoadError(response))
      .then(() => setLoading(false))
  }, [api])

  React.useEffect(() => {
    Promise.all([
      api.get('/families/list', undefined, { ipp: -1, categoryId: categoryId })
    ])
      .then(([families]) => {
        setFamilies(families.result.map(family => ({
          label: family.familyName,
          value: family.familyId,
        })))
      })
      .catch(response => setLoadError(response))
      .then(() => setLoading(false))
  }, [api, categoryId])

  React.useEffect(() => {
    setItvcode(itvcode => ({
      ...itvcode,
      categoryId: categoryId
    }))
  }, [categoryId])

  React.useEffect(() => {
    setItvcode(itvcode => ({
      ...itvcode,
      familyId: familyId
    }))
  }, [familyId])

  const handleChange = React.useCallback(({ target: { name, value } }) => {
    setItvcode(itvcode => ({
      ...itvcode,
      [name]: value
    }))
  }, [])

  const save = React.useCallback(() => {
    setCreateLoading(true)
    setCreateError()
    Promise.all([
      api.post('/itvcodes/details', { body: JSON.stringify(itvcode) }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(() => {
        history.push('/admin/itvcodes')
      })
      .catch(response => setCreateError(response))
      .then(() => setCreateLoading(false))
  }, [api, itvcode, history])

  return (
    <>
      {loading && <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />}
      {!loading && loadError && <ErrAlert error={loadError} />}
      {!loading && !loadError &&
        <>
          <TNavLink id="returnToList" tag={Link} to="/admin/itvcodes" />
          <Card className="mb-5">
            <CardBody tag={Form}>
              <FormGroup tag="fieldset" className="d-flex">
                <TLabel id="itvcodeIdentifier" for="itvcodeIdentifier" />
                <Input id="itvcodeIdentifier"
                  type="text"
                  name="itvcodeIdentifier"
                  disabled={createLoading}
                  onChange={handleChange}
                  value={itvcode.itvcodeIdentifier || ''} />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="itvcodeLabel" for="itvcodeLabel" />
                <Input id="itvcodeLabel"
                  type="text"
                  name="itvcodeLabel"
                  disabled={createLoading}
                  value={itvcode.itvcodeLabel || ''}
                  onChange={handleChange} />
              </FormGroup>
              <FormGroup tag="fieldset" className="d-flex">
                <TLabel id="ref" for="ref" />
                <Input id="ref"
                  type="text"
                  name="ref"
                  disabled={createLoading}
                  value={itvcode.ref || ''}
                  onChange={handleChange} />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="priceTTC" for="priceTTC" />
                <TCustomNumericInput id="priceTTC"
                  step={1}
                  precision={2}
                  // format={undefined}
                  name="priceTTC"
                  disabled={createLoading}
                  value={itvcode.priceTTC}
                  onChange={handleChange} />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="durationMin" for="durationMin" />
                <TCustomNumericInput id="durationMin"
                  step={1}
                  min={0}
                  name="durationMin"
                  disabled={createLoading}
                  value={itvcode.durationMin || ''}
                  onChange={handleChange} />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="create.categoryId" for="categoryId" />
                <CustomSelect
                  inputId="categoryId"
                  name="categoryId"
                  options={categories}
                  onChange={e => setCategoryId(e && e.value)}
                  isClearable
                  placeholder={<T id="categoriesPlaceholder" />}
                  value={categories && categories.filter(c => c.value == categoryId)} />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="create.familyId" for="familyId" />
                <CustomSelect
                  inputId="categoryId"
                  name="categoryId"
                  options={families}
                  onChange={e => setFamilyId(e && e.value)}
                  isClearable
                  placeholder={<T id="familiesPlaceholder" />}
                  value={families && families.filter(f => f.value == familyId)} />
              </FormGroup>
              {createError && <ErrAlert error={createError} />}
            </CardBody>

            <ProtectedComponent rights={['admin-itvcodes_edit']}>
              <CardFooter className="d-flex justify-content-end">
                <TButton id="save"
                  disabled={createLoading}
                  spin={createLoading}
                  className="ml-2"
                  onClick={save} color="primary" />
                <TButton id="cancel"
                  disabled={createLoading}
                  type="cancel"
                  tag={Link}
                  className="ml-2"
                  to="/admin/itvcodes"
                />
              </CardFooter>
            </ProtectedComponent>
          </Card>
        </>
      }
    </>
  )
}

export default ItvcodeNew
