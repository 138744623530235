import React from 'react'
import {
  Card, CardBody, CardFooter,
  Modal, ModalBody, ModalFooter,
  FormGroup, Input
} from 'reactstrap'
import { Link } from 'react-router-dom'

import { AppContext } from 'contexts/AppContext'

import ProtectedComponent from 'components/ProtectedComponent'
import { T, TAlert, TLabel, TButton, ErrAlert, TNavLink } from 'components/TComponents'

const WarehouseEdit = ({ match }) => {

  const { api } = React.useContext(AppContext)

  const [loading, setLoading] = React.useState(true)
  const [loadError, setLoadError] = React.useState(false)
  const [warehouse, setWarehouse] = React.useState()
  const [edit, setEdit] = React.useState(false)
  const [updating, setUpdating] = React.useState(false)
  const [updateError, setUpdateError] = React.useState()
  const [updateSuccess, setUpdateSuccess] = React.useState()
  const [modalOpen, setModalOpen] = React.useState(false)
  const [archiveError, setArchiveError] = React.useState()
  const [archive, setArchive] = React.useState(false)

  React.useEffect(() => {
    api.get('/warehouses/details', undefined, { warehouseId: match.params.id })
      .then(response => setWarehouse(response))
      .catch(response => setLoadError(response))
      .then(() => setLoading(false))
  }, [api, match])

  const handleChangeWarehouse = React.useCallback((value, target) => {
    setWarehouse(warehouse => ({
      ...warehouse,
      [target]: value
    }))
  }, [])

  const handleSave = React.useCallback(() => {
    setUpdateSuccess()
    setUpdateError()
    setUpdating(true)
    api.post('/warehouses/details', {
      body: JSON.stringify({
        warehouseId: warehouse.warehouseId,
        warehouseRef: warehouse.warehouseRef,
        warehouseLabel: warehouse.warehouseLabel
      })
    })
      .then(() => {
        setEdit(false)
        setUpdateSuccess(true)
      })
      .catch(error => setUpdateError(error.code))
      .then(() => setUpdating(false))
  }, [api, warehouse])

  const handleCancel = React.useCallback(() => {
    setEdit(false)
    setUpdateSuccess(false)
    setUpdateError(false)
  }, [])

  const archiveWarehouse = React.useCallback(() => {
    setArchive(true)
    api.del('/warehouses/details', undefined, { warehouseId: warehouse.warehouseId })
      .then(response => {
        setWarehouse(response)
        setModalOpen(false)
      })
      .catch(response => setArchiveError(response))
      .then(() => setArchive(false))
  }, [api, warehouse])

  return (
    <>
      <TNavLink id="returnToList" tag={Link} to="/veloparts/shops" />
      {!loading && !loadError && warehouse && (
        <>
          <Card>
            <CardBody>
              <FormGroup tag="fieldset">
                <TLabel for="warehouseRef" id="warehouseRef" />
                <Input id="warehouseRef"
                  type="text"
                  name="warehouseRef"
                  value={warehouse.warehouseRef}
                  disabled={!edit}
                  onChange={e => { handleChangeWarehouse(e.target.value, 'warehouseRef') }}
                />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel for="warehouseLabel" id="warehouseLabel" />
                <Input id="warehouseLabel"
                  type="text"
                  name="warehouseLabel"
                  value={warehouse.warehouseLabel}
                  disabled={!edit}
                  onChange={e => { handleChangeWarehouse(e.target.value, 'warehouseLabel') }}
                />
              </FormGroup>
              <div>
                {updateError && <TAlert id={updateError} className="mb-0" color="danger" />}
                {updateSuccess && <TAlert id="updateSuccess" className="mb-0" color="success" />}
              </div>
            </CardBody>
            <CardFooter>
              <ProtectedComponent rights={['veloparts-warehouses_edit']}>
                <div className="d-flex">
                  {!warehouse.dateArchived && (
                    <TButton id='archive' loading={updating} disabled={updating} onClick={() => setModalOpen(true)} color="primary" />
                  )}
                  <div className="ml-auto pl-2 d-flex">
                    {!edit && <TButton id="edit" onClick={() => setEdit(true)} />}
                    {edit && <TButton id="cancel" disabled={updating} className="ml-2" onClick={() => handleCancel()} color="warning" />}
                    {edit && <TButton id="save" loading={updating} disabled={updating} className="ml-2" onClick={() => handleSave()} color="success" />}
                  </div>
                </div>
              </ProtectedComponent>
            </CardFooter>
          </Card>
          <Modal isOpen={modalOpen} fade={false} toggle={() => setModalOpen(false)}>
            <ModalBody>
              <T id='archiveContent' />
              {archiveError && <ErrAlert error={archiveError} className="mb-0 mt-2" />}
            </ModalBody>
            <ModalFooter>
              <TButton id="archiveCancel"
                disabled={updating}
                loading={updating}
                onClick={() => setModalOpen(false)} />
              <TButton id={`archiveConfirm`}
                loading={archive} disabled={archive} className="ml-2" color="danger" onClick={() => archiveWarehouse()} />
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  )
}

export default WarehouseEdit
