import React from 'react'
import {
  Nav,
  NavItem,
  TabContent,
  NavLink,
  TabPane
} from 'reactstrap'
import { Redirect, Switch, NavLink as RRNavLink } from 'react-router-dom'

import Route from 'components/Route'
import { T } from 'components/TComponents'
import ProtectedComponent from 'components/ProtectedComponent'

import Calendar from './Calendar'
import Profile from './Profile'
import TriggersList from './TriggersList'
import TriggersNew from './TriggersNew'

const Account = ({ match }) => {

  return (
    <div className="container-fluid">
      <Nav tabs className="mb-3">
        <div className="navitem-container">
          <NavItem>
            <NavLink
              tag={RRNavLink}
              activeClassName="active"
              to="/account/profile">
              <T id="profile" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={RRNavLink}
              activeClassName="active"
              to="/account/calendar">
              <T id="calendar" />
            </NavLink>
          </NavItem>
          <ProtectedComponent rights={['user-triggers_personal_edit']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/account/triggers">
                <T id="alerts" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
        </div>
      </Nav>

      <TabContent>
        <Switch>
          <TabPane tag={Redirect} exact from={`${match.path}/`} to={`account/profile`} />
          <TabPane tag={Route} path={`${match.path}/profile`} component={Profile} />
          <TabPane tag={Route} path={`${match.path}/calendar`} component={Calendar} />
          <TabPane tag={Route} path={`${match.path}/triggers/new`} component={TriggersNew} />
          <TabPane tag={Route} path={`${match.path}/triggers`} component={TriggersList} />
          <TabPane tag={Redirect} to={`/error/`} />
        </Switch>
      </TabContent>
    </div>
  )
}

export default Account
