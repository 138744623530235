import React from 'react'
import { FormattedDate } from 'react-intl'
import { useLocation, useHistory, NavLink } from 'react-router-dom'
import { objectToQuery, queryToObject } from 'react-rest-api'

import {
  Card, CardHeader,
  Row, Col,
} from 'reactstrap'

import { useGet } from 'hooks/useApi.hooks'
import { useFormatMessage } from 'hooks/intl.hooks'

import ProtectedComponent from 'components/ProtectedComponent'
import { T, ErrAlert, TButton, } from 'components/TComponents'
import { Table } from 'components/Table'
import SlotSelector from 'components/SlotSelector'
import { Filters } from 'components/Filters'

import AttachmentsModal from './AttachmentsModal'

const EquipmentList = () => {
  const location = useLocation()
  const history = useHistory()
  const formatMesage = useFormatMessage()

  const filters = React.useMemo(() => ({
    p: {
      type: 'number'
    },
    ipp: {
      type: 'number'
    },
    'order[column]': {},
    'order[dir]': {},
    filter: {
      type: 'string',
      componentType: 'textDebounce'
    },
    businessId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false,
        getOptionValue: option => option.label,
        isClearable: false,
        isSearchable: true,
      },
    },
    categoryId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false
      },
    },
    familyId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false
      },
    },
    statusId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: true,
        getOptionLabel: option => formatMesage({ id: `equipmentStatus.${option.label}`, raw: true })
      }
    },
    tagId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: true
      },
    },
    hasAlert: {
      type: 'boolean',
      componentType: 'checkbox'
    },
  }), [formatMesage])

  const moreFilters = React.useMemo(() => ({
    dateCreated: {
      componentType: 'date',
      transform: value => {
        if (!value) {
          return value
        }
        const date = new Date(value)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      },
      componentOptions: {
        placeholderText: formatMesage({ id: `filters.dateCreated.placeholder` }),
        customInput: <SlotSelector isCustomClearable={true} />,
        dateFormat: 'dd/MM/yyyy'
      }
    },
    dateIntervention: {
      componentType: 'date',
      transform: value => {
        if (!value) {
          return value
        }
        const date = new Date(value)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      },
      componentOptions: {
        placeholderText: formatMesage({ id: `filters.dateIntervention.placeholder` }),
        customInput: <SlotSelector isCustomClearable={true} />,
        dateFormat: 'dd/MM/yyyy'
      }
    },
    addressId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false
      },
    },
  }), [formatMesage])

  // DATA
  const params = React.useMemo(() => queryToObject(location.search, { ...filters, ...moreFilters }), [location.search, filters, moreFilters])

  const [data, refreshing, error, loading] = useGet(`/equipments/list`, undefined, params)

  const refresh = React.useCallback((_params = {}) => history.push({ search: objectToQuery({ ...params, ..._params }) }), [history, params])

  // INNER COMPONENTS
  const DateCreated = React.useCallback(item => (
    <span><FormattedDate value={new Date(item.dateCreated)} /></span>
  ), [])

  const DateUpdated = React.useCallback(item => (
    <span>{item.dateIntervention ? <FormattedDate value={new Date(item.dateIntervention)} /> : ''}</span>
  ), [])

  const Tags = React.useCallback(item => {
    return (
      item.tags && item.tags.length > 0 && item.tags.map(t => {
        return (<div key={t.tagId} className="mb-1 text-center color-tag-rounded" style={{ backgroundColor: t.colorHex ? t.colorHex : '#fff', color: t.colorHex ? '#fff' : '#000' }}>
          {t.tagIdentifier}
        </div>)
      })
    )
  }, [])

  const DetailsButton = React.useCallback(item => (
    <TButton className="ml-auto stretched-link" outline
      id="view"
      tag={NavLink}
      to={{
        pathname: `./equipments/${item.equipmentId}`,
        state: { equipmentsState: location.state }
      }} />
  ), [location])

  // RENDER
  if (error) {
    return <ErrAlert error={error} />
  }

  return (
    <div className="container-fluid">
      <Card className="mb-2">
        <CardHeader>
          <Filters
            loading={loading}
            disabled={refreshing}
            data={data}
            refresh={refresh}
            filters={filters}
            moreFilters={moreFilters}
          />
        </CardHeader>
        {data && data.businessInfo && <CardHeader>
          <Row>
            <T tagName={Col} sm="2" id="businessInfo.accountName" values={{ value: data.businessInfo.accountName }} />
            <T tagName={Col} sm="2" id="businessInfo.businessContactName" values={{ value: data.businessInfo.businessContactName }} />
          </Row>
          <Row>
            <T tagName={Col} sm="2" id="businessInfo.phoneNumber1" values={{ value: data.businessInfo.phoneNumber1 }} />
            {data.businessInfo.phoneNumber2 && <T tagName={Col} sm="2" id="businessInfo.phoneNumber2" values={{ value: data.businessInfo.phoneNumber2 }} />}
          </Row>
          <Row>
            <T tagName={Col} id="businessInfo.addressFull" values={{ value: data.businessInfo.addressFull }} />
          </Row>
        </CardHeader>}
        {data && data.filters && data.filters.businessId && data.filters.businessId.selected && <ProtectedComponent rights={['equipments_import', 'equipments-attachments_view']}>
          <CardHeader className="d-flex px-2">
            <div>
              <ProtectedComponent rights={['equipments_import']}>
                <TButton className="mx-2" color="primary" id="import" tag={NavLink} to={`equipments/import/${data.filters.businessId.selected.value}`} />
              </ProtectedComponent>
              <ProtectedComponent rights={['equipments-attachments_view']}>
                <AttachmentsModal businessId={data.filters.businessId.selected.value} />
              </ProtectedComponent>
              <ProtectedComponent rights={['equipments_new']}>
                <TButton className="mx-2" id="new" tag={NavLink} to="equipments/add" />
              </ProtectedComponent>
            </div>
            <div className="ml-auto">
              <ProtectedComponent rights={['equipments_mass_change']}>
                <TButton className="mx-2" id="changeStatus" tag={NavLink} to={`equipments/status/${data.filters.businessId.selected.value}`} />
              </ProtectedComponent>
            </div>
          </CardHeader>
        </ProtectedComponent>}
      </Card>
      <Table
        isLoading={loading}
        disabled={refreshing}
        data={data}
        refresh={refresh}
        columns={[
          {
            title: 'equipmentIdentifier',
            hasOrder: true,
            render: item => item.equipmentIdentifier
          },
          {
            title: 'categoryName',
            hasOrder: true,
            render: item => item.categoryName
          },
          {
            title: 'familyName',
            hasOrder: true,
            render: item => item.familyName
          },
          {
            title: 'dateCreated',
            hasOrder: true,
            render: DateCreated
          },
          {
            title: 'dateUpdated',
            hasOrder: true,
            render: DateUpdated
          },
          {
            title: 'equipmentStatus',
            render: item => formatMesage({ id: `equipmentStatus.${item.statusValue}`, raw: true })
          },
          {
            title: 'tagIdentifier',
            render: Tags
          },
          {
            className: () => 'align-middle text-right',
            render: DetailsButton
          },
        ]}
      />
    </div>
  )
}

export default EquipmentList
