import React from 'react'
import { useHistory, useLocation, NavLink } from 'react-router-dom'
import { objectToQuery, queryToObject } from 'react-rest-api'

import {
  Alert,
  Card, CardHeader,
  FormGroup,
  Spinner
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import Pagination from 'components/Pagination'
import { TCustomInput } from 'components/TComponents'
import ProtectedComponent from 'components/ProtectedComponent'
import { T, TLabel, TInput, TButton, ErrAlert } from 'components/TComponents'

import { debounce } from 'util/Utils'

const CATEGORIES = 'categories'
const FAMILIES = 'families'

const Categories = () => {
  const { api } = React.useContext(AppContext)
  const history = useHistory()
  const location = useLocation()

  const [data, setData] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()

  const [searchInput, setSearchInput] = React.useState('')

  const params = React.useMemo(() => queryToObject(location.search, { type: { type: 'string' } }), [location.search])

  const type = React.useMemo(() => params.type || CATEGORIES, [params])

  React.useEffect(() => {
    setLoading(true)
    setError()
    Promise.all([
      api.get(`/${type}/list`),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => setData(data))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api, type])

  const refresh = React.useCallback(params => {
    setLoading(true)
    setError()
    Promise.all([
      api.get(`/${type}/list`, undefined, {
        ...data.filters,
        p: data.currentPage,
        ...params
      }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => setData(data))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api, type, data])

  const refreshDebounced = React.useMemo(() => debounce(params => refresh(params), 250), [refresh])

  const handleTypeChange = React.useCallback((e) => {
    setLoading(true)
    setSearchInput('')
    history.push({ search: objectToQuery({ type: e.target.value }) })
  }, [history])

  const handleSearchInput = React.useCallback(({ target: { value } }) => {
    setSearchInput(value)
    refreshDebounced({ filter: value, p: 1 })
  }, [refreshDebounced])

  const singularType = type === FAMILIES ? 'family' : 'category'

  return (
    <>
      <Card className="mb-2">
        <CardHeader className="pb-0">
          <FormGroup tag="fieldset">
            <legend><T id={`titleType`} /></legend>
            <FormGroup check inline>
              <TCustomInput id="categoriesRadio"
                type="radio"
                checked={type === CATEGORIES}
                name="type"
                value={CATEGORIES}
                label="categories.type"
                onChange={handleTypeChange}
                inline />
              <TCustomInput id="familiesRadio"
                type="radio"
                checked={type === FAMILIES}
                name="type"
                value={FAMILIES}
                label="families.type"
                onChange={handleTypeChange}
                inline />
            </FormGroup>
          </FormGroup>
          <FormGroup className="flex-grow-1 pl-0 mr-2">
            <TLabel check className="sr-only" for="filter" id={`${type}.filter.label`} />
            <TInput
              name="filter"
              type="text"
              placeholder={`${type}.filter.placeholder`}
              value={searchInput}
              onChange={handleSearchInput} />
          </FormGroup>
          <ProtectedComponent rights={['admin_archive']}>
            <FormGroup className="flex-grow-1 pl-0">
              <TCustomInput type="switch"
                id="archived"
                name="archived"
                label={`${type}.archived`}
                className="w-100 bg-transparent border-0"
                checked={data ? data.filters.archived : false}
                onChange={e => refresh({ archived: e.target.checked, p: 1 })} />
            </FormGroup>
          </ProtectedComponent>
        </CardHeader>
        <ProtectedComponent rights={['admin-categories_edit']}>
          <CardHeader>
            <TButton tag={NavLink}
              className="mr-2"
              to={`/admin/categories/${type === 'families' ? 'families/' : ''}new`} id={`${type}.create`} />
          </CardHeader>
        </ProtectedComponent>
      </Card>
      {!loading && error && <ErrAlert error={error} />}

      <legend><T id={`${type}.label`} /></legend>
      {!loading && data.result.length < 1 && <Alert color="secondary">
        <T id={`${type}.noResult`} />
      </Alert>}
      {loading && <Spinner className="d-flex mx-auto" color="primary" />}
      {!loading && data.result.map(element => <Card key={element[`${singularType}Id`]} className="mb-2">
        <CardHeader className="d-flex align-items-center">
          {element.archived && <i className="mr-1 mb-0 h5 iconsmind-Box-Close text-primary" />}
          <span className="h5 mb-0">{element[`${singularType}Name`]}</span>
          <TButton className="ml-auto stretched-link" outline
            tag={NavLink}
            to={`./categories/${type === 'families' ? 'families/' : ''}${element[singularType + 'Id']}`} id={`${type}.view`} />
        </CardHeader>
      </Card>)}

      {data && <Pagination totalPage={data.totalPage} currentPage={data.currentPage} onPageClick={p => refresh({ p })} />}
    </>
  )
}

export default Categories
