import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  Card, CardBody, CardHeader,
  FormGroup, Spinner
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import ProtectedComponent from 'components/ProtectedComponent'
import { TCustomInput, TAlert, TLabel, TInput, TButton } from 'components/TComponents'
import Pagination from 'components/Pagination'

import { debounce } from 'util/Utils'

const ShopList = () => {
  const { api } = React.useContext(AppContext)

  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()

  const [filter, setFilter] = React.useState('')

  React.useEffect(() => {
    setLoading(true)
    api.get('/warehouses/list', undefined)
      .then(response => setData(response))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api])

  const refresh = React.useCallback(params => {
    setLoading(true)
    setError()
    Promise.all([
      api.get('/warehouses/list', undefined, {
        ...data.filters,
        p: data.currentPage,
        ...params
      }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => setData(data))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api, data])

  const refreshDebounced = React.useMemo(() => debounce(params => refresh(params), 250), [refresh])

  const handleSearchInput = React.useCallback(({ target: { value } }) => {
    setFilter(value)
    refreshDebounced({ filter: value, p: 1 })
  }, [refreshDebounced])

  return (
    <>
      <Card className="mb-2">
        <CardHeader>
          <FormGroup className="flex-grow-1 pl-0">
            <TLabel id="warehousesFilterTitle" className="sr-only" for="warehouseFilter" />
            <TInput
              name="warehouseFilter"
              type="text"
              placeholder="warehouseFilterPlaceholder"
              value={filter}
              onChange={handleSearchInput} />
          </FormGroup>
          <FormGroup className="flex-grow-1 pl-0 mb-0">
            <TCustomInput type="switch"
              id="archived"
              name="archived"
              label="archived"
              className="w-100 bg-transparent border-0"
              disabled={loading}
              checked={data && data.filters ? data.filters.archived : false}
              onChange={e => refresh({ archived: e.target.checked, p: 1 })} />
          </FormGroup>
        </CardHeader>
        <ProtectedComponent rights={['veloparts-warehouses_edit']}>
          <CardHeader>
            <TButton id="createShop"
              tag={NavLink}
              to="/veloparts/shops/new" />
          </CardHeader>
        </ProtectedComponent>
        <CardBody>
          {error && <TAlert color="danger" id={error.code} />}
          {loading && <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />}
          {!loading && !error && data.result.map(warehouse => (
            <Card key={warehouse.warehouseId} className="mb-2">
              <CardHeader className="d-flex align-items-center">
                <span className="h6 mb-0">
                  {warehouse.dateArchived && <i className="mr-1 iconsmind-Lock text-danger" />}
                  {warehouse.warehouseRef}&nbsp;-&nbsp;{warehouse.warehouseLabel}
                </span>
                <TButton id="viewWarehouse" className="ml-auto stretched-link" outline
                  tag={NavLink}
                  to={`/veloparts/shops/${warehouse.warehouseId}`} />
              </CardHeader>
            </Card>

          ))}
          {!loading && (!data || (data && data.result && data.result.length === 0)) && <TAlert id="noResult" color="warning" />}
        </CardBody>
      </Card>
      {data && <Pagination totalPage={data.totalPage} currentPage={data.currentPage} onPageClick={p => refresh({ p })} />}
    </>

  )
}

export default ShopList
