import React from 'react'
import { useParams, useHistory, useLocation, Link } from 'react-router-dom'
import { FormattedDate } from 'react-intl'
import { objectToQuery } from 'react-rest-api'
import moment from 'moment-timezone'

import 'react-datepicker/dist/react-datepicker.css'

import { useHasRights } from 'components/ProtectedComponent'
import {
  Spinner,
  ListGroup, ListGroupItem,
  InputGroup, FormGroup,
  Modal, ModalBody, ModalFooter,
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'
import { useFormatMessage } from 'hooks/intl.hooks'

import { NotificationManager } from 'components/ReactNotifications'
import SlotSelector from 'components/SlotSelector'
import PlanningItem from 'components/PlanningItem'
import PlanningItemModal from 'components/PlanningItemModal'
import Timeline from 'components/react-calendar-timeline'
import 'components/react-calendar-timeline/lib/Timeline.scss'

import { T, TButton, TNavLink, TCustomInput, ErrAlert, TDatePicker } from 'components/TComponents'

import './Planning.scss'

const bounds = { min: 6, max: 20, nbDays: 7 }

const PlanningUser = () => {
  const routeParams = useParams()
  const history = useHistory()
  const location = useLocation()
  const hasInterventionsRemind = useHasRights('interventions_remind')
  const formatMessage = useFormatMessage()

  const { api, constants, timeStartWork, timeEndWork } = React.useContext(AppContext)

  const [plannings, setPlannings] = React.useState()
  const [planningsUsers, setPlanningsUsers] = React.useState()
  const [planningsLoad, setPlanningsLoad] = React.useState(true)
  const [planningsError, setPlanningsError] = React.useState()
  // const [planningDuration, setPlanningDuration] = React.useState(planningDurations[0])

  const planningDuration = (location.state && location.state.p) || ''

  const [dateSelected, setDateSelected] = React.useState(() => {
    const { d } = { ...location.state }
    return d ? new Date((new Date(d)).setHours(bounds.min, 0, 0)) : new Date((new Date()).setHours(bounds.min, 0, 0))
  })

  const [itemSelected, setItemSelected] = React.useState()
  const [scheduleError, setScheduleError] = React.useState()

  const [planning, setPlanning] = React.useState()

  // Current old states
  const [dropping, setDropping] = React.useState(false)

  React.useEffect(() => {
    const dateEnd = new Date(new Date(dateSelected).setDate(dateSelected.getDate() + 6))
    const dayStart = `${dateSelected.getFullYear()}-${dateSelected.getMonth() + 1}-${dateSelected.getDate()}`
    const dayEnd = `${dateEnd.getFullYear()}-${dateEnd.getMonth() + 1}-${dateEnd.getDate()}`
    setPlanningsError()
    setPlanningsLoad(true)
    api.get('/plannings/view', undefined, { dayStart, dayEnd, userId: routeParams.id, planningDuration })
      .then(response => setPlannings(response))
      .catch(response => setPlanningsError(response))
      .then(() => setPlanningsLoad(false))
    api.get('/plannings/view', undefined, { userTypeId: 0 })
      .then(response => setPlanningsUsers(response))
  }, [api, dateSelected, routeParams.id, planningDuration])

  React.useEffect(() => {
    history.replace({ search: objectToQuery({ ...location.state }), state: location.state })
  }, [history, location.state])

  const getTimeStart = React.useCallback((item, planningDuration, datePlanned, dateDone) => {
    switch (planningDuration) {
    // durationDone
    case '4': return (
      dateDone - item.durationDone * 1000 * 60
    )
      // durationEstimated
    case '1': return (
      datePlanned
    )
      // durationChrono
    case '3': return (
      dateDone - item.durationChrono * 1000 * 60
    )
      // durationPlanned
    case '2': return (
      datePlanned ? datePlanned : dateDone - item.durationPlanned * 1000 * 60
    )
    }
  }, [])

  const getTimeEnd = React.useCallback((item, planningDuration, datePlanned, dateDone) => {
    switch (planningDuration) {
    // durationDone
    case '4': return (
      dateDone
    )
      // durationEstimated
    case '1': return (
      datePlanned + item.durationEstimated * 1000 * 60
    )
      // durationChrono
    case '3': return (
      dateDone
    )
      // durationPlanned
    case '2': return (
      datePlanned ? datePlanned + item.durationPlanned * 1000 * 60 : dateDone
    )
    }
  }, [])

  const getGroup = React.useCallback((groups, intervention, planningDuration) => {
    switch (planningDuration) {
    // durationDone
    case '4': return (
    // dateDone
      groups.find(group => new Date(group.day).setHours(0, 0, 0, 0) === new Date(intervention.dateDone).setHours(0, 0, 0, 0))
    )
      // durationEstimated
    case '1': return (
    // datePlanned + item[planningDuration] * 1000 * 60
      groups.find(group => new Date(group.day).setHours(0, 0, 0, 0) === new Date(intervention.datePlanned).setHours(0, 0, 0, 0))
    )
      // durationChrono
    case '3': return (
      groups.find(group => new Date(group.day).setHours(0, 0, 0, 0) === new Date(intervention.dateDone).setHours(0, 0, 0, 0))
    // dateDone
    )
      // durationPlanned
    case '2': return (
    // datePlanned ? datePlanned + item[planningDuration] * 1000 * 60 : dateDone
      groups.find(group => new Date(group.day).setHours(0, 0, 0, 0) === new Date(intervention.datePlanned).setHours(0, 0, 0, 0))
    )
    }
  }, [])

  React.useEffect(() => {
    if (plannings) {
      const user = plannings.users[0]

      const getInterventionStyle = intervention => {
        const style = {}
        if ([statusConst.done, statusConst.canceled, statusConst.closed].includes(intervention.statusId)) {
          style['backgroundColor'] = 'rgba(0, 0, 0, .4)'
        } else if (intervention.statusId === statusConst.scheduled && (new Date(intervention.datePlanned).getTime() + intervention.durationPlanned * 1000 * 60) < Date.now()) {
          style['backgroundColor'] = 'rgba(255, 0, 0, .4)'
        }
        return style
      }

      const getSwapStyle = swap => {
        const style = {}
        if ([swapStatusConst.done, swapStatusConst.canceled].includes(swap.statusId)) {
          style.background = 'repeating-linear-gradient(125deg, rgba(246, 186, 82, .5), rgba(246, 186, 82, .5) 50%, rgba(0, 0, 0, .4) 50%, rgba(0, 0, 0, .4) 100%)'
        } else if (swap.statusId === swapStatusConst.scheduled && (new Date(swap.datePlanned).getTime() + swap.durationPlanned * 1000 * 60) < Date.now()) {
          style.background = 'repeating-linear-gradient(125deg, rgba(255, 0, 0, .5), rgba(255, 0, 0, .5) 50%, rgba(255, 209, 128, .4) 50%, rgba(255, 209, 128, .4) 100%)'
        } else {
          style.background = 'repeating-linear-gradient(125deg, rgba(246, 186, 82, 1), rgba(246, 186, 82, 1) 50%, rgba(255, 209, 128, 1) 50%, rgba(255, 209, 128, 1) 100%)'
        }
        return style
      }

      if (!user) {
        return {
          planning: {
            dayStart: new Date().setHours(timeStartWork, 0),
            dayEnd: new Date().setHours(timeEndWork),
            groups: [],
            items: [],
          }
        }
      }

      const dayStart = new Date(plannings.filters.dayStart)
      const dateSelected = new Date(new Date(dayStart).setHours(6, 0))

      const days = Array(bounds.nbDays).fill().map((u, i) => new Date(new Date(dateSelected).setDate(dateSelected.getDate() + i)))

      const groups = days.map((day, id) => ({
        id, day,
        title: <FormattedDate value={new Date(day)} year="numeric" month="2-digit" day="2-digit" weekday="long" />
      }))

      const planningDuration = plannings && plannings.filters.planningDuration.selected.value.toString()

      const isSameDate = (startDate, endDate) => {
        const startMoment = moment(startDate).clone().startOf('day')
        const endMoment = moment(endDate).clone().startOf('day')
        return startMoment.diff(endMoment, 'days') == 0;
      }

      const filteredStartedItvs = items => {
        return items
          .filter(intervention => planningDuration !== '3' || (planningDuration === '3' && getTimeEnd(intervention, planningDuration, new Date(intervention.datePlanned).setFullYear(dayStart.getFullYear(), dayStart.getMonth(), dayStart.getDate()), new Date(intervention.dateDone).setFullYear(dayStart.getFullYear(), dayStart.getMonth(), dayStart.getDate())) !== 0))
          .filter(intervention => planningDuration !== '4' || (planningDuration === '4' && getTimeEnd(intervention, planningDuration, new Date(intervention.datePlanned).setFullYear(dayStart.getFullYear(), dayStart.getMonth(), dayStart.getDate()), new Date(intervention.dateDone).setFullYear(dayStart.getFullYear(), dayStart.getMonth(), dayStart.getDate())) !== 0))
          .filter(intervention => planningDuration !== '3' || (planningDuration === '3' && isSameDate(new Date(intervention.dateDone), new Date(plannings.filters.dayStart))))
          .filter(intervention => planningDuration !== '4' || (planningDuration === '4' && isSameDate(new Date(intervention.dateDone), new Date(plannings.filters.dayStart))))
      }

      const items = []
        .concat(filteredStartedItvs(user.interventions).map(intervention => {
          const group = getGroup(groups, intervention, planningDuration)

          if (!group) {
            return undefined
          }

          const datePlanned = new Date(intervention.datePlanned).setFullYear(dayStart.getFullYear(), dayStart.getMonth(), dayStart.getDate())
          const dateDone = new Date(intervention.dateDone).setFullYear(dayStart.getFullYear(), dayStart.getMonth(), dayStart.getDate())

          return {
            id: `timelineItem-itv-${intervention.interventionId}`,
            type: 'intervention',
            // group: 1,
            group: group.id,
            canMove: datePlanned > new Date().setHours(0, 0, 0, 0) && ![statusConst.closed, statusConst.done, statusConst.canceled].includes(intervention.statusId),
            className: intervention.difficultyValue,
            timeStart: getTimeStart(intervention, planningDuration, datePlanned, dateDone),
            timeEnd: getTimeEnd(intervention, planningDuration, datePlanned, dateDone),
            style: getInterventionStyle(intervention),
            onItemClick: setItemSelected,
            intervention
          }
        }))
        .concat(filteredStartedItvs(user.swaps).map(swap => {
          const group = groups.find(group => new Date(group.day).setHours(0, 0, 0, 0) === new Date(swap.datePlanned).setHours(0, 0, 0, 0))

          if (!group) {
            return undefined
          }

          const datePlanned = new Date(swap.datePlanned).setFullYear(dayStart.getFullYear(), dayStart.getMonth(), dayStart.getDate())
          const dateDone = new Date(swap.dateDone).setFullYear(dayStart.getFullYear(), dayStart.getMonth(), dayStart.getDate())

          return {
            id: `timelineItem-swap-${swap.swapId}`,
            type: 'swap',
            group: group.id,
            canMove: datePlanned > new Date().setHours(0, 0, 0, 0) && ![swapStatusConst.closed, swapStatusConst.done, swapStatusConst.canceled].includes(swap.statusId),
            timeStart: getTimeStart(swap, planningDuration, datePlanned, dateDone),
            timeEnd: getTimeEnd(swap, planningDuration, datePlanned, dateDone),
            style: getSwapStyle(swap),
            onItemClick: setItemSelected,
            swap
          }
        }))
        .concat(user.appointments.map(appointment => {
          const group = groups.find(group => new Date(group.day).setHours(0, 0, 0, 0) === new Date(appointment.dateAppointment).setHours(0, 0, 0, 0))

          if (!group) {
            return undefined
          }

          const datePlanned = new Date(appointment.dateAppointment).setFullYear(dayStart.getFullYear(), dayStart.getMonth(), dayStart.getDate())

          return {
            id: `timelineItem-app-${appointment.appointmentId}`,
            type: 'appointment',
            group: group.id,
            canMove: datePlanned > new Date().setHours(0, 0, 0, 0) && appointment.statusId !== appointmentStatus.canceled,
            timeStart: datePlanned,
            timeEnd: datePlanned + appointment.durationPlanned * 1000 * 60,
            style: { 'backgroundColor': 'rgba(125, 0, 125, .4)' },
            onItemClick: setItemSelected,
            appointment
          }
        }))
        .concat(user.unavailabilities.reduce((acc, unavailability) => {
          const days = groups.filter(group => true
            && new Date(group.day).setHours(24, 0, 0, 0) > new Date(unavailability.dateStart)
            && new Date(group.day).setHours(0, 0, 0, 0) < new Date(unavailability.dateEnd)
          )

          return acc.concat(days.map(day => {
            const timeStart = Math.max(new Date(day.day).setHours(6, 0, 0, 0), new Date(unavailability.dateStart))
            const timeEnd = Math.min(new Date(day.day).setHours(20, 0, 0, 0), new Date(unavailability.dateEnd))

            return {
              id: `timelineItem-unv-${unavailability.unavailabilityId}-${day.id}`,
              type: 'unavailability',
              group: day.id,
              canMove: false,
              timeStart: new Date(timeStart).setFullYear(dayStart.getFullYear(), dayStart.getMonth(), dayStart.getDate()),
              timeEnd: new Date(timeEnd).setFullYear(dayStart.getFullYear(), dayStart.getMonth(), dayStart.getDate()),
              stackable: false,
              style: {
                background: 'repeating-linear-gradient(135deg,#eeeeee,#eeeeee 10px,#cacaca 10px,#cacaca 20px)',
                zIndex: 0
              },
              unavailability
            }
          }))
        }, []))
        .concat(filteredStartedItvs(user.maintenanceplans).map(maintenance => {
          const group = getGroup(groups, maintenance, planningDuration)

          if (!group) {
            return undefined
          }

          const datePlanned = new Date(maintenance.datePlanned).setFullYear(dayStart.getFullYear(), dayStart.getMonth(), dayStart.getDate())
          const dateDone = new Date(maintenance.dateDone).setFullYear(dayStart.getFullYear(), dayStart.getMonth(), dayStart.getDate())

          return {
            id: `timelineItem-mai-${maintenance.maintenanceplanId}`,
            type: 'maintenance',
            // group: 1,
            group: group.id,
            canMove: datePlanned > new Date().setHours(0, 0, 0, 0) && ![maintenanceStatus.closed, maintenanceStatus.done, maintenanceStatus.canceled].includes(maintenance.statusId),
            className: maintenance.difficultyValue,
            timeStart: getTimeStart(maintenance, planningDuration, datePlanned, dateDone),
            timeEnd: getTimeEnd(maintenance, planningDuration, datePlanned, dateDone),
            style: { 'backgroundColor': 'rgba(226, 134, 59, .4)' },
            onItemClick: setItemSelected,
            maintenance
          }
        }))
        .filter(item => item)

      setPlanning({
        dayStart: new Date(dayStart).setHours(6, 0),
        dayEnd: new Date(dayStart).setHours(20),
        userId: user.userId,
        userName: user.userName,
        groups,
        items,
      })
    }
  }, [plannings, statusConst, getTimeEnd, getTimeStart, getGroup, swapStatusConst, appointmentStatus, maintenanceStatus, timeEndWork, timeStartWork])


  const statusConst = React.useMemo(() => constants.interventionStatus.reduce((acc, status) => ({ ...acc, [status.key]: status.value }), {}), [constants])
  const swapStatusConst = React.useMemo(() => constants.swapStatus.reduce((acc, status) => ({ ...acc, [status.key]: status.value }), {}), [constants])
  const appointmentStatus = React.useMemo(() => constants.appointmentStatus.reduce((acc, status) => ({ ...acc, [status.key]: status.value }), {}), [constants])
  const maintenanceStatus = React.useMemo(() => constants.maintenanceplansStatus.reduce((acc, status) => ({ ...acc, [status.key]: status.value }), {}), [constants])

  const scheduleIntervention = React.useCallback((itemState) => {
    if (!itemState) {
      setScheduleError({ code: 'unknowtechnical' })
      return Promise.reject(new Error())
    }

    const dateStart = new Date(dateSelected)
    const dateEnd = new Date(new Date(dateStart).setDate(dateStart.getDate() + 7))

    const dayStart = `${dateStart.getFullYear()}-${dateStart.getMonth() + 1}-${dateStart.getDate()}`
    const dayEnd = `${dateEnd.getFullYear()}-${dateEnd.getMonth() + 1}-${dateEnd.getDate()}`

    setScheduleError()
    return api.post('/interventions/schedule', {
      body: JSON.stringify({
        interventionId: itemState.id,
        datePlanned: itemState.date,
        userId: itemState.userId,
        duration: itemState.duration,
        force: itemState.force
      })
    })
      .then(() => api.get('/plannings/view', undefined, { dayStart, dayEnd, userId: itemState.userId }))
      .then(response => setPlannings(response))
  }, [api, dateSelected])

  const scheduleSwap = React.useCallback((itemState) => {
    if (!itemState) {
      setScheduleError({ code: 'unknowtechnical' })
      return Promise.reject(new Error())
    }

    const dateStart = new Date(dateSelected)
    const dateEnd = new Date(new Date(dateStart).setDate(dateStart.getDate() + 7))

    const dayStart = `${dateStart.getFullYear()}-${dateStart.getMonth() + 1}-${dateStart.getDate()}`
    const dayEnd = `${dateEnd.getFullYear()}-${dateEnd.getMonth() + 1}-${dateEnd.getDate()}`

    setScheduleError()
    return api.post('/swaps/schedule', {
      body: JSON.stringify({
        swapId: itemState.id,
        datePlanned: itemState.date,
        userId: itemState.userId,
        duration: itemState.duration,
        force: itemState.force
      })
    })
      .then(() => api.get('/plannings/view', undefined, { dayStart, dayEnd, userId: itemState.userId }))
      .then(response => setPlannings(response))
  }, [api, dateSelected])

  const scheduleAppointment = React.useCallback((itemState) => {
    if (!itemState) {
      setScheduleError({ code: 'unknowtechnical' })
      return Promise.reject(new Error())
    }

    const dateStart = new Date(dateSelected)
    const dateEnd = new Date(new Date(dateStart).setDate(dateStart.getDate() + 7))

    const dayStart = `${dateStart.getFullYear()}-${dateStart.getMonth() + 1}-${dateStart.getDate()}`
    const dayEnd = `${dateEnd.getFullYear()}-${dateEnd.getMonth() + 1}-${dateEnd.getDate()}`

    return api.post('/appointments/schedule', {
      body: JSON.stringify({
        appointmentId: itemState.id,
        dateAppointment: itemState.date,
        userId: itemState.userId,
        duration: itemState.duration,
      })
    })
      .then(() => api.get('/plannings/view', undefined, { dayStart, dayEnd, userId: itemState.userId }))
      .then(response => setPlannings(response))
  }, [api, dateSelected])

  const scheduleMaintenance = React.useCallback((itemState) => {
    if (!itemState) {
      setScheduleError({ code: 'unknowtechnical' })
      return Promise.reject(new Error())
    }

    const dateStart = new Date(dateSelected)
    const dateEnd = new Date(new Date(dateStart).setDate(dateStart.getDate() + 7))

    const dayStart = `${dateStart.getFullYear()}-${dateStart.getMonth() + 1}-${dateStart.getDate()}`
    const dayEnd = `${dateEnd.getFullYear()}-${dateEnd.getMonth() + 1}-${dateEnd.getDate()}`

    return api.post('/maintenanceplans/schedule', {
      body: JSON.stringify({
        maintenanceplanId: itemState.id,
        datePlanned: itemState.date,
        userId: itemState.userId,
        duration: itemState.duration,
      })
    })
      .then(() => api.get('/plannings/view', undefined, { dayStart, dayEnd, userId: itemState.userId }))
      .then(response => setPlannings(response))
  }, [api, dateSelected])

  const handleInterventionDrop = React.useCallback((group, time, item) => {
    setDropping(true)

    const { userId, groups } = planning
    const _group = groups.find(gr => gr.id === group)

    if (!_group) {
      // TODO: trigger error ?
      return
    }

    const timeDate = new Date(time)
    const date = new Date(new Date(_group.day).setHours(timeDate.getHours(), timeDate.getMinutes()))

    switch (item.type) {
    case 'intervention': {
      const constructItem = {
        id: item.intervention.interventionId,
        date: date,
        userId,
        duration: item.intervention.durationPlanned
      }
      scheduleIntervention(constructItem)
        .catch(response => setScheduleError({ ...response, item: constructItem }))
        .then(() => setDropping(false))
      break;
    }
    case 'swap': {
      const constructItem = {
        id: item.swap.swapId,
        date: date,
        userId,
        duration: item.swap.durationPlanned
      }
      scheduleSwap(constructItem)
        .catch(response => setScheduleError({ ...response, item: constructItem }))
        .then(() => setDropping(false))
      break;
    }
    case 'appointment': {
      const constructItem = {
        id: item.appointment.appointmentId,
        date: date,
        userId,
        duration: item.appointment.durationPlanned
      }
      scheduleAppointment(constructItem)
        .catch(response => setScheduleError({ ...response, item: constructItem }))
        .then(() => setDropping(false))
      break;
    }
    case 'maintenance': {
      const constructItem = {
        id: item.maintenance.maintenanceplanId,
        date: date,
        userId,
        duration: item.maintenance.durationPlanned
      }
      scheduleMaintenance(constructItem)
        .catch(response => setScheduleError({ ...response, item: constructItem }))
        .then(() => setDropping(false))
      break;
    }
    }
  }, [planning, scheduleIntervention, scheduleSwap, scheduleAppointment, scheduleMaintenance])

  const handlePlanningDuration = React.useCallback(e => {
    const searchParams = new URLSearchParams(location.search)
    const locationState = {
      c: searchParams.get('c'),
      d: searchParams.get('d'),
      t: searchParams.get('t'),
      p: searchParams.get('p'),
      user: searchParams.get('user')
    }
    history.replace({
      search: location.search,
      state: { ...locationState, p: e.target.value }
    })
    // setLoadPlanningEnable(true)
  }, [history, location.search])

  const handleDateChange = React.useCallback((date) => {
    setDateSelected(date)
    history.push({
      search: location.search,
      state: { ...location.state, d: date }
    })
  }, [history, location.search, location.state])

  const handleItemSelectedSave = React.useCallback(itemState => {
    const newDate = new Date(itemState.date)
    const newDuration = String(itemState.duration)
    switch (itemSelected.type) {
    case 'intervention': {
      const oldDate = new Date(itemSelected.intervention.datePlanned)
      const oldDuration = String(itemSelected.intervention.durationPlanned)
      return scheduleIntervention(itemState)
        .then(() => {
          if (hasInterventionsRemind && (oldDate.getTime() !== newDate.getTime() || oldDuration !== newDuration)) {
            setItemSelected({
              type: 'intervention',
              intervention: {
                interventionId: itemState.id,
                userId: itemState.userId,
                datePlanned: itemState.date,
                durationPlanned: itemState.duration
              },
              remind: true
            })
          } else {
            setItemSelected()
          }
        })
    }
    case 'swap': {
      const oldDate = new Date(itemSelected.swap.datePlanned)
      const oldDuration = String(itemSelected.swap.durationPlanned)
      return scheduleSwap(itemState)
        .then(() => {
          if (hasInterventionsRemind && (oldDate.getTime() !== newDate.getTime() || oldDuration !== newDuration)) {
            setItemSelected({
              type: 'swap',
              swap: {
                swapId: itemState.id,
                userId: itemState.userId,
                datePlanned: itemState.date,
                durationPlanned: itemState.duration
              },
              remind: true
            })
          } else {
            setItemSelected()
          }
        })
    }
    case 'appointment': return scheduleAppointment(itemState).then(() => setItemSelected())
    case 'maintenance': return scheduleMaintenance(itemState).then(() => setItemSelected())
    default: return Promise.resolve().then(() => setItemSelected())
    }
  }, [itemSelected, scheduleIntervention, scheduleSwap, scheduleAppointment, scheduleMaintenance, hasInterventionsRemind])

  const handleItemRemind = React.useCallback(() => {
    switch (itemSelected.type) {
    case 'intervention': {
      return Promise.all([
        api.post('/interventions/reminder', { body: JSON.stringify({ interventionId: itemSelected.intervention.interventionId }) }),
        new Promise(resolve => setTimeout(resolve, 250))
      ])
        .then(() => {
          setItemSelected()
          NotificationManager.success(formatMessage({ id: 'remind.success' }))
        })
    }
    case 'swap': {
      return Promise.all([
        api.post('/swaps/reminder', { body: JSON.stringify({ swapId: itemSelected.swap.swapId }) }),
        new Promise(resolve => setTimeout(resolve, 250))
      ])
        .then(() => {
          setItemSelected()
          NotificationManager.success(formatMessage({ id: 'remind.success' }))
        })
    }
    default: return Promise.resolve()
    }
  }, [api, formatMessage, itemSelected])

  if (!planning || planning.groups.length === 0) {
    return <>
      <TNavLink id="returnToPlanning"
        tag={Link}
        to={{
          pathname: '/planning',
          state: {
            ...(location.state ? location.state.planning : undefined),
            d: location.state && location.state.d ? location.state.d : undefined
          }
        }} />
      {/* TODO: <Spinner /> */}
    </>
  }

  if (planningsError) {
    return (<>
      <TNavLink id="returnToPlanning"
        tag={Link}
        to={{
          pathname: '/planning',
          state: {
            ...(location.state ? location.state.planning : undefined),
            d: location.state && location.state.d ? location.state.d : undefined
          }
        }} />
      <ErrAlert error={planningsError} />
    </>)
  }

  return (
    <div className="container-fluid Planning">
      {scheduleError && (
        <Modal isOpen={Boolean(scheduleError)} toggle={() => setScheduleError()}>
          <ModalBody>
            {scheduleError.code === 'preventUpdate'
              ? <T id={`error.${scheduleError.code}`} values={scheduleError.params} />
              : <ErrAlert error={scheduleError} className="mb-0" />}
            {scheduleError.params && scheduleError.params.reasons && (
              <ListGroup>
                {scheduleError.params.reasons.map(reason => (
                  <ListGroupItem key={reason}><T id={`error.preventUpdate.${reason}`} /></ListGroupItem>
                ))}
              </ListGroup>
            )}
          </ModalBody>
          <ModalFooter className="py-2">
            <TButton id="scheduleError.dismiss" onClick={() => setScheduleError()} />
            {scheduleError.code === 'preventUpdate' && <TButton id="scheduleError.force" color="danger" onClick={() => scheduleIntervention({
              ...scheduleError.item,
              force: scheduleError.params.reasons
            })} />}
          </ModalFooter>
        </Modal>
      )}

      <TNavLink id="returnToPlanning"
        tag={Link}
        className="d-inline-block"
        to={{
          pathname: '/planning',
          state: {
            ...(location.state ? location.state.planning : undefined),
            d: location.state && location.state.d ? location.state.d : undefined
          }
        }} />
      <div className="d-flex align-items-center mb-3">
        <div className="position-relative">
          <TDatePicker
            disabled={!planning}
            customInput={<SlotSelector />}
            selected={dateSelected}
            onChange={handleDateChange}
            // TODO: all DatePicker dateFormat shoudl be in translation. Maybe event a custom DatPicker component for that
            dateFormat="eeee dd/MM/yyyy"
          />
        </div>
        <div className="ml-3">
          <T id="title" values={planning} className="h4" />
        </div>

        {/* <InputGroup className="w-auto ml-3">
          {planningDurations.map(_planningDuration => (
            <FormGroup tag="fieldset" className="px-1" key={_planningDuration} check>
              <TCustomInput type="radio"
                id={`planningDuration.${_planningDuration}`}
                name={'planningDuration'}
                checked={planningDuration === _planningDuration}
                onChange={() => setPlanningDuration(_planningDuration)}
                label={`planningDuration.${_planningDuration}`} />
            </FormGroup>
          ))}
        </InputGroup> */}

        {plannings && <InputGroup className="w-auto ml-3">
          {plannings.filters.planningDuration.values.map(_planningDuration => (
            <FormGroup tag="fieldset" className="px-1" key={_planningDuration.value} check>
              <TCustomInput type="radio"
                id={`planningDuration.${_planningDuration.value}`}
                name={'planningDuration'}
                value={_planningDuration.value}
                checked={plannings.filters.planningDuration.selected.value === _planningDuration.value}
                onChange={e => handlePlanningDuration(e)}
                label={`planningDuration.${_planningDuration.label}`} />
            </FormGroup>
          ))}
        </InputGroup>}
      </div>

      {planning
        ? <Timeline
          groups={planning.groups}
          items={planning.items}
          direction="vertical"
          canMove={!dropping}
          duration={timeEndWork - timeStartWork}
          itemHeightRatio={1}
          defaultTimeStart={new Date(planning.dayStart).setHours(timeStartWork, 0)}
          defaultTimeEnd={new Date(planning.dayStart).setHours(timeEndWork)}
          onCanvasDrop={handleInterventionDrop}
          itemRenderer={PlanningItem}
        />
        : <Spinner className="d-flex ml-auto mr-auto" color="primary" />
      }

      {!planningsLoad && planningsUsers && planningsUsers.users && <PlanningItemModal
        item={itemSelected}
        users={planningsUsers.users}
        onSubmit={handleItemSelectedSave}
        onDismiss={() => setItemSelected()}
        onRemind={handleItemRemind} />}
    </div>
  )
}

export default PlanningUser
