import React from 'react'
import {
  Nav,
  NavItem,
  TabContent,
  NavLink,
  TabPane
} from 'reactstrap'
import { Redirect, Switch, NavLink as RRNavLink } from 'react-router-dom'

import ProtectedComponent from 'components/ProtectedComponent'
import { T, } from 'components/TComponents'
import Route from 'components/Route'

import AssignmentRequests from './AssignmentRequests'
import AssignmentRequest from './AssignmentRequest'
import PairingLVM from './PairingLVM'
import CheckLVM from './CheckLVM'
import Mute from './Mute'
import Docaposte from './Docaposte'

const FluowRoute = ({ match }) => {
  const TabItem = React.useCallback(({ name }) => (
    <NavItem>
      <NavLink tag={RRNavLink}
        activeClassName="active"
        to={`${match.path}/${name}`}>
        <T id={name} />
      </NavLink>
    </NavItem>
  ), [match.path])

  return (
    <div className="container-fluid">
      <Nav tabs className="mb-3">
        <div className="navitem-container">
          <ProtectedComponent>
            <TabItem name="assignments" />
            <TabItem name="pairing-lvm" />
            <TabItem name="check-lvm" />
            <ProtectedComponent rights={['fluow-mute',]}>
              <TabItem name="mute" />
            </ProtectedComponent>
            <ProtectedComponent rights={['fluow-accessQueue',]}>
              <TabItem name="docaposte" />
            </ProtectedComponent>
          </ProtectedComponent>
        </div>
      </Nav>

      <TabContent>
        <Switch>
          <TabPane tag={Redirect} exact from={`${match.path}/`} to={`${match.path}/assignments`} />
          <TabPane tag={Route} rights={['fluow-assignment']} exact path={`${match.path}/assignments`} component={AssignmentRequests} />
          <TabPane tag={Route} rights={['fluow-assignment']} path={`${match.path}/assignments/:id`} component={AssignmentRequest} />
          <TabPane tag={Route} rights={['fluow-pairing']} exact path={`${match.path}/pairing-lvm`} component={PairingLVM} />
          <TabPane tag={Route} rights={['fluow-check']} exact path={`${match.path}/check-lvm`} component={CheckLVM} />
          <TabPane tag={Route} rights={['fluow-mute']} exact path={`${match.path}/mute`} component={Mute} />
          <TabPane tag={Route} rights={['fluow-accessQueue']} exact path={`${match.path}/docaposte`} component={Docaposte} />
        </Switch>
      </TabContent>
    </div>
  )
}

export default FluowRoute
