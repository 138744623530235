import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import Route from 'components/Route'

import Stats from './Stats'

const StatsRoute = ({ match }) => <Switch>
  <Route exact path={`${match.path}`} component={Stats} rights={['stats_view']} />
  <Redirect to={`/`} />
</Switch>

export default StatsRoute
