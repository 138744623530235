import React, { createElement } from 'react'

const join = (...classes) => { return classes.filter(_ => _).join(' ') || undefined }
const noop = () => { }


export function Sheet({
  className,
  headHeaderRender,
  columnHeaderRender,
  columns = [], columnExtractKey, onColumnClick = noop,
  rowHeaderRender,
  rows = [], rowExtractKey, rowRender,
  footer,
  dataClass = noop, dataRender = noop, onDataClick = noop,
  doubleTitle
}) {
  function Row({ row, column, i, j }) {
    return createElement(
      column.isHeader ? 'th' : 'td',
      {
        onClick: (e) => onDataClick(e, row, column),
        className: join(dataClass(row, column, i, j), j === 0 ? 'first' : ''),
      },
      dataRender(row, column, i, j)
    )
  }

  return (
    <table className={className}>
      {columnHeaderRender && (
        <thead>
          <tr style={{ border: 'none' }}>
            {headHeaderRender && columns.map((column, i) => (
              <th style={{ border: 'none' }} key={rowExtractKey(column, i)} >
                {headHeaderRender && headHeaderRender(column, i)}
              </th>
            ))}
          </tr>
          <tr>
            {doubleTitle && (<th></th>)}
            {columns.map((column, i) => (
              <th key={columnExtractKey(column, i)} onClick={onColumnClick}>
                {columnHeaderRender(column, i)}
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {rows.map((row, i) => (
          <tr key={rowExtractKey(row, i)}>
            {rowHeaderRender && (
              <th>
                {rowHeaderRender(row, i)}
              </th>
            )}
            {rowRender
              ? rowRender(row, columns, i, {
                dataRender,
                rowExtractKey,
                rows,
              })
              : columns.map((column, j) => (
                <Row
                  key={`${rowExtractKey(row, i)}.${columnExtractKey(column, j)}`}
                  column={column} row={row} i={i} j={j} />
              ))
            }
          </tr>
        ))}
      </tbody>
      {footer && (
        <tfoot>
          <tr>
            <td colSpan={columns.length + (rowHeaderRender ? 1 : 0)}>{footer()}</td>
          </tr>
        </tfoot>
      )}
    </table>
  )
}
