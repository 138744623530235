import React from 'react'
import { Link } from 'react-router-dom'

import {
  Button,
  Card, CardHeader, CardBody, CardFooter,
  Form, FormGroup, InputGroupAddon, CustomInput, InputGroup,
  NavLink, Spinner,
  Modal, ModalBody, ModalFooter,
  Col
} from 'reactstrap'

import { AppContext, } from 'contexts/AppContext'

import CustomSelect from 'components/CustomSelect'
import { T, TButton, TLabel, TInput, TCustomInput, ErrAlert } from 'components/TComponents'

const ItvcodegroupsDetail = ({ match, history }) => {
  const { api } = React.useContext(AppContext)

  const [itvcodesBase, setItvcodesBase] = React.useState()
  const [itvcodesError, setItvcodesError] = React.useState()
  const [itvcodesLoading, setItvcodesLoading] = React.useState(true)
  const [itvcodesEdit, setItvcodesEdit] = React.useState([])

  const [creating, setCreating] = React.useState(false)
  const [createError, setCreateError] = React.useState()
  const [deleting, setDeleting] = React.useState(false)
  const [deleteError, setDeleteError] = React.useState()

  const [itvcodes, setItvcodes] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  const [searchInput, setSearchInput] = React.useState()
  const [itvcodesFiltered, setItvcodesFiltered] = React.useState([])

  const [editStatus, setEditStatus] = React.useState(false)

  const [dataArray, setDataArray] = React.useState([])
  const [itvcodegroupId, setItvcodegroupId] = React.useState()

  const [cancelModalOpen, setCancelModalOpen] = React.useState(false)

  const [locked, setLocked] = React.useState()

  const businessId = history.location.state && history.location.state.businessId
  const businessName = history.location.state && history.location.state.businessName
  const familyIdInitial = history.location.state && history.location.state.familyId
  const categoryIdInitial = history.location.state && history.location.state.categoryId

  React.useEffect(() => {
    const { params: { id: itvcodegroupId } } = match
    if (itvcodegroupId) {
      setItvcodegroupId(parseInt(itvcodegroupId))
      setEditStatus(true)
      Promise.all([
        api.get('/itvcodegroups/details', undefined, { itvcodegroupId }),
        new Promise(resolve => setTimeout(resolve, 250))
      ])
        .then(([data]) => {
          setItvcodesEdit(data && data.itvcodes)
          setItvcodes(data.itvcodes
            .filter(code => code.itvcodegroupsId == itvcodegroupId)
            .map(code => code.itvcodeId))
          setLocked(data.itvcodegroupId.locked)
        })
        .catch(error => setItvcodesError(error))
        .then(() => setItvcodesLoading(false))
    }

    Promise.all([
      api.get('/itvcodegroups/itvcodesAvailable', undefined, { businessId }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => setItvcodesBase(data))
      .catch(error => setItvcodesError(error))
      .then(() => setItvcodesLoading(false))
  }, [api, businessId, categoryIdInitial, familyIdInitial, match])

  React.useEffect(() => {
    const editStatusData = editStatus && itvcodesEdit.map(code => {
      if (code.itvcodegroupsId == itvcodegroupId) {
        code.checked = 1
      }
      return code
    })
    const data = editStatus ? editStatusData : (itvcodesFiltered && itvcodesFiltered.length > 0 && itvcodesFiltered) ||
            (itvcodesBase && itvcodesBase.result && itvcodesBase.result)
    setDataArray(data)
  }, [editStatus, itvcodesEdit, itvcodesBase, itvcodesFiltered, itvcodegroupId])

  const refresh = React.useCallback((params) => {
    setLoading(true)
    setSearchInput('')
    setItvcodesFiltered([])
    Promise.all([
      api.get('/itvcodegroups/itvcodesAvailable', undefined, {
        businessId,
        categoryId: itvcodesBase.filters.categoryId.selected && itvcodesBase.filters.categoryId.selected.value,
        familyId: itvcodesBase.filters.familyId.selected && itvcodesBase.filters.familyId.selected.value,
        ...params
      }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => setItvcodesBase(data))
      .catch(error => setItvcodesError(error))
      .then(() => setLoading(false))
  }, [api, itvcodesBase, businessId])

  const handleSearchInput = React.useCallback(({ target: { value } }) => {
    setSearchInput(value)
  }, [])

  const resetItvcodeSearch = React.useCallback(e => {
    e.preventDefault()
    setSearchInput('')
    setItvcodesFiltered([])
  }, [])

  const filterAccent = (text) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

  const handleItvcodeSearch = React.useCallback(e => {
    e.preventDefault()
    setItvcodesFiltered(itvcodesBase.result.filter(itvcode =>
      filterAccent(itvcode.itvcodeIdentifier).toUpperCase().includes(filterAccent(searchInput.toUpperCase())) ||
            filterAccent(itvcode.itvcodeLabel).toUpperCase().includes(filterAccent(searchInput).toUpperCase())
    ))
  }, [itvcodesBase, searchInput])

  const check = React.useCallback(({ target: { checked, name } }) => {
    editStatus && setDataArray(editStatus && itvcodesEdit.map(code => {
      if (code.itvcodeId == name) {
        code.checked = checked ? 1 : 0
      }
      return code
    }))
    setItvcodes(itvcodes => checked
      ? [...itvcodes, Number(name)]
      : itvcodes.filter(itvcodeIt => itvcodeIt !== Number(name))
    )
  }, [itvcodesEdit, editStatus])

  const handleSave = React.useCallback(() => {
    setCreating(true)
    setCreateError()
    api.post('/itvcodegroups/details', {
      body: JSON.stringify({
        businessId,
        itvcodeIds: itvcodes,
        itvcodegroupId,
        locked: locked ? 1 : 0
      })
    })
      .then(() => {
        setCreating(false)
        history.push({ pathname: '/admin/itvcodegroups' })
      })
      .catch(error => {
        setCreateError(error)
        setCreating(false)
      })
  }, [api, businessId, history, itvcodes, itvcodegroupId, locked])

  const handleDelete = React.useCallback(() => {
    setDeleting(true)
    setDeleteError()
    api.del('/itvcodegroups/details', undefined, { itvcodegroupId })
      .then(() => {
        setDeleting(false)
        history.push({ pathname: '/admin/itvcodegroups' })
      })
      .catch(error => {
        setDeleteError(error)
        setDeleting(false)
      })
  }, [itvcodegroupId, api, history])

  return (
    <>
      <NavLink
        tag={Link}
        to={{
          pathname: "../itvcodegroups",
          state: {
            businessId,
            categoryId: itvcodesBase && itvcodesBase.filters.categoryId.selected && itvcodesBase.filters.categoryId.selected.value,
            familyId: itvcodesBase && itvcodesBase.filters.familyId.selected && itvcodesBase.filters.familyId.selected.value
          }
        }}>
        <T id="returnToList" />
      </NavLink>
      <Card className="mb-2" tag={Form} onSubmit={e => e.preventDefault()}>
        {!editStatus && (
          <CardHeader className="d-flex align-items-center mb-3 pl-0">
            <Col sm="6" md="3">
              <form className="d-flex align-items-center ml-3" onSubmit={handleItvcodeSearch} onReset={resetItvcodeSearch}>
                <FormGroup tag="fieldset">
                  <TLabel for="searchInput" id="searchInputLabel" className="" />
                  <InputGroup>
                    <TInput
                      name="searchInput"
                      type="text"
                      placeholder="searchInputPlaceholder"
                      value={searchInput}
                      onChange={handleSearchInput} />
                    <InputGroupAddon addonType="append">
                      <Button
                        color="secondary"
                        className="py-0 px-3 d-flex align-items-center"
                        type="reset"
                        disabled={searchInput === ''}>
                        <i className="simple-icon-close font-weight-bold" />
                      </Button>
                      <Button
                        color="secondary"
                        className="py-0 px-3 d-flex align-items-center"
                        type="submit"
                        disabled={searchInput === ''}>
                        <i className="simple-icon-magnifier font-weight-bold" />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </form>
            </Col>
            {itvcodesBase && itvcodesBase.filters && itvcodesBase.filters.categoryId && itvcodesBase.filters.categoryId.values && <Col sm="6" md="3">
              <FormGroup tag="fieldset">
                <TLabel for="categoryId" id="categoryLabel" className="" />
                <CustomSelect
                  inputId="categoryId"
                  name="categoryId"
                  options={itvcodesBase.filters.categoryId.values}
                  onChange={e => refresh({ categoryId: e && e.value })}
                  isClearable
                  placeholder={<T id="categoryPlaceholder" />}
                  value={itvcodesBase.filters.categoryId.selected && itvcodesBase.filters.categoryId.values.filter(c => c.value === itvcodesBase.filters.categoryId.selected.value)}
                  isDisabled={loading} />
              </FormGroup>
            </Col>}
            {itvcodesBase && itvcodesBase.filters && itvcodesBase.filters.familyId && itvcodesBase.filters.familyId.values && <Col sm="6" md="3">
              <FormGroup tag="fieldset">
                <TLabel for="familyId" id="familyLabel" className="" />
                <CustomSelect
                  inputId="familyId"
                  name="familyId"
                  options={itvcodesBase.filters.familyId.values}
                  onChange={e => refresh({ familyId: e && e.value })}
                  isClearable
                  placeholder={<T id="familyPlaceholder" />}
                  value={itvcodesBase.filters.familyId.selected && itvcodesBase.filters.familyId.values.filter(f => f.value === itvcodesBase.filters.familyId.selected.value)}
                  isDisabled={loading} />
              </FormGroup>
            </Col>}
          </CardHeader>
        )}
        <CardBody>
          <div className="mb-4">
            <T className="font-weight-bold h6 d-block" id="business" />
            {businessName}
          </div>
          <TLabel id="locked" />
          <FormGroup tag="fieldset" className="mb-3" check >
            <TCustomInput
              id="locked"
              className="pl-0"
              type="checkbox"
              name={locked}
              checked={locked}
              onChange={() => { setLocked(!locked) }}
              label="lockedCheck" />
          </FormGroup>
          <TLabel id="itvcodesLabel" />
          {itvcodesError && <ErrAlert error={itvcodesError} />}
          {itvcodesLoading && <Spinner className="d-flex" />}
          {!itvcodesLoading && dataArray && dataArray.map(itvcode =>
            <>
              {(!itvcode.itvcodegroupsId || itvcode.itvcodegroupsId == itvcodegroupId) && (
                <FormGroup key={itvcode.itvcodeId} tag="fieldset" check>
                  <CustomInput
                    id={'itvcode.' + itvcode.itvcodeId}
                    className="pl-0"
                    type="checkbox"
                    name={itvcode.itvcodeId}
                    checked={itvcode.checked}
                    // checked={editStatus ? itvcode.itvcodegroupsId == itvcodegroupId || itvcode.checked : itvcode.checked}
                    onChange={check}
                    label={`${itvcode.itvcodeIdentifier} : ${itvcode.itvcodeLabel}`} />
                </FormGroup>
              )}
            </>
          )}

          {createError && <ErrAlert error={createError} />}
        </CardBody>
        <CardFooter>
          {editStatus ? (
            <div className="d-flex">
              <TButton
                disabled={creating}
                type="button"
                className="ml-2"
                color="danger"
                onClick={() => { setCancelModalOpen(!cancelModalOpen) }}
                id="delete" />
              <TButton
                disabled={creating}
                type="button"
                className="ml-auto"
                color="primary"
                onClick={e => handleSave(e)}
                id="edit" />
              <TButton
                disabled={creating}
                type="cancel"
                tag={Link}
                className="ml-2"
                to={{
                  pathname: "../itvcodegroups",
                  state: {
                    businessId,
                    categoryId: itvcodesBase && itvcodesBase.filters.categoryId.selected && itvcodesBase.filters.categoryId.selected.value,
                    familyId: itvcodesBase && itvcodesBase.filters.familyId.selected && itvcodesBase.filters.familyId.selected.value
                  }
                }}
                id="cancel" />
            </div>
          ) : (
            <div className="d-flex justify-content-end">
              <TButton disabled={creating} type="button"
                className="ml-2" color="primary"
                onClick={e => handleSave(e)}
                id="create" />
              <TButton
                disabled={creating}
                type="cancel"
                tag={Link}
                className="ml-2"
                to={{
                  pathname: "../itvcodegroups",
                  state: {
                    businessId,
                    categoryId: itvcodesBase && itvcodesBase.filters.categoryId.selected && itvcodesBase.filters.categoryId.selected.value,
                    familyId: itvcodesBase && itvcodesBase.filters.familyId.selected && itvcodesBase.filters.familyId.selected.value
                  }
                }}
                id="cancel" />
            </div>
          )}
        </CardFooter>
      </Card>
      <Modal isOpen={cancelModalOpen} fade={true} toggle={() => setCancelModalOpen(!cancelModalOpen)}>
        <ModalBody>
          <div className="mb-1"><T id="modal.cancel.content" /></div>
          {deleteError && <ErrAlert error={deleteError} className="mb-0 mt-2" />}
        </ModalBody>
        <ModalFooter>
          <TButton
            id="modal.cancel.cancel"
            onClick={() => setCancelModalOpen(!cancelModalOpen)} />
          <TButton
            id="modal.cancel.confirm"
            className="ml-2" color="danger" onClick={() => handleDelete()} disabled={deleting} />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ItvcodegroupsDetail
