import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import Route from 'components/Route'

import List from './AppointmentList'
import Details from './AppointmentDetails'

const AppointmentRoute = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/new`} component={Details} rights={['appointments_new']} />
    <Route exact path={`${match.path}/:id`} component={Details} rights={['appointments_view']} />
    <Route exact path={`${match.path}`} component={List} rights={['appointments_view']} />
    {/* TODO: Bad redirect ??? */}
    <Redirect to={`/`} />
  </Switch>
)

export default AppointmentRoute
