import React from 'react'
import { useLocation, useHistory, NavLink } from 'react-router-dom'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { objectToQuery, queryToObject } from 'react-rest-api'

import { useGet } from 'hooks/useApi.hooks'

import ProtectedComponent from 'components/ProtectedComponent'
import { Filters } from 'components/Filters'
import { TAlert, TButton } from 'components/TComponents'
import { Table } from 'components/Table'

const PartList = () => {

  const location = useLocation()
  const history = useHistory()

  const filters = React.useMemo(() => ({
    p: {
      type: 'number'
    },
    ipp: {
      type: 'number'
    },
    'order[column]': {},
    'order[dir]': {},
    filterLabel: {
      type: 'string',
      componentType: 'textDebounce'
    },
    filterRef: {
      type: 'string',
      componentType: 'textDebounce'
    },
    warehouseId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false
      },
    }
  }), [])

  const params = React.useMemo(() => queryToObject(location.search, filters), [location.search, filters])

  const [data, refreshing, error, loading] = useGet(`/inventories/list`, undefined, params)

  const refresh = React.useCallback((_params = {}) => history.push({ search: objectToQuery({ ...params, ..._params }) }), [history, params])

  const DetailsButton = React.useCallback(inventory => (
    <ProtectedComponent rights={['veloparts-inventories_view']}>
      <TButton id="viewInventory" className="ml-auto stretched-link" outline
        tag={NavLink}
        to={`/veloparts/inventories/${inventory.inventoryId}`} />
    </ProtectedComponent>
  ), [])

  const RenderQuantity = React.useCallback(inventory => (
    <>
      {Number(inventory.quantity) < 0 ? <>{`⚠️ ${inventory.quantity}`}</> : <>{inventory.quantity}</>}
    </>
  ), [])

  return (
    <>
      <Card className="mb-2">
        <CardHeader>
          <Filters
            loading={loading}
            disabled={refreshing}
            data={data}
            refresh={refresh}
            filters={filters}
          />
        </CardHeader>
        <ProtectedComponent rights={['veloparts-inventories_edit']}>
          <CardHeader>
            <TButton id="createStock"
              tag={NavLink}
              to="/veloparts/inventories/new" />
          </CardHeader>
        </ProtectedComponent>
        <CardBody>
          {error && <TAlert color="danger" id={error.code} />}
          <Table
            isLoading={loading}
            disabled={loading}
            data={data}
            refresh={refresh}
            columns={[
              {
                title: 'warehouseLabel',
                render: item => item.warehouseLabel
              },
              {
                title: 'partLabel',
                hasOrder: true,
                render: item => item.partLabel
              },
              {
                title: 'partRef',
                hasOrder: true,
                render: item => item.partRef
              },
              {
                title: 'quantity',
                hasOrder: true,
                render: RenderQuantity
              },
              {
                className: () => 'align-middle text-right',
                render: DetailsButton
              }
            ]}
          />
        </CardBody>
      </Card>
    </>

  )
}

export default PartList
