import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import CustomSelect from 'components/CustomSelect'

import {
  Card, CardBody, CardFooter,
  Form, FormGroup, Label,
  Input,
  Spinner,
} from 'reactstrap'

import { AppContext, } from 'contexts/AppContext'

import { TLabel, TButton, TCustomInput, TNavLink, ErrAlert, T } from 'components/TComponents'

const NewSubsidiary = () => {
  const { api } = React.useContext(AppContext)
  const history = useHistory()

  const [creating, setCreating] = React.useState(false)
  const [error, setError] = React.useState()
  const [subsidiaryName, setSubsidiaryName] = React.useState('')
  const [subsidiaryIdentifier, setSubsidiaryIdentifier] = React.useState('')
  const [bcCode, setBcCode] = React.useState('')
  const [languages, setLanguages] = React.useState([])
  const [timezones, setTimezones] = React.useState([])
  const [currencies, setCurrencies] = React.useState([])
  const [langId, setLangId] = React.useState()
  const [timezoneId, setTimezoneId] = React.useState()
  const [currencyId, setCurrencyId] = React.useState()
  const [constants, setConstants] = React.useState()
  const [enableVeloptim, setEnableVeloptim] = React.useState(0)
  const [enableVelocenter, setEnableVelocenter] = React.useState(0)
  const [timeStartWork, setTimeStartWork] = React.useState(6)
  const [timeEndWork, setTimeEndWork] = React.useState(20)
  const [maxDuration, setMaxDuration] = React.useState()
  const [invoiceSystemId, setInvoiceSystemId] = React.useState()
  const [invoiceSystemIdentifier, setInvoiceSystemIdentifier] = React.useState()
  const [enableSameEquipmentIdentifier, setEnableSameEquipmentIdentifier] = React.useState(0)
  const [enableReasonParts, setEnableReasonParts] = React.useState(0)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setLoading(true)
    Promise.all([
      api.get('/languages/list'),
      api.get('/timezones/list'),
      api.get('/currencies/list'),
      api.get('/velocare/constants', { headers: { 'X-Velocare-Subsidiary': 1 } }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([languages, timezones, currencies, constants]) => {
        setLanguages(languages)
        setTimezones(timezones)
        setCurrencies(currencies)
        setConstants(constants)
      })
      .catch(response => setError(response))
      .then(() => setLoading(false))
  }, [api])

  const create = React.useCallback(() => {
    setCreating(true)
    setError()
    api.post('/subsidiaries/details', {
      body: JSON.stringify({
        subsidiaryName, subsidiaryIdentifier, bcCode, langId, timezoneId, currencyId, enableVeloptim, enableVelocenter, timeStartWork, timeEndWork, maxDuration, invoiceSystemId: invoiceSystemId && invoiceSystemId.value, invoiceSystemIdentifier, enableSameEquipmentIdentifier, enableReasonParts
      })
    })
      .then(() => history.push('/subsidiaries'))
      .catch(response => {
        setCreating(false)
        setError(response)
      })
  }, [api, history, bcCode, subsidiaryIdentifier, subsidiaryName, langId, timezoneId, currencyId, enableVeloptim, enableVelocenter, timeStartWork, timeEndWork, maxDuration, invoiceSystemId, invoiceSystemIdentifier, enableSameEquipmentIdentifier, enableReasonParts])

  if (loading) {
    return (
      <div className="d-flex position-absolute" style={{ left: 0, right: 0, top: 0, bottom: 0 }}>
        <Spinner color="primary" className="d-flex m-auto" />
      </div>
    )
  }

  return <>
    <TNavLink tag={Link} to="/subsidiaries" id="returnToList" />
    <Card className="mb-2">
      <CardBody tag={Form}>
        <FormGroup tag="fieldset">
          <TLabel for="profile.subsidiaryIdentifier" id="subsidiaryIdentifier" />
          <Input id="profile.subsidiaryIdentifier"
            type="text"
            name="subsidiaryIdentifier"
            value={subsidiaryIdentifier}
            onChange={e => setSubsidiaryIdentifier(e.target.value)} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel for="profile.subsidiaryName" id="subsidiaryName" />
          <Input id="profile.subsidiaryName"
            type="text"
            name="subsidiaryName"
            value={subsidiaryName}
            onChange={e => setSubsidiaryName(e.target.value)} />
        </FormGroup>
        {constants && <FormGroup tag="fieldset">
          <TLabel for="invoiceSystem" id="invoiceSystem" />
          <CustomSelect
            inputId="invoiceSystem"
            name="invoiceSystem"
            options={[{ value: 0, key: 'invoice.noSystem' }].concat(constants.invoiceSystem)}
            onChange={value => setInvoiceSystemId({ value: value.value, key: value.key })}
            placeholder={<T id="invoiceSystem.placeholder" />}
            noOptionsMessage={() => <T id="invoiceSystem.noResult" />}
            getOptionLabel={option => <T id={option.key} raw />}
            getOptionValue={option => option.value} />
        </FormGroup>}
        {invoiceSystemId && invoiceSystemId.key && invoiceSystemId.value !== 0 && <FormGroup tag="fieldset">
          <Label for="profile.invoiceSystemIdentifier">
            <T id={`invoiceSystemIdentifier`} />{` ${invoiceSystemId.key}`}
          </Label>
          <Input id="profile.invoiceSystemIdentifier"
            type="text"
            name="invoiceSystemIdentifier"
            value={invoiceSystemIdentifier}
            onChange={e => setInvoiceSystemIdentifier(e.target.value)} />
        </FormGroup>}
        <FormGroup tag="fieldset">
          <TLabel for="profile.bcCode" id="bcCode" />
          <Input id="profile.bcCode"
            type="text"
            name="bcCode"
            value={bcCode}
            onChange={e => setBcCode(e.target.value)} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel for="profile.language" id="language" />
          <CustomSelect
            id="language"
            options={languages}
            onChange={value => setLangId(value.langId)}
            placeholder={<T id="language.placeholder" />}
            noOptionsMessage={() => <T id="language.noResult" />}
            getOptionLabel={option => <T id={option.langIdentifier} />}
            getOptionValue={option => option.langId} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel for="profile.timezone" id="timezone" />
          <CustomSelect
            id="timezone"
            options={timezones}
            onChange={value => setTimezoneId(value.timezoneId)}
            placeholder={<T id="timezone.placeholder" />}
            noOptionsMessage={() => <T id="timezone.noResult" />}
            getOptionLabel={option => option.timezoneIdentifier}
            getOptionValue={option => option.timezoneId} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel for="profile.currency" id="currency" />
          <CustomSelect
            id="currency"
            options={currencies}
            onChange={value => setCurrencyId(value.currencyId)}
            placeholder={<T id="currency.placeholder" />}
            noOptionsMessage={() => <T id="currency.noResult" />}
            getOptionLabel={option => option.currencyIdentifier}
            getOptionValue={option => option.currencyId} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel for="enableVeloptim" id="enableVeloptimLabel" />
          <TCustomInput
            id="enableVeloptim"
            type="checkbox"
            name="enableVeloptim"
            label="enableVeloptim"
            checked={enableVeloptim}
            onChange={e => { setEnableVeloptim(e.target.checked ? 1 : 0) }} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel for="enableVelocenter" id="enableVelocenterLabel" />
          <TCustomInput
            id="enableVelocenter"
            type="checkbox"
            name="enableVelocenter"
            label="enableVelocenter"
            checked={enableVelocenter}
            onChange={e => { setEnableVelocenter(e.target.checked ? 1 : 0) }} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel for="enableReasonParts" id="enableReasonPartsLabel" />
          <TCustomInput
            id="enableReasonParts"
            type="checkbox"
            name="enableReasonParts"
            label="enableReasonParts"
            checked={enableReasonParts}
            onChange={e => { setEnableReasonParts(e.target.checked ? 1 : 0) }} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel id="planningTime" />
          <div className="d-flex">
            <div>
              <TLabel for="profile.timeStartWork" id="timeStartWork" />
              <Input id="profile.timeStartWork"
                type="number"
                name="timeStartWork"
                value={timeStartWork}
                onChange={e => setTimeStartWork(e.target.value)} />
            </div>
            <div className="ml-2">
              <TLabel for="profile.timeEndWork" id="timeEndWork" />
              <Input id="profile.timeEndWork"
                type="number"
                name="timeEndWork"
                value={timeEndWork}
                onChange={e => setTimeEndWork(e.target.value)} />
            </div>
          </div>
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel for="maxDuration" id="maxDuration" />
          <Input id="maxDuration"
            type="number"
            name="maxDuration"
            value={maxDuration}
            onChange={e => setMaxDuration(e.target.value)} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel for="enableSameEquipmentIdentifier" id="enableSameEquipmentIdentifierLabel" />
          <TCustomInput
            id="enableSameEquipmentIdentifier"
            type="checkbox"
            name="enableSameEquipmentIdentifier"
            label="enableSameEquipmentIdentifier"
            checked={enableSameEquipmentIdentifier}
            onChange={e => { setEnableSameEquipmentIdentifier(e.target.checked ? 1 : 0) }} />
        </FormGroup>
        {error && <ErrAlert color="danger" error={error} />}
      </CardBody>
      <CardFooter className="d-flex justify-content-end">
        <TButton disabled={creating} className="ml-2" onClick={() => create()} color="primary" id="create" />
        <TButton disabled={creating} tag={Link} className="ml-2" to="/admin/subsidiaries" id="cancel" />
      </CardFooter>
    </Card>
  </>
}

export default NewSubsidiary
