import React from 'react'

import {
  Spinner, Progress, Button,
  ListGroup, ListGroupItem,
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import ProtectedComponent from 'components/ProtectedComponent'
import { T, ErrAlert, TButton, } from 'components/TComponents'

const uploadReducer = (state, action) => {
  switch (action.type) {
  case 'initialize': return { loading: false, error: undefined, data: undefined }
  case 'posting': return { loading: true, error: undefined, data: undefined }
  case 'posted': return { loading: false, error: undefined, data: action.data }
  case 'error': return { loading: false, error: action.error, data: undefined }
  default: state
  }
}

const uploadInitialState = {
  loading: false,
  error: undefined,
  data: undefined
}

const deleteReducer = (state, action) => {
  switch (action.type) {
  case 'initialize': return { loading: false, error: undefined, data: undefined }
  case 'deleting': return { ...state, [action.id]: { loading: true, error: undefined, data: undefined } }
  case 'deleted': return { ...state, [action.id]: { loading: false, error: undefined, data: action.data } }
  case 'error': return { ...state, [action.id]: { loading: false, error: action.error, data: undefined } }
  case 'clear': return { ...state, [action.id]: deleteInitialState }
  default: state
  }
}

const deleteInitialState = {
  loading: false,
  error: undefined,
  data: undefined
}

const AttachmentsModal = ({ businessId }) => {
  const { api } = React.useContext(AppContext)
  const fileInput = React.useRef()

  const [data, setData] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [loadError, setLoadError] = React.useState()

  const [uploadState, dispatchUpload] = React.useReducer(uploadReducer, uploadInitialState)
  const [deleteState, dispatchDelete] = React.useReducer(deleteReducer, {})

  const [open, setOpen] = React.useState()

  React.useEffect(() => {
    if (open) {
      setLoadError()
      setLoading(true)
      Promise.all([
        api.get('/equipments/attachments', undefined, { businessId }),
        new Promise(resolve => setTimeout(resolve, 250))
      ])
        .then(([response]) => setData(response))
        .catch(response => setLoadError(response))
        .then(() => setLoading(false))
    }
  }, [open, api, businessId])

  const fileInputChange = React.useCallback(() => {
    dispatchUpload({ type: 'posting' })

    const file = fileInput.current.files[0]
    Promise.all([
      api.put('/equipments/attachment', { body: file }, { businessId, filename: file.name }),
      new Promise(resolve => setTimeout(resolve, 1250))
    ])
      .then(([data]) => {
        setData(old => old.concat(data))
        dispatchUpload({ type: 'posted', data })
      })
      .catch(error => dispatchUpload({ type: 'error', error }))
      .then(() => fileInput.current.value = '')
  }, [api, businessId, fileInput])

  const deleteAttachment = React.useCallback((attachmentId) => {
    dispatchDelete({ id: attachmentId, type: 'deleting' })

    Promise.all([
      api.del('/equipments/attachment', undefined, { attachmentId }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => {
        setData(old => old.filter(attachment => attachment.attachmentId !== attachmentId))
        dispatchDelete({ id: attachmentId, type: 'deleted', data })
      })
      .catch(error => dispatchDelete({ id: attachmentId, type: 'error', error }))
  }, [api])

  return (<>
    <TButton className="mx-2" id="attachments.button" onClick={() => {
      dispatchDelete({ type: 'initialize' })
      dispatchUpload({ type: 'initialize' })
      setOpen(true)
    }} />
    {open &&
      <Modal isOpen={true} size={'lg'} toggle={() => setOpen()}>
        <ModalHeader><T id="attachments.modal.header" /></ModalHeader>
        <ModalBody>
          {loading && <Spinner className="d-flex mx-auto" color="primary" />}
          {!loading && loadError && <ErrAlert error={loadError} className="mb-0" />}
          {!loading && !loadError && <ListGroup>
            {data.length === 0 && <ListGroupItem><T id="attachments.modal.noResult" /></ListGroupItem>}
            {data.length > 0 && data.map(attachment => {
              const state = deleteState[attachment.attachmentId] || {}

              return <ListGroupItem key={attachment.attachmentId} className="d-flex align-items-center">
                <a href={attachment.viewLink} target="_blank" rel="noopener noreferrer">
                  <T id="attachments.modal.link" values={attachment} />
                  <T tagName="small" className="text-muted" id="attachments.modal.fileSize" values={attachment} />
                </a>

                <ProtectedComponent rights={['equipments-attachments_delete']}>
                  {!state.error && !state.loading && <Button close className="ml-auto" onClick={() => deleteAttachment(attachment.attachmentId)} disabled={state.loading}>
                    <i className="simple-icon-trash text-danger" />
                  </Button>}
                  {!state.error && state.loading && <Spinner className="ml-auto" size="sm" color="danger" />}
                  {state.error && <ErrAlert className="mb-0 ml-auto" error={state.error} toggle={() => dispatchDelete({ id: attachment.attachmentId, type: 'clear' })} />}
                </ProtectedComponent>
              </ListGroupItem>
            })}
            {uploadState.loading && <ListGroupItem><Progress animated color="success" value="100" /></ListGroupItem>}
            {uploadState.error && <ListGroupItem color="danger"><T raw id={`error.${uploadState.error.code}`} values={uploadState.error.params} /></ListGroupItem>}
          </ListGroup>}
        </ModalBody>
        <ModalFooter className="py-3">
          <ProtectedComponent rights={['equipments-attachments_upload']}>
            <input type="file" ref={fileInput} onChange={fileInputChange} accept="application/pdf" hidden />
            <TButton className="mr-auto" id="attachments.modal.click" onClick={() => fileInput.current.click()} disabled={uploadState.loading} />
          </ProtectedComponent>
          <TButton className="ml-2" id="attachments.modal.close" onClick={() => setOpen()} />
        </ModalFooter>
      </Modal>
    }
  </>)
}

export default AttachmentsModal
