import React from 'react'
import { useLocation, useHistory, useParams, Link } from 'react-router-dom'
import CustomSelect from 'components/CustomSelect'
import { objectToQuery } from 'react-rest-api'

import {
  Badge, Card, CardHeader, CardBody, CardFooter, FormGroup, Input, InputGroup,
  Modal, ModalBody, ModalFooter, Spinner
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import { useGet } from 'hooks/useApi.hooks'

import Timeline from 'components/react-calendar-timeline'
import PlanningItem from 'components/PlanningItem'
import SlotSelector from 'components/SlotSelector'
import Duration from 'components/Duration'
import { T, TAlert, TAutocomplete, ErrAlert, TButton, TNavLink, TLabel, TInput, TCustomInput, TDatePicker } from 'components/TComponents'

import '../Planning.scss'

const initPosting = (state = {}) => {
  const today = new Date()
  const dateAppointment = state.dateAppointment ? new Date(state.dateAppointment) : new Date(new Date(new Date(today).setDate(today.getDate() + 1)).setHours(8, 0))
  const dateEnd = state.appointmentId ? undefined : new Date(new Date(dateAppointment).setMonth(dateAppointment.getMonth() + 1))

  return {
    loading: false,
    error: undefined,
    base: state,
    data: {
      duration: 60,
      ...state,
      dateAppointment,
      dateEnd,
    },
  }
}

const postingReducer = (state, action) => {
  switch (action.type) {
  case 'saving': return { ...state, loading: true, error: undefined }
  case 'change': return { ...state, data: { ...state.data, ...action.payload } }
  case 'saved': return initPosting(action.data)
  case 'error': return { ...state, error: action.error, loading: false }
  case 'dismissError': return { ...state, error: undefined }
  case 'reset': return initPosting(state.base) // TODO: unused yet
  default: return state
  }
}

const cancelInitialState = {
  open: false,
  loading: false,
  error: undefined
}

const cancelReducer = (state, action) => {
  switch (action.type) {
  case 'open': return { open: true, error: undefined, loading: false }
  case 'close': return { open: false, error: undefined, loading: false }
  case 'load': return { open: true, error: undefined, loading: true }
  case 'success': return { open: false, error: undefined, loading: false }
  case 'error': return { open: true, error: action.error, loading: false }
  default: return state
  }
}

const AppointmentDetails = () => {
  const { api, constants, subsidiary, timeStartWork, timeEndWork } = React.useContext(AppContext)

  const location = useLocation()
  const history = useHistory()
  const routeParams = useParams()

  const detailsParams = React.useMemo(() => ({ appointmentId: routeParams.id }), [routeParams.id])

  // TODO: handle error and refreshing
  const [data, , , loading] = useGet('/appointments/details', undefined, detailsParams, { pass: routeParams.id === undefined })
  const [business, , , businessLoading] = useGet('/appointments/business')

  const [autocomplete, setAutocomplete] = React.useState()
  const [addresses, setAddresses] = React.useState()
  const [addressesLoading, setAddressesLoading] = React.useState(false)
  const [addressesError, setAddressesError] = React.useState()
  const [selectedAddress, setSelectedAddress] = React.useState('')

  const [nbOccurrences, setNbOccurrences] = React.useState(undefined)
  const [companyId, setCompanyId] = React.useState(location.state ? location.state.c : subsidiary.companyId)
  const [userTypeId, setUserTypeId] = React.useState(location.state ? location.state.t : '0')
  const [searchUser, setSearchUser] = React.useState(location.state ? location.state.s : '')

  const [currentChanges, dispatchCurrentChanges] = React.useReducer(postingReducer, data, initPosting)
  const [cancelState, dispatchCancel] = React.useReducer(cancelReducer, cancelInitialState)

  const [planningsUsers, setPlanningUsers] = React.useState()
  const [planningsUsersLoad, setPlanningsUsersLoad] = React.useState(true)
  const [planningsUsersError, setPlanningsUsersError] = React.useState()
  const [plannings, setPlannings] = React.useState([])
  const [planningGroups, setPlanningGroups] = React.useState([])
  const [planningItems, setPlanningItems] = React.useState([])

  const [days, setDays] = React.useState({})

  const [savedAlert, setSavedAlert] = React.useState(0)

  React.useEffect(() => () => savedAlert > 0 && clearTimeout(savedAlert), [savedAlert])

  React.useEffect(() => { if (!loading) dispatchCurrentChanges({ type: 'saved', data }) }, [data, loading])

  React.useEffect(() => {
    if (business && currentChanges.data.businessId) {
      getAddressesList(business.find(b => b.businessId === currentChanges.data.businessId).businessId)
    }
  }, [business, currentChanges.data.businessId, getAddressesList])

  React.useEffect(() => {
    history.replace({ search: objectToQuery({ ...location.state }), state: location.state })
  }, [history, location.state])

  const dayStart = React.useMemo(() => `${currentChanges.data.dateAppointment.getFullYear()}-${currentChanges.data.dateAppointment.getMonth() + 1}-${currentChanges.data.dateAppointment.getDate()}`, [currentChanges.data.dateAppointment])

  React.useEffect(() => {
    api.get('/plannings/view', undefined, { dayStart, companyId, userTypeId, searchUser })
      .then(response => {
        setPlanningUsers(response)
        setPlannings([])
      })
      .catch(response => { setPlanningsUsersError(response) })
      .then(() => {
        setPlanningsUsersLoad(false)
      })
    // TODO: handle error
  }, [api, dayStart, companyId, userTypeId, searchUser])

  const fetchPlanningUser = React.useCallback(i => {
    if (i < planningsUsers.users.length) {
      const _params = {
        dayStart: currentChanges.data.dateAppointment,
        userId: planningsUsers.users[i].userId
      }
      api.get('/plannings/userPlanning', undefined, { ..._params })
        .then(response => {
          setPlannings(oldPlanning => [...oldPlanning.filter((pUser => pUser.userId !== planningsUsers.users[i].userId)), {
            userId: planningsUsers.users[i].userId,
            userName: planningsUsers.users[i].firstName + planningsUsers.users[i].lastName,
            techcenterDistance: planningsUsers.users[i].techcenterDistance || null,
            interventions: response.interventions,
            appointments: response.appointments,
            swaps: response.swaps,
            unavailabilities: response.unavailabilities,
            maintenanceplans: response.maintenanceplans
          }])
        })
    }
  }, [api, planningsUsers, currentChanges.data.dateAppointment])

  React.useEffect(() => {
    const dateToCompare = `${currentChanges.data.dateAppointment.getFullYear()}-${("0" + (currentChanges.data.dateAppointment.getMonth() + 1)).toString().padStart(2, '0')}-${currentChanges.data.dateAppointment.getDate().toString().padStart(2, '0')}`
    if (planningsUsers && planningsUsers.users && planningsUsers.users.length > 0 && planningsUsers.filters && planningsUsers.filters.dayStart === dateToCompare) {
      for (let i = 0; i < planningsUsers.users.length; i++) {
        fetchPlanningUser(i)
      }
    }
  }, [planningsUsers, fetchPlanningUser, currentChanges.data.dateAppointment])

  React.useEffect(() => {
    const newDays = {}
    constants.appointmentDays.map(day => {
      newDays[day] = 0
    })
    setDays(newDays)
  }, [constants])

  const getAddressesList = React.useCallback(businessId => {
    if (businessId !== undefined) {
      setAddressesLoading(true)
      api.get('/interventions/addresses', undefined, { businessId })
        .then(response => setAddresses(response))
        .catch(response => setAddressesError(response))
        .then(() => setAddressesLoading(false))
    }
  }, [api])

  const cancellable = React.useMemo(() =>
    !constants.appointmentStatus.find(as => as.value === currentChanges.data.statusId && ['canceled'].includes(as.key))
  , [constants.appointmentStatus, currentChanges.data.statusId])
  const editable = React.useMemo(() =>
    !constants.appointmentStatus.find(as => as.value === currentChanges.data.statusId && ['done', 'canceled'].includes(as.key))
  , [constants.appointmentStatus, currentChanges.data.statusId])
  const isNew = !currentChanges.data.statusId

  React.useEffect(() => {
    if (!routeParams.id && currentChanges.data.recurrenceId) {
      const dateEnd = currentChanges.data.dateEnd

      api.get('/appointments/occurrences', undefined, {
        dateAppointment: currentChanges.data.dateAppointment,
        dateEnd: `${dateEnd.getFullYear()}-${dateEnd.getMonth() + 1}-${dateEnd.getDate()}`,
        recurrenceId: currentChanges.data.recurrenceId,
        userIdAssignedTo: currentChanges.data.userIdAssignedTo && '[' + currentChanges.data.userIdAssignedTo + ']',
        ...(currentChanges.data.recurrenceId === 2 && { 'days[mon]': days['mon'] }),
        ...(currentChanges.data.recurrenceId === 2 && { 'days[tue]': days['tue'] }),
        ...(currentChanges.data.recurrenceId === 2 && { 'days[wed]': days['wed'] }),
        ...(currentChanges.data.recurrenceId === 2 && { 'days[thu]': days['thu'] }),
        ...(currentChanges.data.recurrenceId === 2 && { 'days[fri]': days['fri'] }),
        ...(currentChanges.data.recurrenceId === 2 && { 'days[sat]': days['sat'] }),
        ...(currentChanges.data.recurrenceId === 2 && { 'days[sun]': days['sun'] })
      })
        .then(response => setNbOccurrences(response.nbOccurrences))
        .catch(() => setNbOccurrences(0)) // TODO: Maybe handle error on it
    }
  }, [api, currentChanges.data.dateAppointment, currentChanges.data.dateEnd, currentChanges.data.recurrenceId, currentChanges.data.userIdAssignedTo, routeParams.id, days])

  const addressHandleChange = React.useCallback(e => {
    const addressId = e ? e.addressId : undefined

    const addressSelected = addressId
      ? addresses.find(address => String(address.addressId) === String(addressId))
      : undefined

    setSelectedAddress(addressId)
    const addressLabel = addressSelected ? addressSelected.addressLabel : ''
    const addressComplement = addressSelected ? addressSelected.addressComplement : ''
    const addressFull = addressSelected ? addressSelected.addressFull : ''
    const mapId = addressSelected ? addressSelected.mapId : ''

    dispatchCurrentChanges({ type: 'change', payload: { addressComplement, addressLabel, addressFull, mapId } })

  }, [addresses])

  const handleChange = React.useCallback(({ target: { name, value } }) => {
    if (name === 'userIdAssignedTo' && isNew) {
      value = value && value.map(v => v.userId)
    }
    dispatchCurrentChanges({ type: 'change', payload: { [name]: value } })
  }, [isNew])

  const handleChangeDays = React.useCallback(dayClicked => {
    const newDays = {}
    Object.entries(days).map(day => {
      const [key, value] = day
      if (key === dayClicked) {
        newDays[key] = value === 0 ? 1 : 0
      } else {
        newDays[key] = value
      }
    })
    setDays(newDays)
  }, [days])

  const handleCompanyId = React.useCallback(value => {
    setCompanyId(value)
    history.replace({
      search: location.search,
      state: { ...location.state, c: value }
    })
  }, [history, location.search, location.state])

  const handleUserTypeId = React.useCallback(value => {
    setUserTypeId(value)
    history.replace({
      search: location.search,
      state: { ...location.state, t: value }
    })
  }, [history, location.search, location.state])

  const filterUsers = React.useCallback(value => {
    setSearchUser(value)
    history.replace({
      search: location.search,
      state: { ...location.state, s: value }
    })
  }, [history, location.search, location.state])

  const handleChangeAddressFull = React.useCallback(e => {
    e.persist()
    dispatchCurrentChanges({ type: 'change', payload: { addressFull: e.target.value } })
    api.get('/maps/autocomplete', undefined, {
      input: e.target.value,
      ...(autocomplete && autocomplete.session_token && { session_token: autocomplete.session_token })
    })
      .then(response => {
        setAutocomplete(response)
      })
      .catch(error => console.log(error))
  }, [api, autocomplete])

  const handleAutocomplete = React.useCallback(e => {
    dispatchCurrentChanges({ type: 'change', payload: { addressFull: e } })
    api.get('/maps/placedetails', undefined, {
      place_id: autocomplete.results.find(r => r.addressFull === e).place_id,
      session_token: autocomplete.session_token,
      addressFull: e
    })
      .then(response => dispatchCurrentChanges({ type: 'change', payload: { mapId: response.mapId } }))
      .catch(error => console.log(error))
  }, [api, autocomplete])

  const post = React.useCallback(() => {
    const dateEnd = currentChanges.data.dateEnd
    // eslint-disable-next-line no-unused-vars
    const { addressFull, ...changeData } = currentChanges.data
    const appointment = {
      ...changeData,
      businessId: routeParams.id ? undefined : currentChanges.data.businessId,
      dateEnd: routeParams.id ? undefined : `${dateEnd.getFullYear()}-${dateEnd.getMonth() + 1}-${dateEnd.getDate()}`,
      statusId: undefined,
      businessName: undefined,
      ...(currentChanges.data.recurrenceId === 2 && { days: days }),
    }

    dispatchCurrentChanges({ type: 'saving' })

    Promise.all([
      api.post('/appointments/details', { body: JSON.stringify(appointment) }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => {
        if (routeParams.id) {
          dispatchCurrentChanges({ type: 'saved', data })
          setSavedAlert(s => clearTimeout(s))
          setSavedAlert(setTimeout(() => setSavedAlert(0), 5000))
        } else {
          history.push({
            pathname: `/appointments`,
            search: location.state ? location.state.appointments : undefined
          })
        }
      })
      .catch(error => dispatchCurrentChanges({ type: 'error', error }))
  }, [api, currentChanges.data, history, location.state, routeParams.id, days])

  const cancel = React.useCallback(() => {
    dispatchCancel({ type: 'load' })
    api.del('/appointments/details', undefined, { appointmentId: routeParams.id })
      .then(data => {
        dispatchCurrentChanges({ type: 'saved', data })
        dispatchCancel({ type: 'success' })
      })
      .catch(error => dispatchCancel({ type: 'error', error }))
  }, [api, routeParams.id])

  const itvStatus = React.useMemo(() => constants.interventionStatus.reduce((acc, status) => ({ ...acc, [status.key]: status.value }), {}), [constants])
  const swapStatus = React.useMemo(() => constants.swapStatus.reduce((acc, status) => ({ ...acc, [status.key]: status.value }), {}), [constants])

  React.useEffect(() => {
    if (plannings && plannings.length > 0) {
      const getInterventionStyle = intervention => {
        const style = {}
        if ([itvStatus.done, itvStatus.canceled, itvStatus.closed].includes(intervention.statusId)) {
          style['backgroundColor'] = 'rgba(0, 0, 0, .4)'
        } else if (intervention.statusId === itvStatus.scheduled && (new Date(intervention.datePlanned).getTime() + intervention.durationPlanned * 1000 * 60) < Date.now()) {
          style['backgroundColor'] = 'rgba(255, 0, 0, .4)'
        } else {
          style['backgroundColor'] = 'rgba(0, 125, 250, .4)'
        }
        return style
      }

      const getSwapStyle = swap => {
        const style = {}
        if ([swapStatus.done, swapStatus.canceled].includes(swap.statusId)) {
          style.background = 'repeating-linear-gradient(125deg, rgba(246, 186, 82, .5), rgba(246, 186, 82, .5) 50%, rgba(0, 0, 0, .4) 50%, rgba(0, 0, 0, .4) 100%)'
        } else if (swap.statusId === swapStatus.scheduled && (new Date(swap.datePlanned).getTime() + (swap.durationPlanned * 1000 * 60)) < Date.now()) {
          style.background = 'repeating-linear-gradient(125deg, rgba(255, 0, 0, .5), rgba(255, 0, 0, .5) 50%, rgba(255, 209, 128, .4) 50%, rgba(255, 209, 128, .4) 100%)'
        } else {
          style.background = 'repeating-linear-gradient(125deg, rgba(246, 186, 82, 1), rgba(246, 186, 82, 1) 50%, rgba(255, 209, 128, 1) 50%, rgba(255, 209, 128, 1) 100%)'
        }
        return style
      }

      const users = !searchUser || searchUser.trim().length === 0 ? plannings
        : plannings.filter(user => (user.firstName + ' ' + user.lastName).toLowerCase().indexOf(searchUser.toLowerCase()) !== -1)

      const planningItems = users.reduce((acc, user) =>
        acc
          .concat(user.interventions
            .map(intervention => ({
              id: `timelineItem-itv-${intervention.interventionId}`,
              type: 'intervention',
              group: intervention.userId,
              canMove: false,
              className: intervention.difficultyValue,
              timeStart: new Date(intervention.datePlanned).getTime(),
              timeEnd: new Date(intervention.datePlanned).getTime() + intervention.durationPlanned * 60 * 1000,
              style: getInterventionStyle(intervention),
              intervention
            }))
          )
          .concat(user.swaps.map(swap => ({
            id: `timelineItem-swap-${swap.swapId}`,
            type: 'swap',
            group: user.userId,
            canMove: false,
            className: swap.difficultyValue,
            timeStart: (new Date(swap.datePlanned).getTime()),
            timeEnd: new Date(swap.datePlanned).getTime() + swap.durationPlanned * 60 * 1000,
            style: getSwapStyle(swap),
            swap
          })))
          .concat(user.appointments
            .map(appointment => ({
              id: `timelineItem-app-${appointment.appointmentId}`,
              type: 'appointment',
              group: appointment.userId,
              canMove: false,
              timeStart: new Date(appointment.dateAppointment).getTime(),
              timeEnd: new Date(appointment.dateAppointment).getTime() + appointment.durationPlanned * 60 * 1000,
              style: { 'backgroundColor': 'rgba(125, 0, 125, .4)' },
              appointment
            }))
          )
          .concat(user.unavailabilities.map(unavailability => ({
            id: `timelineItem-unv-${unavailability.unavailabilityId}`,
            type: 'unavailability',
            group: user.userId,
            canMove: false,
            stackable: false,
            timeStart: new Date(unavailability.dateStart).getTime(),
            timeEnd: new Date(unavailability.dateEnd).getTime(),
            style: {
              background: 'repeating-linear-gradient(135deg,#eeeeee,#eeeeee 10px,#cacaca 10px,#cacaca 20px)',
              zIndex: 0
            },
            unavailability
          })))
          .concat(user.maintenanceplans.map(_maintenance => ({
            id: `timelineItem-mai-${_maintenance.maintenanceplanId}`,
            type: 'maintenance',
            group: user.userId,
            canMove: false,
            className: _maintenance.difficultyValue,
            timeStart: new Date(_maintenance.datePlanned).getTime(),
            timeEnd: new Date(_maintenance.datePlanned).getTime() + _maintenance.durationPlanned * 60 * 1000,
            style: { 'backgroundColor': 'rgba(226, 134, 59, .4)' },
            maintenance: _maintenance
          })))
      , [])
      setPlanningItems(planningItems)
    }
  }, [plannings, itvStatus, swapStatus, searchUser])

  React.useEffect(() => {
    if (planningsUsers && planningsUsers.users) {
      const users = !searchUser || searchUser.trim().length === 0 ? planningsUsers.users
        : planningsUsers.users.filter(user => (user.firstName + ' ' + user.lastName).toLowerCase().indexOf(searchUser.toLowerCase()) !== -1)
      setPlanningGroups(users.map(user => ({ id: user.userId, title: user.firstName + user.lastName })))
    }
  }, [planningsUsers, itvStatus, swapStatus, searchUser])

  const items = React.useMemo(() => {
    if (planningItems) {
      if (currentChanges.data.appointmentId) {
        const matchAppointment = planningItems.find(item => item.type === 'appointment' && item.appointment.appointmentId === currentChanges.data.appointmentId)
        if (matchAppointment) {
          return planningItems.map(item => item === matchAppointment
            ? {
              ...item,
              style: { 'backgroundColor': 'rgba(125, 0, 125, .4)' },
              group: currentChanges.data.userIdAssignedTo || -1,
              timeStart: new Date(currentChanges.data.dateAppointment).getTime(),
              timeEnd: new Date(currentChanges.data.dateAppointment).getTime() + currentChanges.data.duration * 1000 * 60,
            }
            : item)
        }
      }
      return planningItems.concat({
        id: `timelineItem-app-${currentChanges.data.appointmentId || 'temp'}`,
        type: 'appointment',
        group: currentChanges.data.userIdAssignedTo || -1,
        canMove: false,
        timeStart: new Date(currentChanges.data.dateAppointment).getTime() || 0,
        timeEnd: (new Date(currentChanges.data.dateAppointment).getTime() + currentChanges.data.duration * 1000 * 60) || 0,
        style: { 'backgroundColor': 'rgba(125, 0, 125, .4)' },
        appointment: currentChanges.data
      })
    }
  }, [currentChanges.data, planningItems])

  if (planningsUsersError) {
    return <ErrAlert error={planningsUsersError} />
  }

  return (
    <>
      <TNavLink id="returnToList" tag={Link}
        to={{
          pathname: '/appointments',
          search: location.state ? location.state.appointments : undefined
        }} />

      <Card>
        <CardHeader>
          <T id="title" className="h3" />
        </CardHeader>
        <CardBody className="AppointmentPlan">

          <FormGroup className="mb-4">
            <TLabel id="businessId.label" for="businessId" />
            <CustomSelect
              id="businessId"
              value={business && business.find(b => b.businessId === currentChanges.data.businessId)}
              options={(business && business) || []}
              isDisabled={businessLoading || loading || currentChanges.loading || routeParams.id || !editable}
              onChange={option => handleChange({ target: { name: 'businessId', value: option.businessId } })}
              placeholder={<T id="businessId.placeholder" />}
              noOptionsMessage={() => <T id="businessId.noResult" />}
              getOptionLabel={option => option.businessName}
              getOptionValue={option => option.businessId} />
          </FormGroup>

          <TLabel id="addressTitle" />
          <Card className="mb-4">
            <CardBody>
              <FormGroup tag="fieldset">
                <TLabel for="addresses" className="mt-0" id="addresses.label" />
                {addressesLoading && <Spinner className="" color="primary" />}
                {!addressesLoading && addressesError && <ErrAlert error={addressesError} />}
                {!addressesLoading && !addressesError && (
                  <CustomSelect
                    inputId="addresses"
                    name="addresses"
                    options={addresses && addresses}
                    onChange={addressHandleChange}
                    placeholder={<T id="addresses.manual" />}
                    value={addresses && addresses.filter(a => a.addressId === selectedAddress) || ''}
                    isClearable
                    isDisabled={!addresses || loading || currentChanges.loading || !editable}
                    getOptionLabel={option => `${option.addressLabel} – ${option.addressFull} ${option.addressComplement && '- ' + option.addressComplement}`}
                    getOptionValue={option => option.addressId} />
                )}
              </FormGroup>
              <FormGroup tag="fieldset" className="flex-grow-1 d-block">
                <TLabel for="addressLabel" className="justify-content-start mb-2" id="addressLabel" />
                <Input id="addressLabel"
                  className="w-100"
                  type="text"
                  name="addressLabel"
                  disabled={loading || currentChanges.loading || !editable || selectedAddress}
                  value={currentChanges.data.addressLabel || ''}
                  onChange={handleChange} />
              </FormGroup>
              <FormGroup tag="fieldset" className="w-100 d-block">
                <TLabel for="addressFull" className="mb-2" id="addressFull" />
                <TAutocomplete id="addressFull"
                  className="w-100"
                  type="text"
                  name="addressFull"
                  disabled={loading || currentChanges.loading || !editable || selectedAddress}
                  value={currentChanges.data.addressFull || ''}
                  placeholder="addressFullPlaceholder"
                  searchOnFocus={e => handleChangeAddressFull(e)}
                  onChange={e => handleChangeAddressFull(e)}
                  onAutocomplete={e => handleAutocomplete(e)}
                  options={
                    autocomplete && autocomplete.results.length > 0 && autocomplete.results.map(r => {
                      return (r.addressFull)
                    })} />
              </FormGroup>
              <FormGroup tag="fieldset" className="w-100 mb-0">
                <TLabel for="addressComplement" className="mb-2" id="addressComplement" />
                <TInput id="addressComplement"
                  className="w-100"
                  type="text"
                  name="addressComplement"
                  disabled={loading || currentChanges.loading || !editable || selectedAddress}
                  value={currentChanges.data.addressComplement || ''}
                  placeholder="addressComplementPlaceholder"
                  onChange={handleChange} />
              </FormGroup>
            </CardBody>
          </Card>

          <FormGroup tag="fieldset" className="mb-4">
            <TLabel id="information.label" for="information" />
            <Input id="information"
              type="textarea"
              name="information"
              disabled={loading || currentChanges.loading || !editable}
              value={currentChanges.data.information || ''}
              onChange={handleChange} />
          </FormGroup>

          {!routeParams.id && <FormGroup className="mb-4">
            <TLabel id="recurrenceId" for="recurrenceId" />
            <div>
              {constants.appointmentRecurrences.map(rec => <TCustomInput key={rec.key}
                id={`recurrenceId-${rec.key}`}
                className="mb-2"
                type="radio"
                name={`recurrenceId`}
                label={`recurrence.${rec.key}`}
                raw
                value={rec.value}
                checked={rec.value === currentChanges.data.recurrenceId}
                disabled={loading || currentChanges.loading || !editable}
                onChange={() => handleChange({ target: { name: 'recurrenceId', value: rec.value } })}
                inline />)}
            </div>
          </FormGroup>}

          <div>
            <FormGroup className="mb-4">
              <TLabel id="duration.label" for="duration" />
              <div className="d-flex align-items-center h5">
                <Duration tagName={Badge} className="mr-2 mb-0" duration={currentChanges.data.duration} />
                <TCustomInput type="range" min="15" max="480" step="15"
                  id="duration"
                  name="duration"
                  value={currentChanges.data.duration}
                  onChange={handleChange}
                  disabled={loading || currentChanges.loading || !editable} />
              </div>
            </FormGroup>
          </div>

          {!routeParams.id && currentChanges.data.recurrenceId && currentChanges.data.recurrenceId === 2 && (
            <div>
              <FormGroup className="mb-4">
                <TLabel id="choseDays" />
                <div>
                  {constants.appointmentDays && constants.appointmentDays.map(day =>
                    <TButton
                      id={`days.${day}`}
                      key={day}
                      onClick={() => handleChangeDays(day)}
                      color='primary'
                      style={days[day] === 0
                        ? { backgroundColor: '#cacaca', color: 'black', border: '0px', marginRight: 6 }
                        : { backgroundColor: '#dd9c02', marginRight: 4 }}
                    />
                  )}
                </div>
              </FormGroup>
            </div>
          )}
          {nbOccurrences !== undefined && (
            <TAlert
              id="nbOccurrences"
              className="mb-4"
              color={nbOccurrences > 0 ? 'primary' : 'danger'}
              values={{ nbOccurrences }}
            />
          )}

          <div className="d-flex align-items-center mb-3">
            <FormGroup className="mb-4">
              <TLabel id="dateAppointment" />
              <div className="d-flex">
                <i className="btn btn-secondary simple-icon-arrow-left px-2 mr-1"
                  onClick={() => {
                    handleChange({ target: { value: new Date(currentChanges.data.dateAppointment.getTime() - (3600 * 1000 * 24)), name: 'dateAppointment' } })
                  }}
                />
                <TDatePicker
                  wrapperClassName="w-100"
                  disabled={loading || currentChanges.loading || !editable}
                  customInput={<SlotSelector className="datetime-fixed" />}
                  showTimeSelect
                  selected={currentChanges.data.dateAppointment}
                  onChange={value => handleChange({ target: { value, name: 'dateAppointment' } })}
                  dateFormat="eeee dd/MM/yyyy, HH:mm"
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  minTime={new Date(new Date().setHours(timeStartWork, 0, 0, 0))}
                  maxTime={new Date(new Date().setHours(timeEndWork, 0, 0, 0))}
                />
                <i className="btn btn-secondary simple-icon-arrow-right px-2 ml-1"
                  onClick={() => {
                    handleChange({ target: { value: new Date(currentChanges.data.dateAppointment.getTime() + (3600 * 1000 * 24)), name: 'dateAppointment' } })
                  }}
                />
              </div>
            </FormGroup>

            {!routeParams.id && currentChanges.data.recurrenceId && currentChanges.data.recurrenceId !== 1 && (
              <FormGroup className="mb-4 ml-4">
                <TLabel id="dateEnd" />
                <div className="d-flex">
                  <i className="btn btn-secondary simple-icon-arrow-left px-2 mr-1"
                    onClick={() => {
                      handleChange({ target: { value: new Date(currentChanges.data.dateEnd.getTime() - (3600 * 1000 * 24)), name: 'dateEnd' } })
                    }}
                  />
                  <TDatePicker
                    wrapperClassName="w-100"
                    disabled={loading || currentChanges.loading || !editable}
                    customInput={<SlotSelector className="date-fixed" />}
                    selected={currentChanges.data.dateEnd}
                    onChange={value => handleChange({ target: { value, name: 'dateEnd' } })}
                    dateFormat="eeee dd/MM/yyyy"
                    minDate={currentChanges.data.dateAppointment}
                  />
                  <i className="btn btn-secondary simple-icon-arrow-right px-2 ml-1"
                    onClick={() => {
                      handleChange({ target: { value: new Date(currentChanges.data.dateEnd.getTime() + (3600 * 1000 * 24)), name: 'dateEnd' } })
                    }}
                  />
                </div>
              </FormGroup>)}

            {planningsUsers &&
              <InputGroup className="mx-3 flex-nowrap w-auto">
                <TInput type="text"
                  id="searchUser"
                  name="searchUser"
                  style={{ minWidth: 150 }}
                  value={searchUser}
                  autoComplete="off"
                  onChange={e => filterUsers(e.target.value)}
                  placeholder="searchUser" />
                <CustomSelect
                  id="selectCompany"
                  onChange={e => handleCompanyId(e.value)}
                  isSearchable={false}
                  name="selectCompany"
                  options={planningsUsers.filters.companyId.values}
                  value={planningsUsers.filters.companyId.selected}
                  isDisabled={loading || currentChanges.loading || !editable}
                  getOptionLabel={option => option.value == 0 ? <T raw id={`companyId.${option.value}`} /> : <>{option.label}</>} />
                <CustomSelect
                  id="userTypeId"
                  onChange={e => handleUserTypeId(e.value)}
                  isSearchable={false}
                  name="userTypeId"
                  options={[{ value: 0, key: 'all' }].concat(constants.userTypes)}
                  value={constants.userTypes.find(_userType => _userType.value === Number(planningsUsers.filters.userTypeId.selected.value)) || { value: 0, key: 'all' }}
                  isDisabled={loading || currentChanges.loading || !editable}
                  getOptionLabel={option => <T raw id={`userType.${option.key}`} />} />
              </InputGroup>}
          </div>

          <div>
            {planningsUsers && planningsUsers.users && <FormGroup className="mb-4">
              <TLabel id="userIdAssignedTo.label" for="userIdAssignedTo" />
              <CustomSelect
                id="userIdAssignedTo"
                value={planningsUsers.users && planningsUsers.users.find(u => u.userId === currentChanges.data.userIdAssignedTo)}
                options={(planningsUsers.users && planningsUsers.users) || []}
                isDisabled={loading || currentChanges.loading || !editable}
                onChange={option => handleChange({ target: { name: 'userIdAssignedTo', value: isNew ? option : option.userId } })}
                placeholder={<T id="userIdAssignedTo.placeholder" />}
                isMulti={isNew}
                noOptionsMessage={() => <T id="userIdAssignedTo.noResult" />}
                getOptionLabel={option => `${option.firstName} ${option.lastName}`}
                getOptionValue={option => option.userId} />
            </FormGroup>}
          </div>

          {currentChanges.data.statusId !== 4 && planningsUsers && planningsUsers.filters && items && !planningsUsersLoad &&
            <Timeline
              groups={planningGroups}
              items={items}
              direction="horizontal"
              itemHeightRatio={1}
              duration={timeEndWork - timeStartWork}
              defaultTimeStart={new Date(planningsUsers.filters.dayStart).setHours(timeStartWork, 0, 0, 0)}
              defaultTimeEnd={new Date(planningsUsers.filters.dayStart).setHours(timeEndWork, 0, 0, 0)}
              itemRenderer={PlanningItem}
            />}
        </CardBody>
        <CardFooter>
          <TAlert id="saveSuccess" isOpen={savedAlert > 0} toggle={() => setSavedAlert(s => clearTimeout(s))} />
          <ErrAlert error={currentChanges.error} isOpen={Boolean(currentChanges.error)} toggle={() => dispatchCurrentChanges({ type: 'dismissError' })} />
          <div className="d-flex">
            {routeParams.id &&
              <TButton
                id="cancel"
                color="danger"
                onClick={() => dispatchCancel({ type: 'open' })}
                disabled={loading || currentChanges.loading || !cancellable} />}

            <div className="ml-auto">
              <TButton
                id="post"
                onClick={() => post()}
                disabled={loading || currentChanges.loading || !editable} />
            </div>
          </div>
        </CardFooter>
      </Card>

      <Modal isOpen={cancelState.open} fade={true} toggle={() => !cancelState.loading && dispatchCancel({ type: 'close' })}>
        <ModalBody>
          <div className="mb-1"><T id="modal.cancel.content" /></div>
          {cancelState.error && <ErrAlert error={cancelState.error} className="mb-0 mt-2" />}
        </ModalBody>
        <ModalFooter>
          <TButton
            id="modal.cancel.cancel"
            disabled={cancelState.loading}
            onClick={() => dispatchCancel({ type: 'close' })} />
          <TButton
            id="modal.cancel.confirm"
            disabled={cancelState.loading} loading={cancelState.loading}
            className="ml-2" color="danger" onClick={() => cancel()} />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default AppointmentDetails
