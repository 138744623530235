import React from 'react'
import { AppContext } from 'contexts/AppContext'

export const hasRights = (rs, ps) => ps.find(p => p.rights.find(r => rs.includes(r)))

export const useHasRights = (rights) => {
  const { profiles } = React.useContext(AppContext)

  if (!profiles) {
    return null
  }

  return hasRights(Array.isArray(rights) ? rights : [rights], profiles)
}

const ProtectedComponent = ({rights, children}) => {
  const hasRights = useHasRights(rights)

  if (rights && !hasRights) {
    return null
  }

  return children
}

export default ProtectedComponent
