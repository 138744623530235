import React from 'react'
import {
  Card, CardBody, CardFooter,
  Modal, ModalBody, ModalFooter,
  FormGroup, Input
} from 'reactstrap'
import { Link } from 'react-router-dom'

import { AppContext } from 'contexts/AppContext'

import ProtectedComponent from 'components/ProtectedComponent'
import { T, TAlert, TLabel, TButton, ErrAlert, TNavLink } from 'components/TComponents'

const PartEdit = ({ match }) => {

  const { api } = React.useContext(AppContext)

  const [loading, setLoading] = React.useState(true)
  const [loadError, setLoadError] = React.useState(false)
  const [part, setPart] = React.useState()
  const [edit, setEdit] = React.useState(false)
  const [updating, setUpdating] = React.useState(false)
  const [updateError, setUpdateError] = React.useState()
  const [updateSuccess, setUpdateSuccess] = React.useState()
  const [modalOpen, setModalOpen] = React.useState(false)
  const [archiveError, setArchiveError] = React.useState()
  const [archive, setArchive] = React.useState(false)

  React.useEffect(() => {
    api.get('/parts/details', undefined, { partId: match.params.id })
      .then(response => setPart(response))
      .catch(response => setLoadError(response))
      .then(() => setLoading(false))
  }, [api, match])

  const handleChangePart = React.useCallback((value, target) => {
    setPart(part => ({
      ...part,
      [target]: value
    }))
  }, [])

  const handleSave = React.useCallback(() => {
    setUpdateSuccess()
    setUpdateError()
    setUpdating(true)
    api.post('/parts/details', {
      body: JSON.stringify({
        partId: part.partId,
        partRef: part.partRef,
        partLabel: part.partLabel,
        description: part.description,
        externalIdentifier: part.externalIdentifier,
        barcode: part.barcode,
        supplier: part.supplier,
        unit: part.unit,
        purchasePrice: part.purchasePrice || 0,
      })
    })
      .then(() => {
        setEdit(false)
        setUpdateSuccess(true)
      })
      .catch(error => setUpdateError(error.code))
      .then(() => setUpdating(false))
  }, [api, part])

  const handleCancel = React.useCallback(() => {
    setEdit(false)
    setUpdateSuccess(false)
    setUpdateError(false)
  }, [])

  const archivePart = React.useCallback(() => {
    setArchive(true)
    api.del('/parts/details', undefined, { partId: part.partId })
      .then(response => {
        setPart(response)
        setModalOpen(false)
      })
      .catch(response => setArchiveError(response))
      .then(() => setArchive(false))
  }, [api, part])

  return (
    <>
      <TNavLink id="returnToList" tag={Link} to="/veloparts/parts" />
      {!loading && !loadError && part && (
        <>
          <Card>
            <CardBody>
              <FormGroup tag="fieldset">
                <TLabel for="partRef" id="partRef" />
                <Input id="partRef"
                  type="text"
                  name="partRef"
                  value={part.partRef}
                  disabled={!edit}
                  onChange={e => { handleChangePart(e.target.value, 'partRef') }}
                />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel for="partLabel" id="partLabel" />
                <Input id="partLabel"
                  type="text"
                  name="partLabel"
                  value={part.partLabel}
                  disabled={!edit}
                  onChange={e => { handleChangePart(e.target.value, 'partLabel') }}
                />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel for="externalIdentifier" id="externalIdentifier" />
                <Input id="externalIdentifier"
                  type="text"
                  name="externalIdentifier"
                  value={part.externalIdentifier}
                  disabled={!edit}
                  onChange={e => { handleChangePart(e.target.value, 'externalIdentifier') }}
                />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel for="unit" id="unit" />
                <Input id="unit"
                  type="text"
                  name="unit"
                  value={part.unit}
                  disabled={!edit}
                  onChange={e => { handleChangePart(e.target.value, 'unit') }}
                />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel for="barcode" id="barcode" />
                <Input id="barcode"
                  type="text"
                  name="barcode"
                  value={part.barcode}
                  disabled={!edit}
                  onChange={e => { handleChangePart(e.target.value, 'barcode') }}
                />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel for="description" id="description" />
                <Input id="description"
                  type="text"
                  name="description"
                  value={part.description}
                  disabled={!edit}
                  onChange={e => { handleChangePart(e.target.value, 'description') }}
                />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel for="supplier" id="supplier" />
                <Input id="supplier"
                  type="text"
                  name="supplier"
                  value={part.supplier}
                  disabled={!edit}
                  onChange={e => { handleChangePart(e.target.value, 'supplier') }}
                />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel for="purchasePrice" id="purchasePrice" />
                <Input id="purchasePrice"
                  type="number"
                  name="purchasePrice"
                  value={part.purchasePrice}
                  disabled={!edit}
                  onChange={e => { handleChangePart(e.target.value, 'purchasePrice') }}
                />
              </FormGroup>
              <div>
                {updateError && <TAlert id={updateError} className="mb-0" color="danger" />}
                {updateSuccess && <TAlert id="updateSuccess" className="mb-0" color="success" />}
              </div>
            </CardBody>
            <CardFooter>
              <ProtectedComponent rights={['veloparts-parts_edit']}>
                <div className="d-flex">
                  {!part.dateArchived && (
                    <TButton id='archive' loading={updating} disabled={updating} onClick={() => setModalOpen(true)} color="primary" />
                  )}
                  <div className="ml-auto pl-2 d-flex">
                    {!edit && <TButton id="edit" onClick={() => setEdit(true)} />}
                    {edit && <TButton id="cancel" loading={updating} disabled={updating} className="ml-2" onClick={() => handleCancel()} color="warning" />}
                    {edit && <TButton id="save" loading={updating} disabled={updating} className="ml-2" onClick={() => handleSave()} color="success" />}
                  </div>
                </div>
              </ProtectedComponent>
            </CardFooter>
          </Card>
          <Modal isOpen={modalOpen} fade={false} toggle={() => setModalOpen(false)}>
            <ModalBody>
              <T id='archiveContent' />
              {archiveError && <ErrAlert error={archiveError} className="mb-0 mt-2" />}
            </ModalBody>
            <ModalFooter>
              <TButton id="archiveCancel"
                disabled={archive}
                loading={archive}
                onClick={() => setModalOpen(false)} />
              <TButton id={`archiveConfirm`}
                loading={archive} disabled={archive} className="ml-2" color="danger" onClick={() => archivePart()} />
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  )
}

export default PartEdit
