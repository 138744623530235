import React from 'react'
import { NavLink } from 'react-router-dom'

import {
  Card, CardHeader,
  Form, FormGroup,
  Row, Col,
  Spinner
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import Pagination from 'components/Pagination'
import ProtectedComponent from 'components/ProtectedComponent'
import { ErrAlert, TLabel, TInput, TButton, } from 'components/TComponents'

import { debounce } from 'util/Utils'

const ItvcodelistList = () => {
  const { api } = React.useContext(AppContext)

  const [data, setData] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()

  const [searchInput, setSearchInput] = React.useState('')

  React.useEffect(() => {
    api.get('/itvcodelists/list')
      .then(data => setData(data))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api])

  const refresh = React.useCallback((params) => {
    setLoading(true)
    setError()
    Promise.all([
      api.get('/itvcodelists/list', undefined, {
        ...data.filters,
        ...params
      }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => setData(data))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api, data])

  const refreshDebounced = React.useMemo(() => debounce(params => refresh(params), 250), [refresh])

  const handleSearchInput = React.useCallback(({ target: { value } }) => {
    setSearchInput(value)
    refreshDebounced({ filter: value, p: 1 })
  }, [refreshDebounced])

  return (
    <>
      <Card className="mb-2">
        <CardHeader>
          <Form onSubmit={e => e.preventDefault()}>
            <Row form>
              <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel for="searchInput" id="searchInputLabel" className="" />
                  <TInput
                    name="searchInput"
                    type="text"
                    placeholder="searchInputPlaceholder"
                    value={searchInput}
                    onChange={handleSearchInput} />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardHeader>
        {/* TODO: add `rights={[]}` */}
        <ProtectedComponent>
          <CardHeader>
            <TButton tag={NavLink}
              to="./itvcodelists/new" id="create" />
          </CardHeader>
        </ProtectedComponent>
      </Card>

      {loading && <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />}
      {!loading && error && <ErrAlert error={error} />}
      {!loading && !error && <TLabel className="mt-2" id="count"
        values={{ total: data.total }} />}
      {!loading && data.result.map(itvcodelist => <Card key={itvcodelist.itvcodelistId} className="mb-2">
        <CardHeader className="d-flex align-items-center">
          <span className="h5 mb-0">{itvcodelist.itvcodelistLabel}</span>
          <TButton className="ml-auto stretched-link" outline
            tag={NavLink}
            to={`./itvcodelists/${itvcodelist.itvcodelistId}`}
            id="view" />
        </CardHeader>
      </Card>)}

      {data && <Pagination totalPage={data.totalPage} currentPage={data.currentPage} onPageClick={p => refresh({ p })} />}
    </>
  )
}

export default ItvcodelistList
