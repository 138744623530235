/* eslint-disable react/display-name */
import React from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'
import { useLocation, useHistory, NavLink } from 'react-router-dom'
import { objectToQuery, queryToObject } from 'react-rest-api'

import { Card, CardHeader, } from 'reactstrap'

import { useGet } from 'hooks/useApi.hooks'
import { useFormatMessage } from 'hooks/intl.hooks'

import ProtectedComponent from 'components/ProtectedComponent'
import { T, ErrAlert, TButton } from 'components/TComponents'
import { Table } from 'components/Table'
import { Filters } from 'components/Filters'

const SwapList = () => {
  const location = useLocation()
  const history = useHistory()
  const formatMesage = useFormatMessage()

  const filters = React.useMemo(() => ({
    p: {
      type: 'number'
    },
    ipp: {
      type: 'number'
    },
    'order[column]': {},
    'order[dir]': {},
    filter: {
      type: 'string',
      componentType: 'textDebounce'
    },
    businessId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isSearchable: true,
        getOptionValue: option => option.label,
        isMulti: false
      },
    },
    categoryId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false
      },
    },
    familyId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false
      },
    },
    statusId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: true,
        getOptionLabel: option => formatMesage({ id: `swapStatus.${option.label}`, raw: true })
      }
    },
  }), [formatMesage])

  const moreFilters = React.useMemo(() => ({
    subscriptionId: {
      type: 'string',
      componentType: 'textDebounce'
    },
    address: {
      type: 'string',
      componentType: 'textDebounce'
    },
    datePlanned: {
      componentType: 'date',
      transform: value => {
        if (!value) {
          return value
        }
        const date = new Date(value)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      },
      componentOptions: {
        placeholderText: formatMesage({ id: `filters.datePlanned.placeholder` }),
        dateFormat: 'dd/MM/yyyy'
      },
      childKeys: ['datePlannedBefore', 'datePlannedAfter']
    },
    datePlannedBefore: {
      transform: value => {
        if (!value) {
          return value
        }
        const date = new Date(value)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      },
    },
    datePlannedAfter: {
      transform: value => {
        if (!value) {
          return value
        }
        const date = new Date(value)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      },
    },
    equipment: {
      type: 'string',
      componentType: 'textDebounce'
    },
    userAssign: {
      type: 'string',
      componentType: 'textDebounce'
    },
    companyId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false,
        getOptionLabel: option => option.value == 0 ? <T raw id={`companyId.${option.value}`} /> : option.label
      },
    },
  }), [formatMesage])

  // DATA
  const params = React.useMemo(() => queryToObject(location.search, { ...filters, ...moreFilters }), [location.search, filters, moreFilters])

  const [data, refreshing, error, loading] = useGet(`/swaps/list`, undefined, params)

  const refresh = React.useCallback((_params = {}) => history.push({ search: objectToQuery({ ...params, ..._params }) }), [history, params])

  // INNER COMPONENTS
  const DatePlanned = React.useCallback(swap => {
    const style = {}

    if (swap.statusValue === 'scheduled' && swap.datePlanned && (new Date(swap.datePlanned).getTime() + swap.durationPlanned * 60 * 1000) < Date.now()) {
      style.color = 'red'
    }

    return (
      swap.datePlanned
        ? <span style={style}><FormattedDate value={new Date(swap.datePlanned)} />{' '}<FormattedTime value={new Date(swap.datePlanned)} /></span>
        : <T id="noDatePlanned" />

    )
  }, [])

  const DetailsButton = React.useCallback(item => (
    <TButton className="ml-auto stretched-link" outline
      id="details"
      tag={NavLink}
      to={{
        pathname: `/swaps/${item.swapId}`,
        state: { swaps: location.search }
      }}
    />
  ), [location])

  // RENDER
  if (error) {
    return <ErrAlert error={error} />
  }

  return (
    <div className="container-fluid">
      <Card className="mb-2">
        <CardHeader>
          <Filters
            loading={loading}
            disabled={refreshing}
            data={data}
            refresh={refresh}
            filters={filters}
            moreFilters={moreFilters}
          />
        </CardHeader>
        <CardHeader className="d-flex">
          <ProtectedComponent rights={['swaps_new']}>
            <TButton tag={NavLink}
              id="create"
              to={{
                pathname: './swaps/new',
                state: { swaps: location.search }
              }} />
          </ProtectedComponent>

          <div className="ml-auto">
            <TButton tag={NavLink}
              id="table"
              to={{
                pathname: './swaps/table',
                state: { swaps: location.search }
              }} />
          </div>
        </CardHeader>
      </Card>
      <Table
        isLoading={loading}
        disabled={refreshing}
        data={data}
        refresh={refresh}
        columns={[
          {
            title: 'swapId',
            hasOrder: true,
            render: item => item.swapId
          },
          {
            title: 'businessName',
            hasOrder: true,
            render: item => item.businessName
          },
          {
            title: 'addressFull',
            render: item => item.addressFull
          },
          {
            title: 'datePlanned',
            hasOrder: true,
            render: DatePlanned
          },
          {
            title: 'equipmentIdentifier',
            render: item => item.equipmentIdentifier
          },
          {
            title: 'categoryName',
            render: item => item.categoryName
          },
          {
            title: 'familyName',
            render: item => item.familyName
          },
          {
            title: 'userName',
            render: item => item.userName
          },
          {
            title: 'statusValue',
            render: item => formatMesage({ id: `swapStatus.${item.statusValue}`, raw: true })
          },
          {
            className: () => 'align-middle',
            render: DetailsButton
          },
        ]}
      />
    </div>
  )
}

export default SwapList
