import React from 'react'

import {
  Button,
  Card, CardHeader, CardBody,
  Modal, ModalBody, ModalFooter,
  Form, FormGroup, FormText,
  ListGroup, ListGroupItem,
  Spinner, Progress
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import { T, TLabel, TInput, TAlert } from 'components/TComponents'

import { debounce } from 'util/Utils'

const Desappairing = () => {
  const { api } = React.useContext(AppContext)

  const [actualLvmIMEI, setActualLvmIMEI] = React.useState('')
  const [IMEILoad, setIMEILoad] = React.useState(false)
  const [IMEIResult, setIMEIResult] = React.useState()
  const [IMEIError, setIMEIError] = React.useState()
  const [pairLoad, setPairLoad] = React.useState(false)
  const [pairResult, setPairResult] = React.useState()

  const [selectedEquipment, setSelectedEquipment] = React.useState()
  const [selectedEquipmentId, setSelectedEquipmentId] = React.useState()
  const [selectedEquipmentLoad, setSelectedEquipmentLoad] = React.useState(false)
  const [selectedEquipmentSuccess, setSelectedEquipmentSuccess] = React.useState(false)
  const [selectedEquipmentError, setSelectedEquipmentError] = React.useState()

  const [preventUpdate, setPreventUpdate] = React.useState()

  React.useEffect(() => {
    if (pairResult && pairResult.status === 'pulling') {
      setTimeout(() => { // TODO: here is an obvious memory leak using setTimeout -> change page during loading
        api.get(pairResult.params.endpoint, undefined, pairResult.params.query)
          .then(response => {
            setPairResult(pairSuccess => ({
              ...pairSuccess,
              ...response
            }))

            if (response.status !== 'pulling') {
              setSelectedEquipment()
            }
            setPairLoad(false)
          })
          .catch(error => {
            setPairResult({
              ...error,
              status: 'danger'
            })
            setPairLoad(false)
          })
      }, 1000)
    }
  }, [api, pairResult])

  const handleIMEISuccess = React.useCallback((response) => {
    setActualLvmIMEI(value => {
      if (value === response.sn) {
        setIMEIError()
        setIMEIResult(response)
        setIMEILoad(false)
      }
      return value
    })
  }, [])

  const handleIMEIError = React.useCallback((response) => {
    setIMEIResult()
    setIMEIError(response)
    setIMEILoad(false)
  }, [])

  const checkIMEI = React.useMemo(() => debounce((sn) => {
    setIMEILoad(true)
    api.get('/fluow/cmInfos', undefined, { sn })
      .then(handleIMEISuccess)
      .catch(handleIMEIError)
  }, 250), [api, handleIMEIError, handleIMEISuccess])

  const handleIMEI = React.useCallback(({ target: { value } }) => {
    setActualLvmIMEI(value)
    setPairResult()
    checkIMEI(value)
  }, [checkIMEI])

  const checkEquipment = React.useMemo(() => debounce((equipmentIdentifier) => {
    if (equipmentIdentifier && equipmentIdentifier.length > 0) {
      setSelectedEquipmentLoad(true)
      api.get('/fluow/checkAssignRequEquipment', undefined, { equipmentIdentifier })
        .then(response => {
          if (response.equipmentId) {
            setSelectedEquipmentError()
            setSelectedEquipmentSuccess({
              hasLivemodule: response.hasLivemodule,
              imei_livemodule: response.imei_livemodule,
              marque_livemodule: response.marque_livemodule,
            })
            setSelectedEquipmentId(response.equipmentId)
          } else {
            setSelectedEquipmentSuccess()
            setSelectedEquipmentId()
          }
        })
        .catch(error => {
          setSelectedEquipmentSuccess()
          setSelectedEquipmentError(error.code)
          setSelectedEquipmentId()
        })
        .then(() => setSelectedEquipmentLoad(false))
    } else {
      setSelectedEquipmentError()
      setSelectedEquipmentSuccess()
    }
  }, 250), [api])

  const handleEquipment = React.useCallback(({ target: { value } }) => {
    setSelectedEquipment(value)
    checkEquipment(value)
  }, [checkEquipment])

  const unassign = React.useCallback((override = {}) => {
    const payload = {
      lvmIMEI: 0,
      actualLvmIMEI,
      equipmentId: selectedEquipmentId,
      dateAssembly: new Date(),
      ...override
    }

    setPreventUpdate()
    setPairResult()
    setPairLoad(true)
    api.post('/fluow/pairinglvm', { body: JSON.stringify(payload) })
      .then(response => {
        setPairResult(response)
        if (response.status === 'pulling') {
          return
        }
        setSelectedEquipment()
        setPairLoad(false)
      })
      .catch(response => {
        setPairLoad(false)
        response && response.code === 'preventUpdate'
          ? setPreventUpdate({
            ...response.params,
            callback: unassign
          })
          : setPairResult({
            ...response,
            status: 'danger'
          })
      })
  }, [api, actualLvmIMEI, selectedEquipmentId])

  const canDesappair = actualLvmIMEI && selectedEquipmentId && selectedEquipmentSuccess && selectedEquipmentSuccess.hasLivemodule && !pairLoad

  return (
    <Card className="mb-5">
      <CardHeader><T className="h5" id="title.unpair" /></CardHeader>
      <CardBody>

        <Form>
          <div className="d-flex">
            <FormGroup className="mr-2" tag="fieldset">
              <TLabel id="lvmIMEILabel" for="lvmIMEI" />
              <TInput
                className="p-2 w-100"
                type="text"
                name="lvmIMEI"
                value={actualLvmIMEI}
                onChange={handleIMEI}
                placeholder="lvmIMEIPlaceholder"
                disabled={pairLoad}
                autoFocus="autofocus"
                autoComplete="off" />
              {IMEILoad && <Spinner size="sm" color="primary" className="ml-2" />}
              {IMEIResult && IMEIResult.msg && <FormText color={IMEIResult.valid ? 'success' : 'danger'}><T id={`IMEIResult`} values={{ status: IMEIResult.msg }} /></FormText>}
              {IMEIError && <FormText color="danger"><T raw id={`error.${IMEIError.code}`} values={IMEIError.params} /></FormText>}
            </FormGroup>

            <FormGroup className="mr-2" tag="fieldset">
              <TLabel id="equipmentLabel" for="equipment" />
              <TInput
                className="p-2 w-100"
                type="text"
                name="equipment"
                value={selectedEquipment}
                onChange={handleEquipment}
                placeholder="equipmentPlaceholder"
                disabled={pairLoad}
                autoFocus="autofocus"
                autoComplete="off" />
              {selectedEquipmentLoad && <Spinner size="sm" color="primary" className="ml-2" />}
              {selectedEquipmentSuccess && <FormText color='success'>
                <>
                  {selectedEquipmentSuccess.hasLivemodule
                    ? <T id='EquipmentSuccessLVM' values={{ marque_livemodule: selectedEquipmentSuccess.marque_livemodule, imei_livemodule: selectedEquipmentSuccess.imei_livemodule }} />
                    : <T id='EquipmentSuccessNoLVM' />}
                </>
              </FormText>}
              {selectedEquipmentError && <FormText color="danger"><T raw id={`error.${selectedEquipmentError.code}`} /></FormText>}
            </FormGroup>

            <FormGroup tag="fieldset" style={{ paddingTop: 27 }}>
              <Button className="pt-2 pb-2 ml-2 d-block"
                type="button"
                disabled={!canDesappair || IMEILoad}
                onClick={() => unassign()}>
                {pairLoad && <Spinner size="sm" className="mr-2" color="primary" />}
                <T id="unpair" />
              </Button>
            </FormGroup>

          </div>

          {pairResult && !['pulling', 'danger'].includes(pairResult.status) && <TAlert id={pairResult.code || 'success'} values={pairResult.params} color={pairResult.status || 'success'}></TAlert>}
          {pairResult && pairResult.status === 'danger' && <TAlert id={`error.${pairResult.code}`} values={pairResult.params} color="danger"></TAlert>}
          {pairResult && pairResult.status === 'pulling' && (
            <div className="mb-4">
              <Progress striped value={pairResult.value || 0} />
              <T id={pairResult.code || 'pulling'} values={pairResult.params} className="text-muted pt-2" tagName="p" />
            </div>
          )}
        </Form>
      </CardBody>

      {preventUpdate && <Modal isOpen={true} fade={true} toggle={() => setPreventUpdate()}>
        <ModalBody>
          <T id="modal.preventUpdate.content" values={preventUpdate} />

          <ListGroup className="mt-2">
            {preventUpdate.reasons && preventUpdate.reasons.map(reason => (
              <ListGroupItem key={reason}><T id={`modal.preventUpdate.reason.${reason}`} values={preventUpdate} /></ListGroupItem>
            ))}
          </ListGroup>
        </ModalBody>
        <ModalFooter className="py-3">
          <Button disabled={pairLoad}
            onClick={() => setPreventUpdate()}>
            <T id="modal.preventUpdate.cancel" />
          </Button>
          <Button disabled={pairLoad} spin={pairLoad}
            className="ml-2"
            color="danger"
            onClick={() => preventUpdate.callback({ force: preventUpdate.reasons })}>
            <T id="modal.preventUpdate.confirm" />
          </Button>
        </ModalFooter>
      </Modal>}
    </Card>
  )
}

export default Desappairing
