import React from 'react'
import {
  Nav,
  NavItem,
  TabContent,
  NavLink,
  TabPane
} from 'reactstrap'
import { Redirect, Switch, NavLink as RRNavLink } from 'react-router-dom'

import ProtectedComponent from 'components/ProtectedComponent'
import Route from 'components/Route'
import { T } from 'components/TComponents'

import VeloptimManagement from './VeloptimManagement'
import VeloptimMapTour from './VeloptimMapTour'
import VeloptimZones from './VeloptimZones'
import VeloptimWaiting from './VeloptimWaiting'

const Veloptim = ({ match }) => {
  return (
    <div className="container-fluid">
      <Nav tabs className="mb-3">
        <div className="navitem-container">
          <NavItem>
            <NavLink
              tag={RRNavLink}
              activeClassName="active"
              to="/veloptim/management">
              <T id="management" />
            </NavLink>
          </NavItem>
        </div>
        <NavItem className="mr-2" />
        <div className="navitem-container">
          <ProtectedComponent rights={['veloptim-plannedTour']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/veloptim/map">
                <T id="mapTour" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
          <ProtectedComponent rights={['veloptim-minWaiting']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/veloptim/limit">
                <T id="limit" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
          <ProtectedComponent rights={['veloptim-availableUserByDay']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/veloptim/zones">
                <T id="zones" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
        </div>
      </Nav>

      <TabContent>
        <Switch>
          <TabPane tag={Route} path={`${match.path}/map`} component={VeloptimMapTour} />
          <TabPane tag={Route} path={`${match.path}/zones`} component={VeloptimZones} />
          <TabPane tag={Route} path={`${match.path}/limit`} component={VeloptimWaiting} />
          <TabPane tag={Route} path={`${match.path}/management`} component={VeloptimManagement} />
          <TabPane tag={Redirect} to={`veloptim/management/`} />
        </Switch>
      </TabContent>
    </div>
  )
}

export default Veloptim
