import React from 'react'
import { Switch } from 'react-router-dom'

import Route from 'components/Route'

import List from 'routes/Admin/Users/UserList'
import New from 'routes/Admin/Users/UserNew'
import Edit from 'routes/Admin/Users/UserEdit'

export default function Users({ match }) {
  return <Switch>
    <Route exact path={`${match.path}`} component={List} rights={['admin-users_edit', 'admin-users_view',]} />
    <Route path={`${match.path}/new`} component={New} rights={['admin-users_edit',]} />
    <Route path={`${match.path}/:id`} component={Edit} rights={['admin-users_edit', 'admin-users_view',]} />
  </Switch>
}
