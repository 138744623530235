import React from 'react'
import CustomSelect from 'components/CustomSelect'

import {
  Spinner,
  Card, CardBody, CardFooter,
  Form, FormGroup,
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'
import { T, TButton, TLabel, ErrAlert } from 'components/TComponents'

const SelectWrapper = props => {
  return (
    <CustomSelect
      isSearchable={true}
      placeholder={'...'}
      {...props} />
  )
}

const MatrixCreate = ({ onClose }) => {
  const { api } = React.useContext(AppContext)

  const [loading, setLoading] = React.useState(true)
  const [loadError, setLoadError] = React.useState() // TODO

  const [matrices, setMatrices] = React.useState()
  const [businesses, setBusinesses] = React.useState()
  const [categories, setCategories] = React.useState()
  const [families, setFamilies] = React.useState()

  const [matrix, setMatrix] = React.useState('')
  const [business, setBusiness] = React.useState('')
  const [category, setCategory] = React.useState('')
  const [family, setFamily] = React.useState('')

  const [creating, setCreating] = React.useState(false)
  const [createError, setCreateError] = React.useState()

  React.useEffect(() => {
    Promise.all([
      api.get('/matrices/src', undefined, { ipp: -1 }),
      api.get('/business/list', undefined, { ipp: -1 }),
      api.get('/categories/list', undefined, { ipp: -1 })
    ])
      .then(([matrices, business, categories]) => {
        setMatrices(matrices.map(matrix => ({
          label: matrix.matrixName,
          value: matrix.matrixId
        })))
        setBusinesses(business.result.map(bizbi => ({
          label: bizbi.businessName,
          value: bizbi.businessId,
        })))
        setCategories(categories.result.map(category => ({
          label: category.categoryName,
          value: category.categoryId,
        })))
      })
      .catch(response => setLoadError(response))
      .then(() => setLoading(false))
  }, [api])

  const create = React.useCallback(() => {
    setCreating(true)
    setCreateError()
    setLoadError()
    api.post('/matrices/details', {
      body: JSON.stringify({
        matrixId: matrix.value,
        businessId: business.value,
        categoryId: category.value,
        familyId: family.value
      })
    })
      .then(() => {
        setCreating(false)
        onClose()
      })
      .catch(response => {
        setCreateError(response)
        setCreating(false)
      })
  }, [api, business, category, family, matrix, onClose])

  const onCategoryChanged = React.useCallback(category => {
    setFamilies()
    setCategory(category)
    api.get('/families/list', undefined, { ipp: -1, categoryId: category.value })
      .then(families => {
        setFamilies(families.result.map(family => ({
          label: family.familyName,
          value: family.familyId,
        })))
      })
      .catch(response => setLoadError(response))
  }, [api])

  if (loadError) {
    return (
      <Card>
        <CardBody>
          <ErrAlert error={loadError} className="mb-0" />
        </CardBody>
        <CardFooter>
          <TButton id="create.discard" onClick={onClose}
            disabled={creating} />
        </CardFooter>
      </Card>
    )
  }

  if (loading) {
    return <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />
  }

  return (
    <Card className="mb-2">
      <CardBody>
        <Form onSubmit={e => e.preventDefault()}>
          <FormGroup tag="fieldset">
            <TLabel id="create.matrixId" for="matrixId" />
            <SelectWrapper
              id="matrixId"
              onChange={setMatrix}
              name="matrixId"
              options={matrices}
              value={matrix}
              noOptionsMessage={() => <T id="create.noMatrices" />} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel id="create.businessId" for="businessId" />
            <SelectWrapper
              id="businessId"
              onChange={setBusiness}
              name="businessId"
              options={businesses}
              value={business}
              noOptionsMessage={() => <T id="create.noBusiness" />} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel id="create.categoryId" for="categoryId" />
            <SelectWrapper
              id="categoryId"
              onChange={onCategoryChanged}
              name="categoryId"
              options={categories}
              value={category}
              noOptionsMessage={() => <T id="create.noCategories" />} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel id="create.familyId" for="familyId" />
            <SelectWrapper
              id="familyId"
              onChange={setFamily}
              name="familyId"
              isDisabled={!families}
              options={families}
              value={family}
              noOptionsMessage={() => <T id="create.noFamilies" />} />
          </FormGroup>
        </Form>
        {createError && <ErrAlert error={createError} className="mb-0" />}
      </CardBody>
      <CardFooter className="d-flex justify-end">
        <TButton id="create.discard" onClick={onClose}
          disabled={creating} />
        <TButton color="primary" className="ml-2" id="create.create"
          disabled={creating}
          loading={creating}
          onClick={create} />
      </CardFooter>
    </Card>
  )
}

export default MatrixCreate
