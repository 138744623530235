import React from 'react'
import { FormattedDate } from 'react-intl'
import { objectToQuery } from 'react-rest-api'
import {
  Button, Modal, ModalBody, ModalFooter,
  FormGroup, Row, Col,
  Card, CardHeader, Spinner
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'
import { debounce } from 'util/Utils'

import VehicleIcon from 'components/VehicleIcon'
import CustomSelect from 'components/CustomSelect'
import { T, TAutocomplete, TAlert, TCustomInput, TButton, TInput, TLabel, TDatePicker } from 'components/TComponents'
import ProtectedComponent, { useHasRights } from 'components/ProtectedComponent'
import SlotSelector from 'components/SlotSelector'
import { Sheet } from 'components/Sheet'
import ItvcodeSelector from 'components/ItvcodeSelector'
import { getColors } from 'util/Utils'

/* HELPERS FUNCTIONS */
const STATUS = Object.freeze({
  IDLE: 'idle',
  INITIALIZING: 'initializing',
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
})

const wait = async (promise, delay = 250) => {
  return Promise.all([
    promise,
    new Promise(resolve => setTimeout(resolve, delay))
  ]).then(([payload]) => payload)
}

const removeOffset = initialDate => {
  const date = new Date(initialDate)
  return new Date(date - (date.getTimezoneOffset() * 60000))
}

const calendarReducer = (state, action) => {
  if (action.payload) {
    const userPayload = {
      userId: action.payload.userId,
      userName: action.payload.userName,
      response: action.payload.response,
      error: action.payload.error
    }
    return {
      payload: {
        users: (state && state.payload && state.payload.users) ? [...state.payload.users.filter((user => user.userId !== userPayload.userId)), userPayload] : [userPayload],
        days: (action.payload.response && Object.entries(action.payload.response)) || {},
      }
    }
  } else {
    return {}
  }

}

const VeloptimManagement = ({ location, history }) => {

  const [users, setUsers] = React.useState()
  const [usersError, setUsersError] = React.useState()
  const [usersLoading, setUsersLoading] = React.useState(true)

  const [searchUser, setSearchUser] = React.useState()
  const [params, setParams] = React.useState({})

  const [rule, setRule] = React.useState()
  const [ruleModal, setRuleModal] = React.useState(false)
  const [ruleDay, setRuleDay] = React.useState(false)
  const [getRuleError, setGetRuleError] = React.useState()
  const [postRuleError, setPostRuleError] = React.useState()
  const [itvcodes, setItvcodes] = React.useState([])
  const [excludeItvcodes, setExcludeItvcodes] = React.useState(false)

  const [autocomplete, setAutocomplete] = React.useState()
  const [addressFull, setAddressFull] = React.useState()

  const [vehicles, setVehicles] = React.useState([])
  const [vehiclesLoading, setVehiclesLoading] = React.useState(false)
  const [vehiclesError, setVehiclesError] = React.useState()

  const [zoningGroups, setZoningGroups] = React.useState([])
  const [zoningGroupsLoading, setZoningGroupsLoading] = React.useState(false)
  const [zoningGroupsError, setZoningGroupsError] = React.useState()

  const [businessAddresses, setBusinessAddresses] = React.useState()
  const [businessAddressesError, setBusinessAddressesError] = React.useState()

  const [modalOpen, setModalOpen] = React.useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)

  const tourViewRight = useHasRights('veloptim-tour_view')
  const skillRight = useHasRights('admin-veloptim_skill')
  const zoningRight = useHasRights('admin-veloptim_zoninggroup')
  const typologyRight = useHasRights('admin-veloptim_typology')
  const vehicleRight = useHasRights('admin-veloptim_vehicle')

  const { api, constants } = React.useContext(AppContext)
  const tagColors = getColors()

  const buildWeek = React.useCallback(date => {
    return {
      firstDay: getFirstWeekDay(date),
      lastDay: getLastWeekDay(date),
    }
  }, [])

  const getFirstWeekDay = initialDate => {
    const date = removeOffset(initialDate)
    const day = date.getDay()
    const diff = date.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
    date.setDate(diff)
    return date.toISOString().substring(0, 10)
  }

  const getLastWeekDay = initialDate => {
    const date = removeOffset(initialDate)
    const day = date.getDay()
    const diff = date.getDate() - day + (day === 0 ? -6 : 1) + 6 // adjust when day is sunday
    date.setDate(diff)
    return date.toISOString().substring(0, 10)
  }

  const [calendar, dispatchCalendar] = React.useReducer(calendarReducer, { status: STATUS.INITIALIZING })
  const [currentWeek, setCurrentWeek] = React.useState(() => buildWeek(new Date()))
  const [dateSelected, setDateSelected] = React.useState(new Date())

  React.useEffect(() => {
    if (location.state) {
      history.replace({ search: objectToQuery({ ...location.state }), state: location.state })
    }
  }, [history, location.state])

  React.useEffect(() => {
    getBusinessAddresses()
  }, [getBusinessAddresses])

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    setParams(params => ({
      ...params,
      companyId: searchParams.get('c') || params.companyId,
      userTypeId: searchParams.get('t') || params.userTypeId,
      user: searchParams.get('user')
    }))
    setSearchUser(searchParams.get('user'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  React.useEffect(() => {
    if (Object.keys(params).length !== 0) {
      api.get('/veloptim/dashboard', undefined, { ...params })
        .then(response => {
          setUsers(response)
          if (response && response.users && response.users.length === 0) {
            setUsersLoading(false)
          }
        })
        .catch(response => {
          setUsersError(response)
          setUsersLoading(false)
        })
    }
  }, [api, params])

  const refresh = React.useCallback((_params = {}) => history.push({ search: objectToQuery({ ...params, ..._params }) }), [history, params])

  React.useEffect(() => {
    users && users.users && fetchCalendar()
  }, [users, fetchCalendar])

  React.useEffect(() => {
    currentWeek && fetchCalendar()
  }, [fetchCalendar, currentWeek])

  const fetchCalendar = React.useCallback(() => {
    dispatchCalendar({ status: STATUS.LOADING })
    users && users.users.map(user => {
      api.get('/veloptim/tours', undefined, { userId: user.userId, dayStart: currentWeek.firstDay })
        .then(response => dispatchCalendar({ status: STATUS.LOADED, payload: { response, userId: user.userId, userName: `${user.firstName} ${user.lastName}` } }))
        .then(() => setUsersLoading(false))
    })
  }, [api, currentWeek, users])

  const getCalendar = React.useCallback(user => {
    wait(api.get('/veloptim/tours', undefined, { userId: user.userId, dayStart: currentWeek.firstDay }))
      .then(response => dispatchCalendar({ status: STATUS.LOADED, payload: { response, userId: user.userId, userName: `${user.firstName} ${user.lastName}` } }))
  }, [api, currentWeek])

  const orderCalendarUsers = React.useCallback(calendarUsers => {
    const order = users && users.users.map(u => u.userId)
    return order ? calendarUsers.sort((a, b) => order.indexOf(a.userId) - order.indexOf(b.userId)) : calendarUsers
  }, [users])

  const getBusinessAddresses = React.useCallback(() => {
    wait(api.get('/business/businessAddresses'))
      .then(response => setBusinessAddresses(response))
      .catch(error => setBusinessAddressesError(error.code))
  }, [api])

  const refreshDebounced = React.useMemo(() => debounce(params => refresh(params), 250), [refresh])

  const handleSearchUser = React.useCallback(value => {
    setSearchUser(value)
    refreshDebounced({ user: value })
  }, [refreshDebounced])

  const handleUserTypeId = React.useCallback(value => {
    const searchParams = new URLSearchParams(location.search)
    const locationState = {
      c: searchParams.get('c'),
      t: searchParams.get('t'),
      user: searchParams.get('user')
    }
    history.replace({
      search: location.search,
      state: { ...locationState, t: value }
    })
  }, [history, location.search])

  const handleCompanyId = React.useCallback(value => {
    const searchParams = new URLSearchParams(location.search)
    const locationState = {
      c: searchParams.get('c'),
      t: searchParams.get('t'),
      user: searchParams.get('user')
    }
    history.replace({
      search: location.search,
      state: { ...locationState, c: value }
    })
  }, [history, location.search])

  const changeWeek = React.useCallback((direction, isDatePicker) => {
    if (isDatePicker) {
      setCurrentWeek(buildWeek(direction))
    } else {
      const newDate = new Date(currentWeek.firstDay)
      newDate.setDate(direction === 'previous' ? newDate.getDate() - 7 : newDate.getDate() + 7)
      setCurrentWeek(buildWeek(newDate))
      setDateSelected(newDate)
    }
  }, [buildWeek, currentWeek])

  const getVehicles = React.useCallback(() => {
    setVehiclesLoading(true)
    api.get('/vehicles/list', undefined, { ipp: -1 })
      .then(response => setVehicles(response))
      .catch(error => setVehiclesError(error))
      .then(() => setVehiclesLoading(false))
  }, [api])

  const getZones = React.useCallback(() => {
    setZoningGroupsLoading(true)
    api.get('/zoninggroups/list', undefined)
      .then(response => setZoningGroups(response))
      .catch(error => setZoningGroupsError(error))
      .then(() => setZoningGroupsLoading(false))
  }, [api])

  const businessAddressHandleChange = React.useCallback(e => {
    if (e && e.value) {
      setRule(rule => ({
        ...rule,
        mapId: e.mapId
      }))
      setAddressFull(e.value)
    } else {
      setAddressFull('')
      setRule(rule => ({
        ...rule,
        mapId: undefined
      }))
    }
  }, [])

  const openRule = React.useCallback((column, row) => {
    const tourId = (row.response && Object.entries(row.response).find(r => (r[0] === column[0])) && Object.entries(row.response).find(r => (r[0] === column[0]))[1].tourId) || undefined
    const userId = row.userId
    setRuleDay(column[0])
    wait(api.get('/veloptim/tour', undefined, { userId, tourId: tourId }))
      .then(response => {
        if (response.exception) {
          setGetRuleError(response)
        } else {
          getZones()
          getVehicles()
          setRule(response)
          setAddressFull(response.addressFull)
          setExcludeItvcodes(response.excludeItvcodes)
          setItvcodes(response.itvcodes.reduce((acc, cat) => {
            cat.families.map(f => {
              f.items.map(c => {
                if (!c.enabled == !!response.excludeItvcodes) {
                  c.categoryName = cat.categoryName
                  c.familyName = f.familyName
                  acc.push(c)
                }
              })
            })
            return acc
          }, []))
          setRuleModal(true)
        }
      })
      .catch(error => setGetRuleError(error))
  }, [api, getZones, getVehicles])

  const changeRule = React.useCallback((type, name, e) => {
    switch (type) {
    case 'family':
      // eslint-disable-next-line no-case-declarations
      const familyArray = rule.families
      familyArray.map(f => {
        if (f.familyId === name) {
          f.enabled = f.enabled === 1 ? 0 : 1
        }
      })
      setRule(rule => ({
        ...rule,
        families: familyArray
      }))
      break
    case 'typology':
      setRule(rule => ({
        ...rule,
        userTypology: e,
        ...(e == 2 && { zoninggroups: [] }),
      }))
      break
    case 'limitMax':
      setRule(rule => ({
        ...rule,
        limitMax: parseInt(e)
      }))
      break
    case 'limitItv':
      setRule(rule => ({
        ...rule,
        limitItv: parseInt(e)
      }))
      break
    case 'limitSwap':
      setRule(rule => ({
        ...rule,
        limitSwap: parseInt(e)
      }))
      break
    case 'handleItv':
      setRule(rule => ({
        ...rule,
        handleItv: !rule.handleItv
      }))
      break
    case 'handleSwap':
      setRule(rule => ({
        ...rule,
        handleSwap: !rule.handleSwap
      }))
      break
    case 'techcenterRadius':
      setRule(rule => ({
        ...rule,
        techcenterRadius: parseInt(e)
      }))
      break
    case 'vehicleId':
      setRule(rule => ({
        ...rule,
        vehicleId: e
      }))
      break
    case 'zoninggroups':
      setRule(rule => ({
        ...rule,
        zoninggroups: e
      }))
      break
    case 'description':
      setRule(rule => ({
        ...rule,
        description: e
      }))
      break
    case 'colorHex':
      setRule(rule => ({
        ...rule,
        colorHex: e
      }))
      break
    }
  }, [rule])

  const deleteRule = React.useCallback((user) => {
    api.del('/veloptim/tour', undefined, {
      tourId: rule.tourId,
      day: ruleDay
    })
      .then(() => {
        setDeleteModalOpen(false)
        setRuleModal(false)
        getCalendar(user)
      })
      .catch(error => {
        setPostRuleError(error)
        setDeleteModalOpen(false)
      })
  }, [api, rule, getCalendar, ruleDay])

  const saveRule = React.useCallback((user, forceVehicle = false) => {
    setPostRuleError()
    let error = {}
    const completeItvCodes = rule.itvcodes.reduce((acc, c) => {
      c.families.map(f => {
        f.items.map(i => {
          acc.push(i.itvcodeId);
        })
      })
      return acc
    }, [])
    const completeItvCodesCopy = JSON.parse(JSON.stringify(completeItvCodes))
    let counter = 0
    const itvcodesToPost = excludeItvcodes
      ? completeItvCodes.map((c, i) => { itvcodes.map(it => { if (it.itvcodeId === c) { completeItvCodesCopy.splice(i - counter, 1); counter++ } }); return completeItvCodesCopy })[0]
      : itvcodes.reduce((acc, c) => { acc.push(c.itvcodeId); return acc }, [])

    if (skillRight && itvcodesToPost.length === 0 && rule && rule.handleItv) {
      error = { code: 'codesEmpty' }
    }
    if (skillRight && rule.families.reduce((acc, f) => { if (f.enabled === 1) { acc.push(f.familyId) } return acc }, []).length === 0) {
      error = { code: 'familyEmpty' }
    }
    if (skillRight && !rule.handleItv && !rule.handleSwap) {
      error = { code: 'typeEmpty' }
    }
    if (zoningRight && !rule.mapId) {
      error = { code: 'mapIdEmpty' }
    }
    if (error && Object.keys(error).length === 0) {
      const ruleToPost = {
        userId: rule.userId,
        tourId: rule.tourId,
        description: rule.description,
        colorHex: rule.colorHex,
        ...(rule.tourId && { tourId: rule.tourId }),
        ...(skillRight && { handleItv: rule.handleItv ? 1 : 0 }),
        ...(skillRight && { handleSwap: rule.handleSwap ? 1 : 0 }),
        ...(skillRight && { familiesId: rule.families.reduce((acc, f) => { if (f.enabled === 1) { acc.push(f.familyId) } return acc }, []) }),
        ...(skillRight && rule.handleItv && { itvcodesId: itvcodesToPost }),
        day: ruleDay,
        ...(skillRight && rule.handleItv && { excludeItvcodes }),
        ...(typologyRight && { mapId: rule.mapId }),
        ...(typologyRight && { limitMax: rule.limitMax }),
        ...(typologyRight && { limitItv: rule.limitItv }),
        ...(typologyRight && { limitSwap: rule.limitSwap }),
        ...(typologyRight && { userTypology: rule.userTypology }),
        ...(typologyRight && { techcenterRadius: rule.techcenterRadius }),
        ...(zoningRight && { zoninggroupsId: rule.zoninggroups ? rule.zoninggroups.map(z => z.zoninggroupId) : [] }),
        ...(vehicleRight && rule.vehicleId && { vehicleId: rule.vehicleId.vehicleId }),
        ...(forceVehicle && { forceVehicle: 1 })
      }
      api.post('/veloptim/tour', {
        body: JSON.stringify(ruleToPost)
      })
        .then(() => {
          setRuleModal(false)
          getCalendar(user)
        })
        .catch(error => {
          if (error.code === 'vehicleIdAlreadyUse') {
            setModalOpen(true)
          } else {
            setPostRuleError(error)
          }
        })
    } else {
      setPostRuleError(error)
    }

  }, [api, rule, ruleDay, itvcodes, excludeItvcodes, skillRight, typologyRight, zoningRight, vehicleRight, getCalendar])

  const handleChangeAddress = React.useCallback(e => {
    e.persist()
    setAddressFull(e.target.value)
    api.get('/maps/autocomplete', undefined, {
      input: e.target.value,
      ...(autocomplete && autocomplete.session_token && { session_token: autocomplete.session_token })
    })
      .then(response => {
        setAutocomplete(response)
      })
      .catch(error => console.log(error))
  }, [api, autocomplete])

  const handleAutocomplete = React.useCallback(e => {
    api.get('/maps/placedetails', undefined, {
      place_id: autocomplete.results.find(r => r.addressFull === e).place_id,
      session_token: autocomplete.session_token,
      addressFull: e
    })
      .then(response => {
        setRule(rule => ({
          ...rule,
          mapId: response.mapId
        }))
        setAddressFull(response.addressFull)
      })
      .catch(error => console.log(error))
  }, [api, autocomplete])

  const getBusinessAddressesValue = React.useCallback((data) => {
    if (rule && rule.mapId) {
      return (data.map(d => {
        if (d.options && d.options.length > 0) {
          return d.options.filter(option => option.mapId === rule.mapId)
        }
      }))[0]
    }
  }, [rule])

  const getFormattedOptions = data => {
    return (data.map(d => ({
      label: d.businessName,
      options: d.addresses[0].map(a => ({
        label: a.formatted_address,
        value: a.formatted_address,
        mapId: a.mapId
      }))
    })
    ))
  }

  const getFormattedItvcodesOptions = data => {
    return (data.map(d => (
      d.families.map(f => ({
        label: `${d.categoryName} - ${f.familyName}`,
        options: f.items.map(i => (
          {
            label: `${i.itvcodeIdentifier}  - ${i.itvcodeLabel} - (${d.categoryName}-${f.familyName})`,
            value: Object.assign({}, i, { categoryName: d.categoryName }, { familyName: f.familyName })
          })
        )
      }))
    ))[0])
  }

  return (
    <>
      <div className="w-100 sectionContent">
        <Card>
          <CardHeader>
            <Row form>
              <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel id="VeloptimUser.searchUser" for="searchUser" raw />
                  <TInput type="text"
                    id="searchUser"
                    name="searchUser"
                    disabled={usersLoading}
                    value={searchUser}
                    autoComplete="off"
                    onChange={e => handleSearchUser(e.target.value)}
                    placeholder="VeloptimUser.searchUser.placeholder"
                    raw />
                </FormGroup>
              </Col>
              <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel id="VeloptimUser.userTypeId" for="userTypeId" raw />
                  <CustomSelect
                    id="userTypeId"
                    onChange={e => handleUserTypeId(e.value)}
                    isSearchable={false}
                    name="userTypeId"
                    options={[{ value: 0, key: 'all' }].concat(constants.userTypes)}
                    value={constants.userTypes.find(_userType => users && _userType.value === Number(users.filters.userTypeId.selected.value)) || { value: 0, key: 'all' }}
                    isDisabled={usersLoading}
                    getOptionLabel={option => <T raw id={`userType.${option.key}`} />} />
                </FormGroup>
              </Col>
              <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel id="VeloptimUser.selectCompany" for="selectCompany" raw />
                  <CustomSelect
                    id="selectCompany"
                    onChange={e => handleCompanyId(e.value)}
                    isSearchable={false}
                    name="selectCompany"
                    options={users && users.filters.companyId.values}
                    value={users && users.filters.companyId.values.find(_companyId => _companyId.value === users.filters.companyId.selected.value)}
                    isDisabled={usersLoading}
                    getOptionLabel={option => option.value == 0 ? <T raw id={`companyId.${option.value}`} /> : <>{option.label}</>} />
                </FormGroup>
              </Col>
            </Row>
          </CardHeader>
        </Card>

        <div className="veloptim">
          {tourViewRight ? (
            <>
              <div className="parameters mb-5 border">
                <div className="d-flex flex-row align-items-center">
                  <div className="currentWeek border">
                    <Button outline onClick={() => changeWeek('previous')}>
                      <i className="simple-icon-arrow-left" />
                    </Button>
                    <span className="pl-2 pr-2"><FormattedDate value={new Date(currentWeek.firstDay)} />{' - '}<FormattedDate value={new Date(currentWeek.lastDay)} /></span>
                    <Button outline onClick={() => changeWeek('after')} >
                      <i className="simple-icon-arrow-right" />
                    </Button>
                  </div>
                  <div className="ml-3">
                    <TDatePicker
                      customInput={<SlotSelector />}
                      selected={dateSelected}
                      onChange={e => {
                        changeWeek(e, true)
                        setDateSelected(e)
                      }}
                      dayHeaderFormat="wide"
                      dateFormat="eeee dd/MM/yyyy"
                    />
                  </div>
                </div>
              </div>
              {usersError && <TAlert color="danger" className="w-100 mt-2" id={`VeloptimUser.${usersError.code}`} raw />}
              {!usersLoading && calendar && calendar.payload && calendar.payload.days && calendar.payload.days.length > 0 ? (
                <>
                  {calendar.payload.users && users && users.users ? (
                    <Sheet className="sheet w-100 mt-5 border"
                      columns={calendar.payload.days}
                      columnExtractKey={(column, j) => column.day ? `head-${column.day}` : `head-${j}`}
                      columnHeaderRender={column => (
                        column[0] ? (
                          <div className="columnHeader">
                            <FormattedDate value={new Date(column[0])} month="long" day="2-digit" weekday="short" />
                          </div>
                        ) : (<></>)
                      )}
                      doubleTitle
                      rows={orderCalendarUsers(calendar.payload.users)}
                      rowHeaderRender={row => (
                        <div className="columnHeader">
                          {row.userName}
                        </div>
                      )}
                      rowExtractKey={(_, i) => `lineplop-${i}`}
                      dataRender={(row, column) =>
                        row.response ? (
                          <>
                            <div className={`data d-flex ${row.response && Object.entries(row.response).find(r => r[0] === column[0] && r[1].isFull) && 'veloptim-unavailable'}`}>
                              <>{row.response && Object.entries(row.response).map(r =>
                                <div className="d-flex flex-row align-items-center" key={r[1].tourId}>
                                  <>
                                    {r[0] === column[0] && (
                                      <>
                                        {r[1].colorHex && <div className="color-tag mr-2" style={{ backgroundColor: r[1].colorHex }} />}
                                        {r[1].description && <span>{r[1].description}</span>}
                                      </>
                                    )}
                                  </>
                                </div>
                              )}</>
                              <>{row.response && Object.entries(row.response).map(r => {
                                if (r[0] === column[0] && r[1].vehicleType) {
                                  return (<div className="d-flex align-items-center" key={r[1].tourId}>
                                    <VehicleIcon type={r[1].vehicleType} />
                                    {r[1].vehicleIdentifier} - {r[1].vehicleLabel}
                                  </div>)
                                }
                              })}</>
                            </div>
                          </>
                        ) : (
                          <div className="nodata" />
                        )
                      }
                      onDataClick={(e, row, column) => openRule(column, row)} />
                  ) : (
                    <TAlert id="noResult" color="warning" />
                  )}
                </>
              ) : (
                <>
                  {usersLoading ? (
                    <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />
                  ) : (
                    <TAlert id="noResult" color="warning" />
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <TAlert id="error.accessDenied" color="danger" raw />
            </>
          )}
        </div>
        {getRuleError && <TAlert color="danger" className="w-100 mt-2" id={`VeloptimUser.${getRuleError.code}`} raw />}
        <>
          <Modal
            isOpen={ruleModal}
            fade={true}
            size="lg"
            toggle={() => {
              setRuleModal(false)
              setPostRuleError()
            }}>
            <ModalBody>
              <span className="d-block h6 mb-4" >
                <T id="VeloptimUser.modal.title" raw /> {` - `}
                <FormattedDate value={ruleDay} month="long" day="2-digit" weekday="long" />
              </span>
              <FormGroup tag="fieldset">
                <TLabel id="VeloptimUser.description" raw />
                <TInput
                  id="description"
                  className="w-100"
                  type="text"
                  name="description"
                  value={rule && rule.description}
                  placeholder="VeloptimUser.descriptionPlaceholder"
                  raw
                  onChange={e => { changeRule('description', undefined, e.currentTarget.value) }} />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="VeloptimUser.colorHex" raw />
                <CustomSelect
                  id="colorHex"
                  onChange={e => { changeRule('colorHex', undefined, e && e.value) }}
                  isSearchable={false}
                  name="colorHex"
                  placeholder={<T id="VeloptimUser.colorHex.placeholder" raw />}
                  isClearable
                  options={tagColors}
                  value={rule && rule.colorHex && tagColors.find(c => c.value === rule.colorHex)}
                  getOptionLabel={option => <div style={{ backgroundColor: option.value }} className="color-tag" />} />
              </FormGroup>
              <>
                <T id="VeloptimUser.modal.skills" className="d-block mb-3 h6" raw />
                <FormGroup tag="fieldset">
                  <TLabel id="VeloptimUser.type" raw />
                  <TCustomInput
                    id={`VeloptimUser.intervention`}
                    raw
                    type="checkbox"
                    name='intervention'
                    disabled={!skillRight}
                    label={`VeloptimUser.type.intervention`}
                    checked={rule && rule.handleItv}
                    onChange={(e) => { changeRule('handleItv', undefined, e.currentTarget.value) }} />
                  <TCustomInput
                    id={`VeloptimUser.swap`}
                    raw
                    type="checkbox"
                    name='swap'
                    disabled={!skillRight}
                    label={`VeloptimUser.type.swap`}
                    checked={rule && rule.handleSwap}
                    onChange={(e) => { changeRule('handleSwap', undefined, e.currentTarget.value) }} />
                </FormGroup>
                <FormGroup tag="fieldset">
                  <TLabel id="VeloptimUser.family" raw />
                  {rule && rule.families && rule.families.map(family => (
                    <>
                      <TCustomInput
                        key={family}
                        id={`VeloptimUser.${family.familyId}`}
                        raw
                        type="checkbox"
                        name={family.familyName}
                        label={family.familyName}
                        disabled={!skillRight}
                        checked={family.enabled === 1}
                        onChange={e => {
                          changeRule('family', family.familyId, e.currentTarget.value)
                        }} />
                    </>
                  ))}
                </FormGroup>
                {rule && !!rule.handleItv && (
                  <FormGroup tag="fieldset">
                    <TLabel id="VeloptimUser.excludeItvcodes" raw />
                    <TCustomInput
                      id={`VeloptimUser.exclude`}
                      raw
                      type="checkbox"
                      name="exclude"
                      className="mb-3"
                      label="VeloptimUser.excludeItvcodes"
                      checked={excludeItvcodes}
                      disabled={!skillRight}
                      onChange={() => setExcludeItvcodes(!excludeItvcodes)} />
                    <TLabel id={`VeloptimUser.itvcode`} raw />
                    {rule && rule.itvcodes && rule.itvcodes.length > 0 &&
                      <ItvcodeSelector
                        hasBusiness
                        itvcodesAdded={itvcodes}
                        itvcodes={getFormattedItvcodesOptions(rule.itvcodes)}
                        noQuantity
                        noWear
                        withFamilies
                        exclude={excludeItvcodes ? true : false}
                        withHeaders
                        clearable
                        editable={!!skillRight}
                        onChange={codes => setItvcodes(codes)} />
                    }
                  </FormGroup>
                )}
              </>
              {rule && !vehiclesLoading && !vehiclesError && (
                <>
                  <FormGroup tag="fieldset">
                    <TLabel id="VeloptimUser.vehicle" raw />
                    <CustomSelect
                      id="vehicles"
                      onChange={e => { changeRule('vehicleId', undefined, e) }}
                      value={rule && rule.vehicleId && vehicles && vehicles.result && vehicles.result.find(v => v.vehicleId === rule.vehicleId)}
                      isSearchable
                      isDisabled={!vehicleRight}
                      placeholder={<T id="VeloptimUser.vehicle.placeholder" raw />}
                      name="vehicles"
                      options={[{ vehicleId: 0 }].concat(vehicles && vehicles.result)}
                      getOptionLabel={option => option.vehicleId === 0
                        ? <T raw id={`VeloptimUser.noVehicle`} />
                        : <div className='d-flex align-items-center'>{option.vehicleIdentifier} - {option.vehicleLabel} <VehicleIcon type={option.typeId} /></div>}
                      getOptionValue={option => option.vehicleId} />
                  </FormGroup>
                </>
              )}
              <>
                <T id="VeloptimUser.modal.typology" className="d-block mb-3 h6" raw />
                <FormGroup tag="fieldset">
                  <TLabel id="VeloptimUser.userTypology" className="d-block" raw />
                  {constants && constants.veloptimUserTypology && constants.veloptimUserTypology.map(typology => (
                    <TCustomInput
                      key={typology.key}
                      id={typology.key}
                      type="radio"
                      checked={rule && rule.userTypology == typology.value}
                      name="typology"
                      disabled={!typologyRight}
                      value={typology.value}
                      label={`VeloptimUser.${typology.key}`}
                      raw
                      onChange={({ target: { value } }) => changeRule('typology', undefined, value)}
                      inline />
                  ))}
                </FormGroup>
                <FormGroup tag="fieldset">
                  <TLabel id="VeloptimUser.businessAddresses" className="d-block" raw />
                  {businessAddressesError && <TAlert color="danger" className="w-100 mt-2" id={`VeloptimUser.${businessAddressesError.code}`} raw />}
                  {businessAddresses && businessAddresses.length > 0 && (
                    <CustomSelect
                      id="businessAddresses"
                      className="flex-grow-1"
                      onChange={e => businessAddressHandleChange(e)}
                      isSearchable={true}
                      name="businessAddresses"
                      options={getFormattedOptions(businessAddresses)}
                      placeholder={<T id="VeloptimUser.businessAddresses.placeholder" raw />}
                      isClearable
                      isDisabled={!typologyRight}
                      value={getBusinessAddressesValue(getFormattedOptions(businessAddresses))}
                      noOptionsMessage={() => <T id="VeloptimUser.businessAddresses.noResult" raw />} />
                  )}
                </FormGroup>
                <FormGroup tag="fieldset">
                  <TLabel id="VeloptimUser.addressFull" raw />
                  <TAutocomplete id="addressFull"
                    className="w-100"
                    type="text"
                    name="addressFull"
                    value={addressFull}
                    placeholder="VeloptimUser.addressFullPlaceholder"
                    raw
                    disabled={!typologyRight}
                    searchOnFocus={e => handleChangeAddress(e)}
                    onChange={e => handleChangeAddress(e)}
                    onAutocomplete={e => handleAutocomplete(e)}
                    options={
                      autocomplete && autocomplete.results.length > 0 && autocomplete.results.map(r => {
                        return (r.addressFull)
                      })} />
                </FormGroup>
                <FormGroup tag="fieldset">
                  {rule && rule.handleSwap && rule.handleItv ? (
                    <>
                      <TLabel id="VeloptimUser.limitMax" raw />
                      <TInput
                        id="limitMax"
                        className="w-100"
                        type="number"
                        name="limitMax"
                        value={rule && rule.limitMax}
                        placeholder="VeloptimUser.limitMaxPlaceholder"
                        raw
                        disabled={!typologyRight}
                        onChange={e => { changeRule('limitMax', undefined, e.currentTarget.value) }} />
                      <TLabel id="VeloptimUser.limitSwap" raw />
                      <TInput
                        id="limitSwap"
                        className="w-100"
                        type="number"
                        name="limitSwap"
                        value={rule && rule.limitSwap}
                        placeholder="VeloptimUser.limitSwapPlaceholder"
                        disabled={!typologyRight}
                        raw
                        onChange={e => { changeRule('limitSwap', undefined, e.currentTarget.value) }} />
                      <TLabel id="VeloptimUser.limitItv" raw />
                      <TInput
                        id="limitItv"
                        className="w-100"
                        type="number"
                        name="limitItv"
                        value={rule && rule.limitItv}
                        placeholder="VeloptimUser.limitItvPlaceholder"
                        disabled={!typologyRight}
                        raw
                        onChange={e => { changeRule('limitItv', undefined, e.currentTarget.value) }} />
                    </>
                  ) : (
                    <>
                      <TLabel id="VeloptimUser.limitMax" raw />
                      <TInput
                        id="limitMax"
                        className="w-100"
                        type="number"
                        name="limitMax"
                        disabled={!typologyRight}
                        value={rule && rule.limitMax}
                        placeholder="VeloptimUser.limitMaxPlaceholder"
                        raw
                        onChange={e => { changeRule('limitMax', undefined, e.currentTarget.value) }} />
                    </>
                  )}
                </FormGroup>
                {rule && rule.userTypology == 2 && (
                  <FormGroup tag="fieldset">
                    <TLabel id="VeloptimUser.techcenterRadius" raw />
                    <TInput
                      id="techcenterRadius"
                      className="w-100"
                      type="number"
                      name="techcenterRadius"
                      value={rule && rule.techcenterRadius}
                      disabled={!typologyRight}
                      placeholder="VeloptimUser.techcenterRadiusPlaceholder" raw
                      onChange={e => { changeRule('techcenterRadius', undefined, e.currentTarget.value) }} />
                  </FormGroup>
                )}
              </>
              {rule && rule.userTypology == 1 && !zoningGroupsLoading && !zoningGroupsError && zoningGroups && (
                <>
                  <T id="VeloptimUser.modal.zoning" className="d-block mb-3 h6" raw />
                  <FormGroup tag="fieldset">
                    <TLabel id="VeloptimUser.zones" raw />
                    <CustomSelect
                      id="zones"
                      isMulti
                      onChange={e => { changeRule('zoninggroups', undefined, e) }}
                      value={rule && rule.zoninggroups}
                      placeholder={<T id="VeloptimUser.zonesPlaceholder" raw />}
                      isSearchable
                      isDisabled={!zoningRight}
                      name="zones"
                      options={zoningGroups && zoningGroups.result}
                      getOptionLabel={option => option.zoninggroupLabel}
                      getOptionValue={option => option.zoninggroupId} />
                  </FormGroup>
                </>
              )}
            </ModalBody>
            <ModalFooter className="justify-content-between">
              {postRuleError && <TAlert color="danger" className="w-100" id={`VeloptimUser.${postRuleError.code}`} raw />}
              <div>
                <ProtectedComponent rights={['admin-veloptim_delete']}>
                  <TButton color="danger" id={`VeloptimUser.ruleModal.delete`} raw onClick={() => { setDeleteModalOpen(true) }} />
                </ProtectedComponent>
              </div>
              <div>
                <TButton
                  id={`VeloptimUser.ruleModal.cancel`}
                  color="danger"
                  outline
                  raw
                  onClick={() => {
                    setRuleModal(false)
                    setPostRuleError()
                  }} />
                <TButton className="ml-2" id={`VeloptimUser.ruleModal.save`} raw onClick={() => { saveRule(users && users.users.filter(u => u.userId === rule.userId)[0]) }} />
              </div>
            </ModalFooter>
          </Modal>
        </>
      </div>
      <Modal isOpen={modalOpen} fade={false} toggle={() => { setModalOpen(false) }}>
        <ModalBody>
          <T id="VeloptimUser.vehicle.force" raw />
        </ModalBody>
        <ModalFooter>
          <TButton id="VeloptimUser.vehicle.cancel" onClick={() => { setModalOpen(false) }} raw />
          <TButton
            id="VeloptimUser.vehicle.confirm"
            color="danger"
            onClick={() => {
              setModalOpen(false)
              saveRule(users && users.users.filter(u => u.userId === rule.userId)[0], true)
            }}
            className="ml-2 d-flex align-items-center"
            raw />
        </ModalFooter>
      </Modal>

      <Modal isOpen={deleteModalOpen} fade={false} toggle={() => { setDeleteModalOpen(false) }}>
        <ModalBody>
          <T id="VeloptimUser.delete.information" raw />
        </ModalBody>
        <ModalFooter>
          {rule && rule.tourId && <TButton id="VeloptimUser.delete.cancel" onClick={() => { setDeleteModalOpen(false) }} raw />}
          <TButton
            id="VeloptimUser.delete.confirm"
            color="danger"
            onClick={() => {
              setDeleteModalOpen(false)
              deleteRule(users && users.users.filter(u => u.userId === rule.userId)[0])
            }}
            className="ml-2 d-flex align-items-center"
            raw />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default VeloptimManagement
