import React from 'react'
import { useParams, Link } from 'react-router-dom'
import {
  Alert, FormGroup, Input, CustomInput,
  Card, CardHeader, CardBody, CardFooter, Button, Progress,
  Modal, ModalFooter
} from 'reactstrap'
import { FormattedDate, FormattedTime } from 'react-intl'

import { AppContext } from 'contexts/AppContext'

import { useFormatMessage, } from 'hooks/intl.hooks'

import ProtectedComponent from 'components/ProtectedComponent'
import CustomSelect from 'components/CustomSelect'
import { T, TAlert, TCustomInput, TNavLink, TButton, Toption, TLabel } from 'components/TComponents'

import './Custom-Input.scss'

const EquipmentMassChange = () => {
  const params = useParams()
  const { api, subsidiary } = React.useContext(AppContext)
  const formatMessage = useFormatMessage()

  const [massChangeType, setMassChangeType] = React.useState([])

  const [enableSameEquipmentIdentifier, setEnableSameEquipmentIdentifier] = React.useState()

  const [businessId] = React.useState(params.id)
  const [businessName, setBusinessName] = React.useState()
  const [statusInitOptions, setStatusInitOptions] = React.useState([])
  const [statusInit, setStatusInit] = React.useState()
  const [statusToOptions, setStatusToOptions] = React.useState([])
  const [statusTo, setStatusTo] = React.useState()
  const [tags, setTags] = React.useState()
  const [tagsSelected, setTagsSelected] = React.useState()
  const [equipmentsId, setEquipmentsId] = React.useState('')
  const [tagType, setTagType] = React.useState('add')
  const [replace, setReplace] = React.useState()
  const [addresses, setAddresses] = React.useState()
  const [locationSelected, setLocationSelected] = React.useState()

  const [categories, setCategories] = React.useState()
  const [category, setCategory] = React.useState()
  const [families, setFamilies] = React.useState()
  const [family, setFamily] = React.useState()

  const [error, setError] = React.useState()
  const [changeStatusError, setChangeStatusError] = React.useState([])
  const [changeStatusSuccess, setChangeStatusSuccess] = React.useState(false)
  const [nbSuccess, setNbSuccess] = React.useState(0)
  const [changeTagError, setChangeTagError] = React.useState([])
  const [changeTagSuccess, setChangeTagSuccess] = React.useState(false)
  const [nbSuccessTags, setNbSuccessTags] = React.useState(false)
  const [changeLocationError, setChangeLocationError] = React.useState([])
  const [changeLocationSuccess, setChangeLocationSuccess] = React.useState(false)
  const [nbSuccessLocation, setNbSuccessLocation] = React.useState(false)


  const [progress, setProgress] = React.useState(0)
  const [running, setRunning] = React.useState(false);
  const [equipmentIdentifiers, setEquipmentIdentifiers] = React.useState([])

  const [fileLogs, setFileLogs] = React.useState({})

  const [confirmModal, setConfirmModal] = React.useState(false)

  React.useEffect(() => {
    api.get('/business/details', undefined, { businessId })
      .then(response => {
        setBusinessName(response.businessName)
      })
      .catch(error => setError(error))
  }, [api, businessId])

  React.useEffect(() => {
    api.get('/subsidiaries/details', undefined, { subsidiaryId: subsidiary.subsidiaryId })
      .then(response => setEnableSameEquipmentIdentifier(!!response.enableSameEquipmentIdentifier))
      .catch(error => setError(error))
  }, [api, subsidiary])

  React.useEffect(() => {
    api.get('/categories/list', undefined, { ipp: -1, businessId, linkMatrix: 1 })
      .then(categories => {
        setCategories(categories.result.map(category => ({
          label: category.categoryName,
          value: category.categoryId,
        })))
      })
      .catch(response => setError(response))
  }, [api, businessId])

  React.useEffect(() => {
    setStatusTo(undefined)
    const statusInitArray = statusInit && statusInit.split('.')
    api.get('/equipments/changeStatus', undefined, {
      businessId,
      statusId: statusInitArray && statusInitArray[0],
      childStatusId: statusInitArray && statusInitArray[1]
    })
      .then(response => {
        setStatusInitOptions(response.statusFrom)
        setStatusToOptions(response.statusTo)
      })
      .catch(error => setError(error))

  }, [api, statusInit, businessId])

  React.useEffect(() => {
    if (massChangeType === 'tag') {
      api.get('/tags/list')
        .then(response => setTags(response))
        .catch(response => setError(response))
    }
  }, [massChangeType, api])

  React.useEffect(() => {
    if (massChangeType === 'location') {
      api.get('/business/addresseslist', undefined, { businessId })
        .then(response => setAddresses(response))
        .catch(error => setError(error))
    }
  }, [massChangeType, api, businessId])

  const progressEffect = React.useRef();

  React.useEffect(() => {
    if (running) {
      progressEffect.current = setInterval(() => { setProgress((prev) => prev + (100 / equipmentIdentifiers.length)) }, 1000)
    } else {
      clearInterval(progressEffect.current)
    }
  }, [running, equipmentIdentifiers]);

  React.useEffect(() => {
    if (progress === 100) {
      setRunning(false);
      clearInterval(progressEffect.current)
    }
  }, [progress, progressEffect]);

  React.useEffect(() => {
    if (changeStatusError && changeStatusError.length > 0) {
      api.get('/equipments/massChangeLogs', undefined, { businessId })
        .then(response => {
          setFileLogs(response[response.length - 1])
        })
        .catch(error => setChangeStatusError(error))
    }
  }, [api, businessId, changeStatusError])

  React.useEffect(() => {
    if (equipmentsId) {
      setEquipmentIdentifiers(equipmentsId.replace(/(?:\r\n|\r|\n)/g, ',').split(',').filter(eq => eq != null && eq != ""))
    }
  }, [equipmentsId]);

  const handleCategoryChange = React.useCallback(category => {
    setFamilies()
    setCategory(category)
    api.get('/families/list', undefined, { ipp: -1, categoryId: category.value, businessId, linkMatrix: 1 })
      .then(families => {
        setFamilies(families.result.map(family => ({
          label: family.familyName,
          value: family.familyId,
        })))
      })
      .catch(response => setError(response))
  }, [api, businessId])

  const handleFamilyChange = React.useCallback(family => {
    setFamily(family)
  }, [])

  const handleChangeStatus = React.useCallback(() => {
    setConfirmModal(false)
    setProgress(0)
    setError()
    setChangeStatusError([])
    setRunning(true)
    setChangeStatusSuccess(false)
    setNbSuccess(false)

    const statusInitArray = statusInit.split('.')
    const statusToArray = statusTo.split('.')

    api.post('/equipments/massChange', {
      body: JSON.stringify({
        ...(family && { familyId: family.value }),
        ...(category && { categoryId: category.value }),
        equipmentIdentifiers,
        statusInit: statusInitArray[0],
        childStatusInit: statusInitArray[1],
        statusTo: statusToArray[0],
        childStatusTo: statusToArray[1],
        businessId
      })
    })
      .then(response => {
        setProgress(100)
        setRunning(false)
        if (response.nbErrors) {
          setChangeStatusError(response.errors)
        }
        if (response.nbOk) {
          setNbSuccess(response.nbOk)
        }
        if (response.nbOk && !response.nbErrors) {
          setChangeStatusSuccess(true)
        }
      })
      .catch(error => setChangeStatusError(error))
  }, [statusInit, statusTo, api, family, category, equipmentIdentifiers, businessId])

  const handleChangeTag = React.useCallback(() => {
    setConfirmModal(false)
    setError()
    setChangeTagError([])
    setChangeTagSuccess(false)
    setNbSuccessTags(false)
    setRunning(true)
    setProgress(0)

    if (tagType === "add") {
      api.post('/equipments/massTagChange', {
        body: JSON.stringify({
          ...(family && { familyId: family.value }),
          ...(category && { categoryId: category.value }),
          equipmentIdentifiers,
          tagId: tagsSelected.map(t => t.tagId),
          replace: replace ? 1 : 0,
          businessId
        })
      })
        .then(response => {
          setProgress(100)
          setRunning(false)
          if (response.nbErrors) {
            setChangeTagError(response.errors)
          }
          if (response.nbOk) {
            setNbSuccessTags(response.nbOk)
          }
          if (response.nbOk && !response.nbErrors) {
            setChangeTagSuccess(true)
          }
        })
        .catch(error => setChangeTagError(error))
    }
    if (tagType === "remove") {
      api.post('/equipments/deleteMassTag', {
        body: JSON.stringify({
          ...(family && { familyId: family.value }),
          ...(category && { categoryId: category.value }),
          equipmentIdentifiers,
          tagId: tagsSelected.map(t => t.tagId),
          businessId
        })
      })
        .then(response => {
          if (response.errors && response.errors.length > 0) {
            setChangeTagError(response.errors)
          }
          setChangeTagSuccess(true)
        })
        .catch(error => setChangeTagError(error))
    }
  }, [api, family, category, equipmentIdentifiers, tagsSelected, businessId, replace, tagType])

  const handleChangeLocation = React.useCallback(() => {
    setConfirmModal(false)
    setError()
    setChangeLocationError([])
    setChangeLocationSuccess(false)
    setNbSuccessLocation(false)
    setRunning(true)
    setProgress(0)

    if (tagType === "add") {
      api.post('/equipments/massChangeAddress', {
        body: JSON.stringify({
          ...(family && { familyId: family.value }),
          ...(category && { categoryId: category.value }),
          equipmentIdentifiers,
          addressId: locationSelected,
          businessId
        })
      })
        .then(response => {
          setProgress(100)
          setRunning(false)
          if (response.nbErrors) {
            setChangeLocationError(response.errors)
          }
          if (response.nbOk) {
            setNbSuccessLocation(response.nbOk)
          }
          if (response.nbOk && !response.nbErrors) {
            setChangeLocationSuccess(true)
          }
        })
        .catch(error => setChangeLocationError(error))
    }
  }, [tagType, api, family, category, equipmentIdentifiers, locationSelected, businessId])

  const handleTagChange = React.useCallback(e => {
    setTagsSelected(e)
  }, [])

  const handleLocationChange = React.useCallback(e => {
    setLocationSelected(e.addressId)
  }, [])

  const handleReplaceChange = React.useCallback(() => {
    setReplace(!replace)
  }, [replace])

  const handleMassChangeType = React.useCallback(value => {
    setMassChangeType(value)
    setEquipmentsId()
    setEquipmentIdentifiers()
    setFamily()
    setCategory()
  }, [])

  const handleChangeTagType = React.useCallback(value => {
    setTagType(value)
  }, [])

  return (
    <>
      <TNavLink id="returnToList" tag={Link} to="/equipments" />
      <Card>
        <CardHeader>
          <FormGroup tag="fieldset">
            <TLabel id="type" className="d-block" />
            <ProtectedComponent rights={['equipments_mass_change']}>
              <TCustomInput
                id="status"
                type="radio"
                name="massChange"
                label="change.status"
                value="status"
                checked={massChangeType === 'status'}
                onChange={e => handleMassChangeType(e.target.value)}
                inline />
            </ProtectedComponent>
            <ProtectedComponent rights={['equipments-mass_change_tags']}>
              <TCustomInput
                id="tag"
                type="radio"
                name="massChange"
                label="massChange.tag"
                value="tag"
                checked={massChangeType === 'tag'}
                onChange={e => handleMassChangeType(e.target.value)}
                inline />
            </ProtectedComponent>
            <ProtectedComponent rights={['equipments-mass_change_locate_business']}>
              <TCustomInput
                id="location"
                type="radio"
                name="massChange"
                label="massChange.location"
                value="location"
                checked={massChangeType === 'location'}
                onChange={e => handleMassChangeType(e.target.value)}
                inline />
            </ProtectedComponent>
          </FormGroup>
        </CardHeader>
      </Card>
      {massChangeType === 'status' && (
        <Card>
          <CardBody>
            <FormGroup tag="fieldset">
              <TLabel id="business" />
              <span>{businessName}</span>
            </FormGroup>
            {enableSameEquipmentIdentifier && (
              <>
                <FormGroup tag="fieldset">
                  <TLabel id="categoryId" for="categoryId" />
                  <CustomSelect
                    inputId="categoryId"
                    name="categoryId"
                    style={{ flex: 1 }}
                    options={categories}
                    onChange={handleCategoryChange}
                    placeholder={<T id="categoryPlaceholder" />}
                    value={category} />
                </FormGroup>
                <FormGroup tag="fieldset">
                  <TLabel id="familyId" for="familyId" />
                  <CustomSelect
                    inputId="familyId"
                    name="familyId"
                    style={{ flex: 1 }}
                    options={families}
                    onChange={handleFamilyChange}
                    placeholder={<T id="familyPlaceholder" />}
                    value={family} />
                </FormGroup>
              </>
            )}
            {((category && family && enableSameEquipmentIdentifier) || !enableSameEquipmentIdentifier) && (
              <>
                <FormGroup tag="fieldset">
                  <TLabel id="statusInit" />
                  <CustomInput
                    id="statusInit"
                    className="pt-2"
                    type="select"
                    name="statusInit"
                    value={statusInit}
                    onChange={e => { setStatusInit(e.target.value) }}>
                    <Toption value={undefined} id="statusInit.placeholder" />
                    {statusInitOptions.map(opt =>
                      <>
                        {opt.children && opt.children.length > 0 ? (
                          <optgroup key={opt.statusId} label={formatMessage({ id: `equipmentStatus.${opt.statusValue}`, raw: true })}>
                            {opt.children.map(child => (
                              <Toption key={`${opt.statusId}.${child.childStatusValue}`}
                                raw id={`equipmentStatus.${opt.statusValue}.${child.childStatusValue}`}
                                value={`${opt.statusId}.${child.childStatusId}`} />
                            ))}
                          </optgroup>
                        ) : (
                          <Toption key={opt.statusId}
                            raw id={`equipmentStatus.${opt.statusValue}`}
                            value={`${opt.statusId}`} />
                        )}
                      </>
                    )}
                  </CustomInput>

                  <TLabel id="statusTo" className="mt-4" />
                  <CustomInput
                    id="statusTo"
                    className="pt-2"
                    type="select"
                    name="statusTo"
                    value={statusTo}
                    isDisabled={!statusInit}
                    onChange={e => { setStatusTo(e.target.value) }}>
                    <Toption value={undefined} id="statusInit.placeholder" />
                    {statusToOptions.map(opt =>
                      <>
                        {opt.children && opt.children.length > 0 ? (
                          <optgroup key={opt.statusId} label={formatMessage({ id: `equipmentStatus.${opt.statusValue}`, raw: true })}>
                            {opt.children.map(child => (
                              <Toption key={`${opt.statusValue}.${child.childStatusValue}`}
                                raw id={`equipmentStatus.${opt.statusValue}.${child.childStatusValue}`}
                                value={`${opt.statusId}.${child.childStatusId}`} />
                            ))}
                          </optgroup>
                        ) : (
                          <Toption key={opt.statusId}
                            raw id={`equipmentStatus.${opt.statusValue}`}
                            value={`${opt.statusId}`} />
                        )}
                      </>
                    )}
                  </CustomInput>
                  <TLabel id="equipmentList" className="mt-4" />
                  <Input
                    type="textarea"
                    rows="8"
                    value={equipmentsId}
                    onChange={e => setEquipmentsId(e.target.value)}
                    multiline
                  />
                </FormGroup>
              </>
            )}
            {running && (
              <Progress
                color="success"
                animated
                striped
                value={progress}
                className="mb-4"
              />
            )}
            {error && <TAlert id={error} className="mb-0 mt-2" color="danger" />}
            {changeStatusSuccess && (!changeStatusError || changeStatusError.length == 0) && (
              <TAlert id="success" className="mb-2 mt-2" color="success" />
            )}
            {nbSuccess === 1 && (changeStatusError && changeStatusError.length > 0) && (
              <Alert className="mb-2 mt-2" color="warning">
                {`${nbSuccess} `}
                <T id="nbSuccess" />
              </Alert>
            )}
            {nbSuccess > 1 && (changeStatusError && changeStatusError.length > 0)  && (
              <Alert className="mb-2 mt-2" color="warning">
                {`${nbSuccess} `}
                <T id="nbSuccessMore" />
              </Alert>
            )}
            <Button
              className='float-right'
              onClick={() => setConfirmModal(true)}
              disabled={!equipmentsId || !statusTo || !statusInit}
            >
              <T id="changeStatus" />
            </Button>
          </CardBody>
          {changeStatusError && changeStatusError.length > 0 && !changeStatusSuccess  && (
            <CardFooter>
              <h6><T id="status.log" /></h6>
              {fileLogs && fileLogs.downloadLink && (
                <TButton tag="a"
                  id="download.fileLog"
                  target="_blank"
                  href={`${fileLogs.downloadLink.url}?token=${fileLogs.downloadLink.params.token}&businessId=${fileLogs.downloadLink.params.businessId}&filename=${fileLogs.downloadLink.params.filename}&name=${fileLogs.downloadLink.params.name}&subsidiaryId=${fileLogs.downloadLink.params.subsidiaryId}`} />
              )}
              {changeStatusError.map(error =>
                <Alert key="error.equipmentIdentifier" className="mb-0 mt-2" color="danger">
                  {`${error.equipmentIdentifier} - `}
                  <FormattedDate value={new Date(error.datetime)} />{' '}<FormattedTime value={new Date(error.datetime)} />{` - `}
                  <T id="error.equipmentStatus" />{` : ${error.equipmentStatus} - `}
                  <T id="error.statusInit" />{` : ${error.statusInit} - `}
                  <T id="error.statusTo" />{` : ${error.statusTo} - `}
                  <T id="error.code" /> {` : `} <T id={`error.${error.code}`} />
                </Alert>
              )}
            </CardFooter>
          )}
        </Card>
      )}
      {massChangeType === 'tag' && tags && tags.result && tags.result.length > 0 && (
        <Card>
          <CardBody>
            <FormGroup tag="fieldset">
              <TLabel id="business" />
              <span>{businessName}</span>
            </FormGroup>
            {enableSameEquipmentIdentifier && (
              <>
                <FormGroup tag="fieldset">
                  <TLabel id="categoryId" for="categoryId" />
                  <CustomSelect
                    inputId="categoryId"
                    name="categoryId"
                    style={{ flex: 1 }}
                    options={categories}
                    onChange={handleCategoryChange}
                    placeholder={<T id="categoryPlaceholder" />}
                    value={category} />
                </FormGroup>
                <FormGroup tag="fieldset">
                  <TLabel id="familyId" for="familyId" />
                  <CustomSelect
                    inputId="familyId"
                    name="familyId"
                    style={{ flex: 1 }}
                    options={families}
                    onChange={handleFamilyChange}
                    placeholder={<T id="familyPlaceholder" />}
                    value={family} />
                </FormGroup>
              </>
            )}
            {((category && family && enableSameEquipmentIdentifier) || !enableSameEquipmentIdentifier) && (
              <>
                <FormGroup tag="fieldset">
                  <TLabel id="tagType" className="d-block" />
                  <TCustomInput
                    id="staaddtus"
                    type="radio"
                    name="tagType"
                    label="add"
                    value="add"
                    checked={tagType === 'add'}
                    onChange={e => handleChangeTagType(e.target.value)}
                    inline />
                  <TCustomInput
                    id="delete"
                    type="radio"
                    name="tagType"
                    label="delete"
                    value="remove"
                    checked={tagType === 'remove'}
                    onChange={e => handleChangeTagType(e.target.value)}
                    inline />
                </FormGroup>
                <FormGroup tag="fieldset">
                  <TLabel id="tag" for="tagSelect" />
                  <CustomSelect
                    inputId="tag"
                    name="tag"
                    isMulti
                    isSearchable
                    style={{ flex: 1 }}
                    options={tags && tags.result}
                    onChange={handleTagChange}
                    placeholder={<T id="equipmentTagsPlacholder" />}
                    value={tagsSelected && tagsSelected.length > 0 && tags && tags.result.filter(t => tagsSelected.find(it => it.tagId === t.tagId))}
                    getOptionLabel={option => option.colorHex
                      ? <div className="d-flex flex-row align-items-center"><div style={{ backgroundColor: option.colorHex }} className="mr-2 color-tag" /><>{option.tagIdentifier}</></div>
                      : <div className="d-flex flex-row align-items-center"><div style={{ backgroundColor: '#fff' }} className="mr-2 color-tag" /><>{option.tagIdentifier}</></div>}
                    getOptionValue={option => option.tagId} />
                </FormGroup>
                {tagType === 'add' && (<FormGroup tag="fieldset">
                  <TCustomInput
                    id="replace"
                    type="checkbox"
                    name="replace"
                    label="replace"
                    checked={replace}
                    onChange={e => handleReplaceChange(e)} />
                </FormGroup>)}
                <FormGroup tag="fieldset">
                  <TLabel id="equipmentList" />
                  <Input
                    type="textarea"
                    rows="8"
                    value={equipmentsId}
                    onChange={e => setEquipmentsId(e.target.value)}
                    multiline
                  />
                </FormGroup>
              </>
            )}
            <Button
              className='float-right'
              onClick={() => setConfirmModal(true)}
              disabled={!equipmentsId || !tagsSelected}
            >
              <T id="changeStatus" />
            </Button>
          </CardBody>
          {running && (
            <Progress
              color="success"
              animated
              striped
              value={progress}
              className="mb-4"
            />
          )}
          {error && <TAlert id={error} className="mb-0 mt-2" color="danger" />}
          {changeTagError && changeTagError.length > 0 && !changeTagSuccess && (
            <>
              {changeTagError.map(error =>
                <TAlert key={error.code} id={`error.${error.code}`} className="mb-2 mt-2" color="danger" />
              )}
            </>
          )}
          {changeTagSuccess && (!changeTagError || changeTagError.length == 0) && (
            <TAlert id="success" className="mb-2 mt-2" color="success" />
          )}
          {nbSuccessTags === 1 && (changeTagError && changeTagError.length > 0) && (
            <Alert className="mb-2 mt-2" color="warning">
              {`${nbSuccessTags} `}
              <T id="nbSuccessTag" />
            </Alert>
          )}
          {nbSuccessTags > 1 && (changeTagError && changeTagError.length > 0)  && (
            <Alert className="mb-2 mt-2" color="warning">
              {`${nbSuccessTags} `}
              <T id="nbSuccessMoreTag" />
            </Alert>
          )}
        </Card>
      )}
      {massChangeType === 'location' && (
        <Card>
          <CardBody>
            <FormGroup tag="fieldset">
              <TLabel id="business" />
              <span>{businessName}</span>
            </FormGroup>
            {enableSameEquipmentIdentifier && (
              <>
                <FormGroup tag="fieldset">
                  <TLabel id="categoryId" for="categoryId" />
                  <CustomSelect
                    inputId="categoryId"
                    name="categoryId"
                    style={{ flex: 1 }}
                    options={categories}
                    onChange={handleCategoryChange}
                    placeholder={<T id="categoryPlaceholder" />}
                    value={category} />
                </FormGroup>
                <FormGroup tag="fieldset">
                  <TLabel id="familyId" for="familyId" />
                  <CustomSelect
                    inputId="familyId"
                    name="familyId"
                    style={{ flex: 1 }}
                    options={families}
                    onChange={handleFamilyChange}
                    placeholder={<T id="familyPlaceholder" />}
                    value={family} />
                </FormGroup>
              </>
            )}
            {((category && family && enableSameEquipmentIdentifier) || !enableSameEquipmentIdentifier) && (
              <>
                <FormGroup tag="fieldset">
                  <TLabel id="location" for="location" />
                  <CustomSelect
                    inputId="changeAddress"
                    name="changeAddress"
                    options={addresses}
                    onChange={handleLocationChange}
                    placeholder={<T id="locationPlaceholder" />}
                    isClearable
                    value={addresses && addresses.length > 0 && addresses.filter(a => a.addressId === locationSelected)}
                    getOptionLabel={option => option.addressLabel}
                    getOptionValue={option => option.addressId} />
                  <TLabel id="equipmentList" className="mt-4" />
                  <Input
                    type="textarea"
                    rows="8"
                    value={equipmentsId}
                    onChange={e => setEquipmentsId(e.target.value)}
                    multiline
                  />
                </FormGroup>
              </>
            )}
            {running && (
              <Progress
                color="success"
                animated
                striped
                value={progress}
                className="mb-4"
              />
            )}
            {error && <TAlert id={error} className="mb-0 mt-2" color="danger" />}
            {changeLocationError && changeLocationError.length > 0 && !changeLocationSuccess && (
              <>
                {changeLocationError.map(error =>
                  <TAlert key={error.code} id={`error.${error.code}`} className="mb-2 mt-2" color="danger" />
                )}
              </>
            )}
            {changeLocationSuccess && (!changeLocationError || changeLocationError.length == 0) && (
              <TAlert id="success" className="mb-2 mt-2" color="success" />
            )}
            {nbSuccessLocation === 1 && (changeLocationError && changeLocationError.length > 0) && (
              <Alert className="mb-2 mt-2" color="warning">
                {`${nbSuccessLocation} `}
                <T id="nbSuccessTag" />
              </Alert>
            )}
            {nbSuccessLocation > 1 && (changeLocationError && changeLocationError.length > 0)  && (
              <Alert className="mb-2 mt-2" color="warning">
                {`${nbSuccessLocation} `}
                <T id="nbSuccessMoreTag" />
              </Alert>
            )}
            <Button
              className='float-right'
              onClick={() => setConfirmModal(true)}
              disabled={!equipmentsId}>
              <T id="changeLocation" />
            </Button>
          </CardBody>
        </Card>
      )}
      {/* Confirmation modal */}
      {confirmModal && <Modal isOpen={confirmModal} toggle={() => { setConfirmModal(!confirmModal) }} >
        <T className="m-2" id={massChangeType !== 'status' 
          ? massChangeType === 'tag' ? `confirmModalTag` : `confirmModalLocation` 
          : `confirmModalStatus`} />
        <ModalFooter>
          <TButton
            id="cancel"
            color="danger"
            onClick={() => { setConfirmModal(false) }} />
          <TButton
            id="confirm"
            color="primary"
            onClick={massChangeType !== 'status' 
              ? massChangeType === 'tag' ? handleChangeTag : handleChangeLocation 
              : handleChangeStatus} />
        </ModalFooter>
      </Modal>}
    </>
  )
}

export default EquipmentMassChange
