import * as Sentry from "@sentry/react";
import React from 'react'
import { createRoot } from 'react-dom/client';

import { BrowserRouter, Route, } from 'react-router-dom'

import { AppProvider, } from 'contexts/AppContext'
import App from 'containers/App'

import 'index.scss'

Sentry.init({
  environment: process.env.SENTRY_ENV || 'development',
  autoSessionTracking: true,
  IsGlobalModeEnabled: true,
  captureFailedRequests: true,
  dsn: "https://fbefae89237ea3faa582382935259cc9@o4508127066849280.ingest.de.sentry.io/4508131598598224",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/velocare.velogik\.test\/api/,
    /^https:\/\/velocare-recette-ws.velogik\.com\/api/,
    /^https:\/\/velocare-ws.velogik\.com\/api/
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  profilesSampleRate: 1.0, // Capture 100% of the profiles
});

class ErrorBoundary extends React.Component {
  state = {
    error: undefined
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  renderError(error) {
    fetch(`${process.env.API_ENDPOINT}/velocare/alert`, {
      method: 'POST',
      headers: {
        'X-Velocare-Apikey': process.env.API_KEY,
      },
      body: JSON.stringify({ err: error.stack })
    })

    return (
      <div className="container">
        <div className="card">
          <div className="card-header">Global error</div>
          <div className="card-body">
            <p className="mb-0" style={{ whiteSpace: 'pre-wrap' }}>{error.toString()}</p>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return this.state.error ? this.renderError(this.state.error) : this.props.children
  }
}

const MainApp = () =>
  <ErrorBoundary>
    <BrowserRouter>
      <AppProvider>
        <Route path="/" component={App} />
      </AppProvider>
    </BrowserRouter>
  </ErrorBoundary>

const root = createRoot(document.getElementById("root"))
export default root.render(<MainApp />)
