import React from 'react'
import {
  Card, CardBody, CardFooter,
  FormGroup, Input
} from 'reactstrap'
import { useHistory, Link } from 'react-router-dom'

import { AppContext } from 'contexts/AppContext'

import { T, TLabel, TButton, ErrAlert, TNavLink } from 'components/TComponents'
import CustomSelect from 'components/CustomSelect'

const VehiclesNew = () => {

  const { api, constants } = React.useContext(AppContext)
  const history = useHistory()
  const [vehicleIdentifier, setVehicleIdentifier] = React.useState('')
  const [vehicleLabel, setVehicleLabel] = React.useState('')
  const [typeId, setTypeId] = React.useState()

  const [createError, setCreateError] = React.useState(false)

  const postVehicle = React.useCallback(() => {
    setCreateError(false)
    api.post('/vehicles/details', {
      body: JSON.stringify({
        vehicleIdentifier, vehicleLabel, typeId
      })
    })
      .then(response => {
        if (response.vehicleId) {
          history.push('/admin/vehicles')
        }
      })
      .catch(error => setCreateError(error))
  }, [api, vehicleIdentifier, vehicleLabel, typeId, history])

  return (
    <>
      <TNavLink id="returnToList" tag={Link} to="/admin/vehicles" />
      <Card>
        <CardBody>
          {createError && <ErrAlert error={createError} />}
          <FormGroup tag="fieldset">
            <TLabel for="vehicleIdentifier" id="vehicleIdentifier" />
            <Input id="vehicleIdentifier"
              type="text"
              name="vehicleIdentifier"
              value={vehicleIdentifier}
              onChange={e => { setVehicleIdentifier(e.target.value) }}
            />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="vehicleLabel" id="vehicleLabel" />
            <Input id="vehicleLabel"
              type="text"
              name="vehicleLabel"
              value={vehicleLabel}
              onChange={e => { setVehicleLabel(e.target.value) }}
            />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="typeId" id="typeId" />
            <CustomSelect
              id="typeId"
              options={constants.vehicleType}
              onChange={value => setTypeId(value.value)}
              placeholder={<T id="typeId.placeholder" />}
              getOptionLabel={option => <T id={`VehiclesTypeList.${option.key}`} raw />} />
          </FormGroup>
        </CardBody>
        <CardFooter className="d-flex justify-content-end">
          <TButton
            className="ml-2"
            onClick={postVehicle}
            id="add" />
        </CardFooter>
      </Card>
    </>
  )
}

export default VehiclesNew
