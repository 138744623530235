import React from 'react'
import { buildApi } from 'react-rest-api'
import { NotificationManager } from 'components/ReactNotifications/index'
import * as Sentry from "@sentry/react";

export const AppContext = React.createContext()

const INTERVENTION_ANOMALY_TYPES = {
  partMissing: 1,
  cantItv: 2,
  cantEndItv: 3,
  noUser: 4,
  diagIssue: 5,
  other: 6
}

const SWAP_ANOMALY_TYPES = {
  wrongBike: 1,
  noBikeOrUser: 2,
  cantSwap: 3,
  other: 4
}

const CHECKLIST_STATUS = {
  ko: 1,
  watch: 2,
  ok: 3
}

const TRIGGERS_CRITICALITY = [
  { key: 'info', value: 1 },
  { key: 'minor', value: 2 },
  { key: 'major', value: 3 }
]

const appReducer = (state, action) => {
  switch (action.type) {
  case 'init':
    action.payload && action.payload.user
      ? localStorage.setItem('userToken', JSON.stringify(action.payload.user.accessToken))
      : localStorage.removeItem('userToken')
    action.payload && action.payload.subsidiary
      ? localStorage.setItem('userSubsidiary', JSON.stringify(action.payload.subsidiary))
      : localStorage.removeItem('userSubsidiary')
    action.payload && action.payload.user && action.payload.user.timeStartWork && action.payload.user.timeEndWork
      ? localStorage.setItem('userTimeStartWork', JSON.stringify(action.payload.user.timeStartWork))
      : localStorage.removeItem('userTimeStartWork')
    action.payload && action.payload.user && action.payload.user.timeEndWork && action.payload.user.timeEndWork
      ? localStorage.setItem('userTimeEndWork', JSON.stringify(action.payload.user.timeEndWork))
      : localStorage.removeItem('userTimeEndWork')
    action.payload && action.payload.user && action.payload.user.langId
      ? localStorage.setItem('userLanguage', JSON.stringify({
        langId: action.payload.user.langId,
        langIdentifier: action.payload.user.langIdentifier,
        langName: action.payload.user.langName,
      }))
      : localStorage.removeItem('userLanguage')
    action.payload && action.payload.logo
      ? localStorage.setItem('logo', JSON.stringify(action.payload.logo))
      : localStorage.removeItem('logo')
    return {
      loading: false,
      token: action.payload && action.payload.token || undefined,
      user: action.payload && action.payload.user ? action.payload.user : undefined,
      constants: action.payload && action.payload.constants ? action.payload.constants : undefined,
      profiles: action.payload && action.payload.profiles ? action.payload.profiles : undefined,
      translations: action.payload && action.payload.translations ? action.payload.translations : undefined,
      subsidiary: action.payload && action.payload.subsidiary ? action.payload.subsidiary : undefined,
      language: action.payload && action.payload.language ? action.payload.language : state.language,
      logo: action.payload && action.payload.logo ? action.payload.logo : state.logo,
      timezone: action.payload && action.payload.subsidiary && action.payload.subsidiary.timezoneIdentifier ? action.payload.subsidiary.timezoneIdentifier : state.timezone,
      timeStartWork: action.payload && action.payload.timeStartWork ? action.payload.timeStartWork : undefined,
      timeEndWork: action.payload && action.payload.timeEndWork ? action.payload.timeEndWork : undefined,
    }
  case 'setUserToken':
    action.payload && action.payload.token
      ? localStorage.setItem('userToken', JSON.stringify(action.payload.token))
      : localStorage.removeItem('userToken')
    return {
      loading: state.loading,
      token: action.payload && action.payload.token || undefined,
      user: action.payload && action.payload.user || undefined,
      language: action.payload && action.payload.language || undefined,
      logo: action.payload && action.payload.logo || undefined,
      constants: undefined,
      profiles: undefined,
      translations: undefined,
      subsidiary: undefined,
      timeEndWork: action.payload && action.payload.user && action.payload.user.timeEndWork || undefined,
      timeStartWork: action.payload && action.payload.user && action.payload.user.timeStartWork || undefined,
      timezone: state.timezone
    }
  case 'setUser':
    action.payload && action.payload.user
      ? localStorage.setItem('userToken', JSON.stringify(action.payload.user.accessToken))
      : localStorage.removeItem('userToken')
    action.payload && action.payload.language
      ? localStorage.setItem('userLanguage', JSON.stringify(action.payload.language))
      : localStorage.removeItem('userLanguage')
    localStorage.removeItem('userSubsidiary')
    action.payload && action.payload.user && action.payload.user.timeStartWork && action.payload.user.timeEndWork
      ? localStorage.setItem('userTimeStartWork', JSON.stringify(action.payload.user.timeStartWork))
      : localStorage.removeItem('userTimeStartWork')
    action.payload && action.payload.user && action.payload.user.timeEndWork && action.payload.user.timeEndWork
      ? localStorage.setItem('userTimeEndWork', JSON.stringify(action.payload.user.timeEndWork))
      : localStorage.removeItem('userTimeEndWork')
    return {
      loading: state.loading,
      token: action.payload && action.payload.token || state.token,
      user: action.payload && action.payload.user || undefined,
      language: action.payload && action.payload.language || undefined,
      logo: undefined,
      constants: undefined,
      profiles: undefined,
      translations: undefined,
      subsidiary: undefined,
      timeEndWork: action.payload && action.payload.user && action.payload.user.timeEndWork || undefined,
      timeStartWork: action.payload && action.payload.user && action.payload.user.timeStartWork || undefined,
      timezone: state.timezone
    }
  case 'updateUser':
    action.payload && action.payload.timeStartWork
      ? localStorage.setItem('userTimeStartWork', JSON.stringify(action.payload.timeStartWork))
      : localStorage.removeItem('userTimeStartWork')
    action.payload && action.payload.timeEndWork && action.payload.timeEndWork
      ? localStorage.setItem('userTimeEndWork', JSON.stringify(action.payload.timeEndWork))
      : localStorage.removeItem('userTimeEndWork')
    return {
      loading: state.loading,
      token: action.payload && action.payload.token || undefined,
      user: action.payload && action.payload.user || state.user,
      language: state.language,
      logo: state.logo,
      constants: state.constants,
      profiles: state.profiles,
      translations: state.translations,
      subsidiary: state.subsidiary,
      timeEndWork: action.payload && action.payload.user && action.payload.user.timeEndWork || state.timeEndWork,
      timeStartWork: action.payload && action.payload.user && action.payload.user.timeStartWork || state.timeStartWork,
      timezone: state.timezone
    }
  case 'setSubsidiary':
    if (action.payload && action.payload.translations) {
      localStorage.setItem('userSubsidiary', JSON.stringify(action.payload.subsidiary))
      if (action.payload && action.payload.logo) {
        localStorage.setItem('logo', JSON.stringify(action.payload.logo))
      } else {
        localStorage.removeItem('logo')
      }
    } else {
      localStorage.removeItem('userSubsidiary')
    }
    return {
      loading: state.loading,
      token: action.payload && action.payload.token || undefined,
      user: state.user,
      language: action.payload && action.payload.language ? action.payload.language : undefined,
      logo: action.payload && action.payload.logo ? action.payload.logo : undefined,
      timezone: action.payload && action.payload.timezone ? action.payload.timezone : 'Europe/Paris',
      constants: action.payload && action.payload.constants ? action.payload.constants : undefined,
      profiles: action.payload && action.payload.profiles ? action.payload.profiles : undefined,
      translations: action.payload && action.payload.translations ? action.payload.translations : undefined,
      subsidiary: action.payload && action.payload.subsidiary ? action.payload.subsidiary : undefined,
      timeEndWork: action.payload && action.payload.timeEndWork ? action.payload.timeEndWork : undefined,
      timeStartWork: action.payload && action.payload.timeStartWork ? action.payload.timeStartWork : undefined
    }
  case 'setLanguage':
    action.payload && action.payload.language
      ? localStorage.setItem('userLanguage', JSON.stringify(action.payload.language))
      : localStorage.removeItem('userLanguage')
    return {
      loading: state.loading,
      token: action.payload && action.payload.token || undefined,
      user: state.user,
      subsidiary: state.subsidiary,
      constants: state.constants,
      profiles: state.profiles,
      translations: action.payload && action.payload.translations ? action.payload.translations : state.translations,
      language: action.payload && action.payload.language ? action.payload.language : undefined,
      logo: state.logo,
      timezone: state.timezone,
      timeEndWork: state.timeEndWork,
      timeStartWork: state.timeStartWork,
    }
  case 'refreshTranslations':
    return { ...state, translations: { ...state.translations, messages: action.payload } }
  default: return state
  }
}

const appInitialState = {
  loading: true,
  token: undefined,
  user: undefined,
  constants: undefined,
  profiles: undefined,
  translations: undefined,
  subsidiary: undefined,
  language: undefined,
  logo: undefined,
  timeEndWork: undefined,
  timeStartWork: undefined,
  timezone: 'Europe/Paris'
}

export const AppProvider = props => {
  const [{ loading, token, user, constants, profiles, translations, subsidiary, language, logo, timezone, timeEndWork, timeStartWork }, appDispatch] = React.useReducer(appReducer, appInitialState)
  const [menuOpen, setMenuOpen] = React.useState(false)

  React.useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userToken'))
    Promise.all([
      token ? api.get('/users/me', { headers: { Authorization: token } }) : undefined,
    ])
      .then(([user]) => {
        Sentry.setUser({ email: user.email, id: user.userId, username: `${user.firstName} ${user.lastName}` });
        const userSubsidiary = user && user.subsidiaries && JSON.parse(localStorage.getItem('userSubsidiary'))
        const subsidiary = user && user.subsidiaries && (userSubsidiary
          ? user.subsidiaries.find(sub => sub.subsidiaryId === userSubsidiary.subsidiaryId)
          : (user.subsidiaries.length === 1 && user.subsidiaries[0]))
        const language = getLanguage({})
        const planningTime = getPlanningTime({})
        const timeStartWork = planningTime['timeStartWork']
        const timeEndWork = planningTime['timeEndWork']
        return Promise.all([
          Promise.resolve(user), Promise.resolve(subsidiary), Promise.resolve(language), Promise.resolve(timeStartWork), Promise.resolve(timeEndWork),
          subsidiary ? api.get('/velocare/constants', { headers: { Authorization: token, 'X-Velocare-Subsidiary': subsidiary.subsidiaryId } }) : undefined,
          subsidiary ? api.get('/users/rights', { headers: { Authorization: token, 'X-Velocare-Subsidiary': subsidiary.subsidiaryId } }) : undefined,
          subsidiary ? api.get('/velocare/translations', { headers: { Authorization: token, 'X-Velocare-Subsidiary': subsidiary.subsidiaryId } }, { lang: language.langIdentifier }) : undefined,
          subsidiary ? api.get('/subsidiaries/logo', { headers: { Authorization: token, 'X-Velocare-Subsidiary': subsidiary.subsidiaryId } }) : undefined,
        ])
      })
      .then(([user, subsidiary, language, timeStartWork, timeEndWork, constants, rights, translations, logo]) => {
        return ({
          user, subsidiary, language, timeEndWork, timeStartWork, constants, profiles: rights ? rights.profiles : undefined, translations, logo: logo.path
        })
      })
      .catch(response => ({ error: response }))
      .then(payload => appDispatch({ type: 'init', payload }))
  }, [api])

  const api = React.useMemo(() => {
    return buildApi({
      url: process.env.API_ENDPOINT,
      config: {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': user ? user.accessToken : (token ? token : undefined),
          'X-Velocare-Apikey': process.env.API_KEY,
          'X-Velocare-Version': VERSION,
          'X-Velocare-Target': 'Browser',
          'X-Velocare-Subsidiary': subsidiary ? subsidiary.subsidiaryId : undefined,
        },
      },
      resolveCallback: response => {
        return (response.ok ? response.json() : response.json().then(res => Promise.reject(res)))
          .then(response => {
            if (response && response.code === 'invalidOrExpiredToken') {
              appDispatch({ type: 'setUser' })
              return Promise.reject(response)
            }
            return response && response.exception ? Promise.reject(response) : Promise.resolve(response)
          })
      },
      rejectCallback: response => {
        if (!response || !response.exception) {
          NotificationManager.error('network')
        }
        return Promise.reject(response || { code: 'unexpected' })
      },
    })
  }, [subsidiary, user, token])

  const setUser = React.useCallback(token => {
    if (token) {
      return api.get('/users/me', { headers: { Authorization: token } })
        .then((user) => {
          const language = getLanguage({ user: user })
          const timeStartWork = getPlanningTime({ user: user }) && getPlanningTime({ user: user })['timeStartWork']
          const timeEndWork = getPlanningTime({ user: user }) && getPlanningTime({ user: user })['timeEndWork']
          appDispatch({ type: 'setUser', payload: { user, language, timeStartWork, timeEndWork } })
          return Promise.resolve(user)
        })
        .catch(response => {
          appDispatch({ type: 'setUser', payload: { language, timeStartWork, timeEndWork } })
          return Promise.reject(response)
        })
    } else {
      appDispatch({ type: 'setUser', payload: { language, timeStartWork, timeEndWork } })
      return Promise.resolve()
    }
  }, [api, language, timeStartWork, timeEndWork])

  const setUserToken = (token) => {
    appDispatch({ type: 'setUserToken', payload: { token } })
  }

  const updateUser = React.useCallback(user => {
    if (user) {
      return api.get('/users/me')
        .then((user) => {
          const timeStartWork = getPlanningTime({ user: user }) && getPlanningTime({ user: user })['timeStartWork']
          const timeEndWork = getPlanningTime({ user: user }) && getPlanningTime({ user: user })['timeEndWork']
          appDispatch({ type: 'updateUser', payload: { user, timeStartWork, timeEndWork } })
          return Promise.resolve(user)
        })
        .catch(response => {
          appDispatch({ type: 'upsateUser', payload: { user, timeStartWork, timeEndWork } })
          return Promise.reject(response)
        })
    }
  }, [api, timeStartWork, timeEndWork])

  const setSubsidiary = React.useCallback(subsidiary => {
    const timezone = subsidiary && subsidiary.timezoneIdentifier ? subsidiary.timezoneIdentifier : 'Europe/Paris'
    if (subsidiary) {
      const language = getLanguage({ subsidiary: subsidiary })
      const timeStartWork = getPlanningTime({ subsidiary: subsidiary }) && getPlanningTime({ subsidiary: subsidiary })['timeStartWork']
      const timeEndWork = getPlanningTime({ subsidiary: subsidiary }) && getPlanningTime({ subsidiary: subsidiary })['timeEndWork']
      return Promise.all([
        api.get('/velocare/constants', { headers: { 'X-Velocare-Subsidiary': subsidiary.subsidiaryId } }),
        api.get('/users/rights', { headers: { 'X-Velocare-Subsidiary': subsidiary.subsidiaryId } }),
        api.get('/velocare/translations', { headers: { 'X-Velocare-Subsidiary': subsidiary.subsidiaryId } }, { lang: language.langIdentifier }),
        api.get('/subsidiaries/logo', { headers: { 'X-Velocare-Subsidiary': subsidiary.subsidiaryId } }),
      ])
        .then(([constants, rights, translations, logo]) => {
          appDispatch({
            type: 'setSubsidiary',
            payload: { subsidiary, constants, profiles: rights.profiles, translations, language, timezone, timeEndWork, timeStartWork, logo: logo.path }
          })

          return Promise.resolve()
        })
        .catch(response => {
          appDispatch({ type: 'setSubsidiary', payload: { language, timezone, timeEndWork, timeStartWork } })
          return Promise.reject(response)
        })
    } else {
      appDispatch({ type: 'setSubsidiary', payload: { language, timezone, timeEndWork, timeStartWork } })
      return Promise.resolve()
    }
  }, [api, language, timeEndWork, timeStartWork])

  const setLanguage = React.useCallback((language, subsidiary) => {
    if (language) {
      return Promise.all([
        api.get('/velocare/translations', { headers: { 'X-Velocare-Subsidiary': subsidiary.subsidiaryId } }, { lang: language.langIdentifier }),
      ])
        .then(([translations]) => {
          Promise.all([
            api.post('/users/language', {
              body: JSON.stringify({
                langId: language.langId,
                langIdentifier: language.langIdentifier,
                langName: language.langName
              })
            }),
            new Promise(resolve => setTimeout(resolve, 250))
          ])
          appDispatch({
            type: 'setLanguage',
            payload: { language, translations }
          })
          return Promise.resolve()
        })
        .catch(response => {
          return Promise.reject(response)
        })
    } else {
      appDispatch({ type: 'setLanguage' })
      return Promise.resolve()
    }
  }, [api])

  const getLanguage = ({ user, subsidiary }) => {
    const userLanguage = user && user.langId
      ? {
        langId: user.langId,
        langIdentifier: user.langIdentifier,
        langNAme: user.langName
      }
      : (JSON.parse(localStorage.getItem('userLanguage')) || undefined)
    const subsidiaryStored = JSON.parse(localStorage.getItem('userSubsidiary'))
    const actualSub = subsidiary && subsidiary.langId
      ? subsidiary
      : (subsidiaryStored && subsidiaryStored.langId) ? subsidiaryStored : undefined
    const subsidiaryLanguage = actualSub && actualSub.langId ?
      {
        langId: actualSub.langId,
        langIdentifier: actualSub.langIdentifier,
        langName: actualSub.langName
      } : undefined
    const defaultLanguage = {
      langId: '1',
      langIdentifier: 'fr-FR',
      langName: 'french'
    }
    return userLanguage || subsidiaryLanguage || defaultLanguage
  }

  const getPlanningTime = ({ user, subsidiary }) => {
    const userTime = user && user.timeStartWork && user.timeEndWork
      ? {
        timeStartWork: user.timeStartWork,
        timeEndWork: user.timeEndWork
      } : {
        timeStartWork: (JSON.parse(localStorage.getItem('userTimeStartWork')) || undefined),
        timeEndWork: (JSON.parse(localStorage.getItem('userTimeEndWork')) || undefined)
      }
    const subsidiaryStored = JSON.parse(localStorage.getItem('userSubsidiary'))
    const actualTime = subsidiary && subsidiary.timeStartWork
      ? subsidiary
      : (subsidiaryStored && subsidiaryStored.timeStartWork) ? subsidiaryStored : undefined
    const subsidiaryTime = actualTime && actualTime.timeStartWork ?
      {
        timeStartWork: actualTime.timeStartWork,
        timeEndWork: actualTime.timeEndWork
      } : undefined
    return (userTime.timeStartWork && userTime.timeEndWork) ? userTime : subsidiaryTime
  }

  return (
    <AppContext.Provider
      value={{
        loading,
        api, token, user, constants, profiles, translations, subsidiary, language, timezone, timeEndWork, timeStartWork, logo,
        INTERVENTION_ANOMALY_TYPES,
        SWAP_ANOMALY_TYPES,
        CHECKLIST_STATUS,
        TRIGGERS_CRITICALITY,
        login: setUser,
        changePassword: setUserToken,
        logout: () => setUser(),
        setSubsidiary,
        setUser,
        setUserToken,
        updateUser,
        setLanguage,
        refreshTranslations: translations => appDispatch({ type: 'refreshTranslations', payload: translations }),
        menuOpen, setMenuOpen
      }}
      {...props} />
  )
}
