import React from 'react'
import { NavLink } from 'react-router-dom'

import { Card, CardBody, CardFooter, Spinner, } from 'reactstrap'

import { AppContext, } from 'contexts/AppContext'

import { T, TButton, ErrAlert, } from 'components/TComponents'

const reducer = (state, action) => {
  switch (action.type) {
  case 'pageLoaded':
    return {
      initialize: false,
      loading: false,
      error: undefined,
    }
  case 'load':
    return {
      initialize: false,
      loading: true,
      error: undefined,
    }
  case 'error':
    return {
      initialized: false,
      loading: false,
      error: action.payload,
    }
  default:
    return state
  }
}

const defaultState = {
  initialize: true,
  loading: true,
  error: undefined,
}

const BusinessSelector = () => {
  const { user, setSubsidiary, } = React.useContext(AppContext)
  const [state, dispatch] = React.useReducer(reducer, defaultState)

  React.useEffect(() => {
    if (user.subsidiaries.length === 1) {
      setSubsidiary(user.subsidiaries[0])
        .catch(response => dispatch({ type: 'error', payload: response }))
    } else {
      dispatch({ type: 'pageLoaded' })
    }
  }, [user, setSubsidiary])

  const handleSubsidiaryClick = React.useCallback(subsidiary => {
    dispatch({ type: 'load' })
    setSubsidiary(subsidiary)
      // TODO: handle error
      .catch(response => dispatch({ type: 'error', payload: response }))
  }, [setSubsidiary])

  if (state.initialize) {
    return (
      <div className="d-flex position-absolute justify-content-center align-items-center" style={{ left: 0, right: 0, top: 0, bottom: 0 }}>
        <Spinner />
      </div>
    )
  }

  return (
    <div className="mt-4 d-flex flex-wrap justify-content-center">
      {(!user.subsidiaries || user.subsidiaries.length === 0) && (
        <ErrAlert error={{ code: 'noSubsidiaries' }} className="d-flex" />
      )}
      {user.subsidiaries && user.subsidiaries.map(subsidiary => (
        <>
          {subsidiary.locked === 0 && (
            <Card
              key={subsidiary.subsidiaryId}
              className="m-2 selector-card"
              onClick={() => handleSubsidiaryClick(subsidiary)}
            >
              <T
                tagName={CardBody}
                id="item.body"
                className="text-center d-flex justify-content-center align-items-center"
                values={subsidiary} />
            </Card>
          )}
        </>
      ))}
      {user.settings.canManageSubsidiary && <Card className="m-2 selector-card">
        <T
          tagName={CardBody}
          id="new.body"
          className="text-center d-flex justify-content-center align-items-center" />
        <CardFooter className="p-0">
          <TButton color="primary"
            disabled={state.loading}
            className="w-100 rounded-0 stretched-link"
            tag={NavLink} to="/subsidiaries/new"
            id="new.footer" />
        </CardFooter>
      </Card>}
    </div>
  )
}

export default BusinessSelector
