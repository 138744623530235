import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'

import {
  Table, Card, CardHeader,
  Form, FormGroup,
  Row, Col,
  Spinner
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import TH from 'components/TH'
import Pagination from 'components/Pagination'
import CustomSelect from 'components/CustomSelect'
import { ErrAlert, T, TLabel, TInput, TButton, } from 'components/TComponents'

import { debounce } from 'util/Utils'

const ItvcodesgroupsList = () => {
  const { api } = React.useContext(AppContext)

  const [itvCodeGroups, setItvCodeGroups] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()

  const history = useHistory()
  const businessId = history.location.state && history.location.state.businessId

  const [searchInput, setSearchInput] = React.useState('')

  React.useEffect(() => {
    Promise.all([
      api.get('/itvcodegroups/list', undefined, { businessId }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([itvCodeGroups]) => setItvCodeGroups(itvCodeGroups))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api, businessId])

  const refresh = React.useCallback((params) => {
    setLoading(true)
    setError()
    Promise.all([
      api.get('/itvcodegroups/list', undefined, {
        businessId: (itvCodeGroups.filters.businessId.selected && itvCodeGroups.filters.businessId.selected.value) || businessId,
        categoryId: itvCodeGroups.filters.categoryId.selected && itvCodeGroups.filters.categoryId.selected.value,
        familyId: itvCodeGroups.filters.familyId.selected && itvCodeGroups.filters.familyId.selected.value,
        ...params
      }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([itvCodeGroups]) => setItvCodeGroups(itvCodeGroups))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api, businessId, itvCodeGroups])

  const refreshDebounced = React.useMemo(() => debounce(params => refresh(params), 250), [refresh])

  const handleSearchInput = React.useCallback(({ target: { value } }) => {
    setSearchInput(value)
    refreshDebounced({ filter: value, p: 1 })
  }, [refreshDebounced])

  return (
    <>
      <Card className="mb-2">
        <CardHeader>
          <Form onSubmit={e => e.preventDefault()}>
            <Row form>
              <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel for="searchInput" id="searchInputLabel" />
                  <TInput
                    name="searchInput"
                    type="text"
                    placeholder="searchInputPlaceholder"
                    value={searchInput}
                    onChange={handleSearchInput} />
                </FormGroup>
              </Col>
              {itvCodeGroups && itvCodeGroups.filters && itvCodeGroups.filters.businessId && itvCodeGroups.filters.businessId.values && <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel for="businessId" id="businessLabel" />
                  <CustomSelect
                    inputId="businessId"
                    name="businessId"
                    options={itvCodeGroups.filters.businessId.values}
                    onChange={e => refresh({ businessId: e && e.value })}
                    isClearable
                    placeholder={<T id="businessPlaceholder" />}
                    value={itvCodeGroups.filters.businessId.selected && itvCodeGroups.filters.businessId.values.filter(c => c.value === itvCodeGroups.filters.businessId.selected.value)}
                    isDisabled={loading} />
                </FormGroup>
              </Col>}
              {itvCodeGroups && itvCodeGroups.filters && itvCodeGroups.filters.categoryId && itvCodeGroups.filters.categoryId.values && <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel for="categoryId" id="categoryLabel" />
                  <CustomSelect
                    inputId="categoryId"
                    name="categoryId"
                    options={itvCodeGroups.filters.categoryId.values}
                    onChange={e => refresh({ categoryId: e && e.value })}
                    isClearable
                    placeholder={<T id="categoryPlaceholder" />}
                    value={itvCodeGroups.filters.categoryId.selected && itvCodeGroups.filters.categoryId.values.filter(c => c.value === itvCodeGroups.filters.categoryId.selected.value)}
                    isDisabled={loading} />
                </FormGroup>
              </Col>}
              {itvCodeGroups && itvCodeGroups.filters && itvCodeGroups.filters.familyId && itvCodeGroups.filters.familyId.values && <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel for="familyId" id="familyLabel" />
                  <CustomSelect
                    inputId="familyId"
                    name="familyId"
                    options={itvCodeGroups.filters.familyId.values}
                    onChange={e => refresh({ familyId: e && e.value })}
                    isClearable
                    placeholder={<T id="familyPlaceholder" />}
                    value={itvCodeGroups.filters.familyId.selected && itvCodeGroups.filters.familyId.values.filter(c => c.value === itvCodeGroups.filters.familyId.selected.value)}
                    isDisabled={loading} />
                </FormGroup>
              </Col>}
            </Row>
          </Form>
          <div style={{ alignItems: 'center' }}>
            <TButton
              tag={NavLink}
              to={{
                pathname: "./itvcodegroups/new",
                state: {
                  businessId: itvCodeGroups && itvCodeGroups.filters.businessId.selected && itvCodeGroups.filters.businessId.selected.value,
                  businessName: itvCodeGroups && itvCodeGroups.filters.businessId.selected && itvCodeGroups.filters.businessId.selected.label,
                  categoryId: itvCodeGroups && itvCodeGroups.filters.categoryId.selected && itvCodeGroups.filters.categoryId.selected.value,
                  familyId: itvCodeGroups && itvCodeGroups.filters.familyId.selected && itvCodeGroups.filters.familyId.selected.value
                }
              }}
              id="create"
              disabled={!(itvCodeGroups && itvCodeGroups.filters.businessId.selected && itvCodeGroups.filters.businessId.selected.value)}
            />
            {!(itvCodeGroups && itvCodeGroups.filters.businessId.selected && itvCodeGroups.filters.businessId.selected.value) &&
                            <T id="businessMustBeChosen" style={{ marginLeft: 16 }} />
            }
          </div>
        </CardHeader>
      </Card>
      {loading && <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />}
      {!loading && error && <ErrAlert error={error} />}
      {!loading && !error && itvCodeGroups && <Table responsive striped hover className="mb-3 mt-2">
        <thead>
          <tr>
            <TH colName="count" colValues={{ total: itvCodeGroups.total }} colSpan="8" className={['border-top-0']} />
          </tr>
        </thead>
        <thead>
          <tr>
            <TH colName="itvcodegroupsId" />
            <TH colName="itvcodes" />
            <TH colName="businessLabel" />
            <TH colName="familyLabel" />
            <TH colName="categoryLabel" />
            <TH colName="lockedLabel" />
            <TH />
          </tr>
        </thead>
        <tbody>
          {itvCodeGroups && itvCodeGroups.result && itvCodeGroups.result.length > 0 && itvCodeGroups.result.map(itvcodegroup =>
            <tr key={itvcodegroup.itvcodeId} style={{
              transform: `rotate(0)`
            }}>
              <td className="align-middle">{itvcodegroup.itvcodegroupsId}</td>
              <td>
                {itvcodegroup.itvcodes && itvcodegroup.itvcodes.length > 0 && itvcodegroup.itvcodes.map(itvcode =>
                  <p className="mb-0" key={`${itvcode.itvcodeLabel}-${itvcode.familyId}`}>
                    {`${itvcode.itvcodeIdentifier} - ${itvcode.itvcodeLabel}`}</p>
                )}
              </td>
              <td>
                {itvcodegroup.businessName}
              </td>
              <td>
                {itvcodegroup.familyName}
              </td>
              <td>
                {itvcodegroup.categoryName}
              </td>
              <td>
                {!!itvcodegroup.locked && <T id="locked" />}
              </td>
              <td className="text-right align-middle">
                <NavLink className="btn btn-secondary ml-auto stretched-link"
                  tag={NavLink}
                  to={{
                    pathname: `./itvcodegroups/${itvcodegroup.itvcodegroupsId}`,
                    state: {
                      businessId: itvcodegroup.businessId,
                      businessName: itvcodegroup.businessName
                    }
                  }}>
                  <T id="details" />
                </NavLink>
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr><td colSpan="8">
            <Pagination totalPage={itvCodeGroups.totalPage}
              currentPage={itvCodeGroups.currentPage}
              onPageClick={p => refresh({ p })} /></td></tr>
        </tfoot>
      </Table>}
    </>
  )
}

export default ItvcodesgroupsList
