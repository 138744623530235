import React from 'react'

const Sidebar = ({ elements, width, groupHeights, elementRenderer, handleElementClick }) => {
  const renderElement = React.useCallback(group => elementRenderer ? React.createElement(elementRenderer, { group, }) : group.title, [elementRenderer])

  let groupLines = React.useMemo(() =>
    elements.map((group, index) => <div key={group.id}
      className={'rct-sidebar-row rct-sidebar-row-' + (index % 2 === 0 ? 'even' : 'odd')}
      style={{
        height: `${groupHeights[index] - 1}px`,
        lineHeight: `${groupHeights[index] - 1}px`
      }}
      onClick={e => handleElementClick && handleElementClick(e, group)}>
      {renderElement(group)}
    </div>)
  , [groupHeights, elements, handleElementClick, renderElement])

  return (
    <div className={'rct-sidebar'}
      style={{ minWidth: `${width}px` }}>
      <div style={{ width: `${width}px` }}>{groupLines}</div>
    </div>
  )
}

export default Sidebar
