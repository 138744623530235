import React from 'react'
import { NavLink } from 'react-router-dom'

import {
  FormGroup,
  Card, CardHeader,
  Spinner
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import Pagination from 'components/Pagination'
import { TButton, TCustomInput, ErrAlert, TLabel, TInput } from 'components/TComponents'

import { debounce } from 'util/Utils'

const BusinessusersList = () => {
  const { api } = React.useContext(AppContext)

  const [data, setData] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()

  const [searchInput, setSearchInput] = React.useState('')

  React.useEffect(() => {
    Promise
      .all([
        api.get('/businessusers/list'),
        new Promise(resolve => setTimeout(resolve, 250))
      ])
      .then(([users,]) => setData(users))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api])

  const refresh = React.useCallback(params => {
    setLoading(true)
    setError()
    Promise.all([
      api.get('/businessusers/list', undefined, {
        ...data.filters,
        p: data.currentPage,
        ...params
      }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => setData(data))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api, data])

  const refreshDebounced = React.useMemo(() => debounce((params) => refresh(params), 250), [refresh])

  const handleSearchInput = React.useCallback(({ target: { value } }) => {
    setSearchInput(value)
    refreshDebounced({ filter: value, p: 1 })
  }, [refreshDebounced])

  return (
    <>
      <Card className="mb-2">
        <CardHeader>
          <FormGroup className="flex-grow-1 pl-0">
            <TLabel id="filterTitle" check className="sr-only" for="businessUserFilter" />
            <TInput
              name="businessUserFilter"
              type="text"
              placeholder="filterPlaceholder"
              value={searchInput}
              onChange={handleSearchInput} />
          </FormGroup>
          <FormGroup className="flex-grow-1 pl-0 mb-0">
            <TCustomInput type="switch"
              id="locked"
              name="locked"
              label="locked"
              className="w-100 bg-transparent border-0"
              disabled={loading}
              checked={data ? data.filters.locked : false}
              onChange={e => refresh({ locked: e.target.checked, p: 1 })} />
          </FormGroup>
        </CardHeader>
        <CardHeader>
          <TButton tag={NavLink}
            to="./businessusers/new"
            id="create" />
        </CardHeader>
      </Card>
      {loading && <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />}
      {!loading && error && <ErrAlert error={error} />}
      {!loading && !error && data.result.map(businessuser => <Card key={businessuser.userId} className="mb-2">
        <CardHeader className="d-flex align-items-center">
          <span className="h6 mb-0">
            {businessuser.locked && <i className="mr-1 iconsmind-Lock text-danger" />}
            {businessuser.firstName}&nbsp;{businessuser.lastName}
          </span>
          <TButton id="view"
            className="ml-auto stretched-link" outline
            tag={NavLink}
            to={`./businessusers/${businessuser.userId}`} />
        </CardHeader>
      </Card>)}

      {data && <Pagination totalPage={data.totalPage} currentPage={data.currentPage} onPageClick={p => refresh({ p })} />}
    </>
  )
}

export default BusinessusersList
