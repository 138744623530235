import React from 'react'
import { Switch } from 'react-router-dom'

import Route from 'components/Route'

import List from './BusinessuserList'
import Details from './BusinessuserDetails'

export default function Users({ match }) {
  return <Switch>
    <Route exact path={`${match.path}`} component={List} rights={['admin-businessusers_edit', 'admin-businessusers_view',]} />
    <Route path={`${match.path}/new`} component={Details} rights={['admin-businessusers_edit',]} />
    <Route path={`${match.path}/:id`} component={Details} rights={['admin-businessusers_edit', 'admin-businessusers_view',]} />
  </Switch>
}
