import React from 'react'
import {
  Input, Form, FormGroup, Label, Button, Card, CardHeader, CardBody
} from 'reactstrap'

import { AppContext, } from 'contexts/AppContext'

const Message = ({ onSubmit, defaultMessage, edit }) => {
  const [newMessage, setNewMessage] = React.useState(defaultMessage)

  return (
    <CardBody className="px-4 py-2">
      {edit && <FormGroup tag="fieldset">
        <Input value={newMessage.id} onChange={({ target: { value } }) => setNewMessage(message => ({ ...message, id: [value] }))} placeholder="id" />
        <Input value={newMessage.value} onChange={({ target: { value } }) => setNewMessage(message => ({ ...message, value }))} placeholder="value" />
        <Button onClick={() => {
          onSubmit(newMessage)
          setNewMessage(defaultMessage)
        }}>Save</Button>
      </FormGroup>}
      {!edit && <FormGroup tag="fieldset">
        <Label>{defaultMessage.id} - <pre>{defaultMessage.value}</pre></Label>
      </FormGroup>}
    </CardBody>
  )
}

const Translations = () => {
  const { translations, refreshTranslations } = React.useContext(AppContext)
  const [messages, setMessages] = React.useState({})
  const [filter, setFilter] = React.useState('')

  React.useEffect(() => { setMessages({ ...translations.messages }) }, [translations])

  const filteredMessages = React.useMemo(() =>
    Object.entries(messages)
      .filter(([key]) => !filter || key.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
  , [messages, filter])

  const download = React.useCallback(() => {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(messages, null, 2))
    var downloadAnchorNode = document.createElement('a')
    downloadAnchorNode.setAttribute("href", dataStr)
    downloadAnchorNode.setAttribute("download", translations.locale + ".json")
    document.body.appendChild(downloadAnchorNode) // required for firefox
    downloadAnchorNode.click()
    downloadAnchorNode.remove()
  }, [messages, translations.locale])

  return (
    <>
      <FormGroup tag="fieldset">
        <Input value={filter} onChange={e => setFilter(e.target.value)} placeholder="Filtre" className="mb-2" />
      </FormGroup>
      <div className="mb-4">
        <Button onClick={() => download()} className="mr-2">Download</Button>
        <Button onClick={() => refreshTranslations(messages)}>Inject local translations</Button>
      </div>
      <Form onSubmit={e => e.preventDefault()}>
        <Card className="mb-2">
          <CardHeader>Nouveau</CardHeader>
          <Message onSubmit={newMessage => setMessages({ ...messages, [newMessage.id]: newMessage.value })} defaultMessage={{ id: '', value: '' }} edit={true} />
        </Card>
        {filteredMessages.map(([id, value]) => (
          <Card key={id} className="mb-2">
            <CardBody className="px-4 py-2">
              <FormGroup tag="fieldset">
                <Label>{id}<Button onClick={() => setMessages(msgs => {
                  const copy = { ...msgs }
                  delete copy[id]
                  return copy
                })} className="ml-2 py-1 px-3">X</Button></Label>
                <Input value={value} onChange={({ target: { value } }) => setMessages(msgs => ({ ...msgs, [id]: value }))} />
              </FormGroup>
            </CardBody>
          </Card>
        ))}
        <Card>
          <CardHeader>Nouveau</CardHeader>
          <Message onSubmit={newMessage => setMessages({ ...messages, [newMessage.id]: newMessage.value })} defaultMessage={{ id: '', value: '' }} edit={true} />
        </Card>
      </Form>
      <div className="mt-4">
        <Button onClick={() => download()} className="mr-2">Download</Button>
        <Button onClick={() => refreshTranslations(messages)}>Inject local translations</Button>
      </div>
    </>
  )
}

export default Translations
