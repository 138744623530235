import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { parse } from 'intl-messageformat-parser'
import { Spinner } from 'reactstrap'

import { AppContext, } from 'contexts/AppContext'

import { NotificationContainer } from 'components/ReactNotifications/index'
import Route from 'components/Route'

import TopNav from 'containers/TopNav'
import defaultMessagesFr from 'lang/fr-FR.json'
import defaultMessagesEn from 'lang/en-GB.json'
import Routes from 'routes/AppRoutes'
import SubsidiarySelector from 'routes/SubsidiarySelector'
import SubsidiaryNew from 'routes/SubsidiaryNew'
import Login from 'routes/Login'
import ForgotPassword from 'routes/ForgotPassword'
import NewPassword from 'routes/NewPassword'

import 'bootstrap/scss/bootstrap.scss'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'assets/sass/theme.scss'

const Bold = chunk => <b>{chunk}</b>
const Small = chunk => <small>{chunk}</small>

const defaultRichTextElements = {
  lt: () => '<',
  b: Bold,
  small: Small
}

const App = () => {
  const { loading, user, translations, subsidiary, language, timezone, token } = React.useContext(AppContext)

  const intl = React.useMemo(() => {
    const defaultMessages = language && language.langIdentifier === 'en-GB' ? defaultMessagesEn : defaultMessagesFr
    const locale = translations ? translations.locale : 'fr-FR'
    const messages = translations
      ? { ...defaultMessages, ...translations.messages }
      : defaultMessages

    return {
      locale,
      messages: Object.entries(messages).reduce((acc, [key, value]) => {
        // TODO: find another way to compile translations
        try {
          acc[key] = parse(value)
        } catch (e) {
          acc[key] = value
        }
        return acc
      })
    }
  }, [translations, language])

  const routes = React.useMemo(() => {
    if (!user && !token) {
      return <>
        <div className="fixed-background" />
        <main className="d-flex align-items-center">
          <div className="container">
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/new-password" component={NewPassword} />
              <Redirect to={'/login'} />
            </Switch>
          </div>
        </main>
      </>
    }

    if ((user && user.newPasswordRequired) || (!user && token)) {
      return <>
        <div className="fixed-background" />
        <main className="d-flex align-items-center">
          <div className="container">
            <Route exact path="/new-password" component={NewPassword} />
            <Redirect to={'/new-password'} />
          </div>
        </main>
      </>
    }

    if (!subsidiary) {
      return <>
        <TopNav hasMenu={true} isSubsidiary />
        <div className="container">
          <Route exact path="/subsidiaries" component={SubsidiarySelector} />
          <Route exact path="/subsidiaries/new" component={SubsidiaryNew} />
          <Redirect to={'/subsidiaries'} />
        </div>
      </>
    }

    return <Routes />
  }, [user, subsidiary, token])


  if (loading) {
    return (
      <div className="d-flex position-absolute" style={{ left: 0, right: 0, top: 0, bottom: 0 }}>
        <Spinner color="primary" className="d-flex m-auto" />
      </div>
    )
  }

  return (
    <IntlProvider
      wrapRichTextChunksInFragment={true}
      defaultRichTextElements={defaultRichTextElements}
      timeZone={timezone}
      {...intl}>
      <NotificationContainer />
      {routes}
    </IntlProvider>
  )
}

export default App
