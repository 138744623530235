import React from 'react'
import {
  Alert,
  Card, CardTitle,
  Form, FormGroup, Label, Input,
  Button,
} from 'reactstrap'
import { Link } from 'react-router-dom'

import { AppContext } from 'contexts/AppContext'

import { T } from 'components/TComponents'

const TRLink = chunk => <Link to={`/login`} className="text-warning">{chunk}</Link>

const submitReducer = (state, action) => {
  switch(action.type) {
  case 'start': return { loading: true, result: undefined, error: undefined }
  case 'success': return { loading: false, result: action.result, error: undefined }
  case 'error': return { loading: false, result: undefined, error: action.error }
  case 'clear': return state.error ? { error: undefined, ...state } : state
  default: return state
  }
}

const submitInitialState = {
  loading: false,
  result: undefined,
  error: undefined
}

const ForgotPassword = ({ location }) => {
  const { api } = React.useContext(AppContext)

  const [email, setEmail] = React.useState((location.state && location.state.email) || '')
  const [submitState, submitDispatch] = React.useReducer(submitReducer, submitInitialState)

  React.useEffect(() => submitDispatch({ type: 'clear' }), [email])

  const handleSubmit = React.useCallback((e) => {
    e.preventDefault()

    submitDispatch({ type: 'start' })
    Promise.all([
      api.post('/auth/reinit', { body: JSON.stringify({ email }) }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([result]) => submitDispatch({ type: 'success', result }))
      .catch(error => submitDispatch({ type: 'error', error }))
  }, [api, email])

  return (
    <Card className="auth-card">
      <div className="position-relative image-side ">
        <T tagName="p" id="disclaimer.title" className="text-white h2" />
        <T tagName="p" id="disclaimer.content" className="text-white"
          values={{ login: TRLink }} />
      </div>
      <div className="form-side">
        <span className="logo-single" />
        <T tagName={CardTitle} id="form.title" className="mb-4" />
        <Form onSubmit={handleSubmit}>
          <FormGroup className="form-group has-float-label mb-4">
            <Input id="email"
              type="email"
              name="email"
              placeholder="email@demo.com"
              value={email}
              disabled={submitState.loading || Boolean(submitState.result)}
              onChange={e => setEmail(e.target.value)} />
            <T tagName={Label} id="form.email" for="email" />
          </FormGroup>

          {submitState.result && <T tagName={Alert} id="sent" color="success" />}
          {submitState.error && <T tagName={Alert} id={`error.${submitState.error.code}`} values={submitState.error.params} color="danger" />}

          <div className="d-flex justify-content-end align-items-center">
            <T id="form.submit"
              tagName={Button}
              color="primary"
              disabled={submitState.loading || Boolean(submitState.result)}
              className="btn-shadow"
              size="lg" />
          </div>
        </Form>
      </div>
    </Card>
  )
}

export default ForgotPassword
