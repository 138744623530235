import React from 'react'
import { useHistory, Link } from 'react-router-dom'

import {
  Card, CardBody, CardHeader, CardFooter,
  Form, FormGroup,
  Input,
  Row, Col,
  NavLink, Spinner
} from 'reactstrap'

import { AppContext, } from 'contexts/AppContext'
import CustomSelect from 'components/CustomSelect'

import { T, TInput, TButton, TLabel, TCustomInput, ErrAlert } from 'components/TComponents'

import { debounce } from 'util/Utils'

const ItvcodelistNew = () => {
  const { api } = React.useContext(AppContext)

  const history = useHistory()

  const [itvcodesBase, setItvcodesBase] = React.useState()
  const [itvcodesFiltered, setItvcodesFiltered] = React.useState({})
  const [itvcodesError, setItvcodesError] = React.useState()
  const [itvcodesLoading, setItvcodesLoading] = React.useState(true)

  const [creating, setCreating] = React.useState(false)
  const [createError, setCreateError] = React.useState()

  const [itvcodelistLabel, setItvcodelistLabel] = React.useState('')
  const [itvcodes, setItvcodes] = React.useState([])

  const [searchInput, setSearchInput] = React.useState('')

  React.useEffect(() => {
    Promise.all([
      api.get('/itvcodes/list', undefined, { ipp: -1 }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => setItvcodesBase(data))
      .catch(error => setItvcodesError(error))
      .then(() => setItvcodesLoading(false))
  }, [api])

  React.useEffect(() => {
    setCreateError()
  }, [itvcodelistLabel, itvcodes])

  React.useEffect(() => {
    const codesFiltered = {}
    itvcodesBase && itvcodesBase.result.map(code => {
      if (!codesFiltered[code.categoryId]) {
        codesFiltered[code.categoryId] = {}
        codesFiltered[code.categoryId].categoryName = code.categoryName
      }
      if (!codesFiltered[code.categoryId][code.familyId]) {
        codesFiltered[code.categoryId][code.familyId] = {}
        codesFiltered[code.categoryId][code.familyId].familyName = code.familyName
        codesFiltered[code.categoryId][code.familyId].results = []
      }
      codesFiltered[code.categoryId][code.familyId].results.push(code)
    })
    setItvcodesFiltered(codesFiltered)
  }, [itvcodesBase])

  const check = React.useCallback(({ target: { checked, name } }) => {
    setItvcodes(itvcodes => checked
      ? [...itvcodes, Number(name)]
      : itvcodes.filter(itvcodeIt => itvcodeIt !== Number(name))
    )
  }, [])

  const create = React.useCallback(() => {
    setCreating(true)
    setCreateError()
    api.post('/itvcodelists/details', {
      body: JSON.stringify({
        itvcodelistLabel, itvcodes,
      })
    })
      .then(() => {
        setCreating(false)
        history.push({ pathname: '/admin/itvcodelists' })
      })
      .catch(error => {
        setCreateError(error)
        setCreating(false)
      })
  }, [api, history, itvcodelistLabel, itvcodes])

  const refresh = React.useCallback((params) => {
    setItvcodesLoading(true)
    setItvcodesError()
    Promise.all([
      api.get('/itvcodes/list', undefined, {
        ipp: -1,
        filter: itvcodesBase.filters.filter,
        categoryId: itvcodesBase.filters.categories.selected,
        familyId: itvcodesBase.filters.families.selected,
        ...params
      }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([itvCodeGroups]) => setItvcodesBase(itvCodeGroups))
      .catch(error => setItvcodesError(error))
      .then(() => setItvcodesLoading(false))
  }, [api, itvcodesBase])

  const refreshDebounced = React.useMemo(() => debounce(params => refresh(params), 250), [refresh])

  const handleSearchInput = React.useCallback(({ target: { value } }) => {
    setSearchInput(value)
    refreshDebounced({ filter: value, p: 1 })
  }, [refreshDebounced])

  return (
    <>
      <NavLink tag={Link} to="/admin/itvcodelists"><T id="returnToList" /></NavLink>
      <Card className="mb-2" tag={Form} onSubmit={e => e.preventDefault()}>
        <CardHeader tag="h2">
          <Form onSubmit={e => e.preventDefault()}>
            <Row form>
              <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel for="searchInput" id="searchInputLabel" />
                  <TInput
                    name="searchInput"
                    type="text"
                    placeholder="searchInputPlaceholder"
                    value={searchInput}
                    onChange={handleSearchInput} />
                </FormGroup>
              </Col>
              {itvcodesBase && itvcodesBase.filters && itvcodesBase.filters.categories && itvcodesBase.filters.categories.values && <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel for="categoryId" id="categoryLabel" />
                  <CustomSelect
                    inputId="categoryId"
                    name="categoryId"
                    options={itvcodesBase.filters.categories.values}
                    onChange={e => refresh({ categoryId: e && e.categoryId })}
                    isClearable
                    placeholder={<T id="categoryPlaceholder" />}
                    value={itvcodesBase.filters.categories.selected && itvcodesBase.filters.categories.values.filter(c => c.categoryId == itvcodesBase.filters.categories.selected)}
                    getOptionLabel={option => option.categoryName}
                    getOptionValue={option => option.categoryId}
                    isDisabled={itvcodesLoading} />
                </FormGroup>
              </Col>}
              {itvcodesBase && itvcodesBase.filters && itvcodesBase.filters.families && itvcodesBase.filters.families.values && <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel for="familyId" id="familyLabel" />
                  <CustomSelect
                    inputId="familyId"
                    name="familyId"
                    options={itvcodesBase.filters.families.values}
                    onChange={e => refresh({ familyId: e && e.familyId })}
                    isClearable
                    placeholder={<T id="familyPlaceholder" />}
                    value={itvcodesBase.filters.families.selected && itvcodesBase.filters.families.values.filter(f => f.familyId == itvcodesBase.filters.families.selected)}
                    getOptionLabel={option => option.familyName}
                    getOptionValue={option => option.familyId}
                    isDisabled={itvcodesLoading} />
                </FormGroup>
              </Col>}
            </Row>
          </Form>
        </CardHeader>
        <CardBody>
          <FormGroup tag="fieldset">
            <TLabel for="itvcodelistLabel" id="itvcodelistLabel" />
            <Input id="itvcodelistLabel"
              type="text"
              name="itvcodelistLabel"
              disabled={creating}
              value={itvcodelistLabel}
              onChange={e => setItvcodelistLabel(e.target.value)} />
          </FormGroup>

          <TLabel id="itvcodesLabel" />
          {itvcodesError && <ErrAlert error={itvcodesError} />}
          {itvcodesLoading && <Spinner className="d-flex" />}
          {!itvcodesLoading && itvcodesFiltered && Object.values(itvcodesFiltered).map(categoryCode =>
            <>
              <h6 className="mt-4 mb-2"><T id="itvcodelist.category" /> : {categoryCode.categoryName}</h6>
              {Object.values(categoryCode).map(familyCode =>
                <>
                  {familyCode.familyName && (
                    <span className="mb-2 mt-2 d-block">
                      <T id="itvcodelist.family" /> : {familyCode.familyName}
                    </span>
                  )}
                  {familyCode.results && familyCode.results.map(itvcode =>
                    <FormGroup key={itvcode.itvcodeId} tag="fieldset" check>
                      <TCustomInput
                        id={'itvcode.' + itvcode.itvcodeId}
                        className="pl-0"
                        type="checkbox"
                        name={itvcode.itvcodeId}
                        checked={itvcode.checked}
                        onChange={check}
                        label={{ id: 'itvcodeformat', values: itvcode }} />
                    </FormGroup>
                  )}
                </>
              )}
            </>
          )}

          {createError && <ErrAlert error={createError} />}
        </CardBody>
        <CardFooter className="d-flex justify-content-end">
          <TButton disabled={creating} type="button"
            className="ml-2" color="primary"
            onClick={e => create(e)}
            id="create" />
          <TButton disabled={creating} type="cancel" tag={Link} className="ml-2" to="/admin/itvcodelists" id="cancel" />
        </CardFooter>
      </Card>
    </>
  )
}

export default ItvcodelistNew
