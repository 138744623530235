import React, { useCallback } from 'react'
import {
  Alert, Card, CardBody, CardHeader, CardFooter,
  Form, FormGroup, Input,
  Modal, ModalBody, ModalFooter,
  Spinner
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import CustomSelect from 'components/CustomSelect'
import ProtectedComponent from 'components/ProtectedComponent'
import { T, TInput, TButton, TLabel, ErrAlert } from 'components/TComponents'

import { debounce, getColors } from 'util/Utils'

const DeskTags = () => {
  const { api } = React.useContext(AppContext)

  const [create, setCreate] = React.useState(false)
  const [createSuccess, setCreateSuccess] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [editItemId, setEditItemId] = React.useState()
  const [editItemValue, setEditItemValue] = React.useState('')
  const [editItemColor, setEditItemColor] = React.useState('')
  const [tagToDelete, setTagToDelete] = React.useState('')
  const [errorLoading, setErrorLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [createError, setCreateError] = React.useState(false)
  const [searchInput, setSearchInput] = React.useState('')
  const [tag, setTag] = React.useState('')
  const [tagColor, setTagColor] = React.useState()
  const [data, setData] = React.useState()
  const [modalOpen, setModalOpen] = React.useState(false)

  const tagColors = getColors()

  React.useEffect(() => {
    Promise.all([
      api.get('/desktags/list'),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => setData(data))
      .catch(error => setErrorLoading(error))
      .then(() => setLoading(false))
  }, [api, create])

  const refresh = React.useCallback(params => {
    setLoading(true)
    setErrorLoading()
    Promise.all([
      api.get('/desktags/list', undefined, {
        ...data.filters,
        p: data.currentPage,
        ...params
      }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => setData(data))
      .catch(error => setErrorLoading(error))
      .then(() => setLoading(false))
  }, [api, data])

  const refreshDebounced = React.useMemo(() => debounce((params) => refresh(params), 250), [refresh])

  const handleSearchInput = React.useCallback(({ target: { value } }) => {
    setSearchInput(value)
    refreshDebounced({ filter: value, p: 1 })
  }, [refreshDebounced])

  const saveTag = useCallback(({ deskTagId, tagIdentifier, colorHex }) => {
    if (getDuplicate(tagIdentifier) && data.result.find(r => r.deskTagId === deskTagId).colorHex === colorHex) {
      setError('duplicateTag')
    } else {
      api.post('/desktags/details', {
        body: JSON.stringify({
          deskTagId,
          tagIdentifier,
          colorHex
        })
      })
        .then(() => {
          setEditItemId()
          refreshDebounced({ filter: searchInput, p: 1 })
        })
        .catch(error => setError(error))
        .then(() => {
          setEditItemId()
        })
    }
  }, [api, refreshDebounced, searchInput, getDuplicate, data])

  const deleteTag = useCallback(deskTagId => {
    api.del(`/desktags/details&deskTagId=${deskTagId}`)
      .then(() => {
        refreshDebounced({ filter: searchInput, p: 1 })
      })
      .catch(error => setError(error))
  }, [api, refreshDebounced, searchInput])

  const postTag = useCallback(() => {
    if (!getDuplicate(tag)) {
      setCreate(true)
      api.post('/desktags/details', {
        body: JSON.stringify({ tagIdentifier: tag, colorHex: tagColor || null })
      })
        .then(response => {
          if (response.deskTagId) {
            setCreateSuccess(true)
            setTag('')
            setTagColor()
          }
        })
        .catch(error => setCreateError(error))
        .then(() => setCreate(false))
    } else {
      setCreate(false)
      setCreateError('duplicateTag')
    }
  }, [api, tag, tagColor, getDuplicate])

  const getDuplicate = React.useCallback(value => {
    let duplicate = false
    if (data && data.result && data.result.length > 0) {
      data.result.map(result => {
        if (value === result.tagIdentifier) {
          duplicate = true
        }
      })
    }
    return duplicate
  }, [data])

  return (
    <>
      <Card className="mb-2">
        <CardHeader className="pb-0">
          <FormGroup className="flex-grow-1 pl-0">
            <TLabel id="filterTitle" check className="sr-only" for="businessFilter" />
            <TInput
              name="businessFilter"
              type="text"
              placeholder="filterPlaceholder"
              value={searchInput}
              onChange={handleSearchInput} />
          </FormGroup>
        </CardHeader>
      </Card>
      <Card className="mb-2">
        <CardBody tag={Form}>
          <FormGroup tag="fieldset">
            <TLabel for="tag" id="tag" />
            <Input id="tag"
              type="text"
              name="tag"
              value={tag}
              onChange={e => {
                setTag(e.target.value)
                setCreateSuccess(false)
                setCreateError(false)
              }}
            />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="colorHex" id="colorHex" />
            <CustomSelect
              id="colorHex"
              onChange={e => setTagColor(e.value)}
              isSearchable={false}
              name="colorHex"
              placeholder={<T id="colorHex.placeholder" />}
              options={[{ key: 'noColor', value: null }].concat(tagColors)}
              value={tagColors.find(c => c.value === tag.colorHex)}
              getOptionLabel={option => option.key === 'noColor' ? <T id="Tag.noColor" raw /> : <div style={{ backgroundColor: option.value }} className="color-tag" />} />
          </FormGroup>
        </CardBody>
        <CardFooter className="d-flex justify-content-end">
          <TButton
            className="ml-2"
            onClick={postTag}
            id="add" />
        </CardFooter>
      </Card>
      {!loading && createError && <ErrAlert error={createError} />}
      {!loading && createSuccess && <T tagName={Alert} id="sent" color="success" />}
      {loading && <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />}
      {!loading && errorLoading && <ErrAlert error={errorLoading} />}
      {!loading && !errorLoading && data.result.map(tag => <Card key={tag.deskTagId} className="mb-2">
        <CardHeader className="d-flex align-items-center">
          {editItemId && editItemId === tag.deskTagId ? (
            <div className='w-100'>
              <div className='d-flex flex-column'>
                <FormGroup tag="fieldset">
                  <TLabel for="tag" id="tag" />
                  <Input
                    name="editTag"
                    type="text"
                    className="mr-4"
                    style={{ flex: 1 }}
                    defaultValue={tag.tagIdentifier}
                    onChange={e => {
                      setEditItemValue(e.target.value)
                      setError()
                    }} />
                </FormGroup>
                <FormGroup tag="fieldset">
                  <TLabel for="colorHex" id="colorHex" />
                  <CustomSelect
                    id="colorHex"
                    onChange={e => setEditItemColor(e.value)}
                    isSearchable={false}
                    name="colorHex"
                    placeholder={<T id="colorHex.placeholder" />}
                    options={[{ key: 'noColor', value: null }].concat(tagColors)}
                    value={tagColors.find(c => c.value === editItemColor)}
                    getOptionLabel={option => option.key === 'noColor' ? <T id="Tag.noColor" raw /> : <div style={{ backgroundColor: option.value }} className="color-tag" />} />
                </FormGroup>
              </div>
              <div className="text-right">
                <ProtectedComponent rights={['admin-tags-desk_edit']}>
                  <TButton
                    id="save"
                    className="ml-auto mr-4"
                    onClick={() => { saveTag({ deskTagId: tag.deskTagId, tagIdentifier: editItemValue, colorHex: editItemColor }) }}
                  />
                  <TButton
                    id="cancel"
                    className="ml-auto"
                    onClick={() => {
                      setEditItemId()
                      setError()
                    }}
                    outline
                  />
                </ProtectedComponent>
              </div>
            </div>
          ) : (
            <>
              <div style={{ backgroundColor: tag.colorHex ? tag.colorHex : '#fff' }} className="color-tag mr-2 mt-1" />
              <span className="h5 mb-0" style={{ flex: 1 }}>{tag.tagIdentifier}</span>
              <ProtectedComponent rights={['admin-tags-desk_edit']}>
                <div>
                  <TButton
                    id="edit"
                    className="ml-auto mr-4"
                    onClick={() => {
                      setEditItemId(tag.deskTagId)
                      setEditItemValue(tag.tagIdentifier)
                      setEditItemColor(tag.colorHex)
                    }}
                    outline
                  />
                  <TButton
                    id="delete"
                    className="ml-auto"
                    onClick={() => {
                      setModalOpen(true)
                      setTagToDelete(tag.deskTagId)
                    }}
                    color="danger"
                  />
                </div>
              </ProtectedComponent>
            </>
          )}
        </CardHeader>
      </Card>)}
      {!loading && error && <ErrAlert error={error} />}
      <Modal isOpen={modalOpen} fade={false} toggle={() => { setTagToDelete(); setModalOpen(false) }}>
        <ModalBody>
          <T id="deleteWarning" />
        </ModalBody>
        <ModalFooter>
          <TButton id="cancel" onClick={() => { setTagToDelete(); setModalOpen(false) }} />
          <TButton
            id="confirm"
            color="danger"
            onClick={() => {
              deleteTag(tagToDelete)
              setModalOpen(false)
            }}
            className="ml-2 d-flex align-items-center" />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default DeskTags
