import React from 'react'

import { T, } from 'components/TComponents'

const TH = ({ colName, colValues, className = [], propertyName, order, sort, disabled, colSpan = '1', ...props }) => {
  const _className = className.concat('border-bottom-0').join(' ')

  if (!colName) {
    return <th colSpan={colSpan} className={_className} {...props} />
  }

  if (!sort) {
    return <th className={_className} colSpan={colSpan}>
      <T id={colName} values={colValues} />
    </th>
  }

  const sorted = order && order.column === colName
  const direction = sorted && order.dir !== 'asc' ? 'Up' : 'Down'

  if (disabled) {
    return <th className={_className} colSpan={colSpan}>
      <span className={`d-flex align-items-center ${sorted ? 'text-info' : ''}`}>
        <T id={colName} values={colValues} />
        <i className={`ml-1 iconsmind-Arrow-${direction}`} />
      </span>
    </th>
  }

  return <th className={_className} colSpan={colSpan}>
    <a href="#"
      className={`d-flex align-items-center ${sorted ? 'text-info' : ''}`}
      onClick={e => sort(e, propertyName || colName)}>
      <T id={colName} values={colValues} />
      <i className={`ml-1 iconsmind-Arrow-${direction}`} />
    </a>
  </th>
}

export default TH
