import React from 'react'
import { Switch } from 'react-router-dom'

import Route from 'components/Route'

import List from 'routes/Admin/Bases/BaseList'
import New from 'routes/Admin/Bases/BaseNew'
import Edit from 'routes/Admin/Bases/BaseEdit'

export default function Bases({ match }) {
  return <Switch>
    <Route exact path={`${match.path}`} component={List} rights={['admin-bases_edit', 'admin-bases_view',]} />
    <Route path={`${match.path}/new`} component={New} rights={['admin-bases_edit',]} />
    <Route path={`${match.path}/:id`} component={Edit} rights={['admin-bases_edit', 'admin-bases_view',]} />
  </Switch>
}
