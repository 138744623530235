import React from 'react'
import { FormattedDate } from 'react-intl'
import {
  Card, CardBody, CardHeader, CardFooter,
  Modal, ModalBody, ModalFooter,
  FormGroup, Input
} from 'reactstrap'
import { useHistory, Link } from 'react-router-dom'
import moment from 'moment-timezone'

import { AppContext } from 'contexts/AppContext'

import { formatDateTime } from 'util/Utils'
import ProtectedComponent from 'components/ProtectedComponent'
import { T, TAlert, TDatePicker, TLabel, TButton, ErrAlert, TNavLink } from 'components/TComponents'
import SlotSelector from 'components/SlotSelector'

const PartEdit = ({ match }) => {

  const { api, timezone } = React.useContext(AppContext)
  const history = useHistory()

  const [loading, setLoading] = React.useState(true)
  const [loadError, setLoadError] = React.useState(false)
  const [inventory, setInventory] = React.useState()
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
  const [deleteError, setDeleteError] = React.useState()
  const [deleteLoading, setDeleteLoading] = React.useState(false)

  const [quantity, setQuantity] = React.useState()
  const [comments, setComments] = React.useState()
  const [dateMovement, setDateMovement] = React.useState(new Date())
  const [movementModalOpen, setMovementModalOpen] = React.useState(false)
  const [movementLoading, setMovementLoading] = React.useState()
  const [movementSuccess, setMovementSuccess] = React.useState()
  const [movementError, setMovementError] = React.useState()

  const [showHistory, setShowHistory] = React.useState(false)

  React.useEffect(() => {
    api.get('/inventories/details', undefined, { inventoryId: match.params.id })
      .then(response => {
        if (response && response.length === 1) {
          setInventory(response[0])
        }
      })
      .catch(response => setLoadError(response))
      .then(() => setLoading(false))
  }, [api, match])

  const dateTimeToUTC = React.useCallback(initialDate => {
    const utcDateTime = moment.tz(initialDate, timezone).utc().format()
    const dateArray = utcDateTime.split('T')
    return `${dateArray[0]} ${dateArray[1]}`.split('Z')[0]
  }, [timezone])

  const handleSaveMovement = React.useCallback(() => {
    setMovementSuccess()
    setMovementError()
    setMovementLoading(true)
    if (Number.isInteger(Number(quantity))) {
      api.post('/inventories/movement', {
        body: JSON.stringify({
          inventoryId: match.params.id,
          quantity: Number(quantity),
          comments,
          dateMovement: dateTimeToUTC(formatDateTime(dateMovement))
        })
      })
        .then((response) => {
          if (response && response[0]) {
            setQuantity()
            setComments()
            setDateMovement(new Date())
            setMovementSuccess(true)
            setMovementModalOpen(false)
            setInventory(response[0])
          }
        })
        .catch(error => setMovementError(error.code))
        .then(() => setMovementLoading(false))
    } else {
      setMovementError('quantityMustBeInt')
      setMovementLoading(false)
    }
  }, [api, comments, dateMovement, match.params.id, quantity, dateTimeToUTC])

  const deleteStock = React.useCallback(() => {
    setDeleteLoading(true)
    api.del('/inventories/details', undefined, { inventoryId: match.params.id })
      .then(response => {
        if (response && response.code) {
          setDeleteError(response.code)
        } else {
          history.push('/veloparts/inventories')
        }
        setInventory(response)
        setDeleteModalOpen(false)
      })
      .catch(response => setDeleteError(response))
      .then(() => setDeleteLoading(false))
  }, [api, history, match])

  const parseMovements = m => {
    return (
      <p key={m.movementId}>
        <FormattedDate value={new Date(m.dateMovement)} />{' - '}
        <T id={`history.${m.srcIdentifier}`} />
        {` ${m.srcValueId} `}
        {<T id={m.srcIdentifier === 'velocare_interventionId' ? 'history.decrease_movement' : 'history.do_movement'} />}
        {` ${m.quantity}`}
        {m.comments && (
          <>
            {` `}<T id={`history.comments`} />
            {` "${m.comments}"`}
          </>
        )
        }
      </p>
    )
  }

  return (
    <>
      <TNavLink id="returnToList" tag={Link} to="/veloparts/inventories" />
      {!loading && !loadError && inventory && (
        <>
          <Card>
            <ProtectedComponent rights={['veloparts-inventories_movement']}>
              <CardHeader>
                <TButton id="createMovement"
                  onClick={() => { setMovementModalOpen(true) }} />
              </CardHeader>
            </ProtectedComponent>
            <CardBody>
              <>
                <FormGroup tag="fieldset">
                  <TLabel for="warehouseId" id="warehouseId" />
                  <span>{inventory.warehouseLabel}</span>
                </FormGroup>
                <FormGroup tag="fieldset">
                  <TLabel for="partId" id="partId" />
                  <span>{`${inventory.partRef} - ${inventory.partLabel}`}</span>
                </FormGroup>
                <FormGroup tag="fieldset">
                  <TLabel for="quantity" id="quantity" />
                  <span>{inventory.quantity}</span>
                  {inventory.quantity < 0 && ` ⚠️`}
                </FormGroup>
              </>
              <div>
                {movementSuccess && <TAlert id="movementSuccess" className="mb-0" color="success" />}
              </div>
            </CardBody>
            <CardBody className="Section">
              <TButton className="mb-3" id={showHistory ? "hideHistory" : "showHistory"} onClick={() => setShowHistory(!showHistory)} />
              {showHistory && (
                <div>
                  {inventory.movements && inventory.movements.map(m => parseMovements(m))}
                </div>
              )}
            </CardBody>
            <CardFooter>
              <ProtectedComponent rights={['veloparts-inventories_edit']}>
                <div className="d-flex">
                  <TButton id='delete' onClick={() => setDeleteModalOpen(true)} color="primary" />
                </div>
              </ProtectedComponent>
            </CardFooter>
          </Card>

          <Modal isOpen={movementModalOpen} fade={false} toggle={() => setMovementModalOpen(false)}>
            <ModalBody>
              <ProtectedComponent rights={['veloparts-inventories_movement']}>
                <FormGroup tag="fieldset">
                  <TLabel for="quantity" id="quantity" />
                  <Input id="quantity"
                    type="number"
                    name="quantity"
                    value={quantity}
                    onChange={e => { setQuantity(e.target.value) }}
                    disabled={movementLoading}
                  />
                </FormGroup>
                <FormGroup tag="fieldset">
                  <TLabel for="comments" id="comments" />
                  <Input id="comments"
                    type="textarea"
                    name="comments"
                    onChange={e => { setComments(e.target.value) }}
                    value={comments}
                    disabled={movementLoading}
                  />
                </FormGroup>
                <FormGroup tag="fieldset">
                  <TLabel id="dateMovement" />
                  <div className="d-flex">
                    <i className="btn btn-secondary simple-icon-arrow-left px-2 mr-1"
                      onClick={() => {
                        setDateMovement(new Date(dateMovement.getTime() - (3600 * 1000 * 24)))
                      }}
                    />
                    <TDatePicker
                      wrapperClassName="w-auto"
                      disabled={movementLoading}
                      customInput={<SlotSelector className="datetime-fixed" />}
                      showTimeSelect
                      selected={dateMovement}
                      onChange={value => setDateMovement(value)}

                      dateFormat="eeee dd/MM/yyyy HH:mm"
                    />
                    <i className="btn btn-secondary simple-icon-arrow-right px-2 ml-1"
                      onClick={() => {
                        setDateMovement(new Date(dateMovement.getTime() + (3600 * 1000 * 24)))
                      }}
                    />
                  </div>
                </FormGroup>
              </ProtectedComponent>
              {movementError && <TAlert id={movementError} className="mb-0" color="danger" />}
            </ModalBody>
            <ModalFooter>
              <>
                <TButton id="cancel" className="ml-2" onClick={() => setMovementModalOpen(false)} color="warning" />
                <TButton id="save" loading={movementLoading} disabled={movementLoading} className="ml-2" onClick={() => handleSaveMovement()} color="success" />
              </>
            </ModalFooter>
          </Modal>

          <Modal isOpen={deleteModalOpen} fade={false} toggle={() => setDeleteModalOpen(false)}>
            <ModalBody>
              <T id='deleteContent' />
              {deleteError && <ErrAlert error={deleteError} className="mb-0 mt-2" />}
            </ModalBody>
            <ModalFooter>
              <TButton id="deleteCancel"
                disabled={deleteLoading}
                loading={deleteLoading}
                onClick={() => setDeleteModalOpen(false)} />
              <TButton id={`deleteConfirm`}
                loading={deleteLoading} disabled={deleteLoading} className="ml-2" color="danger" onClick={() => deleteStock()} />
            </ModalFooter>
          </Modal>
        </>
      )
      }
    </>
  )
}

export default PartEdit
