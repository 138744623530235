import React from 'react'
import {
  Card, CardHeader, CardFooter,
  FormGroup, Spinner,
  Row, Col,
  Modal, ModalBody, ModalFooter
} from 'reactstrap'
import moment from 'moment-timezone'

import { AppContext } from 'contexts/AppContext'

import CustomSelect from 'components/CustomSelect'
import { T, TLabel, TAlert, TButton } from 'components/TComponents'


const VeloptimSlots = () => {
  const { api, timezone } = React.useContext(AppContext)

  const [loading, setLoading] = React.useState()

  const [slot, setSlot] = React.useState()
  const [slots, setSlots] = React.useState()
  const [slotsError, setSlotsError] = React.useState()
  const [createState, setCreateState] = React.useState(false)
  const [createStateError, setCreateStateError] = React.useState()

  const [editState, setEditState] = React.useState(false)
  const [editStateError, setEditStateError] = React.useState(false)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [groupToDelete, setSlotToDelete] = React.useState()

  // const zoningAdminRight = useHasRights('admin-zoninggroup')

  const [startHours, setStartHours] = React.useState(0)
  const [startMinutes, setStartMinutes] = React.useState(0)
  const [endHours, setEndHours] = React.useState(0)
  const [endMinutes, setEndMinutes] = React.useState(0)
  const [startTime, setStartTime] = React.useState('')
  const [endTime, setEndTime] = React.useState('')

  const hours = Array(24).fill().map((_, i) => i)
  const minutes = Array(12).fill().map((_, i) => (i * 5))

  React.useEffect(() => {
    getSlots()
  }, [getSlots])

  React.useEffect(() => {
    setStartHours(Math.trunc(startTime / 60))
    setStartMinutes(startTime % 60)
    setEndHours(Math.trunc(endTime / 60))
    setEndMinutes(endTime % 60)
  }, [startTime, endTime, timezone])

  const handleStartHours = React.useCallback(({ target: { value } }) => {
    setStartTime(startMinutes + Number(value) * 60)
  }, [startMinutes])

  const handleStartMinutes = React.useCallback(({ target: { value } }) => {
    setStartTime(Number(value) + startHours * 60)
  }, [startHours])

  const handleEndHours = React.useCallback(({ target: { value } }) => {
    setEndTime(endMinutes + Number(value) * 60)
  }, [endMinutes])

  const handleEndMinutes = React.useCallback(({ target: { value } }) => {
    setEndTime(Number(value) + endHours * 60)
  }, [endHours])

  const getSlots = React.useCallback(() => {
    setLoading(true)
    api.get('/veloptim/slots', undefined)
      .then(response => setSlots(response))
      .catch(error => setSlotsError(error))
      .then(() => setLoading(false))
  }, [api])

  const toSqlDateFormat = React.useCallback((time) => {
    const hour = Math.trunc(time / 60)
    const minutes = time % 60
    return moment().hour(hour).minutes(minutes).seconds(0).utc().format("HH:mm:ss").valueOf()
  }, [])

  const fromSqlFormat = React.useCallback((time) => {
    const timeArray = time.split(':')
    return Number(timeArray[0]) * 60 + Number(timeArray[1])
  }, [])

  const timeFromUTC = React.useCallback(initialDate => {
    const dateArray = initialDate.split(' ')
    const dateInJs = `${dateArray[0]}T${dateArray[1]}Z`
    return moment(dateInJs).tz(timezone).format('HH:mm')
  }, [timezone])

  const saveSlot = React.useCallback(id => {
    const body = {
      startSlot: toSqlDateFormat(startTime),
      endSlot: toSqlDateFormat(endTime),
      ...(editState && id && { subsidiarySlotId: id })
    }
    api.post('/veloptim/slot', {
      _stringifyBody: true,
      body
    })
      .then(() => {
        setEditState(false)
        setCreateState(false)
        setSlots([])
        getSlots()
      })
      .catch(error => isNaN(id) ? setCreateStateError(error) : setEditStateError(error))
  }, [toSqlDateFormat, startTime, endTime, editState, api, getSlots])

  const editSlot = React.useCallback(id => {
    const slotSelected = slots.find(s => s.subsidiarySlotId == id)
    if (slotSelected) {
      setSlot(slotSelected)
      setStartTime(fromSqlFormat(timeFromUTC(slotSelected.startSlot)))
      setEndTime(fromSqlFormat(timeFromUTC(slotSelected.endSlot)))
      setEditState(true)
    }
  }, [fromSqlFormat, timeFromUTC, slots])

  const handleCancelEdit = React.useCallback(() => {
    setSlot()
    setSlots()
    getSlots()
    setEditState(false)
    setCreateState(false)
    setEditStateError()
    setCreateStateError()
  }, [getSlots])

  const deleteSlots = React.useCallback(id => {
    api.del('/veloptim/slot', undefined, { subsidiarySlotId: id })
      .then(() => { getSlots() })
      .catch(error => { setSlotsError(error) })
  }, [getSlots, setSlotsError, api])

  return (
    <>
      {slotsError && (
        <TAlert id={slotsError || slotsError.code} color="danger" />
      )}
      <>
        <TLabel id="slot" className="d-block mb-3" />
        {loading && <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />}
        {!loading && slots && (
          <>
            {slots && slots.length > 0 && slots.map(s => (
              <Card className="mb-3" key={s.subsidiarySlotId}>
                {(editState && slot && slot.subsidiarySlotId === s.subsidiarySlotId) ? (
                  <>
                    <CardHeader className="d-flex align-items-center">
                      <div className="d-flex flex-column w-100">
                        <Row>
                          <TLabel id="from" className="w-100" />
                          <Col>
                            <FormGroup tag="fieldset">
                              <TLabel for="selectHour" id="selectHour" />
                              <CustomSelect
                                inputId="selectHour"
                                name="selectHour"
                                options={hours}
                                placeholder={"0"}
                                onChange={e => handleStartHours({ target: { value: e } })}
                                value={hours.filter(h => h == startHours)}
                                getOptionValue={option => option}
                                getOptionLabel={option => option} />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup tag="fieldset">
                              <TLabel for="selectMinute" id="selectMinute" />
                              <CustomSelect
                                inputId="selectMinute"
                                name="selectMinute"
                                options={minutes}
                                onChange={e => handleStartMinutes({ target: { value: e } })}
                                placeholder={"0"}
                                value={minutes.filter(h => h == startMinutes)}
                                getOptionValue={option => option}
                                getOptionLabel={option => option} />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <TLabel id="to" className="w-100" />
                          <Col>
                            <FormGroup tag="fieldset">
                              <TLabel for="selectHour" id="selectHour" />
                              <CustomSelect
                                inputId="selectHour"
                                name="selectHour"
                                options={hours}
                                placeholder={"0"}
                                onChange={e => handleEndHours({ target: { value: e } })}
                                value={hours.filter(h => h == endHours)}
                                getOptionValue={option => option}
                                getOptionLabel={option => option} />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup tag="fieldset">
                              <TLabel for="selectMinute" id="selectMinute" />
                              <CustomSelect
                                inputId="selectMinute"
                                name="selectMinute"
                                options={minutes}
                                onChange={e => handleEndMinutes({ target: { value: e } })}
                                placeholder={"0"}
                                value={minutes.filter(h => h == endMinutes)}
                                getOptionValue={option => option}
                                getOptionLabel={option => option} />
                            </FormGroup>
                          </Col>
                        </Row>
                        {editStateError && <TAlert color="danger" id={editStateError.code} />}
                      </div>
                    </CardHeader>
                    <CardFooter className="d-flex justify-content-end">
                      <div>
                        <TButton className="ml-auto mr-2" id="save" onClick={() => { saveSlot(s.subsidiarySlotId) }} />
                        <TButton className="ml-auto" color="danger" outline id="cancel" onClick={handleCancelEdit} />
                      </div>
                    </CardFooter>
                  </>
                ) : (
                  <CardHeader className="d-flex align-items-center">
                    <span className="h5 mb-0" style={{ flex: 1 }}>{`${timeFromUTC(s.startSlot)} - ${timeFromUTC(s.endSlot)}`}</span>
                    <div>
                      <TButton className="ml-auto mr-4" outline id="edit" disabled={editState || createState} onClick={() => { editSlot(s.subsidiarySlotId) }} />
                      <TButton className="ml-auto" color="danger" id="delete" onClick={() => {
                        setModalOpen(true)
                        setSlotToDelete(s.subsidiarySlotId)
                      }} />
                    </div>
                  </CardHeader>
                )}
              </Card>
            ))}
          </>
        )}
      </>
      {!createState && <TButton disabled={editState} id="addSlot" onClick={() => setCreateState(true)} />}
      {createState && !editState && (
        <Card>
          <CardHeader>
            <FormGroup tag="fieldset">
              <Row>
                <TLabel id="from" className="w-100" />
                <Col>
                  <FormGroup tag="fieldset">
                    <TLabel for="selectHour" id="selectHour" />
                    <CustomSelect
                      inputId="selectHour"
                      name="selectHour"
                      options={hours}
                      placeholder={"0"}
                      onChange={e => handleStartHours({ target: { value: e } })}
                      value={hours.filter(h => h == startHours)}
                      getOptionValue={option => option}
                      getOptionLabel={option => option} />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup tag="fieldset">
                    <TLabel for="selectMinute" id="selectMinute" />
                    <CustomSelect
                      inputId="selectMinute"
                      name="selectMinute"
                      options={minutes}
                      onChange={e => handleStartMinutes({ target: { value: e } })}
                      placeholder={"0"}
                      value={minutes.filter(h => h == startMinutes)}
                      getOptionValue={option => option}
                      getOptionLabel={option => option} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <TLabel id="to" className="w-100" />
                <Col>
                  <FormGroup tag="fieldset">
                    <TLabel for="selectHour" id="selectHour" />
                    <CustomSelect
                      inputId="selectHour"
                      name="selectHour"
                      options={hours}
                      placeholder={"0"}
                      onChange={e => handleEndHours({ target: { value: e } })}
                      value={hours.filter(h => h == endHours)}
                      getOptionValue={option => option}
                      getOptionLabel={option => option} />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup tag="fieldset">
                    <TLabel for="selectMinute" id="selectMinute" />
                    <CustomSelect
                      inputId="selectMinute"
                      name="selectMinute"
                      options={minutes}
                      onChange={e => handleEndMinutes({ target: { value: e } })}
                      placeholder={"0"}
                      value={minutes.filter(h => h == endMinutes)}
                      getOptionValue={option => option}
                      getOptionLabel={option => option} />
                  </FormGroup>
                </Col>
              </Row>
              {createStateError && <TAlert color="danger" id={createStateError.code} />}
            </FormGroup>
          </CardHeader>
          <CardFooter className="d-flex justify-content-end">
            <TButton id="save" className="mr-2" onClick={saveSlot} />
            <TButton color="danger" outline id="cancel" onClick={handleCancelEdit} />
          </CardFooter>

        </Card>
      )}
      <Modal isOpen={modalOpen} fade={false} toggle={() => { setSlotToDelete(); setModalOpen(false) }}>
        <ModalBody>
          <T id="deleteWarning" />
        </ModalBody>
        <ModalFooter>
          <TButton id="cancel" onClick={() => { setSlotToDelete(); setModalOpen(false) }} />
          <TButton
            id="confirm"
            color="danger"
            onClick={() => {
              deleteSlots(groupToDelete)
              setModalOpen(false)
            }}
            className="ml-2 d-flex align-items-center" />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default VeloptimSlots
