import React from 'react'
import CustomSelect from 'components/CustomSelect'
import {
  Card, CardHeader, CardFooter, Col, Row, CardBody, FormGroup
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import { T, TAlert, TLabel, TButton } from 'components/TComponents'

const ItvcodeequipmentList = () => {

  const { api } = React.useContext(AppContext)

  const [business, setBusiness] = React.useState()
  const [categories, setCategories] = React.useState()
  const [families, setFamilies] = React.useState()
  const [filtersDone, setFiltersDone] = React.useState(false)

  const [matrix, setMatrix] = React.useState()
  const [matrixFields, setMatrixFields] = React.useState()
  const [itvcodesBase, setItvcodesBase] = React.useState([])
  const [itvcodesFiltered, setItvcodesFiltered] = React.useState([])

  const [loadingMatrix, setLoadingMatrix] = React.useState()
  const [loadingCodes, setLoadingCodes] = React.useState()
  const [updating, setUpdating] = React.useState()

  const [matrixError, setMatrixError] = React.useState()
  const [itvCodesError, setItvCodesError] = React.useState()
  const [updateError, setUpdateError] = React.useState()

  const [updateSuccess, setUpdateSuccess] = React.useState(false)

  React.useEffect(() => {
    api.get('/matrices/list')
      .then(data => {
        setFilters(data.filters)
        setMatrixError()
      })
      .catch(error => {
        setMatrixError(error)
      })
  }, [api, setFilters])

  React.useEffect(() => {
    if (business && business.selected) {
      api.get('/matrices/list', undefined, {
        businessId: business.selected.value
      })
        .then(data => {
          setCategories(data.filters.categoryId)
          setFamilies(data.filters.familyId)
          setMatrixError()
        })
        .catch(error => setMatrixError(error))
    }
  }, [api, business])

  React.useEffect(() => {
    if (business && business.selected && categories && categories.selected) {
      api.get('/matrices/list', undefined, {
        businessId: business.selected.value,
        categoryId: categories.selected.value
      })
        .then(data => {
          setFamilies(data.filters.familyId)
          setMatrixError()
        })
        .catch(error => setMatrixError(error))
    }
  }, [api, business, categories])

  React.useEffect(() => {
    business && business.selected && families && families.selected && categories && categories.selected ? setFiltersDone(true) : setFiltersDone(false)
    if (filtersDone) {
      setUpdateError()
      api.get('/matrices/list', undefined, {
        businessId: business && business.selected && business.selected.value,
        familyId: families && families.selected && families.selected.value,
        categoryId: categories && categories.selected && categories.selected.value
      })
        .then(data => {
          setMatrixError()
          if (data && data.result && data.result.length > 0 && filtersDone) {
            const matrixData = data.result[0]
            setMatrix(matrixData)
            setLoadingCodes(true)
            api.get('/itvcodes/list', undefined, {
              businessId: business && business.selected && business.selected.value,
              familyId: families && families.selected && families.selected.value,
              categoryId: categories && categories.selected && categories.selected.value
            })
              .then(data => {
                setLoadingCodes(false)
                setItvCodesError()
                setItvcodesBase(data.result)
              })
              .catch(error => {
                setLoadingCodes(false)
                setItvCodesError(error)
              })
              .then(() => {
                setLoadingMatrix(true)
                api.get('/matrices/details', undefined, {
                  businessId: business && business.selected && business.selected.value,
                  matrixId: matrixData && matrixData.matrixId
                })
                  .then(data => {
                    setLoadingMatrix(false)
                    setMatrixError()
                    setMatrixFields(data.matrixFields)
                  })
                  .catch(error => {
                    setLoadingMatrix(false)
                    setMatrixError(error)
                  })
              })
          } else {
            setMatrix()
          }
        })
        .catch(error => setMatrixError(error))
    }
  }, [api, business, categories, families, filtersDone])

  React.useEffect(() => {
    if (matrixFields && matrixFields.length > 0 && itvcodesBase && itvcodesBase.length > 0) {
      let codes = JSON.parse(JSON.stringify(itvcodesBase))
      matrixFields.map(field => {
        itvcodesBase.map(code => {
          if (code.itvcodeId === field.itvcodeId) {
            codes = codes.filter(c => c.itvcodeId !== code.itvcodeId)
          }
        })
      })
      setItvcodesFiltered(codes)
    }
  }, [itvcodesBase, matrixFields])

  const setFilters = React.useCallback(data => {
    setBusiness(data.businessId)
    setCategories(data.categoryId)
    setFamilies(data.familyId)
  }, [])

  const handleItvCodeChange = React.useCallback((field, value) => {
    const newMatrixFields = JSON.parse(JSON.stringify(matrixFields))
    matrixFields.map((mField, i) => {
      if (mField.matrixColumnName === field) {
        value && value.itvcodeId
          ? newMatrixFields[i].itvcodeId = value.itvcodeId
          : newMatrixFields[i].itvcodeId = null
      }
    })
    setMatrixFields(newMatrixFields)
  }, [matrixFields])

  const handleSave = React.useCallback(() => {
    setUpdateSuccess(false)
    if (matrix && matrix.matrixId && business && business.selected && business.selected.value && filtersDone) {
      const matrixCodeItv = matrixFields.filter(mF => mF.itvcodeId)
      const body = {
        matrixId: matrix.matrixId,
        businessId: business.selected.value,
        matrixCodeItv
      }
      api.post('/matrices/linkMatrixCodeItv', { body: JSON.stringify(body) })
        .then(response => {
          if (response.code) {
            setUpdateError(response.code)
          } else {
            setUpdateSuccess(true)
          }
        })
        .catch(error => setUpdateError(error.code))
        .then(() => setUpdating(false))
    }
  }, [api, business, filtersDone, matrix, matrixFields])

  return (
    <div className="container-fluid">
      <Card className="mb-2">
        <CardHeader>
          <Row form>
            <Col sm="6" md="3" >
              <FormGroup tag="fieldset">
                <TLabel id="businessId.label" for="businessId" />
                <CustomSelect
                  id="businessId"
                  value={business && business.selected}
                  options={(business && business.values) || []}
                  isClearable
                  onChange={value => {
                    setBusiness({ ...business, selected: value })
                    setCategories({ ...categories, selected: null })
                    setFamilies({ ...families, selected: null })
                  }}
                  placeholder={<T id="businessId.placeholder" />}
                  noOptionsMessage={() => <T id="businessId.noResult" />} />
              </FormGroup>
            </Col>
            <Col sm="6" md="3" >
              <FormGroup tag="fieldset">
                <TLabel id="categoryId.label" for="categoryId" />
                <CustomSelect
                  id="categoryId"
                  value={categories && categories.selected}
                  options={(categories && categories.values) || []}
                  isClearable
                  onChange={value => {
                    setCategories({ ...categories, selected: value })
                    setFamilies({ ...families, selected: null })
                  }}
                  placeholder={<T id="categoryId.placeholder" />}
                  noOptionsMessage={() => <T id="noResult" raw />} />
              </FormGroup>
            </Col>
            <Col sm="6" md="3" >
              <FormGroup tag="fieldset">
                <TLabel id="familyId.label" for="familyId" />
                <CustomSelect
                  id="familyId"
                  value={families && families.selected}
                  options={(families && families.values) || []}
                  isClearable
                  onChange={value => setFamilies({ ...families, selected: value })}
                  placeholder={<T id="familyId.placeholder" />}
                  noOptionsMessage={() => <T id="noResult" raw />} />
              </FormGroup>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {matrixError && <TAlert id="notMatrix" className="mb-0" color="danger" />}
          {itvCodesError && <TAlert id={itvCodesError} className="mb-0" color="danger" />}
          {!matrixError && !itvCodesError && !updateError && (
            <>
              {!filtersDone ? (
                <T id="choseFiltersDescription" />
              ) : (
                <>
                  {!loadingMatrix && !loadingCodes && itvcodesBase && itvcodesBase.length > 0 && matrix && matrix.matrixId && matrixFields && matrixFields.length > 0 && (
                    <>
                      {matrixFields.map(field =>
                        <Col sm="6" md="3" key={field.matrixColumnName} >
                          <FormGroup tag="fieldset">
                            <span className="font-weight-bold h6 mb-2 d-block">
                              <T id={field.matrixColumnName} raw />
                            </span>
                            <CustomSelect
                              id="itvcode"
                              value={itvcodesBase.find(c => c.itvcodeId === field.itvcodeId)}
                              options={itvcodesFiltered}
                              isClearable
                              onChange={value => handleItvCodeChange(field.matrixColumnName, value)}
                              placeholder={<T id="itvcode.placeholder" />}
                              noOptionsMessage={() => <T id="itvcode.noResult" />}
                              getOptionLabel={option => `${option.itvcodeIdentifier} : ${option.itvcodeLabel}`}
                              getOptionValue={option => option.itvcodeId} />
                          </FormGroup>
                        </Col>
                      )}
                    </>
                  )}
                  {!loadingMatrix && !loadingCodes && !(matrix && matrix.matrixId) && <T id="noData" />}
                </>
              )}
            </>
          )}
        </CardBody>
        <CardFooter>
          <div className="mb-2">
            {updateError && <TAlert id={updateError} className="mb-0" color="danger" />}
            {updateSuccess && <TAlert id="updateSuccess" className="mb-0" color="success" />}
          </div>
          <div className="d-flex justify-content-end">
            <TButton
              disabled={(loadingMatrix && loadingCodes) || updating || !filtersDone}
              className="ml-2"
              spin={updating}
              onClick={() => handleSave()}
              id="save" />
          </div>
        </CardFooter>
      </Card>
    </div>
  )
}

export default ItvcodeequipmentList