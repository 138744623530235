import React from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'
import { NavLink as RRNavLink } from 'react-router-dom'

import {
  Button, NavLink,
  Card, CardHeader, CardBody,
  Form, FormGroup, Label,
  Spinner
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'
import { useFormatMessage } from 'hooks/intl.hooks'

import CustomSelect from 'components/CustomSelect'
import { T, TLabel, TInput, TAlert, ErrAlert, TButton } from 'components/TComponents'
import { Table } from 'components/Table'

const Mute = () => {
  const { api } = React.useContext(AppContext)
  const formatMesage = useFormatMessage()

  const [equipments, setEquipments] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()

  const [list, setList] = React.useState()
  const [listLoad, setListLoad] = React.useState(true)
  const [listErr, setListErr] = React.useState()

  const [muteLoad, setMuteLoad] = React.useState(false)
  const [muteError, setMuteError] = React.useState()

  const [success, setSuccess] = React.useState()

  const [selectedEquipment, setSelectedEquipment] = React.useState()
  const [comments, setComments] = React.useState('')

  React.useEffect(() => {
    api.get('/fluow/muteList')
      .then(response => setList(response))
      .catch(response => setListErr(response))
      .then(() => setListLoad(false))

    api.get('/equipments/list', undefined, { ipp: -1 })
      .then(response => setEquipments(response.result))
      .catch(response => setError(response))
      .then(() => setLoading(false))
  }, [api])

  const selectEquipment = React.useCallback((equipment) => {
    setSelectedEquipment(equipment)
    setSuccess(false)
  }, [])

  const mute = React.useCallback(() => {
    setMuteError()
    setMuteLoad(true)
    api.post('/fluow/mute', { body: JSON.stringify({ equipmentId: selectedEquipment ? selectedEquipment.equipmentId : undefined, comments }) })
      .then(() => {
        setSuccess(true)
        setSelectedEquipment()
      })
      .catch(response => setMuteError(response))
      .then(() => {
        setMuteLoad(false)
        listRefresh()
      })
  }, [api, comments, listRefresh, selectedEquipment])

  const unmute = React.useCallback((ignoredId) => {
    setMuteError()
    setMuteLoad(true)
    api.del('/fluow/mute', undefined, { ignoredId })
      .then(() => {
        setSuccess(true)
        setSelectedEquipment()
      })
      .catch(response => setMuteError(response))
      .then(() => listRefresh())
      .then(() => setMuteLoad(false))
  }, [api, listRefresh])

  const listRefresh = React.useCallback((params) => {
    setListLoad(true)
    api.get('/fluow/muteList', undefined, {
      p: list.currentPage,
      ipp: list.itemPerPage,
      ...params
    })
      .then(response => setList(response))
      .catch(response => setListErr(response))
      .then(() => setListLoad(false))
  }, [api, list])

  const DateCreated = React.useCallback(item =>
    <span><FormattedDate value={new Date(item.dateCreated)} />{' '}<FormattedTime value={new Date(item.dateCreated)} /></span>
  , [])

  const InterventionDate = React.useCallback(item => {
    return item.interventionDate
      ? <span><FormattedDate value={new Date(item.interventionDate)} />{' '}<FormattedTime value={new Date(item.interventionDate)} /></span>
      : <T id="noInterventionDate" />
  }, [])

  const IgnoredElement = React.useCallback(item => (item.equipmentId
    ? <NavLink className="p-0" tag={RRNavLink} to={`/equipments/${item.equipmentId}`}>{item.ignoredElement}</NavLink>
    : <T id="noIgnoredElement" values={item} />
  ), [])

  const UnMuteButton = React.useCallback(item => (
    <TButton className="ml-auto"
      id="unmute"
      disabled={muteLoad}
      onClick={() => unmute(item.ignoredId)}
    />
  ), [muteLoad, unmute])

  if (error || listErr) {
    return <ErrAlert error={error || listErr} />
  }

  return (<>
    <Card className="mb-5">
      <CardHeader><T className="h5" id="title" /></CardHeader>
      <CardBody>
        <Form>
          <div className="d-flex">
            <FormGroup tag="fieldset">
              <TLabel id="equipment.label" for="equipment" />
              <CustomSelect
                id="equipment"
                name="equipment"
                isSearchable={true}
                options={equipments && equipments}
                placeholder={<T id="equipment.placeholder" />}
                noOptionsMessage={() => <T id="noResult" />}
                getOptionLabel={option => option.hasLivemodule
                  ? `${option.equipmentIdentifier} - LVM (${option.marque_livemodule}) `
                  : `${option.equipmentIdentifier}`
                }
                disabled={muteLoad || loading}
                getOptionValue={option => option.equipmentIdentifier}
                value={equipments && selectedEquipment && equipments.filter(e => e.equipmentIdentifier === selectedEquipment.equipmentIdentifier).equipmentIdentifier}
                onChange={e => selectEquipment(e)} />
            </FormGroup>

            <FormGroup className="ml-2" tag="fieldset">
              <TLabel id="comment.label" for="comment" />
              <TInput name="comment" id="comment" placeholder="comment.placeholder" className="p-2" value={comments} onChange={e => setComments(e.target.value)} />
            </FormGroup>

            <FormGroup tag="fieldset">
              <Label>&nbsp;</Label>
              <Button className="pt-2 pb-2 ml-2 d-block"
                type="button"
                disabled={muteLoad}
                onClick={() => mute()}>
                {(muteLoad || loading) && <Spinner size="sm" className="mr-2" color="primary" />}
                <T id="mute" />
              </Button>
            </FormGroup>
          </div>

          {success && <TAlert id="success"></TAlert>}
          {muteError && <ErrAlert error={muteError}></ErrAlert>}

          <p className="mb-0"><T id="disclaimer" /></p>
        </Form>
      </CardBody>
    </Card>

    <Table
      isLoading={listLoad}
      disabled={listLoad}
      data={list}
      refresh={listRefresh}
      columns={[
        {
          title: 'dateCreated',
          hasOrder: true,
          render: DateCreated
        },
        {
          title: 'ignoredElement',
          render: IgnoredElement
        },
        {
          title: 'statusValue',
          render: item => formatMesage({ id: `equipmentStatus.${item.statusValue}`, raw: true })
        },
        {
          title: 'interventionDate',
          render: InterventionDate
        },
        {
          title: 'comments',
          render: item => item.comments
        },
        {
          title: 'fullName',
          render: item => item.fullName
        },
        {
          className: () => 'align-middle',
          render: UnMuteButton
        },
      ]}
    />
  </>)
}

export default Mute
