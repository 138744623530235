import React from 'react'

import {
  Alert, Spinner, Button,
  Form, FormGroup, Label, Input,
  Card, CardHeader, CardBody,
  ListGroup, ListGroupItem,
} from 'reactstrap'
import CustomSelect from 'components/CustomSelect'
import { useHasRights } from 'components/ProtectedComponent'

import { T } from 'components/TComponents'

import { AppContext } from 'contexts/AppContext'


const ItvcodeSelector = ({
  hasBusiness,
  loading,
  itvcodes,
  itvcodesAdded,
  onChange,
  editable = true,
  noQuantity = false,
  noWear = false,
  exclude = false,
  withHeaders = false,
  withFamilies = false,
  reason = false,
  finishedReason = false,
  clearable = false,
  onlyOne = false,
  isSwap = false,
  isIntervention = false,
  qualif = false
}) => {
  const [inputValue, setInputValue] = React.useState()
  const [itvcodeError, setItvcodeError] = React.useState()
  const { constants } = React.useContext(AppContext)
  const canEditFinishedReasonRightItv = useHasRights('interventions-end_web')
  const canEditFinishedReasonRightSwap = useHasRights('swaps-end_web')
  const canCloseRight = useHasRights('interventions_close')

  const itvcodesFormatted = React.useMemo(() => {
    return withHeaders ? itvcodes : itvcodes.map(itvcode => ({
      label: `${itvcode.itvcodeIdentifier} - ${itvcode.itvcodeLabel}`,
      value: itvcode
    }))
  }, [itvcodes, withHeaders])

  const formatOptionLabel = React.useCallback(option => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }} className="ml-auto">
        <div style={{
          fontWeight: itvcodesAdded.find(_i => _i.itvcodeId === option.value.itvcodeId) ? "bold" : "normal",
        }}>
          {option.label}
        </div>
        <div>
          {itvcodesAdded.find(_i => _i.itvcodeId === option.value.itvcodeId) && !noQuantity && 'x' + itvcodesAdded.find(_i => _i.itvcodeId === option.value.itvcodeId).quantity}
        </div>
      </div>
    )
  }, [itvcodesAdded, noQuantity])

  const handleChange = React.useCallback((itvcode) => {
    if (itvcodesAdded.filter(_i => _i.itvcodeId === itvcode.itvcodeId).length === 0) {
      addItvcode(itvcode)
    } else {
      itvcodesAdded.find(_i => _i.itvcodeId === itvcode.itvcodeId) && !noQuantity && addItvcodeQtt(itvcodesAdded.find(_i => _i.itvcodeId === itvcode.itvcodeId), 1)
    }
  }, [itvcodesAdded, addItvcode, addItvcodeQtt, noQuantity])

  const addItvcode = React.useCallback((itvcode) => {
    if (!itvcode) {
      return setItvcodeError('noItvcodeSelected')
    }
    const itvcodeAdded = itvcodesAdded.find(_itvcode => _itvcode.itvcodeId === itvcode.itvcodeId)
    let itvcodesAddedCopy = JSON.parse(JSON.stringify(itvcodesAdded))

    let itvcodesToAdd = []
    if (itvcode.itvcodeIds && itvcode.itvcodeIds.length > 0) {
      itvcode.itvcodeIds.map(linkedCode => {
        if (itvcodesAdded.map(_i => {
          if (_i.itvcodeId === linkedCode.itvcodeId) {
            return true
          }
          return false
        }).includes(true)) {
          itvcodesAddedCopy = itvcodesAddedCopy.map(_i =>
            _i.itvcodeId === linkedCode.itvcodeId ? { ..._i, quantity: _i.quantity + 1 } : _i)
        } else {
          itvcodesToAdd.push({
            ...linkedCode,
            quantity: 1,
            wear: false
          })
        }
      })
    } else {
      itvcodesToAdd = [{
        ...itvcode,
        quantity: 1,
        wear: false
      }]
    }
    if (!itvcodeAdded) {
      setItvcodeError(undefined)
      onChange && onChange(itvcodesAddedCopy.concat(itvcodesToAdd))
    } else {
      setItvcodeError('notSameItvcode')
    }
  }, [itvcodesAdded, onChange,])

  const handleItvcodeChange = React.useCallback((e, itvcode) => {
    const { target: { name, value, checked } } = e
    const _value = checked === undefined ? value : checked

    onChange && onChange(itvcodesAdded.map(_i =>
      _i.itvcodeId === itvcode.itvcodeId ? { ...itvcode, [name]: _value } : _i))
  }, [itvcodesAdded, onChange,])

  const addItvcodeQtt = React.useCallback((itvcode, qtt) => {
    onChange && onChange(itvcodesAdded.map(_i =>
      _i.itvcodeId === itvcode.itvcodeId ? { ...itvcode, quantity: itvcode.quantity + qtt } : _i))
  }, [itvcodesAdded, onChange])

  const removeItvcode = React.useCallback((itvcode) => {
    setItvcodeError(undefined)
    let itvcodesAddedCopy = JSON.parse(JSON.stringify(itvcodesAdded))
    const currentItvcode = itvcodes.find(i => i.itvcodeId === itvcode.itvcodeId)
    if (currentItvcode && currentItvcode.itvcodeIds && currentItvcode.itvcodeIds.length > 0 && currentItvcode.locked) {
      currentItvcode.itvcodeIds.map(linkedCode => itvcodesAddedCopy = itvcodesAddedCopy.filter(_i => linkedCode.itvcodeId !== _i.itvcodeId))
    }
    onChange && onChange(itvcodesAddedCopy.filter(itvcodeAdded => itvcode.itvcodeId !== itvcodeAdded.itvcodeId || itvcode.wear !== itvcodeAdded.wear))
  }, [itvcodesAdded, onChange, itvcodes])

  const handleInputValue = React.useCallback((input, reason) => {
    if (reason.action === 'input-change') {
      setInputValue(input)
    }
  }, [])

  const hasCodeReasonEnable = React.useCallback(itvcode => {
    let hasReasonEnable = false
    if (qualif && itvcode.enabledQualifReasonPart && itvcode.enabledQualifReasonPart.includes(qualif) && isIntervention) {
      hasReasonEnable = true
    }
    if (itvcode.enableReasonPartSwaps && isSwap) {
      hasReasonEnable = true
    }
    return hasReasonEnable
  }, [isIntervention, isSwap, qualif])

  const handleReasonChange = React.useCallback((e, itvcode) => {
    onChange && onChange(itvcodesAdded.map(_i =>
      _i.itvcodeId === itvcode.itvcodeId ? { ...itvcode, 'reasonId': e.value } : _i))
  }, [itvcodesAdded, onChange])

  const removeAllCodes = React.useCallback(() => {
    onChange && onChange([])
  }, [onChange])

  return (
    <Card className="mb-3">
      {clearable && editable && <div className="d-flex align-items-start">
        <Button className="ml-2 pt-2 pb-2 mt-2"
          disabled={loading}
          onClick={() => removeAllCodes()}>
          <T id="ItvcodeSelector.removeAllCodes" raw />
        </Button>
      </div>}
      {editable && <CardHeader className="border-bottom-0">
        {!hasBusiness && <T tagName={Alert} id="noBusinessSelected" color="warning" />}
        {hasBusiness && loading && <Spinner className="" color="primary" />}
        {hasBusiness && !loading && <Form inline>
          <FormGroup tag="fieldset" className="w-100">
            <Label for="itvcodeSelected" className="ml-1 mr-1"><T id="itvcodeLabel" /></Label>
            <CustomSelect
              id="itvcodeSelected"
              className="flex-grow-1"
              onChange={e => handleChange(e.value)}
              isSearchable={true}
              name="itvcodeSelected"
              options={itvcodesFormatted}
              formatOptionLabel={formatOptionLabel}
              inputValue={inputValue}
              onInputChange={handleInputValue}
              value={null}
              closeMenuOnSelect={onlyOne}
              isDisabled={!!(onlyOne && itvcodesAdded.length >= 1)}
              placeholder={'...'}
              noOptionsMessage={() => <T id="itvcodes.noResult" />} />
          </FormGroup>
        </Form>}
        {itvcodeError && <Alert className="mt-2 mb-0" color="danger"><T id={`error.${itvcodeError}`} raw /></Alert>}
        {(!itvcodesAdded || itvcodesAdded.length === 0) && !exclude &&
          <T tagName={Alert} id="noItvcodeListAdded" className="mt-2 mb-0" color="warning" />}
      </CardHeader>}

      {!loading && itvcodesAdded && itvcodesAdded.length > 0 && <CardBody className="p-0">
        <ListGroup>{itvcodesAdded.map((itvcode, index) =>
          <ListGroupItem key={index}
            className="d-flex align-items-center">
            <div className="flex-grow-1 mr-3">
              {withFamilies
                ? `${itvcode.itvcodeIdentifier} - ${itvcode.itvcodeLabel}  - (${itvcode.categoryName} - ${itvcode.familyName})`
                : `${itvcode.itvcodeIdentifier} - ${itvcode.itvcodeLabel}`}
              {!noWear &&
                <>
                  <FormGroup className="ml-1 d-flex align-items-center" check>
                    {(!reason || !hasCodeReasonEnable(itvcode)) && (
                      <>
                        <Input id={itvcode.itvcodeIdentifier}
                          name="wear"
                          className="mt-0"
                          type="checkbox"
                          disabled={loading || !editable || (finishedReason && hasCodeReasonEnable(itvcode) && !canCloseRight)}
                          checked={itvcode.wear}
                          onChange={e => handleItvcodeChange(e, itvcode)} />
                        <Label for={itvcode.itvcodeIdentifier} className="mb-0"><T id="wearLabel" /></Label>
                      </>
                    )}
                  </FormGroup>
                  {((itvcode.reasonListItvDisplay === -1 || itvcode.reasonListItvDisplay === 1) && itvcode.enabledQualifReasonPart && itvcode.enabledQualifReasonPart.includes(qualif)) && isIntervention && (
                    <>
                      {reason ? (
                        <>
                          <CustomSelect
                            options={constants.reasonPart.filter(r => r.value !== 7 && r.value !== 8)}
                            id="reasonPart"
                            onChange={e => handleReasonChange(e, itvcode)}
                            className="mt-1"
                            name="reasonPart"
                            value={constants.reasonPart.filter(r => r.value === itvcode.reasonId)}
                            getOptionLabel={option => <T id={`reasonParts.${option.key}`} raw />}
                            placeholder={<T id="reasonParts.placeholder" raw />}
                            noOptionsMessage={() => <T id="itvcodes.noResult" />} />
                        </>
                      ) : (
                        <>
                          {(finishedReason || !!canCloseRight) && (
                            <>
                              <CustomSelect
                                options={constants.reasonPart.filter(r => r.value !== 7 && r.value !== 8)}
                                id="reasonPart"
                                onChange={e => handleReasonChange(e, itvcode)}
                                className="mt-1"
                                name="reasonPart"
                                isDisabled={!canEditFinishedReasonRightItv || !editable}
                                value={constants.reasonPart.filter(r => r.value === itvcode.reasonId)}
                                getOptionLabel={option => <T id={`reasonParts.${option.key}`} raw />}
                                placeholder={<T id="reasonParts.placeholder" raw />}
                                noOptionsMessage={() => <T id="itvcodes.noResult" />} />
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {(itvcode.reasonListSwapDisplay === 1 && isSwap) && (
                    <>
                      {reason ? (
                        <CustomSelect
                          options={constants.reasonPart.filter(r => r.value !== 7 && r.value !== 8)}
                          id="reasonPart"
                          onChange={e => handleReasonChange(e, itvcode)}
                          className="mt-1"
                          name="reasonPart"
                          value={constants.reasonPart.filter(r => r.value === itvcode.reasonId)}
                          getOptionLabel={option => <T id={`reasonParts.${option.key}`} raw />}
                          placeholder={<T id="reasonParts.placeholder" raw />}
                          noOptionsMessage={() => <T id="itvcodes.noResult" />} />
                      ) : (
                        <>
                          {(finishedReason || !!canCloseRight) && (
                            <>
                              <CustomSelect
                                options={constants.reasonPart.filter(r => r.value !== 7 && r.value !== 8)}
                                id="reasonPart"
                                onChange={e => handleReasonChange(e, itvcode)}
                                className="mt-1"
                                name="reasonPart"
                                isDisabled={!canEditFinishedReasonRightSwap || !editable}
                                value={constants.reasonPart.filter(r => r.value === itvcode.reasonId)}
                                getOptionLabel={option => <T id={`reasonParts.${option.key}`} raw />}
                                placeholder={<T id="reasonParts.placeholder" raw />}
                                noOptionsMessage={() => <T id="itvcodes.noResult" />} />
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              }
            </div>

            {!noQuantity && (
              <>
                <span className="ml-auto"><T id="quantityLabel" /></span>
                {editable && <Button className="ml-1 px-2 py-0 mt-0 btn-quantity"
                  disabled={itvcode.quantity <= 1 || loading}
                  onClick={() => addItvcodeQtt(itvcode, -1)}>
                  <span>-</span>
                </Button>}
                <span className="ml-1">{itvcode.quantity}</span>
                {editable && <Button className="ml-1 px-2 py-0 mt-0 btn-quantity"
                  disabled={loading}
                  onClick={() => addItvcodeQtt(itvcode, 1)}>
                  <span>+</span>
                </Button>}
              </>
            )}
            {editable && <Button className="ml-2 pt-2 pb-2 mt-0"
              disabled={loading}
              onClick={() => removeItvcode(itvcode)}>
              <T id="removeItvcode" />
            </Button>}
          </ListGroupItem>
        )}
        </ListGroup>
      </CardBody>}
    </Card >
  )
}

export default ItvcodeSelector
