import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import CustomSelect from 'components/CustomSelect'
import {
  Card, CardHeader, CardFooter, Col, Row, CustomInput, CardBody, FormGroup, Input
} from 'reactstrap'

import { useFormatMessage } from 'hooks/intl.hooks'

import { AppContext } from 'contexts/AppContext'

import { T, TAlert, TLabel, TNavLink, TButton, Toption, TDatePicker } from 'components/TComponents'

const EquipmentAdd = () => {
  const formatMessage = useFormatMessage()
  const history = useHistory()

  const { api } = React.useContext(AppContext)

  const [business, setBusiness] = React.useState()
  const [categories, setCategories] = React.useState()
  const [families, setFamilies] = React.useState()

  const [equipmentFields, setEquipmentFields] = React.useState()
  const [matrixFields, setMatrixFields] = React.useState()

  const [equipmentData, setEquipmentData] = React.useState({})
  const [matrixData, setMatrixData] = React.useState({})

  const [statusId, setStatusId] = React.useState()
  const [childStatusId, setChildStatusId] = React.useState()

  const [mandatoryFields, setMandatoryFields] = React.useState([])

  const [matrixError, setMatrixError] = React.useState()
  const [mandatoryError, setMandatoryError] = React.useState(false)
  const [equipmentPostError, setEquipmentPostError] = React.useState()


  React.useEffect(() => {
    api.get('/equipments/fieldsByFilters')
      .then(data => {
        setFilters(data)
      })
      .catch(error => {
        setMatrixError(error)
        setEquipmentFields()
        setMatrixFields()
      })
  }, [api, setFilters])

  React.useEffect(() => {
    if ((business && business.selected) && (families && families.selected) && (categories && categories.selected)) {
      api.get('/equipments/fieldsByFilters', undefined, {
        businessId: business && business.selected && business.selected.businessId,
        familyId: families && families.selected && families.selected.familyId,
        categoryId: categories && categories.selected && categories.selected.categoryId
      })
        .then(data => {
          setMatrixError()
          setEquipmentFields(data.equipmentFields)
          setMatrixFields(data.matrixFields)
        })
        .catch(error => setMatrixError(error))
    }
  }, [api, business, categories, families])

  React.useEffect(() => {
    if (business && business.selected) {
      api.get('/equipments/fieldsByFilters', undefined, {
        businessId: business.selected.businessId
      })
        .then(data => {
          setCategories(data.categories)
          setFamilies(data.families)
          setMatrixError()
        })
        .catch(error => setMatrixError(error))
    }
  }, [api, business])

  React.useEffect(() => {
    if (business && business.selected && categories && categories.selected) {
      api.get('/equipments/fieldsByFilters', undefined, {
        businessId: business.selected.businessId,
        categoryId: categories.selected.categoryId
      })
        .then(data => {
          setFamilies(data.families)
          setMatrixError()
        })
        .catch(error => setMatrixError(error))
    }
  }, [api, business, categories])

  React.useEffect(() => {
    if ((equipmentFields && equipmentFields.length > 0) || (matrixFields && matrixFields.length > 0)) {
      const newMandatoryFields = []
      if (equipmentFields && equipmentFields.length > 0) {
        equipmentFields.map(field => {
          !field.nullable && newMandatoryFields.push(field.name)
        })
      }
      if (matrixFields && matrixFields.length > 0) {
        matrixFields.map(field => {
          !field.nullable && newMandatoryFields.push(field.name)
        })
      }
      setMandatoryFields(newMandatoryFields)
    }
  }, [equipmentFields, matrixFields])

  const setFilters = React.useCallback(data => {
    setBusiness(data.business)
    setCategories(data.categories)
    setFamilies(data.families)
  }, [])

  const handleInput = React.useCallback((name, value, type) => {
    if (name === 'statusId') {
      setStatusId(value.split('.')[0])
      setChildStatusId(value.split('.')[1] ? value.split('.')[1] : '')
    }
    type === 'equipment' && setEquipmentData({ ...equipmentData, [name]: value })
    type === 'matrix' && setMatrixData({ ...matrixData, [name]: value instanceof Date ? `${value.getFullYear()}-${String(value.getMonth() + 1).padStart(2, '0')}-${String(value.getDate()).padStart(2, '0')}T00:00:00.000Z` : value })
  }, [equipmentData, matrixData])

  const handleSaveEquipment = React.useCallback(() => {
    if (!hasMandatoryError()) {
      setMandatoryError(false)
      const body = {}
      body.businessId = business.selected.businessId
      body.categoryId = categories.selected.categoryId
      body.familyId = families.selected.familyId
      body.equipmentFields = equipmentData
      body.equipmentFields.statusId = statusId
      body.equipmentFields.childStatusId = childStatusId
      body.matrixFields = matrixData

      api.post('/equipments/details', { body: JSON.stringify(body) })
        .then(() => {
          setEquipmentPostError()
          history.push(`/equipments`)
        })
        .catch(error => {
          setEquipmentPostError(error.code)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mandatoryFields, business, categories, families, statusId, childStatusId, equipmentData, matrixData, hasMandatoryError])

  const hasMandatoryError = React.useCallback(() => {
    let error = false
    if (mandatoryFields && mandatoryFields.length > 0) {
      mandatoryFields.map(field => {
        if (!equipmentData[field] && !matrixData[field]) {
          setMandatoryError(true)
          error = true
        }
      })
    }
    return error
  }, [mandatoryFields, equipmentData, matrixData])

  const renderField = React.useCallback((data, type) => {
    switch (data.type) {
    case 'varchar':
    case 'text':
      return (
        <Input
          type="text"
          name={data.name}
          maxLength={data.length && data.length}
          onChange={e => handleInput(data.name, e.target.value, type)}
        />
      )
    case 'int':
    case 'tinyint':
      return (
        data.values && data.values.length > 0 ? (
          <CustomInput
            type="select"
            name={data.name}
            value={equipmentData[data.name] || matrixData[data.name]}
            onChange={e => handleInput(data.name, e.target.value, type)} >
            <option value={undefined} />
            {data.values.map(value =>
              value.children && value.children.length > 0 ? (
                <optgroup key={value.key} label={formatMessage({ id: `equipmentStatus.${value.key}`, raw: true })}>
                  {value.children.map(child => (
                    <Toption key={`${value.key}.${child.value}`}
                      raw id={`equipmentStatus.${value.key}.${child.key}`}
                      value={`${value.value}.${child.value}`} />
                  ))}
                </optgroup>
              ) : (
                <Toption key={value.value}
                  raw id={`equipmentStatus.${value.key}`}
                  value={`${value.value}`} />
              )
            )}
          </CustomInput>
        ) : (
          <Input
            type="number"
            name={data.name}
            maxLength={data.length && data.length}
            onChange={e => handleInput(data.name, e.target.value, type)}
          />
        )
      )
    case 'date':
      return (
        <TDatePicker
          wrapperClassName="w-100"
          selected={matrixData[data.name]}
          onChange={date => handleInput(data.name, date, type)}
          dateFormat="dd/MM/yyyy"
          dayHeaderFormat="wide"
        />
      )
    }
  }, [matrixData, formatMessage, handleInput, equipmentData])

  const renderFieldWithLabel = React.useCallback((data, type) => {
    return (
      <>
        {data.editable && (
          <FormGroup tag="fieldset">
            <TLabel id={`${data.name}.label`} for={data.name} />
            {!data.nullable && <span>{' * '}</span>}
            {renderField(data, type)}
          </FormGroup>
        )}
      </>
    )
  }, [renderField])

  return (
    <div className="container-fluid">
      <TNavLink id="returnToList" tag={Link} to="/equipments" />
      <Card className="mb-2">
        <CardHeader>
          <T id="choseFiltersDescription" className='mb-4 d-block' />
          <Row form>
            <Col sm="6" md="3" >
              <FormGroup tag="fieldset">
                <TLabel id="businessId.label" for="businessId" />
                <CustomSelect
                  id="businessId"
                  value={business && business.selected}
                  options={(business && business.values) || []}
                  isClearable
                  onChange={value => {
                    setBusiness({ ...business, selected: value })
                    setCategories({ ...categories, selected: null })
                    setFamilies({ ...families, selected: null })
                  }}
                  placeholder={<T id="businessId.placeholder" />}
                  noOptionsMessage={() => <T id="businessId.noResult" />}
                  getOptionLabel={option => option.businessName}
                  getOptionValue={option => option.businessId} />
              </FormGroup>
            </Col>
            <Col sm="6" md="3" >
              <FormGroup tag="fieldset">
                <TLabel id="categoryId.label" for="categoryId" />
                <CustomSelect
                  id="categoryId"
                  value={categories && categories.selected}
                  options={(categories && categories.values) || []}
                  isClearable
                  onChange={value => {
                    setCategories({ ...categories, selected: value })
                    setFamilies({ ...families, selected: null })
                  }}
                  placeholder={<T id="categoryId.placeholder" />}
                  noOptionsMessage={() => <T id="noResult" raw />}
                  getOptionLabel={option => option.categoryName}
                  getOptionValue={option => option.categoryId} />
              </FormGroup>
            </Col>
            <Col sm="6" md="3" >
              <FormGroup tag="fieldset">
                <TLabel id="familyId.label" for="familyId" />
                <CustomSelect
                  id="familyId"
                  value={families && families.selected}
                  options={(families && families.values) || []}
                  isClearable
                  onChange={value => setFamilies({ ...families, selected: value })}
                  placeholder={<T id="familyId.placeholder" />}
                  noOptionsMessage={() => <T id="noResult" raw />}
                  getOptionLabel={option => option.familyName}
                  getOptionValue={option => option.familyId} />
              </FormGroup>
            </Col>
          </Row>
        </CardHeader>
        {(matrixError || (matrixFields && matrixFields.length > 0) || (equipmentFields && equipmentFields.length > 0)) && (
          <>
            <CardBody>
              <>
                {matrixError ? (
                  <TAlert id="notMatrix" className="mb-0" color="danger" />
                ) : (
                  <>
                    <T id="mandatoryFieldsDescription" className="mb-4 d-block" />
                    {equipmentFields && equipmentFields.map(field => renderFieldWithLabel(field, 'equipment'))}
                    {matrixFields && matrixFields.map(field => renderFieldWithLabel(field, 'matrix'))}
                  </>
                )}
              </>
            </CardBody>
            {((matrixFields && matrixFields.length > 0) || (equipmentFields && equipmentFields.length > 0)) && !matrixError && (
              <CardFooter>
                <>
                  {mandatoryError && (
                    <TAlert id="mandatoryFieldMissing" className="mb-4" color="danger" />
                  )}
                  {equipmentPostError && (
                    <TAlert id={`error.${equipmentPostError}`} className="mb-4" color="danger" />
                  )}
                  <Row className="d-flex justify-content-end">
                    <TButton id="saveEquipment" onClick={handleSaveEquipment} />
                  </Row>
                </>
              </CardFooter>
            )}
          </>
        )}
      </Card>
    </div>
  )
}

export default EquipmentAdd