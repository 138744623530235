import React from 'react'

import {
  Table as RNTable,
  Spinner
} from 'reactstrap'
import TableInfos from 'components/TableInfos'
import Pagination from 'components/Pagination'
import TH from 'components/TH'

import { TAlert } from 'components/TComponents'

export const Table = ({
  isLoading,
  data, columns,
  refresh,
  customPagination = false,
  pagination = true,
  count = true,
}) => {

  const handleIpp = React.useCallback(ipp => {
    if (customPagination === 'zonning') {
      refresh({ pZonning: 1, ippZonning: ipp })
    } else if (customPagination === 'techcenter') {
      refresh({ pTechcenter: 1, ippTechcenter: ipp })
    } else {
      refresh({ p: 1, ipp })
    }
  }, [refresh, customPagination])

  const handlePageClick = React.useCallback(p => {
    if (customPagination === 'zonning') {
      refresh({ pZonning: p })
    } else if (customPagination === 'techcenter') {
      refresh({ pTechcenter: p })
    } else {
      refresh({ p })
    }
  }, [refresh, customPagination])

  const order = React.useCallback((e, column) => {
    e.preventDefault()
    refresh({
      'order[column]': column,
      'order[dir]': data.order.column === column && data.order.dir === 'asc' ? 'desc' : 'asc',
      ...(!customPagination && { p: data.currentPage }),
      ...(customPagination && customPagination === 'zonning' && { pZonning: data.currentPage }),
      ...(customPagination && customPagination === 'techcenter' && { pTechcenter: data.currentPage })
    })
  }, [data, refresh, customPagination])

  if (isLoading) {
    return <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />
  }

  if (!data.result || data.result.length == 0) {
    return (
      <TAlert id="noResult" color="warning" />
    )
  }

  return (
    <RNTable responsive striped hover className="mb-3 mt-2">
      <thead>
        <tr>
          <TH colSpan={columns.length} className={['border-top-0']}>
            <TableInfos
              pagination={pagination}
              count={count}
              data={data}
              onChange={handleIpp} />
          </TH>
        </tr>
      </thead>
      <thead>
        <tr>
          {columns.map(({ title, hasOrder }, index) => (
            <TH
              key={index.toString()}
              colName={title}
              order={hasOrder ? data.order : undefined}
              sort={hasOrder ? order : undefined}
              className={['label']}
            />))}
        </tr>
      </thead>
      <tbody>
        {data.result.map((item, itemIndex) =>
          <tr key={itemIndex.toString()}
            style={{ transform: 'rotate(0)', lineHeight: '1.49' }}>
            {columns.map(({ className, render }, columnIndex) => (
              <td
                key={columnIndex}
                className={className ? className(item) : ''}>
                {render && render(item)}
              </td>
            ))}
          </tr>
        )}
      </tbody>
      {pagination && (
        <tfoot>
          <tr><td colSpan={columns.length}>
            <Pagination totalPage={data.totalPage}
              currentPage={data.currentPage}
              onPageClick={handlePageClick} /></td></tr>
        </tfoot>
      )}
    </RNTable>
  )
}

export default Table
