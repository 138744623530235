import React from 'react'
import { NavLink } from 'react-router-dom'
import CustomSelect from 'components/CustomSelect'

import { AppContext } from 'contexts/AppContext'

import {
  FormGroup, Input,
  ListGroup, ListGroupItem,
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap'

import { T, TButton, TLabel, ErrAlert, TDatePicker } from 'components/TComponents'
import SlotSelector from 'components/SlotSelector'

const itemReducer = (state, action) => {
  switch (action.type) {
  case 'change': return Object.assign({}, state, action.data)
  case 'init': return itemInitializer(action.item)
  default: return state
  }
}

const itemInitializer = (initialState = {}) => {
  switch (initialState.type) {
  case 'intervention': return {
    id: initialState.intervention.interventionId,
    userId: initialState.intervention.userId,
    date: new Date(initialState.intervention.datePlanned),
    duration: initialState.intervention.durationPlanned,
    toItem: `/interventions/${initialState.intervention.interventionId}`
  }
  case 'swap': return {
    id: initialState.swap.swapId,
    userId: initialState.swap.userId,
    date: new Date(initialState.swap.datePlanned),
    duration: initialState.swap.durationPlanned,
    toItem: `/swaps/${initialState.swap.swapId}`
  }
  case 'appointment': return {
    id: initialState.appointment.appointmentId,
    userId: initialState.appointment.userId,
    date: new Date(initialState.appointment.dateAppointment),
    duration: initialState.appointment.durationPlanned,
    toItem: `/appointments/${initialState.appointment.appointmentId}`
  }
  case 'maintenance': return {
    id: initialState.maintenance.maintenanceplanId,
    userId: initialState.maintenance.userId,
    date: new Date(initialState.maintenance.datePlanned),
    duration: initialState.maintenance.durationPlanned,
    toItem: `/maintenance/${initialState.maintenance.maintenanceplanId}`
  }
  default: return undefined
  }
}

const PlanningItemModal = ({ item, users, onSubmit, onDismiss, onRemind }) => {
  const [submitError, setSubmitError] = React.useState()
  const [itemState, itemDispatch] = React.useReducer(itemReducer, item, itemInitializer)
  const { constants } = React.useContext(AppContext)

  const [loading, setLoading] = React.useState(false)
  const disabled = React.useMemo(() =>
    loading || !item || item.remind
  , [item, loading])

  React.useEffect(() => itemDispatch({ type: 'init', item }), [item])

  const handleSave = React.useCallback(() => {
    setLoading(true)
    setSubmitError()
    onSubmit({ ...itemState, force: submitError && submitError.code === 'preventUpdate' ? submitError.params.reasons : undefined })
      .catch(error => setSubmitError(error))
      .then(() => setLoading(false))
  }, [onSubmit, itemState, submitError])

  const handleDismiss = React.useCallback(() => {
    setSubmitError()
    onDismiss()
  }, [onDismiss])

  const handleRemind = React.useCallback(() => {
    setLoading(true)
    onRemind()
      .catch(error => setSubmitError(error))
      .then(() => setLoading(false))
  }, [onRemind])

  const itvStatusConst = React.useMemo(() => constants.interventionStatus.reduce((acc, status) => ({ ...acc, [status.key]: status.value }), {}), [constants])
  const swapStatusConst = React.useMemo(() => constants.swapStatus.reduce((acc, status) => ({ ...acc, [status.key]: status.value }), {}), [constants])
  const maintenanceStatusConst = React.useMemo(() => constants.maintenanceplansStatus.reduce((acc, status) => ({ ...acc, [status.key]: status.value }), {}), [constants])

  if (!item || !itemState) {
    return <></>
  }

  let canEdit = true
  if (item && item.intervention && ([itvStatusConst.anomaly, itvStatusConst.done, itvStatusConst.closed].includes(item.intervention.statusId))) {
    canEdit = false
  }
  if (item && item.swap && ([swapStatusConst.anomaly, swapStatusConst.done].includes(item.swap.statusId))) {
    canEdit = false
  }
  if (item && item.maintenance && ([maintenanceStatusConst.canceled, maintenanceStatusConst.done].includes(item.maintenance.statusId))) {
    canEdit = false
  }

  return (
    <Modal isOpen={true}
      toggle={handleDismiss}
      size="lg">
      {canEdit ? (
        <T tagName={ModalHeader} id="itemEdit.header"
          values={{ type: item.type, id: itemState.id }} />
      ) : (
        <T tagName={ModalHeader} id="item.header"
          values={{ type: item.type, id: itemState.id }} />

      )}
      {canEdit && (<ModalBody>
        <FormGroup tag="fieldset">
          <TLabel id="itemEdit.userId"
            for="userSelector" />
          <CustomSelect
            id="userSelector"
            value={users.find(u => u.userId === itemState.userId)}
            options={users}
            isDisabled={disabled}
            onChange={u => itemDispatch({ type: 'change', data: { userId: u.userId } })}
            getOptionLabel={option => option.firstName + ' ' + option.lastName}
            getOptionValue={option => option.userId} />
        </FormGroup>
        <FormGroup tag="fieldset">
          <TLabel id="itemEdit.duration"
            values={{
              hours: Math.floor(itemState.duration / 60),
              minutes: (itemState.duration % 60 < 10 ? '0' : '') + itemState.duration % 60
            }} />
          <Input type="range" min="5" max="500" step="5"
            value={itemState.duration}
            disabled={disabled}
            onChange={({ target: { value: duration } }) => itemDispatch({ type: 'change', data: { duration } })} />
        </FormGroup>
        <FormGroup className="modal-datePickerFormGroup mb-0">
          <TLabel id="itemEdit.date" />
          <TDatePicker
            disabled={disabled} // TODO: seems not working
            inline
            customInput={<SlotSelector />}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={5}
            selected={itemState.date || null}
            minTime={new Date(new Date().setHours(6, 0, 0, 0))}
            maxTime={new Date(new Date().setHours(20, 0, 0, 0))}
            onChange={date => itemDispatch({ type: 'change', data: { date } })}
            // TODO: all DatePicker dateFormat shoudl be in translation. Maybe event a custom DatPicker component for that
            dateFormat="Pp"
            minDate={new Date()}
          />
        </FormGroup>
      </ModalBody>)}
      {submitError && <ModalBody className="p-0">
        <ErrAlert className="m-0 border-0" error={submitError} raw={submitError.code !== 'preventUpdate'} fade={true} toggle={() => setSubmitError()} />
        {submitError.params && submitError.params.reasons && <ListGroup>
          {submitError.params.reasons.map(reason => (
            <ListGroupItem key={reason}><T id={`error.preventUpdate.${reason}`} /></ListGroupItem>
          ))}
        </ListGroup>}
      </ModalBody>}
      <ModalFooter className="py-3">
        {itemState.toItem && <TButton id="itemEdit.toItem" values={{ type: item.type }} color="primary"
          tag={NavLink}
          to={itemState.toItem}
          className="mr-auto" />}
        <TButton id="itemEdit.cancel" onClick={onDismiss} disabled={loading} />
        {canEdit && (
          <>
            {item.remind
              ? <TButton id={`itemEdit.remind`} color="primary" onClick={handleRemind} disabled={loading} loading={loading} />
              : <TButton id={`itemEdit.${submitError && submitError.code === 'preventUpdate' ? 'force' : 'save'}`} color="primary" onClick={handleSave} disabled={loading} loading={loading} />
            }
          </>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default PlanningItemModal