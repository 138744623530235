import React from 'react'
import {
  Card, CardBody, CardFooter,
  Modal, ModalBody, ModalFooter,
  FormGroup, Input
} from 'reactstrap'
import { Link } from 'react-router-dom'

import { AppContext } from 'contexts/AppContext'

import ProtectedComponent from 'components/ProtectedComponent'
import { T, TAlert, TLabel, TButton, ErrAlert, TNavLink } from 'components/TComponents'
import CustomSelect from 'components/CustomSelect'

const VehiclesEdit = ({ match }) => {

  const { api, constants } = React.useContext(AppContext)

  const [loading, setLoading] = React.useState(true)
  const [loadError, setLoadError] = React.useState(false)
  const [vehicle, setVehicle] = React.useState()
  const [edit, setEdit] = React.useState(false)
  const [updating, setUpdating] = React.useState(false)
  const [updateError, setUpdateError] = React.useState()
  const [updateSuccess, setUpdateSuccess] = React.useState()
  const [modalOpen, setModalOpen] = React.useState(false)
  const [archiveError, setArchiveError] = React.useState()
  const [archive, setArchive] = React.useState(false)

  React.useEffect(() => {
    api.get('/vehicles/details', undefined, { vehicleId: match.params.id })
      .then(response => setVehicle(response))
      .catch(response => setLoadError(response))
      .then(() => setLoading(false))
  }, [api, match])

  const handleChangeVehicle = React.useCallback((value, target) => {
    setVehicle(vehicle => ({
      ...vehicle,
      [target]: value
    }))
  }, [])

  const handleSave = React.useCallback(() => {
    setUpdateSuccess()
    setUpdateError()
    setUpdating(true)
    api.post('/vehicles/details', {
      body: JSON.stringify({
        vehicleId: vehicle.vehicleId,
        vehicleIdentifier: vehicle.vehicleIdentifier,
        vehicleLabel: vehicle.vehicleLabel,
        typeId: vehicle.typeId,
      })
    })
      .then(() => {
        setEdit(false)
        setUpdateSuccess(true)
      })
      .catch(error => setUpdateError(error.code))
      .then(() => setUpdating(false))
  }, [api, vehicle])

  const handleCancel = React.useCallback(() => {
    setEdit(false)
    setUpdateSuccess(false)
    setUpdateError(false)
  }, [])

  const archiveVehicle = React.useCallback(() => {
    setArchive(true)
    if (!vehicle.archived) {
      api.del('/vehicles/details', undefined, { vehicleId: vehicle.vehicleId })
        .then(response => {
          setVehicle(response)
          setModalOpen(false)
        })
        .catch(response => setArchiveError(response))
        .then(() => setArchive(false))
    } else {
      api.del('/vehicles/details', undefined, { vehicleId: vehicle.vehicleId, archived: 0 })
        .then(response => {
          setVehicle(response)
          setModalOpen(false)
        })
        .catch(response => setArchiveError(response))
        .then(() => setArchive(false))

    }
  }, [api, vehicle])

  return (
    <>
      <TNavLink id="returnToList" tag={Link} to="/admin/vehicles" />
      {!loading && !loadError && vehicle && (
        <>
          <Card>
            <CardBody>
              <FormGroup tag="fieldset">
                <TLabel for="vehicleIdentifier" id="vehicleIdentifier" />
                <Input id="vehicleIdentifier"
                  type="text"
                  name="vehicleIdentifier"
                  value={vehicle.vehicleIdentifier}
                  disabled={!edit}
                  onChange={e => { handleChangeVehicle(e.target.value, 'vehicleIdentifier') }}
                />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel for="vehicleLabel" id="vehicleLabel" />
                <Input id="vehicleLabel"
                  type="text"
                  name="vehicleLabel"
                  value={vehicle.vehicleLabel}
                  disabled={!edit}
                  onChange={e => { handleChangeVehicle(e.target.value, 'vehicleLabel') }}
                />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel for="typeId" id="typeId" />
                <CustomSelect
                  id="typeId"
                  options={constants.vehicleType}
                  value={constants.vehicleType.find(v => v.value === vehicle.typeId)}
                  onChange={e => { handleChangeVehicle(e.value, 'typeId') }}
                  isDisabled={!edit}
                  getOptionLabel={option => <T id={`VehiclesTypeList.${option.key}`} raw />} />
              </FormGroup>
            </CardBody>
            <CardFooter>
              <div className="mb-2">
                {updateError && <TAlert id={updateError} className="mb-0" color="danger" />}
                {updateSuccess && <TAlert id="updateSuccess" className="mb-0" color="success" />}
              </div>
              <ProtectedComponent rights={['admin-vehicle_edit']}>
                <div className="d-flex">
                  <TButton id={`${vehicle.archived ? 'unarchive' : 'archive'}`} disabled={updating} className="ml-2"
                    onClick={() => setModalOpen(true)} color="primary" />
                  <div className="ml-auto pl-2 d-flex">
                    {!edit && <TButton id="edit" onClick={() => setEdit(true)} />}
                    {edit && <TButton id="cancel" disabled={updating} className="ml-2" onClick={() => handleCancel()} color="warning" />}
                    {edit && <TButton id="save" loading={updating} disabled={updating} className="ml-2" onClick={() => handleSave()} color="success" />}
                  </div>
                </div>
              </ProtectedComponent>
            </CardFooter>
          </Card>
          <Modal isOpen={modalOpen} fade={false} toggle={() => setModalOpen(false)}>
            <ModalBody>
              <T id={`${vehicle.archived ? 'unarchive' : 'archive'}Content`} />
              {archiveError && <ErrAlert error={archiveError} className="mb-0 mt-2" />}
            </ModalBody>
            <ModalFooter>
              <TButton id="archiveCancel"
                disabled={archive}
                onClick={() => setModalOpen(false)} />
              <TButton id={`archiveConfirm`}
                loading={archive} className="ml-2" color="danger" onClick={() => archiveVehicle()} />
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  )
}

export default VehiclesEdit
