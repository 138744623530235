import React from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'

import {
  Spinner,
  Table,
} from 'reactstrap'

import { AppContext, } from 'contexts/AppContext'

import { T, ErrAlert/*, TButton, TLabel,*/ } from 'components/TComponents'
import Pagination from 'components/Pagination'
import TH from 'components/TH'

const EquipmentsHistory = () => {
  const { api, } = React.useContext(AppContext)

  const [statuss, setStatuss] = React.useState()
  const [error, setError] = React.useState()
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    getEquipmentsHistory()
      .then(stats => setStatuss(stats))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [getEquipmentsHistory])

  const getEquipmentsHistory = React.useCallback(param => {
    return api.get('/equipments/allhistory', undefined, {
      p: statuss ? statuss.currentPage : 1,
      ...param,
    })
  }, [api, statuss])

  const handlePageClick = React.useCallback(p => {
    setLoading(true)
    getEquipmentsHistory({ p })
      .then(stats => setStatuss(stats))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [getEquipmentsHistory])

  if (error) {
    return <ErrAlert error={error} className="mt-2 mb-0" />
  }

  if (loading || !statuss.result) {
    // TODO
    return <Spinner className="d-flex ml-auto mr-auto" color="primary" />
  }

  return (
    <Table responsive striped hover className="mb-3 mt-2">
      <thead>
        <tr><TH colSpan="100" className={['border-top-0']}
          colName="count"
          colValues={{ total: statuss.total }} />
        </tr>
      </thead>
      <thead>
        <tr>
          <TH colName="historyId" />
          <TH colName="historySrc" />
          <TH colName="equipmentIdentifier" />
          <TH colName="userName" />
          <TH colName="statusBefore" />
          <TH colName="statusAfter" />
          <TH colName="swapInfo" />
          <TH colName="dateCreated" />
        </tr>
      </thead>
      <tbody>
        {statuss.result.map(status =>
          <tr key={status.historyId}>
            <td>{status.historyId}</td>
            <td>{status.historySrc}</td>
            <td>{status.equipmentIdentifier}</td>
            <td>{status.userName ? status.userName : <T id="noUserPlanned" />}</td>
            <td><T raw id={`equipmentStatus.${status.statusBefore}`} /></td>
            <td><T raw id={`equipmentStatus.${status.statusAfter}`} /></td>
            <td>{status.swapInfo}</td>
            <td><FormattedDate value={new Date(status.dateCreated)} />{' '}<FormattedTime value={new Date(status.dateCreated)} /></td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr><td colSpan="100">
          <Pagination totalPage={statuss.totalPage}
            currentPage={statuss.currentPage}
            onPageClick={handlePageClick} /></td></tr>
      </tfoot>
    </Table>
  )
}

export default EquipmentsHistory
