import React from 'react'
import { Switch } from 'react-router-dom'

import Route from 'components/Route'

import List from 'routes/Admin/Categories/CategoryList'
import New from 'routes/Admin/Categories/CategoryNew'
import Edit from 'routes/Admin/Categories/CategoryEdit'
import FamilyNew from 'routes/Admin/Categories/FamilyNew'
import FamilyEdit from 'routes/Admin/Categories/FamilyEdit'

export default function Bases({ match }) {
  return <Switch>
    <Route exact path={`${match.path}`} component={List} rights={['admin-categories_edit', 'admin-categories_view',]} />
    <Route path={`${match.path}/families/new`} component={FamilyNew} rights={['admin-categories_edit']} />
    <Route path={`${match.path}/families/:id`} component={FamilyEdit} rights={['admin-categories_view', 'admin-categories_edit']} />
    <Route path={`${match.path}/new`} component={New} rights={['admin-categories_edit',]} />
    <Route path={`${match.path}/:id`} component={Edit} rights={['admin-categories_edit', 'admin-categories_view',]} />
  </Switch>
}
