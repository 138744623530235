import React from 'react'
import {
  Alert,
  Card, CardTitle,
  Form, FormGroup, Label, Input,
  Button,
} from 'reactstrap'
import { Redirect, useHistory } from 'react-router-dom'

import { AppContext } from 'contexts/AppContext'

import { T, } from 'components/TComponents'

const NewPassword = ({ location }) => {
  const history = useHistory()

  const [password, setPassword] = React.useState('')
  const [copypassword, setCopypassword] = React.useState('')
  const [submitting, setSubmitting] = React.useState(false)
  const [error, setError] = React.useState()

  const { api, user, login, logout, token, setUserToken, setUser } = React.useContext(AppContext)

  React.useEffect(() => {
    setError(false)
  }, [password, copypassword])

  React.useEffect(() => {
    const params = new URLSearchParams(location.search)
    const email = params.get('email')
    const code = params.get('code')
    if ((!user || user === undefined) && (!token || token === undefined) && (!email && !code)) {
      history.push('/login')
    }
  }, [user, token, history, location])

  const handleSubmit = React.useCallback((e) => {
    e.preventDefault()

    if (!password || password !== copypassword) {
      return
    }

    const params = new URLSearchParams(location.search)
    const code = params.get('code')

    const url = code ? 'reset' : 'password'

    const body = code ? { resetCode: code } : {}

    body.newPwd = password

    setSubmitting(true)
    setError()
    api.post(`/auth/${url}`, { body: JSON.stringify(body) })
      .then(response => login(response.accessToken))
      .catch(response => {
        setSubmitting(false)
        setError(response)
      })
  }, [api, copypassword, location.search, password, login])

  const handleBack = React.useCallback(() => {
    setUserToken()
    setUser()
  }, [setUserToken, setUser])

  const hasMinTenChars = password.length > 9
  const hasUppAndLowChars = password !== password.toUpperCase() && password !== password.toLowerCase()
  const hasNumber = /[0-9]/.test(password)

  if (user && !user.newPasswordRequired) {
    return <Redirect to="/" />
  }

  return (
    <Card className="auth-card">
      <div className="position-relative image-side" />
      <div className="form-side">
        <span className="logo-single" />
        <T tagName={CardTitle} id="form.title" className="mb-4" />
        <Form onSubmit={e => handleSubmit(e)}>
          <FormGroup className="form-group has-float-label mb-2">
            <Input id="password"
              type="password"
              name="password"
              value={password}
              autoComplete="new-password"
              disabled={submitting}
              onChange={e => setPassword(e.target.value)} />
            <T tagName={Label} id="form.password" for="password" />
          </FormGroup>

          <Label className="d-flex align-items-center">
            <T id="form.hasMinTenChars" />
            {hasMinTenChars
              ? <big className="ml-1 text-success simple-icon-check" />
              : <big className="ml-1 text-danger simple-icon-close" />}
          </Label>
          <Label className="d-flex align-items-center">
            <T id="form.hasUppAndLowChars" />
            {hasUppAndLowChars
              ? <big className="ml-1 text-success simple-icon-check" />
              : <big className="ml-1 text-warning simple-icon-exclamation" />}
          </Label>
          <Label className="d-flex align-items-center">
            <T id="form.hasNumber" />
            {hasNumber
              ? <big className="ml-1 text-success simple-icon-check" />
              : <big className="ml-1 text-warning simple-icon-exclamation" />}
          </Label>

          <FormGroup className="form-group has-float-label mb-4 mt-3">
            <Input id="copypassword"
              type="password"
              name="copypassword"
              autoComplete="new-password"
              value={copypassword}
              disabled={submitting}
              onChange={e => setCopypassword(e.target.value)} />
            <T tagName={Label} id="form.copypassword" for="copypassword" />
          </FormGroup>

          {error && <T tagName={Alert} id={`error.${error.code}`} values={error.params} color="danger" />}

          <div className="d-flex justify-content-end align-items-center">
            {user && <T id="form.signout"
              type="button"
              tagName={Button}
              color="warning"
              disabled={submitting}
              className="btn-shadow mr-4"
              onClick={() => logout()}
              size="lg" />}
            {!user && <T id="form.back"
              type="button"
              tagName={Button}
              color="warning"
              disabled={submitting}
              className="btn-shadow mr-4"
              onClick={handleBack}
              size="lg" />}
            <T id="form.submit"
              type="submit"
              tagName={Button}
              color="primary"
              disabled={submitting || password !== copypassword || !password}
              className="btn-shadow"
              size="lg" />
          </div>
        </Form>
      </div>
    </Card>
  )
}


export default NewPassword
