import React from 'react'
import { FormattedDate } from 'react-intl'
import { useLocation, Link, useHistory } from 'react-router-dom'
import { objectToQuery, queryToObject } from 'react-rest-api'

import {
  Card, CardBody,
  Table, Input,
  NavLink, Spinner, CardHeader
} from 'reactstrap'

import { useGet } from 'hooks/useApi.hooks'

import { formatDate } from 'util/Utils'

import { AppContext, } from 'contexts/AppContext'

import { Filters } from 'components/Filters'
import TH from 'components/TH'
import { T, TAlert, TButton, ErrAlert } from 'components/TComponents'

import '../Table.scss'

const rowReducer = (state, action) => {
  switch (action.type) {
  case 'setData': return { ...state, bounds: { ...state.bounds, ...action.bounds } }
  case 'load': return { loading: true, error: undefined, bounds: state.bounds }
  case 'loaded': return { loading: false, error: undefined, bounds: { warn: '', danger: '' } }
  case 'error': return { loading: false, error: action.payload, bounds: state.bounds }
  default: return state
  }
}

const rowInitialState = {
  loading: false,
  error: undefined,
  bounds: { warn: '', danger: '' }
}

const RowTable = ({ day, onUpdate, companyId }) => {
  const { api } = React.useContext(AppContext)
  const [rowState, rowDispatch] = React.useReducer(rowReducer, rowInitialState)

  const total = day.counts.reduce((acc, count) => Number.isInteger(count) ? acc + Number(count) : acc, 0)
  const color = total >= day.boundDanger ? 'danger' : total >= day.boundWarn ? 'warn' : 'safe'

  const boundWarnChanged = rowState.bounds.warn && Number(rowState.bounds.warn) !== Number(day.boundWarn)
  const boundDangerChanged = rowState.bounds.danger && Number(rowState.bounds.danger) !== Number(day.boundDanger)

  React.useEffect(() => {
    rowDispatch({ type: 'setData', bounds: { warn: '', danger: '' } })
  }, [day])

  const updateBounds = React.useCallback(() => {
    rowDispatch({ type: 'load' })
    Promise.all([
      api.post('/swaps/board', {
        body: JSON.stringify({
          date: formatDate(new Date(day.date)),
          boundWarn: rowState.bounds.warn || day.boundWarn,
          boundDanger: rowState.bounds.danger || day.boundDanger,
          companyId: companyId
        })
      }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([response]) => onUpdate(response))
      .then(() => rowDispatch({ type: 'loaded' }))
      .catch(payload => rowDispatch({ type: 'error', payload }))
  }, [api, day, rowState, onUpdate, companyId])

  return (
    <>
      <tr className={`line state-${color}`}>
        <td className="date border-0 py-1 sticky">
          <FormattedDate value={new Date(day.date)} year="numeric" month="2-digit" day="2-digit" weekday="long" />
        </td>
        {day.counts.map((count, i) => <td key={i} className="count align-middle border-0 py-1">{count}</td>)}
        <td className="total border-0 py-1">{total}</td>
        <td className="boundWarn border-top border-right p-0">
          <Input
            type="text" className={`border-0 rounded-0${!rowState.bounds.warn ? ' text-muted' : ''}`}
            placeholder={day.boundWarn}
            value={rowState.bounds.warn}
            onChange={({ target: { value: warn } }) => rowDispatch({ type: 'setData', bounds: { warn } })}
          />
        </td>
        <td className="boundDanger border-top border-right p-0">
          <Input
            type="text" className={`border-0 rounded-0${!rowState.bounds.danger ? ' text-muted' : ''}`}
            placeholder={day.boundDanger}
            value={rowState.bounds.danger}
            onChange={({ target: { value: danger } }) => rowDispatch({ type: 'setData', bounds: { danger } })}
          />
        </td>
        {!!companyId && companyId !== 0 && <td className="apply border-top border-right p-0">
          <TButton id="apply"
            className="w-100 h-100 border-0 rounded-0 py-1"
            disabled={!boundWarnChanged && !boundDangerChanged}
            onClick={() => updateBounds()}
          />
        </td>}
      </tr>
      {rowState.error && (
        <tr>
          <td className="p-0 border-0" colSpan={1 + day.counts.length + 4}><ErrAlert error={rowState.error} className="mb-0" /></td>
        </tr>
      )}
    </>
  )
}

const SwapTable = () => {

  const location = useLocation()
  const history = useHistory()

  const filters = React.useMemo(() => ({
    p: {
      type: 'number'
    },
    businessId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false,
        isSearchable: true,
        getOptionValue: option => option.label,
        isClearable: false,
      },
    },
    categoryId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false
      },
    },
    familyId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false
      },
    },
    itvcodeId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false
      },
    },
    companyId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false,
        translate: [0]
      },
    },
  }), [])

  // DATA
  const params = React.useMemo(() => queryToObject(location.search, { ...filters }), [location.search, filters])

  const [data, refreshing, error, loading] = useGet(`/swaps/board`, undefined, params)

  const refresh = React.useCallback((_params = {}) => history.push({ search: objectToQuery({ ...params, ..._params }) }), [history, params])

  return (
    <div className="container-fluid Table">

      <NavLink tag={Link} to={{
        pathname: '/swaps',
        search: location.state ? location.state.swaps : undefined
      }}>
        <T id="returnToList" />
      </NavLink>

      <Card>
        <CardHeader className="mb-2">
          <Filters
            loading={loading}
            disabled={refreshing}
            data={data}
            refresh={refresh}
            filters={filters}
          />
        </CardHeader>
      </Card>

      {error && <ErrAlert error={error} />}
      {!error && loading && <Spinner color="primary" className="d-flex my-4 mx-auto" />}
      {!error && !loading && (!data || data.users.length === 0) && (
        <TAlert id="noResults" color="warning" />
      )}
      {!error && !loading && data && data.users.length > 0 && (
        <Card className="mb-2">
          <CardBody className="py-3">
            <Table responsive className="mb-3 w-auto separate">
              <thead>
                <tr>
                  <TH colName="date" className={['border-0 text-center sticky color-white']} />
                  {data.users.map((user, i) =>
                    <TH key={`${i}.${user}`} className={['border-0']}>{user}</TH>)}
                  <TH colName="total" className={['border-0']} />
                  <TH colName="warn" className={['border-0 text-center']} />
                  <TH colName="danger" className={['border-0 text-center']} />
                  <TH className={['border-0']} />
                </tr>
              </thead>
              <tbody>
                {data.days.map(day => <RowTable day={day} onUpdate={refresh} key={day.date} companyId={data.filters.companyId.selected.value} />)}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      )}
    </div>
  )
}

export default SwapTable
