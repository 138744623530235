import React from 'react'
import {
  Card, CardHeader, CardBody, CardFooter,
  Collapse,
  Form, FormGroup,
  ListGroup, ListGroupItem,
  Modal, ModalBody, ModalFooter,
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import CustomSelect from 'components/CustomSelect'
import ProtectedComponent from 'components/ProtectedComponent'
import { T, TAlert, ErrAlert, TButton, TLabel, TInput, TCustomInput } from 'components/TComponents'

const Profile = (props) => {
  const { api } = React.useContext(AppContext)

  const [updateLoading, setUpdateLoading] = React.useState(false)
  const [updateError, setUpdateError] = React.useState()

  const [archiveOpen, setArchiveOpen] = React.useState(false)
  const [archiveLoading, setArchiveLoading] = React.useState(false)
  const [archiveError, setArchiveError] = React.useState()

  const [collapse, setCollapse] = React.useState(false)
  const [edit, setEdit] = React.useState(false)
  const [profile, setProfile] = React.useState(props.profile)

  const cancel = React.useCallback(() => {
    setUpdateError()
    setEdit(false)
    setProfile(props.profile)
  }, [props])

  const check = React.useCallback(({ target: { checked } }, right) => {
    setUpdateError()
    if (right.endsWith('_edit') && checked) {
      const rightView = `${right.slice(0, -5)}_view`
      if ('undefined' !== typeof (profile.rights[rightView])) {
        return setProfile(profile => ({
          ...profile,
          rights: {
            ...profile.rights,
            [right]: true,
            [rightView]: true
          }
        }))
      }
    }

    if (right.endsWith('_view') && !checked) {
      const rightEdit = `${right.slice(0, -5)}_edit`
      if ('undefined' !== typeof (profile.rights[rightEdit])) {
        return setProfile(profile => ({
          ...profile,
          rights: {
            ...profile.rights,
            [right]: false,
            [rightEdit]: false
          }
        }))
      }
    }

    setProfile(profile => ({
      ...profile,
      rights: {
        ...profile.rights,
        [right]: checked
      }
    }))
  }, [profile])

  const save = React.useCallback(() => {
    setUpdateLoading(true)
    setUpdateError()
    api.post('/rights/profile', { body: JSON.stringify(profile) })
      .then(response => {
        setEdit(false)
        setProfile(response)
      })
      .catch(response => setUpdateError(response))
      .then(() => setUpdateLoading(false))
  }, [api, profile])

  const archive = React.useCallback(() => {
    setArchiveLoading(true)
    setArchiveError()

    api.del('/rights/profile', undefined, { profileId: props.profile.profileId })
      .then((response) => {
        setProfile(response)
        setEdit(false)
        setArchiveOpen(false)
      })
      .catch(response => setArchiveError(response))
      .finally(() => setArchiveLoading(false))
  }, [api, props.profile])

  const handleChange = React.useCallback(({ target: { name, value } }) => {
    setUpdateError()
    setProfile(profile => ({
      ...profile,
      [name]: value
    }))
  }, [])

  return (
    <Card tag={Form} className={props.className} onSubmit={e => e.preventDefault()}>
      <CardHeader className="d-flex align-items-center position-relative">
        {profile.archived && <i className="mr-1 mb-0 h5 iconsmind-Box-Close text-primary" />}
        {edit ?
          <FormGroup className="mb-1">
            <TLabel className="sr-only"
              for={'profile.' + profile.profileId + '.name'}
              id="nameProfile" />
            <TInput id={'profile.' + profile.profileId + '.name'}
              name="nameProfile"
              type="text"
              value={profile.nameProfile}
              onChange={handleChange}
              placeholder="nameProfilePlaceholder" />
          </FormGroup>
          : <span className="h6 mb-0" color="link">{profile.nameProfile}</span>}
        {!edit && <TButton className="ml-auto stretched-link" outline
          onClick={() => !edit && setCollapse(!collapse)}
          id={collapse ? 'close' : 'open'} />}
      </CardHeader>
      <Form tag={Collapse} isOpen={collapse}>
        <CardBody>
          <FormGroup className="d-flex align-items-center">
            <TLabel className="mb-0 mr-3" for={'profile.' + profile.profileId + '.baseId'} id="profileBaseLabel" />
            <CustomSelect
              inputId={'profile.' + profile.profileId + '.baseId'}
              name="baseId"
              onChange={handleChange}
              options={props.bases.result}
              value={props.bases.result.filter(r => r.baseId === profile.baseId)}
              isDisabled
              getOptionLabel={option => option.baseName}
              getOptionValue={option => option.baseId}
              placeholder={<T id="profileBaseSelect" />} />
          </FormGroup>
          <TLabel id="rightsLabel" />
          {profile.rights && Object.entries(profile.rights).map(right =>
            <FormGroup key={right} tag="fieldset" check className="pl-0">
              <TCustomInput id={'profile.' + profile.profileId + '.right.' + right[0]}
                type="checkbox"
                checked={right[1]}
                disabled={!edit}
                onChange={e => check(e, right[0])}
                raw label={'right.' + right[0]} />
            </FormGroup>
          )}

          <FormGroup className="mt-2" tag="fieldset">
            <TLabel id="usersName" />
            {/* TODO: use ErrAlert */}
            {(!profile.users || profile.users.length < 1) && <TAlert color="warning" className="mb-0" id="noUsers" />}
            {profile.users && profile.users.length > 0 && <ListGroup>{profile.users.map(user =>
              <ListGroupItem key={user.userId}>{user.firstName} {user.lastName}</ListGroupItem>)}
            </ListGroup>}
          </FormGroup>
        </CardBody>
        <ProtectedComponent rights={['admin-profiles_edit']}>
          <CardFooter>
            {/* TODO: use ErrAlert */}
            {updateError && <ErrAlert error={updateError} />}
            {edit && (
              <div className="d-flex">
                <ProtectedComponent rights={['admin_archive']}>
                  <TButton
                    type="button" color="danger"
                    disabled={updateLoading}
                    onClick={() => setArchiveOpen(true)}
                    id={profile.archived ? 'restore' : 'archive'} />
                </ProtectedComponent>
                <div className="d-flex ml-auto pl-2">
                  <TButton className="ml-2"
                    type="button" color="primary"
                    disabled={updateLoading}
                    loading={updateLoading}
                    onClick={() => save(profile)}
                    id="saveAction" />
                  <TButton className="ml-2"
                    type="button" color="secondary"
                    disabled={updateLoading}
                    onClick={() => cancel()}
                    id="cancelAction" />
                </div>
              </div>
            )}
            {!edit && (
              <div className="d-flex justify-content-end">
                <TButton type="button" color="primary"
                  onClick={() => setEdit(true)}
                  id="editAction" />
              </div>
            )}
          </CardFooter>
        </ProtectedComponent>
      </Form>

      <Modal isOpen={archiveOpen} fade={false} toggle={() => setArchiveOpen(false)}>
        <ModalBody>
          <T id={`${profile.archived ? 'restore' : 'archive'}.content`} />
          {profile.nbAssigned > 0 && <TAlert color="warning" className="mb-0" id={`${profile.archived ? 'restore' : 'archive'}.alert`} values={{ nbUsers: profile.nbAssigned }} />}
          {archiveError && <ErrAlert className="mb-0 mt-2" error={archiveError} />}
        </ModalBody>
        <ModalFooter className="py-3">
          <TButton disabled={archiveLoading}
            onClick={() => setArchiveOpen(false)}
            id={`${profile.archived ? 'restore' : 'archive'}.cancel`} />
          <TButton disabled={archiveLoading} loading={archiveLoading}
            className="ml-2" color="danger"
            onClick={() => archive()}
            id={`${profile.archived ? 'restore' : 'archive'}.confirm`} />
        </ModalFooter>
      </Modal>
    </Card >
  )
}

export default Profile
