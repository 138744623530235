import React from 'react'
import { useParams, Link, } from 'react-router-dom'

import {
  Form, FormGroup, Label, CustomInput,
  Row, Col, Spinner,
  Card, CardHeader, CardBody,
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import { useGet } from 'hooks/useApi.hooks'
import { useFormatMessage, } from 'hooks/intl.hooks'

import CustomSelect from 'components/CustomSelect'
import { T, TAlert, TNavLink, TButton, ErrAlert, } from 'components/TComponents'

import './Custom-Input.scss'

const importReducer = (state, action) => {
  switch (action.type) {
  case 'posting': return { loading: true, error: undefined, data: undefined }
  case 'posted': return { loading: false, error: undefined, data: action.data }
  case 'error': return { loading: false, error: action.error, data: undefined }
  default: state
  }
}

const importInitialState = {
  loading: false,
  error: undefined,
  data: undefined
}

const EquipmentImport = () => {
  const params = useParams()
  const { api } = React.useContext(AppContext)
  const fileInput = React.useRef()

  const [businessId] = React.useState(params.id)
  const [categoryId, setCategoryId] = React.useState()
  const [familyId, setFamilyId] = React.useState()

  const [matrixLink, setMatrixLink] = React.useState()
  const [matrixLinkError, setMatrixLinkError] = React.useState()

  const [importState, dispatchImport] = React.useReducer(importReducer, importInitialState)

  const filtersParams = React.useMemo(() => ({ businessId, categoryId, familyId }), [businessId, categoryId, familyId])

  const formatMessage = useFormatMessage()
  const [filters, filtering, filterError, filterInit] = useGet('/equipments/importfilters', undefined, filtersParams)

  const importFile = React.useCallback(e => {
    e.preventDefault()

    dispatchImport({ type: 'posting' })
    api.put('/equipments/import', { body: fileInput.current.files[0] }, {
      businessId: filters.business.selected,
      categoryId: filters.categories.selected,
      familyId: filters.families.selected
    })
      .then(data => dispatchImport({ type: 'posted', data }))
      .catch(error => dispatchImport({ type: 'error', error }))
  }, [api, filters])

  React.useEffect(() => {
    if (businessId && categoryId && familyId) {
      const params = {
        businessId,
        categoryId,
        familyId
      }
      api.get('/equipments/matrixLink', undefined, { ...params })
        .then(response => setMatrixLink(response.matrixLink.url))
        .catch(error => setMatrixLinkError(error))
    }
  }, [businessId, categoryId, familyId, api])

  if (filterInit) {
    return (
      <>
        <TNavLink id="returnToList" tag={Link} to="/equipments" />
        <Spinner className="d-flex ml-auto mr-auto" color="primary" />
      </>
    )
  }

  return (
    <>
      <TNavLink id="returnToList" tag={Link} to="/equipments" />
      <Card>
        <CardHeader>
          <Form onSubmit={e => importFile(e)}>
            <Row form>
              {/* TODO: remove business field -> get it from url */}
              <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <Label for="businessId"><T id="businessLabel" /></Label>
                  <CustomSelect
                    inputId="businessId"
                    name="businessId"
                    options={filters.business.values}
                    placeholder={<T id="businessPlaceholder" value={''} />}
                    value={filters.business.values.filter(b => b.businessId === filters.business.selected)}
                    isDisabled={true}
                    getOptionLabel={option => option.businessName}
                    getOptionValue={option => option.businessId} />
                </FormGroup>
              </Col>
              <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <Label for="categoryId"><T id="categoriesLabel" /></Label>
                  <CustomSelect
                    inputId="categoryId"
                    name="categoryId"
                    options={filters.categories.values}
                    onChange={e => setCategoryId(e ? e.categoryId : '')}
                    isClearable
                    placeholder={<T id="categoriesPlaceholder" value={''} />}
                    value={filters.categories.values.filter(c => c.categoryId === filters.categories.selected)}
                    isDisabled={filtering || importState.loading}
                    getOptionLabel={option => option.categoryName}
                    getOptionValue={option => option.categoryId} />
                </FormGroup>
              </Col>
              <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <Label for="familyId"><T id="familiesLabel" /></Label>
                  <CustomSelect
                    inputId="familyId"
                    name="familyId"
                    options={filters.families.values}
                    onChange={e => setFamilyId(e ? e.familyId : '')}
                    isClearable
                    placeholder={<T id="familiesPlaceholder" value={''} />}
                    value={filters.families.values.filter(f => f.familyId === filters.families.selected)}
                    isDisabled={filtering || importState.loading}
                    getOptionLabel={option => option.familyName}
                    getOptionValue={option => option.familyId} />
                </FormGroup>
              </Col>
              <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <Label for="importInput"><T id="importFileLabel" /></Label>
                  <CustomInput type="file"
                    name="importInput"
                    id="importInput"
                    innerRef={fileInput}
                    label={(fileInput.current && fileInput.current.files[0] && fileInput.current.files[0].name) || formatMessage({ id: 'importFilePlaceholder' })}
                    disabled={filtering || importState.loading} />
                </FormGroup>
              </Col>
            </Row>
            <div className="d-flex justify-content-end">
              {matrixLink &&
                <TButton tag="a"
                  id="matrixLink"
                  target="_blank"
                  href={matrixLink}
                  className="mr-3" />}
              <TButton id="send" disabled={filtering || importState.loading} type="submit" />
            </div>
          </Form>
        </CardHeader>
        {filterError && <CardBody>
          <ErrAlert error={filterError} className="mb-0" />
        </CardBody>}
        {matrixLinkError && <CardBody>
          <ErrAlert error={matrixLinkError} className="mb-0" />
        </CardBody>}
        {importState.error && <CardBody>
          <TAlert color="danger" id={`error.${importState.error.code}`} values={importState.error.params} />
        </CardBody>}
        {importState.data && <CardBody>
          <TAlert color="primary" id="totalImported" values={{ nb: importState.data.totalImported }} />
          {importState.data.errors && importState.data.errors.map((error, i) => <TAlert color="danger" key={i} id={`error.${error.code}`} values={error.record} />)}
        </CardBody>}
      </Card>
    </>
  )
}

export default EquipmentImport
