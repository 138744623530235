import React from 'react'
import { Link } from 'react-router-dom'
import { objectToQuery } from 'react-rest-api'

import {
  Spinner,
  Popover, PopoverBody,
  CardColumns, Card, CardHeader, CardBody,
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'
import { T, TNavLink, ErrAlert } from 'components/TComponents'

import './Dashboard.scss'

const DashCard = ({ title, children, bodyClass }) => {
  const [isHover, setIsHover] = React.useState(false)

  // TODO: translate title prop it TProps keeping the id prop for popup
  // eslint-disable-next-line react/display-name
  const Tooltip = React.useMemo(() => chunk => (
    <span className="ml-auto pl-2"
      id={`${title}Popup`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}>
      <i className="simple-icon-question h4 mb-0 text-muted" />
      <Popover target={`${title}Popup`}
        placement="bottom"
        isOpen={isHover}
        toggle={() => setIsHover(!isHover)}>
        <PopoverBody>{chunk}</PopoverBody>
      </Popover>
    </span>
  ), [isHover, title])

  return (
    <Card className="shadow-none">
      {title && <CardHeader>
        <T tagName="div"
          id={title}
          className="w-100 d-flex align-items-center"
          values={{ tooltip: Tooltip }} />
      </CardHeader>}
      <CardBody className={bodyClass}>
        {children}
      </CardBody>
    </Card>
  )
}

const DashCardItem = ({ value, name, className, link }) =>
  link
    ? <TNavLink key={name} id={name} values={{ value }} tag={Link} to={{ ...link, search: objectToQuery(link.state) }} className={`value p-0 d-inline ${className}`} />
    : <T key={name} id={name} values={{ value }} className={`value ${className}`} />

export const Dashboard = () => {
  const { api } = React.useContext(AppContext)

  const [blocksList, setBlocksList] = React.useState()
  const [blocks, setBlocks] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()

  React.useEffect(() => {
    Promise.all([
      api.get('/dashboard/list'),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then((response) => {
        setBlocksList(response[0].blocks)
        setBlocks(response[0].blocks.map(b => { return { name: b, values: undefined } }))
      })
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api])

  React.useEffect(() => {
    blocksList && blocksList.length > 0 && blocksList.map(block => {
      Promise.all([
        api.get('/dashboard/block', undefined, { name: block }),
        new Promise(resolve => setTimeout(resolve, 250))
      ])
        .then((response) => {
          setBlocks(oldBlocks => oldBlocks.map(b => {
            if (b.name === response[0].block.name) {
              return { name: b.name, values: response[0].block.values }
            }
            return b
          }))
        })
        .catch(response => setError(response))
    })
  }, [api, blocksList])

  if (error) {
    return <ErrAlert error={error} />
  }

  return (
    <div className="container-fluid Dashboard">
      {loading && <>
        <Spinner className="d-flex ml-auto mr-auto" color="primary" />
      </>}
      <CardColumns>
        {!loading && (!blocks || blocks.length === 0) && <DashCard>
          <T id="noBlocks" />
        </DashCard>}
        {!loading && blocks && blocks.length > 0 && blocks.map(block => (
          <DashCard key={block.name}
            title={block.name}
            bodyClass="d-flex flex-column py-3">
            {block.values && block.values.length > 0 ? (
              <>
                {block.values.map(DashCardItem)}
              </>
            ) : (
              <Spinner className="d-flex ml-auto mr-auto" color="primary" />
            )}
          </DashCard>
        ))}
      </CardColumns>
    </div>
  )
}

export default Dashboard
