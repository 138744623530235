import React from 'react'
import { Switch } from 'react-router-dom'

import Route from 'components/Route'

import List from 'routes/Admin/Itvcodereason/ItvcodereasonList'

export default function Users({ match }) {
  return <Switch>
    <Route exact path={`${match.path}`} component={List} rights={['admin-reasonpartchange']} />
  </Switch>
}
