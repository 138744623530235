import React from 'react'
import { Switch } from 'react-router-dom'

import Route from 'components/Route'

import List from 'routes/Admin/Triggers/TriggersList'
import New from 'routes/Admin/Triggers/TriggersNew'

export default function Triggers({ match }) {
  return <Switch>
    <Route exact path={`${match.path}`} component={List} />
    <Route path={`${match.path}/new`} component={New} />
  </Switch>
}
