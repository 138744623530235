import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import Route from 'components/Route'

import MaintenancePlanList from './MaintenancePlanList'
import MaintenancePlanNew from './MaintenancePlanNew'
import MaintenancePlanEdit from './MaintenancePlanEdit'

const MaintenancePlanRoute = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/new`} component={MaintenancePlanNew} rights={['masterplan-edit']} />
    <Route exact path={`${match.path}`} component={MaintenancePlanList} rights={['masterplan-view']} />
    <Route exact path={`${match.path}/:id`} component={MaintenancePlanEdit} rights={['masterplan-view']} />
    <Redirect to={`/`} />
  </Switch>
)

export default MaintenancePlanRoute
