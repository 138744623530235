import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import Route from 'components/Route'

import List from './SwapList'
import Table from './SwapTable'
import New from './SwapNew'
import Edit from './SwapEdit'
import Plan from './SwapPlan'
import Start from './SwapStart'
import SwapPlanVeloptim from './SwapPlanVeloptim'

const SwapRoute = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}/plan/:id`} component={Plan} rights={['swaps_view']} />
    <Route exact path={`${match.path}/new`} component={New} rights={['swaps_new']} />
    <Route exact path={`${match.path}/table`} component={Table} rights={['swaps_new']} />
    <Route exact path={`${match.path}/veloptim/:id`} component={SwapPlanVeloptim} rights={['swaps_view']} />
    <Route exact path={`${match.path}/:id`} component={Edit} rights={['swaps_view']} />
    <Route exact path={`${match.path}`} component={List} rights={['swaps_view']} />
    <Route exact path={`${match.path}/start/:id`} component={Start} rights={['swaps-end_web']} />
    {/* TODO: Bad redirect ??? */}
    <Redirect to={`/`} />
  </Switch>
)

export default SwapRoute
