import React from 'react'

import {
  Button, Spinner,
  Table, Card, CardHeader,
  Modal, ModalBody, ModalFooter,
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'
import TH from 'components/TH'
import { T, TButton, ErrAlert } from 'components/TComponents'
import ProtectedComponent from 'components/ProtectedComponent'
import Pagination from 'components/Pagination'

import MatrixCreate from './MatrixCreate'

const Matrices = () => {
  const [matrices, setMatrices] = React.useState()
  const [matricesLoading, setMatricesLoading] = React.useState(true)
  const [matricesError, setMatricesError] = React.useState()

  const [deleteId, setDeleteId] = React.useState()
  const [deleteLoading, setDeleteLoading] = React.useState()
  const [deleteError, setDeleteError] = React.useState()

  const [creating, setCreating] = React.useState(false)

  const { api } = React.useContext(AppContext)

  const getMatrices = React.useCallback((params = {}) => {
    setMatricesLoading(true)
    setMatricesError(undefined)
    return api.get('/matrices/list', undefined, {
      p: matrices ? matrices.currentPage : 0,
      ...params
    })
      .then(response => setMatrices(response))
      .catch(response => setMatricesError(response))
      .then(() => setMatricesLoading(false))
  }, [api, matrices])

  const openDelete = React.useCallback(id => {
    setDeleteId(id)
    setDeleteLoading(false)
    setDeleteError()
  }, [])

  const deleteMatrix = React.useCallback(() => {
    setDeleteLoading(true)
    return api.del('/matrices/details', undefined, { matrixLinkId: deleteId })
      .then(() => {
        getMatrices()
        setDeleteId()
      })
      .catch(response => setDeleteError(response))
      .then(() => setDeleteLoading(false))
  }, [api, deleteId, getMatrices])

  const handlePageClick = React.useCallback(p => getMatrices({ p }), [getMatrices])

  React.useEffect(() => {
    api.get('/matrices/list')
      .then(response => setMatrices(response))
      .catch(response => setMatricesError(response))
      .then(() => setMatricesLoading(false))
  }, [api])

  if (matricesError) return <ErrAlert error={matricesError} />

  return (<>
    <ProtectedComponent rights={['admin-matrices_edit']}>
      {creating
        ? <MatrixCreate onClose={() => { setCreating(false); getMatrices() }} />
        : <Card className="mb-2">
          <CardHeader>
            <Button onClick={() => setCreating(true)}>
              <T id="openCreate" />
            </Button>
          </CardHeader>
        </Card>}
    </ProtectedComponent>
    {matricesLoading && <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />}
    {!matricesLoading && <Table responsive striped hover className="mb-3 mt-2">
      <thead>
        <tr>
          <TH colName="count" colValues={{ total: matrices.total }} colSpan="100" className={['border-top-0']} />
        </tr>
      </thead>
      <thead>
        <tr>
          <TH colName="matrixLinkId" />
          <TH colName="matrixName" />
          <TH colName="businessName" />
          <TH colName="categoryName" />
          <TH colName="familyName" />
          <ProtectedComponent rights={['admin-matrices_edit']}>
            <TH style={{ whiteSpace: 'nowrap', width: '1%' }} />
          </ProtectedComponent>
        </tr>
      </thead>
      <tbody>
        {matrices.result.map(matrix => <tr key={matrix.matrixLinkId || ''}>
          <td className="align-middle">{matrix.matrixLinkId}</td>
          <td className="align-middle">{matrix.matrixName}</td>
          <td className="align-middle">{matrix.businessName}</td>
          <td className="align-middle">{matrix.categoryName}</td>
          <td className="align-middle">{matrix.familyName}</td>
          <ProtectedComponent rights={['admin-matrices_edit']}>
            <td className="align-middle">
              {!matrix.inUse && <Button color="danger"
                onClick={() => openDelete(matrix.matrixLinkId)}>
                <i className="simple-icon-trash mr-1" />
                <T id="delete" />
              </Button>}
            </td>
          </ProtectedComponent>
        </tr>)}
      </tbody>
      <tfoot>
        <tr><td colSpan="100">
          <Pagination totalPage={matrices.totalPage}
            currentPage={matrices.currentPage}
            onPageClick={page => handlePageClick(page)} /></td></tr>
      </tfoot>
    </Table>}

    <Modal isOpen={deleteId !== undefined} fade={false} toggle={() => setDeleteId()}>
      <ModalBody>
        <T id="deleteContent" />
        {deleteError && <ErrAlert error={deleteError} className="mb-0 mt-2" />}
      </ModalBody>
      <ModalFooter>
        <TButton id="deleteCancel"
          disabled={deleteLoading}
          onClick={() => setDeleteId()} />
        <TButton id="deleteConfirm"
          color="danger"
          disabled={deleteLoading}
          loading={deleteLoading}
          className="ml-2"
          onClick={() => deleteMatrix()} />
      </ModalFooter>
    </Modal>
  </>)
}

export default Matrices
