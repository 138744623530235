import React from 'react'
import { Switch } from 'react-router-dom'

import Route from 'components/Route'

import List from 'routes/Admin/Business/BusinessList'
import Details from 'routes/Admin/Business/BusinessDetails'

export default function Bases({ match }) {
  return <Switch>
    <Route exact path={`${match.path}`} component={List} rights={['admin-business_edit', 'admin-business_view',]} />
    <Route path={`${match.path}/new`} component={Details} rights={['admin-business_edit',]} />
    <Route path={`${match.path}/:id`} component={Details} rights={['admin-business_edit', 'admin-business_view',]} />
  </Switch>
}
