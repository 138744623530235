import React from 'react'
import { useParams, useLocation, useHistory, Link } from 'react-router-dom'
import CustomSelect from 'components/CustomSelect'
import { useIntl } from 'react-intl'
import { objectToQuery } from 'react-rest-api'

import 'react-datepicker/dist/react-datepicker.css'

import Timeline from 'components/react-calendar-timeline'
import 'components/react-calendar-timeline/lib/Timeline.scss'
import VehicleIcon from 'components/VehicleIcon'
import ProtectedComponent from 'components/ProtectedComponent'

import {
  Spinner,
  Button,
  Col, Row,
  FormGroup, FormText, Label,
  NavLink,
  ListGroup, ListGroupItem,
  Modal, ModalHeader, ModalBody, ModalFooter, InputGroup
} from 'reactstrap'

import ReactTooltip from 'react-tooltip';

import { AppContext } from 'contexts/AppContext'
import { useFormatMessage } from 'hooks/intl.hooks'

import Slider from 'components/Slider'
import { NotificationManager } from 'components/ReactNotifications'
import SlotSelector from 'components/SlotSelector'
import PlanningItem from 'components/PlanningItem'
import { T, TAlert, TButton, TProps, ErrAlert, TLabel, TInput, TCustomInput, TDatePicker } from 'components/TComponents'

import { debounce } from 'util/Utils'

import '../Planning.scss'

const itvHours = Array(9).fill().map((_, i) => i)
const itvMinutes = Array(12).fill().map((_, i) => (i * 5))

const remindInit = {
  loading: false,
  result: undefined,
  error: undefined
}

const remindReducer = (state, action) => {
  switch (action.type) {
  case 'loading': return { loading: true, result: undefined, error: undefined }
  case 'result': return { loading: false, result: action.result, error: undefined }
  case 'error': return { loading: false, result: undefined, error: action.error }
  case 'init': return remindInit
  default: return state
  }
}

const SwapPlan = () => {
  const routeParams = useParams()
  const history = useHistory()
  const location = useLocation()
  const formatMessage = useFormatMessage()

  const { api, constants, subsidiary, timeStartWork, timeEndWork } = React.useContext(AppContext)
  const intl = useIntl()

  const preferredTimeRangeOptions = React.useMemo(() => ({
    min: 6 * 60,
    max: 20 * 60,
    step: 15
  }), [])

  const [planningsUsers, setPlanningUsers] = React.useState()
  const [planningsUsersLoad, setPlanningsUsersLoad] = React.useState(true)
  const [planningsUsersError, setPlanningsUsersError] = React.useState()
  const [plannings, setPlannings] = React.useState([])
  const [planningUserToAdd, setPlanningUserToAdd] = React.useState([])

  const [intervention, setIntervention] = React.useState()
  const [interventionLoad, setInterventionLoad] = React.useState(false)
  const [interventionError, setInterventionError] = React.useState()
  const [baseSwap, setBaseSwap] = React.useState()

  const [searchUser, setSearchUser] = React.useState(location.state ? location.state.s : '')
  const [userTypeId, setUserTypeId] = React.useState(location.state && location.state.t)
  const [companyId, setCompanyId] = React.useState(location.state ? location.state.c : subsidiary.companyId)
  // TODO: init datePlanned ???
  const [dateSelected, setDateSelected] = React.useState(location.state && location.state.d ? new Date(location.state.d) : new Date())
  const [itvHourSelected, setItvHourSelected] = React.useState(0)
  const [itvMinuteSelected, setItvMinuteSelected] = React.useState(0)

  const [disableTimeRange, setDisableTimeRange] = React.useState(false)
  const [preferredTimeRange, setPreferredTimeRange] = React.useState([])

  const [dateStart, setDateStart] = React.useState(new Date().setHours(timeStartWork, 0))
  const [dateEnd, setDateEnd] = React.useState(new Date().setHours(timeEndWork))
  const [planningGroups, setPlanningGroups] = React.useState([])
  const [planningItems, setPlanningItems] = React.useState([])

  const [isHelpOpen, setIsHelpOpen] = React.useState(false)

  const [scheduleLoad, setScheduleLoad] = React.useState(false)
  const [scheduleError, setScheduleError] = React.useState()
  const [durationError, setDurationError] = React.useState()
  const [unScheduleLoad, setUnScheduleLoad] = React.useState(false)
  const [unScheduleError, setUnScheduleError] = React.useState()

  const [remindState, remindDispatch] = React.useReducer(remindReducer, remindInit)

  const [alertMax, setAlertMax] = React.useState()
  const [alertMaxError, setAlertMaxError] = React.useState()
  const [modalAlert, setModalAlert] = React.useState(false)
  const [scheduleData, setScheduleData] = React.useState()

  const [userTechcenter, setUserTechcenter] = React.useState([])
  const [techenterScheduleModal, setTechenterScheduleModal] = React.useState(false)
  const [techenterScheduleData, setTechenterScheduleData] = React.useState()

  React.useEffect(() => {
    api.get('/swaps/details', undefined, { swapId: routeParams.id })
      .then(response => {
        setBaseSwap(response)
        setIntervention(response)
      })
      .catch(response => setInterventionError(response))
      .then(() => setInterventionLoad(false))
  }, [api, routeParams.id])

  React.useEffect(() => {
    if (intervention) {
      setItvHourSelected(Math.trunc((intervention.durationPlanned || intervention.durationEstimated) / 60))
      setItvMinuteSelected((intervention.durationPlanned || intervention.durationEstimated) % 60)
      setDisableTimeRange(!(intervention.timeRangeStart && intervention.timeRangeEnd))
      setPreferredTimeRange([intervention.timeRangeStart || preferredTimeRangeOptions.min, intervention.timeRangeEnd || preferredTimeRangeOptions.max])
      const inputHour = document.getElementById('selectHour')
      const inputMinute = document.getElementById('selectMinute')
      if (Math.trunc(intervention.durationPlanned  / 60) !== Math.trunc(baseSwap.durationPlanned / 60)) {
        inputHour.focus()
      } else if (intervention.durationPlanned % 60 !== baseSwap.durationPlanned % 60) {
        inputMinute.focus()
      }
    }
  }, [baseSwap, intervention, preferredTimeRangeOptions.max, preferredTimeRangeOptions.min])

  React.useEffect(() => {
    api.get('/swaps/limiteDay', undefined, {
      planningDate: `${dateSelected.getFullYear()}-${dateSelected.getMonth() + 1 >= 10 ? (dateSelected.getMonth() + 1) : '0' + (dateSelected.getMonth() + 1)}-${dateSelected.getDate()}`,
      companyId,
    })
      .then(response => {
        setAlertMax(response)
      })
      .catch(error => { setAlertMaxError(error) })
  }, [api, dateSelected, companyId])

  React.useEffect(() => {
    if (baseSwap) {
      let ignore = false
      setPlanningsUsersError()
      setPlanningsUsersLoad(true)
      api.get('/plannings/view', undefined, {
        dayStart: `${dateSelected.getFullYear()}-${dateSelected.getMonth() + 1}-${dateSelected.getDate()}`,
        userTypeId,
        companyId
      })
        .then(response => {
          if (!ignore) {
            setPlannings([])
            setPlanningUsers(response)
            const userTechcenterTemp = []
            if (response && response.users && response.users.length > 0) {
              response.users.map(user => {
                if (typeof user.distance !== 'undefined') {
                  userTechcenterTemp.push(user.userId)
                }
              })
            }
            setUserTechcenter(userTechcenterTemp)
          }
        })
        .catch(response => { if (!ignore) { setPlanningsUsersError(response) } })
        .then(() => {
          if (!ignore) {
            setPlanningsUsersLoad(false)
          }
        })

      return () => { ignore = true }
    }
  }, [api, dateSelected, userTypeId, companyId, baseSwap])

  const fetchPlanningUser = React.useCallback(i => {
    if (i < planningsUsers.users.length) {
      const _params = {
        dayStart: `${dateSelected.getFullYear()}-${dateSelected.getMonth() + 1}-${dateSelected.getDate()}`,
        userId: planningsUsers.users[i].userId
      }
      api.get('/plannings/userPlanning', undefined, { ..._params })
        .then(response => {
          setPlannings(oldPlanning => [...oldPlanning.filter((pUser => pUser.userId !== planningsUsers.users[i].userId)), {
            userId: planningsUsers.users[i].userId,
            userName: planningsUsers.users[i].firstName + ' ' + planningsUsers.users[i].lastName,
            distance: (planningsUsers.users[i].distance || planningsUsers.users[i].distance === 0) ? planningsUsers.users[i].distance : null,
            address: planningsUsers.users[i].distance,
            interventions: response.interventions,
            appointments: response.appointments,
            swaps: response.swaps,
            unavailabilities: response.unavailabilities,
            maintenanceplans: response.maintenanceplans
          }])
        })
    }
  }, [api, planningsUsers, dateSelected])

  const fetchPlanningUserArray = React.useCallback(userIndexes => {
    setPlanningUserToAdd([])
    userIndexes.map(i => {
      const _params = {
        dayStart: `${dateSelected.getFullYear()}-${dateSelected.getMonth() + 1}-${dateSelected.getDate()}`,
        userId: planningsUsers.users[i].userId
      }
      api.get('/plannings/userPlanning', undefined, { ..._params })
        .then(response => {
          const item = {
            userId: planningsUsers.users[i].userId,
            userName: planningsUsers.users[i].firstName + planningsUsers.users[i].lastName,
            distance: planningsUsers.users[i].distance || null,
            address: planningsUsers.users[i].distance,
            interventions: response.interventions,
            appointments: response.appointments,
            swaps: response.swaps,
            unavailabilities: response.unavailabilities,
            maintenanceplans: response.maintenanceplans
          }
          setPlanningUserToAdd(oldPlanningUserToAdd => [...oldPlanningUserToAdd, item])
        })
    })
  }, [api, planningsUsers, dateSelected])

  React.useEffect(() => {
    if (planningUserToAdd.length === 2) {
      setPlannings(oldPlanning => [...oldPlanning.filter((pUser => !planningUserToAdd.map(pU => pU.userId).includes(pUser.userId))), ...planningUserToAdd])
    }
  }, [planningUserToAdd])

  React.useEffect(() => {
    const dateToCompare = `${dateSelected.getFullYear()}-${("0" + (dateSelected.getMonth() + 1)).toString().padStart(2, '0')}-${dateSelected.getDate().toString().padStart(2, '0')}`
    if (planningsUsers && planningsUsers.users && planningsUsers.users.length > 0 && planningsUsers.filters && planningsUsers.filters.dayStart === dateToCompare) {
      for (let i = 0; i < planningsUsers.users.length; i++) {
        fetchPlanningUser(i)
      }
    }
  }, [planningsUsers, fetchPlanningUser, dateSelected])

  React.useEffect(() => {
    history.replace({ search: objectToQuery({ ...location.state }), state: location.state })
  }, [history, location.state])

  React.useEffect(() => {
    if (planningsUsers) {
      setDateStart(new Date(planningsUsers.filters.dayStart).setHours(timeStartWork, 0))
      setDateEnd(new Date(planningsUsers.filters.dayStart).setHours(timeEndWork))
    }
  }, [planningsUsers, timeStartWork, timeEndWork])

  const itvStatus = React.useMemo(() => constants.interventionStatus.reduce((acc, status) => ({ ...acc, [status.key]: status.value }), {}), [constants])
  const swapStatus = React.useMemo(() => constants.swapStatus.reduce((acc, status) => ({ ...acc, [status.key]: status.value }), {}), [constants])

  React.useEffect(() => {
    if (planningsUsers) {
      const users = !searchUser || searchUser.trim().length === 0 ? planningsUsers.users
        : planningsUsers.users.filter(user => (user.firstName + ' ' + user.lastName).toLowerCase().indexOf(searchUser.toLowerCase()) !== -1)
      setPlanningGroups(users.map(user => ({ id: user.userId, title: user.firstName + ' ' + user.lastName, distance: user.distance, address: user.address, customHeight: (user.distance || user.distance === 0) ? true : false || null, vehicle: user.vehicleType, vehicleIdentifier: user.vehicleIdentifier })))
    }
  }, [intervention, planningsUsers, searchUser, itvStatus, swapStatus])

  React.useEffect(() => {
    if (plannings && plannings.length > 0 && intervention) {
      const getInterventionStyle = intervention => {
        const style = {}
        if ([itvStatus.done, itvStatus.canceled, itvStatus.closed].includes(intervention.statusId)) {
          style['backgroundColor'] = 'rgba(0, 0, 0, .4)'
        } else if (intervention.statusId === itvStatus.scheduled && (new Date(intervention.datePlanned).getTime() + intervention.durationPlanned * 1000 * 60) < Date.now()) {
          style['backgroundColor'] = 'rgba(255, 0, 0, .4)'
        } else {
          style['backgroundColor'] = 'rgba(0, 125, 250, .4)'
        }
        return style
      }

      const getSwapStyle = swap => {
        const style = {}
        if ([swapStatus.done, swapStatus.canceled].includes(swap.statusId)) {
          style.background = 'repeating-linear-gradient(125deg, rgba(246, 186, 82, .5), rgba(246, 186, 82, .5) 50%, rgba(0, 0, 0, .4) 50%, rgba(0, 0, 0, .4) 100%)'
        } else if (swap.statusId === swapStatus.scheduled && (new Date(swap.datePlanned).getTime() + swap.durationPlanned * 1000 * 60) < Date.now()) {
          style.background = 'repeating-linear-gradient(125deg, rgba(255, 0, 0, .5), rgba(255, 0, 0, .5) 50%, rgba(255, 209, 128, .4) 50%, rgba(255, 209, 128, .4) 100%)'
        } else {
          style.background = 'repeating-linear-gradient(125deg, rgba(246, 186, 82, 1), rgba(246, 186, 82, 1) 50%, rgba(255, 209, 128, 1) 50%, rgba(255, 209, 128, 1) 100%)'
        }
        return style
      }

      const users = !searchUser || searchUser.trim().length === 0 ? plannings
        : plannings.filter(user => user.userName.toLowerCase().indexOf(searchUser.toLowerCase()) !== -1)

      setPlanningItems(users.reduce((acc, user) =>
        acc
          .concat(user.interventions.map(_intervention => ({
            id: `timelineItem-itv-${_intervention.interventionId}`,
            type: 'intervention',
            group: user.userId,
            canMove: false,
            title: '',
            className: _intervention.difficultyValue + (intervention.interventionId === _intervention.interventionId ? ' active' : ''),
            timeStart: new Date(_intervention.datePlanned).getTime(),
            timeEnd: new Date(_intervention.datePlanned).getTime() + _intervention.durationPlanned * 1000 * 60,
            style: getInterventionStyle(_intervention),
            intervention: _intervention
          })))
          .concat(user.swaps.map(_swap => ({
            id: `timelineItem-swap-${_swap.swapId}`,
            type: 'swap',
            group: user.userId,
            canMove: intervention.swapId === _swap.swapId,
            canResize: intervention.swapId === _swap.swapId,
            title: '',
            className: _swap.difficultyValue + (intervention.swapId === _swap.swapId ? ' active' : ''),
            timeStart: new Date(_swap.datePlanned).getTime(),
            timeEnd: new Date(_swap.datePlanned).getTime() + _swap.durationPlanned * 1000 * 60,
            style: getSwapStyle(_swap),
            swap: _swap
          })))
          .concat(user.appointments.map(appointment => ({
            id: `timelineItem-app-${appointment.appointmentId}`,
            type: 'appointment',
            group: user.userId,
            canMove: false,
            timeStart: new Date(appointment.dateAppointment).getTime(),
            timeEnd: new Date(appointment.dateAppointment).getTime() + appointment.durationPlanned * 1000 * 60,
            style: { 'backgroundColor': 'rgba(125, 0, 125, .4)' },
            appointment
          })))
          .concat(user.unavailabilities.map(unavailability => ({
            id: `timelineItem-unv-${unavailability.unavailabilityId}`,
            type: 'unavailability',
            group: user.userId,
            canMove: false,
            stackable: false,
            timeStart: new Date(unavailability.dateStart).getTime(),
            timeEnd: new Date(unavailability.dateEnd).getTime(),
            style: {
              'background': 'repeating-linear-gradient(135deg,#eeeeee,#eeeeee 10px,#cacaca 10px,#cacaca 20px)',
              zIndex: 0
            },
            unavailability
          })))
          .concat(user.maintenanceplans.map(maintenance => ({
            id: `timelineItem-mai-${maintenance.maintenanceplanId}`,
            type: 'maintenance',
            group: user.userId,
            title: '',
            className: maintenance.difficultyValue,
            canMove: false,
            timeStart: new Date(maintenance.datePlanned).getTime(),
            timeEnd: new Date(maintenance.datePlanned).getTime() + maintenance.durationPlanned * 1000 * 60,
            style: { 'backgroundColor': 'rgba(226, 134, 59, .4)' },
            maintenance
          })))
      , []))
    }
  }, [intervention, plannings, searchUser, itvStatus, swapStatus])

  const schedule = React.useCallback(({ userId, force, time, minutes, hours, timeRangeStart, timeRangeEnd, forceAddressTechcenter }, forceAlert = 0) => {
    if (alertMax && alertMax.count >= alertMax.max && forceAlert === 0) {
      setModalAlert(true)
      setScheduleData({ userId, force, time, minutes, hours, timeRangeStart, timeRangeEnd, forceAddressTechcenter })
    } else {
      const { id: swapId } = routeParams
      const duration = ((hours ?? itvHourSelected) * 60) + (minutes ?? itvMinuteSelected)

      const datePlanned = time ? new Date(time) : undefined

      setBaseSwap(intervention)

      setScheduleError()
      return api.post('/swaps/schedule', {
        body: JSON.stringify({
          swapId,
          datePlanned,
          userId,
          timeRangeStart,
          timeRangeEnd,
          duration: intervention.durationPlanned === duration ? undefined : duration,
          force,
          forceAddressTechcenter
        })
      })
        .then(_intervention => {
          if (intervention.userIdAssignedTo) {
            if (_intervention.userIdAssignedTo === intervention.userIdAssignedTo) {
              fetchPlanningUser(planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === intervention.userIdAssignedTo)))
            } else {
              if (planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === intervention.userIdAssignedTo)) === -1) {
                fetchPlanningUser(planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === _intervention.userIdAssignedTo)))
              } else {
                fetchPlanningUserArray([
                  planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === intervention.userIdAssignedTo)),
                  planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === _intervention.userIdAssignedTo))
                ])
              }
            }
          } else {
            if (_intervention.userIdAssignedTo) {
              fetchPlanningUser(planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === _intervention.userIdAssignedTo)))
            }
          }
          setIntervention(_intervention)
        })
    }
  }, [api, intervention, itvHourSelected, itvMinuteSelected, routeParams, fetchPlanningUserArray, fetchPlanningUser, planningsUsers, alertMax])


  const unschedule = React.useCallback(() => {
    const { id: swapId } = routeParams

    setUnScheduleLoad(true)
    return api.del('/swaps/schedule', undefined, { swapId })
      .then(_intervention => setIntervention(_intervention))
      .then(() => fetchPlanningUser(planningsUsers.users.indexOf(planningsUsers.users.find(u => u.userId === intervention.userIdAssignedTo))))
      .catch(response => setUnScheduleError(response))
      .then(() => setUnScheduleLoad(false))
  }, [api, routeParams, fetchPlanningUser, intervention, planningsUsers])

  const handleTimelineDoubleClick = React.useCallback((e, userId, time) => {
    const data = { userId, time }
    if (userTechcenter.includes(userId)) {
      setTechenterScheduleModal(true)
      setTechenterScheduleData(data)
    } else {
      setScheduleLoad(true)
      schedule(data)
        .catch(error => setScheduleError({ ...error, data }))
        .then(() => setScheduleLoad(false))
    }
  }, [schedule, userTechcenter])

  const handleInterventionDrop = React.useCallback((userId, time, item) => {
    setPlanningItems(p => p.map(_item => _item.id === item.id ? { ..._item, canMove: false } : _item))
    const data = { userId, time }
    setScheduleLoad(true)
    schedule(data)
      .catch(error => setScheduleError({ ...error, data }))
      .catch(response => NotificationManager.error(response))
      .then(() => setScheduleLoad(false))
  }, [schedule])

  const handleHelpClick = React.useCallback(() => setIsHelpOpen(true), [])

  const handleHelpToggle = React.useCallback(() => setIsHelpOpen(h => !h), [])

  const handleItvHoursChange = React.useCallback(({ target: { value } }) => {
    setDurationError()
    setScheduleLoad(true)
    const data = { hours: Number(value) }
    schedule(data, 1)
      .catch(error => {
        if (error.code === 'preventUpdate') {
          setScheduleError({ ...error, data })
        } else {
          setDurationError(error)
        }
      })
      .then(() => setScheduleLoad(false))
  }, [schedule])

  const handleItvMinutesChange = React.useCallback(({ target: { value } }) => {
    setDurationError()
    setScheduleLoad(true)
    const data = { minutes: Number(value) }
    schedule(data, 1)
      .catch(error => setScheduleError(error.code === 'preventUpdate' ? { ...error, data } : error))
      .then(() => setScheduleLoad(false))
  }, [schedule])

  const scheduleDebounce = React.useMemo(() => debounce(schedule, 1200), [schedule])

  const handleTimeRange = React.useCallback(({ target: { value } }) => {
    setPreferredTimeRange(value)
    setScheduleLoad(true)
    scheduleDebounce({
      timeRangeStart: Math.min(...value),
      timeRangeEnd: Math.max(...value),
    }, 1)
      .catch(error => setScheduleError(error))
      .then(() => setScheduleLoad(false))
  }, [scheduleDebounce])

  const handleTimeRangeDisable = React.useCallback(({ target: { checked } }) => {
    setScheduleLoad(true)
    schedule({
      timeRangeStart: checked ? null : preferredTimeRange[0],
      timeRangeEnd: checked ? null : preferredTimeRange[1],
    }, 1)
      .catch(error => setScheduleError(error))
      .then(() => setScheduleLoad(false))
  }, [schedule, preferredTimeRange])

  const handleItvDate = React.useCallback(e => {
    e.preventDefault()
    handleDateChange(new Date(intervention.datePlanned))
  }, [handleDateChange, intervention])

  const handleDateChange = React.useCallback((date) => {
    if (date.getTime() < new Date().setHours(0, 0, 0, 0)) {
      return NotificationManager.error({ code: 'cannotGoPast' })
    }

    setDateSelected(date)
    history.replace({
      search: location.search,
      state: { ...location.state, d: date }
    })
  }, [history, location.search, location.state])

  const filterUsers = React.useCallback(value => {
    setSearchUser(value)
    history.replace({
      search: location.search,
      state: { ...location.state, s: value }
    })
  }, [history, location.search, location.state])

  const handleUserTypeId = React.useCallback(value => {
    setUserTypeId(value)
    history.replace({
      search: location.search,
      state: { ...location.state, t: value }
    })
  }, [history, location.search, location.state])

  const handleCompanyId = React.useCallback(value => {
    setCompanyId(value)
    history.replace({
      search: location.search,
      state: { ...location.state, c: value }
    })
  }, [history, location.search, location.state])

  const remind = React.useCallback(() => {
    remindDispatch({ type: 'loading' })
    Promise.all([
      api.post('/swaps/reminder', { body: JSON.stringify({ swapId: routeParams.id }) }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([result]) => {
        remindDispatch({ type: 'result', result })
        setIntervention(intervention => ({ ...intervention, dateReminderSent: result.dateReminderSent }))
        NotificationManager.success(formatMessage({ id: 'remind.popup.success' }))
      })
      .catch(error => remindDispatch({ type: 'error', error }))
  }, [api, formatMessage, routeParams.id])

  const SidebarItem = ({ group }) => {
    return (
      <div className="h-100 d-flex flex-row align-items-center">
        <div className="h-100 d-flex justify-content-center flex-column">
          <p className="mb-0">{group.title}</p>
          {(group.distance || group.distance === 0) &&
            <>
              <p className="mb-0 font-weight-light font-italic" style={{ cursor: 'pointer' }}
              >
                <T id="techcenterDistance" values={{ value: (Math.round(group.distance) / 1000).toFixed(3) }} data-tip={group.address} />
              </p>
              <ReactTooltip border borderColor="#bbb" effect="solid" place="right" type="light" style={{ border: '1px solid #bbb' }} />
            </>
          }
        </div >
        {group.vehicle && group.vehicleIdentifier && (
          <>{' - '}<VehicleIcon type={group.vehicle} /> {' - '} {group.vehicleIdentifier}</>
        )}
      </div >
    )
  }

  const error = interventionError || planningsUsersError
  if (error) {
    return <>
      <NavLink tag={Link} to={`/swaps/${routeParams.id}`}><T id="returnToDetails" /></NavLink>
      <ErrAlert error={error} />
    </>
  }

  return (
    <div className="container-fluid SwapPlan">

      <NavLink tag={Link} to={`/swaps/${routeParams.id}`}><T id="returnToDetails" /></NavLink>

      <span className="mb-2 d-block">
        <span className="h5 text-muted"><T id="title" /></span>
        <span className="h4">{` ${routeParams.id}`}</span>
        {interventionLoad && <Spinner className="h6 ml-2" animation="border" role="status" size="sm"></Spinner>}
      </span>

      <Row>
        <Col>
          <FormGroup tag="fieldset">
            <Label for="selectHour"><T id="selectHour" /></Label>
            <CustomSelect
              inputId="selectHour"
              name="selectHour"
              options={itvHours}
              placeholder={"0"}
              onChange={e => handleItvHoursChange({ target: { value: e } })}
              value={itvHours.filter(h => h == itvHourSelected)}
              getOptionValue={option => option}
              getOptionLabel={option => option}
              isDisabled={interventionLoad} />
          </FormGroup>
        </Col>

        <Col>
          <FormGroup tag="fieldset">
            <Label for="selectMinute"><T id="selectMinute" /></Label>
            <CustomSelect
              inputId="selectMinute"
              name="selectMinute"
              options={itvMinutes}
              onChange={e => handleItvMinutesChange({ target: { value: e } })}
              placeholder={"0"}
              value={itvMinutes.filter(h => h == itvMinuteSelected)}
              getOptionValue={option => option}
              getOptionLabel={option => option}
              isDisabled={interventionLoad} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <ErrAlert error={durationError} isOpen={Boolean(durationError)} toggle={() => setDurationError()} />
        </Col>
      </Row>
      <Row>
        <Col>
          <fieldset className="mb-4">
            <TLabel id="preferredTimeRange.label" className="" tagName="div" />
            <TCustomInput
              id="preferredTimeRange"
              className="mb-2"
              type="checkbox"
              name="disable"
              label="preferredTimeRange.disable"
              checked={disableTimeRange}
              disabled={interventionLoad}
              onChange={handleTimeRangeDisable}
              inline />
            {!disableTimeRange && preferredTimeRange.length > 0 && <T id="preferredTimeRange.value" tagName="div" values={{
              timeStart: new Date().setHours(0, Math.min(...preferredTimeRange), 0, 0),
              timeEnd: new Date().setHours(0, Math.max(...preferredTimeRange), 0, 0)
            }} />}
            {disableTimeRange && <T id="preferredTimeRange.noValue" tagName="div" />}
            <Slider
              name="preferredTimeRange"
              values={preferredTimeRange}
              onChange={handleTimeRange}
              disabled={interventionLoad || disableTimeRange}
              {...preferredTimeRangeOptions} />
            <FormText><T id="preferredTimeRange.disclaimer" /></FormText>
          </fieldset>
        </Col>
      </Row>

      {scheduleError && (
        <Modal isOpen={Boolean(scheduleError)} toggle={() => setScheduleError()}>
          <ModalBody>
            {scheduleError.code === 'preventUpdate'
              ? <T id={`error.${scheduleError.code}`} values={scheduleError.params} />
              : <ErrAlert error={scheduleError} className="mb-0" />}
            {scheduleError.params && scheduleError.params.reasons && (
              <ListGroup>
                {scheduleError.params.reasons.map(reason => (
                  <ListGroupItem key={reason}><T id={`error.preventUpdate.${reason}`} /></ListGroupItem>
                ))}
              </ListGroup>
            )}
          </ModalBody>
          <ModalFooter className="py-2">
            <TButton id="scheduleError.dismiss" onClick={() => setScheduleError()} />
            {scheduleError.code === 'preventUpdate' && <TButton id="scheduleError.force" color="danger" onClick={() => schedule({
              ...scheduleError.data,
              force: scheduleError.params.reasons,
              forceAddressTechcenter: scheduleError.forceAddressTechcenter
            }).then(() => setTechenterScheduleModal(false))} />}
          </ModalFooter>
        </Modal>
      )}

      <Modal isOpen={Boolean(techenterScheduleModal)} toggle={() => setScheduleError()}>
        <ModalBody>
          <T id="forceTechcenterSchedule" />
        </ModalBody>
        <ModalFooter className="py-2">
          <TButton id="forceTechcenterSchedule.2" color="danger" onClick={() => setTechenterScheduleModal(false)} />
          <TButton id="forceTechcenterSchedule.0" color="success" onClick={() => schedule({
            ...techenterScheduleData,
            forceAddressTechcenter: 0
          }).catch(error => setScheduleError({ ...error, data: techenterScheduleData, forceAddressTechcenter: 0 }))
            .then(() => setScheduleLoad(false))
            .then(() => setTechenterScheduleModal(false))} />
          <TButton id="forceTechcenterSchedule.1" color="warning" onClick={() => schedule({
            ...techenterScheduleData,
            forceAddressTechcenter: 1
          }).catch(error => setScheduleError({ ...error, data: techenterScheduleData, forceAddressTechcenter: 1 }))
            .then(() => setScheduleLoad(false))
            .then(() => setTechenterScheduleModal(false))} />
        </ModalFooter>
      </Modal>

      <div className="d-flex align-items-center mb-3">
        <div className="position-relative d-flex">
          <i className="btn btn-secondary simple-icon-arrow-left px-2 mr-1"
            onClick={() => { handleDateChange(new Date(dateSelected.getTime() - (3600 * 1000 * 24))) }}
          />
          <TDatePicker
            disabled={scheduleLoad || unScheduleLoad || interventionLoad}
            customInput={<SlotSelector className="date-fixed" />}
            selected={dateSelected}
            onChange={handleDateChange}
            // TODO: all DatePicker dateFormat shoudl be in translation. Maybe event a custom DatPicker component for that
            dateFormat="eeee dd/MM/yyyy"
            minDate={new Date()}
          />
          <i className="btn btn-secondary simple-icon-arrow-right px-2 ml-1"
            onClick={() => { handleDateChange(new Date(dateSelected.getTime() + (3600 * 1000 * 24))) }}
          />
        </div>

        {planningsUsers &&
          <InputGroup className="mx-3 flex-nowrap w-auto">
            <TInput type="text"
              id="searchUser"
              name="searchUser"
              style={{ minWidth: 150 }}
              value={searchUser}
              autoComplete="off"
              onChange={e => filterUsers(e.target.value)}
              placeholder="searchUser" />
            <CustomSelect
              id="userTypeId"
              onChange={e => handleUserTypeId(e.value)}
              isSearchable={false}
              name="userTypeId"
              options={[{ value: 0, key: 'all' }].concat(constants.userTypes)}
              value={constants.userTypes.find(_userType => _userType.value === Number(planningsUsers.filters.userTypeId.selected.value)) || { value: 0, key: 'all' }}
              isDisabled={scheduleLoad || unScheduleLoad || interventionLoad}
              getOptionLabel={option => <T raw id={`userType.${option.key}`} />} />
            <CustomSelect
              id="selectCompany"
              onChange={e => handleCompanyId(e.value)}
              isSearchable={false}
              name="selectCompany"
              options={planningsUsers.filters.companyId.values}
              value={planningsUsers.filters.companyId.selected}
              isDisabled={scheduleLoad || unScheduleLoad || interventionLoad}
              getOptionLabel={option => option.value == 0 ? <T raw id={`companyId.${option.value}`} /> : <>{option.label}</>} />
          </InputGroup>
        }
      </div>

      <div className="d-flex align-items-center mb-3">
        {intervention && intervention.datePlanned && <div className="mr-3">
          <span>
            <T id="datePlanned"
              className="d-inline-block py-2 mr-2"
              values={{
                date: intl.formatDate(intervention.datePlanned),
                time: intl.formatTime(intervention.datePlanned)
              }} />
            <T id="datePlannedAddress" />{' '}{intervention.addressFull}
            <T className="ml-2" tagName={Button} onClick={handleItvDate} id="returnToItvDate" />
          </span>
        </div>}
        {intervention && !intervention.datePlanned && <>
          <span>
            <T id="noDatePlanned" />{' '}<T id="datePlannedAddress" />{` ${intervention.addressFull}`}
          </span>
        </>}

        <TProps className="ml-auto"
          tagName="div"
          translateProps={{ title: 'helpTitle' }}
          onClick={handleHelpClick}>
          <i className="simple-icon-question h4 mb-0 text-muted" />
        </TProps>
        <Modal isOpen={isHelpOpen} toggle={handleHelpToggle}>
          <T tagName={ModalHeader} id="helpHeader" />
          <T tagName={ModalBody} style={{ whiteSpace: 'pre-wrap' }} id="helpContent" />
          <ModalFooter className="py-3">
            <Button color="primary" onClick={handleHelpToggle}><T id="helpOk" /></Button>
          </ModalFooter>
        </Modal>
      </div>

      {alertMax && <T id="alertMax" className="mb-2 d-block" values={{ count: alertMax.count, max: alertMax.max }} />}

      {!planningsUsersLoad ? (
        <>
          {planningGroups && planningGroups.length > 0 ? (
            <Timeline
              groups={planningGroups}
              groupRenderer={SidebarItem}
              items={planningItems}
              direction="horizontal"
              itemHeightRatio={1}
              lineHeight={30}
              sidebarWidth={300}
              duration={timeEndWork - timeStartWork}
              defaultTimeStart={dateStart}
              defaultTimeEnd={dateEnd}
              onCanvasDoubleClick={handleTimelineDoubleClick}
              onCanvasDrop={handleInterventionDrop}
              itemRenderer={PlanningItem}
              customHeight={45} />
          ) : (
            <TAlert color="warning" id="noData" />
          )}
        </>
      ) : (
        <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />
      )}

      <ErrAlert error={unScheduleError} isOpen={Boolean(unScheduleError)} toggle={() => setUnScheduleError()} className="mt-3 mb-0" />
      <ErrAlert error={alertMaxError} isOpen={Boolean(alertMaxError)} toggle={() => setAlertMaxError()} className="mt-3 mb-0" />

      {modalAlert && (
        <Modal isOpen={modalAlert} toggle={() => {
          setModalAlert(false)
          setScheduleLoad(false)
        }}>
          <ModalBody>
            <T tagName={ModalBody} style={{ whiteSpace: 'pre-wrap' }} id="alertModal.content" />
          </ModalBody>
          <ModalFooter className="py-2">
            <TButton
              id="alertModal.ok"
              onClick={() => {
                schedule(scheduleData, 1)
                  .catch(error => setScheduleError({ ...error, scheduleData }))
                  .then(() => {
                    setTechenterScheduleModal(false)
                    setScheduleLoad(false)
                    setModalAlert(false)
                  })
              }} />
            <TButton id="alertModal.nok" onClick={() => {
              setModalAlert(false)
              setScheduleLoad(false)
            }} />
          </ModalFooter>
        </Modal>
      )}

      <Modal isOpen={Boolean(remindState.error)} toggle={() => remindDispatch({ type: 'init' })}>
        <ModalBody>
          {remindState.error && <ErrAlert error={remindState.error} className="mb-0" />}
        </ModalBody>
        <ModalFooter className="py-2">
          <TButton id="remind.popup.dismiss" onClick={() => remindDispatch({ type: 'init' })} />
        </ModalFooter>
      </Modal>

      <div className="d-flex mt-3 align-items-start">
        <ProtectedComponent rights={['swaps_remind',]}>
          <div>
            <TButton
              id="remind.action"
              disabled={scheduleLoad || unScheduleLoad || interventionLoad || !intervention || !intervention.datePlanned || remindState.loading}
              spin={remindState.loading}
              color="secondary"
              onClick={() => remind()} />
            {intervention && intervention.dateReminderSent && <FormText><T id="remind.date" values={{ date: new Date(intervention.dateReminderSent) }} /></FormText>}
          </div>
        </ProtectedComponent>
        <div className="ml-auto pl-2">
          <TButton
            id="unschedule"
            disabled={scheduleLoad || unScheduleLoad || interventionLoad || !intervention || !intervention.datePlanned}
            color="danger"
            onClick={() => unschedule()} />
        </div>
      </div>
    </div >
  )
}

export default SwapPlan
