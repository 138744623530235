import React from 'react'
import { useIntl } from 'react-intl'
import { useRouteMatch } from 'react-router-dom'

export const useFormatMessage = () => {
  const { formatMessage } = useIntl()
  const { path } = useRouteMatch()

  return React.useCallback((descriptor, values) => formatMessage(
    {
      ...descriptor,
      id: descriptor.raw ? descriptor.id : `${path}.${descriptor.id}`
    },  values)
  , [formatMessage, path])
}

export default useFormatMessage
