import React from 'react'

import { CustomInput } from 'reactstrap'

import './Slider.scss'

const Slider = ({
  name = 'default',
  id = 'default',
  min =  0,
  max = 10,
  step = 1,
  disabled,
  values,
  onChange = () => {}
}) => {
  const handleChange = React.useCallback((i, newValue) => onChange({ target: { name, value: values.map((value, j) => i === j ? newValue : value) } }), [name, onChange, values])

  return (
    <section className="VSlider custom-range">
      {values.map((value, i) => (
        <CustomInput key={`Slider-input-${i}`} id={`${id}-value-${i}`} value={value} onChange={e => handleChange(i, Number(e.target.value))} min={min} max={max} step={step} type="range" disabled={disabled} />
      ))}
    </section>
  )
}

export default Slider