/* eslint-disable react/display-name */
import React from 'react'
import { useLocation, useHistory, NavLink } from 'react-router-dom'
import { objectToQuery, queryToObject } from 'react-rest-api'

import {
  Card, CardHeader,
} from 'reactstrap'

import { useGet } from 'hooks/useApi.hooks'
import { useFormatMessage } from 'hooks/intl.hooks'

import { ErrAlert, TButton } from 'components/TComponents'
import { Table } from 'components/Table'
import { Filters } from 'components/Filters'
import ProtectedComponent from "components/ProtectedComponent"

const MaintenancePlanList = () => {
  const location = useLocation()
  const history = useHistory()
  const formatMessage = useFormatMessage()

  const filters = React.useMemo(() => ({
    p: {
      type: 'number'
    },
    ipp: {
      type: 'number'
    },
    'order[column]': {},
    'order[dir]': {},
    filter: {
      type: 'string',
      componentType: 'textDebounce'
    },
    businessId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isSearchable: true,
        getOptionValue: option => option.label,
        isMulti: false
      },
    },
    categoryId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false
      },
    },
    familyId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false
      },
    },
    statusId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: true,
        getOptionLabel: option => formatMessage({ id: `maintenanceStatus.${option.label}`, raw: true })
      }
    },
    addressId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false,
      },
    },
  }), [formatMessage])

  const params = React.useMemo(() => queryToObject(location.search, filters), [location.search, filters])

  const [data, refreshing, error, loading] = useGet(`/masterplans/list`, undefined, params)

  const refresh = React.useCallback((_params = {}) => history.push({ search: objectToQuery({ ...params, ..._params }) }), [history, params])

  const renderId = React.useCallback(item => {
    const color = ((value) => {
      switch (value) {
      case 'easy': return 'success'
      case 'medium': return 'warning'
      case 'hard': return 'danger'
      default: return ''
      }
    })(item.difficultyValue)

    return <h6 className="mb-0"><span className={`badge badge-${color}`}>{item.masterplanId}</span></h6>
  }, [])

  const renderCategories = React.useCallback(item => {
    if (item.categories.length > 0) {
      const filteredCategories = item.categories.filter((value, index) => {
        const _value = JSON.stringify(value);
        return index === item.categories.findIndex(obj => {
          return JSON.stringify(obj) === _value;
        });
      });
      return(filteredCategories.map((c, i) => (
        i + 1 < filteredCategories.length ? c.categoryName + ' / ' : c.categoryName
      )))
    } else {
      return(item.categories.categoryName)
    }
  }, [])

  const renderFamilies = React.useCallback(item => {
    if (item.families.length > 0) {
      const filteredFamilies = item.families.filter((value, index) => {
        const _value = JSON.stringify(value);
        return index === item.families.findIndex(obj => {
          return JSON.stringify(obj) === _value;
        });
      });
      return(filteredFamilies.map((c, i) => (
        i + 1 < filteredFamilies.length ? c.familyName + ' / ' : c.familyName 
      )))
    } else {
      return(item.families.familyName)
    }
  }, [])

  const DetailsButton = React.useCallback(item => (
    <TButton className="ml-auto stretched-link" outline
      id="details"
      tag={NavLink}
      to={{
        pathname: `./maintenanceplan/${item.masterplanId}`,
        state: { interventions: location.search }
      }}
    />
  ), [location])

  if (error) {
    return <ErrAlert error={error} />
  }

  return (
    <div className="container-fluid">
      <Card className="mb-2">
        <CardHeader>
          <Filters
            loading={loading}
            disabled={refreshing}
            data={data}
            refresh={refresh}
            filters={filters}
          />
        </CardHeader>
        <CardHeader className="d-flex">
          <ProtectedComponent rights={['masterplan-edit']}>
            <TButton tag={NavLink}
              id="create"
              to={{
                pathname: './maintenanceplan/new',
                state: { interventions: location.search }
              }} />
          </ProtectedComponent>
        </CardHeader>
      </Card>
      <Table
        isLoading={loading}
        disabled={refreshing}
        data={data}
        refresh={refresh}
        columns={[
          {
            title: 'masterplanId',
            hasOrder: true,
            render: renderId
          },
          {
            title: 'businessName',
            render: item => item.businessName
          },
          {
            title: 'categoryName',
            render: renderCategories
          },
          {
            title: 'familyName',
            render: renderFamilies
          },
          {
            title: 'addressLabel',
            render: item => item.addressLabel
          },
          {
            title: 'nbEquipments',
            render: item => item.nbEquipments
          },
          {
            title: 'nbEquipmentsRemind',
            render: item => item.nbEquipmentsRemind
          },
          {
            title: 'statusValue',
            render: item => formatMessage({ id: `maintenanceStatus.${item.statusValue}`, raw: true })
          },
          {
            className: () => 'align-middle',
            render: DetailsButton
          },
        ]}
      />
    </div>
  )
}

export default MaintenancePlanList
