import React from 'react'

import {
  Card, CardBody, CardHeader, CardFooter,
  Form,
  FormGroup,
  Spinner,
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import CustomSelect from 'components/CustomSelect'
import ProtectedComponent from 'components/ProtectedComponent'
import {
  T, TLabel, TButton, TInput, ErrAlert, TCustomInput
} from 'components/TComponents'

import Profile from './ProfileItem'

const ProfileList = () => {
  const { api } = React.useContext(AppContext)

  const [profiles, setProfiles] = React.useState()
  const [filteredProfiles, setFilteredProfiles] = React.useState()
  const [profilesByBases, setProfilesByBases] = React.useState()
  const [rights, setRights] = React.useState()
  const [bases, setBases] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()

  const [creating, setCreating] = React.useState(false)
  const [createError, setCreateError] = React.useState()

  const [filter, setFilter] = React.useState('')
  const [newProfile, setNewProfile] = React.useState()
  const [archived, setArchived] = React.useState(false)

  React.useEffect(() => {
    Promise
      .all([
        api.get('/rights/list'),
        api.get('/bases/list', undefined, { ipp: -1 }),
        new Promise(resolve => setTimeout(resolve, 250))
      ])
      .then(([rights, bases]) => {
        setRights(rights)
        setBases(bases)
      })
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api])

  React.useEffect(() => {
    if (profiles) {
      setFilteredProfiles(filter ? profiles.filter(profile => profile.nameProfile.toLowerCase().indexOf(filter.toLowerCase()) !== -1) : profiles)
    }
  }, [filter, profiles])

  React.useEffect(() => {
    if (filteredProfiles && bases && bases.result && bases.result.length > 0) {
      setProfilesByBases(bases.result.map(base => {
        const tempBase = base
        tempBase.profiles = []
        filteredProfiles.filter(profile => profile.baseId === base.baseId).map(p => tempBase.profiles.push(p))
        return tempBase
      }))
    }
  }, [filteredProfiles, bases])

  React.useEffect(() => {
    api.get('/rights/profiles', undefined, {
      archived
    })
      .then(response => setProfiles(response))
  }, [api, archived])

  const handleNewProfileChange = ({ target: { name, value } }) => {
    setNewProfile(profile => ({
      ...profile,
      [name]: value
    }))
  }

  const handleNewProfileRightClick = React.useCallback((e) => {
    const { target: { checked, name: right } } = e

    if (right.endsWith('_edit') && checked) {
      const rightView = `${right.slice(0, -5)}_view`
      if ('undefined' !== typeof newProfile.rights[rightView]) {
        return setNewProfile(profile => ({
          ...profile,
          rights: {
            ...profile.rights,
            [right]: true,
            [rightView]: true
          }
        }))
      }
    }

    if (right.endsWith('_view') && !checked) {
      const rightEdit = `${right.slice(0, -5)}_edit`
      if ('undefined' !== typeof newProfile.rights[rightEdit]) {
        return setNewProfile(profile => ({
          ...profile,
          rights: {
            ...profile.rights,
            [right]: false,
            [rightEdit]: false
          }
        }))
      }
    }

    setNewProfile(profile => ({
      ...profile,
      rights: {
        ...profile.rights,
        [right]: checked
      }
    }))
  }, [newProfile])

  const handleCreateProfile = (e) => {
    e.preventDefault()

    setCreating(true)
    setCreateError()
    api.post('/rights/profile', {
      body: JSON.stringify({
        ...newProfile,
        rights: rights.reduce((acc, right) =>
          Object.assign(acc, { [right]: newProfile.rights[right] || false }), {})
      })
    })
      .then(response => {
        setProfiles(profiles => profiles.concat(response))
        setNewProfile()
      })
      .catch(error => setCreateError(error))
      .then(() => setCreating(false))
  }

  const openNewProfile = () => {
    setNewProfile({
      nameProfile: '',
      baseId: '',
      rights: rights.reduce((acc, right) => Object.assign(acc, { [right]: false }), {})
    })
  }

  if (loading) {
    return <Spinner color="primary" className="d-flex ml-auto mr-auto" />
  }

  if (error) {
    return <ErrAlert error={error} />
  }

  return (
    <>
      {newProfile && <a className="nav-link" href="#" onClick={() => setNewProfile()}><T id="returnToList" /></a>}
      <Card className="mb-2">
        {!newProfile && <CardHeader className="pb-0">
          <FormGroup className="flex-grow-1 pl-0">
            <TLabel className="sr-only" for="profilesFilter" id="profilesFilterTitle" />
            <TInput
              id="profilesFilter"
              name="profilesFilter"
              type="text"
              readOnly={creating}
              placeholder="profilesFilterPlaceholder"
              value={filter || ''}
              onChange={e => setFilter(e.target.value)} />
          </FormGroup>
          <ProtectedComponent rights={['admin_archive']}>
            <FormGroup className="flex-grow-1 pl-0">
              <TCustomInput type="switch"
                id="archived"
                name="archived"
                label="archived"
                className="w-100 bg-transparent border-0"
                checked={archived}
                onChange={e => setArchived(e.target.checked)} />
            </FormGroup>
          </ProtectedComponent>
        </CardHeader>}
        {!newProfile && <ProtectedComponent rights={['admin-profiles_edit']}>
          <CardHeader className="d-flex align-items-center">
            <TButton id="createProfileButton" type="button"
              disabled={creating}
              onClick={openNewProfile} />
          </CardHeader>
        </ProtectedComponent>}

        {newProfile && <Form onSubmit={event => handleCreateProfile(event)}>
          <CardBody>
            <h2><T id="newProfileTitle" /></h2>
            <FormGroup tag="fieldset">
              <TLabel for="newProfileName" id="newProfileNameTitle" />
              <TInput type="text"
                id="newProfileName"
                name="nameProfile"
                value={newProfile.nameProfile}
                onChange={handleNewProfileChange}
                placeholder="newProfileNamePlaceholder" />
            </FormGroup>

            <FormGroup tag="fieldset">
              <TLabel for="newProfileBase" id="newProfileBaseLabel" />
              <CustomSelect
                inputId="newProfileBase"
                name="baseId"
                onChange={e => { handleNewProfileChange({ target: { name: 'baseId', value: e.baseId } }) }}
                options={bases.result}
                value={bases.result.filter(r => r.baseId === newProfile.baseId)}
                getOptionLabel={option => option.baseName}
                getOptionValue={option => option.baseId}
                placeholder={<T id="profileBaseSelect" />} />
            </FormGroup>

            <TLabel id="rightsLabel" />
            {Object.entries(newProfile.rights).map(right =>
              <FormGroup key={right[0]} tag="fieldset" className="pl-0" check>
                <TCustomInput type="checkbox"
                  id={'newProfile.' + right[0]}
                  name={right[0]}
                  checked={right[1]}
                  onChange={handleNewProfileRightClick}
                  raw label={'right.' + right[0]} />
              </FormGroup>
            )}
            {createError && <ErrAlert className="mt-2 mb-0" error={createError} />}
          </CardBody>
          <CardFooter className="d-flex justify-content-end">
            <TButton id="newProfileCancelButton"
              type="button"
              disabled={creating}
              onClick={() => setNewProfile()} />
            <TButton id="newProfileValidButton"
              type="submit"
              className="ml-2"
              color="primary"
              disabled={creating} />
          </CardFooter>
        </Form>}
      </Card>

      {
        !newProfile && profilesByBases && profilesByBases.length > 0 && <div className="mb-4">
          {profilesByBases.map(base =>
            <>
              {base.profiles.length > 0 && (
                <>
                  <div className='h6 mt-4 mb-4'>{base.baseName}</div>
                  {base.profiles.map(profile =>
                    <Profile key={profile.profileId} bases={bases} profile={profile} className="mb-2" />
                  )}
                </>
              )}
            </>
          )}

        </div>
      }
    </>
  )
}

export default ProfileList
