import React from 'react'
import { useHistory, useParams, Link, } from 'react-router-dom'

import {
  Alert,
  Card, CardBody, CardHeader, CardFooter,
  Form, FormGroup, Input, Label,
  Modal, ModalBody, ModalFooter,
  ListGroup, ListGroupItem,
  Row, Col,
  Spinner
} from 'reactstrap'

import { AppContext, } from 'contexts/AppContext'
import CustomSelect from 'components/CustomSelect'

import ProtectedComponent from 'components/ProtectedComponent'
import { T, TButton, TLabel, TCustomInput, TInput, TNavLink, ErrAlert } from 'components/TComponents'

import { debounce } from 'util/Utils'

const ItvcodelistEdit = () => {
  const { api } = React.useContext(AppContext)

  const history = useHistory()
  const routeParams = useParams()

  const [itvcodelist, setItvcodelist] = React.useState()

  const [itvcodelistLoading, setItvcodelistLoading] = React.useState(true)
  const [itvcodelistError, setItvcodelistError] = React.useState()
  const [itvcodelistUpdating, setItvcodelistUpdating] = React.useState()
  const [itvcodelistUpdateError, setItvcodelistUpdateError] = React.useState()
  const [itvcodelistDeleting, setItvcodelistDeleting] = React.useState()
  const [itvcodelistDeleteError, setItvcodelistDeleteError] = React.useState()

  const [itvcodesBase, setItvcodesBase] = React.useState()
  const [itvcodesFiltered, setItvcodesFiltered] = React.useState({})
  const [itvcodesError, setItvcodesError] = React.useState()
  const [itvcodesLoading, setItvcodesLoading] = React.useState(true)

  const [itvcodelistLabel, setItvcodelistLabel] = React.useState('')
  const [itvcodes, setItvcodes] = React.useState([])
  const [selectButtons, setSelectButtons] = React.useState([])

  const [edit, setEdit] = React.useState(false)
  const [removePopup, setRemovePopup] = React.useState(false)

  const [duplicateModal, setDuplicateModal] = React.useState(false)
  const [duplicateItvcodelistError, setDuplicateItvcodelistError] = React.useState(false)
  const [duplicateItvcodelistLoading, setDuplicateItvcodelistLoading] = React.useState()

  const [searchInput, setSearchInput] = React.useState('')

  React.useEffect(() => {
    Promise.all([
      api.get('/itvcodelists/details', undefined, { itvcodelistId: routeParams.id }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => setItvcodelist(removeDuplicate(data)))
      .catch(error => setItvcodelistError(error))
      .then(() => setItvcodelistLoading(false))

    api.get('/itvcodes/list', undefined, { ipp: -1 })
      .then(data => setItvcodesBase(data))
      .catch(error => setItvcodesError(error))
      .then(() => setItvcodesLoading(false))
  }, [api, routeParams])

  React.useEffect(() => {
    if (itvcodelist) {
      setItvcodelistLabel(itvcodelist.itvcodelistLabel)
      setItvcodes(itvcodelist.itvcodes.map(itvcode => itvcode.itvcodeId))
    }
  }, [itvcodelist])

  React.useEffect(() => {
    const codesFiltered = {}
    const buttons = []
    itvcodesBase && itvcodesBase.result.map(code => {
      if (!codesFiltered[code.categoryId]) {
        codesFiltered[code.categoryId] = {}
        codesFiltered[code.categoryId].categoryName = code.categoryName
      }
      if (!codesFiltered[code.categoryId][code.familyId]) {
        codesFiltered[code.categoryId][code.familyId] = {}
        codesFiltered[code.categoryId][code.familyId].familyName = code.familyName
        codesFiltered[code.categoryId][code.familyId].results = []
        buttons.push({
          'category': code.categoryName,
          'family': code.familyName,
          'value': 0,
        })
        setSelectButtons(buttons)
      }
      codesFiltered[code.categoryId][code.familyId].results.push(code)
    })
    setItvcodesFiltered(codesFiltered)
  }, [itvcodesBase])

  const check = React.useCallback(({ target: { checked, name } }) => {
    setItvcodes(itvcodes => checked
      ? [...itvcodes, Number(name)]
      : itvcodes.filter(itvcodeIt => itvcodeIt !== Number(name))
    )
  }, [])

  const checkAll = React.useCallback(({ category, family, check }) => {
    const codesToSet = []
    itvcodesFiltered && Object.values(itvcodesFiltered).length > 0 && Object.values(itvcodesFiltered).map(itvcodeCategory => {
      if (itvcodeCategory.categoryName === category) {
        Object.values(itvcodeCategory).length > 0 && Object.values(itvcodeCategory).map(itvcodeFamily => {
          if (itvcodeFamily.familyName === family) {
            itvcodeFamily.results.map(itvcode => {
              if (check === 0 && !itvcodes.includes(itvcode.itvcodeId)) {
                codesToSet.push(itvcode.itvcodeId)
              }
              if (check === 1 && itvcodes.includes(itvcode.itvcodeId)) {
                codesToSet.push(itvcode.itvcodeId)
              }
            })
          }
        })
      }
    })[0]
    if (check === 0) {
      setSelectButtons(selectButtons => selectButtons.filter(b => b.category !== category || b.family !== family))
      setSelectButtons(selectButtons => [...selectButtons, {
        'category': category,
        'family': family,
        'value': 1,
      }])
      setItvcodes(itvcodes => [...itvcodes, ...codesToSet])
    }
    if (check === 1) {
      setSelectButtons(selectButtons => selectButtons.filter(b => b.category !== category || b.family !== family))
      setSelectButtons(selectButtons => [...selectButtons, {
        'category': category,
        'family': family,
        'value': 0,
      }])
      setItvcodes(itvcodes => itvcodes.filter(i => !codesToSet.includes(i)))

    }
  }, [itvcodesFiltered, itvcodes])

  const removeDuplicate = data => {
    if (data.itvcodes && data.itvcodes.length > 0) {
      const itvcodes = (data.itvcodes.reduce((acc, current) => {
        const x = acc.find(item => item.itvcodeId === current.itvcodeId)
        if (!x) {
          return acc.concat([current])
        } else {
          return acc
        }
      }, []))
      data.itvcodes = itvcodes
    }
    return data
  }

  const duplicate = React.useCallback(() => {
    setDuplicateItvcodelistLoading(true)
    setDuplicateItvcodelistError()
    api.post('/itvcodelists/duplicate', { body: JSON.stringify({ itvcodelistId: routeParams.id }) })
      .then(response => {
        setDuplicateModal(false)
        setDuplicateItvcodelistLoading(false)
        setDuplicateItvcodelistError()
        history.push(`/admin/itvcodelists/${response.itvcodelistId}`)
      })
      .catch(error => {
        setDuplicateItvcodelistLoading(false)
        setDuplicateItvcodelistError(error)
      })
  }, [api, history, routeParams])

  const save = React.useCallback(() => {
    setItvcodelistUpdating(true)
    api.post('/itvcodelists/details', {
      body: JSON.stringify({
        itvcodelistId: routeParams.id,

        itvcodelistLabel, itvcodes,
      })
    })
      .then(() => setEdit(false))
      .catch(error => setItvcodelistUpdateError(error))
      .then(() => setItvcodelistUpdating(false))
  }, [api, routeParams, itvcodelistLabel, itvcodes])

  const cancel = React.useCallback(() => {
    setItvcodelistLabel(itvcodelist.itvcodelistLabel)
    setItvcodes(itvcodelist.itvcodes.map(itvcode => itvcode.itvcodeId))
    setEdit(false)
  }, [itvcodelist])

  const remove = React.useCallback(() => {
    setItvcodelistDeleting(true)
    setItvcodelistDeleteError()
    api.del('/itvcodelists/details', undefined, { itvcodelistId: routeParams.id })
      .then(() => history.push(`/admin/itvcodelists`))
      .catch(error => {
        setItvcodelistDeleting(false)
        setItvcodelistDeleteError(error)
      })
  }, [api, routeParams, history])

  const refresh = React.useCallback((params) => {
    setItvcodesLoading(true)
    setItvcodesError()
    Promise.all([
      api.get('/itvcodes/list', undefined, {
        ipp: -1,
        filter: itvcodesBase.filters.filter,
        categoryId: itvcodesBase.filters.categories.selected,
        familyId: itvcodesBase.filters.families.selected,
        ...params
      }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([itvCodeGroups]) => setItvcodesBase(itvCodeGroups))
      .catch(error => setItvcodesError(error))
      .then(() => setItvcodesLoading(false))
  }, [api, itvcodesBase])

  const refreshDebounced = React.useMemo(() => debounce(params => refresh(params), 250), [refresh])

  const handleSearchInput = React.useCallback(({ target: { value } }) => {
    setSearchInput(value)
    refreshDebounced({ filter: value, p: 1 })
  }, [refreshDebounced])

  if (!itvcodelist || itvcodelistLoading) {
    return <>
      <TNavLink tag={Link} to="/admin/itvcodelists" id="returnToList" />
      <Spinner className="d-flex ml-auto mr-auto" color="primary" />
    </>
  }

  if (itvcodelistError) {
    return <>
      <TNavLink tag={Link} to="/admin/itvcodelists" id="returnToList" />
      <ErrAlert error={itvcodelistError} />
    </>
  }

  return (
    <>
      <TNavLink tag={Link} to="/admin/itvcodelists" id="returnToList" />
      <Card className="mb-2" tag={Form} onSubmit={e => e.preventDefault()}>
        <CardHeader tag="h2">
          <span className="d-block mb-2">{itvcodelist.itvcodelistLabel}</span>
          <Form onSubmit={e => e.preventDefault()}>
            <Row form>
              <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel for="searchInput" id="searchInputLabel" />
                  <TInput
                    name="searchInput"
                    type="text"
                    placeholder="searchInputPlaceholder"
                    value={searchInput}
                    onChange={handleSearchInput} />
                </FormGroup>
              </Col>
              {itvcodesBase && itvcodesBase.filters && itvcodesBase.filters.categories && itvcodesBase.filters.categories.values && <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel for="categoryId" id="categoryLabel" />
                  <CustomSelect
                    inputId="categoryId"
                    name="categoryId"
                    options={itvcodesBase.filters.categories.values}
                    onChange={e => refresh({ categoryId: e && e.categoryId })}
                    isClearable
                    placeholder={<T id="categoryPlaceholder" />}
                    value={itvcodesBase.filters.categories.selected && itvcodesBase.filters.categories.values.filter(c => c.categoryId == itvcodesBase.filters.categories.selected)}
                    getOptionLabel={option => option.categoryName}
                    getOptionValue={option => option.categoryId}
                    isDisabled={itvcodesLoading} />
                </FormGroup>
              </Col>}
              {itvcodesBase && itvcodesBase.filters && itvcodesBase.filters.families && itvcodesBase.filters.families.values && <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel for="familyId" id="familyLabel" />
                  <CustomSelect
                    inputId="familyId"
                    name="familyId"
                    options={itvcodesBase.filters.families.values}
                    onChange={e => refresh({ familyId: e && e.familyId })}
                    isClearable
                    placeholder={<T id="familyPlaceholder" />}
                    value={itvcodesBase.filters.families.selected && itvcodesBase.filters.families.values.filter(f => f.familyId == itvcodesBase.filters.families.selected)}
                    getOptionLabel={option => option.familyName}
                    getOptionValue={option => option.familyId}
                    isDisabled={itvcodesLoading} />
                </FormGroup>
              </Col>}
            </Row>
          </Form>
        </CardHeader>
        <CardBody>
          <FormGroup tag="fieldset">
            <TLabel for="itvcodelistLabel" id="itvcodelistLabel" />
            <Input id="itvcodelistLabel"
              type="text"
              name="itvcodelistLabel"
              disabled={!edit}
              value={itvcodelistLabel}
              onChange={e => setItvcodelistLabel(e.target.value)} />
          </FormGroup>

          <TLabel id="itvcodesLabel" />
          {itvcodesError && <ErrAlert error={itvcodesError} className="mb-0" />}
          {itvcodesLoading && <Spinner className="d-flex" />}
          {!itvcodesLoading && itvcodesFiltered && Object.values(itvcodesFiltered).map(categoryCode =>
            <>
              <Label className="mt-4 mb-2"><T id="itvcodelist.category" /> : {categoryCode.categoryName}</Label>
              {Object.values(categoryCode).map(familyCode =>
                <>
                  {familyCode.familyName && (
                    <div className="d-flex">
                      <span className="mb-2 mt-2 d-block">
                        <Label>
                          <T id="itvcodelist.family" /> : {familyCode.familyName}
                        </Label>
                      </span>
                      {edit && (
                        <TButton
                          id={selectButtons.filter(b => b.family === familyCode.familyName && b.category === categoryCode.categoryName)[0].value === 1 ? 'deselect' : 'select'}
                          className="ml-4 mb-2"
                          outline
                          disabled={!edit}
                          onClick={() => {
                            selectButtons.filter(b => b.family === familyCode.familyName && b.category === categoryCode.categoryName)[0].value === 1
                              ? checkAll({ category: categoryCode.categoryName, family: familyCode.familyName, check: 1 })
                              : checkAll({ category: categoryCode.categoryName, family: familyCode.familyName, check: 0 })
                          }} />
                      )}
                    </div>
                  )}
                  {familyCode.results && familyCode.results.map(itvcode =>
                    <FormGroup key={itvcode.itvcodeId} tag="fieldset" check>
                      <TCustomInput
                        id={'itvcode.' + itvcode.itvcodeId}
                        className="pl-0"
                        type="checkbox"
                        disabled={!edit}
                        name={itvcode.itvcodeId}
                        checked={itvcodes.includes(itvcode.itvcodeId)}
                        onChange={check}
                        label={{ id: 'itvcodeformat', values: itvcode }} />
                    </FormGroup>
                  )}
                </>
              )}
            </>
          )}

          <div className="mt-2" />

          {itvcodelist && itvcodelist.business && itvcodelist.business.length > 0 && <FormGroup tag="fieldset">
            <TLabel id="businessName" />
            <ListGroup>
              {itvcodelist.business.map(business =>
                <ListGroupItem key={business.businessId}>{business.businessIdentifier} - {business.businessName}</ListGroupItem>)}
            </ListGroup>
          </FormGroup>}

          {itvcodelistUpdateError && <ErrAlert error={itvcodelistUpdateError} />}
        </CardBody>

        {/* TODO: add `rights={['']}` */}
        <ProtectedComponent>
          <CardFooter className="d-flex justify-content-end">
            {!edit && <TButton color="primary" onClick={() => setDuplicateModal(true)} id="duplicate" />}
            {!edit && <TButton className="ml-2" onClick={() => setEdit(true)} id="edit" />}
            {edit && <TButton disabled={itvcodelistUpdating}
              onClick={() => setRemovePopup(true)} color="danger"
              id="remove" />}
            {edit && <TButton disabled={itvcodelistUpdating}
              onClick={save} color="primary" className="ml-2"
              spin={itvcodelistUpdating}
              id="save" />}
            {edit && <TButton disabled={itvcodelistUpdating}
              onClick={cancel} className="ml-2"
              id="cancel" />}
          </CardFooter>
        </ProtectedComponent>

        <Modal isOpen={removePopup} fade={false} toggle={() => setRemovePopup(false)}>
          <ModalBody>
            <T id="removeContent" />
            {itvcodelist.business.length > 0 && <Alert color="warning">
              <T id="removeDesc"
                values={{ nbBusiness: itvcodelist.business.length }} />
            </Alert>}
            {itvcodelistDeleteError && <ErrAlert className="mb-0 mt-2" error={itvcodelistDeleteError} />}
          </ModalBody>
          <ModalFooter>
            <TButton disabled={itvcodelistDeleting}
              onClick={() => setRemovePopup(false)}
              id="removeCancel" />
            <TButton disabled={itvcodelistDeleting}
              className="ml-2" color="danger"
              onClick={() => remove()}
              spin={itvcodelistDeleting}
              id="removeConfirm" />
          </ModalFooter>
        </Modal>

        <Modal isOpen={duplicateModal} fade={true} toggle={() => setDuplicateModal(!duplicateModal)}>
          <ModalBody>
            <div className="mb-1"><T id="modal.duplicate.content" /></div>
            {duplicateItvcodelistError && <ErrAlert error={duplicateItvcodelistError} className="mb-0 mt-2" />}
          </ModalBody>
          <ModalFooter>
            <TButton
              id="modal.duplicate.cancel"
              disabled={duplicateItvcodelistLoading}
              onClick={() => setDuplicateModal(!duplicateModal)} />
            <TButton
              id="modal.duplicate.confirm"
              disabled={duplicateItvcodelistLoading}
              loading={duplicateItvcodelistLoading}
              className="ml-2" color="danger" onClick={() => duplicate()} />
          </ModalFooter>
        </Modal>
      </Card>
    </>
  )
}

export default ItvcodelistEdit
