import React from 'react'
import { FormattedDate } from 'react-intl'

import { FormGroup, CardBody } from 'reactstrap'
import Button from 'components/Button'

import { AppContext } from 'contexts/AppContext'

import { TLabel, TCustomInput, TDatePicker } from 'components/TComponents'

import ProtectedComponent from 'components/ProtectedComponent'
import SlotSelector from 'components/SlotSelector'

import './UserCalendar.module.scss'

import UserDisponibilities from './UserDisponibilities'
import VeloptimUser from './Admin/Veloptim/VeloptminUser'


/* HELPERS FUNCTIONS */
const STATUS = Object.freeze({
  IDLE: 'idle',
  INITIALIZING: 'initializing',
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
})

const wait = async (promise, delay = 250) => {
  return Promise.all([
    promise,
    new Promise(resolve => setTimeout(resolve, delay))
  ]).then(([payload]) => payload)
}

const arraying = times => { return Array.from(Array(times)) }

const getFirstWeekDay = initialDate => {
  const date = removeOffset(initialDate)
  const day = date.getDay()
  const diff = date.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
  date.setDate(diff)
  return date
}

const getLastWeekDay = initialDate => {
  const date = removeOffset(initialDate)
  const day = date.getDay()
  const diff = date.getDate() - day + (day === 0 ? -6 : 1) + 6 // adjust when day is sunday
  date.setDate(diff)
  return date
}

const removeOffset = initialDate => {
  const date = new Date(initialDate)
  return new Date(date - (date.getTimezoneOffset() * 60000))
}

const parseNumber = (number, digits = 2) => {
  return Number(number).toLocaleString(undefined, { minimumIntegerDigits: digits })
}

const formatMinutes = minutes => {
  return `${parseNumber(Math.floor(minutes / 60))}:${parseNumber(Math.floor(minutes % 60))}`
}

const calendarRulesReducer = (state, action) => {
  if (action.status === STATUS.ERROR) {
    return action
  }
  return {
    status: action.status || state.status,
    payload: {
      ...state.payload,
      ...(action.payload || {}),
      days: action.payload
        ? action.payload.days.map(day => ({
          ...day,
          slots: day.slots.map(slot => {
            const date = new Date(slot.date)
            return date.getHours() * 60 + date.getMinutes()
          })
        }))
        : state.payload ? state.payload.days : [],
      rows: action.payload
        ? arraying((action.payload.hourEnd - action.payload.hourStart) / action.payload.slotStep).map((_, i) => ({
          time: action.payload.hourStart + (action.payload.slotStep * i)
        }))
        : state.payload ? state.payload.rows : []
    }
  }
}

const UserCalendar = ({ userId, userType }) => {
  const { api, user } = React.useContext(AppContext)
  const userIdentifier = userId || user.userId
  const [copyweekError, setCopyweekError] = React.useState()
  const [copyweekSuccess, setCopyweekSuccess] = React.useState()
  const [copyweekSuccessVeloptim, setCopyweekSuccessVeloptim] = React.useState()
  const [copyweekLoading, setCopyweekLoading] = React.useState()

  const buildWeek = React.useCallback(date => {
    date.setHours(12)
    return {
      firstDay: getFirstWeekDay(date),
      lastDay: getLastWeekDay(date),
    }
  }, [])

  const [calendarRules, dispatchCalendarRules] = React.useReducer(calendarRulesReducer, { status: STATUS.INITIALIZING })
  const [currentWeek, setCurrentWeek] = React.useState(() => buildWeek(new Date()))
  const [dateSelected, setDateSelected] = React.useState(new Date())

  React.useEffect(() => {
    wait(api.get('/calendar/rules', undefined, { userId: userIdentifier, dayStart: currentWeek.firstDay }))
      .then(payload => dispatchCalendarRules({ status: STATUS.LOADED, payload }))
      .catch(payload => dispatchCalendarRules({ status: STATUS.ERROR, payload }))
  }, [api, currentWeek.firstDay, userIdentifier])

  const copyWeek = React.useCallback(() => {
    setCopyweekLoading(true)
    wait(api.post('/calendar/weekrules', {
      body: JSON.stringify({
        userId: userIdentifier, day: currentWeek.firstDay,
      })
    }))
      .then(response =>  {
        if (response.hasVeloptim) {
          setCopyweekSuccessVeloptim(true)
        } else {
          setCopyweekSuccess(true)
        }
      })
      .catch(response => setCopyweekError(response))
      .then(() => setCopyweekLoading(false))
  }, [api, currentWeek, userIdentifier])

  const changeWeek = React.useCallback((direction, isDatePicker) => {
    if (isDatePicker) {
      setCurrentWeek(buildWeek(direction))
    } else {
      const newDate = new Date(currentWeek.firstDay)
      newDate.setDate(direction === 'previous' ? newDate.getDate() - 7 : newDate.getDate() + 7)
      setCurrentWeek(buildWeek(newDate))
      setDateSelected(newDate)
    }
  }, [buildWeek, currentWeek])

  const fillWeek = React.useCallback(() => {
    dispatchCalendarRules({ status: STATUS.LOADING })
    const slots = []
    for (let i = calendarRules.payload.hourStart; i < calendarRules.payload.hourEnd; i = i + calendarRules.payload.slotStep) {
      slots.push(i)
    }
    return wait(api.post('/calendar/rules', {
      body: JSON.stringify({
        userId: userIdentifier,
        dayStart: currentWeek.firstDay,
        hourStart: '',
        hourEnd: '',
        days: calendarRules.payload.days.map(day => ({ ...day, slots: slots.map(_ => formatMinutes(_)) }))
      })
    }))
      .then(payload => dispatchCalendarRules({ status: STATUS.LOADED, payload }))
      .catch(payload => dispatchCalendarRules({ status: STATUS.ERROR, payload }))
      .then(() => setCurrentWeek({ ...currentWeek }))
  }, [calendarRules, api, currentWeek, userIdentifier])

  const toggleSlot = React.useCallback((day, minutes) => {
    if (!day.slots) {
      return
    }
    const slots = day.slots.indexOf(minutes) >= 0 ? day.slots.filter(_ => _ !== minutes) : day.slots.concat(minutes)
    dispatchCalendarRules({ status: STATUS.LOADING })
    return wait(api.post('/calendar/rules', {
      body: JSON.stringify({
        userId: userIdentifier,
        dayStart: currentWeek.firstDay,
        hourStart: '',
        hourEnd: '',
        days: [{ ...day, slots: slots.map(_ => formatMinutes(_)) }]
      })
    }))
      .then(payload => dispatchCalendarRules({ status: STATUS.LOADED, payload }))
      .catch(payload => dispatchCalendarRules({ status: STATUS.ERROR, payload }))
      .then(() => setCurrentWeek({ ...currentWeek }))
  }, [api, currentWeek, userIdentifier])

  const toggleDay = React.useCallback(day => {
    if (!day.slots) {
      return
    }
    const allSlotsActivated = areAllSlotsActivated(day)
    const slots = []
    if (!allSlotsActivated) {
      for (let i = calendarRules.payload.hourStart; i < calendarRules.payload.hourEnd; i = i + calendarRules.payload.slotStep) {
        slots.push(i)
      }
    }
    dispatchCalendarRules({ status: STATUS.LOADING })
    // TODO: Posting rules can result to daychange on some days idk why
    return wait(api.post('/calendar/rules', {
      body: JSON.stringify({
        userId: userIdentifier,
        dayStart: currentWeek.firstDay,
        hourStart: '',
        hourEnd: '',
        days: [{ ...day, slots: slots.map(_ => formatMinutes(_)) }]
      })
    }))
      .then(payload => dispatchCalendarRules({ status: STATUS.LOADED, payload }))
      .catch(payload => dispatchCalendarRules({ status: STATUS.ERROR, payload }))
      .then(() => setCurrentWeek({ ...currentWeek }))
  }, [api, currentWeek, userIdentifier, calendarRules, areAllSlotsActivated])

  const areAllSlotsActivated = React.useCallback(day => {
    if (!day.slots) {
      return
    }
    return (day.slots.length === (calendarRules.payload.hourEnd - calendarRules.payload.hourStart) / calendarRules.payload.slotStep)
  }, [calendarRules])

  const changeSlotStep = React.useCallback((slotStep) => {
    // TODO: debounce ?
    dispatchCalendarRules({ status: STATUS.LOADING })
    wait(api.post('/calendar/slotstep', {
      body: JSON.stringify({
        userId: userIdentifier,
        dayStart: currentWeek.firstDay,
        hourStart: formatMinutes(calendarRules.payload.hourStart),
        hourEnd: formatMinutes(calendarRules.payload.hourEnd),
        slotStep: Number(slotStep),
      })
    }))
      .then(payload => dispatchCalendarRules({ status: STATUS.LOADED, payload }))
      .catch(payload => dispatchCalendarRules({ status: STATUS.ERROR, payload }))
  }, [api, calendarRules, currentWeek, userIdentifier])

  return (
    <CardBody>
      <div className="w-100 sectionContent">
        {[STATUS.LOADED, STATUS.LOADING].includes(calendarRules.status) && (
          <>
            <div className="calendar">
              <TLabel id="calendar.global.label" raw className="label" />
              <div className="parameters mb-5 border">
                <div className="d-flex flex-row align-items-center">
                  <div className="currentWeek border">
                    <Button outline onClick={() => changeWeek('previous')}>
                      <i className="simple-icon-arrow-left" />
                    </Button>
                    <span className="pl-2 pr-2"><FormattedDate value={new Date(currentWeek.firstDay)} />{' - '}<FormattedDate value={new Date(currentWeek.lastDay)} /></span>
                    <Button outline onClick={() => changeWeek('after')} >
                      <i className="simple-icon-arrow-right" />
                    </Button>
                  </div>
                  <div className="ml-3">
                    <TDatePicker
                      customInput={<SlotSelector />}
                      selected={dateSelected}
                      onChange={e => {
                        changeWeek(e, true)
                        setDateSelected(e)
                      }}
                      dayHeaderFormat="wide"
                      dateFormat="eeee dd/MM/yyyy"
                    />
                  </div>
                </div>
                <div>
                  <div className="slots">
                    <FormGroup check inline>
                      <TCustomInput
                        id="slot30"
                        type="radio"
                        disabled={calendarRules.status === STATUS.LOADING}
                        checked={calendarRules.payload.slotStep === 30}
                        name="slot"
                        value={30}
                        label="calendar.slotStep.30"
                        raw
                        onChange={({ target: { value } }) => changeSlotStep(value)}
                        inline />
                    </FormGroup>
                    <FormGroup check inline>
                      <TCustomInput
                        id="slot60"
                        type="radio"
                        disabled={calendarRules.status === STATUS.LOADING}
                        checked={calendarRules.payload.slotStep === 60}
                        name="slot"
                        value={60}
                        label="calendar.slotStep.60"
                        raw
                        onChange={({ target: { value } }) => changeSlotStep(value)}
                        inline />
                    </FormGroup>
                  </div>
                </div>
              </div>
              {userType === 'user' ? (
                <ProtectedComponent rights={['veloptim-tour_view']}>
                  <VeloptimUser userId={userId} userType={userType} currentWeek={currentWeek} />
                </ProtectedComponent>
              ) : (
                <VeloptimUser userId={userId} userType={userType} currentWeek={currentWeek} />
              )}
              {userType === 'admin' ? (
                <ProtectedComponent rights={['admin-unavailabilities_view', 'admin-unavailabilities_edit']}>
                  <UserDisponibilities
                    calendarRules={calendarRules}
                    fillWeek={fillWeek}
                    copyWeek={copyWeek}
                    error={copyweekError}
                    success={copyweekSuccess}
                    successVeloptim={copyweekSuccessVeloptim}
                    loading={copyweekLoading}
                    areAllSlotsActivated={areAllSlotsActivated}
                    toggleDay={toggleDay}
                    toggleSlot={toggleSlot}
                  />
                </ProtectedComponent>
              ) : (
                <UserDisponibilities
                  calendarRules={calendarRules}
                  fillWeek={fillWeek}
                  copyWeek={copyWeek}
                  error={copyweekError}
                  success={copyweekSuccess}
                  successVeloptim={copyweekSuccessVeloptim}
                  loading={copyweekLoading}
                  areAllSlotsActivated={areAllSlotsActivated}
                  toggleDay={toggleDay}
                  toggleSlot={toggleSlot}
                />
              )}
            </div>
          </>
        )}
      </div>
    </CardBody>
  )
}


export default UserCalendar
