import React from 'react'
import { FormattedDate } from 'react-intl'
import { useLocation, Link, useHistory } from 'react-router-dom'
import { objectToQuery, queryToObject } from 'react-rest-api'

import {
  Card, CardBody, CardHeader,
  Table,
  NavLink, Spinner
} from 'reactstrap'

import { useGet } from 'hooks/useApi.hooks'

import TH from 'components/TH'
import { T, TAlert, ErrAlert, TLabel, TInput } from 'components/TComponents'
import { Filters } from 'components/Filters'

import '../Table.scss'

const InterventionTable = () => {
  const location = useLocation()
  const history = useHistory()
  const [alertValue, setAlertValue] = React.useState(location.state && location.state.a && location.state.a)

  const filters = React.useMemo(() => ({
    p: {
      type: 'number'
    },
    businessId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false,
        getOptionValue: option => option.label,
        isSearchable: true,
        isClearable: false,
      },
    },
    categoryId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false
      },
    },
    familyId: {
      array: false,
      componentType: 'select',
      componentOptions: {
        isMulti: false
      },
    },
    itvcodeId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false
      },
    },
    companyId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false,
        translate: [0]
      },
    },
  }), [])

  // DATA
  const params = React.useMemo(() => ({ ...location.state, ...queryToObject(location.search, filters) }), [location, filters])
  const [data, refreshing, error, loading] = useGet(`/interventions/board`, undefined, params)
  const refresh = React.useCallback((_params = {}) => history.push({ search: objectToQuery({ ...params, ..._params }) }), [history, params])

  const Row = React.useCallback(({ day }) => {
    const total = day.counts.reduce((acc, count) => Number.isInteger(count) ? acc + Number(count) : acc, 0)
    const color = alertValue && total >= alertValue ? 'danger' : 'safe'
    return (
      <tr className={`line state-${color}`}>
        <td className="sticky date border-0 py-1">
          <FormattedDate value={new Date(day.date)} year="numeric" month="2-digit" day="2-digit" weekday="long" />
        </td>
        {day.counts.map((count, i) => <td key={i} className="count align-middle border-0 py-1">{count}</td>)}
        <td className="total border-0 py-1">{total}</td>
      </tr>
    )
  }, [alertValue])

  return (
    <div className="container-fluid Table">

      <NavLink tag={Link} to={{
        pathname: '/interventions',
        search: location.state ? location.state.interventions : undefined
      }}>
        <T id="returnToList" />
      </NavLink>

      <Card className="mb-2">
        <CardHeader>
          <Filters
            loading={loading}
            disabled={refreshing}
            data={data}
            refresh={refresh}
            filters={filters}
          />
          <div className="form-row">
            <div className="col-sm-6 col-md-3">
              <TLabel id="filters.alert.label" />
              <TInput
                name="alert"
                type="number"
                placeholder={`filters.alert.placeholder`}
                value={alertValue}
                onChange={e => setAlertValue(e.target.value)} />
            </div>
          </div>
        </CardHeader>
        {error && <ErrAlert error={error} />}
        {!error && loading && <Spinner color="primary" className="d-flex my-4 mx-auto" />}
        {!error && !loading && (!data || data.users.length === 0) && (
          <TAlert id="noResults" color="warning" />
        )}
        {!error && !loading && data && data.users.length > 0 && (
          <CardBody className="py-3">
            <Table responsive className="mb-3 w-auto separate">
              <thead>
                <tr>
                  <TH colName="date" className={['border-0 text-center sticky color-white']} />
                  {data.users.map((user, i) =>
                    <TH key={`${i}.${user}`} className={['border-0']}>{user}</TH>)}
                  <TH colName="total" className={['border-0']} />
                </tr>
              </thead>
              <tbody>
                {data.days.map(day => <Row day={day} key={day.date} />)}
              </tbody>
            </Table>
          </CardBody>
        )}
      </Card>
    </div>
  )
}

export default InterventionTable
