import React from 'react'
import { useIntl } from 'react-intl'
import { selectUnit } from '@formatjs/intl-utils';

import {
  Button, ListGroup, ListGroupItem,
  Card, CardHeader, CardBody, CardColumns, CardLink,
  Form, FormGroup, Label, FormFeedback,
  Spinner,
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import { useGet } from 'hooks/useApi.hooks'

import { T, TLabel, TButton, TInput, TAlert, ErrAlert, } from 'components/TComponents'

const imeiReducer = (imei, newImei) => {
  const invalids = []
  if (imei && isNaN(Number(newImei))) { invalids.push('NaN') }
  if (imei && newImei.length < 15) { invalids.push('tooShort') }
  if (imei && newImei.length > 15) { invalids.push('tooLong') }

  return {
    value: newImei,
    invalids
  }
}

const imeiInitialState = ({
  value: '',
  invalids: []
})

const checkReducer = (state, action) => {
  switch (action.type) {
  case 'load': return { loading: true, error: undefined, result: undefined }
  case 'result': return { loading: false, error: undefined, result: action.result }
  case 'error': return { loading: false, error: action.error, result: undefined }
  case 'clear': return checkInitialState
  default: return state
  }
}

const checkInitialState = {
  loading: false,
  error: undefined,
  result: undefined
}

const CheckLVM = () => {
  const { api } = React.useContext(AppContext)
  const { formatRelativeTime } = useIntl()

  const [imeiState, imeiDispatch] = React.useReducer(imeiReducer, imeiInitialState)
  const [checkState, checkDispatch] = React.useReducer(checkReducer, checkInitialState)

  const [logsLastRefresh, setLogsLastRefresh] = React.useState(new Date().getTime())
  const [logs, logsLoading, logsError] = useGet(`/fluow/lvmlogs`, undefined, undefined, {
    dependencies: logsLastRefresh,
    timeout: 350
  })

  const check = React.useCallback((e) => {
    e.preventDefault()

    // If press enter while invalid
    if (!imeiState.value || imeiState.invalids.length !== 0) { return }

    checkDispatch({ type: 'load' })
    Promise.all([
      api.post('/fluow/checklvm', { body: JSON.stringify({ IMEI: imeiState.value }) }),
      new Promise(resolve => setTimeout(resolve, 350))
    ])
      .then(([result]) => {
        checkDispatch({ type: 'result', result })
        setLogsLastRefresh(new Date().getTime())
      })
      .catch(error => checkDispatch({ type: 'error', error }))
  }, [api, imeiState.invalids.length, imeiState.value])

  const getDelta = React.useCallback((result) => {
    const { value, unit } = selectUnit(new Date(result.lastTrameDate))
    return formatRelativeTime(value, unit, { numeric: 'auto' })
  }, [formatRelativeTime])

  return (<>
    <Card className="mb-5">
      <T tagName={CardHeader} className="h5" id="title" />
      <CardBody>
        <Form onSubmit={check}>
          <div className="d-flex">
            <FormGroup tag="fieldset">
              <TLabel id="imei.label" />
              <TInput className="pt-2 pb-2"
                type="text"
                name="lvmIMEI"
                value={imeiState.value}
                onChange={e => imeiDispatch(e.target.value)}
                placeholder="imei.placeholder"
                disabled={checkState.loading}
                autoFocus="autofocus"
                autoComplete="off"
                invalid={imeiState.invalids.length > 0} />
              {imeiState.invalids.map(invalid => <T key={`imei.error.${invalid}`} tagName={FormFeedback} id={`imei.error.${invalid}`} />)}
            </FormGroup>

            <FormGroup tag="fieldset">
              <Label>&nbsp;</Label>
              <TButton id="imei.check"
                className="py-2 ml-2 d-block"
                type="button"
                disabled={checkState.loading || imeiState.invalids.length !== 0 || !imeiState.value}
                loading={checkState.loading}
                onClick={check} />
            </FormGroup>
          </div>

          <ErrAlert error={checkState.error} isOpen={Boolean(checkState.error)} toggle={() => checkDispatch({ type: 'clear' })} />
        </Form>

        {checkState.result && (
          <ListGroup>
            <T tagName={ListGroupItem} id="lvm.lastTrameDate" values={{ lastTrameDate: new Date(checkState.result.lastTrameDate) }} />
            <T tagName={ListGroupItem} id="lvm.delta" values={{ delta: getDelta(checkState.result) }} />
            <T tagName={ListGroupItem} id="lvm.fwVersion" values={checkState.result} />
            <T tagName={ListGroupItem} id="lvm.securityState" values={checkState.result} />
            <T tagName={ListGroupItem} id="lvm.battVoltage" values={checkState.result} />
            <T tagName={ListGroupItem} id="lvm.ebikeBattLevel" values={checkState.result} />
            <T tagName={ListGroupItem} id="lvm.location" values={{
              ...checkState.result.location,
              gmap: (...chunks) => React.Children.map(chunks, chunk => (
                <a className="text-primary"
                  href={`http://www.google.com/maps/place/${checkState.result.location.lat},${checkState.result.location.lng}`}
                  rel="noopener noreferrer"
                  target="_blank">
                  {chunk}
                </a>
              ))
            }} />
          </ListGroup>
        )}
      </CardBody>
    </Card>

    <Card>
      <CardHeader className="d-flex align-items-center">
        <T id="log.title" className="h5 mr-1 mb-0" />
        <Button className="d-flex align-items-center ml-auto py-2"
          disabled={logsLoading}
          onClick={() => setLogsLastRefresh(new Date().getTime())}>
          <i className="simple-icon-refresh mb-0 h6" />
        </Button>
      </CardHeader>
      <CardBody className="d-flex">
        {logsLoading && <Spinner className="mx-auto my-4x" />}
        {!logsLoading && (!logs || logs.length === 0) && <TAlert id="log.empty" color="info" className="mb-0" />}
        {!logsLoading && logs.length !== 0 && (
          <CardColumns>
            {logs.map(log => <Card key={log.name} className="shadow-none bg-light">
              <CardLink className="d-block m-2 text-primary text-center stretched-link"
                target="_blank"
                rel="noopener noreferrer"
                href={log.url}>
                {log.name}
              </CardLink>
            </Card>)}
          </CardColumns>
        )}
        {!logsLoading && logsError && <ErrAlert error={logsError} />}
      </CardBody>
    </Card>
  </>)
}

export default CheckLVM
