import { default as React } from 'react'
import { NavLink } from 'react-router-dom'

import {
  Card, CardHeader,
  FormGroup,
  Spinner
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import Pagination from 'components/Pagination'
import ProtectedComponent from 'components/ProtectedComponent'
import { TButton, TInput, TLabel, TCustomInput, ErrAlert } from 'components/TComponents'

import { debounce } from 'util/Utils'

const UserList = () => {
  const { api } = React.useContext(AppContext)

  const [data, setData] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()

  const [filter, setFilter] = React.useState('')

  React.useEffect(() => {
    Promise
      .all([
        api.get('/users/list'),
        new Promise(resolve => setTimeout(resolve, 250))
      ])
      .then(([users,]) => setData(users))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api])

  const refresh = React.useCallback(params => {
    setLoading(true)
    setError()
    Promise.all([
      api.get('/users/list', undefined, {
        ...data.filters,
        p: data.currentPage,
        ...params
      }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => setData(data))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api, data])

  const refreshDebounced = React.useMemo(() => debounce(params => refresh(params), 250), [refresh])

  const handleSearchInput = React.useCallback(({ target: { value } }) => {
    setFilter(value)
    refreshDebounced({ filter: value, p: 1 })
  }, [refreshDebounced])

  return (
    <>
      <Card className="mb-2">
        <CardHeader>
          <FormGroup className="flex-grow-1 pl-0">
            <TLabel id="usersFilterTitle" className="sr-only" for="usersFilter" />
            <TInput
              name="usersFilter"
              type="text"
              placeholder="usersFilterPlaceholder"
              value={filter}
              onChange={handleSearchInput} />
          </FormGroup>
          <FormGroup className="flex-grow-1 pl-0 mb-0">
            <TCustomInput type="switch"
              id="locked"
              name="locked"
              label="locked"
              className="w-100 bg-transparent border-0"
              disabled={loading}
              checked={data ? data.filters.locked : false}
              onChange={e => refresh({ locked: e.target.checked, p: 1 })} />
          </FormGroup>
        </CardHeader>
        <ProtectedComponent rights={['admin-users_edit']}>
          <CardHeader>
            <TButton id="createUser"
              tag={NavLink}
              to="./users/new" />
          </CardHeader>
        </ProtectedComponent>
      </Card>
      {loading && <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />}
      {!loading && error && <ErrAlert error={error} />}
      {!loading && !error && data.result.map(user => (
        <Card key={user.userId} className="mb-2">
          <CardHeader className="d-flex align-items-center">
            <span className="h6 mb-0">
              {user.locked && <i className="mr-1 iconsmind-Lock text-danger" />}
              {user.firstName}&nbsp;{user.lastName}
            </span>
            <TButton id="viewUser" className="ml-auto stretched-link" outline
              tag={NavLink}
              to={`./users/${user.userId}`} />
          </CardHeader>
        </Card>
      ))}

      {data && <Pagination totalPage={data.totalPage} currentPage={data.currentPage} onPageClick={p => refresh({ p })} />}
    </>
  )
}

export default UserList
