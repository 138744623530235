import React from 'react'

const Groups = props => (
  <div className={`rct-group-lines${props.direction === 'horizontal' ? '' : '-vertical'}`}>
    {props.groups.map((group, i) => <div
      key={`group-line-${group.id}`}
      onDoubleClick={evt => props.onGroupDoubleClick(evt, group.id)}
      onDrop={evt => props.onDrop(evt, group.id)}
      onDragOver={e => e.preventDefault()}
      className={`rct-hl-${i % 2 === 0 ? 'even' : 'odd'} `}
      style={{ height: props.direction === 'horizontal' ? `${props.groupHeights[i] - 1}px` : '100%' }}
    />)}
  </div>
)

export default Groups
