import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

import TopNav from 'containers/TopNav'
import Sidebar from 'containers/Sidebar'
import Route from 'components/Route'

import Changelog from './Changelog'
import Dashboard from './Dashboard'
import Intervention from './Intervention'
import MaintenancePlan from './MaintenancePlan'
import Maintenance from './Maintenance'
import Appointments from './Appointment'
import Equipment from './Equipment'
import Planning from './Planning'
import PlanningUser from './PlanningUser'
import Desk from './Desk/DeskRoute'
import Fluow from './Fluow'
import Swaps from './Swap'
import Admin from './Admin/AdminRoute'
import Stats from './Stats'
import Translations from './Translations'
import LanguageSelector from './LanguageSelector'
import Account from './Account/AccountRoute'
import Veloptim from './Veloptim/VeloptimRoute'
import Veloparts from './Veloparts/VelopartsRoute'
import Log from "./Log/LogRoute"
import { Error, Error404 } from 'routes/Error'

const AppRoutes = () => (
  <div id="app-container" className="menu-default">
    <TopNav hasMenu={true} />
    <Sidebar />
    <main>
      <Switch>
        <Redirect exact path="/" to="/dashboard" />
        <Redirect path="/subsidiaries" to="/" />
        <Route exact path="/changelog" component={Changelog} />
        <Route exact path="/languages" component={LanguageSelector} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route path="/interventions" component={Intervention} />
        <Route path="/maintenanceplan" component={MaintenancePlan} />
        <Route path="/maintenance" component={Maintenance} />
        <Route path="/appointments" component={Appointments} />
        <Route path="/equipments" component={Equipment} />
        <Route exact path="/planning" component={Planning} />
        <Route path="/planning/:id" component={PlanningUser} />
        <Route path="/translations" component={Translations} />
        <Route path="/desk" component={Desk} />
        <Route path="/log" component={Log} />
        <Route path="/fluow" component={Fluow} rights={[
          'fluow-assignment',
          'fluow-pairing'
        ]} />
        <Route path="/swaps" component={Swaps} />
        <Route path="/statistics" component={Stats} rights={['stats_view']} />
        <Route path="/admin" component={Admin} rights={[
          'admin-users_view', 'admin-users_edit',
          'admin-profiles_view', 'admin-profiles_edit',
          'admin-unavailabilities_view', 'admin-unavailabilities_edit',
          'admin-subcontractors_view', 'admin-subcontractors_edit',
          'admin-businessusers_view', 'admin-businessusers_edit',
          'admin-matrices_view', 'admin-matrices_edit',
          'admin-business_view', 'admin-business_edit',
          'admin-bases_view', 'admin-bases_edit',
          'admin-companies_view', 'admin-companies_edit',
          'admin-techcenters_view', 'admin-techcenters_edit',
          'admin-categories_view', 'admin-categories_edit',
          'admin-itvcodes_view', 'admin-itvcodes_edit',
          'admin-itvcodelists_view', 'admin-itvcodelists_edit',
          // 'admin-subsidiaries_edit',
        ]} />
        <Route path="/account" component={Account} />
        <Route path="/veloptim" component={Veloptim} />
        <Route path="/veloparts" component={Veloparts} />
        <Route path="/error" component={Error} />
        <Route component={Error404} />
      </Switch>
    </main>
  </div>
)

export default AppRoutes
