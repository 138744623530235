import React from 'react'

import {
  Form, FormGroup,
  Row, Col
} from 'reactstrap'

import { T, TLabel, TInput, TDatePicker, TButton, TCustomInput } from 'components/TComponents'
import moment from 'moment-timezone'
import FilterSelect from 'components/FilterSelect'
import Slider from 'components/Slider'
import SlotSelector from 'components/SlotSelector'

import { debounce } from 'util/Utils'

const textInputReducer = (state, action) => ({ ...state, ...action })

const initState = (filters = {}) => {
  return Object.entries(filters).reduce((acc, [key, { type, ...options }]) => {
    if (type === 'textDebounce') {
      acc[key] = options.value
    }
    return acc
  }, {})
}

export const Filters = ({ disabled, data, refresh, filters, moreFilters }) => {
  const [textInputs, dispatchTextInputs] = React.useReducer(textInputReducer, filters, initState)
  const [moreFiltersVisibles, setMoreFiltersVisibles] = React.useState(false)

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const transformValue = React.useCallback((name, value) => {
    return (
      filters[name] && filters[name].transform ? filters[name].transform(value) : value,
      moreFilters && moreFilters[name] && moreFilters[name].transform ? moreFilters[name].transform(value) : value
    )
  }, [filters, moreFilters])

  const handleChange = React.useCallback((name, value) => refresh({ p: 1, [name]: transformValue(name, value) }), [refresh, transformValue])

  const handleCheckboxChange = React.useCallback((name, value) => refresh({ p: 1, [name]: value == 1 ? transformValue(name, value) : undefined }), [refresh, transformValue])

  const handleDateChange = React.useCallback((name, value, customPagination) => {
    if (customPagination && customPagination.length === 2) {
      refresh({ [customPagination[0]]: 1, [customPagination[1]]: 1, [name]: transformValue(name, value) })
    } else {
      refresh({ p: 1, [name]: transformValue(name, value) })
    }
  }, [refresh, transformValue])

  const handleChangeRangeDate = React.useCallback((keys, date) => {
    if (!startDate && !endDate) {
      setStartDate(new Date(date));
      return (refresh({ p: 1, [keys[1]]: transformValue(keys[1], date) }))
    } else if (startDate && !endDate) {
      // Start Date must be before EndDate
      if (new Date(date).getTime() < startDate.getTime()) {
        setEndDate(startDate)
        setStartDate(new Date(date))
        return (refresh({ p: 1, [keys[1]]: transformValue(keys[1], date), [keys[0]]: transformValue(keys[0], startDate) }))
      } else {
        setEndDate(new Date(date));
        return (refresh({ p: 1, [keys[0]]: transformValue(keys[0], date) }))
      }
    }
    if (startDate && endDate) {
      setStartDate(new Date(date));
      setEndDate(null);
      return (refresh({ p: 1, [keys[1]]: transformValue(keys[1], date), [keys[0]]: transformValue(keys[0], null) }))
    }
  }, [refresh, transformValue, endDate, startDate])

  const handleClear = React.useCallback((keys) => {
    setStartDate(null)
    setEndDate(null)
    return (refresh({ p: 1, [keys[1]]: transformValue(keys[1], null), [keys[0]]: transformValue(keys[0], null) }))
  }, [refresh, transformValue])

  const getFormattedItvcodesOptions = data => {
    return (data.map(d => (
      d.families.map(f => ({
        label: `${d.categoryName} - ${f.familyName}`,
        options: f.items.map(i => (
          {
            label: `${i.itvcodeIdentifier}  - ${i.itvcodeLabel} - (${d.categoryName}-${f.familyName})`,
            value: Object.assign({}, i, { categoryName: d.categoryName }, { familyName: f.familyName })
          })
        )
      }))
    ))[0])
  }


  const handleTimeRange = React.useCallback((names, values) => {
    refresh({ p: 1, [names[0]]: values[0], [names[1]]: values[1] })
  }, [refresh])

  const refreshDebounced = React.useMemo(() => debounce(refresh, 250), [refresh])

  const handleInputDebounced = React.useCallback(({ target: { name, value } }) => {
    dispatchTextInputs({ [name]: value })
    refreshDebounced({ p: 1, [name]: value })
  }, [refreshDebounced])

  const renderFilters = filters => {
    return (
      Object.entries(filters).map(([key, { componentType, componentOptions, defaultValues, defaultStep, childKeys, noLabel, withArrows, customPagination }]) => {
        switch (componentType) {
        case 'textDebounce':
          return (
            <Col sm="6" md="3" key={key}>
              <FormGroup tag="fieldset">
                <TLabel id={`filters.${key}.label`} for={key} />
                <TInput
                  name={key}
                  type="text"
                  placeholder={`filters.${key}.placeholder`}
                  value={textInputs[key] === undefined ? (data && data.filters && data.filters[key]) || '' : textInputs[key] || ''}
                  onChange={handleInputDebounced}
                  {...componentOptions} />
              </FormGroup>
            </Col>)
        case 'select':
          return data && data.filters && data.filters[key] && data.filters[key].values && (
            <Col sm="6" md="3" key={key}>
              <FormGroup tag="fieldset">
                <TLabel id={`filters.${key}.label`} for={key} />
                <FilterSelect name={key}
                  placeholder={<T id={`filters.${key}.placeholder`} />}
                  value={data.filters[key].selected}
                  options={data.filters[key].values}
                  isClearable
                  isDisabled={disabled}
                  onChange={value => handleChange(key, value)}
                  {...componentOptions} />
              </FormGroup>
            </Col>
          )
        case 'checkbox':
          return data && data.filters && (data.filters[key] || data.filters[key] === null) && (
            <Col sm="6" md="3" key={key}>
              <FormGroup tag="fieldset">
                <TLabel id={`filters.${key}.label.title`} for={key} className="d-block" />
                <TCustomInput
                  id={key}
                  type="checkbox"
                  name={key}
                  label={`filters.${key}.label`}
                  checked={data.filters[key] == 1}
                  disabled={disabled}
                  onChange={() => handleCheckboxChange(key, data.filters[key] == 1 ? '0' : '1')}
                  {...componentOptions} />
              </FormGroup>
            </Col>
          )
        case 'date':
          return data && data.filters && (
            <div className="col-sm-6 col-md-3" key={key}>
              <FormGroup tag="fieldset">
                <TLabel id={`filters.${key}.label`} for={key} />
                {/* Range DatePicker
                  endDate must be 0, startDate 1 */}
                {childKeys && childKeys.length > 0 ? (
                  <TDatePicker
                    wrapperClassName="w-100"
                    disabled={disabled}
                    onChange={date => handleChangeRangeDate(childKeys, date, customPagination)}
                    onClear={() => handleClear(childKeys)}
                    shouldCloseOnSelect={!(!startDate || Boolean(startDate && endDate))}
                    selectsStart
                    selectsEnd
                    startDate={startDate || undefined}
                    endDate={data.filters[childKeys[0]] ? new Date(data.filters[childKeys[0]]) : undefined}
                    dateFormat="eeee dd/MM/yyyy"
                    value={data.filters[childKeys[1]] ? (data.filters[childKeys[0]] ? `${moment(data.filters[childKeys[1]]).format('DD/MM/YYYY')} - ${moment(data.filters[childKeys[0]]).format('DD/MM/YYYY')}` : moment(data.filters[childKeys[1]]).format('DD/MM/YYYY')) : undefined}
                    customInput={<SlotSelector isCustomClearable={true} onClear={() => { handleClear(childKeys) }} />}
                    {...componentOptions}
                  />
                ) : (
                  <>
                    <div className="position-relative d-flex">
                      {withArrows && (
                        <i className="btn btn-secondary simple-icon-arrow-left px-2 mr-1"
                          onClick={() => { handleDateChange(key, new Date(new Date(data.filters[key]).getTime() - (3600 * 1000 * 24)), customPagination) }}
                        />
                      )}
                      <TDatePicker
                        wrapperClassName={withArrows ? 'w-auto' : 'w-100'}
                        disabled={disabled}
                        selected={data.filters[key]
                          ? new Date(data.filters[key])
                          : withArrows ? new Date() : undefined}
                        onChange={e => handleDateChange(key, e, customPagination)}
                        {...componentOptions}
                      />
                      {withArrows && (
                        <i className="btn btn-secondary simple-icon-arrow-right px-2 ml-1"
                          onClick={() => { handleDateChange(key, new Date(new Date(data.filters[key]).getTime() + (3600 * 1000 * 24)), customPagination) }}
                        />
                      )}
                    </div>
                  </>
                )}
              </FormGroup>
            </div>
          )
        case 'duration':
          return data && data.filters && (
            <div className="col-sm-6 col-md-3" key={key}>
              <FormGroup tag="fieldset">
                <TLabel id={`filters.${key}.label`} for={key} />
                <div className="d-flex flex-row">
                  <span className="d-block mr-2">{data.filters[childKeys[0]] ? data.filters[childKeys[0]] : defaultValues[0]}</span>
                  <Slider
                    name="preferredTimeRange"
                    values={data.filters[childKeys[0]] || data.filters[childKeys[1]] ? [data.filters[childKeys[0]], [data.filters[childKeys[1]]]] : defaultValues}
                    onChange={(e) => handleTimeRange(childKeys, e.target.value)}
                    {...{ min: defaultValues[0], max: defaultValues[1], step: defaultStep }} />
                  <span className="ml-2">{data.filters[childKeys[1]] ? data.filters[childKeys[1]] : defaultValues[1]}</span>
                </div>
              </FormGroup>
            </div>
          )
        case 'switch':
          return data && data.filters && (data.filters[key] || data.filters[key] === null || data.filters[key] === false) && (
            <Col sm="6" md="3" key={key}>
              <FormGroup tag="fieldset">
                {!noLabel && <TLabel id={`filters.${key}.label.title`} for={key} className="d-block" />}
                <TCustomInput
                  id={key}
                  type="switch"
                  name={key}
                  className={noLabel ? 'custom-switch-fff ml-2 custom-switch-mt' : 'custom-switch-fff ml-2'}
                  label={`filters.${key}.label`}
                  checked={data.filters[key] == 1}
                  disabled={disabled}
                  inline
                  onChange={() => handleCheckboxChange(key, data.filters[key] == 1 ? '0' : '1')}
                  {...componentOptions}
                />
              </FormGroup>
            </Col>
          )
        case 'itvcode':
          return data && data.filters && (
            <Col sm="6" md="3" key={key}>
              <FormGroup tag="fieldset">
                <TLabel id={`filters.${key}.label`} for={key} />
                <FilterSelect name={key}
                  placeholder={<T id={`filters.${key}.placeholder`} />}
                  value={getFormattedItvcodesOptions(data.filters[key].values).find(code => code.itvcodeId === data.filters[key].selected)}
                  options={getFormattedItvcodesOptions(data.filters[key].values)}
                  isClearable
                  isDisabled={disabled}
                  onChange={value => handleChange(key, value && value.itvcodeId)}
                  {...componentOptions} />
              </FormGroup>
            </Col>
          )
        }
      })
    )
  }

  return (
    <Form onSubmit={e => e.preventDefault()}>
      <Row form>
        {renderFilters(filters)}
        {moreFilters && (
          <div className="col-sm-6 col-md-3 mt-4">
            <TButton outline id={moreFiltersVisibles ? 'hideMoreFilters' : 'loadMoreFilters'} onClick={() => setMoreFiltersVisibles(!moreFiltersVisibles)} />
          </div>
        )}
      </Row>
      <Row form>
        {moreFilters && moreFiltersVisibles && renderFilters(moreFilters)}
      </Row>
    </Form>
  )
}

export default Filters
