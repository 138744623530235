import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import Route from 'components/Route'

import EquipmentHistoryList from './EquipmentHistoryList'
import EquipmentImport from './EquipmentImport'
import EquipmentMassChange from './EquipmentMassChange'
import EquipmentEdit from './EquipmentEdit'
import EquipmentList from './EquipmentList'
import EquipmentAdd from './EquipmentAdd'

const EquipmentRoute = ({ match }) => (
  <Switch>
    <Route exact path={match.path} component={EquipmentList} rights={['equipments_view']} />
    <Route path={`${match.path}/add`} component={EquipmentAdd} rights={['equipments_new']} />
    <Route exact path={`${match.path}/history`} component={EquipmentHistoryList} />
    <Redirect exact path={`${match.path}/import`} to={match.path} />
    <Route path={`${match.path}/import/:id`} component={EquipmentImport} rights={['equipments_import']} />
    <Route path={`${match.path}/status/:id`} component={EquipmentMassChange} rights={['equipments_import']} />
    <Route path={`${match.path}/:id`} component={EquipmentEdit} rights={['equipments_view']} />
    {/* TODO: redirect is not used due to :id before */}
    <Redirect to={`/`} />
  </Switch>
)

export default EquipmentRoute
