import React from 'react'
import { objectToQuery, queryToObject } from 'react-rest-api'
import { Card, CardBody, Spinner } from 'reactstrap'

import { useGet } from 'hooks/useApi.hooks'

import { T, TAlert } from 'components/TComponents'

import { Table } from 'components/Table'

const VeloptimWaiting = ({ location, history }) => {

  const filters = React.useMemo(() => ({
    p: {
      type: 'number'
    },
    ipp: {
      type: 'number'
    },
    'order[column]': {},
    'order[dir]': {},
  }), [])

  // DATA
  const params = React.useMemo(() => queryToObject(location.search, filters), [location.search, filters])

  const [data, refreshing, error, loading] = useGet(`/veloptim/minWaiting`, undefined, params)

  const refresh = React.useCallback((_params = {}) => history.push({ search: objectToQuery({ ...params, ..._params }) }), [history, params])

  const renderDelay = React.useCallback(item => {
    return (
      <>
        {!!item.delay.day && item.delay.day !== 0 && <T id="delayDay" values={{ day: item.delay.day }} />}
        {!!item.delay.hour && item.delay.hour !== 0 && <T id="delayHour" values={{ hour: item.delay.hour }} />}
        {!item.delay.day && !item.delay.hour && <T id="noData" />}
      </>
    )
  }, [])

  return (
    <Card>
      <CardBody>
        {error && (
          <TAlert id={error} color="danger" />
        )}
        {(loading || refreshing) && (
          <Spinner className="d-flex ml-auto mr-auto" color="primary" />
        )}
        {!error && !loading && !refreshing && (
          <Table
            isLoading={loading}
            disabled={refreshing}
            data={data}
            refresh={refresh}
            columns={[
              {
                title: 'zoninggroupLabel',
                hasOrder: true,
                render: item => item.zoninggroupLabel
              },
              {
                title: 'delayLabel',
                hasOrder: true,
                render: renderDelay
              },
            ]}
          />
        )}
      </CardBody>
    </Card>
  )
}

export default VeloptimWaiting

