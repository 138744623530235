import React from 'react'
import { FormattedDate } from 'react-intl'
import { useHistory, useLocation, Link } from 'react-router-dom'

import {
  Alert, Button,
  Card, CardHeader, CardBody, CardFooter,
  Table, Form, FormGroup,
  Row, Col,
  ListGroup, ListGroupItem,
  Input,
  NavLink,
  Spinner,
  Modal, ModalBody, ModalFooter,
} from 'reactstrap'

import PhoneInput from 'react-phone-number-input'

import { AppContext } from 'contexts/AppContext'

import CustomSelect from '../../components/CustomSelect'
import TH from 'components/TH'
import Pagination from 'components/Pagination'
import { T, TAutocomplete, TButton, TLabel, TInput, TCustomInput, TAlert, ErrAlert } from 'components/TComponents'
import { useFormatMessage } from 'hooks/intl.hooks'

import { debounce } from 'util/Utils'

import ItvcodeSelector from 'components/ItvcodeSelector'

import ProtectedComponent from 'components/ProtectedComponent'

const createSwapReducer = (state, action) => {
  switch (action.type) {
  case 'init': return { creating: false, error: undefined }
  case 'create': return { creating: true, error: undefined }
  case 'created': return { creating: false, error: undefined }
  case 'error': return { creating: false, error: action.payload }
  default: return state
  }
}

const createSwapInitialState = {
  creating: false,
  error: undefined
}

const equipmentsReducer = (state, action) => {
  switch (action.type) {
  case 'load': return { loading: true, data: state.data, error: undefined }
  case 'loaded': return { loading: false, data: action.payload, error: undefined }
  case 'error': return { loading: false, data: undefined, error: action.payload }
  case 'init': return { loading: false, data: undefined, error: undefined }
  default: return state
  }
}

const equipmentsInitialState = {
  loading: true,
  error: undefined,
  data: undefined
}

const SwapNew = () => {
  const { api, constants, user, subsidiary } = React.useContext(AppContext)
  const formatMessage = useFormatMessage()

  const location = useLocation()
  const history = useHistory()

  const [createSwapState, createSwapDispatch] = React.useReducer(createSwapReducer, createSwapInitialState)
  const [equipmentsState, equipmentsDispatch] = React.useReducer(equipmentsReducer, equipmentsInitialState)

  const [equipmentsSearchInput, setEquipmentsSearchInput] = React.useState('')

  const [autocomplete, setAutocomplete] = React.useState('')
  const [addresses, setAddresses] = React.useState()
  const [addressesLoading, setAddressesLoading] = React.useState(true)
  const [addressesError, setAddressesError] = React.useState()
  const [selectedAddress, setSelectedAddress] = React.useState('')

  const [itvcodes, setItvcodes] = React.useState([])
  const [itvcodesLoading, setItvcodesLoading] = React.useState(true)
  const [itvcodesError, setItvcodesError] = React.useState()

  const [equipmentAdded, setEquipmentAdded] = React.useState()
  const [equipmentError, setEquipmentError] = React.useState()
  const [equipmentAlert, setEquipmentAlert] = React.useState()

  const [itvcodesBase, setItvcodesBase] = React.useState()
  const [locationId, setLocationId] = React.useState(0)
  const [information, setInformation] = React.useState('')
  const [claimNumber, setClaimNumber] = React.useState('')

  const [addressLabel, setAddressLabel] = React.useState('')
  const [addressComplement, setAddressComplement] = React.useState('')
  const [addressFull, setAddressFull] = React.useState('')
  const [mapId, setMapId] = React.useState('')

  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phoneNumber, setPhoneNumber] = React.useState('')

  const [businessId, setBusinessId] = React.useState()

  const [durationEstimated, setDurationEstimated] = React.useState(0)
  const [durationPlanned, setDurationPlanned] = React.useState(0)

  const [itvHourSelected, setSwapHourSelected] = React.useState(0)
  const [swapMinutesSelected, setSwapMinutesSelected] = React.useState(0)

  const swapHours = Array(9).fill().map((_, i) => i)
  const swapMinutes = Array(12).fill().map((_, i) => (i * 5))

  const [createdSwapTemp, setCreatedSwapTemp] = React.useState()
  const [swapCreatingVeloptim, setSwapCreatingVeloptim] = React.useState(false)

  // EQUIPMENTS MANAGMENT
  const getEquipments = React.useCallback(params => {
    return api.get('/equipments/list', undefined, {
      filter: equipmentsState.data ? equipmentsState.data.filter : '',
      businessId: equipmentsState.data && equipmentsState.data.filters.businessId.selected
        ? equipmentsState.data.filters.businessId.selected.value : undefined,
      categoryId: equipmentsState.data && equipmentsState.data.filters.categoryId.selected
        ? equipmentsState.data.filters.categoryId.selected.value : undefined,
      familyId: equipmentsState.data && equipmentsState.data.filters.familyId.selected
        ? equipmentsState.data.filters.familyId.selected.value : undefined,
      statusId: equipmentsState.data && equipmentsState.data.filters.statusId.selected
        ? equipmentsState.data.filters.statusId.selected[0].value : undefined,
      'order[column]': equipmentsState.data ? equipmentsState.data.order.column : undefined,
      'order[dir]': equipmentsState.data ? equipmentsState.data.order.dir : undefined,
      p: equipmentsState.data ? equipmentsState.data.currentPage : 1,
      ipp: 3,
      ...params
    })
      .then(equipments => equipmentsDispatch({ type: 'loaded', payload: equipments }))
      .catch(error => equipmentsDispatch({ type: 'error', payload: error }))
  }, [api, equipmentsState])

  // TECHADDRESS
  const addressHandleChange = React.useCallback(e => {
    const addressId = e ? e.addressId : undefined

    const addressSelected = addressId
      ? addresses.find(address => String(address.addressId) === String(addressId))
      : undefined

    setSelectedAddress(addressId)
    setAddressLabel(addressSelected ? addressSelected.addressLabel : '')
    setAddressComplement(addressSelected ? addressSelected.addressComplement : '')
    setAddressFull(addressSelected ? addressSelected.addressFull : '')
    setMapId(addressSelected ? addressSelected.mapId : '')
  }, [addresses])

  const handleChangeAddressFull = React.useCallback(e => {
    e.persist()
    setAddressFull(e.target.value)
    api.get('/maps/autocomplete', undefined, {
      input: e.target.value,
      ...(autocomplete && autocomplete.session_token && { session_token: autocomplete.session_token })
    })
      .then(response => {
        setAutocomplete(response)
      })
      .catch(error => console.log(error))
  }, [api, autocomplete])

  const handleAutocomplete = React.useCallback(e => {
    setAddressFull(e)
    api.get('/maps/placedetails', undefined, {
      place_id: autocomplete.results.find(r => r.addressFull === e).place_id,
      session_token: autocomplete.session_token,
      addressFull: e
    })
      .then(response => {
        setMapId(response.mapId)
      })
      .catch(error => console.log(error))
  }, [api, autocomplete])

  const handleSwapHoursChange = React.useCallback(({ target: { value } }) => {
    setDurationPlanned(swapMinutesSelected + Number(value) * 60)
  }, [swapMinutesSelected])

  const handleSwapMinutesChange = React.useCallback(({ target: { value } }) => {
    setDurationPlanned(Number(value) + itvHourSelected * 60)
  }, [itvHourSelected])

  // SWAP
  const create = React.useCallback(e => {
    e.preventDefault()

    const urlParams = new URLSearchParams(location.search)

    createSwapDispatch({ type: 'create' })
    return api.post('/swaps/new', {
      body: JSON.stringify({
        itvcodes: itvcodes.map(itvcode => ({
          itvcodeId: itvcode.itvcodeId,
          quantity: itvcode.quantity,
          wear: itvcode.wear,
          ...(itvcode.reasonId && itvcode.reasonListSwapDisplay && { reasonId: itvcode.reasonId })
        })),
        durationPlanned: durationPlanned || durationEstimated,
        equipmentId: equipmentAdded ? equipmentAdded.equipmentId : undefined,
        businessId: equipmentsState.data.filters.businessId.selected.value, // TODO: probably useless since business can be determined by equipment
        locationId,
        information: information || '',
        addressLabel, addressComplement, claimNumber,
        firstName, lastName, email, phoneNumber, mapId,
        ticketId: urlParams.get('ticketId'),
      })
    })
      .then(response => {
        createSwapDispatch({ type: 'created' })
        return response
      })
  }, [addressComplement, addressLabel, api, email, claimNumber, equipmentsState, equipmentAdded, firstName, information, itvcodes, lastName, location.search, locationId, phoneNumber, mapId, durationPlanned, durationEstimated])

  const createAndReturn = React.useCallback(e => {
    create(e)
      .then(() => history.push({
        pathname: '/swaps',
        search: location.state ? location.state.swaps : undefined
      }))
      .catch(error => createSwapDispatch({ type: 'error', payload: error }))
  }, [create, history, location])

  const createAndPlan = React.useCallback(e => {
    create(e)
      .then(swap => history.push(`/swaps/plan/${swap.swapId}`))
      .catch(error => createSwapDispatch({ type: 'error', payload: error }))
  }, [create, history])


  const createAndPlanVeloptim = React.useCallback(e => {
    setSwapCreatingVeloptim(true)
    create(e)
      .then(swap => setCreatedSwapTemp(swap))
      .catch(error => {
        createSwapDispatch({ type: 'error', payload: error })
        setSwapCreatingVeloptim(false)
      })
  }, [create])

  const createAndStart = React.useCallback(e => {
    create(e)
      .then(swap => history.push(`/swaps/start/${swap.swapId}`))
      .catch(error => createSwapDispatch({ type: 'error', payload: error }))
  }, [create, history])

  React.useEffect(() => {
    if (createdSwapTemp) {
      setSwapCreatingVeloptim(false)
      history.push({
        pathname: `/swaps/${createdSwapTemp.swapId}`,
        search: location.state ? location.state.swaps : undefined,
        veloptimModal: true
      })
    }
    setCreatedSwapTemp()
  }, [history, location, createdSwapTemp])

  const handleTransform = React.useCallback(e => {
    create(e)
      .then(response => {
        const cancelationReason = formatMessage({ id: `/swaps/transformFromItv`, raw: true })

        api.del('/swaps/details', undefined, { swapId: response.swapId, cancelationReason })
          .catch(error => createSwapDispatch({ type: 'error', payload: error }))
          .then(response => {
            createSwapDispatch({ type: 'created' })
            history.push(`/interventions/new?swapId=${response.swapId}`)
          })
      })
      .catch(error => createSwapDispatch({ type: 'error', payload: error }))
  }, [api, history, create, formatMessage])

  const getEquipmentsDebounced = React.useMemo(() =>
    debounce(params => getEquipments(params), 250)
  , [getEquipments])

  const equipmentsHandleSearchInput = React.useCallback(e => {
    setEquipmentsSearchInput(e.target.value)
    getEquipmentsDebounced({ filter: e.target.value, p: 1 })
  }, [getEquipmentsDebounced])

  const equipmentsOrder = React.useCallback((e, column) => {
    e.preventDefault()

    getEquipments({
      'order[column]': column,
      'order[dir]': equipmentsState.data.order.column === column && equipmentsState.data.order.dir === 'asc' ? 'desc' : 'asc',
    })
  }, [getEquipments, equipmentsState])

  const addEquipment = React.useCallback(equipment => {
    api.get('/equipments/usebyItvSwap', undefined, {
      equipmentId: equipment.equipmentId,
      businessId: equipment.businessId
    })
      .then(() => {
        api.get('/itvcodes/list', undefined, {
          ipp: -1,
          businessId: equipment.businessId,
          familyId: equipment.familyId,
          categoryId: equipment.categoryId
        })
          .then(response => setItvcodesBase(response))
          .then(() => setEquipmentAdded(equipment))
          .catch(error => setItvcodesError(error))
          .then(() => setItvcodesLoading(false))
      })
      .catch(error => {
        setEquipmentError(error)
      })

    api.get('/equipments/alerts', undefined, {
      equipmentId: equipment.equipmentId,
    })
      .then(response => setEquipmentAlert(response))
      .catch(error => setEquipmentError(error))
  }, [api])

  const removeEquipment = React.useCallback(() => {
    setItvcodes([])
    setEquipmentAdded()
  }, [])

  React.useEffect(() => {
    const urlParams = new URLSearchParams(location.search)

    if (urlParams.get('ticketId')) {
      api.get('/desk/details', undefined, { ticketId: urlParams.get('ticketId') })
        .then(ticket =>
          api.get('/equipments/list', undefined, { filter: ticket.equipmentIdentifier, ipp: 3 })
            .then(equipments => {
              equipmentsDispatch({ type: 'loaded', payload: equipments })

              setEquipmentsSearchInput(equipments.filters.filter)

              setClaimNumber(ticket.claimNumber)
              setInformation(ticket.issueDescription)

              setAddressLabel(ticket.addressLabel)
              setAddressFull(ticket.addressFull)
              setMapId(ticket.mapId)
              setAddressComplement(ticket.addressComplement)

              setFirstName(ticket.firstName)
              setLastName(ticket.lastName)
              setEmail(ticket.email)
              setPhoneNumber(ticket.phoneNumber)
            })
            .catch(error => equipmentsDispatch({ type: 'error', payload: error }))
        )
        .catch(() =>
          api.get('/equipments/list', undefined, { ipp: 3 })
            .then(equipments => equipmentsDispatch({ type: 'loaded', payload: equipments }))
            .catch(error => equipmentsDispatch({ type: 'error', payload: error }))
        )
    } else if (urlParams.get('interventionId')) {
      api.get('/interventions/details', undefined, { interventionId: urlParams.get('interventionId') })
        .then(intervention =>
          api.get('/equipments/list', undefined, { filter: intervention.equipmentIdentifier, ipp: 3 })
            .then(equipments => {
              equipmentsDispatch({ type: 'loaded', payload: equipments })
              api.get('/itvcodes/list', undefined, {
                ipp: -1,
                businessId: intervention.businessId,
                familyId: intervention.equipment.familyId,
                categoryId: intervention.equipment.categoryId
              })
                .then(response => {
                  setItvcodesBase(response)
                  setItvcodes(response.result.filter(r => intervention.itvcodes.map(i => i.itvcodeId).includes(r.itvcodeId)).map(i => ({ ...i, quantity: intervention.itvcodes.find(ii => ii.itvcodeId === i.itvcodeId).quantity, wear: intervention.itvcodes.find(ii => ii.itvcodeId === i.itvcodeId).wear })))
                })
                .then(() => setEquipmentAdded(intervention.equipment))
                .catch(error => setItvcodesError(error))
                .then(() => setItvcodesLoading(false))

              setDurationEstimated(intervention.durationEstimated)
              setDurationPlanned(intervention.durationPlanned)

              setClaimNumber(intervention.claimNumber)
              setInformation(intervention.information)

              setLocationId(intervention.locationId)

              setMapId(intervention.mapId)
              setAddressComplement(intervention.addressComplement)
              setAddressFull(intervention.addressFull)
              setAddressLabel(intervention.addressLabel)

              setFirstName(intervention.firstName)
              setLastName(intervention.lastName)
              setEmail(intervention.email)
              setPhoneNumber(intervention.phoneNumber)
            })
            .catch(error => equipmentsDispatch({ type: 'error', payload: error }))
        )
        .catch(() =>
          api.get('/equipments/list', undefined, { ipp: 3 })
            .then(equipments => equipmentsDispatch({ type: 'loaded', payload: equipments }))
            .catch(error => equipmentsDispatch({ type: 'error', payload: error }))
        )
    } else if (urlParams.get('equipmentId')) {
      api.get('/equipments/identity', undefined, { equipmentId: urlParams.get('equipmentId') })
        .then(equipment => addEquipment({ equipmentId: urlParams.get('equipmentId'), ...equipment }))
        .catch(() =>
          api.get('/equipments/list', undefined, { ipp: 3 })
            .then(equipments => equipmentsDispatch({ type: 'loaded', payload: equipments }))
            .catch(error => equipmentsDispatch({ type: 'error', payload: error }))
        )
    } else {
      api.get('/equipments/list', undefined, { ipp: 3 })
        .then(equipments => equipmentsDispatch({ type: 'loaded', payload: equipments }))
        .catch(error => equipmentsDispatch({ type: 'error', payload: error }))
    }
  }, [addEquipment, api, location.search, setEquipmentsSearchInput])

  React.useEffect(() => {
    if (equipmentsState.data && equipmentsState.data.filters.businessId.selected && businessId !== equipmentsState.data.filters.businessId.selected.value) {
      setBusinessId(equipmentsState.data.filters.businessId.selected.value)
    }
  }, [businessId, equipmentsState])

  React.useEffect(() => {
    if (businessId !== undefined) {
      setAddressesLoading(true)
      api.get('/swaps/addresses', undefined, { businessId })
        .then(response => setAddresses(response))
        .catch(response => setAddressesError(response))
        .then(() => setAddressesLoading(false))
    }
  }, [api, businessId])

  React.useEffect(() => {
    setSwapHourSelected(Math.trunc((durationPlanned || durationEstimated) / 60))
    setSwapMinutesSelected((durationPlanned || durationEstimated) % 60)
  }, [durationPlanned, durationEstimated])

  React.useEffect(() => {
    if (itvcodes.length > 0) {
      const body = {
        itvcodes: itvcodes.map(itvcode => ({
          itvcodeId: itvcode.itvcodeId,
          quantity: itvcode.quantity,
        }))
      }
      api.post('/interventions/timeItvcodes', { body: JSON.stringify(body) })
        .then(response => setDurationEstimated(response.durationEstimated))
    } else {
      setDurationEstimated(0)
    }
  }, [api, itvcodes])

  const _renderEquipmentSelector = React.useCallback(() => (
    <Card className="mb-3">
      <CardHeader>
        <Form onSubmit={e => e.preventDefault()}>
          <Row form>
            <Col sm="6" md="3">
              <FormGroup tag="fieldset">
                <TLabel for="equipmentsSearchInput" id="equipment.searchInput.label" className="" />
                <TInput
                  name="equipmentsSearchInput"
                  type="text"
                  placeholder="equipment.searchInput.placeholder"
                  value={equipmentsSearchInput}
                  onChange={e => equipmentsHandleSearchInput(e)} />
              </FormGroup>
            </Col>
            {equipmentsState.data.filters && equipmentsState.data.filters.businessId && equipmentsState.data.filters.businessId.values && <Col sm="6" md="3">
              <FormGroup tag="fieldset">
                <TLabel for="businessId" id="equipment.business.label" />
                <CustomSelect
                  inputId="businessId"
                  name="businessId"
                  onChange={e => getEquipments({ businessId: e && e.value })}
                  isDisabled={equipmentsState.loading}
                  options={equipmentsState.data.filters.businessId.values}
                  value={equipmentsState.data.filters.businessId.selected ? equipmentsState.data.filters.businessId.selected : ''}
                />
              </FormGroup>
            </Col>}
            {equipmentsState.data.filters && equipmentsState.data.filters.categoryId && equipmentsState.data.filters.categoryId.values && <Col sm="6" md="3">
              <FormGroup tag="fieldset">
                <TLabel for="categoryId" id="equipment.category.label" className="" />
                <CustomSelect
                  inputId="categoryId"
                  name="categoryId"
                  isClearable
                  options={equipmentsState.data.filters.categoryId.values}
                  onChange={e => getEquipments({ categoryId: e && e.value })}
                  placeholder={<T id="categoryPlaceholder" />}
                  isDisabled={equipmentsState.loading}
                  value={equipmentsState.data.filters.categoryId.selected || ''} />
              </FormGroup>
            </Col>}
            {equipmentsState.data.filters && equipmentsState.data.filters.familyId && equipmentsState.data.filters.familyId.values && <Col sm="6" md="3">
              <FormGroup tag="fieldset">
                <TLabel for="familyId" id="equipment.family.label" className="" />
                <CustomSelect
                  inputId="familyId"
                  name="familyId"
                  isClearable
                  options={equipmentsState.data.filters.familyId.values}
                  onChange={e => getEquipments({ familyId: e && e.value })}
                  placeholder={<T id="familyPlaceholder" />}
                  isDisabled={equipmentsState.loading}
                  value={equipmentsState.data.filters.familyId.selected || ''} />
              </FormGroup>
            </Col>}
            {equipmentsState.data.filters && equipmentsState.data.filters.statusId && equipmentsState.data.filters.statusId.values && <Col sm="6" md="3">
              <FormGroup tag="fieldset">
                <TLabel for="statusId" id="equipment.status.label" className="" />
                <CustomSelect
                  inputId="statusId"
                  name="statusId"
                  isClearable
                  options={equipmentsState.data.filters.statusId.values}
                  onChange={e => getEquipments({ statusId: e && e.value })}
                  placeholder={<T id="equipment.status.placeholder" />}
                  isDisabled={equipmentsState.loading}
                  value={equipmentsState.data.filters.statusId.selected || ''}
                  getOptionLabel={option => <T id={`equipmentStatus.${option.label}`} raw />} />
              </FormGroup>
            </Col>}
          </Row>
        </Form>
      </CardHeader>
      <Table responsive striped hover className="mb-0 mt-2">
        <thead>
          <tr><TH colSpan="7" className={['border-top-0']}
            colName="equipment.count"
            colValues={{ total: equipmentsState.data.total }} />
          </tr>
        </thead>
        <thead>
          <tr>
            <TH colName="equipmentIdentifier" order={equipmentsState.data.order} sort={equipmentsOrder} />
            <TH colName="equipmentBusiness" order={equipmentsState.data.order} sort={equipmentsOrder} />
            <TH colName="categoryName" order={equipmentsState.data.order} sort={equipmentsOrder} />
            <TH colName="familyName" order={equipmentsState.data.order} sort={equipmentsOrder} />
            <TH colName="dateCreated" order={equipmentsState.data.order} sort={equipmentsOrder} />
            <TH colName="equipmentStatus" />
            <TH />
          </tr>
        </thead>
        <tbody style={{ transform: `rotate(0)` }}>
          {equipmentsState.loading && <tr><td><div className="floating-spinner">
            <style dangerouslySetInnerHTML={{
              __html: `
                .floating-spinner {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                .floating-spinner:before {
                  content: '';
                  top: 0;
                  left: 0;
                  position: absolute;
                  background: black;
                  width: 100%;
                  height: 100%;
                  opacity: .1;
                }` }}>
            </style>
            <Spinner className="" color="primary" />
          </div></td></tr>}
          {equipmentsState.data.result.length > 0 && equipmentsState.data.result.map(equipment => {
            const alreadyAdded = equipmentAdded && Boolean(equipmentAdded.equipmentId === equipment.equipmentId)
            return <tr key={equipment.equipmentId}>
              <td>{equipment.equipmentIdentifier}</td>
              <td>{equipment.businessName}</td>
              <td>{equipment.categoryName}</td>
              <td>{equipment.familyName}</td>
              <td><FormattedDate value={new Date(equipment.dateCreated)} /></td>
              <td><T id={`equipmentStatus.${equipment.statusValue}`} raw /></td>
              <td>
                <Button className="ml-auto"
                  color={alreadyAdded ? 'primary' : 'secondary'}
                  disabled={alreadyAdded}
                  onClick={() => addEquipment(equipment)}>
                  <T id="addEquipment" />
                </Button>
              </td>
            </tr>
          })}
        </tbody>
        <tfoot>
          <tr><td colSpan="100" className="pb-0">
            <Pagination
              totalPage={equipmentsState.data.totalPage}
              currentPage={equipmentsState.data.currentPage}
              onPageClick={p => getEquipments({ p })} /></td></tr>
        </tfoot>
      </Table>
    </Card>
  ), [addEquipment, equipmentsState, equipmentAdded, equipmentsHandleSearchInput, equipmentsOrder, equipmentsSearchInput, getEquipments])

  const popupError = (createSwapState && createSwapState.error)
    ? createSwapState.error && ['alreadyMaintenanceEquipment'].includes(createSwapState.error.code) ? createSwapState.error : undefined
    : equipmentError && ['alreadyMaintenanceEquipment'].includes(equipmentError.code) ? equipmentError : undefined

  return (
    <div className="container-fluid SwapNew">
      <NavLink tag={Link} to={{
        pathname: '/swaps',
        search: location.state ? location.state.swaps : undefined
      }}>
        <T id="returnToList" />
      </NavLink>
      <Card className="mb-2">
        <CardBody>
          {!equipmentAdded
            ? <><TLabel id="equipment.add" />
              {!equipmentsState.error && equipmentsState.data && _renderEquipmentSelector()}
              {equipmentsState.error && <ErrAlert error={equipmentsState.error} />}
            </>
            : <>
              <TLabel id="equipmentList" />
              <ListGroup className="mb-3">
                <ListGroupItem className="d-flex align-items-center">
                  {equipmentAdded.equipmentIdentifier}
                  <TButton className="ml-auto pt-2 pb-2 mt-0"
                    onClick={() => removeEquipment()}
                    id="removeEquipment" />
                </ListGroupItem>
              </ListGroup>
            </>
          }

          {equipmentAlert && (
            <div className="mb-3 pt-2">
              {!!equipmentAlert.nbInfo && <TAlert color="info" id="nbInfo" values={{ value: equipmentAlert.nbInfo }} />}
              {!!equipmentAlert.nbMinor && <TAlert color="warning" id="nbMinor" values={{ value: equipmentAlert.nbMinor }} />}
              {!!equipmentAlert.nbMajor && <TAlert color="danger" id="nbMajor" values={{ value: equipmentAlert.nbMajor }} />}
              {(!!equipmentAlert.nbInfo || !!equipmentAlert.nbMinor || !!equipmentAlert.nbMajor) && equipmentAdded && (
                <TButton id="goToEquipmentLog"
                  tag={Link}
                  to={{
                    pathname: `/equipments/${equipmentAdded.equipmentId}/logs`,
                    state: location.state
                  }}
                  color="primary" />
              )}
            </div>
          )}

          {!equipmentAdded && <TAlert color="warning" id="noEquipmentAdded" />}

          <TLabel id="itvcodeList" />

          {!equipmentAdded && <TAlert color="warning" id="missingEquipmentForItvcode" />}

          {itvcodesError ? <ErrAlert error={itvcodesError} /> : equipmentsState.data && itvcodesBase &&
            <ItvcodeSelector
              hasBusiness={equipmentsState.data.filters.businessId.selected}
              loading={equipmentsState.loading || itvcodesLoading}
              itvcodesAdded={itvcodes}
              itvcodes={itvcodesBase.result}
              reason
              isSwap
              onChange={itvcodes => setItvcodes(itvcodes)} />}

          <>
            <TLabel id="durations" />
            <Card className="mb-4">
              <CardBody className="pb-3">
                <div className="row mb-2">
                  {/* Display /60 hour */}
                  <TLabel
                    id="durationEstimated"
                    values={{
                      hours: Math.floor(durationEstimated / 60),
                      minutes: durationEstimated % 60,
                    }}
                    className="col mb-2" />
                </div>
                {!!durationEstimated && (
                  <>
                    <TLabel id="durationPlanned" className="col mb-2 pl-0" />
                    <Row>
                      <Col>
                        <FormGroup tag="fieldset">
                          <TLabel for="selectHour" id="selectHour" />
                          <CustomSelect
                            inputId="selectHour"
                            name="selectHour"
                            options={swapHours}
                            placeholder={"0"}
                            onChange={e => handleSwapHoursChange({ target: { value: e } })}
                            value={swapHours.filter(h => h == itvHourSelected)}
                            getOptionValue={option => option}
                            getOptionLabel={option => option}
                            isDisabled={createSwapState.creating} />
                        </FormGroup>
                      </Col>

                      <Col>
                        <FormGroup tag="fieldset">
                          <TLabel for="selectMinute" id="selectMinute" />
                          <CustomSelect
                            inputId="selectMinute"
                            name="selectMinute"
                            options={swapMinutes}
                            onChange={e => handleSwapMinutesChange({ target: { value: e } })}
                            placeholder={"0"}
                            value={swapMinutes.filter(h => h == swapMinutesSelected)}
                            getOptionValue={option => option}
                            getOptionLabel={option => option}
                            isDisabled={createSwapState.creating} />
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                )}
              </CardBody>
            </Card>
          </>

          <FormGroup tag="fieldset">
            <TLabel id="claimNumber" for="claimNumber" />
            <Input id="claimNumber"
              type="text"
              name="claimNumber"
              disabled={createSwapState.creating}
              value={claimNumber}
              onChange={e => setClaimNumber(e.target.value)} />
          </FormGroup>

          <FormGroup tag="fieldset">
            <TLabel id="information" for="information" />
            <Input id="information"
              type="textarea"
              name="information"
              disabled={createSwapState.creating}
              value={information}
              onChange={e => setInformation(e.target.value)} />
          </FormGroup>

          <TLabel id="address.title" className="mb-1" />
          <Card className="mb-3">
            <CardBody>
              <FormGroup tag="fieldset">
                <TLabel for="addresses" className="mt-0" id="addresses.label" />
                {addressesLoading && <Spinner className="" color="primary" />}
                {!addressesLoading && addressesError && <ErrAlert error={addressesError} />}
                {!addressesLoading && !addressesError && (
                  <CustomSelect
                    inputId="addresses"
                    name="addresses"
                    options={addresses}
                    isClearable
                    onChange={addressHandleChange}
                    placeholder={<T id="addresses.manual" />}
                    value={addresses && addresses.filter(a => a.addressId === selectedAddress) || ''}
                    isDisabled={createSwapState.creating}
                    getOptionLabel={option => `${option.addressLabel} – ${option.addressFull} ${option.addressComplement && '- ' + option.addressComplement}`}
                    getOptionValue={option => option.addressId} />
                )}
              </FormGroup>

              <FormGroup tag="fieldset" className="flex-grow-1">
                <TLabel for="addressLabel" className="my-0" id="addressLabel" />
                <Input id="addressLabel"
                  className="w-100"
                  type="text"
                  name="addressLabel"
                  disabled={createSwapState.creating || selectedAddress}
                  value={addressLabel || ''}
                  onChange={e => setAddressLabel(e.target.value)} />
              </FormGroup>
              <FormGroup tag="fieldset" className="w-100">
                <TLabel for="addressFull" className="mb-2" id="addressFull" />
                <TAutocomplete id="addressFull"
                  className="w-100"
                  type="text"
                  name="addressFull"
                  disabled={createSwapState.creating || selectedAddress}
                  value={addressFull || ''}
                  placeholder="addressFullPlaceholder"
                  searchOnFocus={e => handleChangeAddressFull(e)}
                  onChange={e => handleChangeAddressFull(e)}
                  onAutocomplete={e => handleAutocomplete(e)}
                  options={
                    autocomplete && autocomplete.results.length > 0 && autocomplete.results.map(r => {
                      return (r.addressFull)
                    })} />
              </FormGroup>
              <FormGroup tag="fieldset" className="w-100 mb-0">
                <TLabel for="addressComplement" className="justify-content-start mb-0" id="addressComplement" />
                <TInput id="addressComplement"
                  className="w-100"
                  type="text"
                  name="addressComplement"
                  disabled={createSwapState.creating || selectedAddress}
                  value={addressComplement || ''}
                  placeholder="addressComplementPlaceholder"
                  onChange={e => setAddressComplement(e.target.value)} />
              </FormGroup>
            </CardBody>
          </Card>

          <FormGroup tag="fieldset">
            <TLabel id="locationId" for="locationId" />
            <div>
              {constants.swapLocation.map(type => <FormGroup key={type.key}
                check inline>
                <TCustomInput
                  id={`locationId-${type.key}`}
                  type="radio"
                  name="locationId"
                  label={`locationId.${type.key}`}
                  disabled={createSwapState.creating}
                  checked={Number(locationId) == type.value}
                  value={type.value}
                  onChange={e => setLocationId(e.target.value)} />
              </FormGroup>)}
            </div>
          </FormGroup>

          <TLabel id="contactTitle" className="mb-1" />
          <Card>
            <CardBody>
              <div className="d-flex">
                <FormGroup tag="fieldset" className=" w-100 flex-grow-1">
                  <TLabel for="firstName" className="my-0" id="firstName" />
                  <Input name="firstName"
                    type="text"
                    value={firstName}
                    disabled={createSwapState.creating}
                    onChange={e => setFirstName(e.target.value)} />
                </FormGroup>
                <FormGroup tag="fieldset" className="ml-3 w-100 flex-grow-1">
                  <TLabel for="lastName" className="my-0" id="lastName" />
                  <Input name="lastName"
                    type="text"
                    value={lastName}
                    disabled={createSwapState.creating}
                    onChange={e => setLastName(e.target.value)} />
                </FormGroup>
              </div>
              <div className="d-flex">
                <FormGroup tag="fieldset" className="w-100 flex-grow-1">
                  <TLabel for="email" className="my-0" id="email" />
                  <Input name="email"
                    type="text"
                    value={email}
                    disabled={createSwapState.creating}
                    onChange={e => setEmail(e.target.value)} />
                </FormGroup>
                <FormGroup tag="fieldset" className="ml-3 mb-0 w-100 flex-grow-1">
                  <TLabel id="phoneNumber" for="phoneNumber" className="my-0" />
                  <Input name="phoneNumber"
                    id="phoneNumber"
                    tag={PhoneInput}
                    defaultCountry="FR"
                    country="FR"
                    className="w-100 d-flex"
                    type="text"
                    value={phoneNumber}
                    disabled={createSwapState.creating}
                    onChange={value => setPhoneNumber(value)} />
                </FormGroup>
              </div>
            </CardBody>
          </Card>

          {!popupError && createSwapState.error && <Alert className="mt-4 mb-0" color="danger"><T id={`error.${createSwapState.error.code}`} raw /></Alert>}

          {popupError && <Modal isOpen={Boolean(popupError.code)}
            fade={false}
            toggle={() => createSwapDispatch({ type: 'init' })}>
            <T id={`error.${popupError.code}.${popupError.params.type}.body`} values={createSwapState.error ? createSwapState.error.params : equipmentError.params} tagName={ModalBody} />
            <ModalFooter>
              <TButton id={`error.${popupError.code}.${popupError.params.type}.cancel`}
                onClick={() => {
                  createSwapState.error
                    ? createSwapDispatch({ type: 'init' })
                    : setEquipmentError()
                }} />
              <TButton id={`error.${popupError.code}.${popupError.params.type}.confirm`}
                tag={Link}
                to={{
                  pathname: `/${popupError.params.type}/${popupError.params.id}`,
                  state: location.state
                }}
                className="ml-2 d-flex align-items-center" color="primary" />
            </ModalFooter>
          </Modal>}

        </CardBody>
        <CardFooter className="d-flex justify-content-between">
          <div>
            <ProtectedComponent rights={['interventions_transform-swaps']}>
              <TButton
                disabled={createSwapState.creating || swapCreatingVeloptim}
                className="mx-1"
                onClick={e => handleTransform(e)}
                id="transform" />
            </ProtectedComponent>
          </div>
          <div>
            <TButton disabled={createSwapState.creating || swapCreatingVeloptim} type="button" className="ml-2" color="primary" onClick={createAndReturn} id="createAndReturn" />
            {((user.enableVeloptim && subsidiary.enableVeloptim) || user.email == 'email-admin@test.tld') ? (
              <>
                <TButton disabled={createSwapState.creating || swapCreatingVeloptim} loading={createSwapState.creating || swapCreatingVeloptim} type="button" className="ml-2" color="primary" onClick={e => createAndPlanVeloptim(e)} id="createAndPlan" />
                <TButton disabled={createSwapState.creating || swapCreatingVeloptim} type="button" className="ml-2" color="primary" onClick={e => createAndPlan(e)} id="createAndPlanWithoutVeloptim" />
              </>
            ) : (
              <TButton disabled={createSwapState.creating || swapCreatingVeloptim} type="button" className="ml-2" color="primary" onClick={e => createAndPlan(e)} id="createAndPlan" />
            )}
            <TButton disabled={createSwapState.creating || swapCreatingVeloptim} type="cancel" tag={Link}
              className="ml-2"
              to={{
                pathname: '/swaps',
                search: location.state ? location.state.swaps : undefined
              }}
              id="cancel" />
            <ProtectedComponent rights={['swaps-end_web']}>
              <TButton disabled={createSwapState.creating || swapCreatingVeloptim} type="button" className="ml-2" color="primary" onClick={e => createAndStart(e)} id="createAndStart" />
            </ProtectedComponent>
          </div>
        </CardFooter>
      </Card>
    </div >
  )
}

export default SwapNew
