/* eslint-disable react/display-name */
import React from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'

import {
  Button, Card, CardHeader, CardBody, CardFooter,
  Form, FormGroup, Input,
  Spinner,
} from 'reactstrap'
import { AppContext, } from 'contexts/AppContext'

import {
  ErrAlert,
  TLabel, TButton, TAlert,
  Toption, TDatePicker, T
} from 'components/TComponents'
import { Table } from 'components/Table'
import { subYears } from 'date-fns'
import CustomSelect from 'components/CustomSelect'

const Stats = () => {
  const { api, constants } = React.useContext(AppContext)

  const [statList, setStatList] = React.useState()
  const [error, setStatListError] = React.useState()
  const [loading, setStatListLoading] = React.useState(true)

  const [selectedStat, setSelectedStat] = React.useState()

  const [exportError, setExportError] = React.useState()
  const [exportSuccess, setExportSuccess] = React.useState()

  const [statsArray, setStatsArray] = React.useState()
  const [statsArrayError, setStatsArrayError] = React.useState()

  React.useEffect(() => {
    api.get('/stats/list')
      .then(stats => {
        setStatList(stats)
        setSelectedStat(stats && stats.length > 0 ? { ...stats[0] } : undefined)
      })
      .catch(error => setStatListError(error))
      .then(() => setStatListLoading(false))
  }, [api, handleSelectedStat])

  React.useEffect(() => {
    getListExports()
  }, [getListExports, exportSuccess])

  const handleSelectedStat = React.useCallback(statName => {
    const foundStat = statList.find(stat => stat.name === statName)
    setSelectedStat({ ...foundStat })
  }, [statList])

  const handleFilterChange = React.useCallback((name, e) => {
    const { target: { value, }, } = e
    setSelectedStat(stat => ({
      ...stat,
      filters: stat.filters.map(filter => filter.name === name ? ({ ...filter, value }) : filter)
    }))
  }, [])

  const renderFilter = React.useCallback((stat, filter) => {
    switch (filter.type) {
    case 'date': return renderDate(stat, filter)
    case 'select': return renderFilterSelect(stat, filter)
    default: return <></>
    }
  }, [renderDate, renderFilterSelect])

  const renderDate = React.useCallback(({ name: statName }, { name, value }) => (
    <FormGroup tag="fieldset" key={`${statName}.${name}`}>
      <TLabel for={`${statName}.${name}`} id={`${statName}.${name}`} />
      {statName === 'equipments_simple' || statName === 'equipments' ? (
        <TDatePicker
          name={`${statName}.${name}`}
          wrapperClassName="w-100"
          selected={value}
          onChange={date => handleFilterChange(name, { target: { value: date } })}
          minDate={subYears(new Date(), 1)}
          // TODO: all DatePicker dateFormat shoudl be in translation. Maybe event a custom DatPicker component for that
          dateFormat="eeee dd/MM/yyyy"
        />
      ) : (
        <TDatePicker
          name={`${statName}.${name}`}
          wrapperClassName="w-100"
          selected={value}
          onChange={date => handleFilterChange(name, { target: { value: date } })}
          // TODO: all DatePicker dateFormat shoudl be in translation. Maybe event a custom DatPicker component for that
          dateFormat="eeee dd/MM/yyyy"
        />
      )}

    </FormGroup>
  ), [handleFilterChange])

  const renderFilterSelect = React.useCallback(({ name: statName }, { name, value, data }) => (
    <FormGroup tag="fieldset" key={`${statName}.${name}`}>
      <TLabel for={`${statName}.${name}`} id={`${statName}.${name}`} />
      {name === 'business' ? (
        <>
          <CustomSelect
            id="itvcodeSelected"
            className="flex-grow-1"
            onChange={e => handleFilterChange(name, { target: { value: e } })}
            isSearchable={true}
            name={name}
            options={data}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.value}
            value={value || data[0] } />
        </>
      ) : (
        <Input id={`${statName}.${name}`}
          type="select"
          name={name}
          value={value}
          onChange={e => handleFilterChange(name, e)}>
          {data && data.length > 0 && data.map(opt => opt.data
            ? <optgroup key={`${statName}.${name}.${opt.key || opt.name}`} label={opt.name} value={opt.key ? this.t(opt.key) : opt.name}>
              {opt && opt.data && opt.data.length > 0 && opt.data.map(subOpt => subOpt.key
                ? <Toption key={`${statName}.${name}.${opt.value}.${subOpt.value}`} id={`${statName}.${name}.${opt.key || opt.name}.${subOpt.key}`} value={subOpt.value} />
                : <option key={`${statName}.${name}.${opt.value}.${subOpt.value}`} value={subOpt.value}>{subOpt.name}</option>)}
            </optgroup>
            : opt.key
              ? <Toption key={`${statName}.${name}.${opt.value}`} value={opt.value} id={`${statName}.${name}.${opt.key}`} />
              : <option key={`${statName}.${name}.${opt.value}`} value={opt.value}>{opt.name}</option>
          )}
        </Input>
      )}
    </FormGroup>
  ), [handleFilterChange])

  const getListExports = React.useCallback(() => {
    return api.get('/stats/listExport')
      .then(response => {
        const data = {}
        data.result = response
        setStatsArray(data)
      })
      .catch(error => { setStatsArrayError(error.code) })
  }, [api])

  const handleExport = React.useCallback(() => {
    if (!selectedStat) return ''

    const params = selectedStat.filters.reduce((acc, itm) => {
      if (itm.value !== undefined) {
        acc[itm.name] = itm.value instanceof Date
          ? `${itm.value.getFullYear()}-${itm.value.getMonth() + 1}-${itm.value.getDate()}`
          : itm.value
      }
      return acc
    }, {})

    params.name = selectedStat.name

    // Hack for business
    if (params.business && params.business.value ) {
      params.business = params.business.value
    }

    api.post(`/stats/newTask`, {
      body: JSON.stringify(params)
    })
      .then(response => {
        setExportSuccess(true)
        setExportError()
        api.post('/stats/task', {
          body: JSON.stringify({ fileId: response.fileId })
        }).then(() => {
        }).catch(error => {
          setExportError(error.code)
          setExportSuccess()
        })
      })
      .catch(error => {
        setExportError(error.code)
        setExportSuccess()
      })
  }, [selectedStat, api])

  const handleRegenerate = React.useCallback(fileId => {
    api.post(`/stats/regenerate`, {
      body: JSON.stringify({ fileId: fileId })
    })
      .then(response => {
        setExportSuccess(true)
        setExportError()
        api.post('/stats/task', {
          body: JSON.stringify({ fileId: response.fileId })
        }).then(() => {
        }).catch(error => {
          setExportError(error.code)
          setExportSuccess()
        })
      })
      .catch(error => {
        setExportError(error.code)
        setExportSuccess()
      })
  }, [api])

  if (error) { return <ErrAlert error={error} /> }
  if (loading) { return <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" /> }

  return (
    <div className="container-fluid">
      {statList.length === 0 && <TAlert id="noStats" color="warning" />}
      {statList.length > 0 && <Card>
        <CardHeader>
          <TLabel for="selectedStat" id="selectedStat" />
          <Input name="selectedStat"
            type="select"
            value={selectedStat && selectedStat.name}
            onChange={e => handleSelectedStat(e.target.value)}>
            {statList.map((stat) => <Toption key={stat.name} value={stat.name} id={stat.name} />)}
          </Input>
        </CardHeader>
        {selectedStat && <CardBody>
          <Form onSubmit={e => e.preventDefault()}>
            {selectedStat.filters.map(filter => renderFilter(selectedStat, filter))}
          </Form>
        </CardBody>}
        {(exportError || exportSuccess || statsArrayError) && <CardBody className="Section">
          <>
            {exportError && <TAlert id={`error.${exportError}`} color="danger" />}
            {exportSuccess && <TAlert id={`exportSuccess`} color="success" />}
            {statsArrayError && <TAlert id={statsArrayError} color="danger" />}
          </>
        </CardBody>}
        {selectedStat && <CardFooter className="text-right">
          <TButton
            id="generateExport"
            onClick={handleExport} />
        </CardFooter>}
      </Card>}

      {!loading && !statsArrayError && (
        <>
          {statsArray && statsArray.result && statsArray.result.length > 0 && (
            <Table
              isLoading={loading}
              pagination={false}
              count={false}
              data={statsArray}
              columns={[
                {
                  title: 'name',
                  hasOrder: true,
                  render: item => <T id={item.name} />
                },
                {
                  title: 'status',
                  hasOrder: true,
                  render: item => <T id={constants.statFileStatus.find(s => s.value === item.status).key} />
                },
                {
                  title: 'business',
                  hasOrder: true,
                  render: item => item.params.business && item.params.business !== undefined && selectedStat && selectedStat.filters && selectedStat.filters.length > 0 && selectedStat.filters.find(filter => filter.name === "business") && selectedStat.filters.find(filter => filter.name === "business").data.find(d => d.value == item.params.business) && selectedStat.filters.find(filter => filter.name === "business").data.find(d => d.value == item.params.business).name
                },
                {
                  title: 'day',
                  hasOrder: true,
                  render: item => item.params.day !== undefined && <FormattedDate value={new Date(item.params.day)} />
                },
                {
                  title: 'dayStart',
                  hasOrder: true,
                  render: item => item.params.dayStart !== undefined && <FormattedDate value={new Date(item.params.dayStart)} />
                },
                {
                  title: 'dayEnd',
                  hasOrder: true,
                  render: item => item.params.dayEnd !== undefined && <FormattedDate value={new Date(item.params.dayEnd)} />
                },
                {
                  title: 'expirates_at',
                  hasOrder: true,
                  render: item => <><FormattedDate value={new Date(item.expirates_at)} />{' '}<FormattedTime value={new Date(item.expirates_at)} /></>
                },
                {
                  title: 'dateCreated',
                  hasOrder: true,
                  render: item => <><FormattedDate value={new Date(item.dateCreated)} />{' '}<FormattedTime value={new Date(item.dateCreated)} /></>
                },
                {
                  title: 'downloadLink',
                  render: item => item.downloadLink ? <Button
                    className="d-flex"
                    style={{ alignItems: 'center', justifyContent: 'center' }}
                    tag="a"
                    target="_blank"
                    href={item.downloadLink}>
                    <i style={{ fontSize: 16 }} className="simple-icon-docs mb-0 mr-2" />
                    <T id="exportButton" />
                  </Button> : <></>
                },
                {
                  title: 'regenerate',
                  render: item => item.downloadLink && item.fileId ? <TButton
                    id="regenerate"
                    className="d-flex"
                    style={{ alignItems: 'center', justifyContent: 'center' }}
                    onClick={() => handleRegenerate(item.fileId)} />
                    : <></>
                }
              ]}
            />
          )}
        </>
      )
      }
    </div >
  )
}

export default Stats
