import React from 'react'
import { Card, CardBody, CardFooter, FormGroup } from 'reactstrap'
import { useHistory, Link } from 'react-router-dom'

import { AppContext } from 'contexts/AppContext'

import CustomSelect from 'components/CustomSelect'
import { TLabel, TButton, ErrAlert, TNavLink } from 'components/TComponents'

const PartNew = () => {

  const { api } = React.useContext(AppContext)
  const history = useHistory()

  const [warehouseId, setWarehouseId] = React.useState()
  const [partId, setPartId] = React.useState()

  const [warehouses, setWarehouses] = React.useState()
  const [warehousesLoading, setWarehousesLoading] = React.useState()
  const [warehousesError, setWarehousesError] = React.useState()
  const [parts, setParts] = React.useState()
  const [partsLoading, setPartsLoading] = React.useState()
  const [partsError, setPartsError] = React.useState()

  const [createError, setCreateError] = React.useState(false)
  const [createLoading, setCreateLoading] = React.useState(false)

  const error = warehousesError || partsError

  React.useEffect(() => {
    getWarehouses()
    getParts()
  }, [getWarehouses, getParts])

  const getWarehouses = React.useCallback(() => {
    setWarehousesLoading(true)
    api.get('/warehouses/list', undefined, { ipp: -1 })
      .then(response => setWarehouses(response))
      .catch(error => setWarehousesError(error))
      .then(() => setWarehousesLoading(false))
  }, [api])

  const getParts = React.useCallback(() => {
    setPartsLoading(true)
    api.get('/parts/list', undefined, { ipp: -1 })
      .then(response => setParts(response))
      .catch(error => setPartsError(error))
      .then(() => setPartsLoading(false))
  }, [api])

  const postInventory = React.useCallback(() => {
    setCreateError(false)
    setCreateLoading(true)
    api.post('/inventories/details', {
      body: JSON.stringify({
        warehouseId,
        partId
      })
    })
      .then(response => {
        if (response && response.code) {
          setCreateError(response)
        } else {
          history.push('/veloparts/inventories')
        }
      })
      .catch(error => setCreateError(error))
      .then(() => setCreateLoading(false))
  }, [api, warehouseId, partId, history])

  return (
    <>
      <TNavLink id="returnToList" tag={Link} to="/veloparts/inventories" />
      <Card>
        <CardBody>
          {error && <ErrAlert error={error} />}
          {warehouses && warehouses.result && warehouses.result.length > 0 && warehouses && parts && parts.result && parts.result.length > 0 && (
            <>
              <FormGroup tag="fieldset">
                <TLabel for="warehouseId" id="warehouseId" />
                <CustomSelect
                  inputId="warehouseId"
                  name="warehouseId"
                  options={warehouses.result}
                  onChange={e => { setWarehouseId(e.warehouseId) }}
                  value={warehouses.result.filter(w => w.warehouseId === warehouseId)}
                  isDisabled={warehousesLoading}
                  getOptionLabel={option => `${option.warehouseRef} - ${option.warehouseLabel}`}
                  getOptionValue={option => option.warehouseId} />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel for="partId" id="partId" />
                <CustomSelect
                  inputId="partId"
                  name="partId"
                  options={parts.result}
                  onChange={e => { setPartId(e.partId) }}
                  value={parts.result.filter(p => p.partId === partId)}
                  isDisabled={partsLoading}
                  getOptionLabel={option => `${option.partRef} - ${option.partLabel}`}
                  getOptionValue={option => option.partId} />
              </FormGroup>
            </>
          )}
          {createError && <ErrAlert error={createError} />}
        </CardBody>
        <CardFooter className="d-flex justify-content-end">
          <TButton
            disabled={createLoading}
            spin={createLoading}
            className="ml-2"
            onClick={postInventory}
            id="add" />
        </CardFooter>
      </Card>
    </>
  )
}

export default PartNew
