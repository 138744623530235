function getGroupOrders(groups) {
  return groups.reduce((acc, group, index) => {
    acc[group.id] = { index, group }
    return acc
  }, {})
}

const EPSILON = 0.001

function collisionHorizontal(a, b) {
  // 2d collisions detection - https://developer.mozilla.org/en-US/docs/Games/Techniques/2D_collision_detection

  return (
    a.collisionLeft + EPSILON < b.collisionLeft + b.collisionWidth &&
    a.collisionLeft + a.collisionWidth - EPSILON > b.collisionLeft &&
    a.top + EPSILON < b.top + b.height &&
    a.top + a.height - EPSILON > b.top
  )
}

function collisionVertical(a, b) {
  return a.collisionTop + EPSILON < b.collisionTop + b.collisionHeight &&
    a.collisionTop + a.collisionHeight - EPSILON > b.collisionTop
}

/**
 * Calculate the position of a given item for a group that
 * is being stacked
 */
function groupStack(
  lineHeight,
  groupItems, groupHeight, groupOffset,
  itemIndex,
  nbGroups, groupIndex,
  direction,
) {
  // calculate non-overlapping positions
  const item = groupItems[itemIndex]

  let curHeight = groupHeight

  if (direction === 'horizontal') {
    item.dimensions.top = groupOffset

    curHeight = Math.max(curHeight, lineHeight)

    if (item.item.stackable === false) {
      return curHeight
    }

    let collidingItem
    do {
      collidingItem = undefined
      //Items are placed from i=0 onwards, only check items with index < i
      for (let j = itemIndex - 1; j >= 0; j--) {
        const other = groupItems[j]

        if (other.dimensions.top !== null &&
          collisionHorizontal(item.dimensions, other.dimensions)
        ) {
          collidingItem = other
          break
        } else {
          // TODO: other.top !== null, other !== item, other.stack
        }
      }

      if (collidingItem !== undefined) {
        // There is a collision. Reposition the items above the colliding element
        item.dimensions.top = collidingItem.dimensions.top + lineHeight
        curHeight = Math.max(
          curHeight,
          item.dimensions.top + item.dimensions.height - groupOffset
        )
      }
    } while (collidingItem)
  } else {
    if (item.item.stackable === false) {
      return curHeight
    }

    let k = groupItems.reduce((acc, curr, index) =>
      (curr.item.stackable !== false && index !== itemIndex && collisionVertical(item.dimensions, curr.dimensions))
        ? {
          position: index > itemIndex ? acc.position + 1 : acc.position,
          nb: acc.nb + 1
        }
        : acc
    , { position: 0, nb: 1 })

    item.dimensions.left = `${100 / nbGroups * groupIndex + (100 / nbGroups * (k.position / k.nb))}%`
    item.dimensions.width = `${100 / nbGroups / k.nb}%`
  }

  return curHeight
}

/**
 * Stack all groups
 */
function stackAll(itemsDimensions, groupOrders, lineHeight, direction, customHeight) {
  const groupedItems = Object.entries(groupOrders).reduce((acc, entry,) => {
    acc[Number(entry[1].index)] = {
      ...entry[1],
      items: itemsDimensions.filter(item => item.dimensions.order && Number(entry[1].index) === Number(item.dimensions.order.index))
    }
    return acc
  }, {})

  const nbGroups = Object.keys(groupedItems).length

  const groupHeights = []
  for (let index in groupedItems) {
    const groupItems = groupedItems[index]
    const { items: itemsDimensions, group } = groupItems
    const groupPosition = groupHeights.reduce((acc, i) => acc + i, 0)

    var groupHeight = 0

    // Find positions for each item in group
    for (let itemIndex = 0; itemIndex < itemsDimensions.length; itemIndex++) {
      groupHeight = groupStack(
        lineHeight,
        itemsDimensions,
        groupHeight,
        groupPosition,
        itemIndex,
        nbGroups,
        index,
        direction,
      )
    }

    // Group unstackable
    for (let itemIndex = 0; itemIndex < itemsDimensions.length; itemIndex++) {
      const item = itemsDimensions[itemIndex]
      if (item.item.stackable === false) {
        if (direction === 'horizontal') {
          item.dimensions.top = groupPosition
          item.dimensions.height = groupHeight
        } else {
          item.dimensions.left = `${100 / nbGroups * index}%`
          item.dimensions.width = `${100 / nbGroups}%`
        }
      }
    }

    if (group.customHeight) {
      groupHeights.push(Math.max(groupHeight, lineHeight, customHeight))
    } else {
      group.height ? groupHeights.push(group.height) : groupHeights.push(Math.max(groupHeight, lineHeight))
    }
  }
  return groupHeights
}

/**
 * get item's position, dimensions and collisions
 * */
function getItemDimensions({
  item,
  canvasTimeStart,
  canvasTimeEnd,
  groupOrders,
  lineHeight,
  itemHeightRatio,
  direction,
}) {
  // TODO: Use real width
  // Use item ref + scrollWidth
  // const itemTimeRange = itemTimeEnd - item.timeStart
  const minItemDuration = 50 * 60 * 1000
  const naturalItemDuration = item.timeEnd - item.timeStart
  const itemTimeRange = Math.max(minItemDuration, naturalItemDuration)

  const effectiveStartTime = Math.max(item.timeStart, canvasTimeStart)
  const effectiveEndTime = Math.min(item.timeEnd, canvasTimeEnd)

  const canvasDuration = canvasTimeEnd - canvasTimeStart
  const timeStart = effectiveStartTime - canvasTimeStart
  const timeStartPercent = timeStart * 100 / canvasDuration

  const itemDuration = effectiveEndTime - effectiveStartTime
  const itemDurationPercent = itemDuration * 100 / canvasDuration

  let dimensions = (direction === 'horizontal')
    ? {
      left: `${timeStartPercent}%`,
      width: `${itemDurationPercent}%`,
      collisionLeft: item.timeStart,
      collisionWidth: itemTimeRange
    }
    : {
      top: `${timeStartPercent}%`,
      height: `${itemDurationPercent}%`,
      collisionTop: item.timeStart,
      collisionHeight: itemTimeRange
    }

  if (dimensions) {
    if (direction === 'horizontal') {
      dimensions.top = null
      dimensions.order = groupOrders[item.group]
      dimensions.height = lineHeight * itemHeightRatio
    } else {
      dimensions.left = null
      dimensions.order = groupOrders[item.group]
    }

    return { id: item.id, dimensions, item }
  }
}

/**
 * For a given x position (leftOffset) in pixels, calculate time based on
 * timeline state (timeline width in px, canvas time range)
 */
export function calculateTimeForPosition(canvasTimeStart, canvasTimeEnd, canvasWidth, leftOffset) {
  const timeToPxRatio = (canvasTimeEnd - canvasTimeStart) / canvasWidth
  const timeFromCanvasTimeStart = timeToPxRatio * leftOffset

  return timeFromCanvasTimeStart + canvasTimeStart
}

export function stackTimelineItems(
  items, groups,
  canvasTimeStart, canvasTimeEnd,
  direction,
  lineHeight, itemHeightRatio, customHeight
) {
  // if there are no groups return an empty array of dimensions
  if (groups.length === 0) {
    return {
      dimensionItems: [],
      height: 0,
      groupHeights: [],
      groupTops: []
    }
  }

  // Get the order of groups based on their id key
  const groupOrders = getGroupOrders(groups)
  let dimensionItems = items
    .filter(item => groupOrders[item.group])
    .map(item => getItemDimensions({
      item,
      canvasTimeStart, canvasTimeEnd,
      groupOrders,
      lineHeight, itemHeightRatio,
      direction,
    }))
    .filter(item => !!item)

  // Get a new array of groupOrders holding the stacked items
  const groupHeights = stackAll(
    dimensionItems,
    groupOrders,
    lineHeight,
    direction,
    customHeight,
  )

  if (direction === 'horizontal') {
    for (let itemIndex = 0; itemIndex < dimensionItems.length; itemIndex++) {
      const item = dimensionItems[itemIndex]
      const itemHeight = groupHeights[item.dimensions.order.index]
      // TODO: if horizontal
      item.dimensions.top = `${item.dimensions.top}px`
      if (customHeight === itemHeight) {
        item.dimensions.height = `${itemHeight - 1}px`
      } else {
        item.dimensions.height = `${item.dimensions.height - 1}px`
      }
    }
  }

  return { dimensionItems, groupHeights }
}
