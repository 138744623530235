import React from 'react'

import { Card, Spinner } from 'reactstrap'

import { AppContext } from 'contexts/AppContext'
import { ErrAlert } from 'components/TComponents'

import UserCalendar from '../UserCalendar'

const userReducer = (state, action) => {
  switch (action.type) {
  case 'init': return {
    user: action.payload,
    baseUser: action.payload
  }
  case 'update': return {
    ...state,
    user: {
      ...state.user,
      ...action.payload
    }
  }
  case 'reset': return {
    ...state,
    user: state.baseUser
  }
  default:
    return state
  }
}

const Calendar = ({ match }) => {
  const { api, user } = React.useContext(AppContext)

  const [loading, setLoading] = React.useState(true)
  const [loadError, setLoadError] = React.useState()

  const [userState, userDispatch] = React.useReducer(userReducer, undefined)

  React.useEffect(() => {
    Promise.all([api.get('/users/details', undefined, { userId: user.userId })])
      .then(([user]) => userDispatch({ type: 'init', payload: user }))
      .catch(response => setLoadError(response))
      .then(() => setLoading(false))
  }, [api, match, user])

  return (
    <>
      {loadError && <ErrAlert error={loadError} className="mt-2" />}
      {loading && <Spinner className="d-flex ml-auto mr-auto" color="primary" />}
      <Card>
        {userState && <UserCalendar userId={userState.baseUser.userId} userType="user" />}
      </Card>
    </>
  )
}

export default Calendar
