import React from 'react'
import {
  Nav,
  NavItem,
  TabContent,
  NavLink,
  TabPane
} from 'reactstrap'
import { Redirect, Switch, NavLink as RRNavLink } from 'react-router-dom'

import ProtectedComponent from 'components/ProtectedComponent'
import Route from 'components/Route'
import { T } from 'components/TComponents'

import ShopList from './Shop/ShopList'
import ShopNew from './Shop/ShopNew'
import ShopEdit from './Shop/ShopEdit'
import PartList from './Part/PartList'
import PartNew from './Part/PartNew'
import PartEdit from './Part/PartEdit'
import StockList from './Stock/StockList'
import StockNew from './Stock/StockNew'
import StockEdit from './Stock/StockEdit'

const Veloparts = ({ match }) => {
  return (
    <div className="container-fluid">
      <Nav tabs className="mb-3">
        <div className="navitem-container">
          <NavItem>
            <NavLink
              tag={RRNavLink}
              activeClassName="active"
              to="/veloparts/inventories">
              <T id="inventories" />
            </NavLink>
          </NavItem>
          <ProtectedComponent rights={['veloparts-warehouses_view']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/veloparts/shops">
                <T id="shops" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
          <ProtectedComponent rights={['veloparts-parts_view']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/veloparts/parts">
                <T id="parts" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
        </div>
      </Nav>

      <TabContent>
        <Switch>
          <TabPane tag={Route} exact path={`${match.path}/inventories`} component={StockList} />
          <TabPane tag={Route} exact path={`${match.path}/inventories/new`} component={StockNew} />
          <Route path={`${match.path}/inventories/:id`} component={StockEdit} />
          <TabPane tag={Route} exact path={`${match.path}/shops`} component={ShopList} />
          <TabPane tag={Route} exact path={`${match.path}/shops/new`} component={ShopNew} />
          <Route path={`${match.path}/shops/:id`} component={ShopEdit} />
          <TabPane tag={Route} exact path={`${match.path}/parts`} component={PartList} />
          <TabPane tag={Route} exact path={`${match.path}/parts/new`} component={PartNew} />
          <Route path={`${match.path}/parts/:id`} component={PartEdit} />
          <TabPane tag={Redirect} to={`veloparts/inventories/`} />
        </Switch>
      </TabContent>
    </div>
  )
}

export default Veloparts
