import React from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'
import { NavLink } from 'react-router-dom'
import { objectToQuery } from 'react-rest-api'

import {
  Button,
  Table, Card, CardHeader,
  Form, FormGroup,
  Spinner
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import Pagination from 'components/Pagination'
import TH from 'components/TH'
import { T, TButton, TLabel, TInput, ErrAlert } from 'components/TComponents'
import TableInfos from 'components/TableInfos'
import FilterSelect from 'components/FilterSelect'

import { debounce } from 'util/Utils'

const AssignmentRequests = ({ history, location }) => {
  const { api } = React.useContext(AppContext)

  const [assignmentRequests, setAssignmentRequests] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [loadError, setLoadError] = React.useState() // TODO: display err
  const [debounceWait, setDebounceWait] = React.useState(false)
  const [searchInput, setSearchInput] = React.useState('')

  React.useEffect(() => {
    setLoadError()
    setLoading(true)
    setSearchInput(location.state ? location.state.filter : '')
    api.get('/fluow/assignRequList', undefined, { isAssigned: false, ...location.state })
      .then(response => setAssignmentRequests(response))
      .catch(response => setLoadError(response))
      .then(() => setLoading(false))
  }, [api, location.state])

  React.useEffect(() => {
    history.replace({ search: objectToQuery({ ...location.state }), state: location.state })
  }, [history, location.state])

  const refresh = React.useCallback((params = {}) => {
    history.push({
      search: location.search,
      state: { ...location.state, ...params }
    })
  }, [history, location.search, location.state])

  const handleChange = React.useCallback((value, name) => refresh({ p: 1, [name]: value }), [refresh])
  const handleIpp = React.useCallback(ipp => refresh({ p: 1, ipp }), [refresh])
  const handlePageClick = React.useCallback(p => refresh({ p }), [refresh])

  const order = React.useCallback((e, column) => {
    e.preventDefault()

    refresh({
      'order[column]': column,
      'order[dir]': assignmentRequests.order.column === column && assignmentRequests.order.dir === 'asc' ? 'desc' : 'asc',
      p: assignmentRequests.currentPage
    })
  }, [assignmentRequests, refresh])

  const refreshDebounced = React.useMemo(() =>
    debounce(params => {
      refresh(params)
      setDebounceWait(false)
    }, 250)
  , [refresh])

  const handleSearchInput = React.useCallback(({ target: { value } }) => {
    setSearchInput(value)
    setDebounceWait(true)
    refreshDebounced({ p: 1, filter: value })
  }, [refreshDebounced])

  const isLoading = loading || debounceWait

  if (loadError) {
    return <ErrAlert error={loadError} />
  }

  return (
    <>
      <Card className="mb-2">
        <CardHeader>
          <Form onSubmit={e => e.preventDefault()}
            className="d-flex flex-wrap align-items-end">
            <FormGroup tag="fieldset"
              className="mb-0 col-sm-6 col-md-3">
              <TLabel id="searchInputLabel" for="searchInput" />
              <TInput id="searchInput"
                type="text"
                name="searchInput"
                placeholder="searchInputPlaceholder"
                value={searchInput}
                onChange={handleSearchInput} />
            </FormGroup>
            {assignmentRequests && assignmentRequests.filters && assignmentRequests.filters.assigned && assignmentRequests.filters.assigned.values && (
              <FormGroup tag="fieldset"
                style={{ minWidth: 150 }}
                className="pr-3 mb-0 col-sm-6 col-md-3">
                <TLabel id="isAssignedLabel" for="isAssigned" />
                <FilterSelect name="isAssigned"
                  value={assignmentRequests.filters.assigned.selected}
                  options={assignmentRequests.filters.assigned.values}
                  getOptionLabel={option => <T id={`isAssigned.${option.label}`} />}
                  onChange={value => handleChange(value, 'isAssigned')} />
              </FormGroup>
            )}
            {assignmentRequests && assignmentRequests.filters && assignmentRequests.filters.categoryId && assignmentRequests.filters.categoryId.values && (
              <FormGroup tag="fieldset"
                style={{ minWidth: 150 }}
                className="pr-3 mb-0 col-sm-6 col-md-3">
                <TLabel id="categoryIdLabel" for="categoryId" />
                <FilterSelect name="categoryId"
                  value={assignmentRequests.filters.categoryId.selected}
                  options={assignmentRequests.filters.categoryId.values}
                  isClearable
                  onChange={value => handleChange(value, 'categoryId')} />
              </FormGroup>
            )}
            {assignmentRequests && assignmentRequests.filters && assignmentRequests.filters.familyId && assignmentRequests.filters.familyId.values && (
              <FormGroup tag="fieldset"
                style={{ minWidth: 150 }}
                className="pr-3 mb-0 col-sm-6 col-md-3">
                <TLabel id="familyIdLabel" for="familyId" />
                <FilterSelect name="familyId"
                  value={assignmentRequests.filters.familyId.selected}
                  options={assignmentRequests.filters.familyId.values}
                  isClearable
                  onChange={value => handleChange(value, 'familyId')} />
              </FormGroup>
            )}
            {assignmentRequests && assignmentRequests.transportExportLink && <Button tag="a"
              className="mt-3"
              color="primary" target="_blank"
              href={assignmentRequests.transportExportLink}>
              <i className="mr-2 iconsmind-Tag-3" />
              <T id="transportExportLink" />
            </Button>}
          </Form>
        </CardHeader>
      </Card>
      {isLoading && <Spinner className="d-absolute mx-auto my-5" color="primary" />}
      {!isLoading && <Table responsive striped hover className="mb-3 mt-2">
        <thead>
          <tr>
            <TH colSpan="8" className={['border-top-0']}>
              <TableInfos
                data={assignmentRequests}
                onChange={handleIpp} />
            </TH>
          </tr>
        </thead>
        <thead>
          <tr>
            <TH colName="dateCreated" order={assignmentRequests.order} sort={order} disabled={isLoading} />
            <TH colName="categoryFamilyName" />
            <TH colName="postalCode" order={assignmentRequests.order} sort={order} disabled={isLoading} />
            <TH colName="assignRequId" />
            <TH colName="isAssigned" />
            <TH colName="deliveryDate" order={assignmentRequests.order} sort={order} disabled={isLoading} />
            <TH />
          </tr>
        </thead>
        <tbody>
          {assignmentRequests.result.length > 0 && assignmentRequests.result.map(equipment =>
            <tr key={equipment.assignRequId} disabled={isLoading}
              style={{ transform: 'rotate(0)' }}>
              <td><FormattedDate value={new Date(equipment.dateCreated)} />{' '}<FormattedTime value={new Date(equipment.dateCreated)} /></td>
              <td>
                <div className="d-flex flex-column">
                  <span>{`${equipment.categoryName} / ${equipment.familyName}`}</span>
                  <span>
                    <T id="products" />
                    {equipment.products.length > 0 ? equipment.products.map(product => product.codeLabel).join(', ') : '-'}
                  </span>
                </div>
              </td>
              <td>{equipment.postalCode}</td>
              <td>{equipment.equipmentIdentifier}</td>
              <td><T id={`isAssigned${equipment.isAssigned ? 'Yes' : 'No'}`} /></td>
              <td>
                {equipment.deliveryDate}
              </td>
              <td>
                <TButton className="ml-auto stretched-link"
                  outline
                  id="view"
                  disabled={isLoading}
                  tag={NavLink}
                  to={{
                    pathname: `./assignments/${equipment.assignRequId}`,
                    state: { assignmentsState: location.state }
                  }} />
              </td>
            </tr>)}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="100">
              <Pagination totalPage={assignmentRequests.totalPage}
                currentPage={assignmentRequests.currentPage}
                onPageClick={handlePageClick} />
            </td>
          </tr>
        </tfoot>
      </Table>}
    </>
  )
}

export default AssignmentRequests
