import React from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'

import {
  Card, CardBody, CardHeader, CardFooter,
  Form, FormGroup, Input,
  NavLink, UncontrolledPopover, PopoverBody,
  Modal, ModalBody, ModalFooter,
  Spinner, Label
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

import CustomSelect from 'components/CustomSelect'
import ProtectedComponent from 'components/ProtectedComponent'
import { T, TAlert, TAutocomplete, ErrAlert, TLabel, TButton, TCustomInput } from 'components/TComponents'

const SelectWrapper = props => {
  return (
    <CustomSelect
      isSearchable={true}
      isClerable={true}
      placeholder={'...'}
      {...props} />
  )
}

const BusinessDetails = () => {
  const { api, subsidiary } = React.useContext(AppContext)
  const routeParams = useParams()
  const history = useHistory()

  const creating = React.useMemo(() => routeParams.id === undefined, [routeParams])

  const [business, setBusiness] = React.useState()
  const [businessBase, setBusinessBase] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()
  const [updating, setUpdating] = React.useState(false)
  const [updateError, setUpdateError] = React.useState()
  const [archiving, setArchiving] = React.useState(false)
  const [archiveError, setArchiveError] = React.useState()

  const [autocomplete, setAutocomplete] = React.useState('')
  const [enableChecklist, setEnableChecklist] = React.useState(undefined)

  const [bases, setBases] = React.useState()
  const [basesLoading, setBasesLoading] = React.useState(false)
  const [basesError, setBasesError] = React.useState()

  const [itvcodelists, setItvcodelists] = React.useState()
  const [ivcodeListsLoading, setItvcodelistsLoading] = React.useState(false)
  const [itvcodeListsError, setItvcodelistsError] = React.useState()

  const [categories, setCategories] = React.useState()
  const [category, setCategory] = React.useState()
  const [families, setFamilies] = React.useState()
  const [family, setFamily] = React.useState()
  const [currentChecklist, setCurrentChecklist] = React.useState()

  const [phoneNumber1Err, setPhoneNumber1Err] = React.useState()
  const [phoneNumber2Err, setPhoneNumber2Err] = React.useState()

  const [addressOpen, setAddressOpen] = React.useState()
  const [currentAddressEdit, setCurrentAddressEdit] = React.useState()

  const [onEdit, setOnEdit] = React.useState(creating)
  const [archiveOpen, setArchiveOpen] = React.useState(false)

  const [subsidiaryVelocenterEnable, setSubsidiaryVelocenterEnable] = React.useState(false)
  const [subsidiaryReasonParts, setSubsidiaryReasonParts] = React.useState(false)
  const [users, setUsers] = React.useState([])

  const [removeAddressPopup, setRemoveAddressPopup] = React.useState(false)

  React.useEffect(() => {
    if (creating) {
      setBusiness({
        businessIdentifier: '',
        businessName: '',
        address: '',
        postalCode: '',
        city: '',
        accountName: '',

        businessContactName: '',
        phoneNumber1: '',
        baseId: '',
        itvcodelistId: '',

        addressComplement: '',
        phoneNumber2: '',

        addresses: [],

        autoclose: false,
        autocloseDays: 0,

        reasonPartChange: false,
        enableReasonPartSwaps: false,

        displayNameVelocenter: '',

        qualif: [
          { key: 'roadReady', value: 3, enabled: false },
          { key: 'interReconditioning', value: 4, enabled: false },
          { key: 'postlocReconditioning', value: 5, enabled: false },
          { key: 'sav', value: 6, enabled: false },
          { key: 'delivered', value: 7, enabled: false }
        ],

        qualifCheckout: [
          { key: 'curative', value: 1, enabled: false },
          { key: 'preventive', value: 2, enabled: false },
          { key: 'roadReady', value: 3, enabled: false },
          { key: 'interReconditioning', value: 4, enabled: false },
          { key: 'postlocReconditioning', value: 5, enabled: false },
          { key: 'sav', value: 6, enabled: false },
          { key: 'delivered', value: 7, enabled: false }
        ],

        qualifPicItv: [
          { key: 'curative', value: 1, enabled: false },
          { key: 'preventive', value: 2, enabled: false },
          { key: 'roadReady', value: 3, enabled: false },
          { key: 'interReconditioning', value: 4, enabled: false },
          { key: 'postlocReconditioning', value: 5, enabled: false },
          { key: 'sav', value: 6, enabled: false },
          { key: 'delivered', value: 7, enabled: false }
        ],

        qualifReasonPart: [
          { key: 'curative', value: 1, enabled: false },
          { key: 'preventive', value: 2, enabled: false },
          { key: 'roadReady', value: 3, enabled: false },
          { key: 'interReconditioning', value: 4, enabled: false },
          { key: 'postlocReconditioning', value: 5, enabled: false },
          { key: 'sav', value: 6, enabled: false },
          { key: 'delivered', value: 7, enabled: false }
        ],

        enableChecklist: true,
        enableChecklistNok: false,
        qualifChecklist: [],

        enableVelocenter: 0,

        veloclikCollectivityId: '',

        enableSendEmailReportCheckoutItv: 0
      })
      setLoading(false)
      setOnEdit(true)
    } else {
      api.get('/business/details', undefined, { businessId: routeParams.id })
        .then(response => {
          setBusiness(response)
          setBusinessBase(response)
        })
        .catch(error => setError(error))
        .then(() => setLoading(false))
    }

    api.get('/bases/list', undefined, { ipp: -1 })
      .then(bases => setBases(bases))
      .catch(error => setBasesError(error))
      .then(() => setBasesLoading(false))

    api.get('/itvcodelists/list', undefined, { ipp: -1 })
      .then(itvcodelists => setItvcodelists(itvcodelists))
      .catch(error => setItvcodelistsError(error))
      .then(() => setItvcodelistsLoading(false))

    api.get('/categories/list', undefined, { ipp: -1, businessId: routeParams.id, linkMatrix: 1 })
      .then((categories) => {
        setCategories(categories.result.map(category => ({
          label: category.categoryName,
          value: category.categoryId,
        })))
      })
      .catch(response => setError(response))
      .then(() => setLoading(false))

    api.get('/subsidiaries/details', undefined, { subsidiaryId: subsidiary.subsidiaryId })
      .then((response) => {
        if (response.enableVelocenter) {
          setSubsidiaryVelocenterEnable(true)
        }
        if (response.enableReasonParts) {
          setSubsidiaryReasonParts(true)
        }
      })
      .catch(response => setError(response))
      .then(() => setLoading(false))
  }, [api, creating, routeParams, subsidiary])

  React.useEffect(() => {
    if (business && business.enableVelocenter) {
      api.get('/users/list', undefined, { businessId: business.businessId, ipp: -1 })
        .then((response) => {
          if (response.result && response.result.length > 0) {
            setUsers(response.result)
          }
        })
        .catch(response => setError(response))
        .then(() => setLoading(false))
    }
  }, [api, business])

  React.useEffect(() => {
    if (categories && categories.length > 0 && enableChecklist !== undefined && businessBase) {
      // Automatic fill checklists in creation OR when enabled checklist
      const qualifChecklist = []
      categories.map(c => {
        api.get('/families/list', undefined, { ipp: -1, categoryId: c.value, businessId: routeParams.id, linkMatrix: 1 })
          .then(families => {
            if (families && families.result && families.result.length > 0) {
              families.result.map(f => {
                // Fill only if checklist exists
                api.get('/checklist/list', undefined, { ipp: -1, businessId: routeParams.id, categoryId: c.value, familyId: f.familyId })
                  .then(response => {
                    if (response && response.result && response.result.checklist && response.result.checklist.length > 0) {
                      qualifChecklist.push({
                        categoryId: c.value,
                        familyId: f.familyId,
                        qualif:
                          [
                            { key: 'curative', value: 1, enabled: enableChecklist ? true : false },
                            { key: 'preventive', value: 2, enabled: enableChecklist ? true : false },
                            { key: 'roadReady', value: 3, enabled: enableChecklist ? true : false },
                            { key: 'interReconditioning', value: 4, enabled: enableChecklist ? true : false },
                            { key: 'postlocReconditioning', value: 5, enabled: enableChecklist ? true : false },
                            { key: 'sav', value: 6, enabled: enableChecklist ? true : false },
                            { key: 'delivered', value: 7, enabled: enableChecklist ? true : false }
                          ]
                      })
                    }
                  })
                  .catch(error => setError(error))
              })
            }
          })
          .catch(response => setError(response))
      })
      setBusiness(business => ({
        ...business,
        qualifChecklist: qualifChecklist
      }))
    }
  }, [api, categories, enableChecklist, routeParams.id, businessBase])

  React.useEffect(() => {
    if (creating) {
      setEnableChecklist(true)
    }
  }, [creating])

  const onCategoryChanged = React.useCallback(category => {
    setFamilies()
    setCurrentChecklist()
    setCategory(category)
    api.get('/families/list', undefined, { ipp: -1, categoryId: category.value, businessId: routeParams.id, linkMatrix: 1 })
      .then(families => {
        setFamilies(families.result.map(family => ({
          label: family.familyName,
          value: family.familyId,
        })))
      })
      .catch(response => setError(response))
  }, [api, routeParams])

  const onFamilyChanged = React.useCallback(family => {
    setFamily(family)
    api.get('/checklist/list', undefined, { ipp: -1, businessId: businessBase.businessId, categoryId: category.value, familyId: family.value })
      .then(response => {
        if (response && response.result && response.result.checklist && response.result.checklist.length > 0) {
          setCurrentChecklist(response.result.checklist)
        } else {
          setCurrentChecklist()
        }
      })
      .catch(error => {
        setError(error.code)
        setCurrentChecklist()
      })
  }, [api, businessBase, category])

  const isChecklistItemChecked = React.useCallback((type) => {
    const filteredChecklist = business.qualifChecklist.find(checklist => checklist.categoryId === category.value && checklist.familyId === family.value)
    let isChecked = false
    if (filteredChecklist) {
      filteredChecklist.qualif.forEach(q => {
        if (q.key === type && q.enabled === true) {
          isChecked = true
        }
      })
    }
    return isChecked
  }, [business, category, family])

  const handleChange = React.useCallback(({ target: { name, type, value, checked } }) => {
    const _value = type === 'checkbox'
      ? checked
      : value === undefined ? '' : value

    if (name === 'phoneNumber1') { setPhoneNumber1Err() }
    if (name === 'phoneNumber2') { setPhoneNumber2Err() }

    setBusiness(business => ({
      ...business,
      [name]: _value
    }))
  }, [])

  const create = React.useCallback(() => {
    const { phoneNumber1, phoneNumber2 } = business

    if (!isValidPhoneNumber(phoneNumber1)) {
      return setPhoneNumber1Err('invalidPhoneNumber')
    }

    if (phoneNumber2 && phoneNumber2.length > 0 && !isValidPhoneNumber(phoneNumber2)) {
      return setPhoneNumber2Err('invalidPhoneNumber')
    }

    setUpdating(true)
    setUpdateError(undefined)

    api.post('/business/details', { body: JSON.stringify(business) })
      .then(() => history.push('/admin/business'))
      .catch(error => {
        setUpdateError(error)
        setUpdating(false)
      })
  }, [api, business, history])

  const save = React.useCallback(() => {
    const { phoneNumber1, phoneNumber2 } = business

    if (!isValidPhoneNumber(phoneNumber1)) {
      return setPhoneNumber1Err('invalidPhoneNumber')
    }

    if (phoneNumber2 && phoneNumber2.length > 0 && !isValidPhoneNumber(phoneNumber2)) {
      return setPhoneNumber2Err('invalidPhoneNumber')
    }

    setUpdating(true)
    setUpdateError(undefined)
    api.post('/business/details', { body: JSON.stringify(business) })
      .then(response => {
        setBusiness(response)
        setBusinessBase(response)
        setOnEdit(false)
        setCategory(false)
        setFamily(false)
      })
      .catch(error => setUpdateError(error))
      .then(() => setUpdating(false))
  }, [api, business])

  const canAddAddress = React.useMemo(() => currentAddressEdit && currentAddressEdit.addressLabel && currentAddressEdit.addressFull, [currentAddressEdit])

  const handleAddressOpen = React.useCallback((index, address) => {
    setAddressOpen(index)
    setCurrentAddressEdit(index === undefined ? undefined : address)
  }, [])

  const handleCurrentAddressChange = React.useCallback(({ target: { name, value } }) => {
    setCurrentAddressEdit(address => ({ ...address, [name]: value }))
    if (name === 'addressFull') {
      api.get('/maps/autocomplete', undefined, {
        input: value,
        ...(autocomplete && autocomplete.session_token && { session_token: autocomplete.session_token })
      })
        .then(response => {
          setAutocomplete(response)
        })
        .catch(error => console.log(error))
    }
  }, [api, autocomplete])

  const handleAutocomplete = React.useCallback(e => {
    setCurrentAddressEdit(address => ({ ...address, addressFull: e }))
    api.get('/maps/placedetails', undefined, {
      place_id: autocomplete.results.find(r => r.addressFull === e).place_id,
      session_token: autocomplete.session_token,
      addressFull: e
    })
      .then(response => {
        setCurrentAddressEdit(address => ({ ...address, mapId: response.mapId }))
      })
      .catch(error => console.log(error))
  }, [api, autocomplete])

  const handleQualif = React.useCallback((key) => {
    setBusiness(business => ({
      ...business,
      qualif: business.qualif.map(q => q.key === key ? { ...q, enabled: !q.enabled } : { ...q })
    }))
  }, [])

  const handleQualifCheckout = React.useCallback((key) => {
    setBusiness(business => ({
      ...business,
      qualifCheckout: business.qualifCheckout.map(q => q.key === key ? { ...q, enabled: !q.enabled } : { ...q })
    }))
  }, [])

  const handleQualifPicItv = React.useCallback((key) => {
    setBusiness(business => ({
      ...business,
      qualifPicItv: business.qualifPicItv.map(q => q.key === key ? { ...q, enabled: !q.enabled } : { ...q })
    }))
  }, [])

  const handleQualifReasonPart = React.useCallback((key) => {
    setBusiness(business => ({
      ...business,
      qualifReasonPart: business.qualifReasonPart.map(q => q.key === key ? { ...q, enabled: !q.enabled } : { ...q })
    }))
  }, [])

  const handleQualifChecklist = React.useCallback(key => {
    setBusiness(business => ({
      ...business,
      qualifChecklist: business.qualifChecklist.map(checklist => {
        if (checklist.qualif && checklist.qualif.length > 0 && checklist.categoryId === category.value && checklist.familyId === family.value) {
          return {
            ...checklist,
            qualif: checklist.qualif.map(q => q.key === key ? { ...q, enabled: !q.enabled } : { ...q })
          }
        } else {
          return checklist
        }
      })
    }))
  }, [category, family])

  const addAddress = React.useCallback(() => {
    setBusiness(business => ({
      ...business,
      addresses: [...business.addresses, {
        addressComplement: '',
        ...currentAddressEdit
      }]
    }))
    setAddressOpen()
    setCurrentAddressEdit()
  }, [currentAddressEdit])

  const removeAddress = React.useCallback(() => {
    setBusiness(business => ({
      ...business,
      addresses: business.addresses.filter((_, index) => index !== addressOpen)
    }))
    setRemoveAddressPopup(false)
    setAddressOpen()
    setCurrentAddressEdit()
  }, [addressOpen])

  const saveAddress = React.useCallback(() => {
    setBusiness(business => ({
      ...business,
      addresses: business.addresses.map((item, index) => addressOpen === index ? { ...currentAddressEdit } : item)
    }))
    setAddressOpen()
    setCurrentAddressEdit()
  }, [addressOpen, currentAddressEdit])

  const dismissAddress = React.useCallback(() => {
    setAddressOpen()
    setCurrentAddressEdit()
  }, [])

  const archive = React.useCallback(() => {
    setArchiving(true)
    setArchiveError(undefined)
    api.del('/business/details', undefined, { businessId: routeParams.id })
      .then(response => {
        setBusiness(response)
        setBusinessBase(response)
        setOnEdit(false)
        setArchiveOpen(false)
      })
      .catch(error => setArchiveError(error))
      .then(() => setArchiving(false))
  }, [api, routeParams.id])

  const cancel = React.useCallback(() => {
    if (creating) {
      setBusiness(businessBase)
      setOnEdit(false)
    } else {
      history.push('/admin/business')
    }
  }, [businessBase, creating, history])

  if (error) {
    return <>
      <NavLink tag={Link} to="/admin/business"><T id="returnToList" /></NavLink>
      <ErrAlert error={error} className="mt-2" />
    </>
  }

  if (loading || basesLoading || ivcodeListsLoading) {
    return <>
      <NavLink tag={Link} to="/admin/business"><T id="returnToList" /></NavLink>
      <Spinner className="d-flex ml-auto mr-auto" color="primary" />
    </>
  }

  return (
    <>
      {business && (
        <>
          <NavLink tag={Link} to="/admin/business"><T id="returnToList" /></NavLink>
          <Card className="mb-2" tag={Form}>
            {!creating && <CardHeader tag="h2">{business.businessIdentifier} - {business.businessName}</CardHeader>}
            <CardBody>
              <FormGroup tag="fieldset">
                <TLabel id="businessIdentifier" for="businessIdentifier" />
                <Input id="businessIdentifier"
                  type="text"
                  name="businessIdentifier"
                  disabled={updating || !onEdit}
                  value={business.businessIdentifier}
                  onChange={handleChange} />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="businessName" for="businessName" />
                <Input id="businessName"
                  type="text"
                  name="businessName"
                  disabled={updating || !onEdit}
                  value={business.businessName}
                  onChange={handleChange} />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="address" for="address" />
                <Input id="address"
                  type="text"
                  name="address"
                  disabled={updating || !onEdit}
                  value={business.address}
                  onChange={handleChange} />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="addressComplement" for="addressComplement" />
                <Input id="addressComplement"
                  type="text"
                  name="addressComplement"
                  disabled={updating || !onEdit}
                  value={business.addressComplement}
                  onChange={handleChange} />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="postalCode" for="postalCode" />
                <Input id="postalCode"
                  type="text"
                  name="postalCode"
                  disabled={updating || !onEdit}
                  value={business.postalCode}
                  onChange={handleChange} />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="city" for="city" />
                <Input id="city"
                  type="text"
                  name="city"
                  disabled={updating || !onEdit}
                  value={business.city}
                  onChange={handleChange} />
              </FormGroup>
              <FormGroup tag="fieldset">
                <Label for="accountName" className="d-flex align-items-center">
                  <T id="accountName" />
                  <i className="simple-icon-question ml-1" id="accountNameTarget" />
                  <UncontrolledPopover trigger="hover" placement="top" target="accountNameTarget">
                    <T tagName={PopoverBody} id="accountName.popover" />
                  </UncontrolledPopover>
                </Label>
                <Input id="accountName"
                  type="text"
                  name="accountName"
                  disabled={updating || !onEdit}
                  value={business.accountName}
                  onChange={handleChange} />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="businessContactName" for="businessContactName" />
                <Input id="businessContactName"
                  type="text"
                  name="businessContactName"
                  disabled={updating || !onEdit}
                  value={business.businessContactName}
                  onChange={handleChange} />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="phoneNumber1" for="phoneNumber1" />
                <Input id="phoneNumber1"
                  tag={PhoneInput}
                  defaultCountry="FR"
                  country="FR"
                  className={`d-flex ${updating || !onEdit ? ' disabled' : ''}`}
                  type="text"
                  name="phoneNumber1"
                  disabled={updating || !onEdit}
                  value={business.phoneNumber1}
                  onChange={value => handleChange({ target: { value, name: 'phoneNumber1' } })} />
                {phoneNumber1Err && <ErrAlert error={{ code: phoneNumber1Err }} className="mb-0 mt-2" />}
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="phoneNumber2" for="phoneNumber2" />
                <Input id="phoneNumber2"
                  tag={PhoneInput}
                  defaultCountry="FR"
                  country="FR"
                  className={`d-flex ${updating || !onEdit ? ' disabled' : ''}`}
                  type="text"
                  name="phoneNumber2"
                  disabled={updating || !onEdit}
                  value={business.phoneNumber2}
                  onChange={value => handleChange({ target: { value, name: 'phoneNumber2' } })} />
                {phoneNumber2Err && <ErrAlert error={{ code: phoneNumber2Err }} className="mb-0 mt-2" />}
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="baseLabel" for="baseId" />
                {bases && (
                  <CustomSelect
                    inputId="baseId"
                    name="baseId"
                    options={bases.result}
                    onChange={e => { handleChange({ target: { name: 'baseId', value: e.baseId } }) }}
                    value={bases.result.filter(b => b.baseId === business.baseId)}
                    isDisabled={updating || !onEdit}
                    getOptionLabel={option => option.baseName}
                    getOptionValue={option => option.baseId} />
                )}
                {basesError && <ErrAlert className="mb-0 mt-4" error={basesError} />}
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="itvcodelistId" for="itvcodelistId" />
                {itvcodelists && (
                  <CustomSelect
                    inputId="itvcodelistId"
                    name="itvcodelistId"
                    options={itvcodelists.result}
                    onChange={e => { handleChange({ target: { name: 'itvcodelistId', value: e.itvcodelistId } }) }}
                    value={itvcodelists.result.filter(i => i.itvcodelistId === business.itvcodelistId)}
                    isDisabled={updating || !onEdit}
                    getOptionLabel={option => option.itvcodelistLabel}
                    getOptionValue={option => option.itvcodelistId} />
                )}
                {itvcodeListsError && <ErrAlert className="mb-0 mt-4" error={itvcodeListsError} />}
              </FormGroup>
              <ProtectedComponent rights={['admin-veloclik_sync']}>
                <FormGroup tag="fieldset">
                  <TLabel id="veloclikCollectivityId" for="veloclikCollectivityId" />
                  <Input id="veloclikCollectivityId"
                    type="number"
                    name="veloclikCollectivityId"
                    disabled={updating || !onEdit || (businessBase && businessBase.veloclikCollectivityId)}
                    value={(businessBase && businessBase.veloclikCollectivityId) || business.veloclikCollectivityId || ''}
                    onChange={handleChange} />
                </FormGroup>
              </ProtectedComponent>
              {subsidiaryVelocenterEnable && <FormGroup tag="fieldset" className="mb-2">
                <TLabel id="enableVelocenterLabel" for="enableVelocenter" />
                <TCustomInput
                  id="enableVelocenter"
                  type="checkbox"
                  name="enableVelocenter"
                  label="enableVelocenter"
                  checked={business.enableVelocenter}
                  disabled={updating || !onEdit}
                  onChange={handleChange} />
                {!!business.enableVelocenter && users && users.length > 0 && (
                  <>
                    <TLabel id="displayNameVelocenter" for="displayNameVelocenter" />
                    <Input id="displayNameVelocenter"
                      type="text"
                      name="displayNameVelocenter"
                      disabled={updating || !onEdit}
                      value={business.displayNameVelocenter}
                      onChange={handleChange} />
                    <TLabel id="userIdVelocenter" for="userIdVelocenter" className="mt-3" />
                    <CustomSelect
                      inputId="userIdVelocenter"
                      name="userIdVelocenter"
                      options={users}
                      onChange={e => { handleChange({ target: { name: 'userIdVelocenter', value: e.userId } }) }}
                      value={users.filter(u => u.userId === business.userIdVelocenter)}
                      isDisabled={updating || !onEdit}
                      getOptionLabel={option => option.firstName + ' ' + option.lastName}
                      getOptionValue={option => option.userId} />
                  </>
                )}
              </FormGroup>}
            </CardBody>
            <CardHeader tag="h2"><T id="preferredAddresses" /></CardHeader>
            <CardBody className="px-0 pt-0">
              {!onEdit && business.addresses.length === 0 && <Card><CardHeader><T id="preferredAddresses.noAddresses" /></CardHeader></Card>}
              {business.addresses.map((address, index) => (
                <Card key={`address-${index}`}>
                  {(!onEdit || addressOpen !== index) && (
                    <CardHeader className="d-flex align-items-center">
                      <T id="preferredAddresses.addressTitle" values={address} className="mr-3" />
                      {onEdit && <TButton
                        color="secondary"
                        id="preferredAddresses.edit"
                        className="ml-auto"
                        onClick={() => handleAddressOpen(index, address)} />}
                    </CardHeader>
                  )}
                  {onEdit && addressOpen === index && (<>
                    <CardBody>
                      <FormGroup tag="fieldset" className="flex-grow-1">
                        <TLabel id="addressLabel" for="addressLabel" />
                        <Input id="addressLabel"
                          type="text"
                          name="addressLabel"
                          disabled={updating || !onEdit}
                          value={currentAddressEdit.addressLabel || ''}
                          onChange={handleCurrentAddressChange} />
                      </FormGroup>
                      <FormGroup tag="fieldset" className="flex-grow-1">
                        <TLabel id="addressFull" for="addressFull" />
                        <TAutocomplete id="addressFull"
                          className="w-100"
                          type="text"
                          name="addressFull"
                          disabled={updating || !onEdit}
                          value={currentAddressEdit.addressFull || ''}
                          placeholder="addressFullPlaceholder"
                          searchOnFocus={handleCurrentAddressChange}
                          onChange={handleCurrentAddressChange}
                          onAutocomplete={e => handleAutocomplete(e)}
                          options={
                            autocomplete && autocomplete.results.length > 0 && autocomplete.results.map(r => {
                              return (r.addressFull)
                            })} />
                      </FormGroup>
                      <FormGroup tag="fieldset">
                        <TLabel id="addressComplement" for="addressComplement" />
                        <Input id="addressComplement"
                          type="text"
                          name="addressComplement"
                          disabled={updating || !onEdit}
                          value={currentAddressEdit.addressComplement || ''}
                          onChange={handleCurrentAddressChange} />
                      </FormGroup>
                    </CardBody>
                    <CardFooter className="d-flex justify-content-end">
                      <TButton color="danger" id="preferredAddresses.remove" onClick={() => { setRemoveAddressPopup(true)} } className="mr-auto" />
                      <TButton color="primary" id="preferredAddresses.save" onClick={saveAddress} className="ml-3" disabled={!canAddAddress} />
                      <TButton color="danger" id="preferredAddresses.dismiss" onClick={dismissAddress} className="ml-3" />
                    </CardFooter>
                  </>)}
                </Card>
              ))}
              {onEdit && <Card>
                {addressOpen !== -1 && (
                  <CardHeader className="d-flex justify-content-end">
                    <TButton
                      color="primary"
                      id="preferredAddresses.new"
                      onClick={() => handleAddressOpen(-1, {})} />
                  </CardHeader>
                )}
                {addressOpen === -1 && (<>
                  <CardBody>
                    <FormGroup tag="fieldset" className="flex-grow-1">
                      <TLabel id="addressLabel" for="addressLabel" />
                      <Input id="addressLabel"
                        type="text"
                        name="addressLabel"
                        disabled={updating || !onEdit}
                        value={currentAddressEdit.addressLabel || ''}
                        onChange={handleCurrentAddressChange} />
                    </FormGroup>
                    <FormGroup tag="fieldset" className="flex-grow-1">
                      <TLabel id="addressFull" for="addressFull" />
                      <TAutocomplete id="addressFull"
                        className="w-100"
                        type="text"
                        name="addressFull"
                        disabled={updating || !onEdit}
                        value={currentAddressEdit.addressFull || ''}
                        placeholder="addressFullPlaceholder"
                        searchOnFocus={handleCurrentAddressChange}
                        onChange={handleCurrentAddressChange}
                        onAutocomplete={e => handleAutocomplete(e)}
                        options={
                          autocomplete && autocomplete.results.length > 0 && autocomplete.results.map(r => {
                            return (r.addressFull)
                          })} />
                    </FormGroup>
                    <FormGroup tag="fieldset">
                      <TLabel id="addressComplement" for="addressComplement" />
                      <Input id="addressComplement"
                        type="text"
                        name="addressComplement"
                        disabled={updating || !onEdit}
                        value={currentAddressEdit.addressComplement || ''}
                        onChange={handleCurrentAddressChange} />
                    </FormGroup>
                  </CardBody>
                  <CardFooter className="d-flex justify-content-end">
                    <TButton color="primary" id="preferredAddresses.add" onClick={addAddress} disabled={!canAddAddress} />
                  </CardFooter>
                </>)}
              </Card>}
            </CardBody>
            <CardHeader tag="h2"><T id="qualifications" /></CardHeader>
            <CardBody>
              <FormGroup className="mb-2" tag="fieldset">
                <TCustomInput
                  id="roadReady"
                  type="checkbox"
                  name="roadReady"
                  label="qualif.roadReady"
                  checked={business.qualif.find((q) => q.key === 'roadReady').enabled}
                  disabled={updating || !onEdit}
                  onChange={e => { handleQualif(e.target.name) }}
                  inline />
                <TCustomInput
                  id="interReconditioning"
                  type="checkbox"
                  name="interReconditioning"
                  label="qualif.interReconditioning"
                  checked={business.qualif.find((q) => q.key === 'interReconditioning').enabled}
                  disabled={updating || !onEdit}
                  onChange={e => { handleQualif(e.target.name) }}
                  inline />
                <TCustomInput
                  id="postlocReconditioning"
                  type="checkbox"
                  name="postlocReconditioning"
                  label="qualif.postlocReconditioning"
                  checked={business.qualif.find((q) => q.key === 'postlocReconditioning').enabled}
                  disabled={updating || !onEdit}
                  onChange={e => { handleQualif(e.target.name) }}
                  inline />
                <TCustomInput
                  id="sav"
                  type="checkbox"
                  name="sav"
                  label="qualif.sav"
                  checked={business.qualif.find((q) => q.key === 'sav').enabled}
                  disabled={updating || !onEdit}
                  onChange={e => { handleQualif(e.target.name) }}
                  inline />
                <TCustomInput
                  id="delivered"
                  type="checkbox"
                  name="delivered"
                  label="qualif.delivered"
                  checked={business.qualif.find((q) => q.key === 'delivered').enabled}
                  disabled={updating || !onEdit}
                  onChange={e => { handleQualif(e.target.name) }}
                  inline />
              </FormGroup>
            </CardBody>
            <CardHeader tag="h2"><T id="qualifCheckout" /></CardHeader>
            <CardBody>
              <FormGroup className="mb-2" tag="fieldset">
                <TCustomInput
                  type="checkbox"
                  name="curative"
                  id="curativeCheckout"
                  label="qualifCheckout.curative"
                  checked={business.qualifCheckout.find((q) => q.key === 'curative').enabled}
                  disabled={updating || !onEdit}
                  onChange={e => { handleQualifCheckout(e.target.name) }}
                  inline />
                <TCustomInput
                  type="checkbox"
                  name="preventive"
                  id="preventiveCheckout"
                  label="qualifCheckout.preventive"
                  checked={business.qualifCheckout.find((q) => q.key === 'preventive').enabled}
                  disabled={updating || !onEdit}
                  onChange={e => { handleQualifCheckout(e.target.name) }}
                  inline />
                <TCustomInput
                  type="checkbox"
                  name="roadReady"
                  id="roadReadyCheckout"
                  label="qualifCheckout.roadReady"
                  checked={business.qualifCheckout.find((q) => q.key === 'roadReady').enabled}
                  disabled={updating || !onEdit}
                  onChange={e => { handleQualifCheckout(e.target.name) }}
                  inline />
                <TCustomInput
                  type="checkbox"
                  name="interReconditioning"
                  id="interReconditioningCheckout"
                  label="qualifCheckout.interReconditioning"
                  checked={business.qualifCheckout.find((q) => q.key === 'interReconditioning').enabled}
                  disabled={updating || !onEdit}
                  onChange={e => { handleQualifCheckout(e.target.name) }}
                  inline />
                <TCustomInput
                  type="checkbox"
                  name="postlocReconditioning"
                  id="postlocReconditioningCheckout"
                  label="qualifCheckout.postlocReconditioning"
                  checked={business.qualifCheckout.find((q) => q.key === 'postlocReconditioning').enabled}
                  disabled={updating || !onEdit}
                  onChange={e => { handleQualifCheckout(e.target.name) }}
                  inline />
                <TCustomInput
                  type="checkbox"
                  name="sav"
                  id="savCheckout"
                  label="qualifCheckout.sav"
                  checked={business.qualifCheckout.find((q) => q.key === 'sav').enabled}
                  disabled={updating || !onEdit}
                  onChange={e => { handleQualifCheckout(e.target.name) }}
                  inline />
                <TCustomInput
                  type="checkbox"
                  name="delivered"
                  id="deliveredCheckout"
                  label="qualifCheckout.delivered"
                  checked={business.qualifCheckout.find((q) => q.key === 'delivered').enabled}
                  disabled={updating || !onEdit}
                  onChange={e => { handleQualifCheckout(e.target.name) }}
                  inline />
              </FormGroup>
              <FormGroup className="mb-2">
                <TCustomInput
                  id="enableSendEmailReportCheckoutItv"
                  type="checkbox"
                  name="enableSendEmailReportCheckoutItv"
                  label="enableSendEmailReportCheckoutItv"
                  checked={business.enableSendEmailReportCheckoutItv}
                  disabled={updating || !onEdit}
                  onChange={handleChange}
                  inline />
              </FormGroup>
            </CardBody>
            <CardHeader tag="h2"><T id="qualifPicItv" /></CardHeader>
            <CardBody>
              <FormGroup className="mb-2" tag="fieldset">
                <TCustomInput
                  type="checkbox"
                  name="curative"
                  id="curativePicItv"
                  label="qualifCheckout.curative"
                  checked={business.qualifPicItv.find((q) => q.key === 'curative').enabled}
                  disabled={updating || !onEdit}
                  onChange={e => { handleQualifPicItv(e.target.name) }}
                  inline />
                <TCustomInput
                  type="checkbox"
                  name="preventive"
                  id="preventivePicItv"
                  label="qualifCheckout.preventive"
                  checked={business.qualifPicItv.find((q) => q.key === 'preventive').enabled}
                  disabled={updating || !onEdit}
                  onChange={e => { handleQualifPicItv(e.target.name) }}
                  inline />
                <TCustomInput
                  type="checkbox"
                  name="roadReady"
                  id="roadReadyPicItv"
                  label="qualifCheckout.roadReady"
                  checked={business.qualifPicItv.find((q) => q.key === 'roadReady').enabled}
                  disabled={updating || !onEdit}
                  onChange={e => { handleQualifPicItv(e.target.name) }}
                  inline />
                <TCustomInput
                  type="checkbox"
                  name="interReconditioning"
                  id="interReconditioningPicItv"
                  label="qualifCheckout.interReconditioning"
                  checked={business.qualifPicItv.find((q) => q.key === 'interReconditioning').enabled}
                  disabled={updating || !onEdit}
                  onChange={e => { handleQualifPicItv(e.target.name) }}
                  inline />
                <TCustomInput
                  type="checkbox"
                  name="postlocReconditioning"
                  id="postlocReconditioningPicItv"
                  label="qualifCheckout.postlocReconditioning"
                  checked={business.qualifPicItv.find((q) => q.key === 'postlocReconditioning').enabled}
                  disabled={updating || !onEdit}
                  onChange={e => { handleQualifPicItv(e.target.name) }}
                  inline />
                <TCustomInput
                  type="checkbox"
                  name="sav"
                  id="savPicItv"
                  label="qualifCheckout.sav"
                  checked={business.qualifPicItv.find((q) => q.key === 'sav').enabled}
                  disabled={updating || !onEdit}
                  onChange={e => { handleQualifPicItv(e.target.name) }}
                  inline />
                <TCustomInput
                  type="checkbox"
                  name="delivered"
                  id="deliveredPicItv"
                  label="qualifCheckout.delivered"
                  checked={business.qualifPicItv.find((q) => q.key === 'delivered').enabled}
                  disabled={updating || !onEdit}
                  onChange={e => { handleQualifPicItv(e.target.name) }}
                  inline />
              </FormGroup>
            </CardBody>
            {subsidiaryReasonParts && (
              <>
                <CardHeader tag="h2"><T id="qualifItvReasonPart" /></CardHeader>
                <CardBody>
                  <FormGroup className="mb-2" tag="fieldset">
                    <TCustomInput
                      type="checkbox"
                      name="curative"
                      id="curativeReasonPart"
                      label="qualifCheckout.curative"
                      checked={business.qualifReasonPart.find((q) => q.key === 'curative').enabled}
                      disabled={updating || !onEdit}
                      onChange={e => { handleQualifReasonPart(e.target.name) }}
                      inline />
                    <TCustomInput
                      type="checkbox"
                      name="preventive"
                      id="preventiveReasonPart"
                      label="qualifCheckout.preventive"
                      checked={business.qualifReasonPart.find((q) => q.key === 'preventive').enabled}
                      disabled={updating || !onEdit}
                      onChange={e => { handleQualifReasonPart(e.target.name) }}
                      inline />
                    <TCustomInput
                      type="checkbox"
                      name="roadReady"
                      id="roadReadyReasonPart"
                      label="qualifCheckout.roadReady"
                      checked={business.qualifReasonPart.find((q) => q.key === 'roadReady').enabled}
                      disabled={updating || !onEdit}
                      onChange={e => { handleQualifReasonPart(e.target.name) }}
                      inline />
                    <TCustomInput
                      type="checkbox"
                      name="interReconditioning"
                      id="interReconditioningReasonPart"
                      label="qualifCheckout.interReconditioning"
                      checked={business.qualifReasonPart.find((q) => q.key === 'interReconditioning').enabled}
                      disabled={updating || !onEdit}
                      onChange={e => { handleQualifReasonPart(e.target.name) }}
                      inline />
                    <TCustomInput
                      type="checkbox"
                      name="postlocReconditioning"
                      id="postlocReconditioningReasonPart"
                      label="qualifCheckout.postlocReconditioning"
                      checked={business.qualifReasonPart.find((q) => q.key === 'postlocReconditioning').enabled}
                      disabled={updating || !onEdit}
                      onChange={e => { handleQualifReasonPart(e.target.name) }}
                      inline />
                    <TCustomInput
                      type="checkbox"
                      name="sav"
                      id="savReasonPart"
                      label="qualifCheckout.sav"
                      checked={business.qualifReasonPart.find((q) => q.key === 'sav').enabled}
                      disabled={updating || !onEdit}
                      onChange={e => { handleQualifReasonPart(e.target.name) }}
                      inline />
                    <TCustomInput
                      type="checkbox"
                      name="delivered"
                      id="deliveredReasonPart"
                      label="qualifCheckout.delivered"
                      checked={business.qualifReasonPart.find((q) => q.key === 'delivered').enabled}
                      disabled={updating || !onEdit}
                      onChange={e => { handleQualifReasonPart(e.target.name) }}
                      inline />
                  </FormGroup>
                </CardBody>
                <CardHeader tag="h2"><T id="qualifSwapReasonPart" /></CardHeader>
                <CardBody>
                  <FormGroup className="mb-2" tag="fieldset">
                    <TCustomInput
                      id="enableReasonPartSwaps"
                      type="checkbox"
                      name="enableReasonPartSwaps"
                      label="enableReasonPartSwaps"
                      checked={business.enableReasonPartSwaps}
                      disabled={updating || !onEdit}
                      onChange={handleChange}
                      inline />
                  </FormGroup>
                </CardBody>
              </>
            )}
            <CardHeader tag="h2"><T id="checklist" /></CardHeader>
            <CardBody>
              <FormGroup className="mb-2">
                <TAlert id="enableChecklist.info" color="warning" />
                <TCustomInput
                  id="enableChecklist"
                  type="checkbox"
                  name="enableChecklist"
                  label="enableChecklist"
                  checked={business.enableChecklist}
                  disabled={updating || !onEdit}
                  onChange={(e) => {
                    handleChange(e)
                    setEnableChecklist(!business.enableChecklist)
                    setCategory()
                    setFamily()
                  }}
                  inline />
              </FormGroup>
              {business.enableChecklist && (
                <>
                  <>
                    <FormGroup tag="fieldset">
                      <TLabel id="create.categoryId" for="categoryId" />
                      <SelectWrapper
                        id="categoryId"
                        onChange={onCategoryChanged}
                        name="categoryId"
                        options={categories}
                        isDisabled={updating || !onEdit}
                        value={category}
                        noOptionsMessage={() => <T id="create.noCategories" />} />
                    </FormGroup>
                    <FormGroup tag="fieldset">
                      <TLabel id="create.familyId" for="familyId" />
                      <SelectWrapper
                        id="familyId"
                        onChange={onFamilyChanged}
                        name="familyId"
                        isDisabled={updating || !onEdit || !families}
                        options={families}
                        value={family}
                        noOptionsMessage={() => <T id="create.noFamilies" />} />
                    </FormGroup>
                  </>
                  {category && family && (
                    <>
                      {currentChecklist ? (
                        <FormGroup className="mb-2" tag="fieldset">
                          <TCustomInput
                            type="checkbox"
                            name="curative"
                            id="curativeChecklist"
                            label="qualifChecklist.curative"
                            checked={isChecklistItemChecked('curative')}
                            disabled={updating || !onEdit || !family}
                            onChange={e => { handleQualifChecklist(e.target.name) }}
                            inline />
                          <TCustomInput
                            type="checkbox"
                            name="preventive"
                            id="preventiveChecklist"
                            label="qualifChecklist.preventive"
                            checked={isChecklistItemChecked('preventive')}
                            disabled={updating || !onEdit || !family}
                            onChange={e => { handleQualifChecklist(e.target.name) }}
                            inline />
                          <TCustomInput
                            type="checkbox"
                            name="roadReady"
                            id="roadReadyChecklist"
                            label="qualifChecklist.roadReady"
                            checked={isChecklistItemChecked('roadReady')}
                            disabled={updating || !onEdit || !family}
                            onChange={e => { handleQualifChecklist(e.target.name) }}
                            inline />
                          <TCustomInput
                            type="checkbox"
                            name="interReconditioning"
                            id="interReconditioningChecklist"
                            label="qualifChecklist.interReconditioning"
                            checked={isChecklistItemChecked('interReconditioning')}
                            disabled={updating || !onEdit || !family}
                            onChange={e => { handleQualifChecklist(e.target.name) }}
                            inline />
                          <TCustomInput
                            type="checkbox"
                            name="postlocReconditioning"
                            id="postlocReconditioningChecklist"
                            label="qualifChecklist.postlocReconditioning"
                            checked={isChecklistItemChecked('postlocReconditioning')}
                            disabled={updating || !onEdit || !family}
                            onChange={e => { handleQualifChecklist(e.target.name) }}
                            inline />
                          <TCustomInput
                            type="checkbox"
                            name="sav"
                            id="savChecklist"
                            label="qualifChecklist.sav"
                            checked={isChecklistItemChecked('sav')}
                            disabled={updating || !onEdit || !family}
                            onChange={e => { handleQualifChecklist(e.target.name) }}
                            inline />
                          <TCustomInput
                            type="checkbox"
                            name="delivered"
                            id="deliveredChecklist"
                            label="qualifChecklist.delivered"
                            checked={isChecklistItemChecked('delivered')}
                            disabled={updating || !onEdit || !family}
                            onChange={e => { handleQualifChecklist(e.target.name) }}
                            inline />
                        </FormGroup>
                      ) : (
                        <TAlert id="enableChecklist.noData" color="warning" />
                      )}
                    </>
                  )}
                </>
              )}
              <TCustomInput
                id="enableChecklistNok"
                type="checkbox"
                name="enableChecklistNok"
                label="enableChecklistNok"
                onChange={handleChange}
                checked={business.enableChecklistNok}
                disabled={updating || !onEdit}
                inline />
            </CardBody>
            <CardHeader tag="h2"><T id="settings" /></CardHeader>
            <CardBody>
              <FormGroup className="mb-2">
                <TCustomInput
                  id="autoclose"
                  type="checkbox"
                  name="autoclose"
                  label="autoclose"
                  checked={business.autoclose}
                  disabled={updating || !onEdit}
                  onChange={handleChange}
                  inline />
              </FormGroup>
              {business.autoclose && <>
                <TCustomInput type="range" min="0" max="30" step="1"
                  id="autocloseDays"
                  name="autocloseDays"
                  value={business.autocloseDays || 0}
                  onChange={handleChange}
                  disabled={updating || !onEdit} />
                <T id="autocloseDays.bubble" values={{ value: business.autocloseDays ? business.autocloseDays : 0 }} />
              </>}
            </CardBody>
            <ProtectedComponent rights={['admin-business_edit']}>
              {updateError && <CardFooter className="p-0 b-0"><ErrAlert error={updateError} className="mb-0" /></CardFooter>}
              <CardFooter className="d-flex justify-content-end">
                {!onEdit && !creating && <TButton id="edit" onClick={() => setOnEdit(true)} />}
                {onEdit && !creating && <ProtectedComponent rights={['admin_archive']}>
                  <TButton disabled={updating} onClick={() => setArchiveOpen(true)} color="danger" className="mr-auto" id={business.archived ? 'restore' : 'archive'} />
                </ProtectedComponent>}
                {onEdit && !creating && <TButton id="save" disabled={updating} loading={updating} className="ml-2" onClick={save} color="primary" />}
                {onEdit && creating && <TButton id="create" disabled={updating} color="primary" className="ml-2" onClick={create} />}
                {onEdit && <TButton id="cancel" disabled={updating} className="ml-2" onClick={cancel} />}
              </CardFooter>
            </ProtectedComponent>
          </Card>

          <Modal isOpen={archiveOpen} fade={false} toggle={() => setArchiveOpen(false)}>
            <ModalBody>
              <T id={`${business.archived ? 'restore' : 'archive'}.content`} />
              {archiveError && <ErrAlert error={archiveError} className="mb-0 mt-2" />}
            </ModalBody>
            <ModalFooter className="py-3">
              <TButton disabled={archiving}
                onClick={() => setArchiveOpen(false)}
                id={`${business.archived ? 'restore' : 'archive'}.cancel`} />
              <TButton disabled={archiving} loading={archiving}
                className="ml-2" color="danger"
                onClick={archive}
                id={`${business.archived ? 'restore' : 'archive'}.confirm`} />
            </ModalFooter>
          </Modal>

          <Modal isOpen={removeAddressPopup} fade={false} toggle={() => setRemoveAddressPopup(false)}>
            <ModalBody>
              <T id="removeContent" />
            </ModalBody>
            <ModalFooter>
              <TButton
                onClick={() => setRemoveAddressPopup(false)}
                id="removeCancel" />
              <TButton 
                className="ml-2" color="danger"
                onClick={removeAddress}
                id="removeConfirm" />
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  )
}

export default BusinessDetails
