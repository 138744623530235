import React from 'react'
import { objectToQuery, queryToObject } from 'react-rest-api'
import {
  Card, CardBody, CardHeader, Spinner
} from 'reactstrap'

import { useGet } from 'hooks/useApi.hooks'

import SlotSelector from 'components/SlotSelector'
import { T, TAlert } from 'components/TComponents'

import { useFormatMessage } from 'hooks/intl.hooks'

import { Filters } from 'components/Filters'

import GoogleMapReact from 'google-map-react';

const VeloptimMapTour = ({ location, history }) => {
  const formatMesage = useFormatMessage()

  const filters = React.useMemo(() => ({
    p: {
      type: 'number'
    },
    ipp: {
      type: 'number'
    },
    'order[column]': {},
    'order[dir]': {},
    date: {
      componentType: 'date',
      transform: value => {
        if (!value) {
          return value
        }
        const date = new Date(value)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      },
      componentOptions: {
        placeholderText: formatMesage({ id: `filters.dateAppointment.placeholder` }),
        customInput: <SlotSelector />,
        dateFormat: 'dd/MM/yyyy',
      },
      withArrows: true
    },
    companyId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false,
        // eslint-disable-next-line react/display-name
        getOptionLabel: option => option.value == 0 ? <T raw id={`companyId.${option.value}`} /> : option.label
      },
    },
    familyId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false
      },
    },
    userTypeId: {
      array: false,
      componentType: 'select',
      componentOptions: {
        isMulti: false,
        isClearable: true,
        getOptionLabel: option => formatMesage({ id: `userType.${option.label}`, raw: true })
      },
    },
    interventionType: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false,
        // eslint-disable-next-line react/display-name
        getOptionLabel: option => option.value !== 0 && formatMesage({ id: `VeloptimUser.interventionType.${option.label}`, raw: true })
      },
    },
    itvcodeId: {
      array: true,
      componentType: 'itvcode',
      componentOptions: {
        isMulti: false
      },
    },
    userId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isSearchable: true,
        getOptionValue: option => option.label,
        getOptionLabel: option => option.value === 0 ? formatMesage({ id: `filters.userId.placeholder` }) : option.label

      },
    }
  }), [formatMesage])

  // DATA
  const params = React.useMemo(() => queryToObject(location.search, filters), [location.search, filters])

  const [data, refreshing, error, loading] = useGet(`/veloptim/plannedTour`, undefined, params)

  const refresh = React.useCallback((_params = {}) => history.push({ search: objectToQuery({ ...params, ..._params }) }), [history, params])

  const defaultProps = {
    center: {
      lat: 0,
      lng: -180
    },
    zoom: 3
  };

  const drawPolyline = React.useCallback((map, maps) => {
    if (data && data.results && data.results.length > 0) {
      let bounds = new maps.LatLngBounds();
      data.results.forEach(t => {
        if (t.tour && t.tour.length > 0) {
          const polyline = new maps.Polyline({
            path: t.tour,
            geodesic: true,
            // random color
            strokeColor: '#' + (Math.random() * 0xFFFFFF << 0).toString(16),
            strokeOpacity: 0.6,
            strokeWeight: 5,
          });
          t.tour.forEach(c => {
            bounds.extend(c)
          })
          polyline.setMap(map)

          var infoWindow = new maps.InfoWindow();

          // Open the InfoWindow on mouseover:
          maps.event.addListener(polyline, 'mouseover', function (e) {
            infoWindow.setPosition(e.latLng);
            infoWindow.setContent("<div>" + formatMesage({ id: "technician" }) + t.firstName + ' ' + t.lastName + "</div><div>" + formatMesage({ id: "km" }) + t.km.toFixed(3) + "</div>");
            infoWindow.open(map);
          });

          // Close the InfoWindow on mouseout:
          maps.event.addListener(polyline, 'mouseout', function () {
            infoWindow.close();
          });
        }
      })
      map.fitBounds(bounds);
    }

  }, [data, formatMesage])

  const tourDataExists = React.useCallback(() => {
    let result = false
    if (data && data.results && data.results.length > 0) {
      data.results.forEach(t => {
        if (t.tour && t.tour.length > 0) {
          result = true
        }
      })
    }
    return result
  }, [data])

  const handleApiLoaded = (map, maps) => {
    drawPolyline(map, maps)
  };

  return (
    <>
      <Card>
        <CardHeader className="mb-2">
          <Filters
            loading={loading}
            disabled={refreshing}
            data={data}
            refresh={refresh}
            filters={filters}
          />
        </CardHeader>
        <CardBody style={{ height: '50vh' }}>
          {error && (
            <TAlert id={error} color="danger" />
          )}
          {(loading || refreshing) && (
            <Spinner className="d-flex ml-auto mr-auto" color="primary" />
          )}
          {!error && !loading && !refreshing && (
            <>
              {data && data.results && data.results.length > 0 && tourDataExists() ? (
                <GoogleMapReact
                  bootstrapURLKeys={{ key: "AIzaSyCpJZIoNY_P1-uPW04TM4kDsWqnqLIiuEo" }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                ></GoogleMapReact>
              ) : (
                <TAlert id="noData" color="warning" />
              )}
            </>
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default VeloptimMapTour
