import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import CustomSelect from 'components/CustomSelect'

import {
  Spinner,
  Card, CardBody, CardFooter,
  Modal, ModalBody, ModalFooter,
  Form, FormGroup,
  Input,
  NavLink,
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'
import { T, TLabel, TButton, ErrAlert } from 'components/TComponents'

const SubcontractorDetails = ({ match }) => {
  const { api, constants } = React.useContext(AppContext)

  const [returnToList, setReturnToList] = React.useState(false)

  const [baseSubcontractor, setBaseSubcontractor] = React.useState({})
  const [subcontractor, setSubcontractor] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    userTypeId: 0,
    companyId: '',
    clearanceId: '',
    stockIdentifier: '',
    aplz: false
  })

  const [loading, setLoading] = React.useState(match.params.id !== undefined)
  const [loadError, setLoadError] = React.useState()
  const [onEdit, setOnEdit] = React.useState(match.params.id === undefined)
  const [editing, setEditing] = React.useState(false)
  const [editError, setEditError] = React.useState()

  const [companies, setCompanies] = React.useState()
  const [companiesLoading, setCompaniesLoading] = React.useState(true)
  const [companiesError, setCompaniesError] = React.useState()

  const [clearances, setClearances] = React.useState()
  const [clearancesLoading, setClearancesLoading] = React.useState(true)
  const [clearancesError, setClearancesError] = React.useState()

  const [lockModalOpen, setLockModalOpen] = React.useState(false)
  const [locking, setLocking] = React.useState(false)
  const [lockError, setLockError] = React.useState()

  React.useEffect(() => {
    if (match.params.id !== undefined) {
      setLoading(true)
      api.get('/subcontractors/details', undefined, { userId: match.params.id })
        .then(response => {
          setSubcontractor(response)
          setBaseSubcontractor({ ...response })
        })
        .catch(response => setLoadError(response))
        .then(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [api, match.params.id])

  React.useEffect(() => {
    api.get('/companies/list', undefined, { ipp: -1 })
      .then(response => setCompanies(response.result))
      .catch(response => setCompaniesError(response))
      .then(() => setCompaniesLoading(false))
  }, [api])

  React.useEffect(() => {
    api.get('/clearances/list', undefined, { ipp: -1 })
      .then(response => setClearances(response))
      .catch(response => setClearancesError(response))
      .then(() => setClearancesLoading(false))
  }, [api])

  const handleChange = React.useCallback(({ target: { name, value, type, checked } }) => {
    setSubcontractor({
      ...subcontractor,
      [name]: type === 'checkbox' ? checked : value
    })
  }, [subcontractor])

  const cancel = () => {
    setSubcontractor({ ...baseSubcontractor })
    setOnEdit(false)
  }

  const post = () => {
    setEditError()
    setEditing(true)

    api.post('/subcontractors/details', {
      body: JSON.stringify({
        ...subcontractor,
        userId: match.params.id || undefined,
      })
    })
      .then(response => {
        if (match.params.id) {
          setSubcontractor(response)
          setBaseSubcontractor({ ...response })

          setOnEdit(false)
          setEditing(false)
        } else {
          setEditing(false)
          setReturnToList(true)
        }
      })
      .catch(response => {
        setEditing(false)
        setEditError(response)
      })
  }

  const toggleLockUser = () => {
    setLocking(true)
    setLockError()
    api.post('/subcontractors/details', {
      body: JSON.stringify({
        userId: match.params.id,
        locked: !baseSubcontractor.locked,
      })
    })
      .then(response => {
        setSubcontractor(response)
        setBaseSubcontractor({ ...response })
        setLockModalOpen(false)
      })
      .catch(response => setLockError(response))
      .then(() => setLocking(false))
  }

  const clearanceName = React.useMemo(() => {
    if (clearances) {
      const clearance = clearances.result.find(clearance => String(clearance.clearanceId) === String(subcontractor.clearanceId))
      return clearance ? clearance.clearanceName : undefined
    }
  }, [clearances, subcontractor.clearanceId])

  const hasId = match.params.id !== undefined

  if (returnToList) {
    return <Redirect to="/admin/subcontractors" />
  }

  if (loading) {
    return <>
      <NavLink tag={Link} to="/admin/subcontractors"><T id="returnToList" /></NavLink>
      <Spinner className="d-flex ml-auto mr-auto" color="primary" />
    </>
  }

  if (loadError) {
    return <>
      <NavLink tag={Link} to="/admin/subcontractors"><T id="returnToList" /></NavLink>
      <ErrAlert error={loadError} />
    </>
  }

  return (
    <>
      <NavLink tag={Link} to="/admin/subcontractors"><T id="returnToList" /></NavLink>
      <Card className="mb-2">
        <CardBody tag={Form}>
          <FormGroup tag="fieldset">
            <TLabel id="firstName" for="firstName" />
            <Input id="firstName"
              type="text"
              name="firstName"
              value={subcontractor.firstName || ''}
              disabled={editing || !onEdit}
              onChange={handleChange} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel id="lastName" for="lastName" />
            <Input id="lastName"
              type="text"
              name="lastName"
              value={subcontractor.lastName || ''}
              disabled={editing || !onEdit}
              onChange={handleChange} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel id="email" for="email" />
            <Input id="email"
              type="text"
              name="email"
              value={subcontractor.email || ''}
              disabled={editing || !onEdit || hasId}
              onChange={handleChange} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel id="userTypeId" for="userTypeId" />
            <CustomSelect
              id="userTypeId"
              onChange={e => handleChange({ target: { name: 'userTypeId', value: e.value } })}
              isSearchable={false}
              name="userTypeId"
              options={constants.userTypes}
              value={constants.userTypes.find(_userType => _userType.value === subcontractor.userTypeId)}
              placeholder={'...'}
              isDisabled={editing || !onEdit}
              getOptionLabel={option => <T raw id={`userType.${option.key}`} />} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel id="companyId.label" for="companyId" />
            {companiesError && <ErrAlert error={companiesError} className="mb-0" />}
            {companiesLoading && !companiesError && <Spinner className="d-block" />}
            {!companiesLoading && !companiesError && <CustomSelect
              id="companyId"
              name="companyId"
              isDisabled={!onEdit}
              value={companies.find(company => company.companyId === subcontractor.companyId) || ''}
              onChange={e => handleChange({ target: { name: 'companyId', value: e.companyId } })}
              isSearchable={false}
              placeholder={'...'}
              options={companies}
              getOptionLabel={option => option.companyName}
              getOptionValue={option => option.companyId} />}
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="clearanceId" id="clearanceLabel" />
            {clearancesError && <ErrAlert error={clearancesError} className="mb-0" />}
            {clearancesLoading && <Spinner className="d-flex" />}
            {!clearancesLoading && !clearancesError &&
              <CustomSelect
                inputId="clearanceId"
                name="clearanceId"
                options={clearances.result}
                onChange={e => handleChange({ target: { name: 'clearanceId', value: e && e.clearanceId } })}
                isClearable
                placeholder={<T id="clearanceSelect" />}
                value={clearances.result.filter(c => c.clearanceId === subcontractor.clearanceId)}
                getOptionLabel={option => <T raw id={`clearances.${option.clearanceName}`} />}
                getOptionValue={option => option.clearanceId}
                isDisabled={editing || !onEdit} />
            }
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="stockIdentifier" id="stockIdentifier" values={{ clearanceName }} />
            <Input id="stockIdentifier"
              type="text"
              name="stockIdentifier"
              value={subcontractor.stockIdentifier}
              disabled={editing || !onEdit || !subcontractor.clearanceId}
              onChange={handleChange} />
          </FormGroup>

          {(onEdit || !hasId) && <FormGroup tag="fieldset" check>
            <Input id="aplz"
              type="checkbox"
              name="aplz"
              checked={subcontractor.aplz || false}
              disabled={editing}
              onChange={handleChange} />
            <TLabel id="aplz" className="" for="aplz" check />
          </FormGroup>}
          {editError && <ErrAlert error={editError} className="mt-2" />}
        </CardBody>
        <CardFooter className="d-flex">
          {hasId && <TButton id={`${subcontractor.locked ? 'unlock' : 'lock'}`} disabled={editing} className="ml-2" onClick={() => { setLockError(); setLockModalOpen(true) }} color={subcontractor.locked ? 'primary' : 'danger'} />}
          <div className="d-flex ml-auto pl-2">
            {(!hasId || onEdit) && <TButton id="post" disabled={editing || lockModalOpen} loading={editing} className="ml-2" onClick={() => post()} color="primary" />}
            {hasId && !onEdit && <TButton id="edit" disabled={editing || lockModalOpen} className="ml-2" onClick={() => setOnEdit(true)} color="primary" />}
            {(!hasId || !onEdit) && <TButton id="cancel" disabled={editing || lockModalOpen} tag={Link} className="ml-2" to="/admin/subcontractors" />}
            {hasId && onEdit && <TButton id="cancel" disabled={editing || lockModalOpen} className="ml-2" onClick={() => cancel()} />}
          </div>
        </CardFooter>
      </Card>

      <Modal isOpen={lockModalOpen} fade={false} toggle={() => setLockModalOpen(false)}>
        <ModalBody>
          <T id={`${subcontractor.locked ? 'unlock' : 'lock'}Content`} />
          {lockError && <ErrAlert error={lockError} className="mb-0 mt-2" />}
        </ModalBody>
        <ModalFooter>
          <TButton id="lockCancel" disabled={locking} onClick={() => setLockModalOpen(false)} />
          <TButton id={`${subcontractor.locked ? 'unlock' : 'lock'}Confirm`}
            disabled={locking}
            loading={locking} className="ml-2 d-flex align-items-center"
            color={subcontractor.ocked ? 'primary' : 'danger'}
            onClick={() => toggleLockUser()} />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default SubcontractorDetails
