import React from 'react'
import { Switch } from 'react-router-dom'

import Route from 'components/Route'

import List from 'routes/Admin/Techcenters/TechcenterList'
import Details from 'routes/Admin/Techcenters/TechcenterDetails'

export default function Techcenters({ match }) {
  return <Switch>
    <Route exact path={`${match.path}`} component={List} rights={['admin-techcenters_edit', 'admin-techcenters_view',]} />
    <Route path={`${match.path}/new`} component={Details} rights={['admin-techcenters_edit',]} />
    <Route path={`${match.path}/:id`} component={Details} rights={['admin-techcenters_edit', 'admin-techcenters_view',]} />
  </Switch>
}
