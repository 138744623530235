import React from 'react'
import { useParams, Link } from 'react-router-dom'

import {
  Card, CardBody, CardHeader, CardFooter,
  Modal, ModalBody, ModalFooter,
  Form, FormGroup, Input,
  Spinner
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import CustomSelect from 'components/CustomSelect'
import ProtectedComponent from 'components/ProtectedComponent'
import { T, ErrAlert, TButton, TNavLink, TLabel } from 'components/TComponents'

const FamilyEdit = () => {
  const { api } = React.useContext(AppContext)
  const routeParams = useParams()

  const [data, setData] = React.useState()
  const [dataBase, setDataBase] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()
  const [updating, setUpdating] = React.useState(false)
  const [updateError, setUpdateError] = React.useState()
  const [archiving, setArchiving] = React.useState(false)
  const [archiveError, setArchiveError] = React.useState()

  const [categories, setCategories] = React.useState()
  const [categoriesLoading, setCategoriesLoading] = React.useState(false)
  const [categoriesError, setCategoriesError] = React.useState()

  const [onEdit, setOnEdit] = React.useState(false)
  const [archiveOpen, setArchiveOpen] = React.useState(false)

  React.useEffect(() => {
    api.get('/families/details', undefined, { familyId: routeParams.id })
      .then(response => {
        setData(response)
        setDataBase(response)
      })
      .catch(error => setError(error))
      .then(() => setLoading(false))

    api.get('/categories/list', undefined, { ipp: -1 })
      .then(bases => setCategories(bases))
      .catch(error => setCategoriesError(error))
      .then(() => setCategoriesLoading(false))
  }, [api, routeParams])

  const handleChange = React.useCallback(e => setData({ ...data, [e.target.name]: e.target.value }), [data])

  const save = React.useCallback(() => {
    setUpdating(true)
    setUpdateError(undefined)
    Promise.all([
      api.post('/families/details', { body: JSON.stringify(data) }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([response]) => {
        setData(response)
        setDataBase(response)
        setOnEdit(false)
      })
      .catch(error => setUpdateError(error))
      .then(() => setUpdating(false))
  }, [api, data])

  const archive = React.useCallback(() => {
    setArchiving(true)
    setArchiveError(undefined)
    api.del('/families/details', undefined, { familyId: routeParams.id })
      .then(response => {
        setData(response)
        setDataBase(response)
        setOnEdit(false)
        setArchiveOpen(false)
      })
      .catch(error => setArchiveError(error))
      .then(() => setArchiving(false))
  }, [api, routeParams.id])

  const cancel = React.useCallback(() => {
    setData(dataBase)
    setOnEdit(false)
  }, [dataBase])

  if (error) {
    return <>
      <TNavLink tag={Link} to="/admin/categories?type=families" id="returnToList" />
      <ErrAlert error={error} className="mt-2" />
    </>
  }

  if (!data || loading || categoriesLoading) {
    return <>
      <TNavLink tag={Link} to="/admin/categories?type=families" id="returnToList" />
      <Spinner className="d-flex ml-auto mr-auto" color="primary" />
    </>
  }

  return (
    <>
      <TNavLink tag={Link} to="/admin/categories?type=families" id="returnToList" />
      <Card className="mb-2" tag={Form} onSubmit={e => e.preventDefault()}>
        <CardHeader tag="h2">{dataBase.familyName}</CardHeader>
        <CardBody>
          <FormGroup tag="fieldset">
            <TLabel for="categoryId" id="categoryId" />
            {categories && (
              <CustomSelect
                inputId="categoryId"
                name="categoryId"
                options={categories.result}
                onChange={e => { handleChange({ target: { name: 'categoryId', value: e.categoryId } }) }}
                value={categories.result.filter(c => c.categoryId === data.categoryId)}
                isDisabled={updating || !onEdit}
                getOptionLabel={option => option.categoryName}
                getOptionValue={option => option.categoryId} />
            )}
            {categoriesError && <ErrAlert className="mb-0 mt-4" error={categoriesError} />}
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="familyName" id="familyName" />
            <Input id="familyName"
              type="text"
              name="familyName"
              disabled={!onEdit}
              value={data.familyName}
              onChange={handleChange} />
          </FormGroup>
          {updateError && <ErrAlert error={updateError} />}
        </CardBody>
        <ProtectedComponent rights={['admin-categories_edit']}>
          <CardFooter className="d-flex justify-content-end">
            {!onEdit && <TButton onClick={() => setOnEdit(true)} id="edit" />}
            {onEdit && <ProtectedComponent rights={['admin_archive']}>
              <TButton disabled={updating} onClick={() => setArchiveOpen(true)} color="danger" className="mr-auto" id={data.archived ? 'restore' : 'archive'} />
            </ProtectedComponent>}
            {onEdit && <TButton disabled={updating} loading={updating} className="ml-2" onClick={save} color="primary" id="save" />}
            {onEdit && <TButton disabled={updating} className="ml-2" onClick={cancel} id="cancel" />}
          </CardFooter>
        </ProtectedComponent>

        <Modal isOpen={archiveOpen} fade={false} toggle={() => setArchiveOpen(false)}>
          <ModalBody>
            <T id={`${data.archived ? 'restore' : 'archive'}.content`} />
            {archiveError && <ErrAlert error={archiveError} className="mb-0 mt-2" />}
          </ModalBody>
          <ModalFooter className="py-3">
            <TButton disabled={archiving}
              onClick={() => setArchiveOpen(false)}
              id={`${data.archived ? 'restore' : 'archive'}.cancel`} />
            <TButton disabled={archiving} loading={archiving}
              className="ml-2" color="danger"
              onClick={archive}
              id={`${data.archived ? 'restore' : 'archive'}.confirm`} />
          </ModalFooter>
        </Modal>
      </Card>
    </>
  )
}

export default FamilyEdit
