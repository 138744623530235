import React from 'react'
import { objectToQuery, queryToObject } from 'react-rest-api'
import {
  Card, CardBody, CardHeader, Spinner
} from 'reactstrap'

import { useGet } from 'hooks/useApi.hooks'

import SlotSelector from 'components/SlotSelector'
import { TAlert, TLabel } from 'components/TComponents'

import { useFormatMessage } from 'hooks/intl.hooks'

import { Filters } from 'components/Filters'
import { Table } from 'components/Table'

const VeloptimZones = ({ location, history }) => {
  const formatMesage = useFormatMessage()

  const filters = React.useMemo(() => ({
    pZonning: {
      type: 'number'
    },
    pTechcenter: {
      type: 'number'
    },
    ippZonning: {
      type: 'number'
    },
    ippTechcenter: {
      type: 'number'
    },
    'order[column]': {},
    'order[dir]': {},
    date: {
      componentType: 'date',
      transform: value => {
        if (!value) {
          return value
        }
        const date = new Date(value)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      },
      componentOptions: {
        placeholderText: formatMesage({ id: `filters.dateAppointment.placeholder` }),
        customInput: <SlotSelector />,
        dateFormat: 'dd/MM/yyyy',
      },
      withArrows: true,
      customPagination: ['pTechcenter', 'pZonning']
    },
  }), [formatMesage])

  // DATA
  const params = React.useMemo(() => queryToObject(location.search, filters), [location.search, filters])

  const [zonningData, zonningRefreshing, zonningError, zonningLoading] = useGet(`/veloptim/availableUserByDayByZonning`, undefined, params)
  const [technicenterData, technicenterRefreshing, technicenterError, technicenterLoading] = useGet(`/veloptim/availableUserByDayByTechcenter`, undefined, params)

  const refresh = React.useCallback((_params = {}) => history.push({ search: objectToQuery({ ...params, ..._params }) }), [history, params])

  return (
    <>
      <Card>
        <CardHeader className="mb-2">
          <Filters
            loading={zonningLoading}
            disabled={zonningRefreshing}
            data={zonningData}
            refresh={refresh}
            filters={filters}
          />
        </CardHeader>
        <CardBody>
          {zonningError && (
            <TAlert id={zonningError} color="danger" />
          )}
          {(zonningLoading || zonningRefreshing) && (
            <Spinner className="d-flex ml-auto mr-auto" color="primary" />
          )}
          {!zonningError && !zonningLoading && !zonningRefreshing && (
            <>
              <TLabel id="zoningTable" />
              <Table
                isLoading={zonningLoading}
                disabled={zonningRefreshing}
                data={zonningData}
                customPagination={'zonning'}
                refresh={refresh}
                columns={[
                  {
                    title: 'zoninggroupLabel',
                    hasOrder: true,
                    render: item => item.zoninggroupLabel
                  },
                  {
                    title: 'nbUsers',
                    hasOrder: true,
                    render: item => item.nbUsers
                  },
                  {
                    title: 'availableUser',
                    render: item => item.availableUser
                  },
                ]}
              />
            </>
          )}
        </CardBody>
        <CardBody>
          {technicenterError && (
            <TAlert id={technicenterError} color="danger" />
          )}
          {(technicenterLoading || technicenterRefreshing) && (
            <Spinner className="d-flex ml-auto mr-auto" color="primary" />
          )}
          {!technicenterError && !technicenterLoading && !technicenterRefreshing && (
            <>
              <TLabel id="techcenterTable" />
              <Table
                isLoading={technicenterLoading}
                disabled={technicenterRefreshing}
                data={technicenterData}
                customPagination={'techcenter'}
                refresh={refresh}
                columns={[
                  {
                    title: 'name',
                    hasOrder: true,
                    render: item => `${item.firstName} ${item.lastName}`
                  },
                  {
                    title: 'address',
                    hasOrder: true,
                    render: item => item.formatted_address
                  },
                  {
                    title: 'isFullLabel',
                    render: item => formatMesage({ id: item.isFull ? `isFull` : `isNotFull` })
                  },
                ]}
              />
            </>
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default VeloptimZones
