/* eslint-disable react/display-name */
import React from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'
import { useLocation, useHistory, NavLink } from 'react-router-dom'
import { objectToQuery, queryToObject } from 'react-rest-api'

import { Card, CardHeader, } from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import { useGet } from 'hooks/useApi.hooks'
import { useFormatMessage } from 'hooks/intl.hooks'

import { ErrAlert, TButton, T, TCustomInput } from 'components/TComponents'
import { Table } from 'components/Table'
import { Filters } from 'components/Filters'

const PrivateLogList = () => {
  const location = useLocation()
  const history = useHistory()
  const formatMesage = useFormatMessage()
  const { api } = React.useContext(AppContext)

  const [refreshedData, setRefreshedData] = React.useState()

  const filters = React.useMemo(() => ({
    p: {
      type: 'number'
    },
    ipp: {
      type: 'number'
    },
    'order[column]': {},
    'order[dir]': {},
    filter: {
      type: 'string',
      componentType: 'textDebounce'
    },
    criticality: {
      componentType: 'select',
      componentOptions: {
        getOptionLabel: option => formatMesage({ id: `Triggers.criticality.${option.label}`, raw: true })
      }
    },
    archived: {
      type: 'boolean',
      componentType: "switch",
      noLabel: true
    },
  }), [formatMesage])

  // DATA
  const params = React.useMemo(() => queryToObject(location.search, filters), [location.search, filters])

  const [data, refreshing, error, loading] = useGet(`/alerttriggers/listPrivate`, undefined, params)

  const refresh = React.useCallback((_params = {}) => history.push({ search: objectToQuery({ ...params, ..._params }) }), [history, params])

  React.useEffect(() => {
    if (data) {
      setRefreshedData(data)
    }
  }, [data])

  // INNER COMPONENTS
  const RenderId = React.useCallback(item => {
    const color = ((value) => {
      switch (value) {
      case 1: return 'success'
      case 2: return 'warning'
      case 3: return 'danger'
      default: return ''
      }
    })(item.criticality)
    return <h6 className="mb-0"><span className={`badge badge-${color}`}>{item.alerttriggerId}</span></h6>
  }, [])

  const DateCreated = React.useCallback(item => (
    <span><FormattedDate value={new Date(item.dateCreated)} />{' '}<FormattedTime value={new Date(item.dateCreated)} /></span>
  ), [])

  const RenderMessage = React.useCallback(item => {
    switch (item.triggerIdentifier) {
    case 'frequency_intervention_equipment':
      return (
        <T id="Triggers.message.frequency_intervention_equipment" raw values={{ equipmentId: item.equipmentIdentifier, nbItv: item.params.nb_itv, frequency: item.params.frequency }} />
      );
    case 'frequency_intervention_equipment_one_itvcode':
      return (
        <T id="Triggers.message.frequency_intervention_equipment_one_itvcode" raw values={{ equipmentId: item.equipmentIdentifier, nbItv: item.params.nb_itv, frequency: item.params.frequency, itvcodeIdentifier: item.params.itvcodeIdentifier, itvcodeLabel: item.params.itvcodeLabel }} />
      );
    case 'frequency_intervention_equipment_one_itvcode_subscription':
      return (
        <T id="Triggers.message.frequency_intervention_equipment_one_itvcode_subscription" raw values={{ equipmentId: item.equipmentIdentifier, nbItv: item.params.nb_itv, frequency: item.params.frequency, itvcodeIdentifier: item.params.itvcodeIdentifier, itvcodeLabel: item.params.itvcodeLabel, subscriptionId: item.subscriptionId }} />
      );
    default:
      return ''
    }
  }, [])

  const DetailsButton = React.useCallback(item => {
    switch (item.triggerIdentifier) {
    case 'frequency_intervention_equipment': case 'frequency_intervention_equipment_one_itvcode': case 'frequency_intervention_equipment_one_itvcode_subscription':
      return (
        <TButton className="ml-auto" outline
          id="details"
          tag={NavLink}
          to={`/equipments/${item.equipmentId}`} />
      );
    default:
      return ''
    }
  }, [])

  const ArchiveButton = React.useCallback(item => {
    return (
      <TCustomInput type="switch"
        id="archived"
        name="archived"
        className="w-100 bg-transparent border-0"
        onChange={() => { archive(item) }}
        disabled={item.archived}
        checked={item.archived} />
    )
  }, [archive])

  const archive = React.useCallback(item => {
    api.del('/alerttriggers/details', undefined, { alerttriggersId: item.alerttriggerId })
      .then(response => setRefreshedData(response))
  }, [api])

  // RENDER
  if (error) {
    return <ErrAlert error={error} />
  }

  return (
    <div className="container-fluid">
      <Card className="mb-2">
        <CardHeader>
          <Filters
            loading={loading}
            disabled={refreshing}
            data={refreshedData || data}
            refresh={refresh}
            filters={filters}
          />
        </CardHeader>
      </Card>
      <Table
        isLoading={loading}
        disabled={refreshing}
        data={refreshedData || data}
        refresh={refresh}
        columns={[
          {
            title: 'alerttriggerId',
            render: RenderId
          },
          {
            title: 'dateCreated',
            hasOrder: true,
            render: DateCreated
          },
          {
            title: 'type',
            render: item => <T id={`Triggers.${item.triggerIdentifier}`} raw />
          },
          {
            title: 'description',
            render: item => item.description
          },
          {
            title: 'message',
            render: RenderMessage
          },
          {
            title: 'archived',
            render: ArchiveButton
          },
          {
            className: () => 'align-middle text-right',
            render: DetailsButton
          },
        ]}
      />
    </div>
  )
}

export default PrivateLogList
