import React from 'react'
import {
  Nav,
  NavItem,
  TabContent,
  NavLink,
  TabPane
} from 'reactstrap'
import { Redirect, Switch, NavLink as RRNavLink } from 'react-router-dom'

import { AppContext } from 'contexts/AppContext'

import ProtectedComponent from 'components/ProtectedComponent'
import Route from 'components/Route'
import { T } from 'components/TComponents'

import Users from './Users/UserRoute'
import ProfileList from './ProfileList'
import Techcenters from './Techcenters/TechcenterRoute'
import Bases from './Bases/BaseRoute'
import Categories from './Categories/CategoryRoute'
import Businessusers from './Businessusers/BusinessuserRoute'
import Business from './Business/BusinessRoute'
import Matrices from './Matrices/MatrixRoute'
import Companies from './Companies/CompanyRoute'
import Itvcodes from './Itvcodes/ItvcodeRoute'
import Itvcodegroups from './Itvcodegroups/ItvcodegroupsRoute'
import Itvcodelists from './Itvcodelists/ItvcodelistRoute'
import Itvcodeequipment from './Itvcodeequipment/ItvcodeequipmentRoute'
import Itvcodereason from './Itvcodereason/ItvcodereasonRoute'
import Subcontractors from './Subcontractors/SubcontractorRoute'
import SubsidiaryEdit from './Subsidiaries/SubsidiaryEdit'
import Tags from './Tags/Tags'
import DeskTags from './Tags/DeskTags'
import Vehicles from './Vehicles/VehiclesRoute'
import Veloptim from './Veloptim/Veloptim'
import Checklist from './Checklists/Checklist'
import Triggers from './Triggers/TriggersRoute'

const Admin = ({ match }) => {
  const { user, profiles, subsidiary } = React.useContext(AppContext)

  const to = (profiles => {
    if (profiles.find(profile => profile.rights.find(right => ['admin-users_view', 'admin-users_edit'].includes(right)))) { return 'users' }
    if (profiles.find(profile => profile.rights.find(right => ['admin-profiles_view', 'admin-profiles_edit'].includes(right)))) { return 'rights' }
    if (profiles.find(profile => profile.rights.find(right => ['admin-business_view', 'admin-business_edit'].includes(right)))) { return 'business' }
    if (profiles.find(profile => profile.rights.find(right => ['admin-matrices_view', 'admin-matrices_edit'].includes(right)))) { return 'matrices' }
    if (profiles.find(profile => profile.rights.find(right => ['admin-companies_view', 'admin-companies_edit'].includes(right)))) { return 'companies' }
    if (profiles.find(profile => profile.rights.find(right => ['admin-bases_view', 'admin-bases_edit'].includes(right)))) { return 'bases' }
    if (profiles.find(profile => profile.rights.find(right => ['admin-techcenters_view', 'admin-techcenters_edit'].includes(right)))) { return 'techcenters' }
    if (profiles.find(profile => profile.rights.find(right => ['admin-categories_view', 'admin-categories_edit'].includes(right)))) { return 'categories' }
  })(profiles)

  return (
    <div className="container-fluid">
      <Nav tabs className="mb-3">
        <div className="navitem-container">
          <ProtectedComponent rights={['admin-users_view', 'admin-users_edit']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/admin/users">
                <T id="users" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
          <ProtectedComponent rights={['admin-profiles_view', 'admin-profiles_edit']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/admin/rights">
                <T id="profiles" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
        </div>
        <ProtectedComponent rights={[
          'admin-users_view', 'admin-users_edit',
          'admin-profiles_view', 'admin-profiles_edit']}>
          <NavItem className="mr-2" />
        </ProtectedComponent>
        <div className="navitem-container">
          <ProtectedComponent rights={['admin-business_view', 'admin-business_edit']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/admin/business">
                <T id="business" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
          <ProtectedComponent rights={['admin-businessusers_view', 'admin-businessusers_edit']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/admin/businessusers">
                <T id="businessusers" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
          <ProtectedComponent rights={['admin-subcontractors_view', 'admin-subcontractors_edit']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/admin/subcontractors">
                <T id="subcontractors" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
        </div>
        <ProtectedComponent rights={[
          'admin-business_view', 'admin-business_edit',
          'admin-businessusers_view', 'admin-businessusers_edit',
          'admin-subcontractors_view', 'admin-subcontractors_edit']}>
          <NavItem className="mr-2" />
        </ProtectedComponent>
        <div className="navitem-container">
          <ProtectedComponent rights={['admin-bases_view', 'admin-bases_edit']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/admin/bases">
                <T id="bases" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
          <ProtectedComponent rights={['admin-techcenters_view', 'admin-techcenters_edit']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/admin/techcenters">
                <T id="techcenters" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
          <ProtectedComponent rights={['admin-categories_view', 'admin-categories_edit']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/admin/categories">
                <T id="categories" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
          <ProtectedComponent rights={['admin-matrices_view', 'admin-matrices_edit']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/admin/matrices">
                <T id="matrices" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
          <ProtectedComponent rights={['admin-companies_view', 'admin-companies_edit']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/admin/companies">
                <T id="companies" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
        </div>
        <ProtectedComponent rights={[
          'admin-itvcodes_view', 'admin-itvcodes_edit',
          'admin-itvcodelists_view', 'admin-itvcodelists_edit']}>
          <NavItem className="mr-2" />
        </ProtectedComponent>
        <div className="navitem-container">
          <ProtectedComponent rights={['admin-itvcodes_view', 'admin-itvcodes_edit']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/admin/itvcodes">
                <T id="itvcodes" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
          <ProtectedComponent rights={['admin-itvcodegroups_view', 'admin-itvcodegroups_edit']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/admin/itvcodegroups">
                <T id="itvcodegroups" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
          <ProtectedComponent rights={['admin-itvcodelists_view', 'admin-itvcodelists_edit']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/admin/itvcodelists">
                <T id="itvcodelists" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
          <ProtectedComponent rights={['admin-matrices_link_itvcodes']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/admin/itvcodeequipment">
                <T id="itvcodeequipment" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
          {!!(subsidiary && subsidiary.enableReasonParts) && (
            <ProtectedComponent rights={['admin-reasonpartchange']}>
              <NavItem>
                <NavLink
                  tag={RRNavLink}
                  activeClassName="active"
                  to="/admin/itvcodereason">
                  <T id="itvcodereason" />
                </NavLink>
              </NavItem>
            </ProtectedComponent>
          )}
        </div>
        {user.settings.canManageSubsidiary && <ProtectedComponent>
          <NavItem className="mr-2" />
        </ProtectedComponent>}
        <div className="navitem-container">
          {user.settings.canManageSubsidiary && <ProtectedComponent>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/admin/subsidiary">
                <T id="subsidiary" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>}
        </div>
        <ProtectedComponent rights={['admin-tags_view']}>
          <NavItem className="mr-2" />
        </ProtectedComponent>
        <div className="navitem-container">
          <ProtectedComponent rights={['admin-tags_view']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/admin/tags">
                <T id="tags" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
          <ProtectedComponent rights={['admin-tags-desk_view']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/admin/desktags">
                <T id="desktags" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
        </div>
        <ProtectedComponent rights={['admin-veloptim_view']}>
          <NavItem className="mr-2" />
        </ProtectedComponent>
        <div className="navitem-container">
          <ProtectedComponent rights={['admin-veloptim_view']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/admin/veloptim">
                <T id="veloptim" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
          <ProtectedComponent rights={['admin-vehicle_view', 'admin-vehicle_edit']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/admin/vehicles">
                <T id="vehicles" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
        </div>
        <ProtectedComponent rights={['admin-checklist_view']}>
          <NavItem className="mr-2" />
        </ProtectedComponent>
        <div className="navitem-container">
          <ProtectedComponent rights={['admin-checklist_view']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/admin/checklists">
                <T id="checklists" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
        </div>
        <ProtectedComponent rights={['admin-triggers_view']}>
          <NavItem className="mr-2" />
        </ProtectedComponent>
        <div className="navitem-container">
          <ProtectedComponent rights={['admin-triggers_view']}>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                activeClassName="active"
                to="/admin/triggers">
                <T id="triggers" />
              </NavLink>
            </NavItem>
          </ProtectedComponent>
        </div>
      </Nav>

      <TabContent>
        <Switch>
          <TabPane tag={Redirect} exact from={`${match.path}/`} to={`${match.path}/${to}`} />
          <TabPane tag={Route} path={`${match.path}/users`} component={Users} />
          <TabPane tag={Route} path={`${match.path}/rights`} component={ProfileList} />
          <TabPane tag={Route} path={`${match.path}/techcenters`} component={Techcenters} />
          <TabPane tag={Route} path={`${match.path}/bases`} component={Bases} />
          <TabPane tag={Route} path={`${match.path}/categories`} component={Categories} />
          <TabPane tag={Route} path={`${match.path}/business`} component={Business} />
          <TabPane tag={Route} path={`${match.path}/matrices`} component={Matrices} />
          <TabPane tag={Route} path={`${match.path}/companies`} component={Companies} />
          <TabPane tag={Route} path={`${match.path}/businessusers`} component={Businessusers} />
          <TabPane tag={Route} path={`${match.path}/subcontractors`} component={Subcontractors} />
          <TabPane tag={Route} path={`${match.path}/itvcodes`} component={Itvcodes} />
          <TabPane tag={Route} path={`${match.path}/itvcodegroups`} component={Itvcodegroups} />
          <TabPane tag={Route} path={`${match.path}/itvcodelists`} component={Itvcodelists} />
          <TabPane tag={Route} path={`${match.path}/itvcodeequipment`} component={Itvcodeequipment} />
          <TabPane tag={Route} path={`${match.path}/itvcodereason`} component={Itvcodereason} />
          {user.settings.canManageSubsidiary && <TabPane tag={Route} path={`${match.path}/subsidiary`} component={SubsidiaryEdit} />}
          <TabPane tag={Route} path={`${match.path}/tags`} component={Tags} />
          <TabPane tag={Route} path={`${match.path}/desktags`} component={DeskTags} />
          <TabPane tag={Route} path={`${match.path}/veloptim`} component={Veloptim} />
          <TabPane tag={Route} path={`${match.path}/vehicles`} component={Vehicles} />
          <TabPane tag={Route} path={`${match.path}/checklists`} component={Checklist} />
          <TabPane tag={Route} path={`${match.path}/triggers`} component={Triggers} />
          <TabPane tag={Redirect} to={`/error/`} />
        </Switch>
      </TabContent>
    </div>
  )
}

export default Admin
