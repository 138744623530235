import React from 'react'
import { ButtonGroup, } from 'reactstrap'

import Button from './Button'

const SlotSelector = ({ disabled, onClick, onChange, value, placeholder, isCustomClearable, onClear, className = '' }, ref) => (
  <ButtonGroup>
    <Button
      ref={ref}
      type="button"
      className={className + (isCustomClearable && value ? ' pr-3' : '')}
      disabled={disabled}
      onClick={onClick}>
      {value || placeholder}
    </Button>
    {isCustomClearable && value && (
      <>
        {onClear ? (
          <Button
            type="button"
            className="pl-3 border-left"
            disabled={disabled}
            onClick={onClear}>
            X
          </Button>
        ) : (
          <Button
            type="button"
            className="pl-3 border-left"
            disabled={disabled}
            onClick={() => onChange({ target: { value: '' } })}>
            X
          </Button>
        )}
      </>
    )}
  </ButtonGroup>
)

export default React.forwardRef(SlotSelector)