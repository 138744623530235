import React from 'react'
import { useIntl } from 'react-intl'

import { AppContext } from 'contexts/AppContext'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom'

import {
  Badge, Modal, ModalBody, ModalFooter, Card, CardBody
} from 'reactstrap'

import { formatDate } from 'util/Utils'
import { T, TAlert, TButton, TLabel } from 'components/TComponents'

import VeloptimPlanModal from 'components/VeloptimPlanModal'
import './VeloptimPlanComponent.scss'

const VeloptimPlanComponent = ({ location }) => {
  const { typeIntervention, id, itvcodes, familyId, mapId, durationPlanned, matchFamily, matchItvcodes, matchLimitItv, matchZonning, matchTypeIntervention,  calculateCloseItv, vehicleType, userTypology, subsidiarySlotId, filters } = location.state.props
  const { api, timezone, subsidiary } = React.useContext(AppContext)
  const history = useHistory()
  const intl = useIntl()

  const [loading, setLoading] = React.useState(false)
  const [loadingMore, setLoadingMore] = React.useState(false)
  const [techenterScheduleModal, setTechenterScheduleModal] = React.useState(false)
  const [techenterScheduleData, setTechenterScheduleData] = React.useState()

  const [results, setResults] = React.useState([])
  const [searchError, setSearchError] = React.useState()
  const [p, setP] = React.useState(0)

  const [planError, setPlanError] = React.useState()
  const [planLoading, setPlanLoading] = React.useState(false)
  const [planErrorItem, setPlanErrorItem] = React.useState()

  const [isSearch, setIsSearch] = React.useState(location.state.props.isSearch)
  const [initialState, setInitialState] = React.useState(true)

  const [veloptimModal, setVeloptimModal] = React.useState(false)

  React.useEffect(() => {
    if (isSearch) {
      if (p === 0) {
        setLoading(true)
        setInitialState(false)
      } else {
        setLoadingMore(true)
      }
      api.post('/veloptim/calculationFilter', {
        body: JSON.stringify({
          typeIntervention,
          itvcodes,
          familyId,
          mapId,
          durationPlanned,
          matchTypeIntervention,
          matchItvcodes,
          matchFamily,
          matchLimitItv,
          matchZonning,
          userTypology,
          calculateCloseItv,
          vehicleType: vehicleType || null,
          subsidiarySlotId: subsidiarySlotId || null,
          ipp: 10,
          p
        })
      })
        .then(response => {
          if (response.code) {
            setSearchError(response.code)
          } else {
            if (p === 0) {
              setResults(response.results)
            } else {
              setResults(results => ([
                ...results,
                ...response.results
              ]))
            }
          }
        })
        .catch(error => {
          setSearchError(error.code)
        })
        .then(() => {
          setLoading(false)
          setLoadingMore(false)
          setIsSearch(false)
          setInitialState(false)
        })
    }
  }, [api, durationPlanned, familyId, itvcodes, mapId, matchFamily, matchItvcodes, matchLimitItv, matchZonning, matchTypeIntervention, subsidiarySlotId, typeIntervention, userTypology, vehicleType, p, initialState, isSearch, calculateCloseItv])

  React.useEffect(() => {
    setP(0)
    setIsSearch(true)
  }, [])

  React.useEffect(() => {
    if (p > 0) {
      setIsSearch(true)
    }
  }, [p])

  React.useEffect(() => {
    setP(0)
  }, [matchFamily, matchItvcodes, matchLimitItv, matchZonning, matchTypeIntervention, subsidiarySlotId, typeIntervention, userTypology, vehicleType])

  const formatTime = React.useCallback(time => {
    const time1 = time.split('.')[0]
    return `${time1.split(':')[0]}:${time1.split(':')[1]}`
  }, [])

  const toSqlFormat = React.useCallback((time) => {
    return `${String(Math.trunc(time / 60)).padStart(2, '0')}:${String(time % 60).padStart(2, '0')}:00`
  }, [])

  const fromSqlFormat = React.useCallback((time) => {
    const timeArray = time.split(':')
    return Number(timeArray[0]) * 60 + Number(timeArray[1])
  }, [])

  const timeFromUTC = React.useCallback(initialDate => {
    const dateArray = initialDate.split(' ')
    const dateInJs = `${dateArray[0]}T${dateArray[1]}Z`
    return moment(dateInJs).tz(timezone).format('HH:mm')
  }, [timezone])

  const timeDateFromUtc = React.useCallback(({ time, date }) => {
    const dateInJs = `${date}T${time}Z`
    return moment(dateInJs).tz(timezone).format('HH:mm')
  }, [timezone])

  const goToIntervention = React.useCallback(({ success, adressChange }) => {
    history.push(`/${typeIntervention === 'intervention' ? `interventions` : `swaps`}/${id}`, { ...location.state, success, adressChange })
  }, [history, location, id, typeIntervention])

  const plan = React.useCallback(({ item, slot }) => {
    api.post('/veloptim/selectSlot', {
      body: JSON.stringify({
        ...(typeIntervention === "intervention" && { interventionId: id }),
        ...(typeIntervention === "swap" && { swapId: id }),
        subsidiarySlotId: slot.subsidiarySlotId,
        day: item.day,
        userId: item.userId,
        timeBetweenEvent: item.timeBetweenEvent,
        zoninggroupId: item.zoninggroupId,
        userTypology: item.userTypology,
      })
    })
      .then(response => {
        if (response.code) {
          setPlanError(response.code)
          setPlanErrorItem(`${item.day}-${item.lastName}`)
        } else {
          goToIntervention({ success: true, adressChange: true})
        }
      })
      .catch(error => {
        setPlanError(error.code)
        setPlanErrorItem(`${item.day}-${item.lastName}`)
      })
  }, [api, id, typeIntervention, goToIntervention])

  const planTechcenter = React.useCallback(({ item, slot, forceAddressTechcenter = undefined }) => {
    if (item.distance && item.distance !== 0 && forceAddressTechcenter === undefined) {
      setTechenterScheduleModal(true)
      setTechenterScheduleData({ item, slot })
    } else {
      setPlanLoading(true)
      api.post('/veloptim/selectSlot', {
        body: JSON.stringify({
          ...(typeIntervention === "intervention" && { interventionId: id }),
          ...(typeIntervention === "swap" && { swapId: id }),
          startSlot: toSqlFormat(slot),
          day: item.day,
          userId: item.userId,
          userTypology: item.userTypology,
          ...(forceAddressTechcenter === undefined || forceAddressTechcenter === 0 ? { forceAddressTechcenter: 0 } : { forceAddressTechcenter: 1 })
        })
      })
        .then(response => {
          if (response.code) {
            setPlanError(response.code)
            setPlanErrorItem(`${item.day}-${item.lastName}`)
            setPlanLoading(false)
          } else {
            goToIntervention({ success: true, adressChange: forceAddressTechcenter === 1 ? true : false })
            setTechenterScheduleModal(false)
            setPlanLoading(false)
          }
        })
        .catch(error => {
          setPlanError(error.code)
          setPlanErrorItem(`${item.day}-${item.lastName}`)
          setPlanLoading(false)
        })
    }
  }, [api, typeIntervention, id, toSqlFormat, goToIntervention])

  const handleLoadMore = React.useCallback(e => {
    e.preventDefault()
    setP(p + 1)
  }, [p])

  const getTechcenterSlots = React.useCallback(item => {
    const startTime = subsidiary.timeStartWork * 60
    const endTime = subsidiary.timeEndWork * 60
    const allSlots = []
    const slots = []
    for (let i = 0; i < item.availabilities.length; i++) {
      const startSlotMinutes = fromSqlFormat(item.availabilities[i].startSlot)
      let firstSlot = startSlotMinutes % 30 === 0 ? startSlotMinutes : startSlotMinutes + 30 - startSlotMinutes % 30
      while (firstSlot < fromSqlFormat(item.availabilities[i].endSlot)) {
        if (item.day === formatDate(new Date())) {
          const compareSlot = fromSqlFormat(timeDateFromUtc({ time: toSqlFormat(firstSlot), date: item.day }))
          if (compareSlot > new Date().getHours() * 60 + new Date().getMinutes()) {
            slots.push(firstSlot)
          }
        } else {
          slots.push(firstSlot)
        }
        firstSlot = firstSlot + 30
      }
    }
    for (let i = startTime; i < endTime; i = i + 30) {
      if (slots.includes(i)) {
        allSlots.push({ time: i, available: true})
      } else {
        allSlots.push({ time: i, available: false})
      }
    }

    return allSlots
  }, [fromSqlFormat, timeDateFromUtc, toSqlFormat, subsidiary])

  const getMobileSlots = React.useCallback(() => {
    const allSlots = filters.subsidiarySlot && filters.subsidiarySlot.length > 0 && filters.subsidiarySlot.map(slot => ({
      ...slot,
      startSlot: slot.startSlot.split(' ')[1],
      endSlot: slot.endSlot.split(' ')[1]
    }))
    return allSlots
  }, [filters])

  const getMobileCurrentSlot = React.useCallback((item, s, slotSize) => {
    let slot
    if (item.availabilities && item.availabilities.length > 0 && item.availabilities.map(a => a.subsidiarySlotId).includes(s.subsidiarySlotId)) {
      slot = item.availabilities.find(a => a.subsidiarySlotId === s.subsidiarySlotId)
    }
    return (
      <>
        {slot ? (
          <Card className="card-button-primary-outline mobile-card" key={slot.slotId} onClick={() => plan({ item: item, slot: slot })} style={{ width: `calc(${slotSize}% - 12px)` }}>
            <CardBody className="card-body-primary flex-column justify-content-center p-1">
              <span className='d-block'><b>{formatTime(toSqlFormat(fromSqlFormat(slot.startSlot)))} - {formatTime(toSqlFormat(fromSqlFormat(slot.endSlot)))}</b></span>
              {!!calculateCloseItv && (
                <>
                  <div className='d-flex flex-row '>
                    <>
                      <div className='d-flex flex-row distance mr-2'>
                        <img src="/assets/img/icon-scheduled.svg" alt="scheduled" className="mr-1" />
                        <span><b>{slot.nbItv || 0}</b></span>
                      </div>
                      <div className='d-flex flex-row distance'>
                        <img src="/assets/img/icon-pin.svg" alt="pin" className="mr-1" />
                        <span>
                          <b>
                            {(slot.detourDist || slot.detourDist == 0) ? (
                              <>
                                {(Math.round(slot.detourDist) / 1000).toFixed(3)}
                                <T id='VeloptimPlanModal.kms' raw />
                              </>
                            ) : (
                              <T id='VeloptimPlanModal.kms0' raw />
                            )} 
                          </b>
                        </span>
                      </div>
                    </>
                  </div>
                </>
              )}
            </CardBody>
          </Card>
        ) : (
          <Card className="card-button-primary-outline mobile-card unavaible" key={s.slotId} style={{ width: `calc(${slotSize}% - 12px)` }} />
        )}
      </>
    )
  }, [calculateCloseItv, formatTime, fromSqlFormat, plan, toSqlFormat])

  const renderMobileCard = React.useCallback(item => {
    const slots = getMobileSlots()
    const slotSize = (100 / slots.length)
    return (
      <Card className='mobile-card-container no-shadow-card'>
        <CardBody className="d-flex flex-row justify-content-between">
          {slots.sort((a, b) => (a.startSlot < b.startSlot) ? -1 : (a.startSlot > b.startSlot) ? 1 : 0).map(s => 
            getMobileCurrentSlot(item, s, slotSize)
          )}
        </CardBody>
      </Card>
    )
  }, [getMobileCurrentSlot, getMobileSlots])

  const checkAvailableSlot = React.useCallback(item => {
    return getTechcenterSlots(item).filter(s => s.available).length > 0 ? true : false
  }, [getTechcenterSlots])

  const renderTechcenterCard = React.useCallback(item => {
    const slots = getTechcenterSlots(item)
    const slotSize = (100 / slots.length) * 2
    return (
      <Card className="card-primary-outline no-shadow-card techcenter-card">
        <CardBody className="card-body-primary flex-row flex-wrap justify-content-between">
          {slots && slots.map(s => (
            s.available ? (
              <Badge onClick={() => planTechcenter({ item: item, slot: s.time })} className="mr-2 mb-1 d-flex badge-slot align-items-center justify-content-center" color="outline" outline key={`${item.day}-${s.time}`} style={{ fontSize: '90%', width: `calc(${slotSize}% - 12px)` }}>
                <span className="d-block">
                  <b>
                    {timeDateFromUtc({ time: toSqlFormat(s.time), date: item.day })}
                  </b>
                </span>
              </Badge>
            ) : (
              <Badge className="mr-2 mb-1 d-flex badge-slot unavaible" color="outline" outline key={`${item.day}-${s.time}`} style={{ fontSize: '90%', width: `calc(${slotSize}% - 12px)` }} />
            )
          ))}
        </CardBody>
      </Card>
    )
  }, [getTechcenterSlots, planTechcenter, timeDateFromUtc, toSqlFormat])

  const renderResume = React.useCallback(() => {
    return (
      <Card className="p-3 resume mb-3">
        <TLabel id='VeloptimPlanModal.result' raw />
        <div className='d-flex flex-row justify-content-between filters'>
          <div className='d-flex flex-row'>
            <div className="mr-4"> 
              <span className='d-block'><TLabel id='VeloptimPlanModal.slot' raw /></span>
              <span className='d-block'>
                {subsidiarySlotId === 0 ? <T id="VeloptimPlanModal.allSlots" raw /> : filters.subsidiarySlot.map(slot => subsidiarySlotId === slot.subsidiarySlotId ? `${timeFromUTC(slot.startSlot)} - ${timeFromUTC(slot.endSlot)}` : <></>)}
              </span>
            </div>
            <div className="mr-4">
              <span className='d-block'><TLabel id='VeloptimPlanModal.userTypology' raw /></span>
              <span className='d-block'>
                {userTypology === 0 ? <T id="VeloptimPlanModal.allUsers" raw /> : filters.userTypology.map(user => userTypology === user.value ? <T id={`VeloptimPlanModal.type.${user.label}`} key={user.label} raw /> : <></>)}
              </span>
            </div>
            <div className="mr-4">
              <span className='d-block'><TLabel id='VeloptimPlanModal.vehicles' raw /></span>
              <span className='d-block'>
                {!vehicleType ? <T id="VeloptimPlanModal.allVehicles" raw /> : filters.vehicleType.map(vehicle => vehicleType === vehicle.value ? <T id={`VehiclesTypeList.${vehicle.label}`} key={vehicle.label} raw /> : <></>)}
              </span>
            </div>
            <div className="mr-4">
              <span className='d-block'><TLabel id='VeloptimPlanModal.competences' raw /></span>
              <span className='d-block competences-block'>
                {typeIntervention === 'intervention' ? (
                  <>
                    {matchTypeIntervention && matchFamily && !!matchItvcodes && <T id="VeloptimPlanModal.allCompetences" raw />}
                    {!matchTypeIntervention && !matchFamily && !matchItvcodes && <T id="VeloptimPlanModal.noCompetences" raw />}
                    {!(matchTypeIntervention && matchFamily && matchItvcodes) && !!matchTypeIntervention && <T id="VeloptimPlanModal.matchTypeIntervention" raw />}
                    {!(matchTypeIntervention && matchFamily && matchItvcodes) && !!matchFamily && <T id="VeloptimPlanModal.matchFamily" raw />}
                    {!(matchTypeIntervention && matchFamily && matchItvcodes) && !!matchItvcodes && <T id="VeloptimPlanModal.matchItvcodes" raw />}
                  </>
                ) : (
                  <>
                    {matchTypeIntervention && matchFamily && <T id="VeloptimPlanModal.allCompetences" raw />}
                    {!matchTypeIntervention && !matchFamily && <T id="VeloptimPlanModal.noCompetences" raw />}
                    {!(matchTypeIntervention && matchFamily) && matchTypeIntervention && <T id="VeloptimPlanModal.matchTypeIntervention" raw />}
                    {!(matchTypeIntervention && matchFamily) && matchFamily && <T id="VeloptimPlanModal.matchFamily" raw />}
                  </>
                )}
              </span>
            </div>
            <div className="mr-4">
              <span className='d-block'><TLabel id='VeloptimPlanModal.zonage' raw /></span>
              <span className='d-block competences-block'>
                {matchZonning && matchLimitItv && <T id="VeloptimPlanModal.allZonages" raw />}
                {!matchZonning && !matchLimitItv && <T id="VeloptimPlanModal.noZonages" raw />}
                {!(matchZonning && matchLimitItv) && matchZonning && <T id="VeloptimPlanModal.matchZonning" raw />}
                {!(matchZonning && matchLimitItv) && matchLimitItv && <T id="VeloptimPlanModal.matchLimitItv" raw />}
              </span>
            </div>
            {userTypology !== 2 && (
              <div className="mr-4">
                <span className='d-block'><TLabel id='VeloptimPlanModal.calculateCloseItvLabel' raw /></span>
                <span>
                  {calculateCloseItv ? <T id="VeloptimPlanModal.yes" raw /> : <T id="VeloptimPlanModal.no" raw />}
                </span>
              </div>
            )}
          </div>
          <TButton id="VeloptimPlanModal.newSearch" raw onClick={() => { setVeloptimModal(true)}}/>
        </div>
      </Card>
    )
  }, [subsidiarySlotId, filters, userTypology, vehicleType, matchTypeIntervention, matchFamily, matchItvcodes, matchZonning, matchLimitItv, calculateCloseItv, timeFromUTC, typeIntervention])

  const renderTechcenterModal = React.useCallback(() => {
    return (
      <Modal isOpen={Boolean(techenterScheduleModal)} toggle={() => setPlanError()}>
        <ModalBody>
          <T id={typeIntervention === 'swap' ? `VeloptimPlanModal.swap.forceTechcenterSchedule` : `VeloptimPlanModal.intervention.forceTechcenterSchedule`} raw />
        </ModalBody>
        <ModalFooter className="py-2">
          <TButton id="VeloptimPlanModal.forceTechcenterSchedule.2" raw color="danger" disabled={planLoading} onClick={() => setTechenterScheduleModal(false)} />
          <TButton id="VeloptimPlanModal.forceTechcenterSchedule.0" raw color="success" disabled={planLoading} onClick={() => planTechcenter({
            item: techenterScheduleData.item,
            slot: techenterScheduleData.slot,
            forceAddressTechcenter: 0
          })} />
          <TButton id="VeloptimPlanModal.forceTechcenterSchedule.1" raw color="warning" disabled={planLoading} onClick={() => planTechcenter({
            item: techenterScheduleData.item,
            slot: techenterScheduleData.slot,
            forceAddressTechcenter: 1
          })} />
        </ModalFooter >
      </Modal >
    )
  }, [planLoading, planTechcenter, techenterScheduleData, techenterScheduleModal, typeIntervention])

  const renderPlanModal = React.useCallback(() => {
    return (
      <VeloptimPlanModal
        typeIntervention={typeIntervention}
        id={id}
        itvcodes={itvcodes}
        familyId={familyId}
        mapId={mapId}
        durationPlanned={durationPlanned}
        onDismiss={() => { setVeloptimModal(false) }}
        onSearch={() => {setIsSearch(true)}}
        searchItems={location && location.state && location.state.props}
        isOpen={veloptimModal} />
    )
  }, [durationPlanned, familyId, id, itvcodes, mapId, typeIntervention, veloptimModal, location])

  return (
    <>
      {renderResume()}
      <>
        {!initialState && (
          <>
            {loading ? (
              <div className='h-100 d-flex justify-content-center align-items-center flex-column'>
                <div className="loader-container">
                  <div className='loader' />
                  <div className='img-container'>
                    <img src="/assets/img/placeholder-search.svg" alt="search" />
                  </div>
                </div>
                <div>
                  <span className='d-block search-text search-text-big'>
                    <b><T id='VeloptimPlanModal.searchText1' raw /></b>
                  </span>
                  <span className='d-block search-text'>
                    <T id='VeloptimPlanModal.searchText2' raw />
                  </span>
                </div>
              </div>
            ) : (
              <>
                {results && results.length > 0 && !initialState ? (
                  <>
                    {results.map(item =>
                      <>
                        {((item.userTypology === 2 && getTechcenterSlots(item) && checkAvailableSlot(item)) || item.userTypology === 1) && (
                          <Card key={`${item.day}-${item.lastName}`} className="my-1 result-card d-flex">
                            <div className="d-flex">
                              <Card className={item.userTypology === 2 ? `user-card techcenter-card no-shadow-card` : `user-card no-shadow-card`} >
                                <CardBody className="d-flex flex-row" >
                                  <div className="date">
                                    <div className="letterDay">{intl.formatDate(item.day, {  weekday: 'long' }).toUpperCase() }</div>
                                    <div className="numericDay"><b>{intl.formatDate(item.day, { day: 'numeric'})}</b></div>
                                    <div className="month">{intl.formatDate(item.day, { month: 'long' }).toUpperCase()}</div>
                                  </div>
                                  <div className="name d-flex flex-column">
                                    <div><b>{item.firstName} {item.lastName}</b></div>
                                    {item.userTypology === 2 && (
                                      <>
                                        <div>
                                          <T id="VeloptimPlanModal.techcenterDistance" raw values={{ value: (Math.round(item.distance) / 1000).toFixed(3) }} />
                                        </div>
                                        <div className='mt-2'>
                                          {item.address}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </CardBody>
                              </Card>
                              {item.userTypology === 1 && (renderMobileCard(item))}
                              {item.userTypology === 2 && (renderTechcenterCard(item))}
                            </div>
                            {planError && planErrorItem === `${item.day}-${item.lastName}` && <TAlert id={`VeloptimPlanModal.${planError}`} raw className="mb-1 mt-2" color="danger" />}
                          </Card >
                        )}
                      </>
                    )}
                    {loadingMore && (
                      <div className='h-100 d-flex justify-content-center align-items-center flex-column'>
                        <div className="loader-container">
                          <div className='loader' />
                          <div className='img-container'>
                            <img src="/assets/img/placeholder-search.svg" alt="search" />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className='d-flex mt-4 mb-4 justify-content-between'>
                      <TButton id="VeloptimPlanModal.loadMore" outline raw onClick={handleLoadMore} />
                      <TButton id="VeloptimPlanModal.newSearch" raw onClick={() => { setVeloptimModal(true)}} />
                    </div>
                  </>
                ) : (
                  <>
                    {searchError
                      ? <TAlert id={`VeloptimPlanModal.${searchError}`} raw className="mb-1 mt-2" color="danger" />
                      : 
                      <div className="noResults w-100">
                        <div className='noResults-inner d-flex flex-column align-items-center'>
                          <img src="/assets/img/placeholder-no-result.svg" alt="no-result" />
                          <div>
                            <span className='d-block mt-3'>
                              <b><T id='VeloptimPlanModal.noResultText1' raw /></b>
                            </span>
                            <span className='d-block mt-1'>
                              <T id='VeloptimPlanModal.noResultText2' raw />
                            </span>
                          </div>
                          <TButton id="VeloptimPlanModal.newSearch" className="mt-3" raw onClick={() => { setVeloptimModal(true)}} />
                        </div>
                      </div>                      
                    }
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
      {renderTechcenterModal()}
      {renderPlanModal()}
    </>
  )
}

export default VeloptimPlanComponent