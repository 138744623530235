import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  Button,
  CustomInput,
  Card, CardBody, CardHeader, CardFooter,
  Modal, ModalBody, ModalFooter,
  Table, Spinner
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'
import TH from 'components/TH'
import CustomSelect from 'components/CustomSelect'
import { useHasRights } from 'components/ProtectedComponent'
import ProtectedComponent from 'components/ProtectedComponent'
import { T, TAlert, TButton } from 'components/TComponents'


const TriggersList = () => {
  const { api, TRIGGERS_CRITICALITY } = React.useContext(AppContext)
  const canEditRight = useHasRights('user-triggers_personal_edit')

  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()

  const [modifySuccess, setModifySuccess] = React.useState()
  const [modifyError, setModifyError] = React.useState()

  const [triggerToDelete, setTriggerToDelete] = React.useState('')
  const [modalOpen, setModalOpen] = React.useState(false)

  React.useEffect(() => {
    getTriggers()
  }, [getTriggers])

  const deleteTrigger = triggerId => {
    api.del(`/triggers/details&triggerId=${triggerId}`)
      .then(() => getTriggers())
      .catch(error => setError(error))
  }

  const getTriggers = React.useCallback(() => {
    setLoading(true)
    api.get('/triggers/privateList', undefined)
      .then(response => setData(response))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api])

  const saveTrigger = React.useCallback((trigger) => {
    setModifyError(false)
    setModifySuccess()
    api.post('/triggers/privateDetails', {
      body: JSON.stringify(trigger)
    })
      .then(response => {
        setModifySuccess(true)
        setData(response)
      })
      .catch(error => setModifyError(error))
  }, [api])

  const enabledButton = React.useCallback(item => (
    <CustomInput type="switch"
      id={`enabled${item.triggerId}`}
      name={`enabled${item.triggerId}`}
      className="w-100 bg-transparent border-0"
      enabled={canEditRight}
      onChange={() => { saveTrigger({ ...item, enabled: item.enabled === 1 ? 0 : 1 }) }}
      checked={item.enabled} />
  ), [canEditRight, saveTrigger])

  const criticalitySelect = React.useCallback(item => (
    <CustomSelect
      id="criticality"
      options={TRIGGERS_CRITICALITY}
      placeholder={< T id="criticality.placeholder" />}
      getOtionValue={option => option.value}
      onChange={value => { saveTrigger({ ...item, criticality: value.value }) }}
      enabled={canEditRight}
      menuPortalTarget={document.body}
      value={TRIGGERS_CRITICALITY.find(t => t.value === item.criticality)}
      getOptionLabel={option => <T id={`Triggers.criticality.${option.key}`} raw />} />
  ), [TRIGGERS_CRITICALITY, canEditRight, saveTrigger])

  const renderId = React.useCallback(item => {
    const color = ((value) => {
      switch (value) {
      case 1: return 'success'
      case 2: return 'warning'
      case 3: return 'danger'
      default: return ''
      }
    })(item.criticality)
    return <h6 className="mb-0"><span className={`badge badge-${color}`}>{item.triggerId}</span></h6>
  }, [])

  const renderParams = React.useCallback(item =>
    item.params && Object.entries(item.params).map(p => {
      if (p[0] !== 'itvcodeId' && p[0] !== 'itvcodeIdentifier' && p[0] !== 'itvcodeLabel') {
        return <span className="d-block" key={p[0]}><T id={`Triggers.${p[0]}`} raw />{` : ${p[1]}`}</span>
      }
      if (p[0] === 'itvcodeId') {
        return <span className="d-block" key="itvcode"><T id={`Triggers.itvcode`} raw />{` : ${Object.entries(item.params).find(p => p[0] === 'itvcodeIdentifier')[1]} - ${Object.entries(item.params).find(p => p[0] === 'itvcodeLabel')[1]}`}</span>
      }
    }), [])

  return (
    <>
      <Card className="mb-2">
        <ProtectedComponent rights={['user-triggers_personal_edit']}>
          <CardHeader>
            <TButton id="createTrigger"
              tag={NavLink}
              to="./triggers/new" />
          </CardHeader>
        </ProtectedComponent>
        <CardBody>
          {error && <TAlert color="danger" id={error.code} />}
          {loading && <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />}
          {!loading &&
            <>
              {data && data.result && data.result.length > 0 ? (
                <Table responsive striped hover className="mb-3 mt-2" style={{ marginBottom: 300 }}>
                  <thead>
                    <tr>
                      <TH colName="triggerId" />
                      <TH colName="description" />
                      <TH colName="triggerIdentifier" />
                      <TH colName="params" />
                      <TH colName="enabled" />
                      <TH colName="criticality" />
                      <ProtectedComponent rights={['user-triggers_personal_edit']}>
                        <TH style={{ whiteSpace: 'nowrap', width: '1%' }} />
                      </ProtectedComponent>
                    </tr>
                  </thead>
                  <tbody>
                    {data.result.map(trigger => <tr key={trigger.triggerId || ''}>
                      <td className="align-middle">{renderId(trigger)}</td>
                      <td className="align-middle">{trigger.description}</td>
                      <td className="align-middle"><T id={`Triggers.${trigger.triggerIdentifier}`} raw /></td>
                      <td className="align-middle">{renderParams(trigger)}</td>
                      <td className="align-middle">{enabledButton(trigger)}</td>
                      <td className="align-middle">{criticalitySelect(trigger)}</td>
                      <ProtectedComponent rights={['user-triggers_personal_edit']}>
                        <td className="align-middle">
                          <Button id="delete" color="danger"
                            onClick={() => {
                              setModalOpen(true)
                              setTriggerToDelete(trigger.triggerId)
                            }}>
                            <i className="simple-icon-trash mr-1" />
                          </Button>
                        </td>
                      </ProtectedComponent>
                    </tr>)}
                  </tbody>
                </Table>
              ) : (
                <T id="noData" />
              )}
            </>
          }
        </CardBody>
        <CardFooter>
          {modifyError && <TAlert color="danger" id={modifyError.code} />}
          {modifySuccess && <TAlert color="success" id="modifySuccess" />}
        </CardFooter>
      </Card>
      <Modal isOpen={modalOpen} fade={false} toggle={() => { setTriggerToDelete(); setModalOpen(false) }}>
        <ModalBody>
          <T id="deleteWarning" />
        </ModalBody>
        <ModalFooter>
          <TButton id="cancel" onClick={() => { setTriggerToDelete(); setModalOpen(false) }} />
          <TButton
            id="confirm"
            color="danger"
            onClick={() => {
              deleteTrigger(triggerToDelete)
              setModalOpen(false)
            }}
            className="ml-2 d-flex align-items-center" />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default TriggersList
