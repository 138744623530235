import React from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'

import {
  Modal, ModalBody, ModalFooter,
  Card, CardHeader, CardBody,
  CardFooter
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import { T, TAlert, ErrAlert, TButton } from 'components/TComponents'
import { Table } from 'components/Table'

const Docaposte = () => {
  const { api } = React.useContext(AppContext)

  const [list, setList] = React.useState()
  const [listLoad, setListLoad] = React.useState(true)
  const [listError, setListError] = React.useState()

  const [deleteSuccess, setDeleteSuccess] = React.useState()
  const [deleteLoad, setDeleteLoad] = React.useState(false)
  const [deleteError, setDeleteError] = React.useState()

  const [currentItem, setCurrentItem] = React.useState()
  const [modalOpen, setModalOpen] = React.useState(false)

  // unqueue(item.messageId

  React.useEffect(() => {
    api.get('/fluow/queueList')
      .then(response => setList({ result: response.queues }))
      .catch(response => setListError(response))
      .then(() => setListLoad(false))
  }, [api])

  const listRefresh = React.useCallback(() => {
    setListLoad(true)
    api.get('/fluow/queueList')
      .then(response => setList({ result: response.queues }))
      .catch(response => setListError(response))
      .then(() => setListLoad(false))
  }, [api])

  const unqueue = React.useCallback((messageId) => {
    setDeleteError()
    setDeleteLoad(true)
    api.del('/fluow/queue', undefined, { messageId })
      .then(() => setDeleteSuccess(true))
      .catch(response => setDeleteError(response))
      .then(() => {
        listRefresh()
        setDeleteLoad(false)
        setCurrentItem()
      })
  }, [api, listRefresh])

  const DateQueued = React.useCallback(item =>
    <span><FormattedDate value={new Date(item.dateQueued)} />{' '}<FormattedTime value={new Date(item.dateQueued)} /></span>
  , [])

  const DateLastTry = React.useCallback(item =>
    item.dateLastTry ? <span><FormattedDate value={new Date(item.dateLastTry)} />{' '}<FormattedTime value={new Date(item.dateLastTry)} /></span> : <></>
  , [])

  const UnQueueButton = React.useCallback(item => (
    <TButton className="ml-auto"
      id="unqueue"
      disabled={deleteLoad || !item.can_delete}
      onClick={() => {
        setModalOpen(true)
        setCurrentItem(item)
      }}
    />
  ), [deleteLoad])

  if (listError) {
    return <ErrAlert error={listError} />
  }

  return (<>
    <Card className="mb-5">
      <CardHeader><T className="h5" id="title" /></CardHeader>
      <CardBody>
        <Table
          isLoading={listLoad}
          disabled={listLoad}
          data={list}
          refresh={listRefresh}
          pagination={false}
          count={false}
          columns={[
            {
              title: 'dateQueued',
              render: DateQueued,
              hasOrder: true,
            },
            {
              title: 'httpMethod',
              hasOrder: true,
              render: item => item.httpMethod
            },
            {
              title: 'uri',
              render: item => item.uri
            },
            {
              title: 'bodyData',
              render: item => item.bodyData
            },
            {
              title: 'dateLastTry',
              render: DateLastTry,
              hasOrder: true,
            },
            {
              className: () => 'align-middle',
              render: UnQueueButton
            },
          ]}
        />
      </CardBody>
      <CardFooter>
        {deleteSuccess && <TAlert id="success"></TAlert>}
        {deleteError && <ErrAlert error={deleteError}></ErrAlert>}
      </CardFooter>
    </Card>

    <Modal isOpen={modalOpen && currentItem} fade={false} toggle={() => setModalOpen(false)}>
      <ModalBody>
        <T id="description" />
      </ModalBody>
      <ModalFooter className="py-3">
        <TButton
          className="ml-2" color="warning"
          onClick={() => {
            setModalOpen(false)
            setCurrentItem()
          }}
          id="cancel" />
        <TButton
          color="success"
          onClick={() => unqueue(currentItem.messageId)}
          id="confirm" />
      </ModalFooter>
    </Modal>
  </>)
}

export default Docaposte
