import React from 'react'
import { Switch } from 'react-router-dom'

import Route from 'components/Route'

import List from 'routes/Admin/Itvcodes/ItvcodeList'
import New from 'routes/Admin/Itvcodes/ItvcodeNew'
import Edit from 'routes/Admin/Itvcodes/ItvcodeEdit'

export default function Users({ match }) {
  return <Switch>
    <Route exact path={`${match.path}`} component={List} rights={['admin-itvcodes_edit', 'admin-itvcodes_view',]} />
    <Route path={`${match.path}/new`} component={New} rights={['admin-itvcodes_edit',]} />
    <Route path={`${match.path}/:id`} component={Edit} rights={['admin-itvcodes_edit',]} />
  </Switch>
}
