import React from 'react'
import { Switch } from 'react-router-dom'

import Route from 'components/Route'

import List from './SubcontractorList'
import Details from './SubcontractorDetails'

export default function Subcontractors({ match }) {
  return <Switch>
    <Route exact path={`${match.path}`} component={List} rights={['admin-subcontractors_edit', 'admin-subcontractors_view',]} />
    <Route path={`${match.path}/new`} component={Details} rights={['admin-subcontractors_edit',]} />
    <Route path={`${match.path}/:id`} component={Details} rights={['admin-subcontractors_edit', 'admin-subcontractors_view',]} />
  </Switch>
}
