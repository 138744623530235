import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import classnames from 'classnames'
import Notification from 'components/ReactNotifications/Notification'

class Notifications extends React.Component {
  static defaultProps = {
    notifications: [],
    onRequestHide: () => {
    },
    timeout: 400
  }

  handleRequestHide = notification => () => {
    const { onRequestHide } = this.props
    if (onRequestHide) {
      onRequestHide(notification)
    }
  }

  render() {
    const { notifications, timeout } = this.props
    const className = classnames('notification-container', {
      'notification-container-empty': notifications.length === 0
    })
    return <div className={className}>
      <TransitionGroup>
        {notifications.map((notification) => {
          const key = notification.id || new Date().getTime()
          return <CSSTransition key={key}
            className="notification"
            timeout={timeout}>
            <Notification
              type={notification.type}
              title={notification.title}
              message={notification.message}
              timeOut={notification.timeOut}
              onClick={notification.onClick}
              onRequestHide={this.handleRequestHide(notification)}
              customClassName={notification.customClassName}
            />
          </CSSTransition>
        })}
      </TransitionGroup>
    </div>
  }
}

export default Notifications
