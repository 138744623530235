import React from 'react'
import {
  Badge, Card, CardHeader, CardFooter,
  FormGroup, Spinner, Input,
  Row, Col,
  Modal, ModalBody, ModalFooter, CardBody
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import VehicleIcon from 'components/VehicleIcon'
import { useHasRights } from 'components/ProtectedComponent'
import CustomSelect from 'components/CustomSelect'
import Pagination from 'components/Pagination'
import { T, TAutocomplete, TInput, TLabel, TAlert, TButton } from 'components/TComponents'

import { debounce } from 'util/Utils'

const VeloptimZoningGroups = () => {
  const { api, constants } = React.useContext(AppContext)

  const [autocomplete, setAutocomplete] = React.useState()
  const [zone, setZone] = React.useState()
  const [zones, setZones] = React.useState([])
  const [loading, setLoading] = React.useState()

  const [zoningGroup, setZoningGroup] = React.useState()
  const [zoningGroups, setZoningGroups] = React.useState()
  const [zoningGroupsError, setZoningGroupsError] = React.useState()
  const [createState, setCreateState] = React.useState(false)
  const [createLoading, setCreateLoading] = React.useState()
  const [createStateError, setCreateStateError] = React.useState()
  const [zoninggroupLabel, setZoninggroupLabel] = React.useState('')
  const [searchInput, setSearchInput] = React.useState('')

  const [editState, setEditState] = React.useState(false)
  const [editStateError, setEditStateError] = React.useState(false)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [groupToDelete, setGroupToDelete] = React.useState()

  const zoningAdminRight = useHasRights('admin-zoninggroup')

  const [hoursBetweenEvent, setHoursBetweenEvent] = React.useState(0)
  const [minutesBetweenEvent, setMinutesBetweenEvent] = React.useState(0)
  const [timeBetweenEvent, setTimeBetweenEvent] = React.useState('')

  const [hoursBetweenEventVehicle, setHoursBetweenEventVehicle] = React.useState([])
  const [minutesBetweenEventVehicle, setMinutesBetweenEventVehicle] = React.useState([])
  const [timeByKmByVehicle, setTimeByKmByVehicle] = React.useState([])

  const hours = Array(9).fill().map((_, i) => i)
  const minutes = Array(12).fill().map((_, i) => (i * 5))
  const allMinutes = Array(60).fill().map((_, i) => i)

  const initalTimeByKmByVehicle =
        [{ value: null, key: 'all' }].concat(constants.vehicleType).map(v => ({
          vehicleTypeId: v.value,
          timeByKm: 0
        }))

  React.useEffect(() => {
    if (zoningAdminRight) {
      getZoningGroups()
    }
  }, [getZoningGroups, zoningAdminRight])

  React.useEffect(() => {
    setHoursBetweenEvent(Math.trunc(timeBetweenEvent / 60))
    setMinutesBetweenEvent(timeBetweenEvent % 60)
  }, [timeBetweenEvent])

  React.useEffect(() => {
    if (timeByKmByVehicle && timeByKmByVehicle.length > 0) {
      setHoursBetweenEventVehicle(timeByKmByVehicle.map(t => ({
        vehicleTypeId: t.vehicleTypeId,
        timeByKm: Math.trunc(t.timeByKm / 60)
      })))
      setMinutesBetweenEventVehicle(timeByKmByVehicle.map(t => ({
        vehicleTypeId: t.vehicleTypeId,
        timeByKm: t.timeByKm % 60
      })))
    }
  }, [timeByKmByVehicle])

  const refresh = React.useCallback(params => {
    setLoading(true)
    setZoningGroupsError()
    Promise.all([
      api.get('/zoninggroups/list', undefined, {
        ...zoningGroups.filters,
        p: zoningGroups.currentPage,
        ...params
      }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => setZoningGroups(data))
      .catch(error => setZoningGroupsError(error))
      .then(() => setLoading(false))
  }, [api, zoningGroups])

  const refreshDebounced = React.useMemo(() => debounce((params) => refresh(params), 250), [refresh])

  const handleSearchInput = React.useCallback(({ target: { value } }) => {
    setSearchInput(value)
    refreshDebounced({ filter: value, p: 1 })
  }, [refreshDebounced])

  const handleHoursBetweenEvent = React.useCallback(({ target: { value } }) => {
    setTimeBetweenEvent(minutesBetweenEvent + Number(value) * 60)
  }, [minutesBetweenEvent])

  const handleMinutesBetweenEvent = React.useCallback(({ target: { value } }) => {
    setTimeBetweenEvent(Number(value) + hoursBetweenEvent * 60)
  }, [hoursBetweenEvent])

  const handleHoursBetweenEventVehicle = React.useCallback(({ target: { value }, vehicleTypeId }) => {
    setTimeByKmByVehicle(timeByKmByVehicle => timeByKmByVehicle.map(t => ({
      vehicleTypeId: t.vehicleTypeId,
      timeByKm: t.vehicleTypeId === vehicleTypeId
        ? minutesBetweenEventVehicle.find(m => m.vehicleTypeId === vehicleTypeId).timeByKm + Number(value) * 60
        : t.timeByKm
    })))
  }, [minutesBetweenEventVehicle])

  const handleMinutesBetweenEventVehicle = React.useCallback(({ target: { value }, vehicleTypeId }) => {
    setTimeByKmByVehicle(timeByKmByVehicle => timeByKmByVehicle.map(t => ({
      vehicleTypeId: t.vehicleTypeId,
      timeByKm: t.vehicleTypeId === vehicleTypeId
        ? Number(value) + hoursBetweenEventVehicle.find(m => m.vehicleTypeId === vehicleTypeId).timeByKm * 60
        : t.timeByKm
    })))
  }, [hoursBetweenEventVehicle])

  const getZoningGroups = React.useCallback(() => {
    setLoading(true)
    api.get('/zoninggroups/list', undefined)
      .then(response => setZoningGroups(response))
      .catch(error => setZoningGroupsError(error))
      .then(() => setLoading(false))
  }, [api])

  const toSqlFormat = React.useCallback((time) => {
    const timeToGet = time || 0
    return `${String(Math.trunc(timeToGet / 60)).padStart(2, '0')}:${String(timeToGet % 60).padStart(2, '0')}:00`
  }, [])

  const fromSqlFormat = React.useCallback((time) => {
    const timeArray = time ? time.split(':') : [0, 0]
    return Number(timeArray[0]) * 60 + Number(timeArray[1])
  }, [])

  const saveZoningGroup = React.useCallback(id => {
    !isNaN(id) ? setCreateLoading(id) : setCreateLoading(true)
    const body = {
      zoninggroupLabel: zoninggroupLabel,
      maps: zones.map(z => z.mapId),
      timeBetweenEvent: toSqlFormat(timeBetweenEvent),
      timeByKmByVehicle: timeByKmByVehicle.map(t => ({ timeByKm: toSqlFormat(t.timeByKm), vehicleTypeId: t.vehicleTypeId })),
      ...(editState && id && { zoninggroupId: id })
    }
    api.post('/zoninggroups/details', {
      _stringifyBody: true,
      body
    })
      .then(() => {
        setEditState(false)
        setCreateState(false)
        setZones([])
        setZoninggroupLabel()
        getZoningGroups()
        setCreateLoading()
      })
      .catch(error => {
        if (isNaN(id)) {
          setCreateStateError(error)
          setCreateLoading()
        } else {
          setEditStateError(error)
          setCreateLoading()
        }
      })
  }, [api, zones, zoninggroupLabel, editState, getZoningGroups, timeBetweenEvent, toSqlFormat, timeByKmByVehicle])

  const editZoningGroup = React.useCallback(id => {
    api.get('/zoninggroups/details', undefined, { zoninggroupId: id })
      .then(response => {
        setZoningGroup(response)
        setZoninggroupLabel(response.zoninggroupLabel)
        setTimeBetweenEvent(fromSqlFormat(response.timeBetweenEvent))
        setTimeByKmByVehicle(response.timeByKm.map(t => ({ timeByKm: fromSqlFormat(t.timeByKm), vehicleTypeId: t.vehicleTypeId })))
        setZones(response.maps)
        setEditState(true)
        setZoningGroupsError()
      })
      .catch(error => setZoningGroupsError(error))
  }, [api, fromSqlFormat])

  const handleCancelEdit = React.useCallback(() => {
    setZoningGroup()
    setZoninggroupLabel()
    setZones()
    setEditState(false)
    setCreateState(false)
    setEditStateError()
    setZoningGroupsError()
    setCreateStateError()
  }, [])

  const deleteZoningGroup = React.useCallback(id => {
    api.del('/zoninggroups/details', undefined, { zoninggroupId: id })
      .then(() => { getZoningGroups() })
      .catch(error => { setZoningGroupsError(error) })
  }, [getZoningGroups, setZoningGroupsError, api])

  const removeAdress = React.useCallback(id => {
    setZones(zones.filter(z => z.mapId !== id))
  }, [setZones, zones])

  const handleChangeZone = React.useCallback(e => {
    e.persist()
    setZone(e.target.value)
    api.get('/maps/autocomplete', undefined, {
      input: e.target.value,
      ...(autocomplete && autocomplete.session_token && { session_token: autocomplete.session_token })
    })
      .then(response => {
        setAutocomplete(response)
      })
      .catch(error => console.log(error))
  }, [api, autocomplete])

  const handleAutocomplete = React.useCallback(e => {
    setZone('')
    setAutocomplete()
    api.get('/maps/placedetails', undefined, {
      place_id: autocomplete.results.find(r => r.addressFull === e).place_id,
      session_token: autocomplete.session_token,
      addressFull: e
    })
      .then(response => {
        let duplicate = false
        zones.map(z => {
          if (z.mapId === response.mapId) {
            duplicate = true
          }
        })
        if (!duplicate) {
          setZones([
            ...zones,
            {
              formatted_address: e,
              mapId: response.mapId
            }
          ])
        }
      })
      .catch(error => console.log(error))
  }, [api, autocomplete, zones])

  return (
    <>
      <>
        <TLabel id="zoning" />
        <Card className="mb-2 mt-3">
          <CardHeader className="pb-0">
            <FormGroup className="flex-grow-1 pl-0">
              <TLabel id="filterTitle" check className="sr-only" for="businessFilter" />
              <TInput
                name="businessFilter"
                type="text"
                placeholder="filterPlaceholder"
                value={searchInput}
                onChange={handleSearchInput} />
            </FormGroup>
          </CardHeader>
        </Card>
        {loading && <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />}
        {!loading && zoningGroups && (
          <>
            {zoningGroups && zoningGroups.result.map(group => (
              <Card className="mb-3" key={group.zoninggroupId}>
                {(editState && zoningGroup && zoningGroup.zoninggroupId === group.zoninggroupId) ? (
                  <>
                    <CardHeader className="d-flex align-items-center">
                      <div className="d-flex flex-column w-100">
                        <>
                          <Input
                            className="mr-3 mb-2"
                            id="zoninggroupLabel"
                            name="zoninggroupLabel"
                            onChange={e => { setZoninggroupLabel(e.target.value) }}
                            placeholder="zoninggroupLabel"
                            style={{ flex: 1 }}
                            type="text"
                            value={zoninggroupLabel}
                          />
                        </>
                        <TLabel id="timeBetweenEvent" className="col my-2 pl-0" />
                        <Row>
                          <Col>
                            <FormGroup tag="fieldset">
                              <TLabel for="selectHour" id="selectHour" />
                              <CustomSelect
                                inputId="selectHour"
                                name="selectHour"
                                options={hours}
                                placeholder={"0"}
                                onChange={e => handleHoursBetweenEvent({ target: { value: e } })}
                                value={hours.filter(h => h == hoursBetweenEvent)}
                                getOptionValue={option => option}
                                getOptionLabel={option => option} />
                            </FormGroup>
                          </Col>

                          <Col>
                            <FormGroup tag="fieldset">
                              <TLabel for="selectMinute" id="selectMinute" />
                              <CustomSelect
                                inputId="selectMinute"
                                name="selectMinute"
                                options={minutes}
                                onChange={e => handleMinutesBetweenEvent({ target: { value: e } })}
                                placeholder={"0"}
                                value={minutes.filter(h => h == minutesBetweenEvent)}
                                getOptionValue={option => option}
                                getOptionLabel={option => option} />
                            </FormGroup>
                          </Col>
                        </Row>

                        <TLabel id="timeBetweenEventVehicle" className="col my-2 pl-0" />
                        <Row className="mb-3">
                          <Col sm="3" md="3" lg="3" xl="3" >
                            <TLabel id="vehicle" />
                          </Col>
                          <Col>
                            <TLabel id="selectHour" />
                          </Col>
                          <Col>
                            <TLabel id="selectMinute" />
                          </Col>
                        </Row>
                        {constants.vehicleType && constants.vehicleType.length > 0 && [{ value: null, key: 'all' }].concat(constants.vehicleType).map(v => (
                          <Row key={v.Value}>
                            <Col sm="3" md="3" lg="3" xl="3" className="align-self-center">
                              <div className="mb-2">
                                <VehicleIcon type={v.value} />
                                {v.value === null ? <T className="ml-2" raw id={`Veloptim.noVehicle`} /> : <T id={`VehiclesTypeList.${v.key}`} raw />}
                              </div>
                            </Col>
                            <Col>
                              <FormGroup tag="fieldset">
                                <CustomSelect
                                  inputId="selectHour"
                                  name="selectHour"
                                  options={hours}
                                  placeholder={"0"}
                                  onChange={e => handleHoursBetweenEventVehicle({ target: { value: e }, vehicleTypeId: v.value })}
                                  value={hoursBetweenEventVehicle && hoursBetweenEventVehicle.length > 0 && hours.filter(h => h == hoursBetweenEventVehicle.find(hB => hB.vehicleTypeId === v.value).timeByKm)}
                                  getOptionValue={option => option}
                                  getOptionLabel={option => option} />
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup tag="fieldset">
                                <CustomSelect
                                  inputId="selectMinute"
                                  name="selectMinute"
                                  options={allMinutes}
                                  onChange={e => handleMinutesBetweenEventVehicle({ target: { value: e }, vehicleTypeId: v.value })}
                                  placeholder={"0"}
                                  value={minutesBetweenEventVehicle && minutesBetweenEventVehicle.length > 0 && allMinutes.filter(m => m == minutesBetweenEventVehicle.find(m => m.vehicleTypeId === v.value).timeByKm)}
                                  getOptionValue={option => option}
                                  getOptionLabel={option => option} />
                              </FormGroup>
                            </Col>
                          </Row>
                        ))}
                        <TAutocomplete id="zone"
                          className="w-100 mb-2"
                          type="text"
                          name="zone"
                          value={zone}
                          placeholder="addressFullPlaceholder"
                          searchOnFocus={e => handleChangeZone(e)}
                          onChange={e => handleChangeZone(e)}
                          onAutocomplete={e => handleAutocomplete(e)}
                          options={
                            autocomplete && autocomplete.results.length > 0 && autocomplete.results.map(r => {
                              return (r.addressFull)
                            })} />
                        <div className="d-flex flex-row align-items-center" style={{ flexWrap: "wrap" }}>
                          {zones && zones.length > 0 && zones.map(z => (
                            <Badge className="mr-2 mb-1 d-flex" key={z.mapId} style={{ fontSize: '90%', width: 'min-content' }}>
                              <span className="d-block">{z.formatted_address}</span>
                              <i onClick={() => { removeAdress(z.mapId) }} style={{ fontSize: '.9rem', cursor: 'pointer' }} className="simple-icon-close ml-2 font-weight-bold d-block" />
                            </Badge>
                          ))}
                        </div>
                        {editStateError && <TAlert color="danger" id={editStateError.code} />}
                      </div>
                    </CardHeader>
                    <CardFooter className="d-flex justify-content-end">
                      <div>
                        <TButton className="ml-auto mr-2" id="save" onClick={() => { saveZoningGroup(group.zoninggroupId) }} disabled={createLoading === group.zoninggroupId} loading={createLoading === group.zoninggroupId} />
                        <TButton className="ml-auto" color="danger" outline id="cancel" onClick={handleCancelEdit} disabled={createLoading === group.zoninggroupId} />
                      </div>
                    </CardFooter>
                  </>
                ) : (
                  <CardHeader className="d-flex align-items-center">
                    <span className="h5 mb-0" style={{ flex: 1 }}>{group.zoninggroupLabel}</span>
                    <div>
                      <TButton className="ml-auto mr-4" outline id="edit" disabled={editState || createState} onClick={() => { editZoningGroup(group.zoninggroupId) }} />
                      <TButton className="ml-auto" color="danger" id="delete" onClick={() => {
                        setModalOpen(true)
                        setGroupToDelete(group.zoninggroupId)
                      }} />
                    </div>
                  </CardHeader>
                )}
                {groupToDelete && group.zoninggroupId === groupToDelete && zoningGroupsError && (
                  <CardBody className="p-2">
                    <TAlert id={zoningGroupsError && zoningGroupsError.code} color="danger" className="mb-0" />
                  </CardBody>
                )}
              </Card>
            ))}
          </>
        )}

      </>
      {!createState && <TButton disabled={editState} id="addZoningGroup" onClick={() => {
        setZones([])
        setCreateState(true)
        setTimeBetweenEvent('')
        setTimeByKmByVehicle(initalTimeByKmByVehicle)
        setZoningGroupsError()
      }
      } />}
      {createState && !editState && (
        <Card>
          <CardHeader>
            <FormGroup tag="fieldset">
              <TInput
                className="mb-2"
                id="zoninggroupLabel"
                name="zoninggroupLabel"
                onChange={e => setZoninggroupLabel(e.target.value)}
                placeholder="zoninggroupLabel"
                type="text"
                value={zoninggroupLabel}
              />
              <TAutocomplete id="zone"
                className="w-100 mb-2"
                type="text"
                name="zone"
                value={zone}
                placeholder="addressFullPlaceholder"
                searchOnFocus={e => handleChangeZone(e)}
                onChange={e => handleChangeZone(e)}
                onAutocomplete={e => handleAutocomplete(e)}
                options={
                  autocomplete && autocomplete.results.length > 0 && autocomplete.results.map(r => {
                    return (r.addressFull)
                  })} />
              <TLabel id="timeBetweenEvent" className="col mb-2 pl-0" />
              <Row>
                <Col>
                  <FormGroup tag="fieldset">
                    <TLabel for="selectHour" id="selectHour" />
                    <CustomSelect
                      inputId="selectHour"
                      name="selectHour"
                      options={hours}
                      placeholder={"0"}
                      onChange={e => handleHoursBetweenEvent({ target: { value: e } })}
                      value={hours.filter(h => h == hoursBetweenEvent)}
                      getOptionValue={option => option}
                      getOptionLabel={option => option} />
                  </FormGroup>
                </Col>

                <Col>
                  <FormGroup tag="fieldset">
                    <TLabel for="selectMinute" id="selectMinute" />
                    <CustomSelect
                      inputId="selectMinute"
                      name="selectMinute"
                      options={minutes}
                      onChange={e => handleMinutesBetweenEvent({ target: { value: e } })}
                      placeholder={"0"}
                      value={minutes.filter(h => h == minutesBetweenEvent)}
                      getOptionValue={option => option}
                      getOptionLabel={option => option} />
                  </FormGroup>
                </Col>
              </Row>
              <TLabel id="timeBetweenEventVehicle" className="col my-2 pl-0" />
              <Row className="mb-3">
                <Col sm="3" md="3" lg="3" xl="3" >
                  <TLabel id="vehicle" />
                </Col>
                <Col>
                  <TLabel id="selectHour" />
                </Col>
                <Col>
                  <TLabel id="selectMinute" />
                </Col>
              </Row>
              {constants.vehicleType && constants.vehicleType.length > 0 && [{ value: null, key: 'all' }].concat(constants.vehicleType).map(v => (
                <Row key={v.value}>
                  <Col sm="3" md="3" lg="3" xl="3" className="align-self-center">
                    <div className="mb-2">
                      <VehicleIcon type={v.value} />
                      {v.value === null ? <T className="ml-2" raw id={`Veloptim.noVehicle`} /> : <T id={`VehiclesTypeList.${v.key}`} raw />}
                    </div>
                  </Col>
                  <Col>
                    <FormGroup tag="fieldset">
                      <CustomSelect
                        inputId="selectHour"
                        name="selectHour"
                        options={hours}
                        placeholder={"0"}
                        onChange={e => handleHoursBetweenEventVehicle({ target: { value: e }, vehicleTypeId: v.value })}
                        value={hoursBetweenEventVehicle && hoursBetweenEventVehicle.length > 0 && hours.filter(h => h == hoursBetweenEventVehicle.find(hB => hB.vehicleTypeId === v.value).timeByKm)}
                        getOptionValue={option => option}
                        getOptionLabel={option => option} />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup tag="fieldset">
                      <CustomSelect
                        inputId="selectMinute"
                        name="selectMinute"
                        options={allMinutes}
                        onChange={e => handleMinutesBetweenEventVehicle({ target: { value: e }, vehicleTypeId: v.value })}
                        placeholder={"0"}
                        value={minutesBetweenEventVehicle && minutesBetweenEventVehicle.length > 0 && allMinutes.filter(m => m == minutesBetweenEventVehicle.find(m => m.vehicleTypeId === v.value).timeByKm)}
                        getOptionValue={option => option}
                        getOptionLabel={option => option} />
                    </FormGroup>
                  </Col>
                </Row>
              ))}
              {zones && zones.length > 0 && zones.map(z => (
                <Badge className="mr-2 mb-1 d-flex" key={z.mapId} style={{ fontSize: '90%', width: 'min-content' }}>
                  <span className="d-block">{z.formatted_address}</span>
                  <i onClick={() => { removeAdress(z.mapId) }} style={{ fontSize: '.9rem', cursor: 'pointer' }} className="simple-icon-close ml-2 font-weight-bold d-block" />
                </Badge>
              ))}
              {createStateError && <TAlert color="danger" id={createStateError.code} />}
            </FormGroup>
          </CardHeader>
          <CardFooter className="d-flex justify-content-end">
            <TButton id="save" className="mr-2" onClick={saveZoningGroup} disabled={createLoading} loading={createLoading} />
            <TButton color="danger" outline id="cancel" disabled={createLoading} onClick={handleCancelEdit} />
          </CardFooter>

        </Card>
      )}
      {zoningGroups && (
        <Pagination totalPage={zoningGroups.totalPage}
          currentPage={zoningGroups.currentPage}
          onPageClick={p => refresh({ p })} />
      )}
      <Modal isOpen={modalOpen} fade={false} toggle={() => { setGroupToDelete(); setModalOpen(false) }}>
        <ModalBody>
          <T id="deleteWarning" />
        </ModalBody>
        <ModalFooter>
          <TButton id="cancel" onClick={() => { setGroupToDelete(); setModalOpen(false) }} />
          <TButton
            id="confirm"
            color="danger"
            onClick={() => {
              deleteZoningGroup(groupToDelete)
              setModalOpen(false)
            }}
            className="ml-2 d-flex align-items-center" />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default VeloptimZoningGroups
