import React from 'react'
import Item from './Item'

const Items = ({ dimensionItems, items, canMove, ...props }) => {

  const sortedDimensionItems = React.useMemo(() => dimensionItems.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}), [dimensionItems])

  return (
    <div className="rct-items">
      {items
        .filter(item => sortedDimensionItems[item.id])
        .map(item => <Item
          key={item.id}
          item={item}
          dimensions={ sortedDimensionItems[item.id].dimensions }
          canMove={
            item['canMove'] !== undefined
              ? item['canMove']
              : canMove
          }
          {...props}
        />)}
    </div>
  )
}

export default Items
