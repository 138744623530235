import React from 'react'
import { FormattedDate } from 'react-intl'

import { T, TLabel, TButton, TAlert } from 'components/TComponents'
import { Sheet } from 'components/Sheet'

/* HELPERS FUNCTIONS */
const STATUS = Object.freeze({
  IDLE: 'idle',
  INITIALIZING: 'initializing',
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
})

const join = (...classes) => { return classes.filter(_ => _).join(' ') || undefined }

const parseNumber = (number, digits = 2) => {
  return Number(number).toLocaleString(undefined, { minimumIntegerDigits: digits })
}

const formatMinutes = minutes => {
  return `${parseNumber(Math.floor(minutes / 60))}:${parseNumber(Math.floor(minutes % 60))}`
}

const UserDisponibilities = ({ calendarRules, fillWeek, copyWeek, error, success, successVeloptim, loading, areAllSlotsActivated, toggleDay, toggleSlot }) => {
  return (
    <div className="w-100 sectionContent">
      {[STATUS.LOADED, STATUS.LOADING].includes(calendarRules.status) && (
        <>
          <div className="calendar">
            <TLabel id="calendar.disponibilities.label" raw className="label" />
            <Sheet className="sheet w-100 mt-3 border"
              columns={calendarRules.payload.days}
              columnExtractKey={column => `head-${column.day}`}
              columnHeaderRender={column => (
                <div className="columnHeader">
                  <FormattedDate value={new Date(column.day)} month="long" day="2-digit" weekday="short" />
                </div>
              )}
              rows={calendarRules.payload.rows}
              rowExtractKey={(_, i) => `line-${i}`}
              dataRender={(row, column) => (
                <div className={join("data", column.slots.includes(row.time) && "selected")}>
                  <span className="d-block">
                    {formatMinutes(row.time)}
                  </span>
                </div>
              )}
              headHeaderRender={column => (
                <div className="columnHeader">
                  <input type="checkbox" checked={areAllSlotsActivated(column)} onChange={() => toggleDay(column)} />
                </div>
              )}
              onDataClick={(e, row, column) => toggleSlot(column, row.time)}
              footer={() => (
                <div className="legend">
                  <div className="item selected"><T id='calendar.legend.selected' raw /></div>
                  <div className="item free"><T id='calendar.legend.free' raw /></div>
                </div>
              )} />
          </div>
          <div className="d-flex flex-column">
            <div>
              {success && <TAlert color="success" className="w-100 mt-2" id={`VeloptimUser.copyweekDisponibilitiesSuccess`} raw />}
              {successVeloptim && <TAlert color="success" className="w-100 mt-2" id={`VeloptimUser.copyweekDisponibilitiesSuccessVeloptim`} raw />}
              {error && <TAlert color="danger" className="w-100 mt-2" id={`VeloptimUser.${error.code}`} raw />}
            </div>
            <div className="d-flex justify-content-end">
              <TButton className="mr-2" id="calendar.copyWeek" raw onClick={() => copyWeek()} spin={loading} disabled={[STATUS.LOADING, STATUS.ERROR].includes(calendarRules.status) || loading} />
              <TButton id="calendar.fillWeek" raw onClick={() => fillWeek()} outline disabled={[STATUS.LOADING, STATUS.ERROR].includes(calendarRules.status)} />
            </div>
          </div>
        </>
      )}
    </div>
  )
}


export default UserDisponibilities
