import React from 'react'

import { NavLink, } from 'reactstrap'

import { T, } from './TComponents'

const TableInfos = ({ data, onChange, steps = [10, 50, 100,], pagination = true, count = true }) => <div className="d-flex">
  {count && <T id="count" values={{ total: data.total }} className="mr-3" />}

  {pagination && (
    <>
      <T id="ipp" className="ml-auto" />

      {steps.map(step => <NavLink key={step}
        className={`d-inline p-0 ml-2 text-${data.itemPerPage === step ? 'muted' : 'info'}`}
        tag="a"
        href="#"
        disabled={data.itemPerPage === step}
        onClick={() => onChange(step)}>
        {step}
      </NavLink>)}
    </>
  )}
</div>

export default TableInfos
