import React from 'react'
import Markdown from 'markdown-to-jsx'

import {
  Card, CardHeader, CardBody,
  Spinner,
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import { T, } from 'components/TComponents'

export default function Changelog() {
  const [isLoading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()
  const [changelog, setChangelog] = React.useState()

  const { api } = React.useContext(AppContext)

  React.useEffect(() => {
    api.get('/changelog/details')
      .then(response => setChangelog(response))
      .catch(response => setError(response))
      .then(() => setLoading(false))
  }, [api])

  if (isLoading) {
    return (
      <Card>
        <CardBody className="d-flex justify-content-center align-items-center p-5"><Spinner color="primary" /></CardBody>
      </Card>
    )
  }

  if (error) {
    return (
      <Card>
        <CardHeader><T id="error.header" /></CardHeader>
        <CardBody>
          <pre className="mb-0">{JSON.stringify(error, null, 2)}</pre>
        </CardBody>
      </Card>
    )
  }

  if (!changelog || !changelog.pageContent) {
    return <Card>
      <CardHeader><T id="noContent.header" /></CardHeader>
      <CardBody><T id="noContent.body" /></CardBody>
    </Card>
  }

  return changelog ? changelog.pageContent.map(md =>
    <Card key={md.version}
      className="mb-4">
      <CardHeader className="h3">{md.version}</CardHeader>
      <CardBody><Markdown>{md.content}</Markdown></CardBody>
    </Card>) : <></>
}
