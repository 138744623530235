import React from 'react'

import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap'

import { NavLink, Link } from 'react-router-dom'
import { FormattedTime } from 'react-intl'

import { AppContext } from 'contexts/AppContext'

import { T } from 'components/TComponents'

const TopNav = ({ hasMenu, isSubsidiary }) => {
  const { user, logout, setMenuOpen, subsidiary, language, setSubsidiary, timezone, logo } = React.useContext(AppContext)
  const [hour, setHour] = React.useState(new Date())
  React.useEffect(() => {
    const timer = setInterval(() => {
      setHour(new Date())
    }, 60 * 1000);
    return () => {
      clearInterval(timer)
    }
  }, []);

  return (
    <nav className="navbar sticky-top">
      {hasMenu && <button
        className="menu-button-mobile d-xs-block d-sm-block d-md-none"
        onClick={() => setMenuOpen(menuOpen => !menuOpen)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
          <rect x="0.5" y="0.5" width="25" height="1" />
          <rect x="0.5" y="7.5" width="25" height="1" />
          <rect x="0.5" y="15.5" width="25" height="1" />
        </svg>
      </button>}

      {logo ? (
        <div className="navbar-logo-multiple" >
          <div className="d-flex flex-row align-items-center justify-content-center">
            <Link className="d-block logo-link" to={isSubsidiary ? "/subsidiaries" : "/"} >
              <span className="logo d-block" />
            </Link>
            <span className="d-block ml-3 mr-3 h-100"> X </span>
            <img src={logo} className="logo-2" />
          </div>
        </div>
      ) : (
        <Link className="navbar-logo" to={isSubsidiary ? "/subsidiaries" : "/"}>
          <span className="logo d-block" />
        </Link>
      )}

      <div className="ml-auto">
        <div className="d-inline-block">
          <UncontrolledDropdown className="dropdown-menu-right">
            <DropdownToggle className="p-0 d-flex flex-row align-items-center" color="empty">
              {timezone && <span className="text-muted mr-4">
                <FormattedTime value={hour} />
                {` (${timezone})`}
              </span>}
              <div>
                {language &&
                  <img className="mr-4" style={{ borderRadius: 4, width: 24 }} src={`/assets/img/flags/${language.langIdentifier}.svg`} />}
              </div>
              <div className="p-0 d-flex flex-column align-items-end">
                <T raw id="menu.name"
                  values={{
                    firstName: user.firstName,
                    lastName: user.lastName
                  }} />
                {subsidiary && <span className="text-muted">{subsidiary.subsidiaryName}</span>}
              </div>
            </DropdownToggle>
            <DropdownMenu className="mt-3" right>
              <DropdownItem className="text-right" onClick={() => logout()}>
                <T raw id="menu.signout" />
              </DropdownItem>
              <DropdownItem divider />
              {user && user.subsidiaries.length > 1 && <DropdownItem className="text-right"
                tag={NavLink}
                to={`/subsidiaries`}
                onClick={() => setSubsidiary()}>
                <T raw id="menu.changeSubsidiary" />
              </DropdownItem>}
              {!isSubsidiary && (
                <>
                  <DropdownItem className="text-right"
                    tag={NavLink}
                    to={`/languages`}>
                    <T raw id="menu.changeLanguage" />
                  </DropdownItem>
                  <DropdownItem className="text-right"
                    tag={NavLink}
                    to={`/account`}>
                    <T raw id="menu.account" />
                  </DropdownItem>
                  <DropdownItem className="text-right"
                    tag={NavLink}
                    to="/changelog">
                    <T raw id="menu.version" values={{ VERSION }} />
                  </DropdownItem>
                  {user && user.userId === 1 && <DropdownItem className="text-right"
                    tag={NavLink}
                    to={`/translations`}>Traductions</DropdownItem>}
                </>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </nav>
  )
}

export default TopNav
