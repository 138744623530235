import React from 'react'
import { FormattedDate } from 'react-intl'

import { FormGroup, Modal, ModalBody, ModalFooter } from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import { T, TAutocomplete, TLabel, TButton, TCustomInput, TInput, TAlert } from 'components/TComponents'
import ProtectedComponent, { useHasRights } from 'components/ProtectedComponent'
import { Sheet } from 'components/Sheet'
import VehicleIcon from 'components/VehicleIcon'
import { getColors } from 'util/Utils'

import './VeloptimUser.module.scss'

import ItvcodeSelector from 'components/ItvcodeSelector'
import CustomSelect from '../../../components/CustomSelect'


/* HELPERS FUNCTIONS */
const STATUS = Object.freeze({
  IDLE: 'idle',
  INITIALIZING: 'initializing',
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
})

const wait = async (promise, delay = 250) => {
  return Promise.all([
    promise,
    new Promise(resolve => setTimeout(resolve, delay))
  ]).then(([payload]) => payload)
}

const calendarReducer = (state, action) => {
  if (action.status === STATUS.ERROR) {
    return action
  }

  return {
    status: action.status || state.status,
    payload: {
      ...state.payload,
      ...(action.payload || {}),
      days: Object.entries(action.payload),
    }
  }
}

const VeloptimUser = ({ userId, userType, currentWeek }) => {
  const { api, user, constants } = React.useContext(AppContext)
  const userIdentifier = userId || user.userId
  const [rule, setRule] = React.useState()
  const [ruleModal, setRuleModal] = React.useState(false)
  const [ruleDay, setRuleDay] = React.useState(false)
  const [getRuleError, setGetRuleError] = React.useState()
  const [postRuleError, setPostRuleError] = React.useState()
  const [copyweekError, setCopyweekError] = React.useState()
  const [copyweekSuccess, setCopyweekSuccess] = React.useState()
  const [copyweekLoading, setCopyweekLoading] = React.useState()
  const [postedRule, setPostedRule] = React.useState(false)
  const [itvcodes, setItvcodes] = React.useState([])
  const [excludeItvcodes, setExcludeItvcodes] = React.useState(false)

  const [autocomplete, setAutocomplete] = React.useState()
  const [addressFull, setAddressFull] = React.useState()

  const [vehicles, setVehicles] = React.useState([])
  const [vehiclesLoading, setVehiclesLoading] = React.useState(false)
  const [vehiclesError, setVehiclesError] = React.useState()

  const [zoningGroups, setZoningGroups] = React.useState([])
  const [zoningGroupsLoading, setZoningGroupsLoading] = React.useState(false)
  const [zoningGroupsError, setZoningGroupsError] = React.useState()

  const [businessAddresses, setBusinessAddresses] = React.useState()
  const [businessAddressesError, setBusinessAddressesError] = React.useState()

  const [modalOpen, setModalOpen] = React.useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)

  const skillUserRight = useHasRights('user-veloptim_skill')
  const skillAdminRight = useHasRights('admin-veloptim_skill')
  const zoningUserRight = useHasRights('user-veloptim_zoninggroup')
  const zoningAdminRight = useHasRights('admin-veloptim_zoninggroup')
  const typologyUserRight = useHasRights('user-veloptim_typology')
  const typologyAdminRight = useHasRights('admin-veloptim_typology')
  const vehicleUserRight = useHasRights('user-veloptim_vehicle')
  const vehicleAdminRight = useHasRights('admin-veloptim_vehicle')

  const skillRights = (userType == "user" && skillUserRight) || (userType == "admin" && skillAdminRight)
  const zoningRights = (userType == "user" && zoningUserRight) || (userType == "admin" && zoningAdminRight)
  const typologyRights = (userType == "user" && typologyUserRight) || (userType == "admin" && typologyAdminRight)
  const vehicleRights = (userType == "user" && vehicleUserRight) || (userType == "admin" && vehicleAdminRight)
  const readingRight = useHasRights('veloptim-tour_view')

  const tagColors = getColors()

  const [calendar, dispatchCalendar] = React.useReducer(calendarReducer, { status: STATUS.INITIALIZING })

  React.useEffect(() => {
    getCalendar()
  }, [getCalendar])

  React.useEffect(() => {
    getBusinessAddresses()
  }, [getBusinessAddresses])

  React.useEffect(() => {
    (postedRule || currentWeek) && getCalendar()
  }, [getCalendar, postedRule, currentWeek])

  const getCalendar = React.useCallback(() => {
    wait(api.get('/veloptim/tours', undefined, { userId: userIdentifier, dayStart: currentWeek.firstDay.toISOString().substring(0, 10) }))
      .then(payload => dispatchCalendar({ status: STATUS.LOADED, payload }))
      .catch(payload => dispatchCalendar({ status: STATUS.ERROR, payload }))
      .then(() => setPostedRule(false))
  }, [api, currentWeek, userIdentifier])

  const getBusinessAddresses = React.useCallback(() => {
    wait(api.get('/business/businessAddresses'))
      .then(response => setBusinessAddresses(response))
      .catch(error => setBusinessAddressesError(error.code))
  }, [api])

  const businessAddressHandleChange = React.useCallback(e => {
    if (e && e.value) {
      setRule(rule => ({
        ...rule,
        mapId: e.mapId
      }))
      setAddressFull(e.value)
    } else {
      setAddressFull('')
      setRule(rule => ({
        ...rule,
        mapId: undefined
      }))
    }
  }, [])

  const copyWeek = React.useCallback(() => {
    setCopyweekLoading(true)
    wait(api.post('/veloptim/weektours', {
      body: JSON.stringify({
        userId: userIdentifier, day: currentWeek.firstDay.toISOString().substring(0, 10),
      })
    })
      .catch(response => setCopyweekError(response))
      .then(() => {
        setCopyweekSuccess(true)
        getCalendar()
      })
      .then(() => setCopyweekLoading(false))
    )
  }, [api, currentWeek, userIdentifier, getCalendar])

  const openRule = React.useCallback(column => {
    const tourId = column[1] ? column[1].tourId : undefined
    setRuleDay(column[0])
    wait(api.get('/veloptim/tour', undefined, { userId: userIdentifier, tourId: tourId }))
      .then(response => {
        if (response.exception) {
          setGetRuleError(response)
        } else {
          getZones()
          getVehicles()
          setRule(response)
          setAddressFull(response.addressFull)
          setExcludeItvcodes(response.excludeItvcodes)
          setItvcodes(response.itvcodes.reduce((acc, cat) => {
            cat.families.map(f => {
              f.items.map(c => {
                if (!c.enabled == !!response.excludeItvcodes) {
                  c.categoryName = cat.categoryName
                  c.familyName = f.familyName
                  acc.push(c)
                }
              })
            })
            return acc
          }, []))
          setRuleModal(true)
        }
      })
      .catch(error => setGetRuleError(error))
  }, [api, userIdentifier, getZones, getVehicles])

  const changeRule = React.useCallback((type, name, e) => {
    switch (type) {
    case 'family':
      // eslint-disable-next-line no-case-declarations
      const familyArray = rule.families
      familyArray.map(f => {
        if (f.familyId === name) {
          f.enabled = f.enabled === 1 ? 0 : 1
        }
      })
      setRule(rule => ({
        ...rule,
        families: familyArray
      }))
      break
    case 'typology':
      setRule(rule => ({
        ...rule,
        userTypology: e,
        ...(e == 2 && { zoninggroups: [] }),
      }))
      break
    case 'limitMax':
      setRule(rule => ({
        ...rule,
        limitMax: parseInt(e)
      }))
      break
    case 'limitItv':
      setRule(rule => ({
        ...rule,
        limitItv: parseInt(e)
      }))
      break
    case 'limitSwap':
      setRule(rule => ({
        ...rule,
        limitSwap: parseInt(e)
      }))
      break
    case 'handleItv':
      setRule(rule => ({
        ...rule,
        handleItv: !rule.handleItv
      }))
      break
    case 'handleSwap':
      setRule(rule => ({
        ...rule,
        handleSwap: !rule.handleSwap
      }))
      break
    case 'techcenterRadius':
      setRule(rule => ({
        ...rule,
        techcenterRadius: parseInt(e)
      }))
      break
    case 'vehicleId':
      setRule(rule => ({
        ...rule,
        vehicleId: e
      }))
      break
    case 'zoninggroups':
      setRule(rule => ({
        ...rule,
        zoninggroups: e
      }))
      break
    case 'description':
      setRule(rule => ({
        ...rule,
        description: e
      }))
      break
    case 'colorHex':
      setRule(rule => ({
        ...rule,
        colorHex: e
      }))
      break
    }
  }, [rule])

  const deleteRule = React.useCallback(() => {
    api.del('/veloptim/tour', undefined, {
      tourId: rule.tourId,
      day: ruleDay
    })
      .then(() => {
        setDeleteModalOpen(false)
        setRuleModal(false)
        setPostedRule(true)
      })
      .catch(error => {
        setPostRuleError(error)
        setDeleteModalOpen(false)
      })
  }, [api, rule, ruleDay])

  const saveRule = React.useCallback((forceVehicle = false) => {
    setPostRuleError()
    let error = {}
    const completeItvCodes = rule.itvcodes.reduce((acc, c) => {
      c.families.map(f => {
        f.items.map(i => {
          acc.push(i.itvcodeId);
        })
      })
      return acc
    }, [])
    const completeItvCodesCopy = JSON.parse(JSON.stringify(completeItvCodes))
    let counter = 0
    const itvcodesToPost = excludeItvcodes
      ? completeItvCodes.map((c, i) => { itvcodes.map(it => { if (it.itvcodeId === c) { completeItvCodesCopy.splice(i - counter, 1); counter++ } }); return completeItvCodesCopy })[0]
      : itvcodes.reduce((acc, c) => { acc.push(c.itvcodeId); return acc }, [])

    if (skillRights && itvcodesToPost.length === 0 && rule && rule.handleItv) {
      error = { code: 'codesEmpty' }
    }
    if (skillRights && rule.families.reduce((acc, f) => { if (f.enabled === 1) { acc.push(f.familyId) } return acc }, []).length === 0) {
      error = { code: 'familyEmpty' }
    }
    if (skillRights && !rule.handleItv && !rule.handleSwap) {
      error = { code: 'typeEmpty' }
    }
    if (zoningRights && !rule.mapId) {
      error = { code: 'mapIdEmpty' }
    }
    if (error && Object.keys(error).length === 0) {
      const ruleToPost = {
        userId: userIdentifier,
        tourId: rule.tourId,
        description: rule.description,
        colorHex: rule.colorHex,
        ...(rule.tourId && { tourId: rule.tourId }),
        ...(skillRights && { handleItv: rule.handleItv ? 1 : 0 }),
        ...(skillRights && { handleSwap: rule.handleSwap ? 1 : 0 }),
        ...(skillRights && { familiesId: rule.families.reduce((acc, f) => { if (f.enabled === 1) { acc.push(f.familyId) } return acc }, []) }),
        ...(skillRights && rule.handleItv && { itvcodesId: itvcodesToPost }),
        day: ruleDay,
        ...(skillRights && rule.handleItv && { excludeItvcodes }),
        ...(typologyRights && { techcenterRadius: rule.techcenterRadius }),
        ...(typologyRights && { mapId: rule.mapId }),
        ...(typologyRights && { limitMax: rule.limitMax }),
        ...(typologyRights && { limitItv: rule.limitItv }),
        ...(typologyRights && { limitSwap: rule.limitSwap }),
        ...(typologyRights && { userTypology: rule.userTypology }),
        ...(zoningRights && { zoninggroupsId: rule.zoninggroups ? rule.zoninggroups.map(z => z.zoninggroupId) : [] }),
        ...(vehicleRights && rule.vehicleId && { vehicleId: rule.vehicleId.vehicleId }),
        ...(forceVehicle && { forceVehicle: 1 })
      }
      api.post('/veloptim/tour', {
        body: JSON.stringify(ruleToPost)
      })
        .then(() => {
          setPostedRule(true)
          setRuleModal(false)
        })
        .catch(error => {
          if (error.code === 'vehicleIdAlreadyUse') {
            setModalOpen(true)
          } else {
            setPostRuleError(error)
          }
        })
    } else {
      setPostRuleError(error)
    }

  }, [api, rule, userIdentifier, ruleDay, itvcodes, excludeItvcodes, skillRights, typologyRights, zoningRights, vehicleRights])

  const handleChangeAddress = React.useCallback(e => {
    e.persist()
    setAddressFull(e.target.value)
    api.get('/maps/autocomplete', undefined, {
      input: e.target.value,
      ...(autocomplete && autocomplete.session_token && { session_token: autocomplete.session_token })
    })
      .then(response => {
        setAutocomplete(response)
      })
      .catch(error => console.log(error))
  }, [api, autocomplete])

  const handleAutocomplete = React.useCallback(e => {
    api.get('/maps/placedetails', undefined, {
      place_id: autocomplete.results.find(r => r.addressFull === e).place_id,
      session_token: autocomplete.session_token,
      addressFull: e
    })
      .then(response => {
        setRule(rule => ({
          ...rule,
          mapId: response.mapId
        }))
        setAddressFull(response.addressFull)
      })
      .catch(error => console.log(error))
  }, [api, autocomplete])

  const getVehicles = React.useCallback(() => {
    setVehiclesLoading(true)
    api.get('/vehicles/list', undefined, { ipp: -1 })
      .then(response => setVehicles(response))
      .catch(error => setVehiclesError(error))
      .then(() => setVehiclesLoading(false))
  }, [api])

  const getZones = React.useCallback(() => {
    setZoningGroupsLoading(true)
    api.get('/zoninggroups/list', undefined)
      .then(response => setZoningGroups(response))
      .catch(error => setZoningGroupsError(error))
      .then(() => setZoningGroupsLoading(false))
  }, [api])

  const getBusinessAddressesValue = React.useCallback((data) => {
    if (rule && rule.mapId) {
      return (data.map(d => {
        if (d.options && d.options.length > 0) {
          return d.options.filter(option => option.mapId === rule.mapId)
        }
      }))[0]
    }
  }, [rule])

  const getFormattedOptions = data => {
    return (data.map(d => ({
      label: d.businessName,
      options: d.addresses[0].map(a => ({
        label: a.formatted_address,
        value: a.formatted_address,
        mapId: a.mapId
      }))
    })
    ))
  }

  const getFormattedItvcodesOptions = data => {
    return (data.map(d => (
      d.families.map(f => ({
        label: `${d.categoryName} - ${f.familyName}`,
        options: f.items.map(i => (
          {
            label: `${i.itvcodeIdentifier}  - ${i.itvcodeLabel} - (${d.categoryName}-${f.familyName})`,
            value: Object.assign({}, i, { categoryName: d.categoryName }, { familyName: f.familyName })
          })
        )
      }))
    ))[0])
  }

  return (
    <>
      {readingRight && (
        <div className="w-100 sectionContent mb-2">
          {[STATUS.LOADED, STATUS.LOADING].includes(calendar.status) && (
            <>
              <div className="veloptim">
                <TLabel id="VeloptimUser.veloptim.title" raw className="label" />
                <Sheet className="sheet w-100 mt-3 border veloptim-sheet"
                  columns={calendar.payload.days}
                  columnExtractKey={column => `head-${column[0]}`}
                  columnHeaderRender={column => (
                    <div className="columnHeader">
                      <FormattedDate value={new Date(column[0])} month="long" day="2-digit" weekday="short" />
                    </div>
                  )}
                  rows={[1]}
                  rowExtractKey={(_, i) => `line-${i}`}
                  dataRender={(row, column) => (
                    <div className={`data d-flex ${column[1] && column[1].isFull && 'veloptim-unavailable'}`}                                        >
                      {column[1] && (
                        <React.Fragment key={column[0]}>
                          <div className="d-flex flex-row align-items-center">
                            {column[1].colorHex && (
                              <div className="color-tag mr-2" style={{ backgroundColor: column[1].colorHex }} />
                            )}
                            {column[1].description && <span>{column[1].description}</span>}
                          </div>
                          {column[1].vehicleType && (
                            <div className="d-flex align-items-center mt-2">
                              <VehicleIcon type={column[1].vehicleType} />
                              {column[1].vehicleIdentifier} - {column[1].vehicleLabel}
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  )}
                  onDataClick={(e, row, column) => openRule(column)} />
                {getRuleError && <TAlert color="danger" className="w-100 mt-2" id={`VeloptimUser.${getRuleError.code}`} raw />}
                {copyweekSuccess && <TAlert color="success" className="w-100 mt-2" id={`VeloptimUser.copyweekSuccess`} raw />}
                {copyweekError && <TAlert color="danger" className="w-100 mt-2" id={`VeloptimUser.${copyweekError.code}`} raw />}
                <div className="d-flex justify-content-end mt-3">
                  <TButton className="mr-2" id="VeloptimUser.calendar.copyWeek" raw onClick={() => copyWeek()} loading={copyweekLoading} disabled={[STATUS.LOADING, STATUS.ERROR].includes(calendar.status) || copyweekLoading} />
                </div>
              </div>
              <Modal
                isOpen={ruleModal}
                fade={true}
                size="lg"
                toggle={() => {
                  setRuleModal(false)
                  setPostRuleError()
                }}>
                <ModalBody>
                  <span className="d-block h6 mb-4" >
                    <T id="VeloptimUser.modal.title" raw /> {` - `}
                    <FormattedDate value={ruleDay} month="long" day="2-digit" weekday="long" />
                  </span>
                  <FormGroup tag="fieldset">
                    <TLabel id="VeloptimUser.description" raw />
                    <TInput
                      id="description"
                      className="w-100"
                      type="text"
                      name="description"
                      value={rule && rule.description}
                      placeholder="descriptionPlaceholder"
                      onChange={e => { changeRule('description', undefined, e.currentTarget.value) }} />
                  </FormGroup>
                  <FormGroup tag="fieldset">
                    <TLabel id="VeloptimUser.colorHex" raw />
                    <CustomSelect
                      id="colorHex"
                      onChange={e => { changeRule('colorHex', undefined, e && e.value) }}
                      isSearchable={false}
                      name="colorHex"
                      placeholder={< T id="VeloptimUser.colorHex.placeholder" raw />}
                      isClearable
                      options={tagColors}
                      value={rule && rule.colorHex && tagColors.find(c => c.value === rule.colorHex)}
                      getOptionLabel={option => <div style={{ backgroundColor: option.value }} className="color-tag" />} />
                  </FormGroup>
                  <>
                    <T id="VeloptimUser.modal.skills" className="d-block mb-3 h6" raw />
                    <FormGroup tag="fieldset">
                      <TLabel id="VeloptimUser.type" raw />
                      <TCustomInput
                        id={`VeloptimUser.intervention`}
                        raw
                        type="checkbox"
                        name='intervention'
                        label={`VeloptimUser.type.intervention`}
                        disabled={!skillRights}
                        checked={rule && rule.handleItv}
                        onChange={(e) => { changeRule('handleItv', undefined, e.currentTarget.value) }} />
                      <TCustomInput
                        id={`VeloptimUser.swap`}
                        raw
                        type="checkbox"
                        name='swap'
                        disabled={!skillRights}
                        label={`VeloptimUser.type.swap`}
                        checked={rule && rule.handleSwap}
                        onChange={(e) => { changeRule('handleSwap', undefined, e.currentTarget.value) }} />
                    </FormGroup>
                    <FormGroup tag="fieldset">
                      <>
                        <TLabel id="VeloptimUser.family" raw />
                        {rule && rule.families && rule.families.map(family => (
                          <TCustomInput
                            key={family}
                            id={`VeloptimUser.${family.familyId}`}
                            raw
                            type="checkbox"
                            name={family.familyName}
                            disabled={!skillRights}
                            label={family.familyName}
                            checked={family.enabled === 1}
                            onChange={e => { changeRule('family', family.familyId, e.currentTarget.value) }} />
                        ))}
                      </>
                    </FormGroup>
                    {rule && !!rule.handleItv && (
                      <FormGroup tag="fieldset">
                        <TLabel id="VeloptimUser.excludeItvcodes" raw />
                        <TCustomInput
                          id={`VeloptimUser.exclude`}
                          raw
                          type="checkbox"
                          name="exclude"
                          className="mb-3"
                          disabled={!skillRights}
                          label="VeloptimUser.excludeItvcodes"
                          checked={excludeItvcodes}
                          onChange={() => setExcludeItvcodes(!excludeItvcodes)} />
                        <TLabel id={`VeloptimUser.itvcode`} raw />
                        {rule && rule.itvcodes && rule.itvcodes.length > 0 &&
                                                    <ItvcodeSelector
                                                      hasBusiness
                                                      itvcodesAdded={itvcodes}
                                                      itvcodes={getFormattedItvcodesOptions(rule.itvcodes)}
                                                      noQuantity
                                                      noWear
                                                      withHeaders
                                                      withFamilies
                                                      clearable
                                                      editable={!!skillRights}
                                                      exclude={excludeItvcodes ? true : false}
                                                      onChange={codes => setItvcodes(codes)} />
                        }
                      </FormGroup>
                    )}
                  </>
                  {rule && !vehiclesLoading && !vehiclesError && (
                    <>
                      <FormGroup tag="fieldset">
                        <TLabel id="VeloptimUser.vehicle" raw />
                        <CustomSelect
                          id="vehicles"
                          onChange={e => { changeRule('vehicleId', undefined, e) }}
                          value={rule && rule.vehicleId && vehicles && vehicles.result && vehicles.result.find(v => v.vehicleId === rule.vehicleId)}
                          isSearchable
                          name="vehicles"
                          isDisabled={!vehicleRights}
                          placeholder={<T id="VeloptimUser.vehicle.placeholder" raw />}
                          options={[{ vehicleId: 0 }].concat(vehicles && vehicles.result)}
                          getOptionLabel={option => option.vehicleId === 0
                            ? <T raw id={`VeloptimUser.noVehicle`} />
                            : <div className='d-flex align-items-center'>  + {option.vehicleIdentifier} - {option.vehicleLabel} - + <VehicleIcon type={option.typeId} /></div>}
                          getOptionValue={option => option.vehicleId} />
                      </FormGroup>
                    </>
                  )}
                  <>
                    <T id="VeloptimUser.modal.typology" className="d-block mb-3 h6" raw />
                    <FormGroup tag="fieldset">
                      <TLabel id="VeloptimUser.userTypology" className="d-block" raw />
                      {constants && constants.veloptimUserTypology && constants.veloptimUserTypology.map(typology => (
                        <TCustomInput
                          key={typology.key}
                          id={typology.key}
                          type="radio"
                          checked={rule && rule.userTypology == typology.value}
                          name="typology"
                          value={typology.value}
                          label={typology.key}
                          disabled={!typologyRights}
                          onChange={({ target: { value } }) => changeRule('typology', undefined, value)}
                          inline />
                      ))}
                    </FormGroup>
                    <FormGroup tag="fieldset">
                      <TLabel id="VeloptimUser.businessAddresses" className="d-block" raw />
                      {businessAddressesError && <TAlert color="danger" className="w-100 mt-2" id={`VeloptimUser.${businessAddressesError.code}`} raw />}
                      {businessAddresses && businessAddresses.length > 0 && (
                        <CustomSelect
                          id="businessAddresses"
                          className="flex-grow-1"
                          onChange={e => businessAddressHandleChange(e)}
                          isSearchable={true}
                          name="businessAddresses"
                          options={getFormattedOptions(businessAddresses)}
                          placeholder={<T id="VeloptimUser.businessAddresses.placeholder" raw />}
                          isClearable
                          isDisabled={!typologyRights}
                          value={getBusinessAddressesValue(getFormattedOptions(businessAddresses))}
                          noOptionsMessage={() => <T id="VeloptimUser.businessAddresses.noResult" raw />} />
                      )}
                    </FormGroup>
                    <FormGroup tag="fieldset">
                      <TLabel id="VeloptimUser.addressFull" raw />
                      <TAutocomplete id="addressFull"
                        className="w-100"
                        type="text"
                        name="addressFull"
                        value={addressFull}
                        placeholder="VeloptimUser.addressFullPlaceholder"
                        raw
                        disabled={!typologyRights}
                        searchOnFocus={e => handleChangeAddress(e)}
                        onChange={e => handleChangeAddress(e)}
                        onAutocomplete={e => handleAutocomplete(e)}
                        options={
                          autocomplete && autocomplete.results.length > 0 && autocomplete.results.map(r => {
                            return (r.addressFull)
                          })} />
                    </FormGroup>
                    <FormGroup tag="fieldset">
                      {rule && rule.handleSwap && rule.handleItv ? (
                        <>
                          <TLabel id="VeloptimUser.limitMax" raw />
                          <TInput
                            id="limitMax"
                            className="w-100"
                            type="number"
                            name="limitMax"
                            value={rule && rule.limitMax}
                            placeholder="VeloptimUser.limitMaxPlaceholder"
                            disabled={!typologyRights}
                            raw
                            onChange={e => { changeRule('limitMax', undefined, e.currentTarget.value) }} />
                          <TLabel id="VeloptimUser.limitSwap" raw />
                          <TInput
                            id="limitSwap"
                            className="w-100"
                            type="number"
                            name="limitSwap"
                            disabled={!typologyRights}
                            value={rule && rule.limitSwap}
                            placeholder="limitSwapPlaceholder"
                            onChange={e => { changeRule('limitSwap', undefined, e.currentTarget.value) }} />
                          <TLabel id="VeloptimUser.limitItv" raw />
                          <TInput
                            id="limitItv"
                            className="w-100"
                            type="number"
                            name="limitItv"
                            disabled={!typologyRights}
                            value={rule && rule.limitItv}
                            placeholder="limitItvPlaceholder"
                            onChange={e => { changeRule('limitItv', undefined, e.currentTarget.value) }} />
                        </>
                      ) : (
                        <>
                          <TLabel id="VeloptimUser.limitMax" raw />
                          <TInput
                            id="limitMax"
                            className="w-100"
                            type="number"
                            name="limitMax"
                            disabled={!typologyRights}
                            value={rule && rule.limitMax}
                            placeholder="VeloptimUser.limitMaxPlaceholder"
                            raw
                            onChange={e => { changeRule('limitMax', undefined, e.currentTarget.value) }} />
                        </>
                      )}
                    </FormGroup>
                    {rule && rule.userTypology == 2 && (
                      <FormGroup tag="fieldset">
                        <TLabel id="VeloptimUser.techcenterRadius" raw />
                        <TInput
                          id="techcenterRadius"
                          className="w-100"
                          type="number"
                          name="techcenterRadius"
                          disabled={!typologyRights}
                          value={rule && rule.techcenterRadius}
                          placeholder="VeloptimUser.techcenterRadiusPlaceholder"
                          raw
                          onChange={e => { changeRule('techcenterRadius', undefined, e.currentTarget.value) }} />
                      </FormGroup>
                    )}
                  </>
                  {rule && rule.userTypology == 1 && !zoningGroupsLoading && !zoningGroupsError && zoningGroups && (
                    <>
                      <T id="VeloptimUser.modal.zoning" className="d-block mb-3 h6" raw />
                      <FormGroup tag="fieldset">
                        <TLabel id="VeloptimUser.zones" raw />
                        <CustomSelect
                          id="zones"
                          isMulti
                          onChange={e => { changeRule('zoninggroups', undefined, e) }}
                          value={rule && rule.zoninggroups}
                          placeholder={<T id="VeloptimUser.zonesPlaceholder" raw />}
                          isSearchable
                          isDisabled={!zoningRights}
                          name="zones"
                          options={zoningGroups && zoningGroups.result}
                          getOptionLabel={option => option.zoninggroupLabel}
                          getOptionValue={option => option.zoninggroupId} />
                      </FormGroup>
                    </>
                  )}
                </ModalBody>
                <ModalFooter className="justify-content-between">
                  {postRuleError && <TAlert color="danger" className="w-100" id={`VeloptimUser.${postRuleError.code}`} raw />}
                  <div>
                    {rule && rule.tourId &&
                                            <ProtectedComponent rights={['admin-veloptim_delete']}>
                                              <TButton color="danger" id={`VeloptimUser.ruleModal.delete`} raw onClick={() => { setDeleteModalOpen(true) }} />
                                            </ProtectedComponent>
                    }
                  </div>
                  <div>
                    <TButton
                      id={`VeloptimUser.ruleModal.cancel`}
                      color="danger"
                      outline
                      raw
                      onClick={() => {
                        setRuleModal(false)
                        setPostRuleError()
                      }} />
                    <TButton className="ml-2" id={`VeloptimUser.ruleModal.save`} raw onClick={() => { saveRule() }} />
                  </div>
                </ModalFooter>
              </Modal>
            </>
          )}
        </div>
      )}
      <Modal isOpen={modalOpen} fade={false} toggle={() => { setModalOpen(false) }}>
        <ModalBody>
          <T id="VeloptimUser.vehicle.force" raw />
        </ModalBody>
        <ModalFooter>
          <TButton id="VeloptimUser.vehicle.cancel" onClick={() => { setModalOpen(false) }} raw />
          <TButton
            id="VeloptimUser.vehicle.confirm"
            color="danger"
            onClick={() => {
              setModalOpen(false)
              saveRule(true)
            }}
            className="ml-2 d-flex align-items-center"
            raw />
        </ModalFooter>
      </Modal>

      <Modal isOpen={deleteModalOpen} fade={false} toggle={() => { setDeleteModalOpen(false) }}>
        <ModalBody>
          <T id="VeloptimUser.delete.information" raw />
        </ModalBody>
        <ModalFooter>
          <TButton id="VeloptimUser.delete.cancel" onClick={() => { setDeleteModalOpen(false) }} raw />
          <TButton
            id="VeloptimUser.delete.confirm"
            color="danger"
            onClick={() => {
              setDeleteModalOpen(false)
              deleteRule()
            }}
            className="ml-2 d-flex align-items-center"
            raw />
        </ModalFooter>
      </Modal>
    </>
  )
}


export default VeloptimUser
