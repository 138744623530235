import React from 'react'
import { NavLink } from 'react-router-dom'

import {
  Alert, Table, Card, CardHeader,
  Form, FormGroup, Label, CustomInput,
  Button,
  Row, Col,
  Spinner
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'
import { useFormatMessage, } from 'hooks/intl.hooks'

import TH from 'components/TH'
import Pagination from 'components/Pagination'
import CustomSelect from 'components/CustomSelect'
import { T, ErrAlert, TLabel, TInput, TButton, TCurrency } from 'components/TComponents'
import ProtectedComponent from 'components/ProtectedComponent'

import { debounce } from 'util/Utils'

const Itvcodes = () => {
  const { api } = React.useContext(AppContext)

  const [data, setData] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()

  const [searchInput, setSearchInput] = React.useState('')
  const [downloadLink, setDownloadLink] = React.useState('')

  const fileInput = React.useRef()

  const formatMessage = useFormatMessage()
  const [uploadError, setUploadError] = React.useState()
  const [uploadSuccess, setUploadSuccess] = React.useState()
  const [uploadWarnings, setUploadWarnings] = React.useState()
  const [uploadLoading, setUploadLoading] = React.useState()

  React.useEffect(() => {
    Promise.all([
      api.get('/itvcodes/list'),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => setData(data))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api])

  const refresh = React.useCallback((params) => {
    setLoading(true)
    setError()
    Promise.all([
      api.get('/itvcodes/list', undefined, {
        filter: data.filters.filter,
        categoryId: data.filters.categories.selected,
        familyId: data.filters.families.selected,
        'order[column]': data.order.column,
        'order[dir]': data.order.dir,
        ...params
      }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => setData(data))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api, data])

  React.useEffect(() => {
    let link = ''
    if (data) {
      if (data.downloadLink) {
        link = data.downloadLink.url + '?token=' + data.downloadLink.params.token + '&subsidiaryId=' + data.downloadLink.params.subsidiaryId
      }
      if (data.filters && data.filters.categories && data.filters.categories.selected) {
        link = link + '&catgoryId=' + data.filters.categories.selected
      }
      if (data.filters && data.filters.families && data.filters.families.selected) {
        link = link + '&familyId=' + data.filters.families.selected
      }
      if (data.filters && data.filters.filter) {
        link = link + '&filter=' + data.filters.filter
      }
      if (data.order) {
        link = link + '&order[column]=' + data.order.column + '&order[dir]=' + data.order.dir
      }
    }
    setDownloadLink(link)
  }, [data])

  const refreshDebounced = React.useMemo(() => debounce(params => refresh(params), 250), [refresh])

  const handleSearchInput = React.useCallback(({ target: { value } }) => {
    setSearchInput(value)
    refreshDebounced({ filter: value, p: 1 })
  }, [refreshDebounced])

  const handleImport = React.useCallback((e) => {
    e.preventDefault()
    const file = fileInput.current.files[0]
    setUploadLoading(true)
    setUploadError()
    setUploadSuccess()
    setUploadWarnings()
    api.put('/itvcodes/import', { body: file })
      .then(response => {
        if (response.totalInsertUpdate || response.totalInsertUpdate >= 0) {
          setUploadSuccess(response.totalInsertUpdate.toString())
        }
        response.errors && setUploadWarnings(response.errors)
      })
      .catch(error => { setUploadError(error.code) })
      .then(() => setUploadLoading(false))
  }, [api])

  const order = React.useCallback((e, column) => {
    e.preventDefault()

    refresh({
      'order[column]': column,
      'order[dir]': data.order.column === column && data.order.dir === 'asc' ? 'desc' : 'asc',
    })
  }, [data, refresh])

  return (
    <>
      <Card className="mb-2">
        <CardHeader>
          <Form onSubmit={e => e.preventDefault()}>
            <Row form>
              <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel for="searchInput" id="searchInputLabel" className="" />
                  <TInput
                    name="searchInput"
                    type="text"
                    placeholder="searchInputPlaceholder"
                    value={searchInput}
                    onChange={handleSearchInput} />
                </FormGroup>
              </Col>
              {data && data.filters && data.filters.categories && data.filters.categories.values && <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel for="categoryId" id="categoryLabel" className="" />
                  <CustomSelect
                    inputId="categoryId"
                    name="categoryId"
                    options={data.filters.categories.values}
                    onChange={e => refresh({ categoryId: e && e.categoryId })}
                    isClearable
                    placeholder={<T id="categoryPlaceholder" />}
                    value={data.filters.categories.selected && data.filters.categories.values.filter(c => c.categoryId == data.filters.categories.selected)}
                    getOptionLabel={option => option.categoryName}
                    getOptionValue={option => option.categoryId}
                    isDisabled={loading} />
                </FormGroup>
              </Col>}
              {data && data.filters && data.filters.families && data.filters.families.values && <Col sm="6" md="3">
                <FormGroup tag="fieldset">
                  <TLabel for="familyId" id="familyLabel" className="" />
                  <CustomSelect
                    inputId="familyId"
                    name="familyId"
                    options={data.filters.families.values}
                    onChange={e => refresh({ familyId: e && e.familyId })}
                    isClearable
                    placeholder={<T id="familyPlaceholder" />}
                    value={data.filters.families.selected && data.filters.families.values.filter(f => f.familyId == data.filters.families.selected)}
                    getOptionLabel={option => option.familyName}
                    getOptionValue={option => option.familyId}
                    isDisabled={loading} />
                </FormGroup>
              </Col>}
            </Row>
          </Form>
        </CardHeader>
        <CardHeader className="d-flex">
          {downloadLink && (
            <Button
              className="d-flex"
              style={{ alignItems: 'center' }}
              tag="a"
              target="_blank"
              href={downloadLink}>
              <i style={{ fontSize: 16 }} className="simple-icon-docs mb-0 mr-2" />
              <T id="exportButton" />
            </Button>
          )}
          <ProtectedComponent rights={['admin-itvcodes_edit',]}>
            <TButton className="ml-auto"
              tag={NavLink}
              to={`./itvcodes/new`}
              id="new" />
          </ProtectedComponent>
        </CardHeader>
        <ProtectedComponent rights={['admin-itvcodes_edit',]}>
          <CardHeader>
            <Form onSubmit={e => handleImport(e)}>
              <FormGroup tag="fieldset">
                <Label for="importInput"><T id="importFileLabel" /></Label>
                <div className="d-flex">
                  <Col sm="6" md="3" className="pl-0">
                    <CustomInput type="file"
                      name="importInput"
                      id="importInput"
                      innerRef={fileInput}
                      label={(fileInput.current && fileInput.current.files[0] && fileInput.current.files[0].name) || formatMessage({ id: 'importFilePlaceholder' })}
                      disabled={uploadLoading} />
                  </Col>
                  <TButton id="send" disabled={uploadLoading} className="ml-1" type="submit" />
                </div>

              </FormGroup>
            </Form>
          </CardHeader>
        </ProtectedComponent>
        {!uploadLoading && (uploadError || uploadSuccess || uploadWarnings) && (
          <div className="m-2">
            {!uploadLoading && uploadError && <Alert color="danger" className="h-auto" ><T id={`error.${uploadError}`} /></Alert>}
            {!uploadLoading && uploadSuccess && <Alert color="success" className="h-auto"><T id={`success`} values={{ value: uploadSuccess }} /></Alert>}
            {!uploadLoading && uploadWarnings && uploadWarnings.map(w =>
              <Alert key={w.record.itvcodeIdentifier} color="warning" className="h-auto">
                {`${w.record.itvcodeIdentifier} - ${w.record.itvcodeLabel} => `}
                <T id={`error.${w.code}`} />
              </Alert>
            )}
          </div>
        )}
      </Card>
      {loading && <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />}
      {!loading && error && <ErrAlert error={error} />}
      {!loading && !error && data && <Table responsive striped hover className="mb-3 mt-2">
        <thead>
          <tr>
            <TH colName="count" colValues={{ total: data.total }} colSpan="8" className={['border-top-0']} />
          </tr>
        </thead>
        <thead>
          <tr>
            <TH colName="itvcodeIdentifier" order={data.order} sort={order} />
            <TH colName="itvcodeLabel" order={data.order} sort={order} />
            <TH colName="categoryName" order={data.order} sort={order} />
            <TH colName="familyName" order={data.order} sort={order} />
            <TH colName="ref" order={data.order} sort={order} />
            <TH colName="durationMin" order={data.order} sort={order} />
            <TH colName="priceTTC" order={data.order} sort={order} />
            <TH />
          </tr>
        </thead>
        <tbody>
          {data.result.map(itvcode => <tr key={itvcode.itvcodeId} style={{
            transform: `rotate(0)`
          }}>
            <td>{itvcode.itvcodeIdentifier}</td>
            <td>{itvcode.itvcodeLabel}</td>
            <td>{itvcode.categoryName}</td>
            <td>{itvcode.familyName}</td>
            <td>{itvcode.ref}</td>
            <td>{itvcode.durationMin}</td>
            <td><TCurrency value={itvcode.priceTTC} /></td>
            <td><TButton className="ml-auto stretched-link" outline
              tag={NavLink}
              to={`./itvcodes/${itvcode.itvcodeId}`}
              id="view" /></td>
          </tr>)}
        </tbody>
        <tfoot>
          <tr><td colSpan="8">
            <Pagination totalPage={data.totalPage}
              currentPage={data.currentPage}
              onPageClick={p => refresh({ p })} /></td></tr>
        </tfoot>
      </Table>}
    </>
  )
}

export default Itvcodes
