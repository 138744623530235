import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import CustomSelect from 'components/CustomSelect'

import {
  Spinner,
  Card, CardBody, CardFooter,
  Modal, ModalBody, ModalFooter,
  Form, FormGroup,
  Input,
  NavLink,
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import { T, ErrAlert, TButton, TLabel, } from 'components/TComponents'

const BusinessuserDetails = ({ match }) => {
  const { api, constants } = React.useContext(AppContext)

  const hasId = match.params.id !== undefined

  const [returnToList, setReturnToList] = React.useState(false)

  const [baseBusinessuser, setBasebusinessuser] = React.useState({})
  const [businessuser, setBusinessuser] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    userTypeId: 0,
    companyId: '',
    business: []
  })
  const [loading, setLoading] = React.useState(hasId)
  const [loadError, setLoadError] = React.useState()

  const [companies, setCompanies] = React.useState()
  const [companiesLoading, setCompaniesLoading] = React.useState(true)
  const [companiesError, setCompaniesError] = React.useState()

  const [business, setBusiness] = React.useState()
  const [businessLoading, setBusinessLoading] = React.useState(true)
  const [businessError, setBusinessError] = React.useState()

  const [onEdit, setOnEdit] = React.useState(!hasId)
  const [editing, setEditing] = React.useState(false)
  const [editError, setEditError] = React.useState()

  const [lockModalOpen, setLockModalOpen] = React.useState(false)
  const [locking, setLocking] = React.useState(false)
  const [lockError, setLockError] = React.useState()

  React.useEffect(() => {
    if (hasId) {
      setLoading(true)
      api.get('/businessusers/details', undefined, { userId: match.params.id })
        .then(response => {
          setBusinessuser(response)
          setBasebusinessuser({ ...response })
        })
        .catch(response => setLoadError(response))
        .then(() => setLoading(false))
    }
  }, [api, hasId, match.params.id])

  React.useEffect(() => {
    api.get('/business/list', undefined, { ipp: -1 })
      .then(response => setBusiness(response.result))
      .catch(response => setBusinessError(response))
      .then(() => setBusinessLoading(false))
  }, [api])

  React.useEffect(() => {
    api.get('/companies/list', undefined, { ipp: -1 })
      .then(response => setCompanies(response.result))
      .catch(response => setCompaniesError(response))
      .then(() => setCompaniesLoading(false))
  }, [api])

  const cancel = React.useCallback(() => {
    setBusinessuser({ ...baseBusinessuser })
    setOnEdit(false)
  }, [baseBusinessuser])

  const handleChange = React.useCallback(({ target: { name, value, type, checked } }) => {
    setBusinessuser({
      ...businessuser,
      [name]: type === 'checkbox' ? checked : value
    })
  }, [businessuser])

  const handleCheckBusiness = React.useCallback((e, businessId) => {
    const { target: { checked } } = e

    if (!onEdit) {
      return
    }

    setBusinessuser({
      ...businessuser,
      business: checked
        ? businessuser.business.concat(businessId)
        : businessuser.business.filter(_businessId => _businessId !== businessId)
    })
  }, [businessuser, onEdit])

  const post = React.useCallback(() => {
    setEditError()
    setEditing(true)
    api.post('/businessusers/details', {
      body: JSON.stringify({
        ...businessuser,
        userId: hasId ? match.params.id : undefined,
      })
    })
      .then(response => {
        if (hasId) {
          setBusinessuser(response)
          setBasebusinessuser({ ...response })
          setOnEdit(false)
          setEditing(false)
        } else {
          setReturnToList(true)
        }
      })
      .catch(response => {
        setEditError(response)
        setEditing(false)
      })
  }, [api, businessuser, hasId, match.params.id])

  const toggleLockUser = React.useCallback(() => {
    setLockError()
    setLocking(true)
    api.post('/businessusers/details', {
      body: JSON.stringify({
        userId: match.params.id, locked: !baseBusinessuser.locked,
      })
    })
      .then(response => {
        setBusinessuser(response)
        setBasebusinessuser({ ...response })
        setLockModalOpen(false)
      })
      .catch(response => setLockError(response))
      .then(() => setLocking(false))
  }, [api, baseBusinessuser.locked, match.params.id])

  if (returnToList) {
    return <Redirect to="/admin/businessusers" />
  }

  if (loading) {
    return <>
      <NavLink tag={Link} to="/admin/businessusers"><T id="returnToList" /></NavLink>
      <Spinner className="d-flex ml-auto mr-auto" color="primary" />
    </>
  }

  if (loadError) {
    return <>
      <NavLink tag={Link} to="/admin/businessusers"><T id="returnToList" /></NavLink>
      <ErrAlert error={loadError} className="mt-2" />
    </>
  }

  return (
    <>
      <NavLink tag={Link} to="/admin/businessusers"><T id="returnToList" /></NavLink>
      <Card className="mb-2">
        <CardBody tag={Form}>
          <FormGroup tag="fieldset">
            <TLabel id="firstName" />
            <Input id="firstName"
              type="text"
              name="firstName"
              value={businessuser.firstName}
              disabled={editing || !onEdit || businessLoading || businessError || companiesLoading || companiesError}
              onChange={handleChange} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel id="lastName" />
            <Input id="lastName"
              type="text"
              name="lastName"
              value={businessuser.lastName}
              disabled={editing || !onEdit || businessLoading || businessError || companiesLoading || companiesError}
              onChange={handleChange} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel id="email" />
            <Input id="email"
              type="text"
              name="email"
              value={businessuser.email}
              disabled={editing || !onEdit || businessLoading || businessError || companiesLoading || companiesError || hasId}
              onChange={handleChange} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel id="userTypeId" for="userTypeId" />
            <CustomSelect
              id="userTypeId"
              onChange={e => handleChange({ target: { name: 'userTypeId', value: e.value } })}
              isSearchable={false}
              name="userTypeId"
              options={constants.userTypes}
              value={constants.userTypes.find(_userType => _userType.value === businessuser.userTypeId)}
              placeholder={'...'}
              isDisabled={editing || !onEdit}
              getOptionLabel={option => <T raw id={`userType.${option.key}`} />} />
          </FormGroup>

          <FormGroup tag="fieldset">
            <TLabel id="companyId.label" for="companyId" />
            {companiesError && <ErrAlert error={companiesError} className="mb-0" />}
            {companiesLoading && !companiesError && <Spinner className="d-block" />}
            {!companiesLoading && !companiesError && <CustomSelect
              id="companyId"
              name="companyId"
              isDisabled={!onEdit}
              value={companies.find(company => company.companyId === businessuser.companyId) || ''}
              onChange={e => handleChange({ target: { name: 'companyId', value: e.companyId } })}
              isSearchable={false}
              placeholder={'...'}
              options={companies}
              getOptionLabel={option => option.companyName}
              getOptionValue={option => option.companyId} />}
          </FormGroup>

          <TLabel id="businessList" />
          {businessLoading && <Spinner className="d-flex mt-2 ml-auto mr-auto" color="primary" />}
          {!businessLoading && business.map((_business) => <FormGroup key={_business.businessId} tag="fieldset" check>
            <Input id={`business.${_business.businessId}`}
              type="checkbox"
              name={_business.nameProfile}
              checked={businessuser.business.includes(_business.businessId) || false}
              disabled={!onEdit}
              onChange={e => handleCheckBusiness(e, _business.businessId)} />
            <TLabel
              id="business"
              className=""
              values={_business}
              for={`business.${_business.businessId}`} />
          </FormGroup>)}
          {businessError && <ErrAlert error={businessError} className="mt-2" />}
          {editError && <ErrAlert error={editError} className="mt-2 mb-0" />}
        </CardBody>
        <CardFooter className="d-flex">
          {hasId && <TButton id={`${baseBusinessuser.locked ? 'unlock' : 'lock'}`} disabled={editing} className="ml-2" onClick={() => setLockModalOpen(true)} color={baseBusinessuser.locked ? 'primary' : 'danger'} />}
          <div className="d-flex ml-auto pl-2">
            {(!hasId || onEdit) && <TButton id="post" disabled={editing} loading={editing} className="ml-2" onClick={() => post()} color="primary" />}
            {hasId && !onEdit && <TButton id="edit" disabled={editing} className="ml-2" onClick={() => setOnEdit(true)} color="primary" />}
            {(!hasId || !onEdit) && <TButton id="cancel" disabled={editing} tag={Link} className="ml-2" to="/admin/businessusers" />}
            {hasId && onEdit && <TButton id="cancel" disabled={editing} className="ml-2" onClick={() => cancel()} />}
          </div>
        </CardFooter>
      </Card>

      <Modal isOpen={lockModalOpen} fade={false} toggle={() => { setLockError(); setLockModalOpen(false) }}>
        <ModalBody>
          <T id={`${baseBusinessuser.locked ? 'unlock' : 'lock'}Content`} />
          {lockError && <ErrAlert error={lockError} className="mb-0 mt-2" />}
        </ModalBody>
        <ModalFooter>
          <TButton id="lockCancel" disabled={locking} onClick={() => { setLockError(); setLockModalOpen(false) }} />
          <TButton id={`${baseBusinessuser.locked ? 'unlock' : 'lock'}Confirm`}
            loading={locking}
            disabled={locking}
            onClick={() => toggleLockUser()}
            className="ml-2 d-flex align-items-center" color={baseBusinessuser.locked ? 'primary' : 'danger'} />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default BusinessuserDetails
