import React from 'react'
import { NavLink } from 'react-router-dom'

import {
  Card, CardHeader,
  FormGroup,
  Spinner,
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import Pagination from 'components/Pagination'
import ProtectedComponent from 'components/ProtectedComponent'
import { ErrAlert, TLabel, TInput, TButton, TCustomInput, } from 'components/TComponents'

import { debounce } from 'util/Utils'

const TechcenterList = () => {
  const { api } = React.useContext(AppContext)

  const [data, setData] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()

  const [searchInput, setSearchInput] = React.useState('')

  React.useEffect(() => {
    Promise.all([
      api.get('/techcenters/list'),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => setData(data))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api])

  const refresh = React.useCallback(params => {
    setLoading(true)
    setError()
    Promise.all([
      api.get('/techcenters/list', undefined, {
        ...data.filters,
        p: 1,
        ...params
      }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([data]) => setData(data))
      .catch(error => setError(error))
      .then(() => setLoading(false))
  }, [api, data])

  const refreshDebounced = React.useMemo(() => debounce(params => refresh(params), 250), [refresh])

  const handleSearchInput = React.useCallback(({ target: { value } }) => {
    setSearchInput(value)
    refreshDebounced({ filter: value, p: 1 })
  }, [refreshDebounced])

  return (
    <>
      <Card className="mb-2">
        <CardHeader className="pb-0">
          <FormGroup className="flex-grow-1 pl-0">
            <TLabel check className="sr-only" for="techcentersFilter"
              id="techcentersFilterTitle" />
            <TInput
              name="techcentersFilter"
              type="text"
              placeholder="techcentersFilterPlaceholder"
              value={searchInput}
              onChange={handleSearchInput} />
          </FormGroup>
          <ProtectedComponent rights={['admin_archive']}>
            <FormGroup className="flex-grow-1 pl-0">
              <TCustomInput type="switch"
                id="archived"
                name="archived"
                label="archived"
                className="w-100 bg-transparent border-0"
                checked={data ? data.filters.archived : false}
                onChange={e => refresh({ archived: e.target.checked, p: 1 })} />
            </FormGroup>
          </ProtectedComponent>
        </CardHeader>
        <ProtectedComponent rights={['admin-techcenters_edit']}>
          <CardHeader>
            <TButton tag={NavLink}
              to="./techcenters/new"
              id="createTechcenter" />
          </CardHeader>
        </ProtectedComponent>
      </Card>
      {loading && <Spinner className="d-flex mx-auto my-5" color="primary" />}
      {!loading && error && <ErrAlert error={error} />}
      {!loading && !error && data.result.map(techcenter => <Card key={techcenter.techcenterId} className="mb-2">
        <CardHeader className="d-flex align-items-center">
          {techcenter.archived && <i className="mr-1 mb-0 h5 iconsmind-Box-Close text-primary" />}
          <span className="h5 mb-0">{techcenter.techcenterIdentifier}</span>
          <span className="h6 mb-0">&nbsp;-&nbsp;{techcenter.techcenterName}</span>
          <TButton className="ml-auto stretched-link" outline
            tag={NavLink}
            to={`./techcenters/${techcenter.techcenterId}`}
            id="viewTechcenter" />
        </CardHeader>
      </Card>)}

      {data && <Pagination totalPage={data.totalPage} currentPage={data.currentPage} onPageClick={p => refresh({ p })} />}
    </>
  )
}

export default TechcenterList
