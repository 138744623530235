import React from 'react'

const Columns = ({ direction, duration }) => {
  const lineCount = duration
  const lineSplit = duration
  const buildedLines = React.useMemo(() => Array.from(Array(lineCount)).map((_, i) => (
    <div key={`rct-time-item-${i}`} className="rct-time-item">
      {Array.from(Array(lineSplit)).map((_, i) => (
        <div key={`rct-time-item-${i}`} className="rct-time-item dashed" />
      ))}
    </div>
  )), [lineCount, lineSplit])

  return <div className={`rct-time-lines-${direction}`}>{buildedLines}</div>
}

export default Columns
