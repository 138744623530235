import React from 'react'

const GroupHeader = props => {
  return (
    <div
      data-testid={`groupHeader`}
      className={`${['rct-groupHeader', props.className].join(' ').trim()}`}>
      {props.elements.map(group => <span
        key={`label-${group.id}`}>
        {group.title}
      </span>)}
    </div>
  )
}

export default GroupHeader
