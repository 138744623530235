import React from 'react'
import { Card, CardHeader, CardBody, Button } from 'reactstrap'
import { useLocation, Link } from 'react-router-dom'
import { T } from 'components/TComponents'

export const Error404 = () => {
  const { pathname } = useLocation()

  return (
    <Card>
      <CardBody>
        <T raw tagName="p" id="layouts.404.content" values={{ pathname }} className="display-4 font-weight-bold" />
        <Button tag={Link}
          to="/"
          color="primary"
          className="btn-shadow"
          size="lg">
          <T raw id="layouts.go-back-home" />
        </Button>
      </CardBody>
    </Card>
  )
}

export const Error = () => (
  <Card>
    <CardHeader className="mb-4">
      <T raw id="layouts.error-title" />
    </CardHeader>
    <CardBody>
      {/* <p className="mb-0 text-muted text-small mb-0">
        <FormattedMessage id="layouts.error-code" />
      </p> */}
      <p className="display-1 font-weight-bold mb-5">Error</p>
      <Button tag={Link}
        to="/"
        color="primary"
        className="btn-shadow"
        size="lg">
        <T raw id="layouts.go-back-home" />
      </Button>
    </CardBody>
  </Card>
)

export default Error404
