import React from 'react'
import {
  Popover, PopoverHeader, PopoverBody
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

const PlanningItem = item => {
  const { constants } = React.useContext(AppContext)
  const interventionAnomalyStatusValue = constants.interventionStatus.find(status => status.key === 'anomaly').value
  const interventionClosedStatusValue = constants.interventionStatus.find(status => status.key === 'closed').value
  const swapAnomalyStatusValue = constants.swapStatus.find(status => status.key === 'anomaly').value

  switch (item.type) {
  case 'intervention': return (
    <div>
      {item.intervention.tracking === 1 && '🕕'}
      {item.intervention.statusId === interventionAnomalyStatusValue && `⚠️`}
      {item.intervention.statusId === interventionClosedStatusValue && `🔒`}
      {item.intervention.dateVeloptimScheduled && '🤖'}
        #{item.intervention.interventionId}
      {item.intervention && item.intervention.description && <Popover target={item.id}
        isOpen={item.isHover}
        toggle={item.toggle}>
        <PopoverHeader>{item.intervention.descriptionTitle || `${item.intervention.dateVeloptimScheduled ? '🤖' : ''} #${item.intervention.interventionId}`}</PopoverHeader>
        <PopoverBody dangerouslySetInnerHTML={{ __html: item.intervention.description }} />
      </Popover>}
    </div>
  )
  case 'swap': return (
    <div>
      {item.swap.tracking === 1 && '🕕'}
      {item.swap.statusId === swapAnomalyStatusValue && `⚠️`}
      {item.swap.dateVeloptimScheduled && '🤖'}
      ⇄{item.swap.swapId}
      {item.swap && item.swap.description && <Popover target={item.id}
        isOpen={item.isHover}
        toggle={item.toggle}>
        <PopoverHeader>{item.swap.descriptionTitle || `${item.swap.dateVeloptimScheduled ? '🤖' : ''} ⇄${item.swap.swapId}`}</PopoverHeader>
        <PopoverBody dangerouslySetInnerHTML={{ __html: item.swap.description }} />
      </Popover>}
    </div>
  )
  case 'appointment': return (
    <div>
        📆{item.appointment.appointmentId}
      {item.appointment && item.appointment.description && <Popover target={item.id}
        isOpen={item.isHover}
        toggle={item.toggle}>
        <PopoverHeader>{item.appointment.descriptionTitle || `📆 ${item.appointment.appointmentId}`}</PopoverHeader>
        <PopoverBody dangerouslySetInnerHTML={{ __html: item.appointment.description }} />
      </Popover>}
    </div>
  )
  case 'maintenance': return (
    <div>
        🔧{item.maintenance.maintenanceplanId}
      {item.maintenance && item.maintenance.description && <Popover target={item.id}
        isOpen={item.isHover}
        toggle={item.toggle}>
        <PopoverHeader>{item.maintenance.descriptionTitle || `📆 ${item.maintenance.maintenanceplanId}`}</PopoverHeader>
        <PopoverBody dangerouslySetInnerHTML={{ __html: item.maintenance.description }} />
      </Popover>}
    </div>
  )
  case 'unavailability': return (<div></div>)
  case 'suggestion': return (<div>#{item.suggestion.interventionId}</div>)
  default: return (
    <div>
        #{item.id}
    </div>
  )
  }
}

export default PlanningItem