import React from 'react'

import Pairing from './PairingComponents/Pairing'
import Desappairing from './PairingComponents/Desappairing'


const PairingLVM = () => {
  return (
    <>
      <Pairing />
      <Desappairing />
    </>
  )
}

export default PairingLVM
