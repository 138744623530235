/* eslint-disable react/display-name */
import React from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'
import { useLocation, useHistory, NavLink } from 'react-router-dom'
import { objectToQuery, queryToObject } from 'react-rest-api'

import { Card, CardHeader, } from 'reactstrap'

import { useGet } from 'hooks/useApi.hooks'
import { useFormatMessage } from 'hooks/intl.hooks'

import ProtectedComponent from 'components/ProtectedComponent'
import { ErrAlert, TButton, T } from 'components/TComponents'
import SlotSelector from 'components/SlotSelector'
import Duration from 'components/Duration'
import { Table } from 'components/Table'
import { Filters } from 'components/Filters'

const AppointmentList = () => {
  const location = useLocation()
  const history = useHistory()
  const formatMesage = useFormatMessage()

  const filters = React.useMemo(() => ({
    p: {
      type: 'number'
    },
    ipp: {
      type: 'number'
    },
    'order[column]': {},
    'order[dir]': {},
    filter: {
      type: 'string',
      componentType: 'textDebounce'
    },
    businessId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isSearchable: true,
        getOptionValue: option => option.label,
        isMulti: true
      },
    },
    statusId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: true,
        getOptionLabel: option => formatMesage({ id: `appointmentStatus.${option.label}`, raw: true })
      }
    },
  }), [formatMesage])

  const moreFilters = React.useMemo(() => ({
    dateAppointment: {
      componentType: 'date',
      transform: value => {
        if (!value) {
          return value
        }
        const date = new Date(value)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      },
      componentOptions: {
        placeholderText: formatMesage({ id: `filters.dateAppointment.placeholder` }),
        dateFormat: 'dd/MM/yyyy'
      },
      childKeys: ['dateAppointmentBefore', 'dateAppointmentAfter']
    },
    dateAppointmentBefore: {
      transform: value => {
        if (!value) {
          return value
        }
        const date = new Date(value)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      },
    },
    dateAppointmentAfter: {
      transform: value => {
        if (!value) {
          return value
        }
        const date = new Date(value)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      },
    },
    duration: {
      componentType: 'duration',
      defaultValues: [0, 480],
      defaultStep: 15,
      childKeys: ['durationStart', 'durationEnd']
    },
    durationStart: {},
    durationEnd: {},
    address: {
      type: 'string',
      componentType: 'textDebounce'
    },
    companyId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false,
        getOptionLabel: option => option.value == 0 ? <T raw id={`companyId.${option.value}`} /> : option.label
      },
    },
  }), [formatMesage])

  // DATA
  const params = React.useMemo(() => queryToObject(location.search, { ...filters, ...moreFilters }), [location.search, filters, moreFilters])

  const [data, refreshing, error, loading] = useGet(`/appointments/list`, undefined, params)

  const refresh = React.useCallback((_params = {}) => history.push({ search: objectToQuery({ ...params, ..._params }) }), [history, params])

  // INNER COMPONENTS
  const DatePlanned = React.useCallback(intervention => {
    return (
      <span><FormattedDate value={new Date(intervention.dateAppointment)} />{' '}<FormattedTime value={new Date(intervention.dateAppointment)} /></span>
    )}, [])

  const AppointmentDuration = React.useCallback(intervention => (
    <Duration duration={intervention.duration} />
  ), [])

  const DetailsButton = React.useCallback(item => (
    <TButton className="ml-auto stretched-link" outline
      id="details"
      tag={NavLink}
      to={{
        pathname: `/appointments/${item.appointmentId}`,
        state: { appointments: location.search }
      }}
    />
  ), [location])

  // RENDER
  if (error) {
    return <ErrAlert error={error} />
  }

  return (
    <div className="container-fluid">
      <Card className="mb-2">
        <CardHeader>
          <Filters
            loading={loading}
            disabled={refreshing}
            data={data}
            refresh={refresh}
            filters={filters}
            moreFilters={moreFilters}
          />
        </CardHeader>
        <ProtectedComponent rights={['appointments_new']}>
          <CardHeader>
            <TButton tag={NavLink}
              id="create"
              to={{
                pathname: './appointments/new',
                state: { appointments: location.search }
              }} />
          </CardHeader>
        </ProtectedComponent>
      </Card>
      <Table
        isLoading={loading}
        disabled={refreshing}
        data={data}
        refresh={refresh}
        columns={[
          {
            title: 'appointmentId',
            render: item => item.appointmentId
          },
          {
            title: 'businessName',
            hasOrder: true,
            render: item => item.businessName
          },
          {
            title: 'dateAppointment',
            hasOrder: true,
            render: DatePlanned
          },
          {
            title: 'duration',
            render: AppointmentDuration
          },
          {
            title: 'address',
            render: item => item.address
          },
          {
            title: 'statusId',
            hasOrder: true,
            render: item => formatMesage({ id: `appointmentStatus.${item.statusValue}`, raw: true })
          },
          {
            className: () => 'align-middle text-right',
            render: DetailsButton
          },
        ]}
      />
    </div>
  )
}

export default AppointmentList
