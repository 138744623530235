import React from 'react'
import { Switch } from 'react-router-dom'

import Route from 'components/Route'

import List from 'routes/Admin/Vehicles/VehiclesList'
import New from 'routes/Admin/Vehicles/VehiclesNew'
import Edit from 'routes/Admin/Vehicles/VehiclesEdit'

export default function Vehicles({ match }) {
  return <Switch>
    <Route exact path={`${match.path}`} component={List} rights={['admin-vehicle_view']}/>
    <Route path={`${match.path}/new`} component={New} rights={['admin-vehicle_edit']}/>
    <Route path={`${match.path}/:id`} component={Edit} rights={['admin-vehicle_edit']}/>
  </Switch>
}
