export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key], B = b[key]
    if (order.indexOf(A + '') > order.indexOf(B + '')) {
      return 1
    } else {
      return -1
    }
  })
  return array
}


export const getDateWithFormat = () => {
  const today = new Date()
  let dd = today.getDate()
  let mm = today.getMonth() + 1 //January is 0!

  const yyyy = today.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  return dd + '.' + mm + '.' + yyyy
}

export const formatDate = (date) => {
  let dd = date.getDate()
  let mm = date.getMonth() + 1 //January is 0!
  const yyyy = date.getFullYear()

  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  return `${yyyy}-${mm}-${dd}`
}

export const formatDateTime = (dateTime) => {
  let DD = dateTime.getDate()
  let MM = dateTime.getMonth() + 1 //January is 0!
  const YYYY = dateTime.getFullYear()
  let HH = dateTime.getHours()
  let mm = dateTime.getMinutes()
  let ss = dateTime.getSeconds()

  if (DD < 10) {
    DD = '0' + DD
  }
  if (MM < 10) {
    MM = '0' + MM
  }
  if (HH < 10) {
    HH = '0' + HH
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  if (ss < 10) {
    ss = '0' + ss
  }
  return `${YYYY}-${MM}-${DD} ${HH}:${mm}:${ss}`
}

export const getCurrentTime = () => {
  const now = new Date()
  return now.getHours() + ':' + now.getMinutes()
}

export const addCommas = (nStr) => {
  nStr += ''
  const x = nStr.split('.')
  let x1 = x[0]
  const x2 = x.length > 1 ? '.' + x[1] : ''
  const rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}

export const debounce = (fct, delay) => {
  let timer

  return (...args) => {
    clearTimeout(timer)
    return new Promise((resolve) => {
      timer = setTimeout(
        () => resolve(fct(...args)),
        delay,
      )
    })
  }
}

export const makeCancelable = (promise) => {
  let hasCanceled_ = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => hasCanceled_ ? reject({ isCanceled: true }) : resolve(val),
      error => hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true
    },
  }
}

export const getColors = () => {
  return [
    { key: 'blue', value: '#145388' },
    { key: 'blueLight', value: '#8cbce4' },
    { key: 'blueDark', value: '#3a435f' },
    { key: 'cyan', value: '#38d0d5' },
    { key: 'green', value: '#576a3d' },
    { key: 'greenLight', value: '#9ff924' },
    { key: 'orange', value: '#e2863b' },
    { key: 'yellow', value: '#e2d83b' },
    { key: 'red', value: '#d7082a' },
    { key: 'brown', value: '#6e2c00' },
    { key: 'purple', value: '#922c88' },
    { key: 'pink', value: '#f5b7b1' },
    { key: 'pinkDark', value: '#d72b7f' },
    { key: 'grey', value: '#626567' },
    { key: 'greyLight', value: '#b2babb' },
  ]
}
