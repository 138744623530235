import React from 'react'
import {
  Card, CardBody, CardHeader, CardFooter,
  FormGroup, Spinner, Label, Input
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import ProtectedComponent from 'components/ProtectedComponent'
import { T, TCustomInput, TAlert, TLabel, TButton } from 'components/TComponents'
import VeloptimZoningGroups from './VeloptimZoningGroups'
import VeloptimSlots from './VeloptimSlots'

const Veloptim = () => {
  const { api } = React.useContext(AppContext)

  const [settings, setSettings] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [edit, setEdit] = React.useState(false)
  const [allChecked, setAllChecked] = React.useState()
  const [loadingError, setLoadingError] = React.useState()
  const [postError, setPostError] = React.useState()

  React.useEffect(() => {
    setLoading(true)
    api.get('/veloptim/settings', undefined)
      .then(response => setSettings(response))
      .catch(error => setLoadingError(error))
      .then(() => setLoading(false))
  }, [api])

  React.useEffect(() => {
    let checked = true
    settings && settings.companies && settings.companies.map(c => {
      if (!c.enableVeloptim) {
        checked = false
      }
    })
    setAllChecked(checked)
  }, [settings])

  const handleChangeVeloptim = React.useCallback((value, name) => {
    const companies = settings.companies.map(c => {
      return c.companyId == name ? { ...c, enableVeloptim: value ? 1 : 0 } : c
    })
    setSettings(settings => ({
      ...settings,
      companies
    }))
  }, [settings])

  const handleChangeTechcenterRadiusVeloptim = React.useCallback(value => {
    setSettings(() => ({
      ...settings,
      techcenterRadiusVeloptim: value,
    }))
  }, [settings])

  const handleChangeAll = React.useCallback(value => {
    const companies = settings.companies.map(c => {
      return { ...c, enableVeloptim: value ? 1 : 0 }
    })
    setSettings(settings => ({
      ...settings,
      companies
    }))
  }, [settings])

  const handleSave = React.useCallback(() => {
    setLoading(true)
    api.post('/veloptim/settings', { body: JSON.stringify(settings) })
      .then(response => setSettings(response))
      .catch(error => setPostError(error))
      .then(() => {
        setLoading(false)
        setEdit(false)
      })
  }, [api, settings])

  return (
    <>
      <Card className="mb-2">
        <CardHeader tag="h2">
          <T id="veloptim" />
        </CardHeader>
        <CardBody>
          {loadingError && <TAlert color="danger" id={loadingError.code} />}
          {loading && <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />}
          {!loading && !loadingError && settings && settings.companies && (
            <>
              <TLabel id="enable" className="d-block" />
              <TButton
                className="mb-2"
                outline
                id={allChecked ? `unselectAll` : `selectAll`}
                disabled={loading || !edit}
                onClick={() => handleChangeAll(!allChecked)} />
              {settings.companies.length > 0 && settings.companies.map(company =>
                <FormGroup tag="fieldset" key={company.companyId}>
                  <Label for={`enableVeloptim${company.companyId}`}>
                    {company.companyName}
                  </Label>
                  <TCustomInput
                    id={`enableVeloptim${company.companyId}`}
                    type="checkbox"
                    name={company.companyId}
                    label="enableVeloptim"
                    checked={company.enableVeloptim}
                    disabled={loading || !edit}
                    onChange={e => { handleChangeVeloptim(e.target.checked, e.target.name) }} />
                </FormGroup>
              )}
            </>
          )}
        </CardBody>
        {!loading && !loadingError && (
          <>
            <CardBody className="pt-0">
              <FormGroup tag="fieldset">
                <TLabel for="techcenterRadiusVeloptim" id="techcenterRadiusVeloptim" />
                <div className="d-flex justify-content-between">
                  <T id="kms.1" />
                  <T id="kms.20" />
                </div>
                <Input type="range" min="1" max="20" step="1"
                  disabled={loading || !edit}
                  value={settings && settings.techcenterRadiusVeloptim ? settings.techcenterRadiusVeloptim : 0}
                  onChange={e => handleChangeTechcenterRadiusVeloptim(e.target.value)} />
                <div>
                  <T id="kms" values={{ value: settings.techcenterRadiusVeloptim }} />
                </div>
              </FormGroup>
            </CardBody>
            <ProtectedComponent rights={['admin-zoninggroup']}>
              <CardBody className="pt-0">
                <VeloptimZoningGroups />
              </CardBody>
            </ProtectedComponent>
            <CardBody className="pt-0">
              <VeloptimSlots />
            </CardBody>
            <ProtectedComponent rights={['admin-veloptim_edit']}>
              <CardFooter>
                <>
                  {postError && <TAlert className="mb-2" color="danger" id={postError.code} />}
                  {!loadingError && <div className="d-flex justify-content-end" >
                    {!edit && <TButton id="edit" onClick={() => { setEdit(true) }} />}
                    {edit && (
                      <>
                        <TButton id="cancel" onClick={() => { setEdit(false) }} />
                        <TButton className="ml-2" id="save" onClick={handleSave} />
                      </>
                    )}
                  </div>}
                </>
              </CardFooter>
            </ProtectedComponent>
          </>
        )}
      </Card>
    </>
  )
}

export default Veloptim
