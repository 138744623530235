import React from 'react'
import {
  Card, CardBody, CardHeader, CardFooter,
  FormGroup, Input
} from 'reactstrap'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { useLocation, useHistory } from 'react-router-dom'
import { objectToQuery, queryToObject } from 'react-rest-api'
import { useGet } from 'hooks/useApi.hooks'

import { AppContext } from 'contexts/AppContext'

import { Filters } from 'components/Filters'
import { T, TAlert, TButton, TLabel, ErrAlert } from 'components/TComponents'
import { useHasRights } from 'components/ProtectedComponent'

const SortableItem = SortableElement(({ item, editItemId, editItemLabel, saveItem, setEditItemLabel, updateLoading, handleDelete, handleCancel, handleEdit, canEdit }) => {
  return (
    <Card className={`${(editItemId && editItemId === item.checklistId) || !canEdit ? 'no-drag' : 'cursor-pointer'}`}>
      <CardBody className="mb-2 d-flex justify-content-between pt-3 pb-3">
        {editItemId && editItemId === item.checklistId && canEdit ? (
          <>
            <div className="d-flex align-items-center" style={{ flex: '1 1 0%' }}>
              <FormGroup tag="fieldset" className="w-100 mb-0">
                <Input
                  name="editItem"
                  id="editItem"
                  type="text"
                  className="mr-4 no-drag w-100"
                  value={editItemLabel}
                  onChange={(e) => setEditItemLabel(e.target.value)} />
                {updateLoading && <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true" />}
              </FormGroup>
            </div>
            <div className='ml-4'>
              <TButton id="save"
                className="ml-auto mr-4 no-drag"
                onClick={saveItem} />
              <TButton id="cancel"
                className="ml-auto mr-2 no-drag"
                onClick={() => handleCancel()}
                outline />
            </div>
          </>
        ) : (
          <>
            <div className="d-flex align-items-center">
              {canEdit && <i className="simple-icon-menu mr-4" style={{ fontSize: 22 }} />}
              <span>{item.checkLabel}</span>
              {updateLoading && <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true" />}
            </div>
            {canEdit && <div>
              <TButton id="edit"
                className="ml-auto mr-4 no-drag"
                onClick={() => handleEdit(item)}
                outline />
              <TButton id="delete"
                className="ml-auto mr-2 no-drag"
                onClick={() => handleDelete(item)}
                color="danger" />
            </div>}
          </>
        )}
      </CardBody>
    </Card>
  )
})

const SortableList = SortableContainer(({ items, editItemId, editItemLabel, saveItem, setEditItemLabel, updateLoading, handleDelete, handleCancel, handleEdit, canEdit }) => {
  return (
    <div>
      {items.map((item, index) => (
        <SortableItem
          key={item.checklistId}
          index={index}
          item={item}
          editItemId={editItemId}
          editItemLabel={editItemLabel}
          saveItem={saveItem}
          setEditItemLabel={setEditItemLabel}
          updateLoading={updateLoading}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          handleCancel={handleCancel}
          canEdit={canEdit}
        />
      ))}
    </div>
  );
});

const Checklist = () => {
  const { api } = React.useContext(AppContext)
  const location = useLocation()
  const history = useHistory()
  const canEdit = useHasRights('admin-checklist_edit') ? true : false
  const [checkLabel, setCheckLabel] = React.useState()
  const [result, setResult] = React.useState()
  const [updateSuccess, setUpdateSuccess] = React.useState()
  const [updateError, setUpdateError] = React.useState()
  const [updateLoading, setUpdateLoading] = React.useState()

  const [editItemId, setEditItemId] = React.useState()
  const [editItemLabel, setEditItemLabel] = React.useState()

  const filters = React.useMemo(() => ({
    p: {
      type: 'number'
    },
    ipp: {
      type: 'number'
    },
    businessId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isSearchable: true,
        getOptionValue: option => option.label,
        isMulti: false
      },
    },
    categoryId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false
      },
    },
    familyId: {
      array: true,
      componentType: 'select',
      componentOptions: {
        isMulti: false
      },
    },
  }), [])

  const params = React.useMemo(() => queryToObject(location.search, filters), [location.search, filters])

  const [data, refreshing, error, loading] = useGet(`/checklist/list`, undefined, params)

  const refresh = React.useCallback((_params = {}) => history.push({ search: objectToQuery({ ...params, ..._params }) }), [history, params])

  React.useEffect(() => {
    if (data && data.result) {
      setResult(data.result)
    }
  }, [data])

  const saveChecklist = React.useCallback((checklist) => {
    setUpdateLoading(true)
    api.post('/checklist/details', {
      body: JSON.stringify({ ...checklist })
    })
      .then(response => {
        if (response.code) {
          setUpdateError(response.code)
          setUpdateLoading()
          setUpdateSuccess()
        } else {
          setResult(response)
          setCheckLabel('')
          setUpdateSuccess(true)
          setUpdateLoading()
          setUpdateError()
        }
      })
      .catch(error => {
        setUpdateError(error.code)
        setUpdateLoading()
        setUpdateSuccess()
      })
  }, [api])

  const handleCancelStart = e => {
    if (e.target.className.includes('no-drag') || e.target.parentElement.className.includes('no-drag')) {
      return true
    }
    return false
  }

  const saveItem = React.useCallback(() => {
    if (result && result.checklist && result.checklist.length > 0) {
      const newChecklist = result.checklist.map(c => {
        if (c.checklistId === editItemId) {
          return (
            {
              checkLabel: editItemLabel,
              priority: c.priority
            }
          )
        }
        return (
          {
            checkLabel: c.checkLabel,
            priority: c.priority
          }
        )
      })
      const dataToPush = JSON.parse(JSON.stringify(result))
      dataToPush.checklist = newChecklist
      setEditItemId()
      setEditItemLabel()
      saveChecklist(dataToPush)
    }
  }, [result, saveChecklist, editItemId, editItemLabel, setEditItemId])

  const handleDelete = React.useCallback((item) => {
    if (result && result.checklist && result.checklist.length > 0) {
      const newChecklist = result.checklist.filter(c => c.checklistId !== item.checklistId).map(c => {
        if (c.priority > item.priority) {
          return (
            {
              checkLabel: c.checkLabel,
              priority: c.priority - 1
            }
          )
        } else {
          return (
            {
              checkLabel: c.checkLabel,
              priority: c.priority
            }
          )
        }
      })
      const dataToPush = JSON.parse(JSON.stringify(result))
      dataToPush.checklist = newChecklist
      saveChecklist(dataToPush)
    }
  }, [saveChecklist, result])

  const handleCancel = React.useCallback(() => {
    setEditItemId()
    setEditItemLabel()
  }, [setEditItemId, setEditItemLabel])

  const handleEdit = React.useCallback((item) => {
    setEditItemId(item.checklistId)
    setEditItemLabel(item.checkLabel)
  }, [setEditItemId, setEditItemLabel])

  const handleSortEnd = React.useCallback(({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex && result && result.checklist && result.checklist.length > 0) {
      const item = result.checklist.find(c => c.priority === oldIndex + 1)
      const newChecklist = result.checklist.map(c => {
        if (c.checklistId === item.checklistId) {
          return (
            {
              checkLabel: c.checkLabel,
              priority: newIndex + 1
            }
          )
        } else if (c.priority >= newIndex + 1 && c.priority <= oldIndex + 1) {
          return (
            {
              checkLabel: c.checkLabel,
              priority: c.priority + 1
            }
          )
        } else if (c.priority <= newIndex + 1 && c.priority >= oldIndex + 1) {
          return (
            {
              checkLabel: c.checkLabel,
              priority: c.priority - 1
            }
          )
        } else {
          return (
            {
              checkLabel: c.checkLabel,
              priority: c.priority
            }
          )
        }
      })
      const dataToPush = JSON.parse(JSON.stringify(result))
      dataToPush.checklist = newChecklist
      saveChecklist(dataToPush)
    }
  }, [saveChecklist, result])

  const updateChecklist = React.useCallback((item, priority) => {
    if (result && result.checklist) {
      if (!priority) {
        const highPriority = result.checklist.length > 0
          ? Math.max(...result.checklist.map(c => c.priority)) + 1
          : 1
        const checklist = result.checklist.map(c => {
          return (
            {
              checkLabel: c.checkLabel,
              priority: c.priority
            }
          )
        })
        checklist.push({
          checkLabel: checkLabel,
          priority: highPriority
        })
        const dataToPush = JSON.parse(JSON.stringify(result))
        dataToPush.checklist = checklist
        saveChecklist(dataToPush)
      }
    }
  }, [result, checkLabel, saveChecklist])

  return (
    <>
      <div className="container-fluid">
        <Card className="mb-2">
          <CardHeader>
            <Filters
              loading={loading}
              disabled={refreshing}
              data={data}
              refresh={refresh}
              filters={filters}
            />
          </CardHeader>
        </Card>
        <Card>
          <>
            {!error && !loading && (
              <>
                {result && result.checklist ? (
                  <>
                    {result.checklist.length > 0 ? (
                      <>
                        <SortableList
                          items={result.checklist}
                          onSortEnd={handleSortEnd}
                          shouldCancelStart={handleCancelStart}
                          editItemLabel={editItemLabel}
                          editItemId={editItemId}
                          saveItem={saveItem}
                          setEditItemLabel={setEditItemLabel}
                          setEditItemId={setEditItemId}
                          updateLoading={updateLoading}
                          handleDelete={handleDelete}
                          handleCancel={handleCancel}
                          handleEdit={handleEdit}
                          canEdit={canEdit}
                        />
                      </>
                    ) : (
                      <CardBody>
                        <T id="noData" />
                      </CardBody>
                    )}
                  </>
                ) : (
                  <CardBody>
                    <T id="noFilters" />
                  </CardBody>
                )}
              </>
            )}
            {!loading && error && <ErrAlert error={error} />}
            {result && result.checklist && canEdit && (
              <Card className="mb-2">
                <CardBody>
                  <TLabel for="checkLabel" id="checkLabel" />
                  <Input id="checkLabel"
                    type="text"
                    name="checkLabel"
                    value={checkLabel}
                    onChange={e => setCheckLabel(e.target.value)}
                  />
                </CardBody>
                {updateSuccess && <TAlert id="updateSuccess" className="mb-0" color="success" />}
                {updateError && <TAlert id={`error.${updateError}`} raw className="mb-0" color="danger" />}
                <CardFooter className="d-flex justify-content-end">
                  <TButton
                    className="ml-2"
                    onClick={updateChecklist}
                    id="add" />
                </CardFooter>
              </Card>
            )}
          </>
        </Card>
      </div>
    </>
  )
}

export default Checklist
