import React from 'react'
import { NavLink } from 'react-router-dom'

import { AppContext } from 'contexts/AppContext'
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone'

import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, CustomInput } from 'reactstrap'

import { T, TButton, TLabel, TCustomInput } from 'components/TComponents'

import './VeloptimPlanModal.scss'

const VeloptimPlanModal = ({ location, typeIntervention, id, itvcodes, familyId, mapId, durationPlanned, onDismiss, isOpen, onSearch, searchItems }) => {
  const { api, timezone } = React.useContext(AppContext)
  const history = useHistory()

  const handleDismiss = React.useCallback(() => {
    onDismiss()
  }, [onDismiss])

  const [filters, setFilters] = React.useState([])
  const [matchTypeIntervention, setMatchTypeIntervention] = React.useState(searchItems && searchItems.matchTypeIntervention || 1)
  const [matchItvcodes, setMatchItvcodes] = React.useState(searchItems && typeIntervention === 'intervention' && searchItems.matchItvcodes || 1)
  const [matchFamily, setMatchFamily] = React.useState(searchItems && searchItems.matchFamily || 1)
  const [matchZonning, setMatchZonning] = React.useState(searchItems && searchItems.matchZonning || 1)
  const [matchLimitItv, setMatchLimitItv] = React.useState(searchItems && searchItems.matchLimitItv || 1)
  const [userTypology, setUserTypology] = React.useState(searchItems && searchItems.userTypology || 0)
  const [calculateCloseItv, setCalculateCloseItv] = React.useState(searchItems && searchItems.calculateCloseItv || 1)
  const [vehicleType, setVehicleType] = React.useState(searchItems && searchItems.vehicleType || 0)
  const [subsidiarySlotId, setSubsidiarySlotId] = React.useState(searchItems && searchItems.subsidiarySlotId || 0)

  React.useEffect(() => {
    isOpen && api.get('/veloptim/filterCalculation', undefined, { typeIntervention: typeIntervention })
      .then(response => setFilters(response))
  }, [api, isOpen, typeIntervention])

  const timeFromUTC = React.useCallback(initialDate => {
    const dateArray = initialDate.split(' ')
    const dateInJs = `${dateArray[0]}T${dateArray[1]}Z`
    return moment(dateInJs).tz(timezone).format('HH:mm')
  }, [timezone])

  return (
    <>
      <Modal isOpen={isOpen}
        toggle={handleDismiss}
        size="xl"
        className='veloptimPlanModal'>
        <ModalHeader className="header">
          <T id="VeloptimPlanModal.saveAndPlan" raw />
        </ModalHeader>
        <ModalBody className="body">
          <div className="mb-3">
            <T id="VeloptimPlanModal.explication" raw/>
          </div>
          <div className="mb-3 d-flex flex-row">
            <div className="column">
              <TLabel id="VeloptimPlanModal.slot" className="mb-2" tagName="div" raw />
              <FormGroup tag="fieldset" >
                {filters && filters.subsidiarySlot && [{ subsidiarySlotId: 0 }].concat(filters.subsidiarySlot).map(slot => (
                  <CustomInput
                    className={slot.subsidiarySlotId === subsidiarySlotId ? 'radio radio-selected' : 'radio'}
                    key={slot.subsidiarySlotId}
                    id={slot.subsidiarySlotId}
                    type="radio"
                    checked={slot.subsidiarySlotId === subsidiarySlotId}
                    name="slot"
                    value={slot.subsidiarySlotId}
                    label={!slot.startSlot ? <T raw id={`Veloptim.noSlot`} /> : `${timeFromUTC(slot.startSlot)} - ${timeFromUTC(slot.endSlot)}`}
                    onChange={({ target: { value } }) => { setSubsidiarySlotId(Number(value)) }} 
                    inline
                  />)
                )}
              </FormGroup>
            </div>
            <div className="column">
              <TLabel id="VeloptimPlanModal.userTypology" className="mb-2" tagName="div" raw />
              {filters && filters.userTypology && [{ value: 0, label: "all" }].concat(filters.userTypology).map(typology => (
                <TCustomInput
                  className={userTypology === typology.value ? 'radio radio-selected' : 'radio'}
                  key={typology.label}
                  id={typology.label}
                  type="radio"
                  checked={userTypology === typology.value}
                  name="typology"
                  value={typology.value}
                  label={`VeloptimPlanModal.type.${typology.label}`}
                  raw
                  onChange={({ target: { value } }) => {
                    setUserTypology(Number(value))
                  }}
                  inline />
              ))}
            </div>
            <div className="column">
              <TLabel id="VeloptimPlanModal.vehicles" className="mb-2" tagName="div" raw />
              {filters && filters.vehicleType && [{ value: 0, label: 'allVehicle' }].concat(filters.vehicleType).map(vehicle => (
                <CustomInput
                  className={vehicle.value === vehicleType ? 'radio radio-selected' : 'radio'}
                  key={vehicle.label}
                  id={vehicle.label}
                  type="radio"
                  checked={vehicle.value === vehicleType}
                  name="vehicle"
                  value={vehicle.value}
                  label={vehicle.label ? <T id={`VehiclesTypeList.${vehicle.label}`} raw /> : <T raw id={`Veloptim.noVehicle`} />}
                  onChange={({ target: { value } }) => {
                    setVehicleType(Number(value)) }} 
                  inline
                />)
              )}
            </div>
            <div className="column ml-3">
              <TLabel id="VeloptimPlanModal.competences" className="mb-3" tagName="div" raw />
              <div className="d-flex flex-column mb-3">
                <TCustomInput
                  id="matchTypeIntervention"
                  raw
                  className="mb-2"
                  type="checkbox"
                  name="disable"
                  label="VeloptimPlanModal.matchTypeIntervention"
                  checked={matchTypeIntervention}
                  onChange={() => setMatchTypeIntervention(!matchTypeIntervention)}
                  inline />
                {typeIntervention === 'intervention' && <TCustomInput
                  id="matchItvcodes"
                  raw
                  className="mb-2"
                  type="checkbox"
                  name="disable"
                  label="VeloptimPlanModal.matchItvcodes"
                  checked={matchItvcodes}
                  onChange={() => setMatchItvcodes(!matchItvcodes)}
                  inline />}
                <TCustomInput
                  id="matchFamily"
                  raw
                  className="mb-2"
                  type="checkbox"
                  name="disable"
                  label="VeloptimPlanModal.matchFamily"
                  checked={matchFamily}
                  onChange={() => setMatchFamily(!matchFamily)}
                  inline />
              </div>
              <TLabel id="VeloptimPlanModal.zonage" className="mb-3" tagName="div" raw />
              <div className="d-flex flex-column mb-3">
                <TCustomInput
                  id="matchZonning"
                  raw
                  className="mb-2"
                  type="checkbox"
                  name="disable"
                  label="VeloptimPlanModal.matchZoning"
                  checked={matchZonning}
                  onChange={() => setMatchZonning(!matchZonning)}
                  inline />
                <TCustomInput
                  id="matchLimitItv"
                  raw
                  className="mb-2"
                  type="checkbox"
                  name="disable"
                  label="VeloptimPlanModal.matchLimitItv"
                  checked={matchLimitItv}
                  onChange={() => setMatchLimitItv(!matchLimitItv)}
                  inline />
              </div>
              {userTypology !== 2 && (<>
                <TLabel id="VeloptimPlanModal.calculateCloseItvLabel" className="mb-3" tagName="div" raw />
                <div className="d-flex align-items-center mb-3">
                  <TCustomInput
                    id="calculateCloseItv"
                    raw
                    className="mb-2 w-100"
                    type="checkbox"
                    name="disable"
                    label="VeloptimPlanModal.calculateCloseItv"
                    checked={calculateCloseItv}
                    onChange={() => setCalculateCloseItv(!calculateCloseItv)}
                    inline />
                </div>
              </>)}
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="footer">
          <div className="w-100 d-flex justify-content-between">
            <TButton id="VeloptimPlanModal.cancel" raw outline onClick={() => { onDismiss() }} />
            <TButton
              id="VeloptimPlanModal.search"
              raw
              onClick={() => {
                const props = { typeIntervention, id, itvcodes, familyId, mapId, durationPlanned, matchFamily, matchItvcodes, matchLimitItv, matchZonning, matchTypeIntervention, calculateCloseItv, vehicleType, userTypology, subsidiarySlotId, filters }
                const interventions = location && location.state && location.state.interventions
                const swaps = location && location.state && location.state.swaps
                if (onSearch) {
                  history.replace(`/${typeIntervention === 'intervention' ? `interventions` : `swaps`}/veloptim/${id}`, { props, interventions, swaps, isSearch: true })
                  onSearch()
                } else {
                  history.push(`/${typeIntervention === 'intervention' ? `interventions` : `swaps`}/veloptim/${id}`, { props, interventions, swaps, isSearch: true })
                }
                onDismiss()
              }}
            />
          </div>
        </ModalFooter>
      </Modal >
    </>
  )
}

export default VeloptimPlanModal