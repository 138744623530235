import React from 'react'
import {
  Card, CardHeader, CardBody, CardFooter,
  FormGroup, Input,
  Modal, ModalBody, ModalFooter,
  Spinner, NavLink,
  Row, Col
} from 'reactstrap'
import { Link } from 'react-router-dom'
import CustomSelect from '../../components/CustomSelect'
import SignatureCanvas from 'react-signature-canvas'

import '../Intervention/InterventionEdit.scss'

import { AppContext, } from 'contexts/AppContext'

import { T, ErrAlert, TAlert, TButton, TLabel, TCustomInput } from 'components/TComponents'
import Button from 'components/Button'

import ItvcodeSelector from 'components/ItvcodeSelector'

import { useIntl } from 'react-intl'

const SwapStart = ({ match, history }) => {
  const { api, subsidiary, user, SWAP_ANOMALY_TYPES, constants } = React.useContext(AppContext)
  const intl = useIntl()

  const signatureRef = React.useRef(null)
  const fileInput1 = React.useRef()
  const fileInput2 = React.useRef()
  const fileInput3 = React.useRef()
  const fileInputFrom = React.useRef()
  const fileInputTo = React.useRef()
  const fileInputAnly = React.useRef()

  const [swap, setSwap] = React.useState()
  const [swapLoading, setSwapLoading] = React.useState(true)
  const [swapError, setSwapError] = React.useState()
  const [baseSwap, setBaseSwap] = React.useState()

  const [techcenterBase, setTechcenterBase] = React.useState()
  const [techcenterError, setTechcenterError] = React.useState()
  const [techcenterLoading, setTechcenterLoading] = React.useState()

  const [itvcodesBase, setItvcodesBase] = React.useState()
  const [itvcodesLoading, setItvcodesLoading] = React.useState(true)
  const [itvcodesError, setItvcodesError] = React.useState()

  const [itvHourSelected, setItvHourSelected] = React.useState(0)
  const [itvMinuteSelected, setItvMinuteSelected] = React.useState(0)

  const [isUserAssigned, setIsUserAssigned] = React.useState()

  const [notAllowedError, setNotAllowedError] = React.useState()
  const [finishError, setFinishError] = React.useState()
  const [uploadError, setUploadError] = React.useState()
  const [uploadLoading, setUploadLoading] = React.useState()

  const [changeUserModal, setChangeUserModal] = React.useState(false)
  const [forceUserAssign, setForceUserAssign] = React.useState(false)

  const [itvQualif, setItvQualif] = React.useState(1)

  const [pictures, setPictures] = React.useState()
  const [checkoutComments, setCheckoutComments] = React.useState()
  const [checkoutSigned, setCheckoutSigned] = React.useState(true)
  const [signatureModal, setSignatureModal] = React.useState(false)

  const [anomalyModal, setAnomalyModal] = React.useState(false)
  const [reason, setReason] = React.useState(SWAP_ANOMALY_TYPES.wrongBike)
  const [anomalyInformation, setAnomalyInformation] = React.useState('')
  const [anomalyError, setAnomalyError] = React.useState()

  const [equipmentList, setEquipmentList] = React.useState()
  const [equipmentError, setEquipmentError] = React.useState()
  const [equipmentSelected, setEquipmentSelected] = React.useState()

  const [businessQualif, setBusinessQualif] = React.useState([])
  const [businessQualifError, setBusinessQualifError] = React.useState('')
  const [businessQualifLoading, setBusinessQualifLoading] = React.useState(true)

  const [lastDateDone, setLastDateDone] = React.useState()
  const [lastKm, setLastKm] = React.useState()
  const [kmModal, setKmModal] = React.useState(false)

  const [equipmentAlert, setEquipmentAlert] = React.useState()

  const {
    swapId,
    deskTicketId,
    equipmentBefore,
    claimNumber,

    statusValue,
    businessId, itvcodes,

    userAssignFirstname,
    userAssignLastname,
    userIdAssignedTo,

    datePlanned,
    durationEstimated,
    timeRangeStart,
    timeRangeEnd,
    information,
    addressLabel, address, postalCode, city,
    addressComplement,

    equipmentKm,
    itvLastKm,
    itvLastDateDone,
    swapLastKm,
    swapLastDateDone,
    techComments,

  } = swap || {}

  const itvHours = Array(9).fill().map((_, i) => i)
  const itvMinutes = Array(12).fill().map((_, i) => (i * 5))

  React.useEffect(() => {
    // Get Equipment Alerts
    if (equipmentBefore) {
      api.get('/equipments/alerts', undefined, {
        equipmentId: equipmentBefore.equipmentId,
      })
        .then(response => setEquipmentAlert(response))
    }
  }, [equipmentBefore, api])

  React.useEffect(() => {
    api.get('/swaps/details', undefined, { swapId: match.params.id })
      .then(response => {
        setSwap(response)
        setBaseSwap({ ...response })
        return api.get('/itvcodes/list', undefined, {
          ipp: -1,
          businessId: response.businessId,
          familyId: response.equipmentBefore.familyId,
          categoryId: response.equipmentBefore.categoryId
        })
          .then(response => {
            setItvcodesLoading(false)
            setItvcodesBase(response)
          })
          .catch(response => setItvcodesError(response))
      })
      .catch(response => setSwapError(response))
      .then(() => setSwapLoading(false))
  }, [api, match])

  React.useEffect(() => {
    if (baseSwap && subsidiary && subsidiary.companyId) {
      api.get('/techcenters/list', undefined, { companyId: subsidiary.companyId })
        .then(response => {
          setTechcenterBase(response.result)
        })
        .catch(error => {
          setTechcenterError(error)
        })
        .then(() => setTechcenterLoading(false))
      api.get('/companies/details', undefined, { companyId: subsidiary.companyId })
        .then(response => setSwap(swap => ({
          ...swap,
          techcenterId: response.techcenterId,
          partsOriginId: response.techcenterId
        })))
        .catch(error => setTechcenterError(error))
        .then(() => setTechcenterLoading(false))
    } else {
      api.get('/techcenters/list', undefined)
        .then(response => {
          setTechcenterBase(response.result)
        })
        .catch(error => {
          setTechcenterError(error)
        })
        .then(() => setTechcenterLoading(false))
    }
  }, [api, subsidiary, baseSwap])

  React.useEffect(() => {
    if (baseSwap && (baseSwap.statusValue !== "scheduled" && baseSwap.statusValue !== 'waitPec')) {
      setNotAllowedError('notAllowed')
    }
    if (baseSwap && user) {
      setIsUserAssigned(baseSwap.userIdAssignedTo === user.userId)
    }
  }, [baseSwap, user])

  React.useEffect(() => {
    {
      businessId && equipmentBefore && (
        api.get('/equipments/list', undefined, {
          ipp: -1,
          businessId: businessId,
          familyId: equipmentBefore.familyId,
          categoryId: equipmentBefore.categoryId,
          statusId: [3]
        })
          .then(response => setEquipmentList(response && response.result))
          .catch(error => setEquipmentError(error))
      )
    }
  }, [api, businessId, equipmentBefore])

  React.useEffect(() => {
    if (businessId !== undefined) {
      setBusinessQualifLoading(true)
      api.get('/interventions/qualif', undefined, { businessId })
        .then(response => setBusinessQualif(response))
        .catch(response => setBusinessQualifError(response))
        .then(() => setBusinessQualifLoading(false))
    }
  }, [api, businessId, setBusinessQualifError, setBusinessQualifLoading])

  React.useEffect(() => {
    setLastKm(((swapLastDateDone > itvLastDateDone) || !itvLastDateDone) ? swapLastKm : itvLastKm)
    setLastDateDone(((swapLastDateDone > itvLastDateDone) || !itvLastDateDone) ? swapLastDateDone : itvLastDateDone)
  }, [swapLastKm, swapLastDateDone, itvLastKm, itvLastDateDone])

  const handlePic = React.useCallback((e, name) => {
    if (swap) {
      const file = e.target.files[0]
      setUploadLoading(true)
      api.put('/swaps/pic', { body: file }, {
        swapId: swap.swapId,
        equipment: name
      })
        .then(response => {
          if (response.picUrl) {
            setPictures({ ...pictures, [name]: response.picUrl })
          }
        })
        .catch(error => {
          if (name === 'anly') {
            setAnomalyError(error.code)
          } else {
            setUploadError(error.code)
          }
        })
        .then(setUploadLoading(false))
    }
  }, [api, swap, pictures])

  const onItvcodesChange = React.useCallback((itvcodes) => {
    setSwap(swap => ({ ...swap, itvcodes: itvcodes }))
  }, [])

  const handleChange = React.useCallback((e) => {
    const { target: { name, value } } = e
    const newState = { [name]: value }
    setSwap(swap => ({
      ...swap,
      ...newState,
    }))
  }, [])

  const handleChangeTechcenterId = React.useCallback(techcenter => {
    setSwap(swap => ({
      ...swap,
      ...{ techcenterId: techcenter.techcenterId },
    }))
  }, [])

  const checkIfKmOk = React.useCallback(() => {
    return lastKm > equipmentKm ? false : true
  }, [lastKm, equipmentKm])

  const handleFinish = React.useCallback((forceKm = false) => {
    const checkKm = checkIfKmOk()
    if (!checkKm && !forceKm) {
      setKmModal(true)
    } else if (checkoutSigned && !(pictures && pictures.signUrl)) {
      setFinishError('signatureRequired')
    } else {
      api.post('/swaps/end', {
        _stringifyBody: true,
        body: {
          swapId: swap.swapId,
          dateStart: new Date().toISOString().replace(/\.[0-9]{3}/, ''),
          dateEnd: new Date().toISOString().replace(/\.[0-9]{3}/, ''),
          partsOriginId: swap.partsOriginId,
          durationDone: itvHourSelected * 60 + itvMinuteSelected,
          durationChrono: itvHourSelected * 60 + itvMinuteSelected,
          equipmentKm: swap.equipmentBefore.settings.trackKm ? parseInt(swap.equipmentKm) : 0,
          techComments: swap.techComments || '',
          itvcodes: swap.itvcodes.map(({ itvcodeId, quantity, wear, reasonId, reasonListSwapDisplay }) => ({
            itvcodeId,
            quantity,
            wear,
            ...(reasonId && reasonListSwapDisplay && { reasonId })
          })),
          itvQualif: itvQualif,
          techcenterId: swap.techcenterId,
          equipmentIdAfter: equipmentSelected && equipmentSelected.equipmentId,
          checkoutComments: checkoutComments,
          ...(pictures && pictures.pic1 && { pic1: pictures.pic1 }),
          ...(pictures && pictures.pic2 && { pic2: pictures.pic2 }),
          ...(pictures && pictures.pic3 && { pic3: pictures.pic3 }),
          checkoutSigned: checkoutSigned ? 1 : 0,
          forceUserAssign: forceUserAssign
        }
      }).then(() => {
        api.post('/swaps/tracking', {
          _stringifyBody: true,
          body: {
            swapId: swap.swapId,
            tracking: 0
          }
        })
        history.push('/swaps')
      }).catch(error => {
        setFinishError(error.code)
      })
    }
  }, [api, swap, itvHourSelected, itvMinuteSelected, history, forceUserAssign, pictures, checkoutComments, equipmentSelected, itvQualif, checkIfKmOk, checkoutSigned])

  const handleAnomaly = React.useCallback(() => {
    setAnomalyError()
    const body = {
      reason,
      information: anomalyInformation,
      swapId: swap.swapId,
      itvcodes: swap.itvcodes,
      techComments: swap.techComments,
      claimNumber: swap.claimNumber,
    }
    api.post('/swaps/anomaly', { body: JSON.stringify(body) })
      .then(() => {
        setAnomalyModal(!false)
        api.post('/swaps/tracking', {
          _stringifyBody: true,
          body: {
            swapId: swap.swapId,
            tracking: 0
          }
        })
        history.push(`/swaps/${swap.swapId}`)
      })
      .catch(error => setAnomalyError(error.code))
  }, [anomalyInformation, api, swap, reason, history])

  const saveSignature = React.useCallback(async path => {
    if (swap) {
      setUploadLoading(true)
      api.put('/swaps/pic', { body: path }, {
        swapId: swap.swapId,
        equipment: 'sign'
      })
        .then(response => {
          if (response.picUrl) {
            setPictures({ ...pictures, 'signUrl': response.picUrl })
          }
        })
        .catch(error => {
          setUploadError(error.code)
        })
        .then(setUploadLoading(false))
    }
  }, [swap, api, pictures])

  const handleSignature = React.useCallback(async signature => {
    if (!signature) {
      setPictures({ ...pictures, 'signUrl': null })
    } else {
      let byteString
      if (signature.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(signature.split(',')[1])
      else
        byteString = unescape(signature.split(',')[1])
      let mimeString = signature.split(',')[0].split(':')[1].split(';')[0];
      let ia = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      saveSignature(new Blob([ia], {type:mimeString}))
    }
  }, [saveSignature, pictures])

  const hasStatus = React.useCallback((...status) => status.includes(statusValue), [statusValue])

  const loading = swapLoading || techcenterLoading || itvcodesLoading
  const error = swapError || techcenterError || itvcodesError || notAllowedError

  if (swapError) {
    return <>
      <NavLink tag={Link} to={`/swaps/${swap.swapId}`}>
        <T id="returnToSwap" />
      </NavLink>
      <TAlert id="swapNotFound" className="mb-0" color="danger" />
    </>
  }

  if (!swapLoading && !equipmentBefore) {
    return <>
      <NavLink tag={Link} to={`/swaps/${swap.swapId}`}>
        <T id="returnToSwap" />
      </NavLink>
      <TAlert id="invalidSwap" className="mb-0" color="danger" />
    </>
  }

  if (loading) {
    return <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />
  }

  if (error) {
    return (
      <TAlert id={error} color="warning" />
    )
  }

  return (
    <div className="container-fluid InterventionStart">
      <NavLink tag={Link} to={`/swaps/${swap.swapId}`}>
        <T id="returnToSwap" />
      </NavLink>
      {swap && (
        <>
          <Card>
            <CardHeader className={`difficulty ${baseSwap.difficultyValue}`}>
              <div className="ml-2">
                <div className="h4">
                  <T id="equipmentBeforeId" />
                  <NavLink
                    tag={Link}
                    to={`/equipments/${equipmentBefore.equipmentId}`}
                    className="p-0 d-inline">
                    {equipmentBefore.equipmentIdentifier}
                  </NavLink>
                  <small className="ml-2">{equipmentBefore.categoryName}</small>
                  <small className="ml-2">{equipmentBefore.familyName}</small>
                </div>
                <div className="h6 mb-0">
                  <span><T id="swapId" />{swapId}</span>
                  <small className="ml-2">
                    {deskTicketId ?
                      <Link to={`/desk/${deskTicketId}`}><T id="deskTicketId" />{deskTicketId}</Link> :
                      <><T id="deskTicketId" /><T id="noTicketId" /></>}
                  </small>
                </div>
              </div>
            </CardHeader>

            <CardBody>
              {equipmentAlert && (
                <div className="mb-3 pt-2">
                  {!!equipmentAlert.nbInfo && <TAlert color="info" id="nbInfo" values={{ value: equipmentAlert.nbInfo }} />}
                  {!!equipmentAlert.nbMinor && <TAlert color="warning" id="nbMinor" values={{ value: equipmentAlert.nbMinor }} />}
                  {!!equipmentAlert.nbMajor && <TAlert color="danger" id="nbMajor" values={{ value: equipmentAlert.nbMajor }} />}
                  {(!!equipmentAlert.nbInfo || !!equipmentAlert.nbMinor || !!equipmentAlert.nbMajor) && equipmentBefore && (
                    <TButton id="goToEquipmentLog"
                      tag={Link}
                      to={{
                        pathname: `/equipments/${equipmentBefore.equipmentId}/logs`
                      }}
                      color="primary" />
                  )}
                </div>
              )}
              {!isUserAssigned && !hasStatus('waitPec') && (
                <TAlert id="notUserAssigned" color="warning" />
              )}

              <FormGroup tag="fieldset">
                <TLabel className="mb-0" id="statusValue" />
                <T id="interventionStatus.started" className="pl-1" />
              </FormGroup>

              {userAssignFirstname && userAssignLastname && (
                <FormGroup tag="fieldset">
                  <TLabel className="mb-0" id="userAssign" />
                  {swapLoading
                    ? <Spinner className="ml-2" color="primary" size="sm" />
                    :
                    <>
                      {forceUserAssign ? (
                        <span>{`${user.firstName} ${user.lastName}`}</span>
                      ) : (
                        <span>{`${userAssignFirstname} ${userAssignLastname}`}</span>
                      )}
                    </>
                  }
                  {user && userIdAssignedTo !== user.userId && !forceUserAssign && (
                    <TButton className="ml-4" id="assignToMe" onClick={() => setChangeUserModal(true)} />
                  )}
                </FormGroup>
              )}

              <FormGroup tag="fieldset">
                <TLabel className="mb-0" id="datePlanned.label" />
                <T id="datePlanned.value"
                  values={{
                    datePlanned: datePlanned ? new Date(datePlanned) : null,
                    timeStart: timeRangeStart ? new Date().setHours(0, Math.min(timeRangeStart), 0, 0) : undefined,
                    timeEnd: timeRangeStart ? new Date().setHours(0, Math.max(timeRangeEnd), 0, 0) : undefined
                  }}
                  className="pl-1" />
              </FormGroup>

              <TLabel id="itvcodeList" />

              {itvcodesError ? <ErrAlert error={itvcodesError} /> : itvcodesBase && itvcodes && <ItvcodeSelector
                hasBusiness={Boolean(businessId)}
                loading={swapLoading || itvcodesLoading}
                editable
                itvcodesAdded={itvcodes}
                itvcodes={itvcodesBase.result}
                reason
                isSwap
                onChange={itvcodes => onItvcodesChange(itvcodes)} />}

              {swap && swap.equipmentBefore && swap.equipmentBefore.parts && swap.equipmentBefore.parts.length > 0 &&
                                <>
                                  <TLabel id="pieces" />
                                  <Card className="mb-4">
                                    <CardBody className="pb-3">
                                      {swap.equipmentBefore.parts.map(piece =>
                                        <div className="mb-4" key={piece.partName}>
                                          <T id={piece.partName} className="d-block" raw />
                                          <span className="d-block"><T id="piece.equipmentPartKm" />{piece.equipmentPartKm}</span>
                                          <span className="d-block"><T id="piece.partSN" />{piece.partSN}</span>
                                        </div>
                                      )}
                                    </CardBody>
                                  </Card>
                                </>
              }

              <FormGroup tag="fieldset">

                <TLabel id="durations" />
                {/* Display /60 hour */}
                <TLabel
                  id="durationEstimated"
                  values={{
                    hours: Math.floor(durationEstimated / 60),
                    minutes: durationEstimated % 60,
                  }}
                  className="col mb-0" />
              </FormGroup>

              <FormGroup tag="fieldset">
                <TLabel id="elapsedTime" />
                <Row>
                  <Col>
                    <TLabel id="selectHour" for="selectHour" />
                    <CustomSelect
                      inputId="selectHour"
                      name="selectHour"
                      options={itvHours}
                      placeholder={"0"}
                      onChange={e => setItvHourSelected(e)}
                      value={itvHours.filter(h => h == itvHourSelected)}
                      getOptionValue={option => option}
                      getOptionLabel={option => option}
                      isDisabled={loading} />
                  </Col>
                  <Col>
                    <TLabel id="selectMinute" for="selectMinute" />
                    <CustomSelect
                      inputId="selectMinute"
                      name="selectMinute"
                      options={itvMinutes}
                      placeholder={"0"}
                      onChange={e => setItvMinuteSelected(e)}
                      value={itvMinutes.filter(h => h == itvMinuteSelected)}
                      getOptionValue={option => option}
                      getOptionLabel={option => option}
                      isDisabled={loading} />
                  </Col>
                </Row>
              </FormGroup>


              <FormGroup tag="fieldset">
                <TLabel id="claimNumber" />
                <Input type="text" name="claimNumber" id="claimNumber"
                  disabled={loading}
                  value={claimNumber || ''}
                  onChange={handleChange} />
              </FormGroup>

              <FormGroup tag="fieldset">
                <TLabel id="information" />
                {information ? (
                  <span>{information}</span>
                ) : (
                  <T id="noInformation" />
                )}
              </FormGroup>

              <TLabel id="addressTitle" />
              <div>
                <p>
                  {addressLabel && addressLabel}
                  {addressLabel && address && ' - '}
                  {address && address}
                  {postalCode && ` ${postalCode}`}
                  {city && ` ${city}`}
                  {addressComplement && ` ${addressComplement}`}
                  {!addressLabel && <T id="addressNotFound" />}
                </p>
              </div>

              {swap.locationValue &&
                                <FormGroup tag="fieldset">
                                  <TLabel id="locationId" for="locationId" />
                                  <T id={`locationId.${swap.locationValue}`} />
                                </FormGroup>
              }

              <FormGroup tag="fieldset" >
                <TLabel className="mb-0" id="lastKm" />
                {lastKm ? lastKm : <T id="noLastKm" />}
              </FormGroup>
              <FormGroup tag="fieldset" >
                <TLabel className="mb-0" id="lastKmDate" />
                {lastDateDone && lastKm ? <T id="lastKmDateValue" values={{ date: intl.formatDate(lastDateDone) }} />
                  : <T id="noLastKmDate" />}
              </FormGroup>

              <FormGroup tag="fieldset" >
                <TLabel className="mb-0" id="equipmentKm.label" />
                <Input name="equipmentKm"
                  type="number"
                  value={equipmentKm || ''}
                  disabled={loading}
                  onChange={e => {
                    handleChange(e)
                  }} />
              </FormGroup>

              <FormGroup className="my-4">
                <TLabel id="techComments" />
                <Input type="textarea" name="techComments" id="techComments"
                  value={techComments || ''}
                  disabled={loading}
                  onChange={e => handleChange(e)} />
              </FormGroup>

              <FormGroup tag="fieldset">
                <TLabel id="contactFull" />
                {swap.contactFull ? (
                  <>
                    <span>{swap.contactFull}</span>
                    <>
                      {swap.phoneNumber && (
                        <span>{swap.phoneNumber}</span>
                      )}
                    </>
                  </>
                ) : (
                  <T id="contactNotFound" />
                )}
              </FormGroup>

              <FormGroup tag="fieldset">
                <TLabel id="itvQualif" for="itvQualif" />
                <div>
                  {businessQualifLoading && <Spinner className="" color="primary" />}
                  {!businessQualifLoading && businessQualifError && <ErrAlert error={businessQualifError} />}
                  {!businessQualifLoading && !businessQualifError && (
                    <>
                      {constants.interventionQualif.map(qualif =>
                        <React.Fragment key={qualif.key}>
                          {businessQualif.find(bq => bq.key === qualif.key).enabled && (
                            <FormGroup key={qualif.key} check inline>
                              <TCustomInput
                                id={`itvQualif-${qualif.key}`}
                                type="radio"
                                name="itvQualif"
                                label={`qualif.${qualif.key}`}
                                disabled={loading}
                                checked={itvQualif == qualif.value}
                                value={qualif.value}
                                onChange={e => setItvQualif(e.target.value)} />
                            </FormGroup>
                          )}
                        </React.Fragment>
                      )}
                    </>
                  )}
                </div>
              </FormGroup>
            </CardBody>
          </Card>
          <Card>
            <Card>
              <CardBody>
                <TLabel className="mb-4 d-block" id="newEquipment" />
                <FormGroup tag="fieldset" className="my-4">
                  {equipmentError && <TAlert id={equipmentError} color="warning" />}
                  {!equipmentError && (
                    <>
                      <TLabel id="equipmentAfter" />
                      <CustomSelect
                        inputId="equipmentAfter"
                        name="equipmentAfter"
                        onChange={e => setEquipmentSelected(e)}
                        isDisabled={loading}
                        options={equipmentList}
                        getOptionLabel={option => option.equipmentIdentifier}
                        getOptionValue={option => option.equipmentIdentifier}
                        value={equipmentSelected && equipmentList && equipmentList.length > 0 && equipmentList.filter(e => e.equipmentIdentifier === equipmentSelected.equipmentIdentifier)} />
                    </>
                  )}
                </FormGroup>
                <TLabel className="mb-2 mt-2" id="from" />
                <FormGroup tag="fieldset">
                  <input ref={fileInputFrom} type="file" name="from" accept="application/png, application/jpeg" onChange={e => { handlePic(e, "from") }} hidden />
                  <div className="d-flex flex-column">
                    {pictures && pictures.from && (
                      <img className="mb-2" src={pictures.from} style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }} />
                    )}
                    <TButton className="mr-auto" id="upload" onClick={() => fileInputFrom.current.click()} disabled={uploadLoading} />
                  </div>
                </FormGroup>
                <TLabel className="mb-2 mt-2" id="to" />
                <FormGroup tag="fieldset">
                  <input ref={fileInputTo} type="file" name="to" accept="application/png, application/jpeg" onChange={e => { handlePic(e, "to") }} hidden />
                  <div className="d-flex flex-column">
                    {pictures && pictures.to && (
                      <img className="mb-2" src={pictures.to} style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }} />
                    )}
                    <TButton className="mr-auto" id="upload" onClick={() => fileInputTo.current.click()} disabled={uploadLoading} />
                  </div>
                </FormGroup>
                {techcenterBase && swap && <FormGroup tag="fieldset" className="my-4">
                  <TLabel id="techcenterId" />
                  <CustomSelect
                    inputId="techcenterId"
                    name="techecenterId"
                    onChange={e => handleChangeTechcenterId(e)}
                    isDisabled={loading}
                    options={techcenterBase}
                    getOptionLabel={option => option.techcenterName}
                    getOptionValue={option => option.techcenterId}
                    value={techcenterBase.filter(t => t.techcenterId === swap.techcenterId)} />
                </FormGroup>}
              </CardBody>
              {uploadError && <TAlert id={uploadError} color="warning" />}
            </Card>
            <Card>
              <CardBody>
                <TLabel className="mb-4 d-block" id="checkout" />
                <TLabel className="mb-2 mt-2" id="pic1" />
                <FormGroup tag="fieldset">
                  <input ref={fileInput1} type="file" name="pic1" accept="application/png, application/jpeg" onChange={e => { handlePic(e, "pic1") }} hidden />
                  <div className="d-flex flex-column">
                    {pictures && pictures.pic1 && (
                      <img className="mb-2" src={pictures.pic1} style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }} />
                    )}
                    <TButton className="mr-auto" id="upload" onClick={() => fileInput1.current.click()} disabled={uploadLoading} />
                  </div>
                </FormGroup>
                <TLabel className="mb-2 mt-2" id="pic2" />
                <FormGroup tag="fieldset">
                  <input ref={fileInput2} type="file" name="pic2" accept="application/png, application/jpeg" onChange={e => { handlePic(e, "pic2") }} hidden />
                  <div className="d-flex flex-column">
                    {pictures && pictures.pic2 && (
                      <img className="mb-2" src={pictures.pic2} style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }} />
                    )}
                    <TButton className="mr-auto" id="upload" onClick={() => fileInput2.current.click()} disabled={uploadLoading} />
                  </div>
                </FormGroup>
                <TLabel className="mb-2 mt-2" id="pic3" />
                <FormGroup tag="fieldset">
                  <input ref={fileInput3} type="file" name="pic3" accept="application/png, application/jpeg" onChange={e => { handlePic(e, "pic3") }} hidden />
                  <div className="d-flex flex-column">
                    {pictures && pictures.pic3 && (
                      <img className="mb-2" src={pictures.pic3} style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }} />
                    )}
                    <TButton className="mr-auto" id="upload" onClick={() => fileInput3.current.click()} disabled={uploadLoading} />
                  </div>
                </FormGroup>
                <TLabel className="mb-2 mt-2" id="checkoutComments" />
                <FormGroup tag="fieldset">
                  <Input
                    type="textarea"
                    name="checkoutComments"
                    id="checkoutComments"
                    value={checkoutComments || ''}
                    disabled={loading}
                    onChange={e => setCheckoutComments(e.target.value)} />
                </FormGroup>
                <FormGroup tag="fieldset">
                  <div>
                    <TCustomInput
                      id="checkoutSigned"
                      className="mb-2"
                      type="checkbox"
                      name="disable"
                      label="checkoutSigned"
                      checked={!checkoutSigned}
                      onChange={() => setCheckoutSigned(!checkoutSigned)}
                      inline />
                  </div>
                  {checkoutSigned && (
                    <div>
                      {pictures && pictures.signUrl && (
                        <img className="mb-3 mt-2 d-block" src={pictures.signUrl} style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }} />
                      )}
                      <TButton id="sign" onClick={() => { setSignatureModal(true)} }/>
                    </div>
                  )}
                </FormGroup>
              </CardBody>
              {uploadError && <TAlert id={uploadError} color="warning" />}
            </Card>
            {finishError && <TAlert id={`error.${finishError}`} className="mt-4" color="danger" />}
            <CardFooter className="d-flex">
              <div className="ml-auto">
                <TButton className="mx-1" disabled={loading}
                  id="anomaly"
                  onClick={() => { setAnomalyModal(true) }}
                  color="warning" />
                <TButton className="mx-1" disabled={loading}
                  id="finish"
                  onClick={() => { handleFinish() }}
                  color="primary" />
              </div>
            </CardFooter>
          </Card >
          <Modal isOpen={anomalyModal} fade={true} toggle={() => setAnomalyModal(!anomalyModal)}>
            <ModalBody>
              <T id="anomaly" className="d-block mb-3 h6" />
              <FormGroup tag="fieldset">
                <TLabel id="reason.title" className="d-block" />
                {Object.entries(SWAP_ANOMALY_TYPES).map(([anomaly, value]) => (
                  <TCustomInput
                    key={anomaly}
                    id={anomaly}
                    type="radio"
                    name="anomaly"
                    checked={reason == value}
                    label={`anomaly.${anomaly}`}
                    onChange={() => setReason(value)} />
                ))}
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="anomalyInformation" />
                <Input
                  type="textarea"
                  rows="8"
                  value={anomalyInformation}
                  onChange={e => setAnomalyInformation(e.target.value)}
                  multiline
                />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel className="mb-2 mt-2" id="anly" />
                <input ref={fileInputAnly} type="file" name="anly" accept="application/png, application/jpeg" onChange={e => { handlePic(e, "anly") }} hidden />
                <div className="d-flex flex-column">
                  {pictures && pictures.anly && (
                    <img className="mb-2" src={pictures.anly} style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }} />
                  )}
                  <TButton className="mr-auto" id="upload" onClick={() => fileInputAnly.current.click()} disabled={uploadLoading} />
                </div>
              </FormGroup>
              {anomalyError && <TAlert id={anomalyError} color="danger" />}
            </ModalBody>
            <ModalFooter>
              <Button
                className="ml-2" onClick={() => {
                  setAnomalyModal(!anomalyModal)
                }}>
                <T id="cancel" color="warning" />
              </Button>
              <Button
                onClick={() => handleAnomaly()} color="success">
                <T id="confirm" />
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={changeUserModal} fade={true} toggle={() => setChangeUserModal(!changeUserModal)}>
            <ModalBody>
              <div className="mb-1"><T id="modal.changeUser" /></div>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  setForceUserAssign(true)
                  setChangeUserModal(!changeUserModal)
                  setIsUserAssigned(true)
                }}>
                <T raw id="yes" />
              </Button>
              <Button
                className="ml-2" onClick={() => {
                  setForceUserAssign(false)
                  setChangeUserModal(!changeUserModal)
                }}>
                <T raw id="no" />
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={kmModal} fade={true} toggle={() => setKmModal(!kmModal)}>
            <ModalBody>
              <div className="mb-1"><T id="modal.km" /></div>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  handleFinish(true)
                  setKmModal(!kmModal)
                }}>
                <T raw id="yes" />
              </Button>
              <Button
                className="ml-2" onClick={() => {
                  setKmModal(!kmModal)
                }}>
                <T raw id="no" />
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={signatureModal} fade={true} toggle={() => setSignatureModal(!signatureModal)}>
            <ModalBody>
              <TLabel id="modal.signature" className="mb-3" />
              <div style={{ border: '1px solid darkgray', marginTop: 6, height: 200, width: '100%' }}>
                <SignatureCanvas
                  ref={signatureRef}
                  canvasProps={{width: 444, height: 200, className: 'sigCanvas'}} />
              </div>
              <T id="signature.description" className="d-block mt-3" />
            </ModalBody>
            <ModalFooter className="justify-content-between">
              <div className="d-flex">
                <TButton
                  className="mr-2"
                  id="signature.cancel"
                  color="warning"
                  onClick={() => { setSignatureModal(false) }} />
                <TButton
                  id="signature.clear"
                  color="danger"
                  onClick={() => { 
                    handleSignature()
                    signatureRef.current.clear() 
                  }} />
              </div>
              <div>
                <TButton
                  id="signature.confirm"
                  onClick={() => { 
                    handleSignature(signatureRef.current.getTrimmedCanvas().toDataURL('image/png'))
                    setSignatureModal(false)
                  }}/>
              </div>
            </ModalFooter>
          </Modal>
        </>
      )}
    </div >
  )
}

export default SwapStart
