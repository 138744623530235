import React from 'react'
import {
  Card, CardBody, CardFooter,
  FormGroup, Input
} from 'reactstrap'
import { useHistory, Link } from 'react-router-dom'

import { AppContext } from 'contexts/AppContext'

import { TLabel, TButton, ErrAlert, TNavLink } from 'components/TComponents'

const ShopNew = () => {

  const { api } = React.useContext(AppContext)
  const history = useHistory()
  const [warehouseRef, setWarehouseRef] = React.useState('')
  const [warehouseLabel, setWarehouseLabel] = React.useState('')

  const [createError, setCreateError] = React.useState(false)
  const [createLoading, setCreateLoading] = React.useState(false)

  const postWarehouse = React.useCallback(() => {
    setCreateError(false)
    setCreateLoading(true)
    api.post('/warehouses/details', {
      body: JSON.stringify({
        warehouseRef, warehouseLabel
      })
    })
      .then(response => {
        if (response.warehouseId) {
          history.push('/veloparts/shops')
        }
      })
      .catch(error => setCreateError(error))
      .then(() => setCreateLoading(false))
  }, [api, warehouseRef, warehouseLabel, history])

  return (
    <>
      <TNavLink id="returnToList" tag={Link} to="/veloparts/shops" />
      <Card>
        <CardBody>
          <FormGroup tag="fieldset">
            <TLabel for="warehouseRef" id="warehouseRef" />
            <Input id="warehouseRef"
              type="text"
              name="warehouseRef"
              value={warehouseRef}
              onChange={e => { setWarehouseRef(e.target.value) }}
            />
          </FormGroup>
          <FormGroup tag="fieldset">
            <TLabel for="warehouseLabel" id="warehouseLabel" />
            <Input id="warehouseLabel"
              type="text"
              name="warehouseLabel"
              value={warehouseLabel}
              onChange={e => { setWarehouseLabel(e.target.value) }}
            />
          </FormGroup>
          {createError && <ErrAlert error={createError} />}
        </CardBody>
        <CardFooter className="d-flex justify-content-end">
          <TButton
            className="ml-2"
            disabled={createLoading}
            spin={createLoading}
            onClick={postWarehouse}
            id="add" />
        </CardFooter>
      </Card>
    </>
  )
}

export default ShopNew
