import React from 'react'

import {
  Alert,
  Card, CardHeader, CardBody, CardFooter,
  FormGroup, Input,
  ListGroup, ListGroupItem,
  Modal, ModalBody, ModalFooter,
  Spinner, NavLink,
} from 'reactstrap'
import { Link } from 'react-router-dom'

import { AppContext, } from 'contexts/AppContext'

import CustomSelect from '../../components/CustomSelect'
import { T, TAutocomplete, ErrAlert, TAlert, TLabel, TInput, TButton } from 'components/TComponents'

import ItvcodeSelector from 'components/ItvcodeSelector'

import ProtectedComponent from 'components/ProtectedComponent'

const maintenanceReducer = (state, action) => {
  switch (action.type) {
  case 'update':
  case 'cancel':
  case 'reprog':
  case 'transform':
  case 'resume':
  case 'close': return {
    ...state,
    loading: true,
    status: action.type,
    error: undefined,
    prevent: action.clearPrevent ? undefined : state.prevent
  }
  case 'ready': return {
    status: action.type,
    loading: false,
    baseData: action.payload,
    currentData: { ...action.payload },
    error: undefined,
    prevent: undefined
  }
  case 'prevent': return {
    ...state,
    loading: false,
    status: 'ready',
    prevent: action.payload
  }
  case 'edit': return {
    ...state,
    loading: false,
    currentData: {
      ...state.currentData,
      ...action.payload
    }
  }
  case 'reset': return {
    status: 'ready',
    loading: false,
    baseData: state.baseData,
    currentData: { ...state.baseData },
    error: undefined,
    prevent: state.prevent
  }
  case 'error': return {
    ...state,
    loading: false,
    status: state.status,
    error: action.payload
  }
  default: return state
  }
}

const maintenanceInitialState = {
  status: 'fetch',
  loading: true,
  currentData: undefined,
  baseData: undefined,
  error: undefined,
  prevent: undefined
}

const MaintenanceEdit = ({ match, history, location }) => {
  const { api, constants } = React.useContext(AppContext)

  const [autocomplete, setAutocomplete] = React.useState()

  const [maintenanceState, maintenanceDispatch] = React.useReducer(maintenanceReducer, maintenanceInitialState)

  const [addresses, setAddresses] = React.useState()
  const [addressesLoading, setAddressesLoading] = React.useState(true)
  const [addressesError, setAddressesError] = React.useState()

  const [itvcodesBase, setItvcodesBase] = React.useState([])
  const [itvcodesLoading, setItvcodesLoading] = React.useState(true)
  const [itvcodesError, setItvcodesError] = React.useState()

  const [confirmCancel, setConfirmCancel] = React.useState(false)
  const [cancelationReasonTemp, setCancelationReasonTemp] = React.useState('')

  const [durationEstimated, setDurationEstimated] = React.useState()

  const {
    maintenanceplanId,

    statusValue,
    userCanceled, cancelationReason,
    businessId, itvcodes,
    userAssignFirstname, userAssignLastname,
    addressLocationLabel,
    businessName,
    categoryName,
    familyName,

    datePlanned,
    durationPlanned,
    durationChrono,
    durationDone,
    timeRangeStart,
    timeRangeEnd,
    difficultyId,
    information,

    selectedAddress,
    addressLabel,
    addressComplement,
    addressFull,

    interventions,
    masterplanId
  } = maintenanceState.currentData || {}

  const hasStatus = React.useCallback((...status) => status.includes(statusValue), [statusValue])

  const canEdit = hasStatus('new', 'scheduled') && !loading

  React.useEffect(() => {
    api.get('/maintenanceplans/details', undefined, { maintenanceplanId: match.params.id })
      .then(response => {
        maintenanceDispatch({ type: 'ready', payload: response })
        response.itvcodes.map((codes, i) => {
          return api.get('/itvcodes/list', undefined, {
            ipp: -1,
            businessId: response.businessId,
            familyId: codes.familyId,
            categoryId: codes.categoryId
          })
            .then(response => {
              setItvcodesBase(itvcodesBase => ({
                ...itvcodesBase,
                [i]: response
              }))
            })
            .catch(response => setItvcodesError(response))
            .then(() => setItvcodesLoading(false))
        })
      })
      .catch(response => maintenanceDispatch({ type: 'error', payload: response }))
  }, [api, match])

  React.useEffect(() => {
    if (businessId) {
      api.get('/swaps/addresses', undefined, { businessId })
        .then(addresses => setAddresses(addresses))
        .catch(error => setAddressesError(error))
        .then(() => setAddressesLoading(false))
    }
  }, [api, businessId])

  React.useEffect(() => {
    if (itvcodes && Object.values(itvcodes).length > 0) {
      {Object.values(itvcodes).map((codes, i) => {
        const body = {
          itvcodes: codes.itvcodes.map(itvcode => ({
            itvcodeId: itvcode.itvcodeId,
            quantity: itvcode.quantity,
          }))
        }
        api.post('/interventions/timeItvcodes', { body: JSON.stringify(body) })
          .then(response => {
            setDurationEstimated(duration => ({
              ...duration,
              [i]: response.durationEstimated
            }))
          })
      })}
    } else {
      setDurationEstimated({})
    }
  }, [api, itvcodes])

  const onItvcodesChange = React.useCallback((itvcodes) => {
    maintenanceDispatch({ type: 'edit', payload: { itvcodes } })
  }  , [])

  const addressHandleChange = React.useCallback((e) => {
    const addressId = e ? e.addressId : undefined

    const addressSelected = addressId
      ? addresses.find(address => String(address.addressId) === String(addressId))
      : undefined

    maintenanceDispatch({
      type: 'edit', payload: {
        selectedAddress: addressId,
        addressLabel: addressSelected ? addressSelected.addressLabel : '',
        addressComplement: addressSelected ? addressSelected.addressComplement : '',
        addressFull: addressSelected ? addressSelected.addressFull : '',
        mapId: addressSelected ? addressSelected.mapId : ''
      }
    })
  }, [addresses])

  const handleChangeAddressFull = React.useCallback(e => {
    e.persist()
    maintenanceDispatch({
      type: 'edit', payload: {
        addressFull: e.target.value,
        mapId: undefined
      }
    })
    api.get('/maps/autocomplete', undefined, {
      input: e.target.value,
      ...(autocomplete && autocomplete.session_token && { session_token: autocomplete.session_token })
    })
      .then(response => {
        setAutocomplete(response)
      })
      .catch(error => console.log(error))
  }, [api, autocomplete])

  const handleAutocomplete = React.useCallback(e => {
    maintenanceDispatch({
      type: 'edit', payload: {
        addressFull: e,
      }
    })
    api.get('/maps/placedetails', undefined, {
      place_id: autocomplete.results.find(r => r.addressFull === e).place_id,
      session_token: autocomplete.session_token,
      addressFull: e
    })
      .then(response => {
        maintenanceDispatch({
          type: 'edit', payload: {
            mapId: response.mapId,
          }
        })
      })
      .catch(error => console.log(error))
  }, [api, autocomplete])

  const handleChange = React.useCallback(({ target: { name, value } }) =>
    maintenanceDispatch({ type: 'edit', payload: { [name]: value } })
  , [])

  const save = React.useCallback((override) => {
    return api.post('/maintenanceplans/details', {
      _stringifyBody: true,
      body: {
        maintenanceplanId,
        itvcodes: canEdit ? Object.values(maintenanceState.currentData.itvcodes).map((codes, i) => ({
          itvcodes: codes.itvcodes.map(itvcode => ({
            itvcodeId: itvcode.itvcodeId,
            quantity: itvcode.quantity,
            wear: itvcode.wear
          })),
          categoryId:maintenanceState.currentData.itvcodes[i].categoryId ,
          familyId: maintenanceState.currentData.itvcodes[i].familyId
        })) : undefined,
        information: canEdit ? maintenanceState.currentData.information : undefined,
        difficultyId: canEdit ? maintenanceState.currentData.difficultyId : undefined,
        locationId: canEdit ? maintenanceState.currentData.locationId : undefined,
        addressLabel: canEdit ? maintenanceState.currentData.addressLabel : undefined,
        addressComplement: canEdit ? maintenanceState.currentData.addressComplement : undefined,
        mapId: canEdit ? maintenanceState.currentData.mapId : undefined,
        ...override
      }
    })
  }, [api, maintenanceplanId, canEdit, maintenanceState.currentData])

  const handleSave = React.useCallback(override => {
    maintenanceDispatch({ type: 'update' })
    Promise.all([
      save(override),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([response]) => maintenanceDispatch({ type: 'ready', payload: response }))
      .catch(response =>
        response && response.code === 'preventUpdate'
          ? maintenanceDispatch({ type: 'prevent', payload: { ...response.params, callback: handleSave } })
          : maintenanceDispatch({ type: 'error', payload: response })
      )
      .then(([response]) => maintenanceDispatch({ type: 'ready', payload: response }))
  }, [save])

  const handleSaveAndPlan = React.useCallback(override => {
    maintenanceDispatch({ type: 'update' })
    save(override)
      .then(() => history.push(`/maintenance/plan/${match.params.id}`))
      .catch(response => {
        response && response.code === 'preventUpdate'
          ? maintenanceDispatch({ type: 'prevent', payload: { ...response.params, callback: handleSaveAndPlan } })
          : maintenanceDispatch({ type: 'error', payload: response })
      })
  }, [history, match, save])

  const handleStart = React.useCallback(override => {
    maintenanceDispatch({ type: 'update' })
    save(override)
      .then(() => history.push(`/maintenance/start/${match.params.id}`))
      .catch(response => {
        response && response.code === 'preventUpdate'
          ? maintenanceDispatch({ type: 'prevent', payload: { ...response.params, callback: handleStart } })
          : maintenanceDispatch({ type: 'error', payload: response })
      })
  }, [history, match, save])

  const handleCancel = React.useCallback(() => {
    maintenanceDispatch({ type: 'cancel' })

    api.del('/maintenanceplans/details', undefined, { maintenanceplanId: match.params.id, cancelationReason: cancelationReasonTemp })
      .then(response => {
        maintenanceDispatch({ type: 'ready', payload: response })
        setConfirmCancel(false)
      })
      .catch(response => maintenanceDispatch({ type: 'error', payload: response }))
  }, [api, match, cancelationReasonTemp])

  const loading = maintenanceState.status !== 'ready' || itvcodesLoading

  if (maintenanceState.status === 'fetch' && maintenanceState.error) {
    return <>
      <NavLink tag={Link} to={{
        pathname: '/maintenance',
        search: location.state ? location.state.swaps : undefined
      }}>
        <T id="returnToList" />
      </NavLink>
      <TAlert id="maintenanceNotFound" className="mb-0" color="danger" />
    </>
  }

  return (
    <div className="container-fluid InterventionEdit">
      <NavLink tag={Link} to={{
        pathname: '/maintenance',
        search: location.state ? location.state.maintenance : undefined
      }}>
        <T id="returnToList" />
      </NavLink>
      <Card>
        {/* TODO: loading */}
        {maintenanceState.status !== 'fetch' && <CardHeader>
          <div className="ml-2">
            <div className="h4">
              <T id="equipments" />
              <small className="ml-2">{businessName}</small>
              <small className="ml-2">{categoryName}</small>
              <small className="ml-2">{familyName}</small>
            </div>
            <div className="h6 mb-2">
              <span><T id="maintenanceId" />{` ${maintenanceplanId}`}</span>
            </div>
            {masterplanId && (
              <div className="h6 mb-0">
                <span>
                  <T id="masterplanId" />
                  <Link to={`/maintenanceplan/${masterplanId}`}>#{masterplanId}</Link>
                </span>
              </div>
            )}
          </div>
        </CardHeader>}
        {maintenanceState.status === 'fetch' && <CardHeader className="d-flex align-items-center">
          <T id="loading" />
          <Spinner className="ml-2" color="primary" size="sm" />
        </CardHeader>}

        <CardBody>
          <FormGroup tag="fieldset">
            <TLabel className="mb-0" id="statusValue" />
            {maintenanceState.status === 'fetch'
              ? <Spinner className="ml-2" color="primary" size="sm" />
              : <T raw id={`maintenanceStatus.${statusValue}`} className="pl-1" />}
          </FormGroup>

          {userAssignFirstname && userAssignLastname && (
            <FormGroup tag="fieldset">
              <TLabel className="mb-0" id="userAssign" />
              {maintenanceState.status === 'fetch'
                ? <Spinner className="ml-2" color="primary" size="sm" />
                : <span>{`${userAssignFirstname} ${userAssignLastname}`}</span>}
            </FormGroup>
          )}

          <FormGroup tag="fieldset">
            <TLabel className="mb-0" id="datePlanned.label" />
            {maintenanceState.status === 'fetch'
              ? <Spinner className="ml-2" color="primary" size="sm" />
              : <T id="datePlanned.value"
                values={{
                  datePlanned: datePlanned ? new Date(datePlanned) : null,
                  timeStart: timeRangeStart ? new Date().setHours(0, Math.min(timeRangeStart), 0, 0) : undefined,
                  timeEnd: timeRangeStart ? new Date().setHours(0, Math.max(timeRangeEnd), 0, 0) : undefined
                }}
                className="pl-1" />}
          </FormGroup>

          <FormGroup tag="fieldset">
            <TLabel className="mb-0"  id="location" />
            {addressLocationLabel ? (
              <span>{addressLocationLabel}</span>
            ) : (
              <T id="noLocation" />
            )}
          </FormGroup>

          {interventions && interventions.length > 0 && (
            <FormGroup tag="fieldset">
              <TLabel className="mb-2" id="interventions.label" />

              <ListGroup>
                {interventions.map((intervention, i) => (
                  <ListGroupItem key={intervention.interventionId}>
                    <Link key={i} tag={Link} to={{
                      pathname: `/interventions/${intervention.interventionId}`,
                    }}>
                      <span className="d-block"><T id="interventionId" />{` ${intervention.interventionId} `}</span>
                      <span className="d-block"><T id="equipmentNumber" />{` ${intervention.equipmentIdentifier} (${intervention.categoryName} - ${intervention.familyName})`}</span>
                    </Link>
                  </ListGroupItem>
                )
                )}
              </ListGroup>
            </FormGroup>
          )}

          {userCanceled && <FormGroup tag="fieldset">
            <T id="userCanceled" tagName="p" className="mb-1" values={{ user: userCanceled }} />
            <Alert color="danger">{cancelationReason || <T id="noCancelReason" />}</Alert>
          </FormGroup>}

          {itvcodes && itvcodes.length > 0 && itvcodes.map((codes, i) => (
            <>
              <TLabel id="parcSelected" />
              <ListGroup className="mb-3">
                <ListGroupItem className="d-flex align-items-center">
                  <T id="nbEquipments"
                    values={{
                      businessName: maintenanceState.currentData.businessName,
                      categoryName: codes.categoryName,
                      familyName: codes.familyName
                    }} />
                </ListGroupItem>
              </ListGroup>
              <TLabel id="itvcodeList" />
              {itvcodesError ? <ErrAlert error={itvcodesError} /> : itvcodesBase && itvcodesBase[i] && codes && (
                <ItvcodeSelector
                  hasBusiness={businessId}
                  loading={maintenanceState.status === 'fetch' || itvcodesLoading}
                  itvcodesAdded={codes.itvcodes}
                  itvcodes={itvcodesBase[i].result}
                  reason={false}
                  noWear
                  onChange={codes => onItvcodesChange(itvcodes.map((codeList, j) => {
                    if (i === j) {
                      codeList.itvcodes = codes
                    }
                    return codeList
                  }))}                  
                />
              )}

              <TLabel id="durations" />
              <Card className="mb-4">
                <CardBody className="pb-3">
                  <div className="row mb-2">
                    {/* Display /60 hour */}
                    <TLabel
                      id="durationEstimated"
                      values={{
                        hours: Math.floor(durationEstimated[i] / 60),
                        minutes: durationEstimated[i] % 60,
                      }}
                      className="col mb-0" />
                    {durationPlanned != null
                      ? <TLabel
                        id="durationPlanned"
                        values={{
                          hours: Math.floor(durationPlanned / 60),
                          minutes: durationPlanned % 60,
                        }}
                        className="col mb-0" />
                      : <TLabel
                        id="durationPlannedNull"
                        className="col mb-0" />}
                  </div>
                  {(durationChrono != null || durationDone != null) && <div className="row mb-2">
                    {durationChrono != null && <TLabel
                      id="durationChrono"
                      values={{
                        hours: Math.floor(durationChrono / 60),
                        minutes: durationChrono % 60,
                      }}
                      className="col mb-0" />}
                    {durationDone != null && <TLabel
                      id="durationDone"
                      values={{
                        hours: Math.floor(durationDone / 60),
                        minutes: durationDone % 60,
                      }}
                      className="col mb-0" />}
                  </div>}
                </CardBody>
              </Card>
            </>
          ))}

          <FormGroup tag="fieldset">
            <TLabel id="information" />
            <Input type="textarea" name="information" id="information"
              disabled={maintenanceState.loading || !canEdit}
              value={information || ''}
              onChange={handleChange} />
          </FormGroup>

          <FormGroup tag="fieldset" className="mt-3">
            <TLabel id="difficultyId" for="difficultyId" />
            <div className={`difficulty ${!loading && difficultyId && constants.difficultyLevels.find(d => Number(d.value) === Number(difficultyId)).key}`}>
              <CustomSelect
                inputId="difficultyId"
                name="difficultyId"
                options={constants.difficultyLevels}
                onChange={e => { handleChange({ target: { name: 'difficultyId', value: e.value } }) }}
                value={constants.difficultyLevels.filter(d => d.value === difficultyId)}
                isDisabled={!canEdit}
                getOptionLabel={option => <T id={`difficulty.${option.key}`} raw />}
                getOptionValue={option => option.value} />
            </div>
          </FormGroup>

          <TLabel className="mb-2" id="addressTitle" />
          <Card className="mb-4">
            <CardBody>
              {addressesError ? <ErrAlert error={addressesError} /> : canEdit && (
                <FormGroup tag="fieldset">
                  <TLabel className="mt-0" id="addresses.label" for="addresses" />
                  <CustomSelect
                    inputId="addresses"
                    name="addresses"
                    options={addresses}
                    onChange={addressHandleChange}
                    placeholder={<T id="addresses.manual" />}
                    value={addresses && addresses.filter(a => a.addressId === selectedAddress) || ''}
                    isClearable
                    isDisabled={maintenanceState.loading || addressesLoading}
                    getOptionLabel={option => `${option.addressLabel} – ${option.addressFull} ${option.addressComplement && '- ' + option.addressComplement}`}
                    getOptionValue={option => option.addressId} />
                </FormGroup>
              )}
              <FormGroup tag="fieldset" className="flex-grow-1">
                <TLabel for="addressLabel" className="justify-content-start mb-2" id="addressLabel" />
                <Input id="addressLabel"
                  className="w-100"
                  type="text"
                  name="addressLabel"
                  disabled={maintenanceState.loading || !(canEdit) || selectedAddress}
                  value={addressLabel || ''}
                  onChange={e => handleChange(e)} />
              </FormGroup>
              <FormGroup tag="fieldset" className="w-100">
                <TLabel for="addressFull" className="mb-2" id="addressFull" />
                <TAutocomplete id="addressFull"
                  className="w-100"
                  type="text"
                  name="addressFull"
                  disabled={!canEdit || selectedAddress}
                  value={addressFull || ''}
                  placeholder="addressFullPlaceholder"
                  searchOnFocus={e => handleChangeAddressFull(e)}
                  onChange={e => handleChangeAddressFull(e)}
                  onAutocomplete={e => handleAutocomplete(e)}
                  options={
                    autocomplete && autocomplete.results.length > 0 && autocomplete.results.map(r => {
                      return (r.addressFull)
                    })} />
              </FormGroup>
              <FormGroup tag="fieldset" className="w-100 mb-0">
                <TLabel for="addressComplement" className="mb-2" id="addressComplement" />
                <TInput id="addressComplement"
                  className="w-100"
                  type="text"
                  name="addressComplement"
                  disabled={maintenanceState.loading || !canEdit || selectedAddress}
                  value={addressComplement || ''}
                  placeholder="addressComplementPlaceholder"
                  onChange={e => handleChange(e)} />
              </FormGroup>
            </CardBody>
          </Card>
        </CardBody>

        <CardFooter>
          {maintenanceState.status !== 'cancel' && maintenanceState.error && <ErrAlert error={maintenanceState.error} values={{ value: maintenanceState.error.itvcodes && maintenanceState.error.itvcodes.map((code, i) => i === 0 ? code : `, ${code}`) }} />}
          <div className="d-flex">
            {!hasStatus('canceled', 'anomaly', 'done') && (
              <ProtectedComponent rights={['maintenanceplan-cancel']}>
                <TButton disabled={maintenanceState.loading}
                  onClick={() => setConfirmCancel(true)} color="danger"
                  className="mx-1"
                  id="cancel" />
              </ProtectedComponent>
            )}
            <div className="ml-auto">
              {!hasStatus('canceled', 'done') && (
                <ProtectedComponent rights={['maintenanceplan-edit']}>
                  <TButton disabled={maintenanceState.loading}
                    spin={(maintenanceState.loading)}
                    className="mx-1"
                    onClick={() => handleSave()}
                    id="save" />
                </ProtectedComponent>
              )}
              {!hasStatus('canceled', 'done', 'anomaly') && (
                <ProtectedComponent rights={['maintenanceplan-edit']}>
                  <TButton disabled={maintenanceState.loading || !hasStatus('new', 'scheduled')}
                    spin={(maintenanceState.loading)}
                    className="mx-1"
                    onClick={() => handleSaveAndPlan()}
                    id="saveAndPlan" />
                </ProtectedComponent>
              )}
              {(hasStatus('scheduled') || hasStatus('new')) && (
                <ProtectedComponent rights={['maintenanceplan-end_web']}>
                  <TButton disabled={maintenanceState.loading || !hasStatus('new', 'scheduled')}
                    spin={(maintenanceState.loading)}
                    className="mx-1"
                    id="start"
                    to={`./start/${maintenanceplanId}`}
                    onClick={() => handleStart()}
                  />
                </ProtectedComponent>
              )}
            </div>
          </div>
        </CardFooter>
      </Card>

      {
        maintenanceState.prevent && <Modal isOpen={true} fade={true} toggle={() => maintenanceDispatch({ type: 'prevent', payload: undefined })}>
          <ModalBody>
            <T id="modal.preventUpdate.content" values={maintenanceState.prevent} />

            <ListGroup>
              {maintenanceState.prevent.reasons && maintenanceState.prevent.reasons.map(reason => (
                <ListGroupItem key={reason}><T id={`modal.preventUpdate.reason.${reason}`} values={maintenanceState.prevent} /></ListGroupItem>
              ))}
            </ListGroup>
          </ModalBody>
          <ModalFooter>
            <TButton disabled={maintenanceState.loading || maintenanceState.status === 'cancel'}
              onClick={() => maintenanceDispatch({ type: 'prevent', })}
              id="modal.preventUpdate.cancel" />
            <TButton disabled={maintenanceState.loading || maintenanceState.status === 'cancel'} spin={maintenanceState.loading || maintenanceState.status === 'cancel'}
              className="ml-2" color="danger" onClick={() => maintenanceState.prevent.callback({
                force: maintenanceState.prevent.reasons
              })}
              id="modal.preventUpdate.confirm" />
          </ModalFooter>
        </Modal>
      }

      < Modal isOpen={confirmCancel} fade={true} toggle={() => setConfirmCancel(false)}>
        <ModalBody>
          <div className="mb-1"><T id="modal.cancelContent" /></div>
          <TLabel for="cancelationReason" id="modal.cancelationReason" />
          <TInput id="cancelationReason"
            type="textarea" name="cancelationReasonTemp"
            value={cancelationReasonTemp}
            onChange={e => setCancelationReasonTemp(e.target.value)}
            placeholder="modal.cancelationReasonPlaceholder" />
          {maintenanceState.status === 'cancel' && maintenanceState.error && <ErrAlert className="mb-0 mt-2" error={maintenanceState.error} values={{ value: maintenanceState.error.itvcodes && maintenanceState.error.itvcodes.map((code, i) => i === 0 ? code : `, ${code}`) }} />}
        </ModalBody>
        <ModalFooter>
          <TButton disabled={maintenanceState.loading && maintenanceState.status === 'cancel'}
            onClick={() => setConfirmCancel(false)}
            id="modal.cancelCancel" />
          <TButton disabled={maintenanceState.loading && maintenanceState.status === 'cancel'} spin={maintenanceState.loading && maintenanceState.status === 'cancel'}
            className="ml-2" color="danger" onClick={handleCancel}
            id="modal.cancelConfirm" />
        </ModalFooter>
      </Modal>
    </div >
  )
}

export default MaintenanceEdit
