import React from 'react'
import Select from 'react-select'

const CustomSelect = ({ ...props }) => {
  return (
    <Select
      styles={customStyles}
      theme={customTheme}
      {...props}
    />
  )
}

const customTheme = theme => ({
  ...theme,
  borderRadius: 0,
  minHeight: 43,
  paddingLeft: 16,
  border: 'solid 1px #e0e0e0',
  colors: {
    ...theme.colors,
  },
})

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused || state.isHover || state.isSelected ? 'solid 1px 0180cc' : 'solid 1px e0e0e0',
    boxShadow: 'none',
    minWidth: 150,
    height: 'auto',
    minHeight: 43
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '4f4f4f',
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    marginBottom: 0,
    border: 'solid 1px #0180cc',
    borderTop: 'none',
    boxShadow: 'none',
    zIndex: 5
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    boxShadow: 'none'
  }),
  menuPortal: (provided) => ({
    ...provided,
    border: 'solid 1px purple'
  }),
}

export default CustomSelect