import React from 'react'
import {
  Card, CardHeader, CardBody, CardFooter,
  FormGroup, Input,
  Modal, ModalBody, ModalFooter,
  Spinner, NavLink,
  Row, Col
} from 'reactstrap'
import SignatureCanvas from 'react-signature-canvas'
import { Link } from 'react-router-dom'
import CustomSelect from '../../components/CustomSelect'
import ProtectedComponent, { useHasRights } from 'components/ProtectedComponent'
import { useIntl } from 'react-intl'

import './InterventionEdit.scss'

import { AppContext, } from 'contexts/AppContext'

import { T, ErrAlert, TAlert, TButton, TDatePicker, TLabel, TCustomInput } from 'components/TComponents'
import Button from 'components/Button'
import SlotSelector from 'components/SlotSelector'

import ItvcodeSelector from 'components/ItvcodeSelector'

const InterventionStart = ({ match, history }) => {
  const { api, subsidiary, user, INTERVENTION_ANOMALY_TYPES, CHECKLIST_STATUS } = React.useContext(AppContext)
  const canEditRight = useHasRights('interventions-end_web')
  const intl = useIntl()
  const signatureRef = React.useRef(null)

  const fileInput1 = React.useRef()
  const fileInput2 = React.useRef()
  const fileInput3 = React.useRef()
  const fileInputPic1 = React.useRef()
  const fileInputPic2 = React.useRef()
  const fileInputPic3 = React.useRef()


  const [intervention, setIntervention] = React.useState()
  const [interventionLoading, setInterventionLoading] = React.useState(true)
  const [interventionError, setInterventionError] = React.useState()
  const [baseIntervention, setBaseIntervention] = React.useState()

  const [techcenterBase, setTechcenterBase] = React.useState()
  const [techcenterError, setTechcenterError] = React.useState()
  const [techcenterLoading, setTechcenterLoading] = React.useState()

  const [itvcodesBase, setItvcodesBase] = React.useState()
  const [itvcodesLoading, setItvcodesLoading] = React.useState(true)
  const [itvcodesError, setItvcodesError] = React.useState()

  const [statusToBase, setStatusToBase] = React.useState([])

  const [itvHourSelected, setItvHourSelected] = React.useState(0)
  const [itvMinuteSelected, setItvMinuteSelected] = React.useState(0)

  const [duplicateInterventionNoQuality, setDuplicateInterventionNoQuality] = React.useState()

  const [matrix, setMatrix] = React.useState([])
  const [matrixFieldsFiltered, setMatrixFieldsFiltered] = React.useState([])
  const [matrixFields, setMatrixFields] = React.useState()

  const [loadingMatrix, setLoadingMatrix] = React.useState()
  const [loadingCodes, setLoadingCodes] = React.useState()
  const [matrixError, setMatrixError] = React.useState()

  const [isUserAssigned, setIsUserAssigned] = React.useState()

  const [notAllowedError, setNotAllowedError] = React.useState()
  const [finishError, setFinishError] = React.useState()
  const [uploadError, setUploadError] = React.useState()
  const [uploadAnlyError, setUploadAnlyError] = React.useState()
  const [uploadLoading, setUploadLoading] = React.useState()

  const [changeUserModal, setChangeUserModal] = React.useState(false)
  const [forceUserAssign, setForceUserAssign] = React.useState(false)

  const [canHaveCheckout, setCanHaveCheckout] = React.useState(false)
  const [canHavePic, setCanHavePic] = React.useState(false)

  const [pictures, setPictures] = React.useState()
  const [checkoutComments, setCheckoutComments] = React.useState()
  const [checkoutSigned, setCheckoutSigned] = React.useState(true)
  const [signatureModal, setSignatureModal] = React.useState(false)

  const [anomalyModal, setAnomalyModal] = React.useState(false)
  const [reason, setReason] = React.useState(INTERVENTION_ANOMALY_TYPES.partMissing)
  const [anomalyInformation, setAnomalyInformation] = React.useState('')
  const [anomalyError, setAnomalyError] = React.useState()

  const [newChecklist, setNewChecklist] = React.useState()
  const [enableChecklistNok, setEnableChecklistNok] = React.useState(false)

  const [lastDateDone, setLastDateDone] = React.useState()
  const [lastKm, setLastKm] = React.useState()
  const [kmModal, setKmModal] = React.useState(false)
  const [dateDone, setDateDone] = React.useState(new Date())
  const [dateDoneEnable, setDateDoneEnable] = React.useState(false)

  const [equipmentAlert, setEquipmentAlert] = React.useState()

  const [durationEstimated, setDurationEstimated] = React.useState(intervention && intervention.durationEstimated)

  const {
    interventionId,
    deskTicketId,
    noQuality,
    equipment,
    claimNumber,
    duplicateInterventionId,
    duplicateInterventionIds,

    statusValue,
    businessId, itvcodes,

    userAssignFirstname,
    userAssignLastname,
    userIdAssignedTo,

    datePlanned,
    qualifValue,
    timeRangeStart,
    timeRangeEnd,
    information,
    addressLabel, address, postalCode, city,
    addressComplement,

    equipmentKm,
    itvLastKm,
    itvLastDateDone,
    swapLastKm,
    swapLastDateDone,
    techComments,

  } = intervention || {}

  const itvHours = Array(9).fill().map((_, i) => i)
  const itvMinutes = Array(12).fill().map((_, i) => (i * 5))

  React.useEffect(() => {
    // Get Equipment Alerts
    if (equipment) {
      api.get('/equipments/alerts', undefined, {
        equipmentId: equipment.equipmentId,
      })
        .then(response => setEquipmentAlert(response))
    }
  }, [equipment, api])

  React.useEffect(() => {
    api.get('/interventions/details', undefined, { interventionId: match.params.id })
      .then(response => {
        setIntervention(response)
        setBaseIntervention({ ...response })
        return api.get('/itvcodes/list', undefined, {
          ipp: -1,
          businessId: response.businessId,
          familyId: response.equipment.familyId,
          categoryId: response.equipment.categoryId
        })
          .then(response => {
            setItvcodesBase(response)
          })
          .catch(response => setItvcodesError(response))
          .then(() => setItvcodesLoading(false))
      })
      .catch(response => setInterventionError(response))
      .then(() => setInterventionLoading(false))
  }, [api, match])

  React.useEffect(() => {
    if (duplicateInterventionId) {
      api.get('/interventions/details', undefined, { interventionId: duplicateInterventionId })
        .then(response => {
          setDuplicateInterventionNoQuality(response.noQuality)
        })
    }
  }, [api, duplicateInterventionId])

  React.useEffect(() => {
    if (baseIntervention && subsidiary && subsidiary.companyId) {
      api.get('/techcenters/list', undefined, { companyId: subsidiary.companyId, ipp: -1 })
        .then(response => {
          setTechcenterBase(response.result)
        })
        .catch(error => {
          setTechcenterError(error)
        })
      api.get('/companies/details', undefined, { companyId: subsidiary.companyId })
        .then(response => {
          setIntervention(intervention => ({
            ...intervention,
            partsOriginId: response.techcenterId
          }))
        })
        .catch(error => setTechcenterError(error))
        .then(() => setTechcenterLoading(false))
    } else {
      api.get('/techcenters/list', undefined, { ipp: -1 })
        .then(response => {
          setTechcenterBase(response.result)
        })
        .catch(error => {
          setTechcenterError(error)
        })
        .then(() => setTechcenterLoading(false))
    }
  }, [api, subsidiary, baseIntervention])

  React.useEffect(() => {
    if (baseIntervention && (baseIntervention.statusValue !== "scheduled" && baseIntervention.statusValue !== "waitPec")) {
      setNotAllowedError('notAllowed')
    }
    if (baseIntervention && user) {
      setIsUserAssigned(baseIntervention.userIdAssignedTo === user.userId)
    }
    if (baseIntervention) {
      setCanHaveCheckout(baseIntervention && baseIntervention.equipment && baseIntervention.equipment.subscriptionId && baseIntervention.qualifCheckoutEnable)
      setCanHavePic(baseIntervention.qualifCheckoutPicItvEnable)
    }
  }, [baseIntervention, user])

  React.useEffect(() => {
    const newStatsusTo = []
    if (baseIntervention && baseIntervention.equipment && baseIntervention.equipment.statusTo) {
      baseIntervention.equipment.statusTo.map(s => {
        if (s.children && s.children.length > 0) {
          s.children.map(c => {
            newStatsusTo.push({
              value: `${s.value}.${c.value}`,
              key: `${s.key}.${c.key}`,
              authorizeKo: s.authorizeKo
            })
          })
        } else {
          newStatsusTo.push(s)
        }
      })
    }
    setStatusToBase(newStatsusTo)
  }, [baseIntervention, setStatusToBase])

  React.useEffect(() => {
    if (businessId && equipment) {
      api.get('/matrices/list', undefined, {
        businessId,
        familyId: equipment.familyId,
        categoryId: equipment.categoryId
      })
        .then(data => {
          setMatrixError()
          if (data && data.result && data.result.length > 0) {
            const matrixData = data.result[0]
            setLoadingCodes(true)
            setLoadingMatrix(true)
            api.get('/matrices/details', undefined, {
              businessId,
              matrixId: matrixData && matrixData.matrixId
            })
              .then(data => {
                setLoadingMatrix(false)
                setLoadingCodes(false)
                setMatrixError()
                setMatrixFields(data.matrixFields)
              })
              .catch(error => {
                setLoadingMatrix(false)
                setLoadingCodes(false)
                setMatrixError(error)
              })
          }
        })
        .catch(error => setMatrixError(error))
    }
  }, [api, businessId, equipment])

  React.useEffect(() => {
    if (itvcodes && matrixFields) {
      const codesWithMatrix = matrixFields.filter(field => field.itvcodeId !== null)
      const codesToLink = []
      itvcodes.map(code => {
        codesWithMatrix.map(m => {
          if (code.itvcodeId === m.itvcodeId) {
            codesToLink.push({
              itvcodeId: code.itvcodeId,
              itvcodeIdentifier: code.itvcodeIdentifier,
              itvcodeLabel: code.itvcodeLabel,
              matrixColumnName: m.matrixColumnName,
            })
          }
        })
      })
      setMatrixFieldsFiltered(codesToLink)
    }
    if (itvcodes && itvcodes.length > 0) {
      const body = {
        itvcodes: itvcodes.map(itvcode => ({
          itvcodeId: itvcode.itvcodeId,
          quantity: itvcode.quantity,
        }))
      }
      api.post('/interventions/timeItvcodes', { body: JSON.stringify(body) })
        .then(response => setDurationEstimated(response.durationEstimated))
    } else {
      setDurationEstimated(0)
    }
  }, [api, matrixFields, itvcodes])

  React.useEffect(() => {
    if (intervention) {
      if (intervention.checklist && intervention.checklist.length > 0) {
        setNewChecklist(intervention.checklist)
      } else {
        api.get('/checklist/list', undefined, { ipp: -1, businessId: intervention.businessId, categoryId: intervention.equipment.categoryId, familyId: intervention.equipment.familyId, qualifId: intervention.qualifId })
          .then(response => {
            if (response.result && response.result.checklist && response.result.checklist.length > 0) {
              setNewChecklist(response.result.checklist.map(checklistItem => ({
                ...checklistItem,
                status: 1,
                comments: ""
              })))
              if (response.result.enableChecklistNok === 1) {
                setEnableChecklistNok(true)
              }
              setIntervention(intervention => ({
                ...intervention,
                ...{
                  checklist: response.result.checklist.map(checklistItem => ({
                    ...checklistItem,
                    status: 1,
                    comments: ""
                  }))
                },
              }))
            }
          })
          .catch(error => setFinishError(error.code))
      }
    }
  }, [api, intervention])

  React.useEffect(() => {
    setLastKm(((swapLastDateDone > itvLastDateDone) || !itvLastDateDone) ? swapLastKm : itvLastKm)
    setLastDateDone(((swapLastDateDone > itvLastDateDone) || !itvLastDateDone) ? swapLastDateDone : itvLastDateDone)
  }, [swapLastKm, swapLastDateDone, itvLastKm, itvLastDateDone])

  React.useEffect(() => {
    if (baseIntervention && baseIntervention.equipment && baseIntervention.equipment.parts && baseIntervention.equipment.parts.length > 0 && matrixFieldsFiltered) {
      const originalMatrix = baseIntervention.equipment.parts.map(piece => ({
        matrixColumnName: piece.partName,
        km: piece.equipmentPartKm,
        SN: piece.partSN
      }
      ))
      setMatrix(originalMatrix)
      setIntervention(intervention => ({
        ...intervention,
        ...{ matrix: originalMatrix },
      }))
    }
  }, [baseIntervention, matrixFieldsFiltered])

  const handlePic = React.useCallback((e, name) => {
    if (intervention) {
      const file = e.target.files[0]
      setUploadLoading(true)
      api.put('/interventions/pic', { body: file }, {
        interventionId: intervention.interventionId,
        equipment: name
      })
        .then(response => {
          if (response.picUrl) {
            setPictures({ ...pictures, [name]: response.picUrl })
          }
        })
        .catch(error => {
          if (name === 'anly') {
            setUploadAnlyError(error.code)
          } else {
            setUploadError(error.code)
          }
        })
        .then(setUploadLoading(false))
    }
  }, [api, intervention, pictures])

  const onItvcodesChange = React.useCallback((itvcodes) => {
    setIntervention(intervention => ({ ...intervention, itvcodes: itvcodes }))
  }, [])

  const handleChange = React.useCallback((e) => {
    const { target: { name, value } } = e
    const newState = { [name]: value }
    setIntervention(intervention => ({
      ...intervention,
      ...newState,
    }))
  }, [])

  const handleChangePartsOriginId = React.useCallback(techcenter => {
    setIntervention(intervention => ({
      ...intervention,
      ...{ partsOriginId: techcenter.techcenterId },
    }))
  }, [])

  const handleChangeStatusTo = React.useCallback(status => {
    const statusValue = status.value.toString().split('.')
    setIntervention(intervention => ({
      ...intervention,
      ...{
        statusTo: statusValue[0],
        childStatusTo: statusValue[1]
      }
    }))
  }, [])

  const updateMatrix = React.useCallback((e) => {
    if (baseIntervention) {
      const { target: { value } } = e
      const matrixToSend = JSON.parse(JSON.stringify(matrix))
      if (matrixToSend.length > 0) {
        matrixToSend.forEach(m => {
          m.km = baseIntervention.equipment.settings.trackKm ? value : 0
        })
      }
      setMatrix(matrixToSend)
      setIntervention(intervention => ({
        ...intervention,
        ...{ matrix: matrixToSend },
      }))
    }
  }, [matrix, baseIntervention])

  const handleMatrixChange = React.useCallback((sn, matrixColumnName) => {
    const matrixItem = {
      matrixColumnName: matrixColumnName,
      SN: sn
    }
    const matrixToSend = JSON.parse(JSON.stringify(matrix))
    if (matrixToSend.length > 0) {
      const columnId = matrixToSend.findIndex(m => m.matrixColumnName === matrixItem.matrixColumnName)
      if (columnId !== -1) {
        matrixToSend[columnId].SN = sn
        matrixToSend[columnId].km = baseIntervention.equipment.settings.trackKm ? intervention.equipmentKm : 0
      } else {
        matrixToSend.push({
          matrixColumnName: matrixItem.matrixColumnName,
          SN: sn,
          km: baseIntervention.equipment.settings.trackKm ? intervention.equipmentKm : 0
        })
      }
    } else {
      matrixToSend.push({
        matrixColumnName: matrixItem.matrixColumnName,
        SN: sn,
        km: baseIntervention.equipment.settings.trackKm ? intervention.equipmentKm : 0
      })
    }
    // Test with deep object copy to prevent infintie loop on saveIntervention
    if (JSON.stringify(matrixToSend) !== JSON.stringify(matrix)) {
      setMatrix(matrixToSend)
      setIntervention(intervention => ({
        ...intervention,
        ...{ matrix: matrixToSend },
      }))
    }

  }, [baseIntervention, matrix, intervention])

  const handleCheckChange = React.useCallback(({ item, status, comments }) => {
    if (status) {
      setIntervention(intervention => ({
        ...intervention,
        ...{
          checklist: newChecklist.map(checklistItem => ({
            ...checklistItem,
            status: item.priority === checklistItem.priority ? status : (checklistItem.status ? checklistItem.status : "")
          }))
        },
      }))
    }
    if (comments || comments === "") {
      setIntervention(intervention => ({
        ...intervention,
        ...{
          checklist: newChecklist.map(checklistItem => ({
            ...checklistItem,
            comments: item.priority === checklistItem.priority ? comments : (checklistItem.comments ? checklistItem.comments : '')
          }))
        },
      }))
    }
  }, [setIntervention, newChecklist])

  const handleCheckAllOk = React.useCallback(() => {
    setIntervention(intervention => ({
      ...intervention,
      ...{
        checklist: newChecklist.map(checklistItem => ({
          ...checklistItem,
          status: 3
        }))
      },
    }))
  }, [setIntervention, newChecklist])

  const checkIfChecklistOk = React.useCallback(() => {
    if (intervention && intervention.checklist && intervention.checklist.length > 0) {
      const check = intervention.checklist.map(item => {
        if (!item.status || item.status === 1) {
          if (enableChecklistNok && !(!item.comments || item.comments.length === 0)) {
            return true
          } else {
            if (enableChecklistNok && (!item.comments || item.comments.length === 0)) {
              return 'koComments'
            } else {
              return 'ko'
            }
          }
        }
        if (item.status === 2 && (!item.comments || item.comments.length === 0)) {
          return 'comments'
        }
        return true
      })
      return check
    }
    return []
  }, [intervention, enableChecklistNok])

  const checkIfKmOk = React.useCallback(() => {
    return lastKm > equipmentKm ? false : true
  }, [lastKm, equipmentKm])

  const hasCodeReason = React.useCallback(itvcode => {
    let hasReason = false
    if (intervention) {
      if (itvcode.reasonListItvDisplay === 1 || (itvcode.reasonListItvDisplay === -1 && itvcode.enabledQualifReasonPart && itvcode.enabledQualifReasonPart.includes(intervention.qualifValue))) {
        hasReason = true
      } else {
        const code = itvcode.reason && itvcode.reason.length > 0 && itvcode.reason.find(r => r.businessId === intervention.businessId)
        if (code && (code.reasonListItvDisplay === 1 || (code.reasonListItvDisplay === -1 && code.enabledQualifReasonPart && code.enabledQualifReasonPart.includes(intervention.qualifValue)))) {
          hasReason = true
        }
      }
    }
    return hasReason
  }, [intervention])

  const handleFinish = React.useCallback((forceKm = false) => {
    const statusTo = intervention.equipment.statusTo
      .find(s => String(s.value) === String(intervention.statusTo))
    const check = checkIfChecklistOk()
    if (!statusTo) {
      setFinishError('statusTo')
    }
    const checkKm = checkIfKmOk()
    if (check.includes('ko')) {
      setFinishError('canotEndInterventionWithStatus')
    } else if (check.includes('koComments')) {
      setFinishError('canotEndInterventionKoWithoutComment')
    } else if (check.includes('comments')) {
      setFinishError('canotEndInterventionWithoutComment')
    } else if (canHaveCheckout && checkoutSigned && !(pictures && pictures.signUrl)) {
      setFinishError('signatureRequired')
    } else {
      if (!checkKm && !forceKm) {
        setKmModal(true)
      } else {
        api.post('/interventions/end', {
          _stringifyBody: true,
          body: {
            interventionId: intervention.interventionId,
            dateStart: new Date().toISOString().replace(/\.[0-9]{3}/, ''),
            dateEnd: new Date().toISOString().replace(/\.[0-9]{3}/, ''),
            ...(dateDoneEnable && { dateDone: new Date(dateDone).toISOString().replace(/\.[0-9]{3}/, '') }),
            partsOriginId: intervention.partsOriginId,
            durationDone: itvHourSelected * 60 + itvMinuteSelected,
            durationChrono: itvHourSelected * 60 + itvMinuteSelected,
            equipmentKm: intervention.equipment.settings.trackKm ? parseInt(intervention.equipmentKm) : 0,
            techComments: intervention.techComments || '',
            itvcodes: intervention.itvcodes.map(itvcode => ({
              itvcodeId: itvcode.itvcodeId,
              quantity: itvcode.quantity,
              wear: itvcode.wear,
              ...(itvcode.reasonId && hasCodeReason(itvcode) && { reasonId: itvcode.reasonId })
            })),
            checklist: intervention.checklist,
            equipment: {
              equipmentId: intervention.equipment.equipmentId,
              statusId: intervention.statusTo,
              childStatusId: intervention.childStatusTo
            },
            ...(matrix && { matrix }),
            claimNumber: intervention.claimNumber,
            ...(canHaveCheckout && { checkoutComments: checkoutComments }),
            ...(canHaveCheckout && pictures && pictures.pic1 && { pic1: pictures.pic1 }),
            ...(canHaveCheckout && pictures && pictures.pic2 && { pic2: pictures.pic2 }),
            ...(canHaveCheckout && pictures && pictures.pic3 && { pic3: pictures.pic3 }),
            ...(canHaveCheckout && { checkoutSigned: checkoutSigned ? 1 : 0 }),

            ...(canHavePic && pictures && pictures.fot1 && { fot1: pictures.fot1 }),
            ...(canHavePic && pictures && pictures.fot2 && { fot2: pictures.fot2 }),
            ...(canHavePic && pictures && pictures.fot3 && { fot3: pictures.fot3 }),

            forceUserAssign: forceUserAssign
          }
        }).then(() => {
          api.post('/interventions/tracking', {
            _stringifyBody: true,
            body: {
              interventionId: intervention.interventionId,
              tracking: 0
            }
          })
          history.push('/interventions')
        }).catch(error => {
          setFinishError(error.code)
        })
      }
    }
  }, [api, intervention, itvHourSelected, itvMinuteSelected, matrix, history, forceUserAssign, canHaveCheckout, pictures, checkoutComments, checkIfChecklistOk, checkIfKmOk, canHavePic, hasCodeReason, checkoutSigned, dateDone, dateDoneEnable])

  const handleAnomaly = React.useCallback(() => {
    setAnomalyError()
    const body = {
      reason,
      information: anomalyInformation,
      interventionId: intervention.interventionId,
      itvcodes: intervention.itvcodes,
      techComments: intervention.techComments,
      claimNumber: intervention.claimNumber,
      checklist: intervention.checklist
    }
    api.post('/interventions/anomaly', { body: JSON.stringify(body) })
      .then(() => {
        setAnomalyModal(!false)
        api.post('/interventions/tracking', {
          _stringifyBody: true,
          body: {
            interventionId: intervention.interventionId,
            tracking: 0
          }
        })
        history.push(`/interventions/${intervention.interventionId}`)
      })
      .catch(error => setAnomalyError(error.code))
  }, [anomalyInformation, api, intervention, reason, history])

  const hasStatus = React.useCallback((...status) => status.includes(statusValue), [statusValue])

  const saveSignature = React.useCallback(async path => {
    if (intervention) {
      setUploadLoading(true)
      api.put('/interventions/pic', { body: path }, {
        interventionId: intervention.interventionId,
        equipment: 'sign'
      })
        .then(response => {
          if (response.picUrl) {
            setPictures({ ...pictures, 'signUrl': response.picUrl })
          }
        })
        .catch(error => {
          setUploadAnlyError(error.code)
        })
        .then(setUploadLoading(false))
    }
  }, [intervention, api, pictures])

  const handleSignature = React.useCallback(async signature => {
    if (!signature) {
      setPictures({ ...pictures, 'signUrl': null })
    } else {
      let byteString
      if (signature.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(signature.split(',')[1])
      else
        byteString = unescape(signature.split(',')[1])
      let mimeString = signature.split(',')[0].split(':')[1].split(';')[0];
      let ia = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      saveSignature(new Blob([ia], {type:mimeString}))
    }
  }, [saveSignature, pictures])

  const handleBackdate = React.useCallback(() => {
    setDateDoneEnable(!dateDoneEnable)
  }, [dateDoneEnable])

  const canEdit = hasStatus('waitPec', 'scheduled', 'done', 'anomaly') && !loading && !!canEditRight

  const loading = interventionLoading || techcenterLoading || itvcodesLoading || loadingMatrix || loadingCodes
  const error = interventionError || techcenterError || itvcodesError || notAllowedError || matrixError

  if (interventionError) {
    return <>
      <NavLink tag={Link} to={`/interventions/${intervention.interventionId}`}>
        <T id="returnToIntervention" />
      </NavLink>
      <TAlert id="itvNotFound" className="mb-0" color="danger" />
    </>
  }

  if (!interventionLoading && !equipment) {
    return <>
      <NavLink tag={Link} to={`/interventions/${intervention.interventionId}`}>
        <T id="returnToIntervention" />
      </NavLink>
      <TAlert id="invalidItv" className="mb-0" color="danger" />
    </>
  }

  if (loading) {
    return <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />
  }

  if (error) {
    return (
      <TAlert id={error} color="warning" />
    )
  }

  return (
    <div className="container-fluid InterventionStart">
      <NavLink tag={Link} to={`/interventions/${intervention.interventionId}`}>
        <T id="returnToIntervention" />
      </NavLink>
      {intervention && (
        <>
          <Card>
            <CardHeader className={`difficulty ${baseIntervention.difficultyValue}`}>
              <div className="ml-2">
                {equipment && <div className="h4">
                  <T id="equipmentId" />
                  <NavLink
                    tag={Link}
                    to={`/equipments/${equipment.equipmentId}`}
                    className="p-0 d-inline">
                    {equipment.equipmentIdentifier}
                  </NavLink>
                  <small className="ml-2">{equipment.categoryName}</small>
                  <small className="ml-2">{equipment.familyName}</small>
                </div>}
                <div className="h6 mb-0">
                  <span><T id="interventionId" />{interventionId}</span>
                  <small className="ml-2">
                    {deskTicketId ?
                      <Link to={`/desk/${deskTicketId}`}><T id="deskTicketId" />{deskTicketId}</Link> :
                      <><T id="deskTicketId" /><T id="noTicketId" /></>}
                  </small>
                </div>
                {noQuality === 1 &&
                                    <div className="mb-0 mt-2">
                                      <T id="noQuality" />
                                      {duplicateInterventionIds && duplicateInterventionIds.length > 0 && <T id="noQuality.origin" />}
                                      {duplicateInterventionIds && duplicateInterventionIds.length > 0 && duplicateInterventionIds.map(itv =>
                                        <Link key={itv} to={`../interventions/${itv}`}>
                                          {` #${itv} `}
                                        </Link>
                                      )}
                                    </div>
                }
                {duplicateInterventionId && (
                  <div className="mb-0 mt-2">
                    <T id="duplicateOrigin" />
                    <Link to={`../interventions/${duplicateInterventionId}`}>
                      {` #${duplicateInterventionId} `}
                    </Link>
                    {!!duplicateInterventionNoQuality && <T id="duplicateOriginNoQuality" />}
                  </div>
                )}
              </div>
            </CardHeader>

            <CardBody>
              <div className="mb-3 pt-2">
                {equipmentAlert && (
                  <>
                    {!!equipmentAlert.nbInfo && <TAlert color="info" id="nbInfo" values={{ value: equipmentAlert.nbInfo }} />}
                    {!!equipmentAlert.nbMinor && <TAlert color="warning" id="nbMinor" values={{ value: equipmentAlert.nbMinor }} />}
                    {!!equipmentAlert.nbMajor && <TAlert color="danger" id="nbMajor" values={{ value: equipmentAlert.nbMajor }} />}
                    {(!!equipmentAlert.nbInfo || !!equipmentAlert.nbMinor || !!equipmentAlert.nbMajor) && equipment && (
                      <TButton id="goToEquipmentLog"
                        tag={Link}
                        to={{
                          pathname: `/equipments/${equipment.equipmentId}/logs`
                        }}
                        color="primary" />
                    )}
                  </>
                )}
              </div>

              {!isUserAssigned && !hasStatus('waitPec') && (
                <FormGroup tag="fieldset">
                  <TLabel className="mb-0" id="statusValue" />
                  <T id="interventionStatus.started" className="pl-1" />
                </FormGroup>
              )}

              {userAssignFirstname && userAssignLastname && (
                <FormGroup tag="fieldset">
                  <TLabel className="mb-0" id="userAssign" />
                  {interventionLoading
                    ? <Spinner className="ml-2" color="primary" size="sm" />
                    :
                    <>
                      {forceUserAssign ? (
                        <span>{`${user.firstName} ${user.lastName}`}</span>
                      ) : (
                        <span>{`${userAssignFirstname} ${userAssignLastname}`}</span>
                      )}
                    </>
                  }
                  {userIdAssignedTo !== user && !forceUserAssign && (
                    <TButton className="ml-4" id="assignToMe" onClick={() => setChangeUserModal(true)} />
                  )}
                </FormGroup>
              )}

              <FormGroup tag="fieldset">
                <TLabel className="mb-0" id="datePlanned.label" />
                <T id="datePlanned.value"
                  values={{
                    datePlanned: datePlanned ? new Date(datePlanned) : null,
                    timeStart: timeRangeStart ? new Date().setHours(0, Math.min(timeRangeStart), 0, 0) : undefined,
                    timeEnd: timeRangeStart ? new Date().setHours(0, Math.max(timeRangeEnd), 0, 0) : undefined
                  }}
                  className="pl-1" />
              </FormGroup>

              <ProtectedComponent rights={['interventions-override_date']}>
                <TButton 
                  className="mr-auto mb-3" 
                  id={dateDoneEnable ? `noBackDate` : `backdate`} 
                  onClick={handleBackdate} />
                {dateDoneEnable && (
                  <FormGroup className="modal-datePickerFormGroup mb-3">
                    <TLabel id="itemEdit.date" />
                    <TDatePicker
                      wrapperClassName="w-100"
                      customInput={<SlotSelector  className="datetime-fixed" />}
                      showTimeSelect
                      selected={dateDone}
                      onChange={date => setDateDone(date)}
                      dateFormat="eeee dd/MM/yyyy, HH:mm"
                      timeFormat="HH:mm"
                      timeIntervals={5}
                    />
                  </FormGroup>
                )}
              </ProtectedComponent>

              <FormGroup tag="fieldset">
                <TLabel className="mb-0" id="qualif.label" />
                <T id={`qualif.${qualifValue}`} className="pl-1" />
              </FormGroup>

              <TLabel id="itvcodeList" />

              {itvcodesError ? <ErrAlert error={itvcodesError} /> : itvcodesBase && itvcodes && <ItvcodeSelector
                hasBusiness={Boolean(businessId)}
                loading={interventionLoading || itvcodesLoading}
                editable={canEdit}
                itvcodesAdded={itvcodes}
                itvcodes={itvcodesBase.result}
                isIntervention
                qualif={qualifValue}
                reason
                onChange={itvcodes => onItvcodesChange(itvcodes)} />}

              {intervention && intervention.equipment && intervention.equipment.parts && intervention.equipment.parts.length > 0 && (
                <>
                  <TLabel id="pieces" />
                  <Card className="mb-4">
                    <CardBody className="pb-3">
                      {intervention.equipment.parts.map(piece =>
                        <div className="mb-4" key={piece.partName}>
                          <T id={piece.partName} className="d-block" raw />
                          <span className="d-block"><T id="piece.equipmentPartKm" />{piece.equipmentPartKm}</span>
                          <span className="d-block"><T id="piece.partSN" />{piece.partSN}</span>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </>
              )}

              <FormGroup tag="fieldset">

                <TLabel id="durations" />
                {/* Display /60 hour */}
                <TLabel
                  id="durationEstimated"
                  values={{
                    hours: Math.floor(durationEstimated / 60),
                    minutes: durationEstimated % 60,
                  }}
                  className="col mb-0" />
              </FormGroup>

              <FormGroup tag="fieldset">
                <TLabel id="elapsedTime" />
                <Row>
                  <Col>
                    <TLabel id="selectHour" for="selectHour" />
                    <CustomSelect
                      inputId="selectHour"
                      name="selectHour"
                      options={itvHours}
                      placeholder={"0"}
                      onChange={e => setItvHourSelected(e)}
                      value={itvHours.filter(h => h == itvHourSelected)}
                      getOptionValue={option => option}
                      getOptionLabel={option => option}
                      isDisabled={loading} />
                  </Col>
                  <Col>
                    <TLabel id="selectMinute" for="selectMinute" />
                    <CustomSelect
                      inputId="selectMinute"
                      name="selectMinute"
                      options={itvMinutes}
                      placeholder={"0"}
                      onChange={e => setItvMinuteSelected(e)}
                      value={itvMinutes.filter(h => h == itvMinuteSelected)}
                      getOptionValue={option => option}
                      getOptionLabel={option => option}
                      isDisabled={loading} />
                  </Col>
                </Row>
              </FormGroup>


              <FormGroup tag="fieldset">
                <TLabel id="claimNumber" />
                <Input type="text" name="claimNumber" id="claimNumber"
                  disabled={loading || !canEdit}
                  value={claimNumber || ''}
                  onChange={handleChange} />
              </FormGroup>

              <FormGroup tag="fieldset">
                <TLabel id="information" />
                {information ? (
                  <span>{information}</span>
                ) : (
                  <T id="noInformation" />
                )}
              </FormGroup>

              <TLabel id="addressTitle" />
              <div>
                <p>
                  {addressLabel && addressLabel}
                  {addressLabel && address && ' - '}
                  {address && address}
                  {postalCode && ` ${postalCode}`}
                  {city && ` ${city}`}
                  {addressComplement && ` ${addressComplement}`}
                  {!addressLabel && <T id="addressNotFound" />}
                </p>
              </div>

              {intervention.locationValue &&
                                <FormGroup tag="fieldset">
                                  <TLabel id="locationId" for="locationId" />
                                  <T id={`locationId.${intervention.locationValue}`} />
                                </FormGroup>
              }

              <FormGroup tag="fieldset" >
                <TLabel className="mb-0" id="lastKm" />
                {lastKm ? lastKm : <T id="noLastKm" />}
              </FormGroup>
              <FormGroup tag="fieldset" >
                <TLabel className="mb-0" id="lastKmDate" />
                {lastDateDone && lastKm ? <T id="lastKmDateValue" values={{ date: intl.formatDate(lastDateDone) }} />
                  : <T id="noLastKmDate" />}
              </FormGroup>

              <FormGroup tag="fieldset" >
                <TLabel className="mb-0" id="equipmentKm.label" />
                <Input name="equipmentKm"
                  type="number"
                  value={equipmentKm || ''}
                  disabled={loading}
                  onChange={e => {
                    handleChange(e)
                    updateMatrix(e)
                  }} />
              </FormGroup>

              <FormGroup className="my-4">
                <TLabel id="techComments" />
                <Input type="textarea" name="techComments" id="techComments"
                  value={techComments || ''}
                  disabled={loading}
                  onChange={e => handleChange(e)} />
              </FormGroup>

              {techcenterBase && intervention && <FormGroup tag="fieldset" className="my-4">
                <TLabel id="partsOriginId" />
                <CustomSelect
                  inputId="partsOriginId"
                  name="partsOriginId"
                  onChange={e => handleChangePartsOriginId(e)}
                  isDisabled={loading}
                  options={techcenterBase}
                  getOptionLabel={option => option.techcenterName}
                  getOptionValue={option => option.techcenterId}
                  value={techcenterBase.filter(t => t.techcenterId === intervention.partsOriginId)} />
              </FormGroup>}

              {statusToBase && <FormGroup tag="fieldset" >
                <TLabel id="statusTo" />
                <CustomSelect
                  inputId="statusTo"
                  name="statusTo"
                  onChange={e => handleChangeStatusTo(e)}
                  isDisabled={loading}
                  options={statusToBase}
                  getOptionLabel={option => <T id={`equipmentStatus.${option.key}`} raw />}
                />
              </FormGroup>}
              <TLabel id="contactFull" />
              {intervention.contactFull ? (
                <span>{intervention.contactFull}</span>
              ) : (
                <T id="contactNotFound" />
              )}
            </CardBody>
          </Card>
          {matrixFieldsFiltered && matrixFieldsFiltered.length > 0 && (
            <Card>
              <CardBody>
                <TLabel className="mb-4" id="codesToLink.description" />
                {matrixFieldsFiltered.map(code =>
                  <React.Fragment key={code.itvcodeId}>
                    <FormGroup tag="fieldset">
                      <div className="mb-2">
                        <TLabel id="itvcodeLabel" />
                        <span>{code.itvcodeIdentifier} - {code.itvcodeLabel}</span>
                      </div>
                      <div className="mb-2">
                        <TLabel id="piecesParts" />
                        <T id={`matrix.${code.matrixColumnName}`} />
                      </div>
                      <div className="mb-2">
                        <TLabel id="partSN" />
                        <Input
                          id="partSN"
                          name="partSN"
                          type="text"
                          value={(matrix && matrix.length > 0 && matrix.find(m => m.matrixColumnName === code.matrixColumnName) && matrix.find(m => m.matrixColumnName === code.matrixColumnName).SN) || ''}
                          onChange={e => { handleMatrixChange(e.target.value, code.matrixColumnName) }}
                        />
                      </div>
                    </FormGroup>
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          )}
          <Card>
            {canHavePic && (
              <Card>
                <CardBody>
                  <TLabel className="mb-4 d-block" id="pic" />
                  <TLabel className="mb-2 mt-2" id="fot1" />
                  <FormGroup tag="fieldset">
                    <input ref={fileInputPic1} type="file" name="fot1" accept="application/png, application/jpeg" onChange={e => { handlePic(e, "fot1") }} hidden />
                    <div className="d-flex flex-column">
                      {pictures && pictures.fot1 && (
                        <img className="mb-2" src={pictures.fot1} style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }} />
                      )}
                      <TButton className="mr-auto" id="upload" onClick={() => fileInputPic1.current.click()} disabled={uploadLoading} />
                    </div>
                  </FormGroup>
                  <TLabel className="mb-2 mt-2" id="fot2" />
                  <FormGroup tag="fieldset">
                    <input ref={fileInputPic2} type="file" name="fot2" accept="application/png, application/jpeg" onChange={e => { handlePic(e, "fot2") }} hidden />
                    <div className="d-flex flex-column">
                      {pictures && pictures.fot2 && (
                        <img className="mb-2" src={pictures.fot2} style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }} />
                      )}
                      <TButton className="mr-auto" id="upload" onClick={() => fileInputPic2.current.click()} disabled={uploadLoading} />
                    </div>
                  </FormGroup>
                  <TLabel className="mb-2 mt-2" id="fot3" />
                  <FormGroup tag="fieldset">
                    <input ref={fileInputPic3} type="file" name="fot3" accept="application/png, application/jpeg" onChange={e => { handlePic(e, "fot3") }} hidden />
                    <div className="d-flex flex-column">
                      {pictures && pictures.fot3 && (
                        <img className="mb-2" src={pictures.fot3} style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }} />
                      )}
                      <TButton className="mr-auto" id="upload" onClick={() => fileInputPic3.current.click()} disabled={uploadLoading} />
                    </div>
                  </FormGroup>
                </CardBody>
                {uploadError && <TAlert id={uploadError} color="warning" />}
              </Card>
            )}
            {newChecklist && newChecklist.length > 0 && (
              <CardBody>
                <TLabel className="mb-4" id="checklist" />
                <Button
                  className="ml-4"
                  onClick={handleCheckAllOk}>
                  <T id="allOk" />
                </Button>
                {newChecklist.map(item => (
                  <Card key={item.priority}>
                    <CardBody className='pb-3 pt-3'>
                      <FormGroup tag="fieldset" className="pb-0">
                        <span className="d-block" style={{ fontWeight: 'bold', marginBottom: 8 }}>{item.checkLabel}</span>
                        <div className='d-flex flex-rox mb-2'>
                          {Object.entries(CHECKLIST_STATUS).map(statusItem =>
                            <TCustomInput
                              id={`${item.checkLabel}.${statusItem[1]}`}
                              key={`${item.checkLabel}.${statusItem[1]}`}
                              type="radio"
                              name={`${item.checkLabel}`}
                              label={`check.${statusItem[0]}`}
                              disabled={loading}
                              className="mr-4"
                              onChange={(e) => handleCheckChange({ item, status: parseInt(e.target.value) })}
                              checked={parseInt(item.status) === statusItem[1]}
                              value={parseInt(statusItem[1])} />
                          )}
                        </div>
                        <div>
                          <TLabel id="comments" />
                          <Input
                            id={`${item.checkLabel}.comments`}
                            name="comments"
                            type="text"
                            value={item.comments || ""}
                            onChange={e => { handleCheckChange({ item, comments: e.target.value }) }}
                          />
                        </div>
                      </FormGroup>
                    </CardBody>
                  </Card>
                ))}
              </CardBody>
            )}
            {canHaveCheckout && (
              <Card>
                <CardBody>
                  <TLabel className="mb-4 d-block" id="checkout" />
                  {!!intervention.enableSendEmailReportCheckoutItv && (
                    <TAlert id="enableSendEmailReportCheckoutItv" color="warning" />

                  )}
                  <TLabel className="mb-2 mt-2" id="pic1" />
                  <FormGroup tag="fieldset">
                    <input ref={fileInput1} type="file" name="pic1" accept="application/png, application/jpeg" onChange={e => { handlePic(e, "pic1") }} hidden />
                    <div className="d-flex flex-column">
                      {pictures && pictures.pic1 && (
                        <img className="mb-2" src={pictures.pic1} style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }} />
                      )}
                      <TButton className="mr-auto" id="upload" onClick={() => fileInput1.current.click()} disabled={uploadLoading} />
                    </div>
                  </FormGroup>
                  <TLabel className="mb-2 mt-2" id="pic2" />
                  <FormGroup tag="fieldset">
                    <input ref={fileInput2} type="file" name="pic2" accept="application/png, application/jpeg" onChange={e => { handlePic(e, "pic2") }} hidden />
                    <div className="d-flex flex-column">
                      {pictures && pictures.pic2 && (
                        <img className="mb-2" src={pictures.pic2} style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }} />
                      )}
                      <TButton className="mr-auto" id="upload" onClick={() => fileInput2.current.click()} disabled={uploadLoading} />
                    </div>
                  </FormGroup>
                  <TLabel className="mb-2 mt-2" id="pic3" />
                  <FormGroup tag="fieldset">
                    <input ref={fileInput3} type="file" name="pic3" accept="application/png, application/jpeg" onChange={e => { handlePic(e, "pic3") }} hidden />
                    <div className="d-flex flex-column">
                      {pictures && pictures.pic3 && (
                        <img className="mb-2" src={pictures.pic3} style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }} />
                      )}
                      <TButton className="mr-auto" id="upload" onClick={() => fileInput3.current.click()} disabled={uploadLoading} />
                    </div>
                  </FormGroup>
                  <TLabel className="mb-2 mt-2" id="checkoutComments" />
                  <FormGroup tag="fieldset">
                    <Input
                      type="textarea"
                      name="checkoutComments"
                      id="checkoutComments"
                      value={checkoutComments || ''}
                      disabled={loading}
                      onChange={e => setCheckoutComments(e.target.value)} />
                  </FormGroup>
                  <FormGroup tag="fieldset">
                    <div>
                      <TCustomInput
                        id="checkoutSigned"
                        className="mb-2"
                        type="checkbox"
                        name="disable"
                        label="checkoutSigned"
                        checked={!checkoutSigned}
                        onChange={() => setCheckoutSigned(!checkoutSigned)}
                        inline />
                    </div>
                    {checkoutSigned && (
                      <div>
                        {pictures && pictures.signUrl && (
                          <img className="mb-3 mt-2 d-block" src={pictures.signUrl} style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }} />
                        )}
                        <TButton id="sign" onClick={() => { setSignatureModal(true)} }/>
                      </div>
                    )}
                  </FormGroup>
                </CardBody>
                {uploadError && <TAlert id={uploadError} color="warning" />}
              </Card>
            )}
            {finishError && (
              <TAlert id={`error.${finishError}`} className="mt-4" color="danger" />
            )}
            <CardFooter className="d-flex">
              <div className="ml-auto">
                <TButton className="mx-1" disabled={loading}
                  id="anomaly"
                  onClick={() => { setAnomalyModal(true) }}
                  color="warning" />
                <TButton className="mx-1" disabled={loading}
                  id="finish"
                  onClick={() => { handleFinish() }}
                  color="primary" />
              </div>
            </CardFooter>
          </Card >
          <Modal isOpen={anomalyModal} fade={true} toggle={() => setAnomalyModal(!anomalyModal)}>
            <ModalBody>
              <T id="anomaly" className="d-block mb-3 h6" />
              <FormGroup tag="fieldset">
                <TLabel id="reason.title" className="d-block" />
                {Object.entries(INTERVENTION_ANOMALY_TYPES).map(([anomaly, value]) => (
                  <TCustomInput
                    key={anomaly}
                    id={anomaly}
                    type="radio"
                    name="anomaly"
                    checked={reason == value}
                    label={`anomaly.${anomaly}`}
                    onChange={() => setReason(value)} />
                ))}
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="anomalyInformation" />
                <Input
                  type="textarea"
                  rows="8"
                  value={anomalyInformation}
                  onChange={e => setAnomalyInformation(e.target.value)}
                  multiline
                />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel className="mb-2 mt-2" id="anly" />
                <input ref={fileInput1} type="file" name="anly" accept="application/png, application/jpeg" onChange={e => { handlePic(e, "anly") }} hidden />
                <div className="d-flex flex-column">
                  {pictures && pictures.anly && (
                    <img className="mb-2" src={pictures.anly} style={{ maxWidth: 600, maxHeight: 300, objectFit: 'contain' }} />
                  )}
                  <TButton className="mr-auto" id="upload" onClick={() => fileInput1.current.click()} disabled={uploadLoading} />
                </div>
              </FormGroup>
              {uploadAnlyError && <TAlert id={uploadAnlyError} color="warning" />}
              {anomalyError && <TAlert id={anomalyError} color="danger" />}
            </ModalBody>
            <ModalFooter>
              <Button
                className="ml-2" onClick={() => {
                  setAnomalyModal(!anomalyModal)
                }}>
                <T id="cancel" color="warning" />
              </Button>
              <Button
                onClick={() => handleAnomaly()} color="success">
                <T id="confirm" />
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={changeUserModal} fade={true} toggle={() => setChangeUserModal(!changeUserModal)}>
            <ModalBody>
              <div className="mb-1"><T id="modal.changeUser" /></div>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  setForceUserAssign(true)
                  setChangeUserModal(!changeUserModal)
                }}>
                <T raw id="yes" />
              </Button>
              <Button
                className="ml-2" onClick={() => {
                  setForceUserAssign(false)
                  setChangeUserModal(!changeUserModal)
                }}>
                <T raw id="no" />
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={kmModal} fade={true} toggle={() => setKmModal(!kmModal)}>
            <ModalBody>
              <div className="mb-1"><T id="modal.km" /></div>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  handleFinish(true)
                  setKmModal(!kmModal)
                }}>
                <T raw id="yes" />
              </Button>
              <Button
                className="ml-2" onClick={() => {
                  setKmModal(!kmModal)
                }}>
                <T raw id="no" />
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={signatureModal} fade={true} toggle={() => setSignatureModal(!signatureModal)}>
            <ModalBody>
              <TLabel id="modal.signature" className="mb-3" />
              <div style={{ border: '1px solid darkgray', marginTop: 6, height: 200, width: '100%' }}>
                <SignatureCanvas
                  ref={signatureRef}
                  canvasProps={{width: 444, height: 200, className: 'sigCanvas'}} />
              </div>
              <T id="signature.description" className="d-block mt-3" />
            </ModalBody>
            <ModalFooter className="justify-content-between">
              <div className="d-flex">
                <TButton
                  className="mr-2"
                  id="signature.cancel"
                  color="warning"
                  onClick={() => { setSignatureModal(false) }} />
                <TButton
                  id="signature.clear"
                  color="danger"
                  onClick={() => { 
                    signatureRef.current.clear() 
                    handleSignature()
                  }} />
              </div>
              <div>
                <TButton
                  id="signature.confirm"
                  onClick={() => { 
                    handleSignature(signatureRef.current.getTrimmedCanvas().toDataURL('image/png'))
                    setSignatureModal(false)
                  }}/>
              </div>
            </ModalFooter>
          </Modal>
        </>
      )}
    </div >
  )
}

export default InterventionStart
