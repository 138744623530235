import React from 'react'
import { useParams, Link } from 'react-router-dom'

import {
  Card, CardBody, CardHeader, CardFooter,
  Form, FormGroup, Input,
  Spinner
} from 'reactstrap'

import { AppContext } from 'contexts/AppContext'

import ProtectedComponent from 'components/ProtectedComponent'
import { TButton, TNavLink, TLabel, ErrAlert, TCustomNumericInput } from 'components/TComponents'

const ItvcodeEdit = () => {
  const { api } = React.useContext(AppContext)
  const routeParams = useParams()

  const [edit, setEdit] = React.useState()

  const [itvcode, setItvcode] = React.useState()
  const [itvcodeLoading, setItvcodeLoading] = React.useState(true)
  const [itvcodeError, setItvcodeError] = React.useState()
  const [itvcodeBase, setItvcodeBase] = React.useState()

  const [updateLoading, setUpdateLoading] = React.useState(false)
  const [updateError, setUpdateError] = React.useState()

  React.useEffect(() => {
    Promise.all([
      api.get('/itvcodes/details', undefined, { itvcodeId: routeParams.id }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([response]) => setItvcodeBase(response))
      .catch(response => setItvcodeError(response))
      .then(() => setItvcodeLoading(false))
  }, [api, routeParams])

  React.useEffect(() => setItvcode(itvcodeBase), [itvcodeBase])

  const handleChange = React.useCallback(({ target: { name, value } }) => {
    setItvcode(itvcode => ({
      ...itvcode,
      [name]: value
    }))
  }, [])

  const save = React.useCallback(() => {
    setUpdateLoading(true)
    setUpdateError()
    Promise.all([
      api.post('/itvcodes/details', { body: JSON.stringify(itvcode) }),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([response]) => {
        setItvcodeBase(response)
        setEdit(false)
      })
      .catch(response => setUpdateError(response))
      .then(() => setUpdateLoading(false))
  }, [api, itvcode])

  const cancel = React.useCallback(() => {
    setEdit(false)
    setItvcode(itvcodeBase)
  }, [itvcodeBase])

  return (
    <>
      {itvcode && (
        <>
          <TNavLink id="returnToList" tag={Link} to="/admin/itvcodes" />
          <Card className="mb-5">
            <CardHeader tag="h2">{itvcode.itvcodeIdentifier}</CardHeader>
            {itvcodeError && <ErrAlert className="mb-0" error={itvcodeError} />}
            {itvcodeLoading && <Spinner className="d-flex ml-auto mr-auto mb-5 mt-5" color="primary" />}
            {!itvcodeLoading && !itvcodeError && <CardBody tag={Form}>
              <FormGroup tag="fieldset" className="d-flex">
                <TLabel id="itvcodeIdentifier" for="itvcodeIdentifier" />
                <Input id="itvcodeIdentifier"
                  type="text"
                  name="itvcodeIdentifier"
                  disabled={true}
                  value={itvcode.itvcodeIdentifier} />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="itvcodeLabel" for="itvcodeLabel" />
                <Input id="itvcodeLabel"
                  type="text"
                  name="itvcodeLabel"
                  disabled={!edit || updateLoading}
                  value={itvcode.itvcodeLabel || ''}
                  onChange={handleChange} />
              </FormGroup>
              <FormGroup tag="fieldset" className="d-flex">
                <TLabel id="ref" for="ref" />
                <Input id="ref"
                  type="text"
                  name="ref"
                  disabled={!edit || updateLoading}
                  value={itvcode.ref || ''}
                  onChange={handleChange} />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="priceTTC" for="priceTTC" />
                <TCustomNumericInput id="priceTTC"
                  step={1}
                  precision={2}
                  type={'currency'}
                  canEdit={edit}
                  name="priceTTC"
                  disabled={!edit || updateLoading}
                  value={itvcode.priceTTC}
                  onChange={handleChange} />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="durationMin" for="durationMin" />
                <TCustomNumericInput id="durationMin"
                  step={1}
                  min={0}
                  name="durationMin"
                  disabled={!edit || updateLoading}
                  value={itvcode.durationMin || ''}
                  onChange={handleChange} />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="categoryName" for="categoryName" />
                <Input id="categoryName"
                  type="text"
                  name="categoryName"
                  disabled={true}
                  value={itvcode.categoryName || ''} />
              </FormGroup>
              <FormGroup tag="fieldset">
                <TLabel id="familyName" for="familyName" />
                <Input id="familyName"
                  type="text"
                  name="familyName"
                  disabled={true}
                  value={itvcode.familyName || ''} />
              </FormGroup>
              {updateError && <ErrAlert error={updateError} />}
            </CardBody>}
            <ProtectedComponent rights={['admin-itvcodes_edit']}>
              <CardFooter className="d-flex justify-content-end">
                {!edit && <TButton id="edit"
                  disabled={updateLoading || itvcodeLoading}
                  onClick={() => setEdit(true)} />}
                {edit && <TButton id="save"
                  disabled={updateLoading || itvcodeLoading}
                  spin={updateLoading}
                  className="ml-2"
                  onClick={save} color="primary" />}
                {edit && <TButton id="cancel"
                  disabled={updateLoading || itvcodeLoading}
                  className="ml-2"
                  onClick={cancel}
                />}
              </CardFooter>
            </ProtectedComponent>
          </Card>
        </>
      )}
    </>
  )
}

export default ItvcodeEdit
